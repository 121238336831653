import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';
import FormattedPrice from 'components/FormattedPrice';
import messages from './messages';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaxTotal = styled(Flex)`
  font-weight: bold;
`;

export const TaxLabel = styled.span``;

const TaxBreakdown = ({ taxBreakdown, taxTotal, isTaxExcluded }) => (
  <>
    {taxBreakdown.map(taxTotal => (
      <Flex key={taxTotal.get('tax_category_id')}>
        <TaxLabel>{taxTotal.get('name')}</TaxLabel>
        <span>
          <FormattedPrice value={taxTotal.get('total')} />
        </span>
      </Flex>
    ))}
    <TaxTotal>
      <TaxLabel>
        <FormattedMessage {...messages.taxTotal} />{' '}
        {!isTaxExcluded && (
          <small>
            <FormattedMessage {...globalMessages.taxIncluded} />
          </small>
        )}
      </TaxLabel>
      <span>
        <FormattedPrice value={taxTotal} />
      </span>
    </TaxTotal>
  </>
);

TaxBreakdown.propTypes = {
  taxBreakdown: PropTypes.instanceOf(List),
  taxTotal: PropTypes.number,
  isTaxExcluded: PropTypes.bool,
};

export default TaxBreakdown;
