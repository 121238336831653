import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { image } from 'utils';

import SuitableFor from 'components/SuitableFor';
import { Chilli } from 'components/Chilli';
import ProductPrice from 'components/Product/price';
import ProductIcon from 'components/Product/icon';
import QuantityInOrder from 'components/Product/QuantityInOrder';
import { H4 } from 'components/Typography';

import { getLocale } from 'selectors/user';

import { Container, DisabledMessage, StyledImg, Details, FlexContainer, Price, Breadcrumbs } from './styles';

const propTypes = {
  product: PropTypes.instanceOf(Map),
  price: PropTypes.number,
  isAvailable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledMessage: PropTypes.node,
  isLoadingInfo: PropTypes.bool,
  additionalButtons: PropTypes.array,
  showInfo: PropTypes.func,
  breadcrumbs: PropTypes.array,
  inOrder: PropTypes.number,
  serviceId: PropTypes.string.isRequired,
  currentLocale: PropTypes.string,
};

const defaultProps = {
  isAvailable: true,
  isDisabled: false,
  disabledMessage: null,
  isLoadingInfo: false,
  breadcrumbs: null,
};

const ProductBar = ({
  product,
  price,
  showInfo,
  isAvailable,
  isDisabled,
  disabledMessage,
  isLoadingInfo,
  additionalButtons,
  breadcrumbs,
  inOrder,
  serviceId,
  currentLocale,
}) => (
  <Container onClick={showInfo}>
    <QuantityInOrder quantity={inOrder} />
    {additionalButtons && <div>{additionalButtons}</div>}
    {product?.getIn(['images', 0, 'path']) && (
      <StyledImg
        src={image(product?.getIn(['images', 0, 'path']), { width: 56, height: 56 })}
        alt={product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}
      />
    )}
    <Details>
      {breadcrumbs && (
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb, index) => (
            <span key={index}>{index === breadcrumbs.length - 1 ? breadcrumb : `${breadcrumb} > `}</span>
          ))}
        </Breadcrumbs>
      )}
      <FlexContainer>
        <H4>{product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}</H4>
        <SuitableFor product={product} />
        <Chilli product={product} />
      </FlexContainer>

      <FlexContainer>
        <Price>
          {disabledMessage ? (
            <DisabledMessage>{disabledMessage}</DisabledMessage>
          ) : (
            <ProductPrice price={price} />
          )}
        </Price>
      </FlexContainer>
    </Details>
    <div>
      <ProductIcon
        isAvailable={isAvailable}
        isDisabled={isDisabled}
        isLoading={isLoadingInfo}
        onAdd={showInfo}
        serviceId={serviceId}
      />
    </div>
  </Container>
);

ProductBar.propTypes = propTypes;
ProductBar.defaultProps = defaultProps;

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(ProductBar);
