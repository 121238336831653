const sortVenuesByDistance = require('./sortVenuesByDistance');
const addUserDistanceToVenue = require('./addUserDistanceToVenue');
const filterVenues = require('./filterVenues');

const calculateVenuesListing = (venues, userGeo, searchTerm) => {
  let venuesList = venues;

  if (userGeo) {
    venuesList = sortVenuesByDistance(addUserDistanceToVenue(venues, userGeo));
  }

  return searchTerm?.trim() ? filterVenues(venuesList, searchTerm) : venuesList;
};

module.exports = calculateVenuesListing;
