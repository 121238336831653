import React from 'react';
import PropTypes from 'prop-types';

// Icon
const OrdersIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M202,158.816V223H35V37h58v2.473c0,0-20.695,11.615-23.22,37.032c10.109,0.168,85.798,0.021,97.89,0		C166.572,51.593,145,39.476,145,39.476V37h57v59.704l11-11.614V25h-69.975c-0.462-2-1.313-4.759-2.539-6.855		c-1.482-2.536-3.404-4.875-5.715-6.911c-2.32-2.038-4.848-3.591-7.513-4.618c-2.674-1.027-5.701-1.547-8.996-1.547		c-2.985,0-5.923,0.52-8.73,1.538c-2.856,1.033-5.336,2.614-7.375,4.688c-2.277,2.012-4.18,4.324-5.657,6.851		C95.27,20.251,94.417,23,93.959,25H23v210h190v-87.293L202,158.816z M105,46.148V30.757c0-1.935,0.341-3.743,1.008-5.381		c0.658-1.63,1.741-3.162,3.095-4.553c1.04-1.065,2.356-1.954,3.953-2.64c1.609-0.683,3.371-1.029,5.243-1.029		c1.886,0,3.76,0.354,5.536,1.048c1.728,0.686,3.17,1.568,4.199,2.618c1.352,1.393,2.792,2.928,3.456,4.557		c0.666,1.647,1.51,3.458,1.51,5.38v15.322c0,0,13.938,4.921,18.619,17.921c-7.788,0-61.452,0-66.254,0		C90.824,50,105,46.148,105,46.148z"
      />
      <path
        className="icon"
        fill={fill}
        d="M151.452,189.505c-1.069,0-2.045-0.096-2.899-0.284c-1.444-0.299-2.236-1.154-2.663-1.918l-26.262-27.973	c-1.563-1.654-2.358-3.67-2.358-5.986c0-2.285,0.812-4.183,2.352-5.505c1.246-1.638,3.025-2.5,5.164-2.5	c2.155,0,4.045,0.844,5.616,2.508l21.068,22.439l62.233-64.352c1.249-1.691,3.053-2.584,5.227-2.584	c2.157,0,4.047,0.844,5.619,2.508l0.089,0.095l0.075,0.107c1.15,1.637,1.733,3.584,1.733,5.789c0,2.325-0.846,4.254-2.447,5.585	l-67.46,69.885c-0.433,0.758-1.228,1.604-2.669,1.905C153.023,189.409,152.205,189.505,151.452,189.505z"
      />
      <path className="icon" fill={fill} d="M49,87v35h35V87H49z M74,112H59V97h15V112z" />
      <path className="icon" fill={fill} d="M49,178v35h35v-35H49z M74,203H59v-15h15V203z" />
      <path className="icon" fill={fill} d="M49,131v35h35v-35H49z M74,156H59v-15h15V156z" />
    </g>
  </svg>
);

OrdersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OrdersIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default OrdersIcon;
