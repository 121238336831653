import { useEffect } from 'react';

function useHandleOutsideClick(isOpen, nodeRef, setIsOpen, callback = () => {}) {
  useEffect(() => {
    const handleOutsideClick = ({ target }) => {
      if (isOpen && nodeRef?.current.contains(target) === false) {
        setIsOpen(false);
        callback();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, nodeRef, setIsOpen, callback]);
}

export default useHandleOutsideClick;
