import React from 'react';
import { ContentArea, ListLowerLatin, ListLowerRoman } from './styles';

type Props = { className?: string };

const TermsAndConditions = ({ className }: Props) => (
  <ContentArea className={className}>
    <p>Wi-Q OPEG TERMS AND CONDITIONS</p>

    <p>PLEASE READ THIS CAREFULLY BEFORE USING THIS OPEG OR WEBSITE.</p>

    <h3>1. Parties</h3>

    <p>
      This end-user licence agreement (the "Agreement") is a legal agreement between you ("You" or and Wi-Q
      Limited, a company registered in England and Wales with company registration number whose registered
      office is at 150 Minories, London, EC3N 1LS&nbsp; (referred to in this this Agreement as "Us", "We", or
      "Our"). Our VAT number is 199 2137 74.
    </p>

    <h3>2. Definitions</h3>

    <p>In this Agreement the following definitions apply:</p>
    <p>
      <b>Device:</b> the device that You use to access Our Site, including but not limited to tablet,
      notebook, netbook, laptop, mobile phone, smartphone, pda, tablet, or any other device that is capable of
      accessing Our Site whether or not You actually own the device.
    </p>
    <p>
      <b>OPEG:</b> means Our website as accessed by You with Your Device.
    </p>
    <p>
      <b>Order:</b> Your order for Products from the Outlet as placed through the OPEG.
    </p>
    <p>
      <b>Outlet:</b> The outlet specified in the OPEG as the provider of the Products.
    </p>
    <p>
      <b>Products:</b> Those products offered by the Outlet for Your purchase through the OPEG.
    </p>
    <p>
      <b>Services:</b> The services that We provide to You from time to time through Our Site on the terms and
      conditions of this Agreement, namely ordering of food and drink items from Outlets.
    </p>
    <p>
      <b>Site:</b> Our website, OPEG, and or other software through which You may order Products from the
      Outlet including but not limited to Our website <a href="https://www.wi-q.com/">https://www.wi-q.com</a>{' '}
      and all references include any current or future version of Our website or OPEG or other software
      through which You access Our Services, in each case whether accessed through any current or future
      platform or Device.
    </p>

    <h3>3. Status of Agreement</h3>

    <ListLowerLatin>
      <li>
        This Agreement sets out the terms and conditions on which We provide Our Services to You using Your
        Device to access Our Site.
      </li>
      <li>
        Please read this Agreement carefully before placing Your Order as Your purchase is subject to this
        Agreement.
      </li>
      <li>
        By clicking on the &quot;Accept&quot; button below You agree to the terms of this Agreement which will
        bind You. If You do not agree to the terms of this Agreement You must click on the &quot;Cancel&quot;
        button below and You will not be able to access Our Services.
      </li>
      <li>
        You accept responsibility in accordance with the terms of this Agreement for the use of the Site or
        any Service whether or not You own the Device You are using.
      </li>
      <li>
        We reserve the right to change this Agreement from time to time. We will notify You if We change any
        part of this Agreement with details of the change.
      </li>
      <li>We advise You to print or store a copy of this Agreement for future reference.</li>
      <li>
        By ordering Products via the Site (whether now or in the future), You agree to be bound by this
        Agreement including any changes to it in effect at the time of Your Order.
      </li>
      <li>We licence use of Our Site on the basis of this Agreement.</li>
      <li>
        From time to time We will update the OPEG. Depending on the update, You may not be able to use the
        OPEG or the Services until You have accepted any new terms.
      </li>
      <li>We remain the owners of the Site at all times.</li>
      <li>
        The terms of Our privacy policy (Our "Privacy Policy") are incorporated into this Agreement and are
        reproduced below.
      </li>
      <li>
        By using Our Site and or the OPEG You acknowledge and agree that internet transmissions are never
        completely private or secure. You understand that any message or information You send using the Site
        may be read or intercepted by others, even if there is a special notice that a particular transmission
        is encrypted.
      </li>
      <li>
        By using Our Site and or the OPEG or any of the Services, You consent to Us collecting and using
        technical information about Your Device and related software.
      </li>
      <li>
        Certain Services will make use of location data sent from Your Device. If You use the Services, we
        will use this data in accordance with our Privacy Notice to provide the Services.
      </li>
      <li>You are responsible for making all arrangements necessary for You to have access to Our Site.</li>
      <li>
        You are also responsible for ensuring that all persons who access the Site through Your Device are
        aware of this Agreement and that they comply with them as You will be responsible for their actions.
      </li>
      <li>
        The Site the OPEG or any Service may contain links to other independent third-party websites ("Third
        Party Sites"). Third Party Sites are not under Our control, and We are not responsible for and do not
        endorse their content or their privacy policies (if any). You will need to make Your own independent
        judgement regarding Your interaction with any Third Party Sites, including the purchase and use of any
        products or services accessible through them. For the avoidance of doubt, we are not responsible for
        inaccurate or incorrect information about Products sold by Third Party Sites via the OPEG or Our Site.
        If you require further information (including any dietary or allergen information not shown) or advice
        about any Product or service available on the OPEG or on our Site please contact the relevant Third
        Party Site for more information.
      </li>
    </ListLowerLatin>

    <h3>4. Grant and scope of licence</h3>

    <ListLowerLatin>
      <li>
        In consideration of You agreeing to abide by the terms of this Agreement We grant You a
        non-transferable, non-exclusive, non-sub licensable licence to use the OPEG on the Device, subject to
        these terms and the Privacy Policy, incorporated into this Agreement by reference. We reserve all
        other rights.
      </li>
    </ListLowerLatin>

    <h3>5. Site Access</h3>

    <ListLowerLatin>
      <li>
        We aim to ensure that the Site and or the OPEG is available at all times but We do not undertake any
        obligation to do so or warrant that it will be. We will not be liable to You if the Site is
        unavailable at any time or for any period.
      </li>
      <li>
        We may temporarily suspend Your access to the Site and or the OPEG at any time and without notice.
      </li>
    </ListLowerLatin>

    <h3>6. Restrictions</h3>

    <p>Except as expressly set out in this Agreement or as permitted by any local law, You agree</p>

    <ListLowerLatin>
      <li>
        not to copy the Site and or the OPEG in whole or in part except where such copying is incidental to
        normal use of the Site and or the OPEG, or where it is necessary for the purpose of back-up or
        operational security and then to the minimum extent and for the minimum period necessary;
      </li>
      <li>
        not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the Site or the OPEG;
      </li>
      <li>
        not to make alterations to, or modifications of, the whole or any part of the Site or the OPEG, or
        permit the Site or the OPEG or any part of it to be combined with, or become incorporated in, any
        other programs;
      </li>
      <li>
        not to provide or otherwise make available the Site and or the OPEG in whole or in part (including
        object and source code), in any form to any person without Our prior written consent;
      </li>
      <li>
        not use the Site and or the OPEG or any Service in any unlawful manner, for any unlawful purpose, or
        in any manner inconsistent with this Agreement, or act fraudulently or maliciously, for example, by
        hacking into or inserting malicious code, including viruses, or harmful data, into the Site and or the
        OPEG, any Service or any operating system;
      </li>
      <li>
        to comply with all technology control or export laws and regulations that apply to the technology used
        or supported by the Site and or the OPEG or any Service;
      </li>
      <li>
        not infringe Our intellectual property rights or those of any third party in relation to Your use of
        the Site and or the OPEG or any Service;
      </li>
      <li>
        not transmit any material that is defamatory, offensive or otherwise objectionable in relation to Your
        use of the Site and or the OPEG or any Service;
      </li>
      <li>
        not use the Site and or the OPEG or any Service in a way that could damage, disable, overburden,
        impair or compromise Our systems or security or interfere with other users or any other person;
      </li>
      <li>
        not collect or harvest any information or data from any Service or Our systems or attempt to decipher
        any transmissions to or from the servers running any Service; and
      </li>
      <li>
        not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any
        part of the Site and or the OPEG or attempt to do any such thing except to the extent that (by virtue
        of section 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited
        because they are essential for the purpose of achieving inter-operability of the Site with another
        software program, and provided that the information obtained by You during such activities:
        <ListLowerRoman>
          <li>
            is used only for the purpose of achieving inter-operability of the Site and or the OPEG with
            another software program;
          </li>
          <li>
            is not unnecessarily disclosed or communicated without Our prior written consent to any third
            party; and
          </li>
          <li>is not used to create any software that is substantially similar to the OPEG;</li>
        </ListLowerRoman>
      </li>
    </ListLowerLatin>

    <h3>7. Your Status</h3>

    <ListLowerLatin>
      <li>
        By placing an Order through the OPEG, You warrant that:
        <ListLowerRoman>
          <li>You are legally capable of entering into binding contracts; and</li>
          <li>You have the means to pay for Your Order; and</li>
          <li>You are at least 18 years old.</li>
        </ListLowerRoman>
      </li>
      <li>
        Acknowledgment and Agreement: You acknowledge and agree that:
        <ListLowerRoman>
          <li>
            Offense and Prohibition: It is an offense for any person under the local age limit to buy or
            attempt to buy intoxicating liquor. It is also an offense for any person over the local age limit
            to purchase intoxicating liquor on behalf of any person under the age of the local age limit.
          </li>
          <li>
            Order Restrictions: Orders containing alcohol cannot be accepted from or on behalf of persons
            under the local age limit.
          </li>
        </ListLowerRoman>
      </li>
    </ListLowerLatin>

    <h3>8. Your Order</h3>

    <ListLowerLatin>
      <li>
        Once You have selected the Products You wish to order from the Outlet and provided the other required
        information, You will be given the opportunity to submit Your Order by clicking or selecting the
        &quot;place my order&quot; button.
      </li>
      <li>Once You have submitted Your Order You will not be entitled to change or cancel it.</li>
      <li>
        Where any payment You make is not authorised, Your Order will not be processed or communicated to the
        relevant Outlet.
      </li>
      <li>
        Where any payment you make is authorised, We will send Your Order to the relevant Outlet and will
        notify You that Your Order has been received and is being processed.
      </li>
      <li>
        Such notification merely indicates that Your Order has been received and is being processed, and does
        not necessarily mean that the Outlet has accepted Your Order.
      </li>
      <li>The Outlet is free to accept or reject Your Order as it sees fit.</li>
      <li>
        We will notify You (generally by email) as soon as reasonably practicable if an Outlet rejects Your
        Order.
      </li>
      <li>
        In the event that an Outlet rejects Your Order neither the Outlet or We are required to inform You of
        the reason.
      </li>
    </ListLowerLatin>

    <h3>9. Status of Your Order</h3>

    <ListLowerLatin>
      <li>Your legal contract for the supply and purchase of Products is between You and the Outlet.</li>
      <li>We have no control over the actions or omissions of any Outlet.</li>
      <li>
        Accordingly, We do not give any undertaking or warranty about the Products ordered from any Outlet or
        their description and We disclaim any such warranties to the maximum extent permitted by law.
      </li>
    </ListLowerLatin>

    <h3>10. Price and Payment</h3>

    <ListLowerLatin>
      <li>
        Product prices will be as quoted on the Site to include VAT and unless stated otherwise exclude
        service charges or gratuities which You may add at Your discretion.
      </li>
      <li>
        On occasion, it is possible that the Site may contain an incorrect Product price. If the correct price
        is higher than that stated on the Site, We will normally contact You before the relevant Order is
        fulfilled. In such an event, neither We nor the relevant Outlet is under any obligation to ensure that
        the Order is provided to You at the incorrect lower price or to compensate You in respect of incorrect
        pricing. If We do not contact You in this way then this does not prevent Us from contacting You after
        Your Order has been processed and in any event You shall remain liable for the correct Price of the
        relevant Product.
      </li>
      <li>
        Payment for Orders must be made by an accepted credit or debit card through the Site or directly to
        the Outlet at the point of delivery to You.
      </li>
      <li>If You pay by credit or debit card, You may be required to show the card to the Outlet.</li>
      <li>
        A credit or discount may apply to Your Order if You use a promotional voucher or code and You pay for
        any balance by credit or debit card.
      </li>
    </ListLowerLatin>

    <h3>11. Intellectual property rights</h3>

    <ListLowerLatin>
      <li>
        You acknowledge that all intellectual property rights in the Site and the OPEG anywhere in the world
        belong to Us or Our licensors, that rights in the Site and the OPEG are licensed (not sold) to You,
        and that You have no rights in, or to, the Site and the OPEG other than the right to use the Site and
        the OPEG in accordance with the terms of this Agreement.
      </li>
      <li>You acknowledge that You have no right to have access to the OPEG in source-code form.</li>
    </ListLowerLatin>

    <h3>12. Limitation of liability</h3>

    <ListLowerLatin>
      <li>
        You acknowledge that the Site and or the OPEG has not been developed to meet Your individual
        requirements, and that it is therefore Your responsibility to ensure that the facilities and functions
        of the Site and or the OPEG meet Your requirements.
      </li>
      <li>
        We only supply the OPEG for domestic and private use. You agree not to use the OPEG for any
        commercial, business or resale purposes, and We have no liability to You for any loss of profit, loss
        of business, business interruption, or loss of business opportunity.
      </li>
      <li>
        Although We try to ensure that information on the Site and the OPEG is correct, We do not promise it
        is accurate or complete or up to date.
      </li>
      <li>
        Where an Outlet has provided Us with allergy, dietary and other Product information We try to
        accurately reproduce this on the Site and the OPEG. It is the responsibility of the Outlet to provide
        this to Us, and if You require any further information You should contact the Outlet directly before
        placing Your Order. Consequently we exclude all liability for any errors or omissions in such
        information.
      </li>
      <li>
        Our maximum aggregate liability under or in connection with this Agreement (including Your use of any
        Services) whether in contract, tort (including negligence) or otherwise, shall in all circumstances be
        limited to the price actually paid by You for the Services on the occasion You suffered a loss. This
        does not apply to the types of loss set out in condition f) below.
      </li>
      <li>
        Nothing in this Agreement shall limit or exclude Our liability for:
        <ListLowerRoman>
          <li>death or personal injury;</li>
          <li>fraud or fraudulent misrepresentation; and</li>
          <li>any other liability that cannot be excluded or limited by English law.</li>
        </ListLowerRoman>
      </li>
    </ListLowerLatin>

    <h3>13. Termination</h3>

    <ListLowerLatin>
      <li>
        We may terminate this Agreement immediately by written notice to You without specifying a reason.
      </li>
      <li>
        On termination for any reason:
        <ListLowerRoman>
          <li>all rights granted to You under this Agreement shall cease;</li>
          <li>
            You must immediately cease all activities authorised by this Agreement, including Your use of any
            Services;
          </li>
          <li>
            You must immediately delete or remove the Site and the OPEG from all Devices, and immediately
            destroy all copies of the Site and the OPEG in Your possession, custody or control and, if We
            request, certify to Us that You have done so;
          </li>
          <li>
            We may remotely access the Device and remove the Site and the OPEG from all of them and cease
            providing You with access to the Services.
          </li>
        </ListLowerRoman>
      </li>
    </ListLowerLatin>

    <h3>14. Communication between Us</h3>

    <p>
      If You wish to contact Us in writing, or if any condition in this Agreement requires You to give Us
      written notice, You can send this to Us by e-mail to{' '}
      <a href="mailto:info@wi-q.com" target="_blank">
        info@wi-q.com
      </a>{' '}
      or by prepaid post to Office Address: Number 3, Whitbread Business Centre, Whitbread Farm Lane, Chatham
      Green, Chelmsford, Essex CM3 3FE.
    </p>

    <ListLowerLatin>
      <li>We will confirm receipt of this by contacting You in writing, normally by e-mail.</li>
      <li>
        If We have to contact You or give You notice in writing, We will do so by e-mail or by post to the
        address You provide to Us in Your request for the OPEG.
      </li>
    </ListLowerLatin>

    <h3>15. Events outside Our control</h3>

    <ListLowerLatin>
      <li>
        We will not be liable or responsible for any failure to perform, or delay in performance of, any of
        Our obligations under this Agreement that is caused by any act or event beyond Our reasonable control,
        including but not limited to failure of public or private telecommunications networks, public utility
        providers, or the internet (an "Event Outside Our Control").
      </li>
      <li>
        If an Event Outside Our Control takes place that affects the performance of Our obligations under this
        Agreement:
        <ListLowerRoman>
          <li>
            Our obligations under this Agreement will be suspended and the time for performance of Our
            obligations will be extended for the duration of the Event Outside Our Control; and{' '}
          </li>
          <li>
            We will use Our reasonable endeavors to find a solution by which Our obligations under this
            Agreement may be performed despite the Event Outside Our Control.
          </li>
        </ListLowerRoman>
      </li>
    </ListLowerLatin>

    <h3>16. Other important terms</h3>

    <ListLowerRoman>
      <li>
        We may transfer Our rights and obligations under this Agreement to another organisation, but this will
        not affect Your rights or Our obligations under this Agreement.
      </li>
      <li>
        You may only transfer Your rights or obligations under this Agreement to another person if We agree in
        writing.
      </li>
      <li>
        If We fail to insist that You perform any of Your obligations under this Agreement, or if We do not
        enforce Our rights against You, or if We delay in doing so, that will not mean that We have waived Our
        rights against You and will not mean that You do not have to comply with those obligations. If We do
        waive a default by You, We will only do so in writing, and that will not mean that We will
        automatically waive any later default by You.
      </li>
      <li>
        Each of the conditions of this Agreement operates separately. If any court or competent authority
        decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full
        force and effect.
      </li>
      <li>
        This agreement and the documents and policies referred to in it constitute the whole agreement and
        understanding between You and Us and supersede and replace any previous arrangement, understanding or
        agreement between You and Us relating to its subject matter.
      </li>
      <li>
        This Agreement, its subject matter and its formation, are governed by English law. You and We both
        agree that the courts of England and Wales will have exclusive jurisdiction to settle any dispute
        relating to this Agreement including its formation.
      </li>
    </ListLowerRoman>

    <h3 id="privacyPolicy">OUR PRIVACY NOTICE</h3>

    <p>
      Welcome to wi-Q's Privacy Notice. This Privacy Notice provides information about how we collect and
      process your personal data when you visit our website or use our service. Please read the following
      carefully to understand our views and practices regarding your personal data and how we will treat it.
    </p>

    <p>
      We are wi-Q Limited, and we are the data controller in respect to any personal data we collect or
      process when you visit our website or use our service.
    </p>

    <p>
      We are part of a wider corporate group. We may disclose your personal information to any member of our
      group, which means our subsidiaries, our ultimate holding company and its subsidiaries.
    </p>

    <h3>CONTACT</h3>

    <p>
      Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to{' '}
      <a href="mailto:info@wi-q.com">info@wi-q.com</a>
    </p>

    <h3>INFORMATION WE MAY COLLECT FROM YOU</h3>

    <p>We may collect and process the following data about You:</p>

    <ul>
      <li>
        Submitted Information: information that You provide by filling in forms on the OPEG. This includes
        information provided at the time of registering to use the OPEG, subscribing to any of the Services,
        posting material or requesting further services. It excludes Your payment information which is not
        collected or processed by Us.
      </li>
      <li>Additional information:</li>
      <ul>
        <li>
          details of transactions You carry out through the OPEG and of the fulfilment of any orders you place
          via our website;
        </li>
        <li>
          details of Your visits to the OPEG including, but not limited to, traffic data, location data, dates
          and times, frequency of access, weblogs and other communication data, and the resources that You
          access;
        </li>
      </ul>
      <li>
        Device information: We may collect information about the Device including, where available, the
        Device's or computer's unique Device identifiers, operating system, browser type and mobile network
        information as well as the Devices telephone number. We may associate Device information with
        Submitted Information and will treat the combined information as personal data in accordance with this
        policy for as long as it is combined.
      </li>
      <li>
        Location information: when You use one of Our location-enabled Services, We may collect and process
        information about Your actual location.
      </li>
      <li>
        Log information: when You use the Services, We may automatically collect and store certain information
        in server logs, including but not limited to internet protocol (IP) addresses, internet service
        provider (ISP), clickstream data, browser type and language, viewed and exit pages and date or time
        stamps.
      </li>
    </ul>

    <p>
      We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.
      Aggregated Data could be derived from your personal data but is not considered personal data in law as
      this data will not directly or indirectly reveal your identity. For example, we may aggregate your usage
      data to calculate the percentage of users accessing a specific website feature. However, if we combine
      or connect Aggregated Data with your personal data so that it can directly or indirectly identify you,
      we treat the combined data as personal data which will be used in accordance with this privacy policy.
    </p>

    <h3>Cookies</h3>

    <p>
      Wi-Q is a cloud-based mobile ordering platform ( Platform ) that allows you to: (i) access a trader's
      menu; (ii) order food and drinks; and (iii) pay for your order using a selection of payment methods, all
      using your mobile device, without the need to download an app.
    </p>

    <p>
      This Cookies Policy explains: (i) what cookies are; (ii) what cookies we use and why; and (iii) how you
      can manage your cookies on our website{' '}
      <a href="https://www.wi-q.com" target="_blank">
        www.wi-q.com
      </a>
      . Where any information we collect through cookies on our Website constitutes personal data, our Privacy
      Notice (available here{' '}
      <a href="https://www.wi-q.com/privacy-policy/" target="_blank">
        www.wi-q.com/privacy-policy
      </a>
      ) applies to the collection and use of that data.
    </p>

    <p>
      This Cookies Policy only applies when you access and use our Website. We have a separate privacy notice
      and cookies policy for when you use our Website which you can access here{' '}
      <a href="https://www.wi-q.com/privacy-policy/" target="_blank">
        www.wi-q.com/privacy-policy
      </a>
      .
    </p>

    <ol>
      <li>
        <strong>What are cookies?</strong> Cookies are small files of letters and numbers that are stored on
        your computer or mobile device. They help us distinguish you from other users of our Website and to
        improve your user experience and our service.
      </li>
      <li>
        <strong>What cookies do we use and why? We use the following cookies:</strong>
        <ul>
          <li>
            <strong>Analytical or performance cookies.</strong> These allow us to recognise and count the
            number of visitors and to see how visitors move around our Website when they are using it. This
            helps us to improve the way our Website works, for example, by ensuring that users are finding
            what they are looking for easily
          </li>
          <li>
            <strong>Functionality cookies.</strong> These are used to improve the functionality of our
            Website, for example to remember your cookies choices or to ensure that the Website is displayed
            in your chosen language
          </li>
        </ul>
        <p>
          You can find out more information about the individual cookies we use and the purposes for which we
          use them in the list below:
        </p>

        <ul>
          <li>
            <strong>acceptCookies & catAccCookies.</strong> These are functionality cookies used to remember
            your choice about cookies on the Website. Where you have previously indicated a preference, your
            preference will be stored using these cookies. They expire in 6 months.
          </li>
          <li>
            <strong>googletrans.</strong> This is functionality cookie used to remember your chosen language
            for the Website. It expires a the end of the session.
          </li>
          <li>
            <strong>cfduid.</strong> This is performance cookie used to identify individual clients of wi-Q
            behind a shared internet protocol address so that we can apply security settings on a
            client-by-client basis. It expires in four weeks.
          </li>
        </ul>
      </li>
      <li>
        <strong>Third party cookies.</strong> In common with most websites, wi-Q uses certain third party
        cookies to capture certain information about its Website's use, including:
        <ul>
          <li>
            <strong>Analytics cookies:</strong> wi-Q uses these (namely Google Analytics) to distinguish
            between users and understand how the Website is being used. These measure the number of visitors
            and tell wi-Q how users navigate the Website; and
          </li>
          <li>
            <strong>YouTube:</strong> to play video content imbedded on the Website, YouTube set analytics
            cookies to measure performance.
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>How you can manage your cookies.</strong> When you first access the Website, you will be
          given the option to accept or reject functionality, performance and third party cookies which you
          can also do on your settings page.
        </p>
        <p>
          You can also refuse our use of all cookies, including cookies that are necessary to be remembered by
          specific services of our Website, simply by adjusting your browser settings. Blocking cookies in
          specific browsers:
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?hl=en" target="_blank">
              Enable or disable cookies
            </a>
            - Accounts Help (Google)
          </li>
          <li>
            <a href="http://windows.microsoft.com/en-gb/windows-vista/block-or-allow-cookies" target="_blank">
              Block or allow cookies
            </a>
            – (Windows – Microsoft)
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              target="_blank"
            >
              Enable and disable cookies that websites use to track your preferences
            </a>
            (Firefox)
          </li>
          <li>
            <a href="https://support.apple.com/en-gb/HT201265" target="_blank">
              Safari web settings on your iPhone, iPad, and iPod touch
            </a>
            (Safari)
          </li>
          <li>
            <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">
              Google Analytics Opt-Out Browser Add-On
            </a>
            (Google)
          </li>
        </ul>

        <p>
          Information regarding cookie settings on other browsers is easily accessible via a simple web
          search. If you are having problems however, please contact us. For general information about cookies
          and how to disable them, please visit{' '}
          <a href="https://www.allaboutcookies.org/" target="_blank">
            www.allaboutcookies.org
          </a>
          .
        </p>
      </li>
      <li>
        <strong>Contact us about cookies.</strong> If you have any questions about our use of cookies on our
        Platform or Website, please contact us by emailing{' '}
        <a href="mailto:info@wi-q.com" target="_blank">
          info@wi-q.com
        </a>
        .
      </li>
    </ol>

    <h3>HOW WE USE YOUR PERSONAL DATA</h3>

    <p>
      We will only use your personal data when the law allows us to. Most commonly, we will use your personal
      data in the following circumstances:
    </p>

    <ul>
      <li>where we need to perform the contract we are about to enter into or have entered into with you;</li>
      <li>
        where it is necessary for our legitimate interests (or those of a third party) and your interests and
        fundamental rights do not override those interests;
      </li>
      <li>where we need to comply with a legal obligation.</li>
    </ul>

    <p>
      Generally, we do not rely on consent as a legal basis for processing your personal data although we will
      get your consent before sending third party direct marketing communications to you via email or text
      message. You have the right to withdraw consent to marketing at any time by contacting us.
    </p>

    <h3>WHERE WE STORE YOUR PERSONAL DATA</h3>

    <p>
      The data that we collect from you may be transferred to, and stored at, a destination outside the
      European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for
      us or for one of our suppliers. These staff may be engaged in the fulfilment of your request, order or
      reservation, the processing of your payment details and the provision of support services. We will take
      all steps reasonably necessary to ensure that your data is treated securely and in accordance with this
      privacy notice and in accordance with our obligations when transferring data internationally.
    </p>

    <p>
      Any payment transactions carried out by a third-party provider of payment processing services will be
      encrypted. Where we have given you (or where you have chosen) a password that enables you to access
      certain parts of our website, you are responsible for keeping this password confidential. We ask you not
      to share a password with anyone.
    </p>

    <p>
      Unfortunately, the transmission of information via the internet is not completely secure. Although we
      will do our best to protect your data, we cannot guarantee the security of the data transmitted to us.
      Once we have received your information, we will use strict procedures and security features to try to
      prevent unauthorised access.
    </p>

    <h3>USES MADE OF THE INFORMATION</h3>

    <p>We use information held about You in the following ways:</p>

    <ul>
      <li>to process your order;</li>
      <li>to enhance your user experience;</li>
      <li> to manage your relationship with us;</li>
      <li>to improve our website, services, marketing or customer relationships;</li>
      <li>to recommend products or services which may be of interest to you; and</li>
      <li>for direct marketing</li>
    </ul>

    <h3>DISCLOSURE OF YOUR INFORMATION</h3>

    <p>We may disclose Your personal information to third parties:</p>

    <ul>
      <li>
        In the event that We sell or buy any business or assets, in which case We may disclose Your personal
        data to the prospective seller or buyer of such business or assets.
      </li>
      <li>
        If We or substantially all of Our assets are acquired by a third party, in which case personal data
        held by it about its customers will be one of the transferred assets.
      </li>
      <li>
        If We are under a duty to disclose or share Your personal data in order to comply with any legal or
        regulatory obligation or request.
      </li>
      <li>
        If you consent to Your personal information being used by third parties for the purposes or marketing.
      </li>
    </ul>

    <h3>HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</h3>

    <p>
      If you check out as a guest on our website we will retain data for 6 months from the date of your
      transaction. If you set up an account with us, we will retain your data for as long as the account is
      open and for 6 months after the date the account is closed.{' '}
    </p>

    <p>
      In some circumstances we will anonymise your personal data (so that it can no longer be associated with
      you) for research or statistical purposes, in which case we may use this information indefinitely
      without further notice to you.
    </p>

    <h3>YOUR RIGHTS</h3>

    <p>
      You have the right to ask Us not to process Your personal data for marketing purposes. You can exercise
      Your right to prevent such processing by checking certain boxes on the forms We use to collect Your
      data. You can also exercise the right at any time by contacting Us at
      <a href="mailto:help@wi-q.com" target="_blank">
        help@wi-q.com
      </a>
      .
    </p>

    <p>
      The website may, from time to time, contain links to and from the websites of Our partner networks,
      advertisers and affiliates. If You follow a link to any of these websites, please note that these
      websites and any services that may be accessible through them have their own privacy policies and that
      We do not accept any responsibility or liability for these policies or for any personal data that may be
      collected through these websites or services, such as contact and location data. Please check these
      policies before You submit any personal data to these websites or use these services.
    </p>

    <h3>ACCESS TO INFORMATION</h3>

    <p>
      The General Data Protection Regulation (GDPR) gives you the right to access information held about you,
      as well as rights to: rectification; erasure of personal data; restriction of processing concerning you;
      object to our processing of the data; data portability. Your rights can be exercised in accordance with
      the GDPR and the provisions of the Data Protection Act 2018, but it is worth noting that there may be
      circumstances under which we are not obliged to comply with a request
    </p>

    <p>
      You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
      However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
      Alternatively, we could refuse to comply with your request in these circumstances.
    </p>

    <h3>CHANGES TO PRIVACY POLICY</h3>

    <p>
      Any changes we may make to our privacy notice in the future will be posted on this page and, where
      appropriate, notified to you by SMS or e-mail or notifying you of a change when you next start the
      website. The new terms may be displayed on-screen and you may be required to read and accept them to
      continue your use of the App or the website.
    </p>
  </ContentArea>
);

export default TermsAndConditions;
