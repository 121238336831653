import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openAddAddressAlert } from 'actions/UI';
import ActionButton from 'components/ActionButton';
import settingsMessages from 'components/settingsMessages';

type Props = {
  openAddAddressAlert?: () => void;
  route?: string;
};

const AddAddressButton = ({ openAddAddressAlert, route }: Props) => {
  const handleOnClick = () => openAddAddressAlert();

  return (
    <ActionButton
      to={route}
      buttonType="button"
      onClick={route ? () => {} : handleOnClick}
      label={<FormattedMessage {...settingsMessages.addAddressTitle} />}
    />
  );
};

export default connect(null, { openAddAddressAlert })(AddAddressButton);
