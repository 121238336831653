import styled from 'styled-components';
import Loading from 'components/Loading/index';
import Dropdown from 'components/Dropdown';

export const LoadingDevices = styled(Loading)`
  position: relative;
`;

export const StyledDropdown = styled(Dropdown)`
  text-align: left;
`;
