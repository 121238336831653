import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CartIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M171.216,229.726c-4.43,0-8.118-1.535-11.276-4.692c-3.157-3.157-4.692-6.846-4.692-11.276s1.535-8.119,4.692-11.276 c3.158-3.157,6.847-4.692,11.276-4.692c2.108,0,4.184,0.436,6.168,1.295c2.007,0.872,3.726,2.015,5.108,3.397 s2.525,3.102,3.398,5.11c0.858,1.984,1.294,4.06,1.294,6.166c0,4.428-1.535,8.116-4.693,11.276 C179.331,228.19,175.643,229.726,171.216,229.726z M106.528,229.726c-4.43,0-8.119-1.535-11.276-4.692s-4.692-6.846-4.692-11.276 c0-2.92,0.702-5.604,2.086-7.977c1.411-2.419,3.311-4.316,5.809-5.803c2.444-1.453,5.161-2.189,8.074-2.189    c1.189,0,2.404,0.136,3.611,0.402c1.175,0.263,2.307,0.662,3.36,1.186c1.063,0.534,2.084,1.181,3.027,1.918    c0.911,0.718,1.744,1.551,2.47,2.472c0.733,0.937,1.379,1.957,1.917,3.027c0.52,1.045,0.919,2.178,1.184,3.358 c0.265,1.201,0.4,2.416,0.4,3.605c0,4.428-1.535,8.116-4.693,11.276C114.644,228.19,110.955,229.726,106.528,229.726z M93.276,179 c-1.06,0-2.021-0.135-2.937-0.84c-0.942-0.719-1.557-1.496-1.885-2.591L48.712,29.674c-0.489-1.793-1.083-3.283-1.763-4.48 c-0.778-1.354-1.584-2.267-2.47-2.825c-0.72-0.446-1.296-0.778-1.774-0.972C42.07,21.147,41.409,21,40.718,21H9.946    c-0.874,0-1.705-0.185-2.539-0.631c-0.775-0.412-1.35-0.97-1.759-1.741c-0.448-0.839-0.665-1.661-0.665-2.535 c0-1.559,0.47-2.738,1.436-3.705C7.385,11.422,8.539,11,9.946,11h30.771c1.317,0,2.667,0.125,4.01,0.459 c1.333,0.335,2.964,0.977,4.841,1.947c1.723,0.895,3.411,2.488,5.013,4.757c1.659,2.352,2.999,5.264,3.981,8.629L70.666,68h20.661 l45.764-39.635l0.051-0.06c1.673-1.672,3.585-2.784,5.684-3.293c1.074-0.259,2.153-0.393,3.206-0.393 c1.048,0,2.104,0.129,3.139,0.387c2.023,0.507,3.903,1.615,5.584,3.292l0.119,0.127L201.478,68h23.195 c1.742,0,3.042,0.676,4.093,2.167c1.066,1.518,1.301,3.059,0.736,4.869l-34.35,101.022c-0.317,0.951-0.919,1.517-1.844,2.171 c-0.936,0.659-1.918,0.771-3.001,0.771H93.276z M97.352,169h89.455l30.312-91H73.384L97.352,169z M106.251,68h79.365 l-39.553-33.827L106.251,68z"
      />
    </g>
  </svg>
);

CartIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CartIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CartIcon;
