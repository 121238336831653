import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  defaultTab: PropTypes.bool,
  setContent: PropTypes.func,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.node,
};

const defaultProps = {
  onClick: () => {},
};

const BaseTab = ({ children, content, defaultTab, setContent, selected, onClick, className, label }) => {
  useEffect(() => {
    if (children && !content && defaultTab) {
      setContent(children);
    }
  }, []);

  const active = typeof selected === 'undefined' ? content === children : selected;
  const clickHandler = () => {
    onClick();
    setContent(children);
  };

  return (
    <li onClick={clickHandler} className={active ? className : ''}>
      {label}
    </li>
  );
};

BaseTab.propTypes = propTypes;
BaseTab.defaultProps = defaultProps;

export default BaseTab;
