import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import OfferList from 'components/Loyalty/OfferList';
import { RewardIcon } from 'components/Icons';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { useLoyalty } from 'hooks';

import Page from '../container';
import ComoIcon from '../../Icons/ComoIcon';

const StyledRewardsPage = styled.div`
  padding: 20px;
`;

const StyledRewardsText = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const messages = defineMessages({
  pageTitle: {
    defaultMessage: 'Rewards',
  },
});

const RewardsPage: React.FC = () => {
  const { type, offers, offersLoading: loading, redeemOffer } = useLoyalty();

  let icon = RewardIcon;

  if (type === 'como') {
    icon = ComoIcon;
  }

  return (
    <Page titleMessage={messages.pageTitle} settingsPage={false} Icon={icon}>
      <StyledRewardsPage>
        {type === 'como' && (
          <StyledRewardsText>
            <FormattedMessage defaultMessage="To redeem a reward, make sure you have added the corresponding item to the basket first." />
          </StyledRewardsText>
        )}
        {loading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {!loading && <OfferList offers={offers} onRedeem={redeemOffer} />}
      </StyledRewardsPage>
    </Page>
  );
};

export default RewardsPage;
