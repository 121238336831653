import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MolluscIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon molluscIcon"
        fill={fill}
        opacity={opacity}
        d="M308.1,915.5V783.1L17,579.7v-12.1c0-129,50.2-250.3,141.5-341.5C249.7,134.8,371,84.5,500,84.5S750.3,134.8,841.5,226	C932.8,317.2,983,438.5,983,567.5v12.1L691.9,783.1v132.4H308.1z M354.7,868.9h290.6v-74.6H354.7V868.9z M620,747.6h41.2l275-192.1	l-0.1-3.8c-1.8-49.2-11.7-97.3-29.6-142.9c-17.3-44.2-41.7-85.1-72.4-121.6l-7.2-8.6L620,747.6z M523.3,747.6h45.8l222.5-504.6	l-3.9-3.4c-35.2-30.9-74.8-55.9-117.8-74.1c-44.2-18.8-91-30-139.1-33.4l-7.5-0.5V747.6z M469.2,132.2	c-48.1,3.4-94.9,14.6-139.1,33.4c-42.9,18.2-82.6,43.1-117.8,74.1l-3.9,3.4l222.5,504.6h45.8V131.7L469.2,132.2z M165.9,287.1	c-30.7,36.5-55.1,77.4-72.4,121.6c-17.9,45.6-27.9,93.7-29.6,142.9l-0.1,3.8l275,192.1h41.2L173.1,278.5L165.9,287.1z"
      />
    </g>
  </svg>
);

MolluscIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MolluscIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default MolluscIcon;
