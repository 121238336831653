import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.color.secondaryBackground};
  color: ${({ theme }) => theme.color.primaryText};
  border-radius: 24px;
  width: 100%;
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  text-align: center;
`;

const button = css`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: #fff;
`;

export const MinusButton = styled.button`
  ${button}
  background: ${({ theme }) => theme.buttons.quantityMinus};
  float: left;
`;

export const PlusButton = styled.button`
  ${button}
  background: ${({ theme }) => theme.buttons.quantityPlus};
  float: right;
`;

export const Quantity = styled.div`
  text-align: center;
  display: inline-block;
  line-height: 38px;
  font-size: 1.25em;
`;

export const HiddenQuantityText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
