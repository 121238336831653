import styled from 'styled-components';

export const ButtonText = styled.span`
  text-transform: capitalize;
`;

export const FormContainer = styled.div`
  @media (min-width: 768px) {
    width: 65%;
    float: left;
  }
`;

export const SummaryContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 35%;
    float: left;
    padding: 5px 0 0 10px;
    height: ${props => `${props.formHeight}px` || '100vh'};
  }
`;

export const InnerContainer = styled.div`
  @media (min-width: 768px) {
    position: sticky;
    top: 58px;
  }
`;

export const LoadingOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  backdrop-filter: blur(5px); /* apply background blur */
  z-index: 999; /* ensure it's above other content */
`;

export const LoadingMessage = styled.div`
  color: white;
  font-size: 24px;
`;

export const ButtonContainer = styled.div`
  max-width: 300px;
`;
