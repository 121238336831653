import React from 'react';
import { defineMessages } from 'react-intl';

import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  header: {
    defaultMessage: 'Thank You',
  },
  message: {
    defaultMessage: 'Thank you for taking the time to leave feedback.',
  },
  confirm: {
    defaultMessage: 'Return to Menu',
  },
});

export default props => <StandardAlert {...props} messages={messages} config={{ buttons: 1 }} />;
