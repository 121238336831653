import styled, { keyframes } from 'styled-components';
import ActionButton from 'components/ActionButton';
import { RefreshIcon } from 'components/Icons';

export const OrderContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ButtonLabel = styled.span`
  text-transform: capitalize;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

export const RefreshIconStyled = styled(RefreshIcon)`
  transform-origin: 50% 50%;
  animation-name: ${spin};
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const SyncLabel = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 1;
  color: #999;
  padding: 15px;

  &:lang(ru) {
    font-size: 19px;
  }
`;

export const RetryButton = styled(ActionButton)`
  color: ${({ theme }) => theme.color.danger};
  border-color: ${({ theme }) => theme.color.danger};
`;
