/**
 * answersTransformer
 *
 * Maps the answer indexes to the actual answer values from the question.
 *
 * Loops through all answers, gets the question from the questions object
 * passed in and if the question type is multiple choice, then gets the
 * actual answer values based on the answer index and adds it to result
 * object. Default case assigns value as-is to result object.
 *
 * Returns result object.
 *
 * @param questions
 * @param answers
 * @returns {{}}
 */

const answersTransformer = (questions, answers) => {
  const result = {};

  Object.keys(answers).forEach(questionId => {
    const question = questions.find(question => question.id === parseInt(questionId, 10));

    if (question.type === 'multiple_choice') {
      result[questionId] = answers[questionId].map(answerIndex => question.choices[answerIndex]);
    } else {
      result[questionId] = answers[questionId];
    }
  });

  return result;
};

export default answersTransformer;
