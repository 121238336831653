import React, { ReactNode } from 'react';

import { Label } from 'assets/styles/sharedStyles';
import Select from 'components/Select';
import convertOptions from './convertOptions';
import { Container } from './styles';

export const generateDropdownOptions = options =>
  options.map(option => ({
    label: option.get('name'),
    value: option.get('id'),
  }));

type Props = {
  className?: string;
  options?: [] | {};
  onChange?: (val: string | number) => void;
  onFocus?: (val: string | number) => void;
  onBlur?: (val: string | number) => void;
  searchable?: boolean;
  haserror?: string;
  required?: boolean;
  isLoading?: boolean;
  label: ReactNode;
};

const Dropdown = (props: Props) => (
  <Container className={props.className}>
    {props.label && (
      <Label
        htmlFor="dropdown-select"
        className="control-label"
        required={props.required}
        haserror={props.haserror}
      >
        {props.label}
      </Label>
    )}

    <Select
      id="dropdown-select"
      {...props}
      options={convertOptions()(props.options)}
      setValueOnClick={props.onChange}
      onBlur={props.onBlur}
    />
  </Container>
);

export default Dropdown;
