import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';

import IntlApp from './intlApp';

const App = ({ store, children }) => (
  <Provider store={store}>
    <IntlApp>{children}</IntlApp>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object,
  children: PropTypes.object,
};

export default hot(App);
