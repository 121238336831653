import React from 'react';
import PropTypes from 'prop-types';

const MaestroCardIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 72 45.75"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999">
        <rect fill="none" stroke="none" width="70" height="43.75" rx="4" />
        <rect className="g" x="-0.5" y="-0.5" width="71" height="44.75" rx="4.5" />
      </g>
      <g transform="translate(14.084 9.312)">
        <path
          fill="#ec1c2e"
          d="M590.2,170.453l.649-.017.639-.049.628-.079.619-.111.611-.14.6-.17.582-.2.574-.228.557-.252.541-.277.528-.3.509-.33.495-.351.475-.371.459-.4.437-.417.412-.438.4-.458.376-.474.352-.491.326-.512.3-.529.28-.541.252-.557.223-.574.2-.582.17-.6.141-.607.111-.623.078-.628.049-.64.017-.648-.017-.644-.049-.636-.078-.623-.111-.619-.141-.6-.17-.594-.2-.583-.223-.57-.252-.553-.28-.542-.3-.525-.326-.512-.352-.491-.376-.475-.4-.454-.412-.437-.437-.413-.459-.4-.475-.372-.495-.351-.509-.326-.528-.3-.541-.281-.557-.251-.574-.223-.582-.2-.6-.169-.611-.14-.619-.112-.628-.078-.639-.049-.649-.017-.648.017-.64.049-.628.078-.62.112-.611.14-.595.169-.586.2-.569.223-.557.251-.541.281-.525.3-.511.326-.492.351-.475.372-.454.4-.434.413-.417.437-.4.454-.371.475-.35.491-.326.512-.3.525-.277.542-.247.553-.227.57-.2.583-.165.594-.14.6-.112.619-.078.623-.05.636-.016.644.016.648.05.64.078.628.112.623.14.607.165.6.2.582.227.574.247.557.277.541.3.529.326.512.35.491.371.474.4.458.417.438.434.417.454.4.475.371.492.351.511.33.525.3.541.277.557.252.569.228.586.2.595.17.611.14.62.111.628.079.64.049Z"
          transform="translate(-560.956 -145.326)"
        />
        <path
          fill="#0084c4"
          d="M560.325,170.453l.648-.017.64-.049.628-.079.619-.111.611-.14.6-.17.586-.2.57-.228.558-.252.54-.277.524-.3.512-.33.491-.351.475-.371.455-.4.437-.417.414-.438.4-.458.371-.474.351-.491.326-.512.3-.529.276-.541.252-.557.224-.574.2-.582.166-.6.14-.607.111-.623.078-.628.049-.64.017-.648-.017-.644-.049-.636-.078-.623-.111-.619-.14-.6-.166-.594-.2-.583-.224-.57-.252-.553-.276-.542-.3-.525-.326-.512-.351-.491-.371-.475-.4-.454-.414-.437-.437-.413-.455-.4-.475-.372-.491-.351-.512-.326-.524-.3-.54-.281-.558-.251-.57-.223-.586-.2-.6-.169-.611-.14-.619-.112-.628-.078-.64-.049-.648-.017-.648.017-.64.049-.628.078-.619.112-.611.14-.6.169-.582.2-.573.223-.557.251-.541.281-.528.3-.508.326-.5.351-.474.372-.459.4-.433.413-.417.437-.4.454-.375.475-.352.491-.326.512-.3.525-.28.542-.252.553-.223.57-.2.583-.169.594-.141.6-.111.619-.078.623-.05.636-.016.644.016.648.05.64.078.628.111.623.141.607.169.6.2.582.223.574.252.557.28.541.3.529.326.512.352.491.375.474.4.458.417.438.433.417.459.4.474.371.5.351.508.33.528.3.541.277.557.252.573.228.582.2.6.17.611.14.619.111.628.079.64.049Z"
          transform="translate(-547.741 -145.326)"
        />
        <path fill="#ec1c2e" d="M582.446,153.345h6.37v-.7h-5.755Z" transform="translate(-563.078 -148.559)" />
        <path fill="#ec1c2e" d="M580.655,156.011h7.37v-.7H581.1Z" transform="translate(-562.286 -149.739)" />
        <path
          fill="#ec1c2e"
          d="M579.315,158.715h8.118v-.7h-7.774Z"
          transform="translate(-561.694 -150.933)"
        />
        <path fill="#ec1c2e" d="M578.444,161.382h8.6v-.7h-8.389Z" transform="translate(-561.309 -152.113)" />
        <path fill="#ec1c2e" d="M577.873,164.049H586.8v-.7H578Z" transform="translate(-561.057 -153.29)" />
        <path fill="#ec1c2e" d="M577.706,169.22h8.988v-.7h-9.05Z" transform="translate(-560.956 -155.575)" />
        <path fill="#ec1c2e" d="M578.038,172.039h8.773v-.7h-8.9Z" transform="translate(-561.073 -156.821)" />
        <path
          fill="#ec1c2e"
          d="M578.732,174.326h8.348v-.7h-8.563Z"
          transform="translate(-561.341 -157.831)"
        />
        <path fill="#ec1c2e" d="M579.773,177.107h7.713v-.7h-8.051Z" transform="translate(-561.747 -159.06)" />
        <path fill="#ec1c2e" d="M581.274,179.926h6.816v-.7H580.8Z" transform="translate(-562.352 -160.306)" />
        <path
          fill="#ec1c2e"
          d="M583.171,182.633H588.8v-.7h-6.247Z"
          transform="translate(-563.127 -161.502)"
        />
        <g transform="translate(39.752 19.768)">
          <path
            fill="#ec1c2e"
            d="M618.992,181.235l.037-.112.062-.1.079-.091.091-.075.1-.057.112-.037.12-.012.123.012.116.037.1.057.091.075.074.091.058.1.038.112h-.128l-.026-.078-.044-.087-.058-.074-.07-.058-.083-.045-.095-.029-.1-.009-.1.009-.1.029-.082.045-.074.058-.059.074-.045.087-.025.078Z"
            transform="translate(-618.98 -180.748)"
          />
          <path
            fill="#ec1c2e"
            d="M619.688,181.5v-.149h.235l.053,0,.045,0,.042.013.033.016.025.021.02.025.013.029,0,.038h-.108l0-.021-.008-.017-.013-.016-.016-.012-.021-.008-.02,0-.021,0h-.153v.083Z"
            transform="translate(-619.287 -181.016)"
          />
          <path
            fill="#ec1c2e"
            d="M618.977,181.728l.008-.1v0h.124l0,.02-.009.087Z"
            transform="translate(-618.973 -181.134)"
          />
          <path
            fill="#ec1c2e"
            d="M619.688,181.728v-.107h.107v.086h.178l.024,0,.021-.008.013-.008.012-.016.008-.021v-.029h.108l0,.037-.009.033-.012.024-.013.013Z"
            transform="translate(-619.287 -181.134)"
          />
          <path
            fill="#ec1c2e"
            d="M620.935,181.728l-.008-.087,0-.02h.128v0l.012.1Z"
            transform="translate(-619.833 -181.134)"
          />
          <path
            fill="#ec1c2e"
            d="M618.974,181.814l0,.02.012.124.037.116.062.1.079.091.091.07.1.054.112.032.12.013.123-.013.116-.032.1-.054.091-.07.074-.091.058-.1.038-.116.012-.124v-.02h-.128v.02l-.008.108-.029.1-.044.087-.058.074-.07.062-.083.046-.095.029-.1.008-.1-.008-.1-.029-.082-.046-.074-.062-.059-.074-.045-.087-.029-.1-.009-.108v-.02Z"
            transform="translate(-618.97 -181.219)"
          />
          <path
            fill="#ec1c2e"
            d="M619.688,181.814v.256h.107v-.21h.108l.128.21h.108l-.153-.21.037,0,.037,0,.029-.012.025-.016.008-.008Z"
            transform="translate(-619.287 -181.219)"
          />
        </g>
        <path
          fill="#fff"
          d="M591.334,164.739l-.082,0-.082-.008-.075-.013-.074-.007-.075-.013-.074-.012-.078-.012-.083-.012-.086-.009-.092-.008-.1-.008-.112,0h-.123l-.136,0-.153.008-.165.013-.12.017-.111.025-.1.033-.091.037-.083.045-.074.05-.062.057-.049.058-.037.066-.025.07-.013.07v.074l.017.078.029.079.045.078.058.078.12.108.14.087.166.074.178.062.186.062.194.062.2.07.19.083.178.1.17.12.148.153.124.181.095.223.062.265.021.313-.021.372-.046.277-.075.248-.094.223-.12.2-.14.174-.153.149-.169.128-.181.111-.19.091-.2.074-.2.063-.2.045-.2.033-.2.021-.189.012-.186,0h-.257l-.23,0-.211,0-.194,0-.178-.008-.157-.009-.148-.012-.133-.017-.123-.017-.116-.021-.108-.02-.107-.029-.1-.029-.1-.029-.1-.037-.1-.037.256-1.3.058.013.07.02.087.021.1.025.111.021.124.029.132.024.14.025.149.021.157.021.161.017.169.016.169.008.173,0h.174l.177-.008.165-.029.136-.045.1-.066.082-.074.062-.087.046-.09.037-.091.024-.091.013-.1,0-.083-.021-.078-.034-.066-.045-.066-.058-.058-.071-.054-.078-.05-.087-.045-.094-.046-.1-.045-.108-.041-.107-.042-.115-.046-.116-.046-.12-.049-.116-.05-.115-.054-.116-.062-.112-.066-.107-.071-.1-.078-.094-.091-.087-.095-.078-.108-.071-.116-.058-.128-.049-.136-.034-.153-.021-.165,0-.181.013-.194.012-.12.017-.116.017-.111.024-.108.025-.1.029-.1.037-.095.037-.095.045-.087.046-.086.054-.079.058-.078.062-.074.066-.071.07-.066.078-.062.083-.058.087-.054.095-.049.1-.05.1-.041.111-.041.117-.033.123-.034.133-.029.136-.025.14-.021.149-.017.156-.013.165-.008.17-.008h.4l.2,0,.178,0,.165.008.145.008.133.008.119.013.1.008.1.013.082.012.079.008.065.013.059.008.054.008.049.008.046,0Z"
          transform="translate(-564.807 -153.257)"
        />
        <g transform="translate(21.552 10.017)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M591.334,164.739h0l-.082,0-.082-.008-.075-.013-.074-.007-.075-.013-.074-.012-.078-.012-.083-.012-.086-.009-.092-.008-.1-.008-.112,0h-.123l-.136,0-.153.008-.165.013h0l-.12.017-.111.025-.1.033-.091.037-.083.045-.074.05-.062.057-.049.058-.037.066-.025.07-.013.07v.074l.017.078.029.079.045.078.058.078h0l.12.108.14.087.166.074.178.062.186.062.194.062.2.07.19.083.178.1.17.12.148.153.124.181.095.223.062.265.021.313-.021.372h0l-.046.277-.075.248-.094.223-.12.2-.14.174-.153.149-.169.128-.181.111-.19.091-.2.074-.2.063-.2.045-.2.033-.2.021-.189.012-.186,0h-.257l-.23,0-.211,0-.194,0-.178-.008-.157-.009-.148-.012-.133-.017-.123-.017-.116-.021-.108-.02-.107-.029-.1-.029-.1-.029-.1-.037-.1-.037.256-1.3h0l.058.013.07.02.087.021.1.025.111.021.124.029.132.024.14.025.149.021.157.021.161.017.169.016.169.008.173,0h.174l.177-.008h0l.165-.029.136-.045.1-.066.082-.074.062-.087.046-.09.037-.091.024-.091h0l.013-.1,0-.083-.021-.078-.034-.066-.045-.066-.058-.058-.071-.054-.078-.05-.087-.045-.094-.046-.1-.045-.108-.041-.107-.042-.115-.046-.116-.046-.12-.049-.116-.05-.115-.054-.116-.062-.112-.066-.107-.071-.1-.078-.094-.091-.087-.095-.078-.108-.071-.116-.058-.128-.049-.136-.034-.153-.021-.165,0-.181.013-.194h0l.012-.12.017-.116.017-.111.024-.108.025-.1.029-.1.037-.095.037-.095.045-.087.046-.086.054-.079.058-.078.062-.074.066-.071.07-.066.078-.062.083-.058.087-.054.095-.049.1-.05.1-.041.111-.041.117-.033.123-.034.133-.029.136-.025.14-.021.149-.017.156-.013.165-.008.17-.008h.4l.2,0,.178,0,.165.008.145.008.133.008.119.013.1.008.1.013.082.012.079.008.065.013.059.008.054.008.049.008.046,0-.3,1.338"
            transform="translate(-586.359 -163.274)"
          />
        </g>
        <path
          fill="#fff"
          d="M596.8,160.95h1.66l-.256,1.446h1.02L599,163.713l-1.044-.021-.549,3.291-.009.07v.062l.009.054.017.05.024.041.028.041.038.033.041.024.05.026.05.02.057.017.058.008.066.012.066,0,.07,0h.2l.115-.008.107-.017.092-.021.07-.02.054-.021.032-.017.013,0-.169,1.127-.041.025-.045.021-.046.02-.046.021-.045.016-.05.016-.048.016-.058.017-.059.016-.062.013-.07.012-.074.012-.079.013-.082.013-.1.013-.1.012h-.446l-.235-.017-.211-.024-.185-.034-.161-.046-.137-.049-.115-.062-.095-.07-.078-.079-.062-.087-.045-.1-.029-.1-.016-.115,0-.12,0-.132.012-.137.021-.149Z"
          transform="translate(-568.92 -152.23)"
        />
        <g transform="translate(26.746 8.72)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M596.8,160.95h1.66l-.256,1.446h1.02L599,163.713l-1.044-.021-.549,3.291h0l-.009.07v.062l.009.054.017.05.024.041.028.041.038.033.041.024.05.026.05.02.057.017.058.008.066.012.066,0,.07,0h.2l.115-.008.107-.017.092-.021.07-.02.054-.021.032-.017.013,0-.169,1.127h0l-.041.025-.045.021-.046.02-.046.021-.045.016-.05.016-.048.016-.058.017-.059.016-.062.013-.07.012-.074.012-.079.013-.082.013-.1.013-.1.012h-.446l-.235-.017-.211-.024-.185-.034-.161-.046-.137-.049-.115-.062-.095-.07-.078-.079-.062-.087-.045-.1-.029-.1-.016-.115,0-.12,0-.132.012-.137.021-.149,1.1-6.457"
            transform="translate(-595.665 -160.95)"
          />
        </g>
        <path
          fill="#fff"
          d="M578.241,167.005l-.021.1,0,.1.008.111.025.107.032.112.05.111.058.107.07.1.078.1.091.1.095.082.1.078.111.066.116.054.124.041.123.029.15.021.148.02.153.017.153.008.157,0h.153l.162-.008.156-.013.161-.024.165-.029.165-.041.165-.053.165-.062.174-.074.169-.091.174-.1-.256,1.466-.1.05-.1.045-.087.038-.09.033-.091.032-.091.025-.095.021-.1.021-.107.013-.117.012-.128.012-.136,0-.149.008h-.166l-.181,0h-.342l-.149-.009-.145-.008-.148-.016-.153-.02-.149-.025-.149-.033-.148-.033-.145-.045-.144-.046-.14-.054-.141-.061-.136-.071-.133-.074-.123-.082-.124-.091-.115-.1-.112-.107-.108-.116-.095-.124-.091-.137-.082-.14-.074-.152-.066-.162-.058-.173-.045-.182-.038-.19-.024-.206-.016-.215v-.222l.007-.239.021-.249.012-.086.013-.1.017-.1.02-.107.02-.112.029-.116.034-.12.032-.124.042-.129.045-.124.054-.128.057-.128.066-.128.07-.128.078-.127.082-.124.1-.12.1-.12.108-.116.12-.107.123-.107.137-.1.144-.091.157-.087.165-.074.178-.07.185-.062.2-.05.211-.041.223-.029.231-.017.248-.008h.116l.115.008.12.008.12.013.119.017.124.025.12.029.12.034.119.036.121.046.115.049.111.059.108.066.107.07.1.083.1.086.09.1.087.1.078.115.07.12.067.131.057.14.049.153.037.161.034.174.02.181.009.195v.207l-.013.219-.02.231-.037.244-.05.256-.065.363h-4.29l.256-1.148H580.6l.012-.157v-.145l-.008-.136-.024-.124-.033-.111-.042-.1-.054-.091-.062-.083-.07-.074-.078-.061-.083-.05-.087-.042-.095-.033-.1-.025-.1-.011-.107,0h-.116l-.107.016-.107.02-.1.034-.095.045-.091.053-.087.062-.078.07-.074.083-.066.087-.062.1-.058.1-.049.107-.042.116-.033.124-.029.128Z"
          transform="translate(-560.446 -153.273)"
        />
        <g transform="translate(16.045 10.037)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M578.241,167.005h0l-.021.1,0,.1.008.111.025.107.032.112.05.111.058.107.07.1.078.1.091.1.095.082.1.078.111.066.116.054.124.041.123.029h0l.15.021.148.02.153.017.153.008.157,0h.153l.162-.008.156-.013.161-.024.165-.029.165-.041.165-.053.165-.062.174-.074.169-.091.174-.1-.256,1.466h0l-.1.05-.1.045-.087.038-.09.033-.091.032-.091.025-.095.021-.1.021-.107.013-.117.012-.128.012-.136,0-.149.008h-.166l-.181,0h-.342l-.149-.009-.145-.008-.148-.016-.153-.02-.149-.025-.149-.033-.148-.033-.145-.045-.144-.046-.14-.054-.141-.061-.136-.071-.133-.074-.123-.082-.124-.091-.115-.1-.112-.107-.108-.116-.095-.124-.091-.137-.082-.14-.074-.152-.066-.162-.058-.173-.045-.182-.038-.19-.024-.206-.016-.215v-.222l.007-.239.021-.249h0l.012-.086.013-.1.017-.1.02-.107.02-.112.029-.116.034-.12.032-.124.042-.129.045-.124.054-.128.057-.128.066-.128.07-.128.078-.127.082-.124.1-.12.1-.12.108-.116.12-.107.123-.107.137-.1.144-.091.157-.087.165-.074.178-.07.185-.062.2-.05.211-.041.223-.029.231-.017.248-.008h.116l.115.008.12.008.12.013.119.017.124.025.12.029.12.034.119.036.121.046.115.049.111.059.108.066.107.07.1.083.1.086.09.1.087.1.078.115.07.12.067.131.057.14.049.153.037.161.034.174.02.181.009.195v.207l-.013.219-.02.231-.037.244-.05.256-.065.363h-4.29l.256-1.148H580.6l.012-.157v-.145l-.008-.136-.024-.124-.033-.111-.042-.1-.054-.091-.062-.083-.07-.074-.078-.061-.083-.05-.087-.042-.095-.033-.1-.025-.1-.011-.107,0h-.116l-.107.016-.107.02-.1.034-.095.045-.091.053-.087.062-.078.07-.074.083-.066.087-.062.1-.058.1-.049.107-.042.116-.033.124-.029.128-.231,1.234"
            transform="translate(-576.491 -163.31)"
          />
        </g>
        <path
          fill="#fff"
          d="M571.254,168.477l-.236,1.318h-1.49l.017-.124.016-.124.017-.111.016-.1.017-.087.012-.074.016-.05.017-.028-.078.09-.1.091-.111.088-.128.082-.136.078-.149.074-.157.066-.161.062-.169.054-.173.046-.174.032-.173.025-.174.013-.169,0-.165-.012-.161-.026-.211-.049-.186-.066-.169-.078-.149-.087-.132-.095-.113-.107-.1-.111-.083-.123-.07-.124-.057-.129-.046-.132-.037-.135-.024-.132-.017-.136-.008-.132,0-.128.017-.231.037-.219.054-.212.078-.2.095-.185.107-.174.128-.161.137-.153.153-.136.16-.119.174-.111.182-.1.19-.082.194-.067.2-.049.2-.038h.008l.029,0,.045,0,.061-.008.071-.008.082-.008.092-.013.1-.008.1-.009.1-.013.1-.008.1-.008.09-.008.087,0,.079,0h.277l.074,0h.07l.066,0h.132l.058,0h.054l.046,0h.041l.032,0h.046l.013-.041.028-.1.022-.116v-.1l-.009-.075-.013-.07-.021-.066-.029-.062-.033-.058-.041-.053-.041-.05-.05-.05-.054-.041-.054-.033-.062-.033-.061-.029-.065-.02-.071-.017-.07-.011-.071-.009-.168,0-.178,0-.186,0-.189.012-.19.013-.19.016-.19.021-.186.02-.177.028-.169.025-.153.029-.144.033-.128.033-.108.033-.087.033-.061.037.425-1.4.124-.057.133-.054.131-.046.14-.037.145-.029.148-.025.157-.021.161-.016.166-.008.169-.008.173,0h.561l.2,0,.206,0,.169.016.178.025.185.046.186.053.186.07.186.087.173.1.165.116.153.132.137.145.116.161.09.177.066.195.037.21,0,.222-.033.244Z"
          transform="translate(-555.754 -153.3)"
        />
        <g transform="translate(10.119 10.07)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M571.254,168.477l-.236,1.318h-1.49l.017-.124.016-.124.017-.111.016-.1.017-.087.012-.074.016-.05.017-.028h0l-.078.09-.1.091-.111.088-.128.082-.136.078-.149.074-.157.066-.161.062-.169.054-.173.046-.174.032-.173.025-.174.013-.169,0-.165-.012-.161-.026h0l-.211-.049-.186-.066-.169-.078-.149-.087-.132-.095-.113-.107-.1-.111-.083-.123-.07-.124-.057-.129-.046-.132-.037-.135-.024-.132-.017-.136-.008-.132,0-.128h0l.017-.231.037-.219.054-.212.078-.2.095-.185.107-.174.128-.161.137-.153.153-.136.16-.119.174-.111.182-.1.19-.082.194-.067.2-.049.2-.038h.008l.029,0,.045,0,.061-.008.071-.008.082-.008.092-.013.1-.008.1-.009.1-.013.1-.008.1-.008.09-.008.087,0,.079,0h.277l.074,0h.07l.066,0h.132l.058,0h.054l.046,0h.041l.032,0h.046l.013-.041.028-.1.022-.116v-.1h0l-.009-.075-.013-.07-.021-.066-.029-.062-.033-.058-.041-.053-.041-.05-.05-.05-.054-.041-.054-.033-.062-.033-.061-.029-.065-.02-.071-.017-.07-.011-.071-.009h0l-.168,0-.178,0-.186,0-.189.012-.19.013-.19.016-.19.021-.186.02-.177.028-.169.025-.153.029-.144.033-.128.033-.108.033-.087.033-.061.037.425-1.4h0l.124-.057.133-.054.131-.046.14-.037.145-.029.148-.025.157-.021.161-.016.166-.008.169-.008.173,0h.561l.2,0,.206,0h0l.169.016.178.025.185.046.186.053.186.07.186.087.173.1.165.116.153.132.137.145.116.161.09.177.066.195.037.21,0,.222-.033.244-.615,3.1"
            transform="translate(-565.873 -163.37)"
          />
        </g>
        <path
          fill="#0084c4"
          d="M571.287,169.657l0-.033-.025-.025-.042-.02-.053-.012-.07-.008-.075,0h-.177l-.095.008-.1.012-.095.012-.1.017-.091.016-.087.017-.074.02-.066.021-.037.008-.05.016-.061.021-.067.028-.07.03-.079.037-.078.045-.078.046-.078.057-.075.062-.07.065-.061.075-.059.078-.041.087-.032.094-.021.1-.008.2.029.157.049.124.074.091.091.062.095.041.1.021.1,0,.182,0,.161-.017.152-.029.136-.037.124-.042.111-.049.1-.058.087-.062.078-.062.067-.07.058-.066.049-.07.042-.07.033-.067.025-.065.02-.062.021-.091.02-.1.021-.1.021-.1.016-.1.017-.091.016-.087Z"
          transform="translate(-557.212 -156.033)"
        />
        <path
          fill="#fff"
          d="M551.908,168.725h-1.615l1.358-7.775h2.808l.277,4.629,1.912-4.629h2.915l-1.362,7.775h-1.615L557.6,162.9h-.062l-2.4,5.822h-1.808l-.017-.244-.037-.648-.053-.917-.062-1.052L553.1,164.8l-.054-.937-.038-.677-.016-.285h-.066Z"
          transform="translate(-548.869 -152.23)"
        />
        <g transform="translate(1.424 8.72)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M551.908,168.725h-1.615l1.358-7.775h2.808l.277,4.629,1.912-4.629h2.915l-1.362,7.775h-1.615L557.6,162.9h-.062l-2.4,5.822h-1.808l-.017-.244-.037-.648-.053-.917-.062-1.052L553.1,164.8l-.054-.937-.038-.677-.016-.285h-.066l-1.019,5.822"
            transform="translate(-550.292 -160.95)"
          />
        </g>
        <path
          fill="#fff"
          d="M601.519,169.8h1.68l.573-3.357.025-.132.029-.128.033-.128.041-.12.046-.12L604,165.7l.07-.107.078-.1.1-.092.107-.083.128-.074.141-.066.166-.053.185-.041.206-.024.231-.017h.021l.025,0,.034,0h.033l.029,0,.024,0,.017,0h.008l0-.024.017-.071.021-.107.032-.136.042-.152.045-.166.054-.165.062-.156.045-.116.054-.111.053-.111.058-.1.05-.087.041-.07.029-.046.009-.016h-.009l-.021-.009-.029,0-.042-.008-.041-.008-.05,0-.045-.008h-.041l-.182.012-.165.02-.153.033-.141.037-.132.049-.119.054-.116.066-.1.071-.1.079-.095.086-.087.091-.091.1-.086.1-.083.112-.087.116-.086.12.194-1.061-1.68-.021-1.106,6.35Z"
          transform="translate(-571.506 -153.306)"
        />
        <g transform="translate(30.012 10.078)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M601.519,169.8h1.68l.573-3.357h0l.025-.132.029-.128.033-.128.041-.12.046-.12L604,165.7l.07-.107.078-.1.1-.092.107-.083.128-.074.141-.066.166-.053.185-.041.206-.024.231-.017h.021l.025,0,.034,0h.033l.029,0,.024,0,.017,0h.008l0-.024.017-.071.021-.107.032-.136.042-.152.045-.166.054-.165.062-.156h0l.045-.116.054-.111.053-.111.058-.1.05-.087.041-.07.029-.046.009-.016h-.009l-.021-.009-.029,0-.042-.008-.041-.008-.05,0-.045-.008h-.041l-.182.012-.165.02-.153.033-.141.037-.132.049-.119.054-.116.066-.1.071-.1.079-.095.086-.087.091-.091.1-.086.1-.083.112-.087.116-.086.12.194-1.061-1.68-.021-1.106,6.35h0"
            transform="translate(-601.519 -163.385)"
          />
        </g>
        <path
          fill="#fff"
          d="M611.849,163.385l.186,0,.182.012.178.016.174.025.165.034.16.041.153.046.152.053.145.062.137.066.136.071.124.078.124.086.115.091.108.1.1.1.1.107.09.116.083.116.078.124.074.128.062.137.061.136.05.144.046.145.037.153.033.157.025.161.016.161.009.17,0,.174,0,.173-.013.211-.025.2-.029.194-.037.19-.041.182-.054.173-.058.17-.062.16-.075.153-.074.148-.088.14-.086.132-.1.128-.1.119-.107.112-.111.107-.116.1-.12.1-.128.086-.128.079-.136.074-.141.066-.14.062-.149.053-.148.049-.157.042-.157.034-.157.029-.165.021-.165.016-.169.009-.169,0-.17,0-.169-.009-.161-.016-.161-.025-.156-.029-.157-.033-.148-.042-.144-.049-.145-.054-.136-.062-.132-.065-.128-.074-.123-.078-.116-.088-.116-.091-.107-.1-.1-.1-.1-.111L609,168.76l-.086-.124-.075-.128-.074-.136-.062-.141-.059-.144-.049-.153-.046-.161-.037-.165-.029-.171-.02-.177-.013-.182,0-.189,0-.194.013-.2.02-.191.029-.186.037-.177.041-.173.049-.17.054-.161.062-.156.066-.153.074-.144.083-.137.087-.136.091-.123.1-.124.1-.112.108-.111.115-.1.12-.095.124-.091.128-.083.135-.074.141-.074.144-.062.145-.058.153-.049.156-.046.162-.037.165-.033.169-.021.173-.02.178-.008Z"
          transform="translate(-574.567 -153.306)"
        />
        <g transform="translate(33.877 10.078)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M611.849,163.385h0l.186,0,.182.012.178.016.174.025.165.034.16.041.153.046.152.053.145.062.137.066.136.071.124.078.124.086.115.091.108.1.1.1.1.107.09.116.083.116.078.124.074.128.062.137.061.136.05.144.046.145.037.153.033.157.025.161.016.161.009.17,0,.174,0,.173h0l-.013.211-.025.2-.029.194-.037.19-.041.182-.054.173-.058.17-.062.16-.075.153-.074.148-.088.14-.086.132-.1.128-.1.119-.107.112-.111.107-.116.1-.12.1-.128.086-.128.079-.136.074-.141.066-.14.062-.149.053-.148.049-.157.042-.157.034-.157.029-.165.021-.165.016-.169.009-.169,0h0l-.17,0-.169-.009-.161-.016-.161-.025-.156-.029-.157-.033-.148-.042-.144-.049-.145-.054-.136-.062-.132-.065-.128-.074-.123-.078-.116-.088-.116-.091-.107-.1-.1-.1-.1-.111L609,168.76l-.086-.124-.075-.128-.074-.136-.062-.141-.059-.144-.049-.153-.046-.161-.037-.165-.029-.171-.02-.177-.013-.182,0-.189,0-.194h0l.013-.2.02-.191.029-.186.037-.177.041-.173.049-.17.054-.161.062-.156.066-.153.074-.144.083-.137.087-.136.091-.123.1-.124.1-.112.108-.111.115-.1.12-.095.124-.091.128-.083.135-.074.141-.074.144-.062.145-.058.153-.049.156-.046.162-.037.165-.033.169-.021.173-.02.178-.008.177,0"
            transform="translate(-608.443 -163.385)"
          />
        </g>
        <path
          fill="#fff"
          d="M618.97,174.311h.487v.062h-.19v.4h-.107v-.4h-.19Z"
          transform="translate(-579.219 -158.135)"
        />
        <g transform="translate(39.752 16.176)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M618.97,174.311h.487v.062h-.19v.4h-.107v-.4h-.19v-.062"
            transform="translate(-618.97 -174.311)"
          />
        </g>
        <path
          fill="#fff"
          d="M619.954,174.311h.148l.174.359h0l.169-.359h.149v.466h-.108v-.359h0l-.169.359h-.087l-.17-.359h0v.359h-.107Z"
          transform="translate(-579.653 -158.135)"
        />
        <g transform="translate(40.301 16.176)">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.215px"
            d="M619.954,174.311h.148l.174.359h0l.169-.359h.149v.466h-.108v-.359h0l-.169.359h-.087l-.17-.359h0v.359h-.107v-.466"
            transform="translate(-619.954 -174.311)"
          />
        </g>
        <path
          fill="#ec1c2e"
          d="M612.156,167.53l-.025.232-.013.214v.194l.013.181.025.165.036.144.047.132.057.119.07.1.078.087.087.074.1.059.1.044.116.034.119.016.129.009.14,0,.136-.016.128-.03.123-.045.116-.058.111-.074.1-.087.1-.1.087-.115.083-.133.078-.148.066-.161.062-.177.054-.194.042-.211.037-.226.02-.178.013-.169v-.165l-.013-.157-.02-.145-.033-.14-.045-.128-.054-.119-.066-.108-.075-.091-.086-.083-.095-.066-.107-.054-.116-.033-.123-.02h-.137l-.169.012-.157.029-.145.049-.137.062-.123.083-.115.091-.1.1-.092.116-.086.123-.074.132-.067.132-.053.14-.049.141-.041.141-.029.141Z"
          transform="translate(-576.191 -154.418)"
        />
      </g>
    </g>
  </svg>
);

MaestroCardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

MaestroCardIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default MaestroCardIcon;
