import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './styles';

const AdditionalButton = ({ className, onClick, children, smallBtn }) => (
  <StyledButton onClick={onClick} className={className} smallBtn={smallBtn}>
    {children}
  </StyledButton>
);

AdditionalButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  smallBtn: PropTypes.bool,
};

export default AdditionalButton;
