import styled from 'styled-components';

export const ContainerLink = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${({ theme, disabled }) => (disabled ? theme.color.darkGrey : theme.color.success)};
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  margin: 3px;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  @media (min-width: 1000px) {
    &:hover {
      background-color: ${({ theme }) => theme.color.grayLighter};
    }
  }
`;
