import styled, { css } from 'styled-components';

export const GratuityOptionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  padding-block: 10px;
`;

export const Label = styled.span`
  font-weight: 700;
  margin-inline-end: auto;
`;

export const GratuityOptionButton = styled.button<{ selected: boolean; fade: boolean }>`
  aspect-ratio: 1;
  background-color: ${({ theme }) => theme.color.inputBackground};
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 5px;
  color: ${({ theme }) => theme.color.lightGrey};
  display: grid;
  outline: 1px solid transparent;
  outline-offset: 2px;
  place-content: center;
  transition: opacity 250ms ease-out, outline 250ms ease-out;
  width: 4rem;

  ${({ fade }) =>
    fade &&
    css`
      opacity: 0.5;
    `}
  ${({ selected }) =>
    selected &&
    css`
      outline-color: ${({ theme }) => theme.color.brandPrimary};
    `};
`;

export const GratuityOptionAmount = styled.span`
  color: ${({ theme }) => theme.color.text};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const OtherGratuityOption = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
