import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import globalMessages from 'components/globalMessages';
import { AddressChoice, AddAddressButton } from 'components/Address';
import { selectAddressesList, selectDefaultAddressId, selectIsUserAddressInRange } from 'selectors/user';

import { SelectionContainer } from './styles';

const propTypes = {
  addressesList: PropTypes.instanceOf(List),
  addressesListSize: PropTypes.number,
  defaultAddressId: PropTypes.string,
  value: PropTypes.string,
  formSubmitting: PropTypes.bool,
  onChange: PropTypes.func,
  isAddressInRange: PropTypes.func,
};

const AddressSelection = ({
  addressesList,
  addressesListSize,
  defaultAddressId,
  value,
  formSubmitting,
  onChange,
  isAddressInRange,
}) => {
  useEffect(() => {
    const defaultAddress = addressesList?.find(add => add.get('id') === defaultAddressId);
    const latitude = defaultAddress?.get('latitude');
    const longitude = defaultAddress?.get('longitude');

    if (value === '' && isAddressInRange(latitude, longitude)) {
      onChange(defaultAddressId);
    }
  }, [addressesList, defaultAddressId, value, isAddressInRange, onChange]);

  if (addressesListSize === 0) return null;

  return (
    <div>
      <SelectionContainer>
        {addressesList.map(address => {
          const addressId = address?.get('id');
          const addressLatitude = address?.get('latitude');
          const addressLongitude = address?.get('longitude');
          const isDisabled = isAddressInRange(addressLatitude, addressLongitude) === false;

          return (
            <AddressChoice
              addressId={addressId}
              key={addressId}
              address={address}
              isDefault={defaultAddressId === addressId}
              checked={value === addressId}
              disabled={isDisabled || formSubmitting}
              disabledMsg={isDisabled && <FormattedMessage {...globalMessages.deliveryAddressUnavailable} />}
              onChange={() => onChange(addressId)}
            />
          );
        })}
      </SelectionContainer>

      <div>
        <AddAddressButton />
      </div>
    </div>
  );
};

AddressSelection.propTypes = propTypes;

export default connect(state => ({
  addressesList: selectAddressesList(state),
  addressesListSize: selectAddressesList(state).size,
  defaultAddressId: selectDefaultAddressId(state),
  isAddressInRange: (addressLatitude, addressLongitude) =>
    selectIsUserAddressInRange(state, addressLatitude, addressLongitude),
}))(AddressSelection);
