import styled, { css } from 'styled-components';
import { fontSizeBig, fontSizeMedium } from 'assets/styles/fonts';

export const StyledAllergenItem = styled.li`
  color: #bbb;
  width: 50%;
  padding: 6px 0px;
  display: flex;
  flex-flow: row;
  align-items: center;
  ${fontSizeMedium};

  ${props =>
    props.active &&
    css`
      color: ${({ theme }) => theme.color.primaryText};
      svg .icon {
        fill: ${({ theme }) => theme.color.success};
      }
    `}

  html[dir='rtl'] & {
    text-align: right;
  }

  &:lang(ru) {
    font-size: 12px;
  }

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;

    html[dir='rtl'] & {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  @media (min-width: 425px) {
    width: 33%;
  }

  @media (min-width: 768px) {
    width: 25%;
    padding: 10px 0px;
    ${fontSizeBig};

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
