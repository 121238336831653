import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.color.primaryBackground};
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  z-index: ${({ theme }) => theme.zIndex.thirteen};
`;

export const IconWrap = styled.span`
  padding: 10px;
  color: ${({ theme }) => theme.color.danger};
`;

export const TextWrap = styled.span`
  padding: 10px 0;
  line-height: 20px;
  color: ${({ theme }) => theme.color.iconFill};
`;

export const CloseIconWrap = styled.span`
  border-left: 1px solid ${({ theme }) => theme.color.borderColor};
  color: ${({ theme }) => theme.color.iconFill};
  cursor: pointer;
  padding: 15px;
`;
