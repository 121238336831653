import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import image from 'utils/image';
import { Figure } from './styles';

const ProductImage = ({ images, altText }) => {
  if (images.size > 0) {
    return (
      <Figure>
        <img src={image(images.getIn([0, 'path']), { width: 800 })} alt={altText} />
      </Figure>
    );
  }

  return null;
};

ProductImage.propTypes = {
  images: PropTypes.instanceOf(List),
  altText: PropTypes.string,
};

ProductImage.defaultProps = {
  images: new List(),
};

export default ProductImage;
