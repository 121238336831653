import 'isomorphic-fetch';
import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Swipeable } from 'react-touch';
import { injectIntl } from 'react-intl';

import Profile from 'components/Profile';
import SidebarMenu from 'components/SidebarMenu';
import { toggleSidebarOpen } from 'actions/sidebar';
import { openLoginRegisterAlert } from 'actions/UI';
import SidebarMenuChoices from 'components/SidebarMenuChoices';
import { confirmLogout as logoutAction } from 'actions/user';
import { isLoggedIn, selectUser } from 'selectors/user';
import { selectMenusByType, selectMenuTypeId } from 'selectors/browse';
import { selectSidebarIsOpen } from 'selectors/sidebar';

import { SidebarStyled } from './styles';

const propTypes = {
  openLoginRegisterAlert: PropTypes.func,
  menuButton: PropTypes.func,
  user: PropTypes.instanceOf(Map),
  serviceId: PropTypes.string,
  menuId: PropTypes.string,
  logout: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }),
  menus: PropTypes.instanceOf(Map),
};

const Sidebar = ({
  intl,
  menuButton,
  toggleSidebar,
  sidebarOpen,
  loggedIn,
  user,
  logout,
  openLoginRegisterAlert,
  menus,
  serviceId,
  menuId,
}) => {
  const onSwipe = dir => {
    const { locale } = intl;
    if (dir === 'left' && (locale !== 'ar' || locale !== 'he')) {
      return;
    }
    return menuButton || toggleSidebar;
  };

  return (
    <Swipeable onSwipeLeft={onSwipe('left')} onSwipeRight={onSwipe('right')}>
      <SidebarStyled isOpen={sidebarOpen}>
        <Profile
          isLoggedIn={loggedIn}
          user={user}
          logout={logout}
          toggleSidebarOpen={toggleSidebar}
          openLoginRegisterAlert={openLoginRegisterAlert}
        />

        <div>
          <SidebarMenu
            isLoggedIn={loggedIn}
            serviceId={serviceId}
            user={user}
            logout={logout}
            toggleSidebarOpen={toggleSidebar}
          />

          <SidebarMenuChoices
            menus={menus}
            serviceId={serviceId}
            menuId={menuId}
            toggleSidebarOpen={toggleSidebar}
          />
        </div>
      </SidebarStyled>
    </Swipeable>
  );
};

Sidebar.propTypes = propTypes;

const mapStateToProps = (state, { serviceId }) => ({
  loggedIn: isLoggedIn(state),
  user: selectUser(state),
  sidebarOpen: selectSidebarIsOpen(state),
  menus: selectMenusByType(state, selectMenuTypeId(state), serviceId),
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => {
    dispatch(toggleSidebarOpen());
  },
  logout: () => {
    dispatch(toggleSidebarOpen());
    dispatch(logoutAction());
  },
  openLoginRegisterAlert: section => {
    dispatch(openLoginRegisterAlert(section));
  },
});

const IntlSidebar = injectIntl(Sidebar);

export default connect(mapStateToProps, mapDispatchToProps)(IntlSidebar);
