import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import FulfilmentTypes from 'components/FulfilmentTypes';
import { useHandleOutsideClick } from 'hooks';
import { PointerIconFull, CloseIcon } from 'components/Icons';
import { VenuePinContainer, PinInfoContainer, PinInfo, PinInfoName, PinInfoClose } from './styles';

const propTypes = {
  name: PropTypes.string,
  address: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showCloseIcon: PropTypes.bool,
  venueId: PropTypes.number,
  disabled: PropTypes.bool,
};

const VenuePin = ({ name, address, showCloseIcon, venueId, disabled }) => {
  const [showInfo, toggleShowInfo] = useState(false);

  const venuePinContainerRef = useRef();
  useHandleOutsideClick(showInfo, venuePinContainerRef, toggleShowInfo);

  return (
    <VenuePinContainer
      onClick={() => toggleShowInfo(!showInfo)}
      ref={venuePinContainerRef}
      selected={showInfo}
    >
      {showInfo && (
        <PinInfoContainer disabled={disabled}>
          <PinInfo>
            <PinInfoName>{name}</PinInfoName>
            {address && <div>{address}</div>}

            <FulfilmentTypes venueId={venueId} disabled={disabled} />

            {showCloseIcon && (
              <PinInfoClose
                onClick={event => {
                  event.preventDefault();
                  toggleShowInfo(false);
                }}
                type="button"
              >
                <CloseIcon width="19px" height="19px" />
              </PinInfoClose>
            )}
          </PinInfo>
        </PinInfoContainer>
      )}

      <PointerIconFull height="34" width="34" />
    </VenuePinContainer>
  );
};

VenuePin.propTypes = propTypes;

export default VenuePin;
