import { defineMessages } from 'react-intl';

export default defineMessages({
  errorHeader: {
    defaultMessage: 'Order Processing Error',
  },
  errorMessage: {
    defaultMessage:
      'There was an error processing your order. Please try again later or contact a member of staff for further assistance',
  },
  createTabHeaderError: {
    defaultMessage: 'Error Creating Tab',
  },
  invalidOrder: {
    defaultMessage: 'Invalid Order',
  },
  invalidOrderMessage: {
    defaultMessage: 'This order is no longer valid',
  },
  unavailableItems: {
    defaultMessage: 'Unavailable Items',
  },
  unavailableItemsMessage: {
    defaultMessage: 'Some items have been removed from your basket. Please review your order and try again.',
  },
  siteOffline: {
    defaultMessage: 'Site Offline',
  },
  siteOfflineMessage: {
    defaultMessage: 'We are unable to contact the venue. Please try again or contact a member of staff.',
  },
  unvailableTime: {
    defaultMessage: 'Time Unavailable',
  },
  unvailableTimeMessage: {
    defaultMessage:
      'We are unable to place your order for the requested time. Please select another time and try again.',
  },
  itemErrors: {
    defaultMessage: 'Item Errors',
  },
  itemErrorsMessage: {
    defaultMessage:
      'Some of your items are currently unavailable to be ordered. Please review your order or contact a member of staff.',
  },
  orderError: {
    defaultMessage:
      'We were unable to place your order. Please review your order or contact a member of staff.',
  },
  validationErrors: {
    defaultMessage: 'Validation errors',
  },
  validationErrorsMessage: {
    defaultMessage: 'There are some errors on the form. Please check the details entered',
  },
  noDeliveryTimes: {
    defaultMessage: 'There are currently no delivery times available. Please try again later.',
  },
  noCollectionTimes: {
    defaultMessage: 'There are currently no collection times available. Please try again later.',
  },
  passcodeError: {
    defaultMessage:
      'The passcode you have entered is invalid. Please check the passcode with your group leader.',
  },
});
