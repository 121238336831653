import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import DayPickerCalendar from 'components/DayPickerCalendar';
import globalMessages from 'components/globalMessages';
import ActionButton from 'components/ActionButton';
import { Container, Title, DateRangeText, DateBlock, MessageBlockStyled, FilterButton } from './styles';

class FilterByDate extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    hasFilter: PropTypes.bool,
    onClear: PropTypes.func,
    onApply: PropTypes.func,
  };

  state = {
    from: this.props.dateFrom || '',
    to: this.props.dateTo || '',
    loading: false,
  };

  componentWillUnmount() {
    clearTimeout(this.loadingTimeOut);
  }

  handleDayChange = key => selectedDay => this.setState({ [key]: this.cleanDate(selectedDay) });

  handleOnApply = () => {
    this.setState({ loading: true });

    this.loadingTimeOut = setTimeout(() => {
      this.setState({ loading: false });
      this.props.onApply(this.state.from, this.state.to);
    }, 500);
  };

  // eslint-disable-next-line class-methods-use-this
  cleanDate = date => {
    if (date === undefined) return '';

    return format(date, 'yyyy-MM-dd');
  };

  // eslint-disable-next-line class-methods-use-this
  reverseDate = date => date.split('-').reverse().join('/');

  render() {
    const { title, onClear, hasFilter } = this.props;
    const { from, to, loading } = this.state;
    const isApplydisabled = !from && !to;
    const isFromBiggerThanTo = from && to ? from > to : false;

    return (
      <Container>
        {title && <Title className="u-textCenter u-textCapitalize">{title}</Title>}

        <DateRangeText className="u-textCapitalize">
          <FormattedMessage {...globalMessages.dateRange} />
        </DateRangeText>
        <div className="u-flex u-flexJustifyCenter u-flexJustifyBetween">
          <DateBlock>
            <DayPickerCalendar
              buttonLabel={this.reverseDate(from) || <FormattedMessage {...globalMessages.from} />}
              onDayChange={this.handleDayChange('from')}
              defaultMonth={from ? new Date(from) : null}
              selected={from ? new Date(from) : null}
              disabled={{ after: new Date() }}
              toMonth={new Date()}
            />
          </DateBlock>
          <DateBlock>
            <DayPickerCalendar
              buttonLabel={this.reverseDate(to) || <FormattedMessage {...globalMessages.to} />}
              onDayChange={this.handleDayChange('to')}
              defaultMonth={to ? new Date(to) : null}
              selected={to ? new Date(to) : null}
              disabled={{ after: new Date() }}
              toMonth={new Date()}
            />
          </DateBlock>
        </div>

        {isFromBiggerThanTo && (
          <MessageBlockStyled
            header={<FormattedMessage {...globalMessages.warning} />}
            body={<FormattedMessage {...globalMessages.wrongDates} />}
          />
        )}

        <div className="u-flex u-flexJustifyCenter u-flexJustifyBetween">
          {hasFilter ? (
            <FilterButton>
              <ActionButton
                dangerButton={true}
                label={<FormattedMessage {...globalMessages.clearFilters} />}
                buttonType="button"
                onClick={onClear}
              />
            </FilterButton>
          ) : (
            <div />
          )}

          <FilterButton>
            <ActionButton
              primaryButton={true}
              label={
                loading ? (
                  <FormattedMessage {...globalMessages.loading} />
                ) : (
                  <FormattedMessage {...globalMessages.applyFilters} />
                )
              }
              buttonType="button"
              onClick={this.handleOnApply}
              disabled={isApplydisabled || loading || isFromBiggerThanTo}
            />
          </FilterButton>
        </div>
      </Container>
    );
  }
}

export default FilterByDate;
