import React from 'react';
import PropTypes from 'prop-types';

const CupIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon"
      fill={fill}
      d="M493.495,990c-119.124,0-233.748-39.437-238.602-41.156c-9.809-3.438-16.787-12.338-17.595-22.905  l-49.956-618.119c-0.505-7.382,2.174-14.563,7.029-20.124c5.056-5.462,11.932-8.443,19.314-8.443  c51.118,0,72.961,30.638,80.191,40.65l-18.961,18.911l21.236-15.625c3.894,5.209,13.096,17.646,37.82,17.646  c25.281,0,37.214-17.341,37.467-17.544c9.505-16.128,37.871-44.038,82.314-44.038c50.966,0,72.809,30.438,80.04,40.498  l-19.012,18.911l21.59-15.473c3.792,5.209,12.641,17.646,37.416,17.646c25.533,0,37.617-17.341,37.617-17.544  c1.821-3.034,21.034-33.32,61.99-41.915c14.258-3.287,28.112,6.117,31.045,20.374c2.983,14.412-6.118,28.318-20.376,31.299  c-18.202,3.842-27.506,17.748-27.506,17.797c-10.163,16.282-38.629,42.98-82.77,42.98c-23.41,0-56.68-6.826-80.292-39.693  l-2.781-3.589c-4.803-6.928-13.146-18.455-36.961-18.455c-24.877,0-36.961,18.254-37.415,19.112  c-9.961,15.927-38.478,42.625-82.366,42.625c-34.18,0-61.332-13.248-80.443-39.338l-2.882-3.944l-7.433-8.848l45.455,562.449  c34.635,10.419,119.731,33.018,204.827,33.018c85.854,0,174.287-22.349,209.832-32.512l71.09-779.918  c-0.304,0-11.478-56.629-270.153-61.635c-159.827-2.679-263.377,33.977-275.765,48.59c-4.753,9.709-2.426,13.502-1.567,15.017  c6.067,9.962,44.292,42.625,277.787,42.625h0.304c44.393,0,44.393,0,82.213-1.62c13.5,0,26.899,10.672,27.506,25.333  c0.809,14.56-10.719,26.849-25.18,27.404c-38.882,1.768-38.882,1.768-84.843,1.768c-249.27,0-303.473-36.301-322.838-67.903  c-8.494-13.906-15.421-36.912,0.354-67.906c23.157-45.201,167.815-79.787,323.04-76.145  c266.057,5.206,321.928,66.338,321.928,116.847l-72.657,800.144c-0.961,10.567-7.989,19.414-18.05,22.701  C731.54,951.576,612.416,990,493.495,990"
    />
  </svg>
);

CupIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CupIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CupIcon;
