import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ForgotPasswordIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M215.604,100.684L190,75.055V36h-39.926L131.68,17.641c-1.502-1.495-3.299-2.727-5.391-3.625	c-2.099-0.898-4.197-1.363-6.289-1.363c-2.099,0-4.197,0.441-6.289,1.34c-2.099,0.898-3.896,2.321-5.391,3.816L89.902,36H48v41.59	l-24,23.992V227h192V101.582L215.604,100.684z M190,87.053l16.648,16.775L190,119.699V87.053z M151,72.858l10.385,2.083l0.051-0.135	l-7.131-8.467l5.977-3.524L164.041,73h0.134l3.831-10.185l5.913,3.408l-7.257,8.559l-0.141,0.214L177,72.858v6.788l-10.545-1.882	l0.036,0.202l7.376,8.266l-5.955,3.494l-3.816-10.416l-0.129,0.067l-3.894,10.349l-6.021-3.494l6.948-8.4v-0.135l-10,1.949V72.858z	 M136.27,75.008l10.73-2.15v6.788l-10.798-1.882l-0.089,0.202l7.313,8.266l-5.986,3.494l-3.833-10.416l-0.137,0.067l-3.897,10.349	l-5.771-3.494l7.199-8.4v-0.135l-11,1.949v-6.788l10.632,2.083l0.175-0.135l-7.068-8.467l6.007-3.524L133.536,73h0.134l3.831-10.185	l5.913,3.408l-7.234,8.559L136.27,75.008z M113.711,23.195c1.797-1.797,3.889-2.809,6.289-2.809c2.394,0,4.492,1.068,6.289,2.865	L138.867,36h-37.734L113.711,23.195z M90,72.858l10.379,2.083l0.048-0.135l-7.132-8.467l5.976-3.524L103.031,73h0.134l3.83-10.185	l5.914,3.408l-7.258,8.559l-0.134,0.214L116,72.858v6.788l-10.55-1.882l0.034,0.202l7.375,8.266l-5.956,3.494l-3.818-10.416	l-0.128,0.067l-3.894,10.349l-6.017-3.494l6.954-8.4v-0.135l-10,1.949V72.858z M59,72.858l10.627,2.083l0.172-0.135l-7.07-8.467	l6.007-3.524L72.525,73h0.134l3.831-10.185l5.913,3.408l-7.257,8.559l0.119,0.214L86,72.858v6.788l-10.803-1.882l-0.093,0.202	l7.312,8.266l-5.987,3.494l-3.833-10.416l-0.136,0.067l-3.898,10.349l-5.766-3.494l7.204-8.4v-0.135l-11,1.949V72.858z	 M36.195,100.234L48,88.372v32.001l-15.973-16.096L36.195,100.234z M32,120.449v-6.289l50.289,50.313l-0.438,0.898L32,214.785	V120.449z M37.793,220l4.492-4.18l71.426-71.943c1.797-1.496,3.889-2.28,6.289-2.28c2.394,0,4.492,0.87,6.289,2.366L202.207,220	H37.793z M208,115.508v99.277l-50.762-50.313L208,113.711V115.508z"
      />
    </g>
  </svg>
);

ForgotPasswordIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ForgotPasswordIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ForgotPasswordIcon;
