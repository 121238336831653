import { defineMessages } from 'react-intl';

const messages = defineMessages({
  selectPaymentMethod: {
    defaultMessage: 'Select a Payment Method',
  },
  makePayment: {
    defaultMessage: 'Complete Your Order',
  },
  paymentError: {
    defaultMessage: 'Payment Error',
  },
  paymentErrorMessage: {
    defaultMessage:
      'There was an error processing your payment. Please try again or select a different payment method.',
  },
  tabLogin: {
    defaultMessage:
      'You must be logged in to create a tab. Pleae log in now or create an account to continue.',
  },
});

export default messages;
