export const CLEAR_ORDER = 'app/Order/CLEAR_ORDER';
export const UPDATE_ITEM = 'app/Order/UPDATE_ITEM';
export const SET_ITEM_NOTES = 'app/Orders/SET_ITEM_NOTES';
export const SET_GRATUITY = 'app/Orders/SET_GRATUITY';
export const SET_FULFILMENT_METHOD = 'app/Order/SET_FULFILMENT_METHOD';
export const ADD_PRODUCT_TO_ORDER = 'app/Order/ADD_PRODUCT_TO_ORDER';
export const ADD_DONATION_TO_ORDER = 'app/Order/ADD_DONATION_TO_ORDER';
export const ADD_LOYALTY_OFFER_TO_ORDER = 'app/Order/ADD_LOYALTY_OFFER_TO_ORDER';
export const REMOVE_LOYALTY_OFFER_FROM_ORDER = 'app/Order/REMOVE_LOYALTY_OFFER_TO_ORDER';
export const DECREASE_ITEM_QUANTITY = 'app/Orders/DECREASE_ITEM_QUANTITY';
export const DECREASE_DONATION_QUANTITY = 'app/Orders/DECREASE_DONATION_QUANTITY';
export const REMOVE_PRODUCT_FROM_ORDER = 'app/Order/REMOVE_PRODUCT_FROM_ORDER';
export const REMOVE_DONATION_FROM_ORDER = 'app/Order/REMOVE_DONATION_FROM_ORDER';
export const REPLACE_ORDER = 'app/Order/REPLACE_ORDER';
export const UPDATE_ORDER_FROM_SERVER = 'app/Order/UPDATE_ORDER_FROM_SERVER';
export const FAILED_ORDER_UPDATE = 'app/Order/FAILED_ORDER_UPDATE';
export const RETRY_ORDER_UPDATE = 'app/Order/RETRY_ORDER_UPDATE';
export const SET_ORDER_VIOLATIONS = 'app/Order/SET_ORDER_VIOLATIONS';
export const SET_ORDER_VIOLATION = 'app/Order/SET_ORDER_VIOLATION';
export const SET_PROMOTION_APPLIED = 'app/Order/SET_PROMOTION_APPLIED';
export const VALIDATING_PROMOTION = 'app/Order/VALIDATING_PROMOTION';
export const TERMS_AND_CONDITIONS_ALERT = 'app/Order/TERMS_AND_CONDITIONS_ALERT';
export const SET_COMPLETED_ORDER = 'app/Order/SET_COMPLETED_ORDER';
export const UPDATE_COMPLETED_ORDER_NUMBER = 'app/Order/UPDATE_COMPLETED_ORDER_NUMBER';
export const UPDATE_COMPLETED_ORDER_STATE = 'app/Order/UPDATE_COMPLETED_ORDER_STATE';
export const GET_ORDERING_DEVICES = 'app/Order/GET_ORDERING_DEVICES';
export const SET_ORDERING_DEVICE = 'app/Order/SET_ORDERING_DEVICE';
export const REGISTER_ORDERING_DEVICE = 'app/Order/REGISTER_ORDERING_DEVICE';
export const UNREGISTER_ORDERING_DEVICE = 'app/Order/UNREGISTER_ORDERING_DEVICE';
