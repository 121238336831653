import React from 'react';
import ActionButton from 'components/ActionButton';
import { FormattedMessage } from 'react-intl';
import productMessages from 'components/Product/messages';

interface Props {
  callback: () => void;
}

export const AddToOrder: React.FC<Props> = ({ callback }) => (
  <ActionButton
    primaryButton={true}
    onClick={callback}
    label={<FormattedMessage {...productMessages.addToOrder} />}
  />
);
