import React from 'react';
import PropTypes from 'prop-types';

// Icon
const FilteredIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon FilteredIcon"
        fill={fill}
        d="M93.578,226.387v-96.203L5,13.119h230l-88.583,117.065v70.701l-44.057,25.502H93.578z M189.671,57.336l26.198-34.675h-192 l50.925,67.162c0,0,22.07,5.448,55.472-15.688S189.671,57.336,189.671,57.336z"
      />
    </g>
  </svg>
);

FilteredIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FilteredIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default FilteredIcon;
