import React from 'react';
import PropTypes from 'prop-types';

const ServerErrorIcon = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <title>Server Error Icon</title>
    <g>
      <path
        className="icon serverErrorIcon"
        fill={fill}
        d="M930.7,10H69.3C33.7,10,4.8,38.9,4.8,74.6v850.9c0,35.7,28.9,64.6,64.6,64.6h861.4c35.7,0,64.6-28.9,64.6-64.6V74.6 C995.2,38.9,966.3,10,930.7,10z M168,100.7c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23s-23-10.3-23-23 C145,111,155.3,100.7,168,100.7z M83,100.7c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23s-23-10.3-23-23C60,111,70.3,100.7,83,100.7z M936.5,925h-873V222h873V925z M936.5,146.7h-610v-46h610V146.7z"
      />
      <path
        className="icon serverErrorIcon"
        fill={fill}
        d="M778,790.8c6.8-8,10.2-19.3,10.2-33.8v-57.2h-90.2V757c0,14.5,3.7,25.8,11,33.8c7.3,8,19,12.1,34.9,12.1	C759.8,802.8,771.2,798.8,778,790.8z M667.6,839.3c-15-17.8-22.5-43.2-22.5-76.2v-63.3H442v-63.1h399.4v130.5	c0,30.1-7.9,54.1-23.7,72C801.9,857,777.4,866,744.3,866C708.1,866,682.5,857.1,667.6,839.3L667.6,839.3z"
      />
      <path
        className="icon serverErrorIcon"
        fill={fill}
        d="M361.1,445.7l-60.8-60.3l60.7-60.3L320.6,285l-60.7,60.3L199.1,285l-40.5,40.1l60.7,60.3l-60.7,60.3 l40.5,40.2l60.7-60.3l60.8,60.3L361.1,445.7z"
      />
      <path
        className="icon serverErrorIcon"
        fill={fill}
        d="M797.7,445.7L737,385.4l60.7-60.3L757.2,285l-60.7,60.3L635.7,285l-40.5,40.1l60.7,60.3l-60.7,60.3 l40.5,40.2l60.7-60.3l60.8,60.3L797.7,445.7z"
      />
    </g>
  </svg>
);

ServerErrorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ServerErrorIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ServerErrorIcon;
