import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaymentMethodChoice from 'components/PaymentSelection/PaymentChoice/PaymentMethodChoice';
import { HeaderButtonContainer, PaymentsContainer } from './styles';

const propTypes = {
  groupImage: PropTypes.string,
  groupName: PropTypes.node,
  choices: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

class PaymentGroup extends Component {
  state = {
    expanded: false,
    selectedMethod: { key: null, image: null },
  };

  componentDidMount() {
    const { value, choices } = this.props;
    const selectedValue = choices.find(choice => choice.key === value);

    if (selectedValue) {
      this.setState({
        selectedMethod: { key: selectedValue?.key || null, image: selectedValue?.image || null },
      });
    }
  }

  expand = () => this.setState(prevState => ({ expanded: !prevState.expanded }));

  handleOnChange = choice => () => {
    this.setState({ expanded: false, selectedMethod: choice });
    this.props.onChange(choice.key);
  };

  render() {
    const { groupImage, groupName, choices, value, disabled } = this.props;
    const { expanded, selectedMethod } = this.state;

    return (
      <>
        <HeaderButtonContainer selected={value === selectedMethod.key}>
          <PaymentMethodChoice
            key={groupName}
            id={groupName}
            label={groupName}
            image={value === selectedMethod.key ? selectedMethod.image : groupImage}
            checked={value === selectedMethod.key}
            onChange={this.expand}
            disabled={disabled}
          />

          {expanded && (
            <PaymentsContainer disabled={disabled}>
              {choices.map(choice => (
                <PaymentMethodChoice
                  grouped={true}
                  key={choice.id}
                  id={`${choice.key}-grouped-${groupName}`}
                  label={choice.name}
                  image={choice.image}
                  checked={false}
                  onChange={this.handleOnChange(choice)}
                  disabled={(value === selectedMethod.key && choice.key === selectedMethod.key) || disabled}
                />
              ))}
            </PaymentsContainer>
          )}
        </HeaderButtonContainer>
      </>
    );
  }
}

PaymentGroup.propTypes = propTypes;

export default PaymentGroup;
