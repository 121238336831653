import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectFulfilmentMethodNotes } from 'selectors/root';
import { linkUnderlined } from 'assets/styles/linkStyles';

const Container = styled.div`
  padding: 1em 0.5em;
  margin: 1.5em 0;
  border-left-width: 0.3em;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.infoBorder};
  background: ${({ theme }) => theme.color.infoBackground};
  word-wrap: break-word;
  word-break: break-word;

  a {
    ${linkUnderlined}
  }

  html[dir='rtl'] & {
    border-width: 0 0.3em 0 0;
  }
`;

const FulfilmentNotes = ({ notes }) => notes && <Container dangerouslySetInnerHTML={{ __html: notes }} />;

FulfilmentNotes.propTypes = {
  notes: PropTypes.string,
};

const mapStateToProps = state => ({
  notes: selectFulfilmentMethodNotes(state),
});

export default connect(mapStateToProps)(FulfilmentNotes);
