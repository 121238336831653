import { OPEN_ALERT } from 'actions/UI/constants';
import fetchHelper from 'utils/fetchHelper';
import {
  selectProductBackButton,
  selectSectionBackButton,
  selectMenuBackButton,
  selectMenuSelectionBackButton,
} from 'selectors/browse';
import { MENU_TYPE_PROMPT } from 'appConstants';
import { getMenuList, getProducts } from '../../../shared/dataLoader';
import {
  SET_BROWSE_INFO,
  SET_BACK_BUTTON,
  SET_MENU_TYPE_ID,
  SET_SERVICE_ID,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  UPDATE_PRODUCTS,
  REQUEST_MENU_LIST,
  RECEIVE_MENU_LIST,
  SET_MENU_ID,
} from './constants';

const requestMenuList = () => ({ type: REQUEST_MENU_LIST });

const receiveMenuList = menuList => ({
  type: RECEIVE_MENU_LIST,
  menuList,
  receivedAt: Date.now(),
});

export const fetchMenuList = venueId => dispatch => {
  dispatch(requestMenuList());
  return getMenuList(venueId).then(menuList => dispatch(receiveMenuList(menuList)));
};

const requestProducts = () => ({ type: REQUEST_PRODUCTS });

const receiveProducts = products => ({
  type: RECEIVE_PRODUCTS,
  products,
  receivedAt: Date.now(),
});

export const updateProducts = products => ({
  type: UPDATE_PRODUCTS,
  products,
});

export const promptForMenuType = () => ({
  type: OPEN_ALERT,
  alertType: MENU_TYPE_PROMPT,
  alertProps: { hideCloseBtn: true },
});

export const fetchProduct = productId => dispatch => {
  dispatch(requestProducts(productId));
  const cb = res => {
    if (res.data) dispatch(receiveProducts(res.data));
  };
  return getProducts(productId, cb);
};

export const fetchProducts = productsInput => dispatch => {
  const productIds = Array.isArray(productsInput) ? productsInput : productsInput.valueSeq().toArray();
  dispatch(requestProducts(productIds));
  const cb = res => {
    if (res.data) dispatch(receiveProducts(res.data));
  };
  return getProducts(productIds, cb);
};

export const setBrowseBackButton = (serviceId, menuId, sectionId, productId) => (dispatch, getState) => {
  const state = getState();
  let data = {};
  if (menuId && sectionId && productId) data = selectProductBackButton(state, serviceId, menuId, sectionId);
  else if (menuId && sectionId) data = selectSectionBackButton(state, serviceId, menuId, sectionId);
  else if (menuId) data = selectMenuBackButton(state, serviceId);
  else data = selectMenuSelectionBackButton(state, serviceId);
  dispatch(setBackButton(data.text, data.href));
};

export const setBrowseInfo =
  (title = '', description = '', imagePath = '') =>
  dispatch => {
    dispatch({
      type: SET_BROWSE_INFO,
      title,
      description,
      imagePath,
    });
  };

export const setBackButton = (text, href) => ({
  type: SET_BACK_BUTTON,
  text,
  href,
});

export const setMenuTypeId = menuTypeId => dispatch => {
  fetchHelper('/api/browse/menuTypeId', 'PUT', { menuTypeId });
  // we don't mind if it fails or succeeds
  dispatch(setLocalMenuTypeId(menuTypeId));
};

const setLocalMenuTypeId = menuTypeId => ({
  type: SET_MENU_TYPE_ID,
  menuTypeId,
});

export const setService = serviceId => dispatch => {
  serviceId = serviceId.toString();
  fetchHelper('/api/browse/serviceId', 'PUT', { serviceId });
  dispatch(setLocalService(serviceId));
};

export const setMenu = menuId => dispatch => {
  menuId = menuId.toString();
  dispatch(setLocalMenu(menuId));
};

export const setLocalService = serviceId => ({
  type: SET_SERVICE_ID,
  serviceId,
});

export const setLocalMenu = menuId => ({
  type: SET_MENU_ID,
  menuId,
});
