import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectOrderTotal } from 'selectors/order';
import { getServiceId, selectAvailablePaymentMethods, selectTabEnabledByServiceId } from 'selectors/root';
import FormHeader from 'components/Form/FormHeader';
import PaymentSelection from 'components/PaymentSelection';
import messages from './intl';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  tabEnabled: PropTypes.bool,
  formSubmitting: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.instanceOf(List).isRequired,
  orderTotal: PropTypes.number,
  totalDue: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  form: PropTypes.object,
  serviceId: PropTypes.string.isRequired,
};

class Payment extends React.Component {
  componentDidUpdate() {
    const { paymentMethods } = this.props;
    if (!this.props.value && paymentMethods.size === 1) {
      this.onChange(paymentMethods.getIn([0, 'key']));
    }
  }

  paymentTile = () => {
    const { paymentMethods } = this.props;
    return paymentMethods.size > 1 ? messages.selectPaymentMethod : messages.makePayment;
  };

  onChange = paymentKey => {
    this.props.onChange(paymentKey);
    this.props.onBlur();
  };

  render() {
    const { paymentMethods } = this.props;
    if (paymentMethods.size === 1) return null;

    return (
      <section>
        <FormHeader title={<FormattedMessage {...this.paymentTile()} />} />

        <PaymentSelection
          paymentMethods={paymentMethods.toJS()}
          onChange={this.onChange}
          value={this.props.value}
          disabled={this.props.formSubmitting}
        />
      </section>
    );
  }
}

Payment.propTypes = propTypes;

const mapStateToProps = (state, { totalDue }) => {
  const orderTotal = totalDue || selectOrderTotal(state);

  return {
    paymentMethods: selectAvailablePaymentMethods(state, orderTotal),
    orderTotal,
    tabEnabled: !!selectTabEnabledByServiceId(state),
    paymentSubmitted: state.getIn(['payment', 'waiting']),
    serviceId: getServiceId(state),
  };
};

export default connect(mapStateToProps)(Payment);
