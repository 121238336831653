import { css } from 'styled-components';

export const linkUnderlined = css`
  color: ${({ theme }) => theme.color.link};
  text-decoration: underline;
  line-height: 22px;

  &:hover {
    text-decoration: none;
  }
`;
