import React from 'react';
import PropTypes from 'prop-types';

// Icon
const VegetarianIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon vegetarianIcon"
        fill={fill}
        opacity={opacity}
        d="M807.8,105.7L553,894.3H447L192.2,105.7h94.2c10.2,0,18.5,3.1,24.9,9.3c6.4,6.2,11.2,14.2,14.4,24L474.5,620
	c4.9,15.6,9.7,32.8,14.2,51.5c4.5,18.7,8.8,38.3,12.9,58.6c3.5-20.4,7.3-39.9,11.3-58.6c4.1-18.7,8.6-35.9,13.5-51.5l147.9-481.1
	c2.3-8.4,6.9-16,13.7-22.9c6.8-6.9,15.2-10.4,25.1-10.4H807.8z"
      />
    </g>
  </svg>
);

VegetarianIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

VegetarianIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default VegetarianIcon;
