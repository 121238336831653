import React from 'react';
import { withCookies as reactCookies, Cookies } from 'react-cookie'; // eslint-disable-line no-restricted-imports
import { instanceOf } from 'prop-types';

import excludeProps from 'utils/excludeProps';

const YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

const withCookies = WrappedComponent => {
  class HOC extends React.Component {
    getCookie = name => this.props.cookies.get(name);

    getAllCookies = () => this.props.cookies.getAll();

    setCookie = (name, value, options = {}) => {
      const defaultOptions = {
        path: '/',
        domain: __config__.COOKIE_DOMAIN,
      };

      if (options.maxLifetime) {
        delete options.maxLifetime;
        defaultOptions.maxAge = YEAR_IN_SECONDS;
      }

      this.props.cookies.set(name, value, Object.assign(defaultOptions, options));
    };

    removeCookie = name => this.props.cookies.remove(name);

    render() {
      const props = excludeProps(this.props, ['cookies', 'allCookies']);
      return (
        <WrappedComponent
          {...props}
          getCookie={this.getCookie}
          getAllCookies={this.getAllCookies}
          setCookie={this.setCookie}
          removeCookie={this.removeCookie}
        />
      );
    }
  }

  HOC.propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  return reactCookies(HOC);
};

export default withCookies;
