import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ProductPrice from 'components/Product/price';
import FormattedModifierValue from 'components/FormattedModifierValue';
import ItemName from 'components/OrderReview/ItemName';
import ItemPrice from 'components/OrderReview/ItemPrice';
import { getModifierPrice } from 'selectors/browse';
import NotesIcon from 'components/Icons/NotesIcon';

import messages from '../messages';
import { LineItem, LineItemFlex, Modifier, StyledPrice } from './styles';

const ProductLineItem = ({ item, getModifierPrice }) => (
  <LineItem>
    <LineItemFlex>
      <ItemName item={item} />
      <StyledPrice>
        <ItemPrice item={item} />
      </StyledPrice>
    </LineItemFlex>
    {item.get('modifiers') &&
      item.get('modifiers').map(modifier =>
        modifier.get('values').map((value, index) => (
          <Modifier key={index}>
            <LineItemFlex>
              {modifier.get('modifier') && `- ${modifier.getIn(['modifier', 'name'])}: `}
              <FormattedModifierValue
                value={value.get('value')}
                type={modifier.getIn(['modifier', 'type'])}
              />
              {value.get('quantity') ? ` x ${value.get('quantity')}` : ''}

              <StyledPrice>
                {!value.get('price_adjustment') ? (
                  ' - '
                ) : (
                  <ProductPrice price={getModifierPrice(value, item.get('tax_category_ids'))} />
                )}
              </StyledPrice>
            </LineItemFlex>
          </Modifier>
        ))
      )}
    {(item.get('notes') || null) && (
      <div>
        <NotesIcon height="20px" />
        <FormattedMessage {...messages.has_notes} />
      </div>
    )}
  </LineItem>
);

ProductLineItem.propTypes = {
  item: PropTypes.instanceOf(Map).isRequired,
  getModifierPrice: PropTypes.func,
};

const mapStateToProps = state => ({
  getModifierPrice: (modifier, taxCategories) => getModifierPrice(state, modifier, taxCategories),
});

export default connect(mapStateToProps)(ProductLineItem);
