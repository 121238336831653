import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectDineInPasscode, isDineInOrderingFlow } from 'selectors/root';
import globalMessages from 'components/globalMessages';

import { OuterContainer, Container } from './styles';

type Props = {
  passcode?: string;
  isDineInOrderingFlow?: boolean;
};

const ActivePasscode = ({ passcode, isDineInOrderingFlow }: Props) => {
  if (!isDineInOrderingFlow || !passcode) return null;

  return (
    <OuterContainer>
      <Container>
        <strong>
          <FormattedMessage {...globalMessages.yourPasscode} />: #{passcode}
        </strong>
      </Container>
    </OuterContainer>
  );
};

export default connect(state => ({
  passcode: selectDineInPasscode(state),
  isDineInOrderingFlow: isDineInOrderingFlow(state),
}))(ActivePasscode);
