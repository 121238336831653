import React from 'react';

import {
  BeerIcon,
  AlshayaBurgerIcon,
  CoffeeIcon,
  ChatIcon,
  CupIcon,
  HotDrinkIcon,
  AlshayaDrinksIcon,
  FriesIcon,
  HotdogIcon,
  IceCreamIcon,
  GuestDirectoryIcon,
  TwitterIcon,
  YouTubeIcon,
  FacebookIcon,
  TermsIcon,
  YelpIcon,
  GoogleIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  WebsiteIcon,
  CareemIcon,
  DeliverooIcon,
  DoordashIcon,
  GrubhubIcon,
  UbereatsIcon,
  TalabatIcon,
  ZomatoIcon,
  ToyouIcon,
  MrsoolIcon,
  JahezIcon,
  HungerstationIcon,
  CloseIcon,
  YesIcon,
  InfoIcon,
  AlertIcon,
  RefreshIcon,
  PointerIcon,
  TakeawayIcon,
  ServiceIcon,
  CarIcon,
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  EloIcon,
  ElvIcon,
  JcbIcon,
  MadaIcon,
  MaestroIcon,
  MastercardIcon,
  SwitchIcon,
  UnionPayIcon,
  VisaIcon,
  PayPalIcon,
  VisaCardIcon,
  MastercardCardIcon,
  MaestroCardIcon,
  SoloCardIcon,
  AmexCardIcon,
  ChampagneIcon,
  CalendarIcon,
  SpaIcon,
  KidsIcon,
  SidesIcon,
  DipsIcon,
  ChickenIcon,
  BurgerIcon,
  DrinksIcon,
  AshaBreakfastIcon,
  AshaAppetizersIcon,
  AshaBeveragesIcon,
  AshaBiryaniIcon,
  AshaBreadIcon,
  AshaCurriesNonVegIcon,
  AshaDessertsIcon,
  AshaCurriesVegIcon,
  AshaHotBeveragesIcon,
  AshaKebabPlattersIcon,
  AshaMealBundlesIcon,
  AshaSidesIcon,
  AshaSizzlersIcon,
  AshaWrapsIcon,
  AshaSoupsAndSaladsIcon,
  AshaKidsMenuIcon,
  TiktokIcon,
  AuraIcon,
  ShakeShackChickenIcon,
} from 'components/Icons';

const getIcon = (iconName, { defaultIcon, darkMode, width, height } = {}) => {
  switch (iconName) {
    case 'visaCard':
      return <VisaCardIcon width={width} height={height} />;
    case 'mastercardCard':
      return <MastercardCardIcon width={width} height={height} />;
    case 'maestroCard':
      return <MaestroCardIcon width={width} height={height} />;
    case 'soloCard':
      return <SoloCardIcon width={width} height={height} />;
    case 'amexCard':
      return <AmexCardIcon width={width} height={height} />;
    case 'visa':
      return <VisaIcon width={width} height={height} />;
    case 'unionpay':
      return <UnionPayIcon width={width} height={height} />;
    case 'switch':
      return <SwitchIcon width={width} height={height} />;
    case 'mastercard':
      return <MastercardIcon width={width} height={height} />;
    case 'maestro':
      return <MaestroIcon width={width} height={height} />;
    case 'mada':
      return <MadaIcon width={width} height={height} />;
    case 'jcb':
      return <JcbIcon width={width} height={height} />;
    case 'elv':
      return <ElvIcon width={width} height={height} />;
    case 'elo':
      return <EloIcon width={width} height={height} />;
    case 'disc':
    case 'discover':
      return <DiscoverIcon width={width} height={height} />;
    case 'dins':
    case 'diners':
      return <DinersIcon width={width} height={height} />;
    case 'amex':
      return <AmexIcon width={width} height={height} />;
    case 'paypal':
      return <PayPalIcon width={width} height={height} />;
    case 'car':
      return <CarIcon width={width} height={height} />;
    case 'room':
      return <ServiceIcon width={width} height={height} />;
    case 'address':
      return <TakeawayIcon width={width} height={height} />;
    case 'point':
      return <PointerIcon width={width} height={height} />;
    case 'syncing':
      return <RefreshIcon width={width} height={height} />;
    case 'error':
      return <CloseIcon width={width} height={height} />;
    case 'success':
    case 'synced':
    case 'confirmed':
      return <YesIcon width={width} height={height} />;
    case 'info':
    case 'pending':
      return <InfoIcon width={width} height={height} />;
    case 'warning':
    case 'failed':
      return <AlertIcon width={width} height={height} />;
    case 'careem':
      return <CareemIcon width={width} height={height} />;
    case 'deliveroo':
      return <DeliverooIcon width={width} height={height} />;
    case 'doordash':
      return <DoordashIcon width={width} height={height} />;
    case 'grubhub':
      return <GrubhubIcon width={width} height={height} />;
    case 'ubereats':
      return <UbereatsIcon width={width} height={height} fill={darkMode ? '#fff' : '#000'} />;
    case 'talabat':
      return <TalabatIcon width={width} height={height} />;
    case 'hungerstation':
      return <HungerstationIcon width={width} height={height} />;
    case 'zomato':
      return <ZomatoIcon width={width} height={height} />;
    case 'jahez':
      return <JahezIcon width={width} height={height} />;
    case 'toyou':
      return <ToyouIcon width={width} height={height} />;
    case 'mrsool':
      return <MrsoolIcon width={width} height={height} />;
    case 'beer':
      return <BeerIcon width={width} height={height} />;
    case 'alshaya_burger':
      return <AlshayaBurgerIcon width={width} height={height} />;
    case 'coffee':
      return <CoffeeIcon width={width} height={height} />;
    case 'chat':
      return <ChatIcon width={width} height={height} />;
    case 'cup':
      return <CupIcon width={width} height={height} />;
    case 'hotdrink':
      return <HotDrinkIcon width={width} height={height} />;
    case 'alshaya_drinks':
      return <AlshayaDrinksIcon width={width} height={height} />;
    case 'fries':
      return <FriesIcon width={width} height={height} />;
    case 'hotdog':
      return <HotdogIcon width={width} height={height} />;
    case 'icecream':
      return <IceCreamIcon width={width} height={height} />;
    case 'directory':
      return <GuestDirectoryIcon width={width} height={height} />;
    case 'terms':
      return <TermsIcon width={width} height={height} />;
    case 'yelp':
      return <YelpIcon width={width} height={height} />;
    case 'google':
      return <GoogleIcon width={width} height={height} />;
    case 'twitter':
      return <TwitterIcon width={width} height={height} />;
    case 'youtube':
      return <YouTubeIcon width={width} height={height} />;
    case 'facebook':
      return <FacebookIcon width={width} height={height} />;
    case 'instagram':
      return <InstagramIcon width={width} height={height} />;
    case 'linkedin':
      return <LinkedInIcon width={width} height={height} />;
    case 'pinterest':
      return <PinterestIcon width={width} height={height} />;
    case 'tiktok':
      return <TiktokIcon width={width} height={height} />;
    case 'website':
      return <WebsiteIcon width={width} height={height} />;
    case 'champagne':
      return <ChampagneIcon width={width} height={height} />;
    case 'calendar':
      return <CalendarIcon width={width} height={height} />;
    case 'spa':
      return <SpaIcon width={width} height={height} />;
    case 'kids':
      return <KidsIcon width={width} height={height} />;
    case 'sides':
      return <SidesIcon width={width} height={height} />;
    case 'dips':
      return <DipsIcon width={width} height={height} />;
    case 'chicken':
      return <ChickenIcon width={width} height={height} />;
    case 'burger':
      return <BurgerIcon width={width} height={height} />;
    case 'drinks':
      return <DrinksIcon width={width} height={height} />;
    case 'asha-breakfast':
      return <AshaBreakfastIcon width={width} height={height} />;
    case 'asha-appetizers':
      return <AshaAppetizersIcon width={width} height={height} />;
    case 'asha-beverages':
      return <AshaBeveragesIcon width={width} height={height} />;
    case 'asha-biryani':
      return <AshaBiryaniIcon width={width} height={height} />;
    case 'asha-bread':
      return <AshaBreadIcon width={width} height={height} />;
    case 'asha-curries-non-veg':
      return <AshaCurriesNonVegIcon width={width} height={height} />;
    case 'asha-desserts':
      return <AshaDessertsIcon width={width} height={height} />;
    case 'asha-curries-veg':
      return <AshaCurriesVegIcon width={width} height={height} />;
    case 'asha-hot-beverages':
      return <AshaHotBeveragesIcon width={width} height={height} />;
    case 'asha-kebab-platters':
      return <AshaKebabPlattersIcon width={width} height={height} />;
    case 'asha-meal-bundles':
      return <AshaMealBundlesIcon width={width} height={height} />;
    case 'asha-sides':
      return <AshaSidesIcon width={width} height={height} />;
    case 'asha-sizzlers':
      return <AshaSizzlersIcon width={width} height={height} />;
    case 'asha-wraps':
      return <AshaWrapsIcon width={width} height={height} />;
    case 'asha-soups-and-salads':
      return <AshaSoupsAndSaladsIcon width={width} height={height} />;
    case 'asha-kids-menu':
      return <AshaKidsMenuIcon width={width} height={height} />;
    case 'aura':
      return <AuraIcon width={width} height={height} />;
    case 'shakeshackchicken':
      return <ShakeShackChickenIcon />;
    default:
      return defaultIcon || null;
  }
};

export default getIcon;
