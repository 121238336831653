import { getFavourites } from 'dataLoader';
import { REQUEST_FAVOURITES, RECEIVE_FAVOURITES_PRODUCTS } from './constants';

function requestFavourites() {
  return {
    type: REQUEST_FAVOURITES,
  };
}

function receiveFavourites({ favourites, success }) {
  return {
    type: RECEIVE_FAVOURITES_PRODUCTS,
    favourites,
    success,
    receivedAt: Date.now(),
  };
}

export const fetchFavourites = () => dispatch => {
  dispatch(requestFavourites());

  const cb = res => dispatch(receiveFavourites(res));
  return getFavourites(cb);
};
