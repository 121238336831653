import React from 'react';
import PropTypes from 'prop-types';

class CurrencyCodeProvider extends React.Component {
  getChildContext() {
    return { currency: this.props.currency };
  }

  render() {
    return this.props.children;
  }
}

CurrencyCodeProvider.childContextTypes = {
  currency: PropTypes.string,
};

CurrencyCodeProvider.propTypes = {
  children: PropTypes.node,
  currency: PropTypes.string,
};

export default CurrencyCodeProvider;
