import React from 'react';
import PropTypes from 'prop-types';

const DrinksIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 39 53"
  >
    <path
      d="M34.8612 13.8699H32.3763L30.5699 10.2677C30.393 9.9167 30.0336 9.69472 29.6406 9.69331H20.4315L21.131 5.51669L27.5524 5.51704C28.1292 5.51704 28.5966 5.04928 28.5966 4.4728C28.5966 3.89632 28.1292 3.42855 27.5524 3.42855H20.2437C19.7263 3.42191 19.2824 3.79493 19.1995 4.30569L18.3119 9.69329H8.75813C8.36518 9.69468 8.0058 9.91668 7.82888 10.2677L6.02249 13.8699H3.53753C2.96105 13.8699 2.49329 14.3373 2.49329 14.9141V19.0908V19.0904C2.49329 19.3673 2.60341 19.633 2.79918 19.8288C2.99496 20.0245 3.26065 20.1347 3.53753 20.1347H5.69869L7.57812 46.4574C7.6337 47.2495 7.98819 47.9911 8.56991 48.5319C9.15129 49.0727 9.91657 49.3723 10.7105 49.3706H27.6987C28.493 49.3723 29.2583 49.0727 29.8396 48.5319C30.4214 47.9911 30.7755 47.2496 30.8311 46.4574L31.4055 38.4489L32.011 29.9184L32.7004 20.1347H34.8615C35.1384 20.1347 35.4041 20.0245 35.5999 19.8288C35.7956 19.633 35.9058 19.3673 35.9058 19.0904V14.9142C35.9058 14.6373 35.7956 14.3716 35.5999 14.1758C35.4041 13.98 35.1384 13.8699 34.8615 13.8699L34.8612 13.8699ZM9.40578 11.7818H28.9939L30.0381 13.8699H8.36207L9.40578 11.7818ZM29.3697 37.4673C26.1182 38.3707 22.6586 38.1686 19.5341 36.8929C16.1696 35.5257 12.4562 35.2693 8.93592 36.1619L8.49753 30.0747C11.9037 29.0108 15.5744 29.1696 18.8755 30.5236C20.8986 31.3105 23.0434 31.738 25.2133 31.787C26.768 31.766 28.3142 31.5559 29.8178 31.1605L29.3697 37.4673ZM27.699 47.2815H10.701C10.1514 47.2829 9.69521 46.8584 9.65672 46.3106L9.09282 38.2915C12.3297 37.4074 15.7676 37.613 18.8766 38.8764C20.8996 39.663 23.0444 40.0905 25.2144 40.1398C26.5603 40.1252 27.9007 39.9675 29.2134 39.67L28.7436 46.3105C28.7051 46.8583 28.2489 47.2827 27.6993 47.2814L27.699 47.2815ZM29.9753 28.9678C26.5524 30.0537 22.8568 29.902 19.5346 28.5399C15.9779 27.0978 12.0397 26.8919 8.35205 27.955L7.78814 20.1346H30.6124L29.9753 28.9678ZM33.8177 18.0466H4.58281V15.9581H33.8177V18.0466Z"
      className="icon drinksIcon"
      fill={fill}
    />
  </svg>
);

DrinksIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

DrinksIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default DrinksIcon;
