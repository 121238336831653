import { Map, fromJS } from 'immutable';

import { REQUEST_MENU, RECEIVE_MENU, REQUEST_SECTION, RECEIVE_SECTION } from 'actions/menu/constants';
import { RECEIVE_FAVOURITES_PRODUCTS } from 'actions/favourites/constants';
import {
  RECEIVE_MENU_TYPES,
  REQUEST_MENU_LIST,
  RECEIVE_MENU_LIST,
  SET_BROWSE_INFO,
  SET_BACK_BUTTON,
  SET_MENU_TYPE_ID,
  SET_IS_MENU_ACTIVE,
  SET_SERVICE_ID,
  RECEIVE_PRODUCTS,
  UPDATE_PRODUCTS,
  REQUEST_PRODUCTS,
  SET_MENU_ID,
} from 'actions/browse/constants';

const initialState = fromJS({
  backButton: {
    href: '/',
    text: 'Home',
  },
  isLoading: false,
  sectionIsLoading: false,
  menuTypeId: undefined,
  data: {
    menuTypes: [],
    menus: undefined,
    products: undefined,
  },
  browseInfo: undefined,
});

export default (state = initialState, action) =>
  initialState.merge(
    fromJS(
      browseMeta(state, action).merge(
        fromJS({
          data: browseData(state.get('data'), action),
        })
      )
    )
  );

export const browseMeta = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACK_BUTTON:
      return state.set('backButton', fromJS({ href: action.href, text: action.text }));
    case SET_BROWSE_INFO:
      return state.set(
        'browseInfo',
        fromJS({
          title: action.title,
          description: action.description,
          imagePath: action.imagePath,
        })
      );
    case REQUEST_MENU:
    case REQUEST_PRODUCTS:
    case REQUEST_MENU_LIST:
      return state.set('isLoading', true);

    case RECEIVE_MENU:
    case RECEIVE_PRODUCTS:
    case RECEIVE_MENU_LIST:
      return state.set('isLoading', false);

    case REQUEST_SECTION:
      return state.set('sectionIsLoading', true);

    case RECEIVE_SECTION:
      return state.set('sectionIsLoading', false);

    case SET_MENU_TYPE_ID:
      return state.set('menuTypeId', action.menuTypeId);

    case SET_SERVICE_ID:
      return state.set('serviceId', action.serviceId);

    case SET_MENU_ID:
      return state.set('menuId', action.menuId);

    case '@@CLIENT_INIT':
      return state.merge(
        fromJS({
          serviceId: state.get('serviceId'),
        })
      );

    default:
      return state;
  }
};

export const getProductsFromMenuList = menuList =>
  Object.keys(menuList).reduce(
    (menusReduction, menuId) => getProductsFromMenu(menuList[menuId], menusReduction),
    new Map()
  );

export const getProductsFromMenu = (menu, reduction = new Map()) =>
  (menu?.sections &&
    Object.keys(menu?.sections).reduce(
      (sectionsReduction, sectionId) => getProductsFromSection(menu.sections[sectionId], sectionsReduction),
      reduction
    )) ||
  new Map();

export const getProductsFromSection = (section, reduction = new Map()) =>
  (section?.section_products &&
    section?.section_products.reduce((sectionProductsReduction, sectionProduct) => {
      if (!sectionProduct?.product?.id) return sectionProductsReduction;
      return sectionProductsReduction.set(
        sectionProduct.product.id.toString(),
        fromJS(sectionProduct.product)
      );
    }, reduction)) ||
  new Map();

export const browseData = (state = initialState.get('data'), action) => {
  switch (action.type) {
    case RECEIVE_MENU_TYPES: {
      return state.set('menuTypes', fromJS(action.menuTypes));
    }

    case RECEIVE_MENU_LIST: {
      const products = getProductsFromMenuList(action.menuList);

      return state
        .update('products', currentProducts =>
          typeof currentProducts === 'undefined' ? products : currentProducts.merge(fromJS(products))
        )
        .set('menus', fromJS(action.menuList));
    }

    case RECEIVE_MENU: {
      if (!action.menu) return state;
      const products = getProductsFromMenu(action.menu);

      const menu = fromJS(action.menu);

      return state
        .update('products', currentProducts =>
          typeof currentProducts === 'undefined' ? products : currentProducts.merge(fromJS(products))
        )
        .setIn(['menus', menu.get('id').toString()], menu);
    }

    case RECEIVE_SECTION: {
      if (!action.section) return state;
      const products = getProductsFromSection(action.section);

      return state
        .update('products', currentProducts =>
          typeof currentProducts === 'undefined' ? products : currentProducts.merge(fromJS(products))
        )
        .setIn(
          ['menus', action.section.menu?.toString(), 'sections', action.section.id?.toString()],
          fromJS(action.section)
        );
    }

    case SET_IS_MENU_ACTIVE: {
      if (!action.id) return state;
      return state.mergeDeep(
        fromJS({
          menus: {
            [action.id.toString()]: {
              active: action.active,
              nextActiveTime: action.nextActiveTime,
            },
          },
        })
      );
    }

    case RECEIVE_PRODUCTS: {
      const products = fromJS(action.products).reduce(
        (productsReduction, product) => productsReduction.set(product.get('id').toString(), product),
        new Map()
      );

      return state.update('products', currentProducts =>
        typeof currentProducts === 'undefined' ? products : currentProducts.merge(products)
      );
    }

    case UPDATE_PRODUCTS: {
      const products = fromJS(action.products);

      products.forEach(product => {
        state = state.mergeIn(['products', product.get('id').toString()], product);
      });
      return state;
    }

    case RECEIVE_FAVOURITES_PRODUCTS: {
      if (action.favourites) {
        const products = fromJS(action.favourites).reduce(
          (productsReduction, product) =>
            productsReduction.set(product.getIn(['product', 'id']).toString(), product.get('product')),
          new Map()
        );

        return state.update('products', currentProducts =>
          typeof currentProducts === 'undefined' ? products : currentProducts.merge(products)
        );
      }
      return state;
    }
    default:
      return state;
  }
};
