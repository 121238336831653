import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import FormattedPrice from 'components/FormattedPrice';
import InfoRow from './InfoRow';
import messages from '../../../../Pages/User/Orders/messages';
import { Title, OrderInfoContainer, Uppercase } from '../styles';

const InfoSummary = ({ order, venueName }) => (
  <>
    <Title>
      <FormattedMessage {...messages.orderInformation} />
    </Title>

    <OrderInfoContainer>
      <InfoRow message={messages.orderFrom} data={venueName} />
      <InfoRow message={messages.orderNumber} data={order.get('number')} />
      <InfoRow
        message={messages.orderTime}
        data={
          <FormattedDate
            value={order.get('completed_at') || order.get('updated_at')}
            year="numeric"
            month="long"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        }
      />
      <InfoRow message={messages.orderTotal} data={<FormattedPrice value={order.get('total')} />} />
      <InfoRow message={messages.orderStatus} data={<Uppercase>{order.get('state')}</Uppercase>} />
      {typeof order.get('covers') === 'number' && (
        <InfoRow message={messages.covers} data={order.get('covers')} />
      )}
    </OrderInfoContainer>
  </>
);

InfoSummary.propTypes = {
  order: PropTypes.instanceOf(Map).isRequired,
  venueName: PropTypes.string,
};

export default InfoSummary;
