import React from 'react';

interface Props {
  width?: string | number;
  height?: string | number;
}

const CircleQuestion: React.FC<Props> = ({ width = 16, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22.8571" cy="22.8571" r="22.8571" fill="#55A122" />
    <path
      d="M21.0823 27.2446L21.0228 23.7383C21.9935 23.3619 22.7859 22.9855 23.4 22.6091C24.0339 22.2328 24.5192 21.8564 24.856 21.48C25.2126 21.0838 25.4602 20.6777 25.5988 20.2617C25.7375 19.8259 25.8068 19.3802 25.8068 18.9246C25.8068 18.3699 25.7078 17.8945 25.5097 17.4983C25.3314 17.0823 25.0739 16.7356 24.7371 16.4583C24.4202 16.181 24.0438 15.9829 23.608 15.864C23.192 15.7253 22.7463 15.656 22.2708 15.656C21.5379 15.656 20.7653 15.7749 19.9531 16.0126C19.1607 16.2503 18.3684 16.6267 17.576 17.1417L16.5657 14.616C17.1204 14.2198 17.7345 13.883 18.408 13.6057C19.1013 13.3284 19.8244 13.1105 20.5771 12.952C21.3299 12.7935 22.0529 12.7143 22.7463 12.7143C23.5981 12.7143 24.4004 12.8331 25.1531 13.0709C25.9257 13.2888 26.6091 13.6354 27.2034 14.1109C27.8175 14.5863 28.2929 15.2004 28.6297 15.9531C28.9863 16.6861 29.1646 17.5676 29.1646 18.5977C29.1646 19.6476 28.9764 20.5688 28.6 21.3611C28.2434 22.1535 27.6689 22.8568 26.8766 23.4709C26.104 24.085 25.1036 24.6693 23.8754 25.224V27.2446H21.0823ZM20.7257 33.3657C20.7257 32.7318 20.8545 32.2762 21.112 31.9989C21.3893 31.7215 21.8647 31.5829 22.5383 31.5829C23.192 31.5829 23.6476 31.7215 23.9051 31.9989C24.1825 32.2762 24.3211 32.7318 24.3211 33.3657C24.3211 33.9798 24.1825 34.4255 23.9051 34.7029C23.6476 34.9802 23.192 35.1189 22.5383 35.1189C21.8647 35.1189 21.3893 34.9802 21.112 34.7029C20.8545 34.4255 20.7257 33.9798 20.7257 33.3657Z"
      fill="white"
    />
  </svg>
);

export default CircleQuestion;
