import styled, { css } from 'styled-components';
import { StyledInput } from 'assets/styles/sharedStyles';

const SingleDigitShared = css`
  text-align: center;
  padding: 12px 14px;
  margin: 0 5px;
  width: 60px;
`;

export const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
`;

export const SingleDigitInput = styled.input`
  ${StyledInput}
  ${SingleDigitShared}
`;

export const SingleDigit = styled.span`
  ${StyledInput}
  ${SingleDigitShared}

  border: 1px solid ${({ theme }) => theme.color.borderGray};
  color: ${({ theme }) => theme.color.success};
  background-color: ${({ theme }) => theme.color.white};
`;
