import { fromJS } from 'immutable';

export const initialState = fromJS({
  trackingId: undefined,
  venueDimension: undefined,
  trackingData: undefined,
});

export const getTrackingId = state => state.getIn(['analytics', 'trackingId']);
export const getVenueDimension = state => state.getIn(['analytics', 'venueDimension']);
export const getFacebookPixelId = state =>
  state.getIn(['analytics', 'trackingData', 'facebookPixelId'], false);
export const getCustomMetaTags = state => state.getIn(['analytics', 'trackingData', 'metaTags'], false);
export const getGtmId = state => state.getIn(['analytics', 'trackingData', 'googleTagManagerId'], false);

export default (state = initialState) => state;
