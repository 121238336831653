import React from 'react';
import PropTypes from 'prop-types';

const CrossIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16.237 16.157"
  >
    <path
      fill={fill}
      className="icon CrossIcon NoIcon"
      d="M22.757,24.126,16.088,30.8l-1.369-1.369,6.668-6.668-6.749-6.669,1.451-1.451,6.668,6.747,6.668-6.747,1.451,1.451-6.749,6.669L30.8,29.427,29.427,30.8Z"
      transform="translate(-14.639 -14.639)"
    />
  </svg>
);

CrossIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CrossIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CrossIcon;
