import styled from 'styled-components';
import { H4 } from 'components/Typography';

export const Header = styled.div`
  color: ${({ theme }) => theme.color.primaryText};
  font-weight: 400;
  text-align: center;
  margin: 0 3px 10px 3px;

  @media (min-width: 768px) {
    text-align: initial;
  }
`;

export const Title = styled(H4)`
  display: inline;
`;

export const RequiredTxt = styled.p`
  font-size: 12px;
  font-style: italic;
  color: #999;

  @media (min-width: 768px) {
    display: inline;
    margin-left: 5px;
  }
`;
