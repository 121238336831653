import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { List } from 'immutable';
import { browserHistory } from 'react-router';

import FeedbackForm from 'components/FeedbackForm';
import { fetchQuestions, submitAnswers } from 'actions/feedback';
import { getFeedbackQuestions, answersLoading } from 'selectors/feedback';
import { SurveyIcon } from 'components/Icons';
import Page from '../container';

const messages = defineMessages({
  title: {
    defaultMessage: 'Feedback',
  },
});

const propTypes = {
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fetchQuestions: PropTypes.func,
  questions: PropTypes.instanceOf(List),
  answersLoading: PropTypes.bool,
  submitAnswers: PropTypes.func,
};

const Feedback = ({ serviceId, fetchQuestions, questions, submitAnswers, answersLoading }) => {
  useEffect(() => {
    if (!serviceId) browserHistory.push('/');

    if (serviceId && questions.size === 0) {
      fetchQuestions(serviceId);
    }
  }, [fetchQuestions, questions.size, serviceId]);

  return (
    <Page settingsPage={true} titleMessage={messages.title} Icon={SurveyIcon}>
      {questions.size > 0 ? (
        <FeedbackForm questions={questions.toJS()} onSubmit={submitAnswers} submitting={answersLoading} />
      ) : (
        <div />
      )}
    </Page>
  );
};

Feedback.propTypes = propTypes;

export default connect(
  (state, { params }) => ({
    serviceId: params?.serviceId || false,
    questions: getFeedbackQuestions(state),
    answersLoading: answersLoading(state),
  }),
  { fetchQuestions, submitAnswers }
)(Feedback);
