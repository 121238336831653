import styled, { css } from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';
import { H3 } from 'components/Typography';

const marginBottom = css`
  margin-bottom: 5px;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.color.text};
  display: block;
  padding: 10px;
  transition: all 0.5s ease-in-out;
`;

export const CompanyDetails = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  padding: 10px 0;
  margin-bottom: 10px;
`;

export const Label = styled.p`
  font-weight: normal;
  color: #999;
  line-height: 15px;
  margin-top: 5px;
  ${fontSizeMedium}
`;

export const CompanyName = styled(H3)`
  color: ${({ theme }) => theme.color.primaryText};
  ${marginBottom}
`;

export const Draft = styled.div`
  background-color: ${({ theme }) => theme.color.secondaryBackground};
  padding: 5px;
  margin-top: 20px;
`;

export const FeedbackContainer = styled.div`
  margin: 40px 0 10px 0;
  display: inline-block;
  width: 100%;
`;
