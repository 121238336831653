import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CrustaceansIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon crustationsIcon"
        fill={fill}
        opacity={opacity}
        d="M971.2,324c-19.7,30.7-52.2,55.1-52.2,55.1s14.7-36.7,29.3-80.6c2.7-8.1,3.4-17.9,2.7-28.5c-25.7-57.7-62-101.7-105.3-133	c-2.3,21.7-4.8,43.4-7.4,58.9c-4.4,26.4-27.3,52.8-44.9,69.7c-11.7,11.2-11.7,11.2-11.7,11.2s-9,6.2-8.5,4.9	c3.3-8.2,15.8-40.6,28.5-78.5c8.5-25.4-2.6-67.7-13.4-99.1c-99.5-43.8-219.8-37.8-323.8,3.8C281.2,181.2,10,701.6,10,701.6	l260.7-315.8l-84.7,154.5c0,0,131.9-102.6,212.6-110c80.6-7.3,95.3-95.3,205.2-102.6c14.1-0.9,28.3,0.1,42.3,2.7	c-21.6,17-45.3,38.9-65.9,65.3c-53.9,69.3-69.4,159.4-70,163.2c-1.9,11.2,5.7,21.9,17,23.7c1.1,0.2,2.3,0.3,3.4,0.3	c9.9,0,18.6-7.2,20.3-17.2c0.1-0.8,14.3-83.5,61.9-144.7c25.8-33.2,57.8-58.6,81.9-75.1c4.6,2,9.2,4.1,13.7,6.4	c-45,66.6-58.4,144.4-59,147.9c-1.9,11.2,5.7,21.9,16.9,23.7c1.2,0.2,2.3,0.3,3.4,0.3c9.9,0,18.7-7.2,20.4-17.2	c0.1-0.8,12.7-73.9,53.7-133.5c50.8,35.3,89.7,83.1,101.9,115.4c22,58.6-58.6,124.6-58.6,124.6s-197.9,0-190.6,58.6	c7.3,58.6,153.9,0,153.9,0S559.8,774.9,567.1,826.2C574.4,877.6,809,709,809,709S647.7,877.6,662.4,921.5	c13,39,204.4-181.1,247.8-231.6C879.5,659,872.4,592,872.4,592s23.9,31.4,54.2,66.5c1.2,1.4,2.7,2.7,4.2,4.1	C958.4,618.9,1021.4,493.9,971.2,324z M757.3,187.4l-0.1,0.7l-0.2,0.7c-12.6,37.7-25.1,69.6-27.5,75.6l-12.3,31.5l-62.6-11.6	c-13.7-2.5-27.3-3.8-40.5-3.8c-4.5,0-9,0.2-13.5,0.4c-75.5,5-117.6,42-148.4,68.9c-23.9,20.9-37.3,31.9-58,33.8	c-17.5,1.6-37.3,6.4-58.6,14.4l-21.5,8l42.8-84.8c-0.5-8.9-2-39.4,0.1-62.4l0.3-2.9l1.9-2.2c62.1-71.7,96.9-92,122.8-102.3	c50.2-20.1,104-30.7,155.7-30.7c38.4,0,74.8,5.7,108,17l4.5,1.5l1.2,4.6C758.9,171.9,757.8,184.3,757.3,187.4z M854.7,413.2	l-9.7-11.2c-17-19.8-37.4-38.9-58.8-55.4l-9.3-7.2l23.8-18c1.2-0.9,11.9-9.1,25.2-21.8c18.6-17.9,33.1-35.9,43.2-53.6l7.3-12.9	l8.1,12.4c6.6,10.2,12.8,21.1,18.4,32.5l1.6,3.3l-1.2,3.5c-14,41.9-27.9,76.6-28,76.9L854.7,413.2z M934.3,530.4	c-2.3,10.4-5.1,20.8-8.4,31l-5.2,16.4l-10.9-14.2l-17.3-22.7l0.9-4.1c5.1-22.3,3.8-43.9-3.8-64.2c-0.4-1.1-0.9-2.2-1.3-3.3l-2.6-6.4	l56.4-42.4l0.6,17.2C943.9,469,941,500.1,934.3,530.4z"
      />
    </g>
  </svg>
);

CrustaceansIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

CrustaceansIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default CrustaceansIcon;
