import React from 'react';
import PropTypes from 'prop-types';

const CarIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M301.1,591.5c0,33.8-58,30.6-91.9,30.6S148,594.7,148,560.9c0-33.8,27.4-61.2,61.2-61.2
	S301.1,557.7,301.1,591.5z M791.1,499.6c-33.8,0-91.9,58-91.9,91.9c0,33.8,58,30.6,91.9,30.6s61.2-27.4,61.2-61.2
	C852.4,527.1,825,499.6,791.1,499.6z M989,330.8l-15.3,45.9c-3.1,9.4-11.9,15.7-21.8,15.7h-54.6c38.2,33.7,62.2,82.9,62.2,137.8
	v107.2c0,29.2-11.7,55.7-30.6,75v78.1c0,42.3-34.3,76.6-76.6,76.6h-91.9c-42.3,0-76.6-34.3-76.6-76.6v-45.9H316.5v45.9
	c0,42.3-34.3,76.6-76.6,76.6H148c-42.3,0-76.6-34.3-76.6-76.6v-78.1c-18.9-19.3-30.6-45.8-30.6-75V530.3
	c0-54.9,24.1-104.1,62.2-137.8H48.5c-9.9,0-18.7-6.3-21.8-15.7l-15.3-45.9c-5-14.9,6.1-30.2,21.8-30.2h61.2
	c9.9,0,18.7,6.3,21.8,15.7l9.9,29.7l58.5-125.4c25.1-53.7,79.5-88.4,138.8-88.4H677c59.2,0,113.7,34.7,138.8,88.4l58.5,125.4
	l9.9-29.7c3.1-9.4,11.9-15.7,21.8-15.7h61.2C982.9,300.6,994,315.9,989,330.8z M192.1,349.4c10.7-1.9,21.6-2.9,32.5-2.9h551.2
	c11.1,0,22,1,32.5,2.9l-48.1-103c-15-32.2-47.7-53-83.3-53H323.4c-35.5,0-68.2,20.8-83.3,53L192.1,349.4L192.1,349.4z M255.2,744.6
	c-115.9,0-111.6,0.4-122.5-1.1v47c0,8.4,6.9,15.3,15.3,15.3h91.9c8.4,0,15.3-6.9,15.3-15.3V744.6z M867.7,743.5
	c-10.9,1.6-6.8,1.1-122.5,1.1v45.9c0,8.4,6.9,15.3,15.3,15.3h91.9c8.4,0,15.3-6.9,15.3-15.3V743.5z M775.8,407.8H224.6
	c-67.5,0-122.5,55-122.5,122.5v107.2c0,25.3,20.6,45.9,45.9,45.9h704.4c25.3,0,45.9-20.6,45.9-45.9V530.3
	C898.3,462.7,843.4,407.8,775.8,407.8z M641.9,560.9H358.6c-6.7,0-11.5,4.2-11.5,15.3c0,25.4,20.6,45.9,45.9,45.9h214.4
	c25.4,0,45.9-20.6,45.9-45.9C653.3,565.1,648.6,560.9,641.9,560.9z"
      />
    </g>
  </svg>
);

CarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CarIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CarIcon;
