import styled from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

export const ButtonStyled = styled.button`
  background-color: ${({ theme, located }) => (located ? theme.color.danger : theme.color.white)};
  border: 1px solid ${({ theme, located }) => (located ? theme.color.danger : theme.color.borderGray)};
  width: 100%;
  color: ${({ theme, located }) => (located ? theme.color.white : theme.color.darkGrey)};
  padding: 8px;
  font-size: 1.2em;
  height: 42px;
  border-radius: 21px;
  ${fontSizeMedium};
  margin-left: 5px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }
`;

export const LocationIconContainer = styled.span`
  color: ${({ theme, located }) => (located ? theme.color.white : theme.color.success)};
`;

export const Text = styled.span`
  margin-left: 5px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }
`;
