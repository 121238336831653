import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import FormattedPrice from 'components/FormattedPrice';

const messages = defineMessages({
  free: {
    defaultMessage: 'Free',
  },
  unknown: {
    defaultMessage: '–',
  },
});

const ProductPrice = ({ price }) => {
  if (price === 0) return <FormattedMessage {...messages.free} />;
  if (typeof price === 'undefined') return <FormattedMessage {...messages.unknown} />;
  return <FormattedPrice value={price} />;
};

ProductPrice.propTypes = {
  price: PropTypes.number,
};

export default ProductPrice;
