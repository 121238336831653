import { TickIcon } from 'components/Icons';
import { Offer } from 'hooks/useLoyalty';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface OfferListItemProps {
  offer: Offer;
  onRedeem: Function;
  inline?: boolean;
  highlight?: boolean;
}

const StyledOfferListItem = styled.div<{ nonRedeemable: boolean; inline: boolean; highlight: boolean }>`
  ${({ inline }) =>
    inline &&
    `
      > button {
        display: flex;
        flex-redirection: row;

        html[dir=rtl] & {
          flex-redirection: row-reverse;
        }
      }
    `}
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 8px;
  opacity: ${props => (props.nonRedeemable ? 0.5 : 1)};
  ${({ highlight, theme }) => {
    if (highlight) {
      return `
        border-color: ${theme.color.success};
        border-width: 2px;
      `;
    }
  }}
  position: relative;
`;

const ClickTarget = styled.button`
  display: block;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-align: left;
  direction: ltr;

  [dir='rtl'] & {
    text-align: right;
    direction: rtl;
  }
`;

const Image = styled.img<{ inline: boolean }>`
  ${props =>
    props.inline &&
    `
      width: 30%;
      height: auto;
      object-fit: cover;
      margin: 0.5rem 0rem 0.5rem 0.5rem;
    `}

  ${props =>
    !props.inline &&
    `
      width: 100%;
      height: auto;
    `}
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => parseInt(theme.fonts.baseFontSize, 10) / 2}px;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fonts.fontSizeBig};
`;

const PointPrice = styled.span`
  min-width: 25px;
  height: 25px;
  display: inline-block;
  background: red;
  float: right;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  padding: 0 4px;
  margin: 3px;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};
`;

const ExpiryDate = styled.p`
  padding-block-start: 0.5rem;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};
`;

const NonRedeemableReason = styled.p`
  color: ${({ theme }) => theme.color.danger};
  font-size: ${({ theme }) => theme.fonts.fontSizeSmall};
`;

const StyledInBasket = styled.div`
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.success};
  color: ${({ theme }) => theme.color.white};
`;

const StyledFakeButton = styled.div`
  background-color: ${({ theme }) => theme.color.brandSecondary};
  color: ${({ theme }) => theme.color.brandPrimary};
  border-radius: 8px;
  padding: 0.4rem 0;
  margin-top: 10px;
  width: 100px;
  text-align: center;
`;

const OfferListItem: React.FC<OfferListItemProps> = ({
  offer,
  inline = false,
  highlight = false,
  onRedeem,
}) => {
  const { name, description, image, endDate, redeemable, nonRedeemableReason, inBasket, exchangeCost } =
    offer;

  const offerStyle = redeemable ? {} : { nonRedeemable: true };

  const handleClick = (offer: Offer) => (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (offer.redeemable === false) return;

    onRedeem(offer, inline);
  };

  return (
    <StyledOfferListItem
      nonRedeemable={offerStyle.nonRedeemable || false}
      inline={inline}
      highlight={highlight}
    >
      <ClickTarget onClick={handleClick(offer)}>
        {inBasket && <InBasketIcon />}
        <Image inline={inline} src={image} alt={name} />
        <ContentWrapper>
          {exchangeCost && <PointPrice>{exchangeCost}</PointPrice>}
          <Title>{name}</Title>
          <Description>{description}</Description>
          {endDate && offer.type === 'offer' && (
            <ExpiryDate>
              <FormattedMessage
                defaultMessage="Expires {expiryDate}"
                values={{
                  expiryDate: <FormattedDate value={endDate} day="numeric" month="short" year="numeric" />,
                }}
              />
            </ExpiryDate>
          )}
          {inline && redeemable && (
            <StyledFakeButton>
              {!inBasket ? (
                <FormattedMessage defaultMessage="Redeem" />
              ) : (
                <FormattedMessage defaultMessage="Remove" />
              )}
            </StyledFakeButton>
          )}
          {!redeemable && <NonRedeemableReason>{nonRedeemableReason}</NonRedeemableReason>}
        </ContentWrapper>
      </ClickTarget>
    </StyledOfferListItem>
  );
};

const InBasketIcon = () => (
  <StyledInBasket>
    <TickIcon width="24px" height="24px" />
  </StyledInBasket>
);

export default OfferListItem;
