import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormattedPrice from 'components/FormattedPrice';
import globalMessages from 'components/globalMessages';
import OrderItem from './OrderItem';
import messages from '../../../Pages/User/Orders/messages';
import { Title, OrderInfoContainer, CellsContainer, TotalLabel, Price } from './styles';

const OrderItems = ({ items, total }) => (
  <>
    <Title>
      <FormattedMessage {...globalMessages.orderSummary} />
    </Title>

    <OrderInfoContainer>
      {items.map(item => (
        <OrderItem key={item.get('name')} item={item} />
      ))}

      <CellsContainer>
        <TotalLabel>
          <FormattedMessage {...messages.total} />
        </TotalLabel>
        <Price>
          <FormattedPrice value={total} />
        </Price>
      </CellsContainer>
    </OrderInfoContainer>
  </>
);

OrderItems.propTypes = {
  items: PropTypes.instanceOf(List),
  total: PropTypes.number,
};

export default OrderItems;
