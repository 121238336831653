import { fetchHelper } from 'utils';
import { REQUEST_DONATIONS, REQUEST_DONATIONS_SUCCESS, REQUEST_DONATIONS_FAILURE } from './constants';

export const fetchDonations = () => dispatch => {
  dispatch({ type: REQUEST_DONATIONS });

  const success = donationsList => dispatch({ type: REQUEST_DONATIONS_SUCCESS, donationsList });
  const failure = () => dispatch({ type: REQUEST_DONATIONS_FAILURE });

  fetchHelper(`/api/donation-offerings`, 'GET', null, success, failure);
};
