import React from 'react';
import PropTypes from 'prop-types';

const TalabatIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 400 87.163"
  >
    <g transform="translate(0)">
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M36.52,66.182q-4.358,0-6.065-2t-1.707-6.594V38.275H41.7L40.072,23.909H28.748V.029S23.8.357,22.154.487C13.7,1.149,7.992,6.773,7.992,13.35l.033,11.1L.017,25v13.28H8.025V60.412q0,10.128,5.357,15.955T29.22,82.2a47.744,47.744,0,0,0,8.537-.647l.069-.014a8.046,8.046,0,0,0,6.466-7.943v-9.53a16.1,16.1,0,0,1-7.772,2.12"
        transform="translate(355.708 4.964)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M239.187,379.026a11.713,11.713,0,0,0,4.828-3.827v-4.946a20.167,20.167,0,0,0-11.54-3.415,10.048,10.048,0,0,0-6.359,1.766,6.2,6.2,0,0,0-2.237,5.182,6.006,6.006,0,0,0,2.179,4.946q2.177,1.767,6.3,1.766a16.027,16.027,0,0,0,6.83-1.472M209.1,388.092q-6.535-5.122-6.535-14.306,0-9.538,6.3-14.66T226.941,354a43.149,43.149,0,0,1,9.185,1,33.863,33.863,0,0,1,7.889,2.65v-1.178q0-9.066-14.837-9.067c-6.909,0-11.136.433-16.866,3.1,0,0-.777-5.716-.967-7.652-.418-4.292,2.965-7.505,8.643-8.554.04-.007.081-.01.123-.02a70.8,70.8,0,0,1,12.717-1.118q15.778,0,23.845,6.3t8.066,17.6v25.844c0,6.928-2.419,8.539-8.15,8.539H245.854l-.543-4.828a22.5,22.5,0,0,1-8.008,4.828,32.1,32.1,0,0,1-11.068,1.766q-10.6,0-17.133-5.122"
        transform="translate(-56.202 -306.054)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M253.525,379.026a11.713,11.713,0,0,0,4.828-3.827v-4.946a20.166,20.166,0,0,0-11.54-3.415,10.046,10.046,0,0,0-6.358,1.766,6.2,6.2,0,0,0-2.237,5.182,6.006,6.006,0,0,0,2.179,4.946q2.177,1.767,6.3,1.766a16.028,16.028,0,0,0,6.83-1.472m-30.086,9.067q-6.535-5.122-6.535-14.306,0-9.538,6.3-14.66T241.279,354a43.148,43.148,0,0,1,9.185,1,33.859,33.859,0,0,1,7.889,2.65v-1.178q0-9.066-14.837-9.067c-6.909,0-11.136.433-16.866,3.1,0,0-.777-5.716-.967-7.652-.418-4.292,2.965-7.505,8.643-8.554.04-.007.081-.01.122-.02a70.8,70.8,0,0,1,12.717-1.118q15.778,0,23.845,6.3t8.066,17.6v25.844c0,6.928-2.419,8.539-8.15,8.539H260.191l-.542-4.828a22.5,22.5,0,0,1-8.008,4.828,32.1,32.1,0,0,1-11.068,1.766q-10.6,0-17.133-5.122"
        transform="translate(73.995 -306.054)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M36.5,66.182q-4.358,0-6.064-2t-1.707-6.594V38.275H41.685L40.055,23.909H28.731V.029s-4.947.328-6.595.457C13.684,1.149,7.974,6.773,7.974,13.35l.033,11.1L0,25v13.28H8.008V60.412q0,10.128,5.357,15.955T29.2,82.2a47.745,47.745,0,0,0,8.537-.647l.069-.014a8.046,8.046,0,0,0,6.466-7.943v-9.53a16.1,16.1,0,0,1-7.772,2.12"
        transform="translate(0 4.964)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M229.468,379.026A11.715,11.715,0,0,0,234.3,375.2v-4.946a20.166,20.166,0,0,0-11.54-3.415A10.047,10.047,0,0,0,216.4,368.6a6.2,6.2,0,0,0-2.237,5.182,6.007,6.007,0,0,0,2.178,4.946,9.79,9.79,0,0,0,6.3,1.766,16.026,16.026,0,0,0,6.83-1.472m-30.086,9.067q-6.535-5.122-6.535-14.306,0-9.538,6.3-14.66T217.221,354a43.15,43.15,0,0,1,9.185,1,33.864,33.864,0,0,1,7.889,2.65v-1.178q0-9.066-14.837-9.067c-6.909,0-11.135.433-16.865,3.1,0,0-.778-5.716-.967-7.652-.418-4.292,2.965-7.505,8.643-8.554.04-.007.081-.01.122-.02a70.8,70.8,0,0,1,12.717-1.118q15.778,0,23.844,6.3t8.067,17.6v25.844c0,6.928-2.419,8.539-8.151,8.539H236.134l-.542-4.828a22.5,22.5,0,0,1-8.008,4.828,32.1,32.1,0,0,1-11.068,1.766q-10.6,0-17.133-5.122"
        transform="translate(-144.455 -306.054)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M220.5,415.865l-.02-85.391s-4.931.328-6.574.457c-8.427.662-14.118,6.287-14.118,12.864l-.02,72.07Z"
        transform="translate(-81.634 -330.474)"
      />
      <path
        fill="#ff5215"
        fillRule="evenodd"
        className="icon TalabatIcon"
        d="M254.428,397.908a14.225,14.225,0,0,1-10.245,3.709A15.376,15.376,0,0,1,235.7,399.2a13.56,13.56,0,0,1-5.417-6.535V382.659a13.578,13.578,0,0,1,5.417-6.535,15.385,15.385,0,0,1,8.478-2.414,14.1,14.1,0,0,1,10.245,3.768q3.886,3.769,3.885,10.245t-3.885,10.185M275.858,372a26.507,26.507,0,0,0-10.539-10.657,31.81,31.81,0,0,0-15.72-3.768,33.861,33.861,0,0,0-10.774,1.707,25.058,25.058,0,0,0-8.538,4.652l.03-33.462s-4.947.328-6.594.458c-8.455.662-14.164,6.286-14.164,12.864v72.07h19.476l.543-5.653q7.887,7.419,20.018,7.418a32.3,32.3,0,0,0,15.662-3.709,26.213,26.213,0,0,0,10.6-10.48,31.8,31.8,0,0,0,3.768-15.719A32.221,32.221,0,0,0,275.856,372"
        transform="translate(7.307 -330.471)"
      />
    </g>
  </svg>
);

TalabatIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

TalabatIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default TalabatIcon;
