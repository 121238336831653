import styled, { css } from 'styled-components';
import AuraLogo from 'components/Icons/AuraLogo';
import ActionButton from 'components/ActionButton';
import Input from 'components/Input';

export const Container = styled.div`
  padding: 1rem;
`;

export const TitleInContent = styled.h3`
  margin-block-end: 2rem;
  text-align: left;
`;

export const StyledLogo = styled(AuraLogo)`
  height: 3.2rem;
  display: inline-block;
  fill: #fff;
  color: #fff;
  width: auto;
`;

export const HeaderMessage = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.brandSecondary};
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
`;

export const Content = styled.div`
  margin-inline-end: 2rem;
  text-align: left;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-block-start: 1rem;
`;

export const SignInOrOutButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.buttons.primaryBg};
  color: ${({ theme }) => theme.buttons.primaryColor};
  border: 1px solid ${({ theme }) => theme.buttons.primaryBorder};
  font-size: 0.8rem;
  min-height: unset;
  padding: 0.5rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  text-transform: none;
  width: fit-content;

  & > span {
    white-space: nowrap;
  }

  html[dir='rtl'] & {
    left: 1rem;
    right: unset;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.buttons.primaryBg};
  color: ${({ theme }) => theme.buttons.primaryColor};
  border: 1px solid ${({ theme }) => theme.buttons.primaryBorder};
  height: ${({ height }) => height || 'auto'};
  margin-block-start: 2rem;
  margin-inline: auto;
  padding-block: 1rem;
  width: calc(100% - 2rem);

  ${props =>
    props.disabled &&
    css`
      border-color: #999;
    `}
`;

export const BrandText = styled.div`
  color: ${({ theme }) => theme.color.brandSecondary};
`;

export const LightInputWrapper = styled.div`
  input {
    ${({ theme }) => css`
      background-color: ${theme.color.primaryText};
      color: ${theme.color.primaryBackground};
    `}
  }
`;

export const ModalFooter = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-start: 2rem;
  width: 66%;
`;

export const LoginChoiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
`;

export const SignInBodyContainer = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

export const StyledInput = styled(Input)`
  background-color: white;
  color: #5e6d70;
`;
