import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  text-align: center;
  margin: 10px 0;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const Option = styled.button`
  flex: 1;
  margin: 0 5px;
  font-weight: bold;
`;

export const OptionBar = styled.div`
  background-color: ${({ selected, theme }) => (selected ? theme.color.success : theme.color.infoBackground)};
  height: 8px;
  border-radius: 10px;
  margin-top: 5px;
`;
