import React from 'react';
import PropTypes from 'prop-types';

import ProductPrice from 'components/Product/price';
import AddToCartIcon from 'components/Icons/AddToCartIcon';
import image from 'utils/image';

import { addCssPrefixTo } from 'utils';
import {
  MiniProduct,
  Image,
  Icon,
  IconNoImg,
  TextWrapper,
  Name,
  Description,
  PriceContainer,
  Price,
} from './styles';

const propTypes = {
  onChange: PropTypes.func,
  showImage: PropTypes.bool,
  imagePath: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
};

const defaultProps = {
  showImage: true,
};

const ProductOption = ({ onChange, showImage, imagePath, name, description, price }) => (
  <MiniProduct className={addCssPrefixTo('MINI_PRODUCT_OPTION')} onClick={onChange}>
    {showImage && imagePath && (
      <>
        <Image>
          <img src={image(imagePath, { width: 700 })} alt={name} />
        </Image>

        <Icon>
          <AddToCartIcon width="20px" height="20px" />
        </Icon>
      </>
    )}

    <TextWrapper>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </TextWrapper>

    <PriceContainer>
      <Price>
        <ProductPrice price={price} />
      </Price>

      {!showImage && (
        <IconNoImg>
          <AddToCartIcon width="20px" height="20px" />
        </IconNoImg>
      )}
    </PriceContainer>
  </MiniProduct>
);

ProductOption.propTypes = propTypes;
ProductOption.defaultProps = defaultProps;

export default ProductOption;
