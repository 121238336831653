import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';

export const StyledQuantityOption = styled.div`
  text-align: left;
  margin: 10px 5px;
  width: 100%;
  ${fontSizeBig};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const StyledLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

export const StyledButtonContainer = styled.div`
  width: 115px;
`;
