import { defineMessages } from 'react-intl';

const messages = defineMessages({
  create_account: {
    defaultMessage: 'Create an Account',
  },
  registerWithWiQMsg: {
    defaultMessage: 'Create a single wi-Q account and use it to order from any wi-Q powered venue worldwide.',
  },
  registrationError: {
    defaultMessage: 'Registration Error!',
  },
  name: {
    defaultMessage: 'Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  confirmPassword: {
    defaultMessage: 'Confirm Password',
  },
  register: {
    defaultMessage: 'Register',
  },
  forgot_password: {
    defaultMessage: 'Forgot password?',
  },
  passwordsDontMatch: {
    defaultMessage: "The passwords you typed didn't match.",
  },
  badRegister: {
    defaultMessage: 'Unable to create your account at the moment. Please try again.',
  },
  login: {
    defaultMessage: 'Login',
  },
});

export default messages;
