import styled from 'styled-components';

export const Figure = styled.figure`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  margin-bottom: 10px;
  border-radius: 5px;

  img {
    width: 100%;
    height: auto;
  }
`;
