import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';

const generateMenuTypeMsg = id => {
  switch (id) {
    case 1:
      return <FormattedMessage {...globalMessages.dineIn} />;
    case 2:
      return <FormattedMessage {...globalMessages.takeAway} />;
    case 3:
      return <FormattedMessage {...globalMessages.collection} />;
    case 4:
      return <FormattedMessage {...globalMessages.delivery} />;
    case 5:
      return <FormattedMessage {...globalMessages.roomService} />;
    default:
      return null;
  }
};

export default generateMenuTypeMsg;
