import styled from 'styled-components';

export const StyledText = styled.p`
  font-style: italic;
  margin: 20px 0;
`;

export const AllergenList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: left;

  @media (min-width: 768px) {
    margin: 15px 35px;
  }
`;

export const NoAllergens = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.success};
`;
