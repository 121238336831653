import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import FormattedPrice from 'components/FormattedPrice';

import RangeSlider from 'components/RangeSlider';
import { getGratuityPrice } from 'selectors/order';
import { getServiceMinimumGratuity, selectGratuityMessageByLocale } from 'selectors/root';

import { MainContainer, Container, ServiceLabelStyled, GratuityStyled, Percent } from './styles';

const messages = defineMessages({
  label: {
    defaultMessage: 'Gratuity',
  },
});

class GratuityInput extends React.Component {
  static propTypes = {
    orderTotal: PropTypes.number,
    gratuityPercent: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    minimumGratuity: PropTypes.number.isRequired,
    gratuityMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  onSliderChange = value => this.props.onChange(value);

  render() {
    const { orderTotal, gratuityPercent, gratuityMessageByLocale, minimumGratuity } = this.props;

    const gratuityAmount = getGratuityPrice(orderTotal, new Map({ value: gratuityPercent }));
    return (
      <MainContainer>
        <Container>
          <ServiceLabelStyled>
            {gratuityMessageByLocale || <FormattedMessage {...messages.label} />}
          </ServiceLabelStyled>

          <GratuityStyled>
            <Percent>{`${gratuityPercent}%`}</Percent>
            {' / '}
            <span>
              <FormattedPrice value={gratuityAmount} />
            </span>
          </GratuityStyled>
        </Container>

        <RangeSlider
          min={minimumGratuity}
          max={100}
          defaultValue={gratuityPercent}
          step={0.5}
          onChange={this.onSliderChange}
        />
      </MainContainer>
    );
  }
}

const mapStateToProps = (state, { serviceId }) => ({
  minimumGratuity: getServiceMinimumGratuity(state, serviceId),
  gratuityMessageByLocale: selectGratuityMessageByLocale(state),
});

export default connect(mapStateToProps)(GratuityInput);
