import { browserHistory } from 'react-router';

import { openFeedbackThanksAlert } from 'actions/UI';
import fetchHelper from 'utils/fetchHelper';

import {
  REQUEST_FEEDBACK_QUESTIONS,
  REQUEST_FEEDBACK_QUESTIONS_SUCCESS,
  REQUEST_FEEDBACK_QUESTIONS_FAILURE,
  SUBMIT_FEEDBACK_ANSWERS,
  FEEDBACK_SUBMISSION_FAILURE,
  FEEDBACK_SUBMISSION_SUCCESS,
} from './constants';

const redirectHome = () => browserHistory && browserHistory.push('/');

function requestQuestions() {
  return {
    type: REQUEST_FEEDBACK_QUESTIONS,
  };
}

function requestQuestionsSuccess(questions) {
  return {
    type: REQUEST_FEEDBACK_QUESTIONS_SUCCESS,
    questions,
  };
}

function requestQuestionsFailure() {
  return {
    type: REQUEST_FEEDBACK_QUESTIONS_FAILURE,
  };
}

export const fetchQuestions = serviceId => dispatch => {
  dispatch(requestQuestions());

  const success = questions =>
    !questions.length ? dispatch(requestQuestionsFailure()) : dispatch(requestQuestionsSuccess(questions));

  const failure = () => {
    dispatch(requestQuestionsFailure());
  };

  fetchHelper(`/api/feedback/${serviceId}/questions`, 'GET', null, success, failure);
};

export const submitAnswers = answers => dispatch => {
  dispatch({
    type: SUBMIT_FEEDBACK_ANSWERS,
    answers,
  });

  const success = () => {
    dispatch(openFeedbackThanksAlert(redirectHome));
    dispatch({ type: FEEDBACK_SUBMISSION_SUCCESS });
  };
  const fail = () => {
    dispatch({
      type: FEEDBACK_SUBMISSION_FAILURE,
      success: false,
      violations: null,
    });
  };
  fetchHelper('/api/feedback/answers', 'POST', answers, success, fail);
};
