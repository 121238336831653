import { BroadcastChannel } from 'broadcast-channel';
import { isLoggedIn } from 'selectors/user';
import { logout } from 'actions/user';
import { BC_LOGOUT } from './constants';

const bc = new BroadcastChannel('wi-q/webapp');

export const postBroadcastMessage = data => bc.postMessage(data);

export default ({ dispatch, getState }) =>
  next => {
    if (typeof window === 'undefined' || !bc) return action => next(action);

    const state = getState();

    bc.onmessage = e => e.type === BC_LOGOUT && isLoggedIn(state) && dispatch(logout(true));

    return action => next(action);
  };
