import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import SuitableFor from 'components/SuitableFor';
import ProductImage from 'components/ProductImage';
import StandardButtons from 'components/Product/ProductAdditional/StandardButtons';
import ProductPrice from 'components/Product/price';
import BaseModifier from 'components/Product/BaseModifier';
import { getLocale } from 'selectors/user';

import { Chilli } from 'components/Chilli';
import { Details, FlexBox, ProductName, Price, StyledH1, Description } from './styles';

const propTypes = {
  product: PropTypes.object,
  price: PropTypes.number,
  additionalButtons: PropTypes.array,
  productDescription: PropTypes.string,
  productImages: PropTypes.instanceOf(List),
  baseModifier: PropTypes.instanceOf(Map),
  selectedBaseModifierId: PropTypes.number,
  pageView: PropTypes.bool,
  changeModifier: PropTypes.func,
  currentLocale: PropTypes.string,
};

const ProductDetails = ({
  productDescription,
  productImages,
  product,
  price,
  baseModifier,
  selectedBaseModifierId,
  changeModifier,
  pageView = false,
  additionalButtons = [],
  currentLocale,
}) => (
  <Details>
    {productImages.size > 0 && (
      <FlexBox hasImage={true}>
        <ProductImage
          images={productImages}
          altText={product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}
        />
      </FlexBox>
    )}
    <FlexBox hasImage={productImages.size > 0}>
      <ProductName>
        <StyledH1>
          {product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}
        </StyledH1>
        <SuitableFor product={product} />
        <Chilli product={product} />
      </ProductName>
      <Price>
        <ProductPrice price={price} />
      </Price>
      <BaseModifier
        baseModifier={baseModifier}
        selectedId={selectedBaseModifierId}
        changeModifier={changeModifier}
      />
      <StandardButtons smallBtns={pageView} product={product} additionalButtons={additionalButtons} />
      <Description>{productDescription}</Description>
    </FlexBox>
  </Details>
);

ProductDetails.propTypes = propTypes;

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(ProductDetails);
