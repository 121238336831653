import styled from 'styled-components';

export const MainContainer = styled.div`
  padding: 20px 10px 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ServiceLabelStyled = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`;

export const GratuityStyled = styled.div`
  font-size: 14px;
`;

export const Percent = styled.span`
  color: ${({ theme }) => theme.color.success};
`;
