import React from 'react';
import PropTypes from 'prop-types';

// Icon
const PointerIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon pointerIcon"
        fill={fill}
        opacity={opacity}
        d="M500,10c45.3,0,88.3,8.7,129,26c40.7,17.3,76,41,106,71s53.7,65,71,105c17.3,40,26,83.3,26,130c0,65.3-15.7,134-47,206	c-31.3,72-65.7,138-103,198c-37.3,60-72.3,110.3-105,151c-32.7,40.7-51,63-55,67l-22,26l-22-26c-4-4-22.3-26.3-55-67 c-32.7-40.7-67.7-91-105-151c-37.3-60-71.7-126-103-198c-31.3-72-47-140.7-47-206c0-46.7,8.7-90,26-130c17.3-40,41-75,71-105 s65.3-53.7,106-71C411.7,18.7,454.7,10,500,10z M500,896c17.3-21.3,41.3-52.7,72-94c30.7-41.3,60.7-88,90-140	c29.3-52,55-106,77-162c22-56,33-108.7,33-158c0-37.3-7.3-72.7-22-106c-14.7-33.3-34.3-62-59-86c-24.7-24-53.3-43.3-86-58	c-32.7-14.7-67.7-22-105-22c-37.3,0-72.3,7.3-105,22c-32.7,14.7-61.3,34-86,58c-24.7,24-44.3,52.7-59,86c-14.7,33.3-22,68.7-22,106 c0,49.3,11,102,33,158c22,56,47.7,110,77,162c29.3,52,59.3,98.7,90,140C458.7,843.3,482.7,874.7,500,896z M500,170 c25.3,0,49,4.7,71,14c22,9.3,41,22,57,38c16,16,28.7,35,38,57c9.3,22,14,45.7,14,71c0,25.3-4.7,48.7-14,70c-9.3,21.3-22,40.3-38,57	c-16,16.7-35,29.7-57,39c-22,9.3-45.7,14-71,14c-25.3,0-49-4.7-71-14c-22-9.3-41-22.3-57-39c-16-16.7-28.7-35.7-38-57	c-9.3-21.3-14-44.7-14-70c0-25.3,4.7-49,14-71c9.3-22,22-41,38-57c16-16,35-28.7,57-38C451,174.7,474.7,170,500,170z M500,470	c16,0,31.3-3,46-9c14.7-6,27.7-14.7,39-26c11.3-11.3,20-24.3,26-39c6-14.7,9-30,9-46c0-17.3-3-33.3-9-48c-6-14.7-14.7-27.3-26-38 c-11.3-10.7-24.3-19-39-25c-14.7-6-30-9-46-9c-16,0-31.3,3-46,9c-14.7,6-27.7,14.3-39,25c-11.3,10.7-20,23.3-26,38 c-6,14.7-9,30.7-9,48c0,16,3,31.3,9,46c6,14.7,14.7,27.7,26,39c11.3,11.3,24.3,20,39,26C468.7,467,484,470,500,470z"
      />
    </g>
  </svg>
);

PointerIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

PointerIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default PointerIcon;
