import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import Form from 'components/Form';
import FormHeader from 'components/Form/FormHeader';
import fieldTypes from 'components/Form/fieldTypes';
import SettingsPage from 'components/Pages/User/SettingsPage';
import { selectUser } from 'selectors/user';
import settingsMessages from 'components/settingsMessages';
import updateDetails from '../updateDetails';

const messages = defineMessages({
  editPasswordTitle: {
    defaultMessage: 'Change Password',
  },
  passwordUpdated: {
    defaultMessage: 'Password updated successfully.',
  },
  currentPassword: {
    defaultMessage: 'Current Password',
  },
  newPassword: {
    defaultMessage: 'New Password',
  },
  retypePassword: {
    defaultMessage: 'Confirm New Password',
  },
  errorUpdating: {
    defaultMessage: 'Could not update password, please try again later.',
  },
});

class EditPassword extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(Map),
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }),
  };

  state = {
    error: false,
  };

  getFormFields = () => {
    const { formatMessage } = this.props.intl;
    return [
      {
        ...fieldTypes.password,
        intlLabel: formatMessage(messages.currentPassword),
      },
      {
        ...fieldTypes.newPassword,
        intlLabel: formatMessage(messages.newPassword),
      },
      {
        ...fieldTypes.confirmPassword,
        intlLabel: formatMessage(messages.retypePassword),
      },
    ];
  };

  onSubmit = (values, callback) => {
    const email = this.props.user.get('email');
    const newDetails = {
      password: values.newPassword,
    };

    updateDetails({ email, password: values.password, newDetails })
      .then(() => {
        callback({ success: true });
      })
      .catch(error => {
        const message = error.message === 'forbidden' ? settingsMessages.forbidden : messages.errorUpdating;
        this.setState({ error: message });
        callback({ error: true });
      });
  };

  render() {
    return (
      <SettingsPage>
        <FormHeader title={<FormattedMessage {...messages.editPasswordTitle} />} requiredFields={true} />
        <Form
          fields={this.getFormFields()}
          onSubmit={this.onSubmit}
          submitButtonLabel={<FormattedMessage {...settingsMessages.saveChanges} />}
          errorHeader={<FormattedMessage {...settingsMessages.updateError} />}
          errorMessage={this.state.error ? <FormattedMessage {...this.state.error} /> : ''}
          successHeader={<FormattedMessage {...settingsMessages.success} />}
          successMessage={<FormattedMessage {...messages.passwordUpdated} />}
        />
      </SettingsPage>
    );
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(injectIntl(EditPassword));
