import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MaestroIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391 C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55 c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M718.7,250.5c0,112.4-91.5,203.9-204,203.9 c-46.9,0-90.2-15.9-124.7-42.6c-34.5,26.7-77.8,42.6-124.7,42.6c-112.5,0-204-91.5-204-203.9c0-112.4,91.5-203.9,204-203.9 c46.9,0,90.2,15.9,124.7,42.6c34.5-26.7,77.8-42.6,124.7-42.6C627.2,46.6,718.7,138.1,718.7,250.5L718.7,250.5z M514.4,64.6 c-41.4,0-79.6,13.5-110.5,36.4c30.2,28,51.8,65.1,60.7,106.7h-18.7c-8.7-37.3-28.7-70.3-56.2-95.1c-27.4,24.9-47.4,57.8-56.2,95.1 h-18.7c8.9-41.7,30.5-78.7,60.7-106.7C344.7,78.2,306.4,64.6,265,64.6c-102.6,0-185.7,83.1-185.7,185.6 c0,102.5,83.2,185.7,185.7,185.7c41.4,0,79.6-13.6,110.5-36.4c-26.3-24.5-46.2-55.9-56.7-91.3h19.1c10.2,30.9,28.3,58.3,51.8,79.6 c23.6-21.4,41.7-48.7,51.8-79.6h19.1c-10.5,35.4-30.4,66.7-56.7,91.3c30.9,22.9,69.1,36.4,110.5,36.4 c102.6,0,185.8-83.1,185.8-185.7C700.1,147.7,617,64.6,514.4,64.6L514.4,64.6z"
      />
      <path
        className="icon"
        fill={fill}
        d="M386.3,293.7c-7.5,1.9-14.9,2.9-22.6,2.9c-24.6,0-37.4-11.2-37.4-32.6 c0-25,16.3-43.4,38.5-43.4c18.1,0,29.7,10.3,29.7,26.5c0,5.3-0.8,10.5-2.7,18H348c-1.6,10.4,6.1,15,19.1,15c7.8,0,14.8-1.4,22.6-4.5 L386.3,293.7L386.3,293.7z M374.5,250.7c0-1.6,2.4-12.8-10.2-13.1c-7,0-12,4.7-14,13.1H374.5z"
      />
      <path
        className="icon"
        fill={fill}
        d="M405.8,245.4c0,9.2,5.2,15.6,17,20.4c9,3.7,10.5,4.8,10.5,8.1 c0,4.6-4,6.7-12.9,6.7c-6.7,0-12.8-0.9-19.9-2.9l-3.2,16.8c6.4,1.5,15.3,2,23.3,2.2c23.6,0,34.5-7.7,34.5-24.4 c0-10-4.5-15.9-15.8-20.3c-9.4-3.7-10.4-4.6-10.4-7.9c0-4,3.7-6,11-6c4.4,0,10.4,0.4,16.1,1.1l3.2-17c-5.8-0.8-14.6-1.4-19.7-1.4 C414.3,220.6,405.7,231.9,405.8,245.4L405.8,245.4z"
      />
      <path
        className="icon"
        fill={fill}
        d="M311.6,295.5h-18.4l0.4-7.7c-5.6,6-13,8.9-23.2,8.9 c-12,0-20.2-8.2-20.2-19.9c0-17.9,14.3-28.2,38.8-28.2c2.5,0,5.7,0.2,9,0.5c0.7-2.4,0.9-3.4,0.9-4.7c0-4.9-3.8-6.7-14.2-6.7 c-10.2,0-17,1.5-23.4,3.3l3.1-16.4c11-2.8,18.2-3.9,26.4-3.9c19,0,29,7.4,29,21.4c0.2,3.7-1.2,11.2-1.8,14.5 C317.4,261.3,312.1,288.9,311.6,295.5z M296.1,263c-2.3-0.3-3.3-0.3-4.9-0.3c-12.5,0-18.8,3.7-18.8,11.1c0,4.7,3.1,7.5,7.9,7.5 C289.2,281.2,295.7,273.7,296.1,263L296.1,263z"
      />
      <path
        className="icon"
        fill={fill}
        d="M499.4,279c-1.5,0.5-3.4,0.7-5.9,0.7c-5.2,0-7.5-1.6-7.5-5.4 c0-2.4,6.2-33.3,6.2-33.3h11.3l3-17.8h-11.2l3.3-17.5h-21.8c0,0-9.8,52.8-10.4,56.5c-0.6,3.6-3.4,16.6-3,19.4c0,10.2,6.1,16,17.4,16 c5,0,9.8-0.7,15.8-2.3L499.4,279L499.4,279z"
      />
      <path
        className="icon"
        fill={fill}
        d="M596.8,296.6c-24.6,0-34.5-14.9-34.5-33.2c0-25.5,16.8-42.8,42.8-42.8 c22.6,0,34.6,14.2,34.6,32.5C639.7,283.4,621.7,296.6,596.8,296.6z M604.2,238.6c-11.1,0-18,13.1-18,24.7c0,9.8,4.7,16.3,12.5,16.2 c10.7,0,17.5-9.8,17.5-26.3C616.2,245.7,613.2,238.6,604.2,238.6z"
      />
      <path
        className="icon"
        fill={fill}
        d="M520.8,222.4c-2.8,22.6-7.7,45.6-11.5,68.2l-0.9,5h22.1 c7.9-43.8,10.6-56.3,27.3-51.9c0.9-2.4,8-20.7,8-20.7c-11.6-4.3-19.1,1.8-25.9,10.5c0.6-3.9,1.8-7.7,1.5-11.1H520.8L520.8,222.4z"
      />
      <path
        className="icon"
        fill={fill}
        d="M253.3,206.6h-35.7L193.4,262l-1.8-55.3h-34.3l-17,89.4h19.9l13.1-68.3 l3.6,68.3h20l30.1-68.8L214,296.1h22L253.3,206.6L253.3,206.6z"
      />
    </g>
  </svg>
);

MaestroIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MaestroIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default MaestroIcon;
