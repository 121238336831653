import React from 'react';
import PropTypes from 'prop-types';

import QuestionTitle from './QuestionTitle';

import { TextQuestionContainer, InputStyled } from './styles';

const TextQuestion = ({ question, value = '', onUpdate }) => (
  <TextQuestionContainer>
    <QuestionTitle>{question}</QuestionTitle>
    <InputStyled
      className="form-control"
      type="text"
      value={value}
      onChange={e => onUpdate(e.target.value)}
    />
  </TextQuestionContainer>
);

TextQuestion.propTypes = {
  question: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default TextQuestion;
