import styled, { css } from 'styled-components';

const sharedIconStyles = css`
  width: 45px;
  height: 45px;
  background: ${({ theme }) => theme.color.brandSecondary};
  border-radius: 50%;
  color: #fff;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiniProduct = styled.div`
  background: ${({ theme }) => theme.color.secondaryBackground};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 5px;
  min-width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    min-width: 200px;
  }
`;

export const Image = styled.div`
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 15px;
  height: 90px;
  background-color: ${({ theme }) => theme.color.grayLighter};

  @media (min-width: 768px) {
    height: 120px;
  }

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
`;

export const Icon = styled.div`
  background-color: ${({ theme }) => theme.color.success} !important;
  position: absolute;
  right: 15px;
  top: 67px;

  ${sharedIconStyles}

  html[dir='rtl'] & {
    right: initial;
    left: 15px;
  }

  @media (min-width: 768px) {
    top: 97px;
  }
`;

export const IconNoImg = styled.div`
  ${sharedIconStyles}
`;

export const TextWrapper = styled.div`
  padding: 7px 7px 0px;
  margin-bottom: 15px;
  flex-grow: 1;
`;

export const Name = styled.p`
  white-space: normal;
  color: ${({ theme }) => theme.color.primaryText};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: block;
    white-space: normal;
    position: relative;
    line-height: 1.2em;
    max-height: 4.8em;
    padding-right: 1em;
    overflow: hidden;

    ::before {
      content: '...';
      position: absolute;
      right: 5px;
      bottom: 2px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  padding: 0 7px;
  justify-content: end;
  align-items: center;
`;

export const Price = styled.p`
  color: ${({ theme }) => theme.color.primaryText};
  font-weight: bold;
  padding: 0 7px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
