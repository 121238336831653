import styled, { css } from 'styled-components';

const fontSmall = css`
  font-size: 11px;
`;

export const ServiceContainer = styled.div`
  background: #fff;
  color: #444;
  position: relative;
  margin-bottom: 6px;
  padding: 10px;
  min-height: 115px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;

  ${({ compact }) =>
    compact &&
    css`
      padding: 0;
      min-height: auto;
      text-align: left;
    `}
`;
ServiceContainer.displayName = 'ServiceContainer';

export const Address = styled.div`
  ${fontSmall}
`;

export const CartButtonStyled = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Info = styled.div`
  ${fontSmall}
`;

export const Text = styled.p`
  text-transform: uppercase;
  font-size: 10px;
  color: #999;
`;

export const ServiceName = styled.h3`
  font-weight: 400;
  line-height: 21px;
  font-size: ${({ compact }) => (compact ? '15px' : '18px')};
  margin: ${({ compact }) => (compact ? '5px 0 0' : '15px 0 5px')};
`;

export const TextContainer = styled.div`
  ${({ compact }) =>
    compact &&
    css`
      display: inline-block;
      position: absolute;
      left: 80px;
      vertical-align: top;
    `}
`;

export const IsDraft = styled.div`
  background-color: ${({ theme }) => theme.color.grayLighter};
  padding: 5px;
  margin-top: 20px;
`;
