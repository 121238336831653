import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import GetStartedButton from 'components/GetStartedButton';
import ButtonContainer from 'components/PrimaryButton/Container';
import {
  selectIsOrganisation,
  selectVenueName,
  selectOrganisationName,
  getIsAdmin,
  getIsVenueOffline,
  selectMessageByLocale,
} from 'selectors/root';
import addCssPrefixTo from 'utils/addCssPrefixTo';
import { HomePagePrimaryButton } from 'assets/styles/sharedStyles';

import globalMessages from 'components/globalMessages';

import { kioskModeEnabled } from 'selectors/features';
import LanguageSelect from 'components/LanguageSelect';
import {
  Container,
  BoarderContainer,
  WelcomeContent,
  CompanyName,
  WelcomeMessage,
  WelcomeStyled,
  VenuesContent,
  Venues,
  OfflineMessage,
} from './styles';

const propTypes = {
  children: PropTypes.node,
  companyName: PropTypes.string,
  getStartedMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isAdmin: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isOffline: PropTypes.bool,
  isOrganisation: PropTypes.bool,
  kioskMode: PropTypes.bool,
  offlineReasonByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  user: PropTypes.instanceOf(Map),
  welcomeMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const OnBoarder = ({
  children,
  companyName,
  getStartedMessageByLocale,
  isAdmin,
  isOffline,
  isOrganisation,
  kioskMode,
  offlineReasonByLocale,
  welcomeMessageByLocale,
}) => {
  const isCollapsed = !children;

  return (
    <Container className="primaryColor" showServices={!isCollapsed && !isOrganisation}>
      <BoarderContainer
        className={`${addCssPrefixTo('WELCOME')} primaryBackground`}
        isCollapsed={isCollapsed}
      >
        {kioskMode && <LanguageSelect hide={!isCollapsed} />}
        <WelcomeStyled isCollapsed={isCollapsed}>
          <WelcomeContent isCollapsed={isCollapsed}>
            <WelcomeMessage className={addCssPrefixTo('WELCOME_MESSAGE')}>
              <CompanyName className={addCssPrefixTo('COMPANY_NAME')}>
                {welcomeMessageByLocale || companyName}
              </CompanyName>
            </WelcomeMessage>

            {isOrganisation && (!isOffline || isAdmin) && (
              <ButtonContainer>
                <HomePagePrimaryButton
                  onClick={() => {}}
                  text={getStartedMessageByLocale || <FormattedMessage {...globalMessages.get_started} />}
                  link="/venues"
                  data-cy="goToVenuesButton"
                />
              </ButtonContainer>
            )}

            {!isOrganisation && (!isOffline || isAdmin) && <GetStartedButton />}

            {isOffline && !isAdmin && (
              <OfflineMessage>
                {offlineReasonByLocale || (
                  <FormattedMessage defaultMessage="Online ordering unavailable. Check back soon." />
                )}
              </OfflineMessage>
            )}
          </WelcomeContent>
        </WelcomeStyled>

        <Venues isCollapsed={isCollapsed} isOrganisation={isOrganisation}>
          <VenuesContent isCollapsed={isCollapsed} isOrganisation={isOrganisation} className="container">
            {children}
          </VenuesContent>
        </Venues>
      </BoarderContainer>
    </Container>
  );
};

OnBoarder.propTypes = propTypes;

const mapStateToProps = state => {
  const isOrganisation = selectIsOrganisation(state);
  return {
    companyName: isOrganisation ? selectOrganisationName(state) : selectVenueName(state),
    getStartedMessageByLocale: selectMessageByLocale(state, 'getting_started_button'),
    isAdmin: getIsAdmin(state),
    isOffline: getIsVenueOffline(state),
    isOrganisation,
    kioskMode: kioskModeEnabled(state),
    offlineReasonByLocale: selectMessageByLocale(state, 'offline_reason'),
    welcomeMessageByLocale: selectMessageByLocale(state, 'welcome_message'),
  };
};

export default connect(mapStateToProps)(OnBoarder);
