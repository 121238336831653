import styled from 'styled-components';
import { fontSizeBig, fontSizeMedium } from 'assets/styles/fonts';
import MessageBlock from 'components/MessageBlock';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 5px;
  padding: 10px;
`;

export const Title = styled.h3`
  ${fontSizeBig};
  font-weight: 400;
  margin-bottom: 15px;
`;

export const DateRangeText = styled.p`
  ${fontSizeMedium}
`;

export const DateBlock = styled.div`
  width: 47%;
  margin: 5px 0 20px;
`;

export const MessageBlockStyled = styled(MessageBlock)`
  margin: 0 0 20px;
`;

export const FilterButton = styled.div`
  min-width: 120px;
  display: inline-block;
  width: auto;
`;
