import React from 'react';
import PropTypes from 'prop-types';

// Icon
const NextIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M120,237c-16.268,0-31.644-3.096-45.702-9.202c-14.049-6.097-26.602-14.621-37.308-25.335	c-10.352-10.346-18.694-22.809-24.793-37.043C6.094,151.184,3,135.902,3,120c0-15.894,3.094-31.175,9.197-45.418 c6.1-14.229,14.442-26.691,24.794-37.043c10.721-10.722,23.273-19.247,37.308-25.337C88.359,6.096,103.735,3,120,3 c15.893,0,31.174,3.094,45.419,9.197c14.23,6.102,26.702,14.636,37.066,25.366c10.683,10.317,19.217,22.789,25.316,37.018	C233.905,88.824,237,104.105,237,120c0,15.902-3.095,31.185-9.198,45.42c-6.1,14.235-14.634,26.707-25.366,37.067	c-10.301,10.674-22.772,19.207-37.017,25.314C151.176,233.905,135.896,237,120,237z M120,16.309 c-14.266,0-27.819,2.709-40.283,8.054c-12.479,5.345-23.692,12.94-33.327,22.576c-9.264,9.264-16.675,20.292-22.028,32.778 C19.019,92.188,16.31,105.742,16.31,120c0,14.267,2.709,27.819,8.053,40.283c5.352,12.495,12.763,23.522,22.027,32.777 c9.636,9.637,20.849,17.232,33.327,22.576c12.463,5.344,26.017,8.053,40.284,8.053c14.259,0,27.813-2.709,40.283-8.053 c12.463-5.341,23.482-12.928,32.752-22.549c9.295-9.293,16.801-20.328,22.338-32.826c5.519-12.462,8.316-26.008,8.316-40.262 c0-14.246-2.798-27.792-8.317-40.262c-5.536-12.489-13.043-23.525-22.312-32.8c-9.295-9.646-20.313-17.233-32.777-22.575 C147.811,19.019,134.258,16.309,120,16.309z M124.358,169.043L166.688,126H58v-12h108.688l-42.33-43.044l8.723-9.609l58.747,58.701 l-58.747,58.63L124.358,169.043z"
      />
    </g>
  </svg>
);

NextIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

NextIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default NextIcon;
