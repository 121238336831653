import { selectMenusByType, selectMenuTypeId } from 'selectors/browse';

export default (dispatch, getState) =>
  ({ params }, replace) => {
    const state = getState();
    const menuTypeId = selectMenuTypeId(state);
    const serviceId = params.serviceId;
    const menus = selectMenusByType(state, menuTypeId, serviceId);
    if (menus.size === 1) replace(`/service/${serviceId}/menu/${menus.first().get('id')}`);
  };
