import React from 'react';
import PropTypes from 'prop-types';

const YouTubeIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon YouTubeIcon"
      fill={fill}
      d="M980.2,304.1c0,0-9.6-67.5-39-97.3c-37.3-39-79-39.2-98.2-41.5c-137.1-9.9-342.8-9.9-342.8-9.9h-0.4c0,0-205.7,0-342.8,9.9	c-19.2,2.3-60.9,2.5-98.2,41.5c-29.4,29.7-38.9,97.3-38.9,97.3S10,383.4,10,462.7V537c0,79.3,9.8,158.6,9.8,158.6	s9.6,67.5,38.9,97.3c37.3,39,86.2,37.8,108.1,41.9c78.4,7.5,333.2,9.8,333.2,9.8s205.9-0.3,343.1-10.2c19.2-2.3,60.9-2.5,98.2-41.5	c29.4-29.7,39-97.3,39-97.3S990,616.3,990,537v-74.3C990,383.4,980.2,304.1,980.2,304.1z M934,537c0,71.2-8.4,143.3-9.3,151.1	c-0.4,2.6-2.6,15.6-7.1,30.5c-7.8,25.9-15.4,34.1-16.2,34.9l-0.3,0.3l-0.3,0.3c-19.2,20.1-39.2,22.1-56.8,23.8	c-2.1,0.2-4.1,0.4-6.1,0.6c-131.3,9.4-328.9,10-337.7,10c-10.1-0.1-249.7-2.5-326-9.4c-4.6-0.8-9.2-1.3-14.1-1.9	c-20.6-2.5-43.8-5.3-60.9-23.1l-0.3-0.3l-0.3-0.3c-0.8-0.8-8.4-9-16.2-34.9c-4.5-15-6.7-28-7.1-30.5C74.4,680.4,66,608,66,537v-74.3	c0-71,8.4-143.3,9.3-151.1c0.4-2.5,2.6-15.6,7.1-30.5c7.8-25.9,15.4-34.1,16.2-34.9l0.3-0.3l0.3-0.3c19.2-20.1,39.2-22.1,56.8-23.8	c2.1-0.2,4.1-0.4,6.1-0.6c133.6-9.6,335.6-9.7,337.6-9.7h0.4c2,0,204.1,0.1,337.6,9.7c2,0.2,4,0.4,6.1,0.6	c17.6,1.7,37.6,3.7,56.8,23.8l0.3,0.3l0.3,0.3c9.2,9.3,20,42.5,23.3,65.3c0.8,6.6,9.3,79.7,9.3,151.3V537z"
    />
    <path className="icon YouTubeIcon" d="M398.8,627.1l264.8-137.2L398.8,351.8L398.8,627.1z" />
  </svg>
);

YouTubeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

YouTubeIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default YouTubeIcon;
