import React from 'react';
import PropTypes from 'prop-types';

const AmexCardIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 72 45.749"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999">
        <rect stroke="none" width="70" height="43.749" rx="4" />
        <rect fill="none" x="-0.5" y="-0.5" width="71" height="44.749" rx="4.5" />
      </g>
      <g transform="translate(7.491 11.624)">
        <path
          fill="#fff"
          d="M388.263,723.027v-.7l-.335.7h-2.9l-.363-.751v.751H379.25l-.641-1.515h-1.084l-.678,1.515h-2.811v-4.573l2.06-4.659h3.942l.554,1.32V713.8h4.822l1.06,2.222,1.037-2.232h15.221a2.587,2.587,0,0,1,1.322.359l0-.359h3.834v.5a3.12,3.12,0,0,1,1.709-.5h6.766l.595,1.339v-1.339h4.336l.787,1.339v-1.339h4.145v9.241h-4.259l-1-1.611v1.6h-5.293l-.745-1.65h-1.037l-.7,1.65h-3.2a4.152,4.152,0,0,1-2.091-.656v.656h-6.378v-2.156c0-.313-.241-.291-.241-.291h-.232v2.447Z"
          transform="translate(-374.036 -713.795)"
        />
        <path
          fill="#fff"
          d="M393.828,733.924h8.381l1.208,1.369,1.237-1.369H411.1a2.965,2.965,0,0,1,1.18.321v-.321h5.737a2.933,2.933,0,0,1,1.34.364v-.364h7.375v.335a2.5,2.5,0,0,1,1.231-.335h4.8v.335a2.939,2.939,0,0,1,1.245-.335h3.771v8.708a2.075,2.075,0,0,1-1.521.561h-4.973v-.277a2.553,2.553,0,0,1-1.194.277H416.881v-2.251c0-.222-.1-.262-.294-.262h-.182v2.512h-4.128v-2.5a2.785,2.785,0,0,1-1.223.246h-1.486v2.251H404.48l-1.159-1.412-1.237,1.412h-8.257Z"
          transform="translate(-382.783 -722.69)"
        />
        <path
          fill="#147591"
          d="M441.154,736.414h5.259v1.41h-3.649v1.229h3.547V740.3h-3.547v1.294h3.649v1.376h-5.259Z"
          transform="translate(-403.697 -723.791)"
        />
        <path
          fill="#147591"
          d="M466.042,738.979c1.977.1,2.142,1.089,2.142,2.142a1.914,1.914,0,0,1-2.062,1.887h-3.379v-1.4h2.632c.446,0,1.111,0,1.111-.629,0-.3-.116-.49-.592-.54-.211-.031-.993-.074-1.111-.076-1.786-.047-2.2-.935-2.2-2.016a1.807,1.807,0,0,1,1.918-1.9h3.379v1.4H465.4c-.562.006-1.169-.066-1.169.569,0,.4.3.483.68.52C465.047,738.934,465.923,738.98,466.042,738.979Z"
          transform="translate(-413.166 -723.803)"
        />
        <path
          fill="#147591"
          d="M455.191,738.979c1.977.1,2.142,1.089,2.142,2.142a1.914,1.914,0,0,1-2.063,1.887h-3.379v-1.4h2.633c.446,0,1.112,0,1.112-.629,0-.3-.118-.49-.593-.54-.212-.031-.994-.074-1.111-.076-1.786-.047-2.2-.935-2.2-2.016a1.806,1.806,0,0,1,1.916-1.9h3.379v1.4h-2.472c-.563.006-1.17-.066-1.17.569,0,.4.3.483.681.52C454.2,738.934,455.074,738.98,455.191,738.979Z"
          transform="translate(-408.372 -723.803)"
        />
        <path
          fill="#147591"
          d="M411.39,736.428h-5.645l-1.843,2.033-1.784-2.047h-6.34v6.552h6.135l1.93-2.165,1.887,2.18h3.086v-2.225h2.164c.834,0,2.355-.028,2.355-2.325A1.814,1.814,0,0,0,411.39,736.428Zm-10.318,5.162h-3.708V740.3h3.547v-1.242h-3.547v-1.23l3.912.009,1.566,1.783Zm6.15.761-2.311-2.735,2.311-2.559Zm3.627-2.944h-2v-1.573h2.01a.792.792,0,1,1-.006,1.573Z"
          transform="translate(-383.644 -723.791)"
        />
        <path
          fill="#147591"
          d="M433.716,740.009a1.789,1.789,0,0,0,1.112-1.814,1.729,1.729,0,0,0-1.931-1.754h-4.182v6.552h1.608v-2.312h2.106c.555,0,.775.556.789,1.112l.044,1.2h1.565l-.059-1.346C434.77,740.593,434.478,740.1,433.716,740.009Zm-1.389-.678h-2v-1.484h2.01a.772.772,0,0,1,.885.753C433.22,739.038,432.986,739.331,432.327,739.331Z"
          transform="translate(-398.201 -723.803)"
        />
        <rect fill="#147591" width="1.609" height="6.56" transform="translate(31.16 1.312)" />
        <path
          fill="#147591"
          d="M406.274,716.16h5.257v1.41h-3.647v1.23h3.545v1.243h-3.545v1.294h3.647v1.376h-5.257Z"
          transform="translate(-388.283 -714.84)"
        />
        <path
          fill="#147591"
          d="M422.407,719.728a1.787,1.787,0,0,0,1.112-1.814,1.729,1.729,0,0,0-1.932-1.754h-4.182v6.552h1.609V720.4h2.105c.557,0,.776.555.79,1.111l.045,1.2h1.565l-.059-1.346C423.461,720.313,423.168,719.816,422.407,719.728Zm-1.389-.678h-2v-1.484h2.011a.771.771,0,0,1,.885.753C421.91,718.758,421.677,719.05,421.018,719.05Z"
          transform="translate(-393.202 -714.84)"
        />
        <path
          fill="#147591"
          d="M388.328,716.16l-1.974,4.395-1.967-4.395h-2.522v6.31l-2.808-6.31h-2.129l-2.867,6.552h1.7l.628-1.463H379.6l.637,1.463h3.239v-4.864l2.149,4.864h1.463l2.2-4.783v4.783H390.9V716.16Zm-11.334,3.676.96-2.29,1,2.29Z"
          transform="translate(-374.047 -714.84)"
        />
        <path
          fill="#147591"
          d="M450,716.154v4.537l-2.712-4.537h-2.377v6.153l-2.741-6.15h-2.129l-2.216,5.075s-.936,0-1.031-.023c-.388-.08-.994-.353-1-1.508v-.475c0-1.536.834-1.648,1.9-1.648h.993v-1.424h-2.1c-.732,0-2.322.558-2.376,3.25-.037,1.814.753,3.309,2.536,3.309h2.121l.628-1.463H442.7l.636,1.463h3.174v-4.8l2.9,4.8H451.6v-6.558Zm-9.881,3.682.958-2.29,1.009,2.29Z"
          transform="translate(-400.627 -714.837)"
        />
      </g>
    </g>
  </svg>
);

AmexCardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

AmexCardIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default AmexCardIcon;
