import React from 'react';

import SettingsPage from 'components/Pages/User/SettingsPage';
import { AddressForm } from 'components/Address';
import settingsMessages from 'components/settingsMessages';

const AddAddress = () => (
  <SettingsPage titleMessage={settingsMessages.addressesTitle}>
    <AddressForm />
  </SettingsPage>
);

export default AddAddress;
