import styled from 'styled-components';

import { AlertMessage } from 'assets/styles/sharedStyles';

export const Description = styled(AlertMessage)`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;
