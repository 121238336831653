const inArray = (key, array) => {
  if (array.indexOf(key) === -1) return key;
};

export default (inputProps, excludeProps) =>
  Object.keys(inputProps)
    .filter(key => inArray(key, excludeProps))
    .reduce((obj, curKey) => {
      obj[curKey] = inputProps[curKey];
      return obj;
    }, {});
