import React from 'react';
import { defineMessages } from 'react-intl';

import { LogoutIcon } from 'components/Icons';
import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  header: {
    defaultMessage: 'Log Out?',
  },
  message: {
    defaultMessage: 'Are you sure you want to log out?',
  },
  confirm: {
    defaultMessage: 'Yes',
  },
  cancel: {
    defaultMessage: 'No',
  },
});

export default props => <StandardAlert {...props} Icon={LogoutIcon} messages={messages} />;
