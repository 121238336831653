import React from 'react';
import { defineMessages } from 'react-intl';

import TermsAndConditions from 'components/TermsAndConditions';
import Page from 'components/Pages/container';
import { TermsIcon } from 'components/Icons';
import { Container } from './styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Terms & Conditions',
  },
});

export default () => (
  <Page settingsPage={true} titleMessage={messages.title} Icon={TermsIcon}>
    <Container>
      <TermsAndConditions />
    </Container>
  </Page>
);
