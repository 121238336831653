import React from 'react';
import PropTypes from 'prop-types';

// Icon
const VisaIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,500H55c-30.3,0-55-24.7-55-55V54C0,23.7,24.7-1,55-1h670c30.3,0,55,24.7,55,55v391 C780,475.3,755.3,500,725,500z M55,29c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V54 c0-13.8-11.2-25-25-25H55z"
      />
      <path className="icon" fill={fill} d="M293.2,348.2l33.4-195.8h53.4l-33.4,195.8H293.2z" />
      <path
        className="icon"
        fill={fill}
        d="M539.3,156.7c-10.6-4-27.1-8.2-47.8-8.2 c-52.7,0-89.9,26.6-90.2,64.6c-0.3,28.1,26.5,43.8,46.8,53.2c20.8,9.6,27.8,15.7,27.7,24.3c-0.1,13.1-16.6,19.1-31.9,19.1 c-21.4,0-32.7-3-50.2-10.3l-6.9-3.1l-7.5,43.8c12.5,5.5,35.5,10.2,59.4,10.4c56.1,0,92.5-26.2,92.9-66.9 c0.2-22.3-14-39.2-44.8-53.2c-18.7-9.1-30.1-15.1-30-24.3c0-8.1,9.7-16.8,30.6-16.8c17.4-0.3,30.1,3.5,39.9,7.5l4.8,2.3 L539.3,156.7"
      />
      <path
        className="icon"
        fill={fill}
        d="M676.6,152.5h-41.2c-12.8,0-22.3,3.5-27.9,16.2l-79.2,179.4h56 c0,0,9.2-24.1,11.2-29.4c6.1,0,60.6,0.1,68.3,0.1c1.6,6.9,6.5,29.3,6.5,29.3h49.5L676.6,152.5z M611.2,278.9 c4.4-11.3,21.3-54.7,21.3-54.7c-0.3,0.5,4.4-11.3,7.1-18.7l3.6,16.9c0,0,10.2,46.7,12.4,56.5L611.2,278.9L611.2,278.9z"
      />
      <path
        className="icon"
        fill={fill}
        d="M247.9,152.5L195.7,286l-5.6-27.1c-9.7-31.3-40-65.2-73.9-82.1 L164,347.9l56.5-0.1l84-195.4H247.9"
      />
      <path
        className="icon"
        fill={fill}
        d="M146.9,152.5h-86l-0.7,4.1c66.9,16.2,111.2,55.4,129.6,102.4 l-18.7-90C167.9,156.6,158.5,152.9,146.9,152.5"
      />
    </g>
  </svg>
);

VisaIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

VisaIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default VisaIcon;
