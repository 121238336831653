import React from 'react';
import PropTypes from 'prop-types';

const GuestServicesIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <polygon
      className="icon guestServicesIcon"
      fill={fill}
      opacity={opacity}
      points="719,962 52,962 52,915.8 102,864.2 102,832 669,832 669,864.2 719,915.8 "
    />
    <path
      className="icon guestServicesIcon"
      fill={fill}
      opacity={opacity}
      d="M653,739L653,739c-4.8-134-108.1-243.3-239.8-257.6c4.9-6.3,7.8-14.1,7.8-22.7c0-13.5-6.9-25-17.9-31.6V398h35v-21H330v21h34v29.1c-11,6.6-17.7,18.4-17.7,31.8c0,8.7,3,16.2,7.9,22.5C222.4,495.7,119.2,605,114.4,739h0l-13.1,54h564.7L653,739z M140,765l5.9-23.9c0.5-1.6,0.8-3.3,0.8-5.1c2.2-61.5,27.8-119,72.1-161.8c44.4-43,102.9-66.6,164.8-66.6s120.3,23.7,164.8,66.6c44.3,42.9,69.9,100.3,72.1,161.8c0.1,1.8,0.4,3.5,0.9,5.2l5.9,23.8H140z"
    />
    <polygon
      className="icon guestServicesIcon"
      fill={fill}
      opacity={opacity}
      points="907.6,322.3 868.2,88.4 989.1,63.9 989.1,33 836.8,65.4 885.5,355 989.1,339.3 989.1,308.4 "
    />
    <path
      className="icon guestServicesIcon"
      fill={fill}
      opacity={opacity}
      d="M804.4,96.7L558.4,96c0,0-136.4-2.4-149.9,5.8L177.3,250.4c-16,9.9-21,30.9-11.2,46.9c9.9,16,30.9,21,46.9,11.1l226.8-96.9L621,235.3l2.2,2.9l-162.4,43c-12.3,0.8-21.7,11.4-20.9,23.7l0.5,7.8c0.8,12.3,11.4,21.7,23.7,20.9l228.5-5l144.2-50.9L804.4,96.7z"
    />
  </svg>
);

GuestServicesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

GuestServicesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default GuestServicesIcon;
