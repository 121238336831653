import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Your order is being placed.',
  },
  content: {
    defaultMessage: 'We are currently processing your order.',
  },
  awaiting_confirmation: {
    defaultMessage: 'Awaiting confirmation...',
  },
  review_content: {
    defaultMessage: 'Waiting for your order to be accepted.',
  },
  nextContent: {
    defaultMessage: 'Please be patient...',
  },
  failedTitle: {
    defaultMessage: 'Your order failed.',
  },
  failedContent: {
    defaultMessage:
      'There was an error processing your order. Please contact a member of staff for further assistance.',
  },
  rejectedTitle: {
    defaultMessage: "We're unable to accept your order.",
  },
  rejectedContent: {
    defaultMessage:
      "Sorry, we weren't able to accept your order. Please try again, or contact a member of staff for further assistance.",
  },
  orderNumber: {
    defaultMessage: 'Order Number',
  },
  contactUs: {
    defaultMessage: 'Contact us',
  },
  contactWaiter: {
    defaultMessage: 'Call a waiter',
  },
});
