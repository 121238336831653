import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FooterItemTitle = styled.small`
  color: ${({ theme }) => theme.color.thankyouColor} !important;
`;

const FooterItemDetail = styled.div`
  font-weight: bold;
`;

export const FooterItem: React.FC<Props> = ({ children, title }) => (
  <Container>
    <FooterItemTitle>{title}</FooterItemTitle>
    <FooterItemDetail>{children}</FooterItemDetail>
  </Container>
);
