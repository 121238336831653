import { setService, setMenuTypeId, promptForMenuType, setMenu } from 'actions/browse';
import {
  hideStaffMenu,
  selectMenuTypeId,
  menuHasMenuType,
  getMenuTypes,
  getMenuById,
} from 'selectors/browse';
import { getServiceById, selectCurrentMenuTypes } from 'selectors/root';

export default (dispatch, getState) =>
  ({ params }, replace) => {
    const state = getState();
    const { menuId, serviceId } = params;

    // return home if the service doesn't exist
    if (!getServiceById(state, serviceId).size) return replace('/');
    dispatch(setService(serviceId));

    if (menuId) {
      // return to service if the menu doesn't exist
      if (!getMenuById(state, menuId)) return replace(`/service/${serviceId}`);
      dispatch(setMenu(menuId));
    }

    // return to service if hidden staff menu
    if (menuId && hideStaffMenu(state, menuId)) return replace(`/service/${params.serviceId}`);

    // kinda blergh stuff below
    const menuTypeId = selectMenuTypeId(state);
    const menuTypes = selectCurrentMenuTypes(state);

    if (
      !menuTypeId ||
      (menuId && getMenuById(state, menuId) && !menuHasMenuType(state, menuId, menuTypeId))
    ) {
      if (menuId && getMenuTypes(state, menuId).size === 1) {
        dispatch(setMenuTypeId(getMenuTypes(state, menuId).first()));
      } else if (menuTypes.size === 1) {
        dispatch(setMenuTypeId(menuTypes.first().get('id')));
      } else if (menuTypes.size) {
        dispatch(promptForMenuType());
      }
    }
  };
