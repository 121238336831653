import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: calc(100% - 120px);
  text-align: left;
`;

export const BackButton = styled.div`
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 15px;

  html[dir='rtl'] & {
    left: auto;
    right: 15px;
  }
`;

export const VenuesTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  margin: 15px 0;
  text-align: center;
`;

export const ServiceSelectionHeader = styled.p`
  margin: 15px 0 5px;
`;
