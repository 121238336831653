import styled from 'styled-components';
import { addCssPrefixTo } from '../../utils';

export const MenuContainer = styled.ul.attrs({
  className: addCssPrefixTo('MENU_CONTAINER'),
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  list-style: none;
  margin: 15px 0 0;

  @media (min-width: 768px) {
    margin: 30px 0 0;
    grid-template-columns: ${props => (props.isGridView ? '1fr 1fr' : '1fr')};
  }
`;
