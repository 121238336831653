import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import globalMessages from 'components/globalMessages';
import ActionButton from 'components/ActionButton';
import answersTransformer from './answersTransformer';
import getQuestionComponent from './getQuestionComponent';

import { Container, ButtonContainer } from './styles';

const arrayIsSubset = (array1 = [], array2 = []) => array1.every(answer => array2.indexOf(answer) >= 0);

const conditionsMet = (conditions, answers) =>
  !conditions ||
  conditions.reduce(
    (met, condition) => !met || arrayIsSubset(condition.answers, answers[condition.question]),
    true
  );

const messages = defineMessages({
  submitAnswers: {
    defaultMessage: 'Submit Answers',
  },
});

const propTypes = {
  onSubmit: PropTypes.func,
  questions: PropTypes.array,
  submitting: PropTypes.bool,
};

const FeedbackForm = ({ onSubmit, questions, submitting }) => {
  const [answers, setAnswers] = useState({});

  return (
    <Container>
      {questions
        .filter(question => conditionsMet(question.conditions, answers))
        .map(question => ({
          Component: getQuestionComponent(question.type),
          question,
        }))
        .map(({ Component, question }) => (
          <Component
            key={question.id}
            id={`${question.id}`}
            question={question.name}
            choices={question.choices}
            required={question.required}
            maxChoices={question.max_choices}
            value={answers[question.id]}
            onUpdate={value => setAnswers(prevState => ({ ...prevState, [question.id]: value }))}
          />
        ))}

      <ButtonContainer>
        <ActionButton
          onClick={!submitting ? () => onSubmit(answersTransformer(questions, answers)) : () => {}}
          primaryButton={true}
          loading={submitting}
          buttonType="submit"
          disabled={submitting}
          label={
            !submitting ? (
              <FormattedMessage {...messages.submitAnswers} />
            ) : (
              <FormattedMessage {...globalMessages.loading} />
            )
          }
        />
      </ButtonContainer>
    </Container>
  );
};

FeedbackForm.propTypes = propTypes;

export default FeedbackForm;
