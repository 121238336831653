import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';

import productMessages from 'components/Product/messages';
import globalMessages from 'components/globalMessages';
import messages from './messages';

const NavigationButtons = ({
  cancel,
  setPageNumber,
  pageNumber,
  totalPages,
  browseOnly,
  editing,
  addToOrder,
}) => (
  <AlertFooter>
    <AlertButtons>
      {pageNumber === 1 && (
        <ActionButton
          dangerButton={true}
          label={
            browseOnly ? (
              <FormattedMessage {...globalMessages.close} />
            ) : (
              <FormattedMessage {...globalMessages.cancel} />
            )
          }
          onClick={cancel}
        />
      )}
      {pageNumber > 1 && (
        <ActionButton
          label={<FormattedMessage {...globalMessages.previous} />}
          onClick={() => setPageNumber(pageNumber - 1)}
        />
      )}
      {pageNumber < totalPages && (
        <ActionButton
          primaryButton={true}
          label={<FormattedMessage {...globalMessages.next} />}
          onClick={() => setPageNumber(pageNumber + 1)}
        />
      )}
      {pageNumber === totalPages && !browseOnly && (
        <ActionButton
          label={
            editing ? (
              <FormattedMessage {...messages.update_item} />
            ) : (
              <FormattedMessage {...productMessages.addToOrder} />
            )
          }
          primaryButton={true}
          onClick={addToOrder}
        />
      )}
      {pageNumber === totalPages && pageNumber !== 1 && browseOnly && (
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.close} />}
          onClick={cancel}
        />
      )}
    </AlertButtons>
  </AlertFooter>
);

NavigationButtons.propTypes = {
  editing: PropTypes.bool,
  browseOnly: PropTypes.bool,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  setPageNumber: PropTypes.func,
  cancel: PropTypes.func,
  addToOrder: PropTypes.func,
};

export default NavigationButtons;
