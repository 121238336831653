import styled from 'styled-components';
import { Link } from 'react-router';

export const Title = styled.h3`
  text-transform: uppercase;
  margin: 10px;
`;

export const LinkMenuStyled = styled(Link)`
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 5px;
  display: block;
  margin: 5px 10px;
  padding: 11px;
  font-size: 0.9em;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.secondayBackground};
`;
