import { defineMessages } from 'react-intl';

export default defineMessages({
  promotion_coupon: {
    defaultMessage: 'Voucher code',
  },
  membership_number: {
    defaultMessage: 'Membership Number',
  },
  promotion_coupon_invalid: {
    defaultMessage: 'The voucher code you entered is not valid',
  },
  membership_number_invalid: {
    defaultMessage: 'The membership number you have entered is not valid',
  },
  promotion_coupon_applied: {
    defaultMessage: 'Voucher applied!',
  },
  membership_number_applied: {
    defaultMessage: 'Membership number applied!',
  },
});
