import styled from 'styled-components';

const switchtype = (type, [error, success, info, warning, defaultCase], theme) => {
  switch (type) {
    case 'error':
      return theme.color[error];
    case 'success':
      return theme.color[success];
    case 'info':
      return theme.color[info];
    case 'warning':
      return theme.color[warning];
    default:
      return theme.color[defaultCase] || defaultCase;
  }
};

export const AlertContainer = styled.div`
  display: ${({ large }) => (large ? 'block' : 'flex')};
  border: ${({ large }) => (large ? '1px solid' : 'none')};
  border-left: ${({ large }) => (large ? '1px solid' : '3px solid')};
  text-align: ${({ large }) => (large ? 'center' : 'left')};
  color: ${({ theme, type }) => switchtype(type, ['danger', 'success', 'info', 'warning', 'text'], theme)};
  background-color: ${({ theme, type }) =>
    switchtype(
      type,
      [
        'errorBackgroundColor',
        'successBackgroundColor',
        'infoBackgroundColor',
        'warningBackgroundColor',
        'secondaryBackground',
      ],
      theme
    )};
  line-height: 1.3;
  font-weight: 700;
  margin: 20px 0 10px;
  min-height: 52px;
  padding: 10px;
  position: relative;

  html[dir='rtl'] & {
    text-align: ${({ large }) => (large ? 'center' : 'right')};
    border-left: ${({ large }) => (large ? '1px solid' : 'none')};
    border-right: ${({ large }) => (large ? '1px solid' : '3px solid')};
  }

  svg {
    width: 32px;
  }
`;

export const IconContainer = styled.div`
  margin-right: ${({ large }) => (large ? '0' : '10px')};

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: ${({ large }) => (large ? '0' : '10px')};
  }
`;

export const Title = styled.h3`
  font-size: 21px;
  line-height: 21px;
  margin-bottom: 5px;
`;
