import { fromJS } from 'immutable';

import { TOGGLE_SIDEBAR_OPEN } from 'actions/sidebar/constants';

const initialState = fromJS({
  isOpen: false,
});

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR_OPEN:
      return state.set('isOpen', !state.get('isOpen'));

    default:
      return state;
  }
}

export default sidebarReducer;
