export const enableCollectionTime = state => state.getIn(['venue', 'id']) !== 389;

export const shouldAllowItemNotes = state => state.getIn(['features', 'order_item_notes']);
export const shouldShowPriceExcludingTax = state => state.getIn(['features', 'show_prices_excluding_tax']);
export const shouldShowGratuity = state =>
  state.getIn(['features', 'show_checkout_gratuity']) ||
  state.getIn(['features', 'show_checkout_service_charge']);
export const shouldShowSplashCallWaiter = state => state.getIn(['features', 'show_splash_call_waiter']);

export const shouldShowContactUs = state => {
  const result = state.getIn(['features', 'show_contact_us']);
  return result === undefined ? true : result;
};

export const shouldShowServiceAddress = state => {
  const result = state.getIn(['features', 'show_service_address']);
  return result === undefined ? true : result;
};
export const shouldShowGeolocation = state => state.getIn(['features', 'enable_geolocation']);

export const shouldShowPostcodeSelector = state => state.getIn(['features', 'enable_postcode_selection']);

export const requirePostcode = state => state.getIn(['features', 'require_postcode']);

export const requireTelephone = state => state.getIn(['features', 'telephone_required']);

export const allergenContactStaff = state => state.getIn(['features', 'allergen_contact_staff']);

export const shouldConfirmLocation = state => state.getIn(['features', 'confirm_location']);

export const disableCovers = state => state.getIn(['features', 'disable_covers']);

export const isGridView = state => state.getIn(['features', 'grid_view']);

export const showMenuSectionCarousel = state => state.getIn(['features', 'parent_menu_carousel']);

export const isDarkModeEnabled = state => state.getIn(['features', 'dark_mode']);

export const isStockManaged = state => state.getIn(['features', 'products_stock_managed']);

export const kioskModeEnabled = state => state.getIn(['features', 'kiosk_mode']); // initialised from cookie, not feature flag

export const showExitButton = state => state.getIn(['features', 'show_exit_button']); // initialised from cookie, not feature flag

export const isPreOrderDatesOnly = state => state.getIn(['features', 'pre_order_dates_only']);

export const showAllergenCheckbox = state => state.getIn(['features', 'display_allergen_checkbox']);

export const hasProductCustomisationAccordion = state =>
  state.getIn(['features', 'product_customisation_accordion']);
