import React from 'react';
import PropTypes from 'prop-types';

import { InputContainer, CheckboxInput as StyledInput, StyledTickIcon } from './styles';

const propTypes = {
  children: PropTypes.node,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

const CheckboxInput = ({ checked, ...inputProps }) => (
  <InputContainer>
    <StyledInput checked={!!checked} {...inputProps} type="checkbox" />
    {inputProps.checked && <StyledTickIcon />}
  </InputContainer>
);

CheckboxInput.propTypes = propTypes;

export default CheckboxInput;
