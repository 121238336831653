import { defineMessages } from 'react-intl';

export default defineMessages({
  valueRequired: {
    defaultMessage: 'This value is required',
  },
  invalidEmail: {
    defaultMessage: 'Invalid email address',
  },
  invalidLocationName: {
    defaultMessage: 'Invalid name',
  },
  invalidLocationNumber: {
    defaultMessage: 'Invalid number',
  },
  invalidCovers: {
    defaultMessage: 'Please enter a number between 1 and 50',
  },
  notAcceptedTerms: {
    defaultMessage: 'You need to accept the Terms and Conditions.',
  },
  invalidPassword: {
    defaultMessage: 'The password must match the above criteria',
  },
  passwordsDoNotMatch: {
    defaultMessage: 'The passwords you have entered do not match.',
  },
});
