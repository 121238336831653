import React from 'react';
import PropTypes from 'prop-types';

import { MessageContainer } from './styles';

const Message = props => <MessageContainer>{props.intlLabel}</MessageContainer>;

Message.propTypes = {
  intlLabel: PropTypes.string,
};

export default Message;
