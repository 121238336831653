import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import Header from 'components/Header';
import PageTitle from 'components/PageTitle';
import ActivePasscode from 'components/ActivePasscode';
import PageHeader from 'components/PageHeader';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import { isOfflineVenueMode, isServiceBrowseOnly } from 'selectors/root';
import { addCssPrefixTo, showFooterMenu } from 'utils';

import { StyledPage, PageContent } from './styles';

const propTypes = {
  titleMessage: PropTypes.object,
  intlTitle: PropTypes.string,
  redirectCondition: PropTypes.bool,
  hideCartButton: PropTypes.bool,
  isOfflineVenue: PropTypes.bool,
  serviceBrowseOnly: PropTypes.bool,
  hideSidebar: PropTypes.bool,
  backButton: PropTypes.object,
  hideBackButton: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iconFill: PropTypes.string,
  image: PropTypes.string,
  headerOnlyTitle: PropTypes.bool,
  large: PropTypes.bool,
  settingsPage: PropTypes.bool,
  preContainerContent: PropTypes.func,
  container: PropTypes.bool,
  location: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
  hideBackButton: false,
  titleMessage: null,
  hideCartButton: false,
  serviceBrowseOnly: false,
  large: false,
  hideSidebar: false,
  settingsPage: false,
  container: true,
  preContainerContent: () => {},
};

const PageContainer = ({
  titleMessage,
  intlTitle,
  redirectCondition,
  hideCartButton,
  isOfflineVenue,
  serviceBrowseOnly,
  hideSidebar,
  backButton,
  hideBackButton,
  Icon,
  iconFill,
  image,
  headerOnlyTitle,
  large,
  settingsPage,
  preContainerContent,
  container,
  location,
  children,
}) => {
  const pageTitleRef = useRef();
  const pageHeaderTitle = titleMessage ? <FormattedMessage {...titleMessage} /> : intlTitle;

  useEffect(() => {
    if (redirectCondition) browserHistory.push('/');
  }, [redirectCondition]);

  return (
    <>
      {intlTitle && <PageTitle title={intlTitle} />}
      {titleMessage && <PageTitle message={titleMessage} />}

      <Header
        subTitle={titleMessage ? <FormattedMessage {...titleMessage} /> : intlTitle}
        hideCartButton={hideCartButton || isOfflineVenue || serviceBrowseOnly}
        hideSidebar={hideSidebar}
        backButton={backButton}
        hideBackButton={hideBackButton}
        icon={Icon && <Icon />}
        pageTitleRef={pageTitleRef}
      />

      <StyledPage>
        <ActivePasscode />

        <PageHeader
          Icon={Icon}
          iconFill={iconFill}
          image={image}
          title={headerOnlyTitle ? null : pageHeaderTitle}
          large={large}
          backButton={backButton}
          hideBackButton={hideBackButton}
          settingsPage={settingsPage}
          pageTitleRef={pageTitleRef}
        />
        {preContainerContent()}
        <div className={`${container ? 'container' : ''} ${addCssPrefixTo('PAGE_CONTAINER')}`}>
          <PageContent container={container} showFooter={showFooterMenu(location.pathname)}>
            {children}
          </PageContent>
        </div>
      </StyledPage>
    </>
  );
};

PageContainer.propTypes = propTypes;
PageContainer.defaultProps = defaultProps;

export default withRouter(
  connect((state, { params }) => ({
    sidebarOpen: selectSidebarIsOpen(state),
    isOfflineVenue: isOfflineVenueMode(state),
    serviceBrowseOnly: isServiceBrowseOnly(state, params?.serviceId),
  }))(PageContainer)
);
