import React from 'react';
import PropTypes from 'prop-types';

const HungerstationIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" className="icon HungerstationIcon" width="180" height="180" />
    <g transform="translate(12 57.787)">
      <g transform="translate(0 1)">
        <path
          fill="#fff"
          fillRule="evenodd"
          className="icon HungerstationIcon"
          d="M149.6,33.619a4.942,4.942,0,0,0-.219-4.353l-3.289-5.915a13.663,13.663,0,0,0,1.116-1.57,12.829,12.829,0,0,0,1.886-6.774A13.287,13.287,0,0,0,144.9,5.1a14.165,14.165,0,0,0-9.923-3.84H105.415a4.939,4.939,0,0,0-4.48,2.865,17.755,17.755,0,0,0-4.749-2.471A18.489,18.489,0,0,0,87.069.935a18.006,18.006,0,0,0-8.459,3.66,4.941,4.941,0,0,0-4.669-3.332H67.467a4.942,4.942,0,0,0-4.453,2.8l-.486-.693a4.943,4.943,0,0,0-4.046-2.1H43.022a4.929,4.929,0,0,0-3.565,1.519,4.93,4.93,0,0,0-3.565-1.519H29.381c-.733,0-3.275.073-3.946.073H19a4.927,4.927,0,0,0-3.563,1.517,4.925,4.925,0,0,0-3.562-1.517H5.4A4.943,4.943,0,0,0,.456,6.278V31.6a4.939,4.939,0,0,0,2.653,4.378,12.345,12.345,0,0,0-2.018,6.946,11.689,11.689,0,0,0,2.418,7.444l-1.052.611A4.942,4.942,0,0,0,.3,56.928a13.887,13.887,0,0,0,5.679,7.09,17,17,0,0,0,9.368,2.5,15.783,15.783,0,0,0,10.092-3.182,11.946,11.946,0,0,0,2.346-2.471v.165a4.942,4.942,0,0,0,4.942,4.942s8.889.069,9.564.069l7.166-.032a4.942,4.942,0,0,0,4.735-3.526l.334-1.046.755-.18.533,1.066a5.212,5.212,0,0,0,4.7,3.661l6.64.158c.8,0,3.321-.078,3.891-.078l6.628-.092a4.94,4.94,0,0,0,4.371-2.638,4.94,4.94,0,0,0,4.371,2.638h6.509a4.943,4.943,0,0,0,4.705-3.432,18.688,18.688,0,0,0,22.407.483,4.941,4.941,0,0,0,4.52,2.949h6.509a4.943,4.943,0,0,0,4.452-2.793l.484.689a4.942,4.942,0,0,0,4.046,2.1h5.018A4.942,4.942,0,0,0,150,61.028V35.573a4.918,4.918,0,0,0-.4-1.954"
          transform="translate(0 -0.67)"
        />
      </g>
      <path
        fill="#592913"
        fillRule="evenodd"
        className="icon HungerstationIcon"
        d="M28.214,151.384a12.128,12.128,0,0,1-6.691-1.709,9.02,9.02,0,0,1-3.709-4.618l5.564-3.236A5.053,5.053,0,0,0,28.4,145.2q2.981,0,2.982-1.694,0-1.117-1.719-1.8-.725-.3-3.19-1.013a13.967,13.967,0,0,1-5.528-2.855,6.527,6.527,0,0,1-2.036-5.109,7.179,7.179,0,0,1,2.528-5.709,9.311,9.311,0,0,1,6.309-2.182,10.773,10.773,0,0,1,5.672,1.491,9.588,9.588,0,0,1,3.782,4.328l-5.455,3.2a4.09,4.09,0,0,0-3.963-2.837,2.8,2.8,0,0,0-1.764.476,1.476,1.476,0,0,0-.6,1.207,1.69,1.69,0,0,0,.889,1.426,14.889,14.889,0,0,0,3.466,1.352l2.964,1.068a12.013,12.013,0,0,1,2.5,1.3,5.506,5.506,0,0,1,2,2.286,7.664,7.664,0,0,1,.655,3.283,7.059,7.059,0,0,1-2.654,5.854,10.953,10.953,0,0,1-7.019,2.109m30.182-26v6.291H52.1v19.164H45.6V131.674H39.3v-6.291Zm38.433,0v6.291H90.537v19.164H84.028V131.674H77.737v-6.291Z"
        transform="translate(-12.872 -89.48)"
      />
      <path
        fill="#592913"
        fillRule="evenodd"
        className="icon HungerstationIcon"
        d="M100.465,74.229h6.509V48.775h-6.509Zm17.818-7.764a7.255,7.255,0,1,0-1.964-4.964,6.693,6.693,0,0,0,1.964,4.964m14.382,4.473a13.614,13.614,0,0,1-18.946,0,13.432,13.432,0,0,1,0-18.873,13.613,13.613,0,0,1,18.946,0,13.432,13.432,0,0,1,0,18.873m19.979-22.164h6.473V74.229H154.1l-8.981-12.8v12.8h-6.509V48.775h5.055l8.982,12.8ZM70.735,62.75l-3.966.669,2.068-7.031,1.9,6.362ZM64.541,48.783,56.346,74.5h7.118L64.4,71.37l.539-1.806,7.538-1.351,1.1,3.345.976,2.941h6.77L72.82,48.783h-8.28ZM33.056,28.924,25.931,30.2V19.479H19.456V44.8H25.93V36.019l7.125-1.277V44.8h6.438V19.479H33.056ZM60.773,42.978a10.7,10.7,0,0,1-7.257,2.437,10.7,10.7,0,0,1-7.257-2.437,8.132,8.132,0,0,1-2.82-6.475v-17.1h6.512V35.884q0,3.2,3.565,3.2t3.564-3.2V19.406h6.512V36.5a8.136,8.136,0,0,1-2.819,6.475M81.525,19.406H88V44.869H82.98L74,32.065v12.8H67.484V19.406H72.54l8.985,12.8ZM116.6,28.174l.419,2.549a12.2,12.2,0,0,1-1.989,9.526,12.4,12.4,0,0,1-8.427,4.908A13.308,13.308,0,0,1,96.309,42.98a12.769,12.769,0,0,1-5.318-8.673,12.925,12.925,0,0,1,2.233-9.953,12.785,12.785,0,0,1,8.706-5.341,13.522,13.522,0,0,1,6.7.519,12.216,12.216,0,0,1,5.268,3.446L108.959,27a5.407,5.407,0,0,0-2.521-1.539,7.424,7.424,0,0,0-3.4-.177,6.631,6.631,0,0,0-4.562,2.742,6.867,6.867,0,0,0-1.087,5.267,6.777,6.777,0,0,0,8.319,5.745q4.379-.721,5.235-4.254l-6.1,1-.91-5.527,12.67-2.086Zm9.385,10.584h9.858v6.111H119.473V19.406H135.66v6.075h-9.676v3.529h8.767v6h-8.767Zm19.381-13.35v6.307l3.5-.438a3.5,3.5,0,0,0,2.07-.923,3.2,3.2,0,0,0,0-4.147,2.6,2.6,0,0,0-1.964-.8Zm6.766,19.461-4.438-8.221-2.328.391v7.83h-6.512V19.406h10.185a9.145,9.145,0,0,1,6.53,2.491,8.311,8.311,0,0,1,2.637,6.311,7.921,7.921,0,0,1-1.164,4.2,8.563,8.563,0,0,1-3.165,3.037l5.238,9.421h-6.984Z"
        transform="translate(-14.058 -12.871)"
      />
    </g>
  </svg>
);

HungerstationIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

HungerstationIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default HungerstationIcon;
