import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import Product from 'components/Product';
import RemoveFavouritesButton from 'components/FavouriteProduct/RemoveFavouritesButton';

const FavouriteProduct = ({ item, menuTypeId, serviceId }) => {
  const product = item.get('product');
  if (typeof product === 'undefined') return null;

  return (
    <Product
      item={item}
      additionalHeaderButtons={[
        <RemoveFavouritesButton key="removeFavourite" productId={product.get('id')} />,
      ]}
      menuTypeId={menuTypeId}
      serviceId={serviceId}
    />
  );
};

FavouriteProduct.propTypes = {
  item: PropTypes.instanceOf(Map),
  menuTypeId: PropTypes.number,
  serviceId: PropTypes.string,
};

export default FavouriteProduct;
