import { List } from 'immutable';

import { CLEAR_ORDER } from 'actions/order/constants';
import orderReducer from './orderReducer';

function orderListReducer(state = new List(), action) {
  if (!action.serviceId || !action.menuTypeId) return state;
  let orderIndex = state.findIndex(
    order => order.get('serviceId') === action.serviceId && order.get('menuTypeId') === action.menuTypeId
  );
  if (orderIndex === -1) orderIndex = state.size;
  if (action.type === CLEAR_ORDER) {
    return state.remove(orderIndex);
  }
  const order = orderReducer(state.get(orderIndex), action);
  return state.set(orderIndex, order);
}

export default orderListReducer;
