/* eslint-disable global-require */
/* eslint-disable no-empty */

const mapFiles = context => {
  const keys = context.keys();
  const values = keys.map(context);
  return keys.reduce((acc, key, index) => ({
    ...acc,
    [key.replace(/\.\/(.*)\.json$/, '$1')]: values[index],
  }), {});
}

const localeData = mapFiles(require.context('./', true, /\.json$/));

const intlDataArr = [];
Object.keys(localeData).forEach(locale => {
  try {
    const localeCode = locale.split('_')[0]; // react-intl/locale-data only supports 2/3 letter codes
    intlDataArr.push(...require(`react-intl/locale-data/${localeCode}`))
  }
  catch(e) {}
});


const getLocaleCode = (locale = 'en-GB') => {
  const localeCodes = { en: 'en-GB', zh: 'zh-CN' }; // codes require a region
  const localeCode = locale.replace('_', '-');
  return localeCodes[localeCode] || localeCode;
};

export const dateLocales = {};
Object.keys(localeData).forEach(locale => {
  const localeCode = getLocaleCode(locale);
  try {
    dateLocales[localeCode] = require(`date-fns/locale/${localeCode}/index.js`);
  }
  catch(e) {}
});

export default localeData;
