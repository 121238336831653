import styled from 'styled-components';

export const PageContent = styled.div`
  padding: 10px 10px 0;
  max-width: 1024px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 10px 20px 0;
  }
`;

export const StyledTabs = styled.ul`
  margin-bottom: 30px;
  overflow-y: scroll;
  white-space: nowrap;
  overflow: auto;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text};

  li {
    max-width: 80px;
    white-space: normal;
    display: inline-block;
    padding: 10px;
    border-bottom: 2px solid ${({ theme }) => theme.color.borderColor};
    box-sizing: content-box;

    @media (min-width: 768px) {
      max-width: none;
      padding: 10px 20px;
      font-size: 14px;
    }

    &.tabSelected {
      color: ${({ theme }) => theme.color.primaryText};
      border-color: ${({ theme }) => theme.color.success};
    }
  }
`;
