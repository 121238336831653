import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import globalMessages from 'components/globalMessages';
import Checkbox from 'components/Checkbox';
import { EditIconAlt, DeleteIcon } from 'components/Icons';
import { AddressText } from 'components/Address';
import { openDeleteAddressAlert } from 'actions/UI';
import { deleteAddress, editAddress } from 'actions/user';
import { selectSettingIsDefault } from 'selectors/user';

import { AddressContainer, DeleteContainer, Controls, LoadingSpinnerStyled, VerifyButton } from './styles';

type Props = {
  address?: Map<string, any>;
  isDefault?: boolean;
  settingIsDefault?: boolean;
  isSelected?: boolean;
  onChange?: () => void;
  openDeleteAddressAlert?: (addressLabel: string, addressId: string, cb: () => void) => void;
  deleteAddress?: (addressId: string) => void;
  editAddress?: (address: {}, addressId: string) => void;
};

const Address = ({
  address,
  isDefault,
  onChange,
  openDeleteAddressAlert,
  deleteAddress,
  settingIsDefault,
  isSelected,
  editAddress,
}: Props) => {
  const addressId = address?.get('id');

  return (
    <AddressContainer>
      {!address.get('latitude') && !address.get('longitude') && (
        <VerifyButton onClick={() => editAddress(address?.toJS(), addressId)} type="button">
          <FormattedMessage {...globalMessages.verify} />
        </VerifyButton>
      )}

      <AddressText address={address} isDefault={isDefault} primaryText={true} />

      <Controls>
        {isSelected && settingIsDefault ? (
          <LoadingSpinnerStyled width="26px" height="26px" noPad={true} />
        ) : (
          <Checkbox checked={isDefault} onChange={!isDefault ? onChange : () => {}}>
            <FormattedMessage {...globalMessages.isDefaultText} />
          </Checkbox>
        )}

        <div>
          <Link to={`/user/edit_address/${addressId}`}>
            <EditIconAlt />
          </Link>
          {!settingIsDefault && (
            <DeleteContainer
              type="button"
              onClick={() =>
                openDeleteAddressAlert(address?.get('addressLabel'), addressId, () =>
                  deleteAddress(addressId)
                )
              }
            >
              <DeleteIcon />
            </DeleteContainer>
          )}
        </div>
      </Controls>
    </AddressContainer>
  );
};

export default connect(
  state => ({
    settingIsDefault: selectSettingIsDefault(state),
  }),
  { openDeleteAddressAlert, deleteAddress, editAddress }
)(Address);
