import React from 'react';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectCurrentMenuTypes } from 'selectors/root';
import ActionButton from 'components/ActionButton';
import { setMenuTypeId } from 'actions/browse';
import { Title, MenuButtons } from './styles';
import { messages } from './messages';

interface Props {
  closeAlert: () => void;
  setMenuTypeId: (id: number) => void;
}

const MenuTypePrompt: React.FC<Props> = ({ closeAlert, setMenuTypeId }) => {
  const menuTypes = useSelector(selectCurrentMenuTypes);

  const handleClick = (menuTypeId: number) => {
    setMenuTypeId(menuTypeId);
    closeAlert();
  };

  return (
    <div className="containerFullPad">
      <Title>
        <FormattedMessage {...messages.header} />
      </Title>

      <MenuButtons>
        {menuTypes.map(menuType => (
          <ActionButton
            key={menuType.get('id')}
            onClick={() => handleClick(menuType.get('id'))}
            label={menuType.get('name')}
            primaryButton={true}
          />
        ))}
      </MenuButtons>
    </div>
  );
};

export default connect(null, { setMenuTypeId })(MenuTypePrompt);
