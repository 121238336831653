import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import Checkbox from 'components/Checkbox';
import permissionState from './permissionState';
import { Label } from './styles';

const messages = defineMessages({
  termsAndConditionsLabel: {
    defaultMessage:
      'By checking this box you confirm that you would like to receive exclusive offers, more information on our products/services, events and news.',
  },
});

type Props = {
  onChange: (checked: boolean) => void;
  value?: '' | 10 | 20;
  label?: object;
};

const ThirdPartyPermission = ({ onChange, value, label = messages.termsAndConditionsLabel }: Props) => (
  <Checkbox
    id="ThirdPartyPermission"
    checked={value === permissionState.ACCEPTED}
    onChange={event => onChange(event.target.checked ? permissionState.ACCEPTED : permissionState.DECLINED)}
  >
    <Label>
      <FormattedMessage {...label} />
    </Label>
  </Checkbox>
);

export default ThirdPartyPermission;
