import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import DayPickerCalendar from 'components/DayPickerCalendar';
import { DayPickerWrapper } from './styles';

const DateModifier = ({ label, date, updateValues }) => {
  const now = new Date();
  const toDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);

  return (
    <DayPickerWrapper>
      <DayPickerCalendar
        buttonLabel={`${label}: ${date ? date.toLocaleDateString() : ''}`}
        onDayChange={val => {
          if (val === undefined) return '';
          updateValues(format(val, 'yyyy-MM-dd'));
        }}
        defaultMonth={date}
        toMonth={new Date(toDate.getFullYear(), toDate.getMonth())}
        selected={date}
        disabled={{ before: now }}
      />
    </DayPickerWrapper>
  );
};

DateModifier.propTypes = {
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  updateValues: PropTypes.func.isRequired,
};

export default DateModifier;
