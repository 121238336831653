import React from 'react';
import PropTypes from 'prop-types';

import EggsIcon from 'components/Icons/EggIcon';
import FishIcon from 'components/Icons/FishIcon';
import MilkIcon from 'components/Icons/MilkIcon';
import SoyaIcon from 'components/Icons/SoyaIcon';
import LupinIcon from 'components/Icons/LupinIcon';
import NutsIcon from 'components/Icons/AlmondIcon';
import HalalIcon from 'components/Icons/HalalIcon';
import VeganIcon from 'components/Icons/VeganIcon';
import CeleryIcon from 'components/Icons/CeleryIcon';
import GlutenIcon from 'components/Icons/GlutenIcon';
import KosherIcon from 'components/Icons/KosherIcon';
import SesameIcon from 'components/Icons/SesameIcon';
import PeanutsIcon from 'components/Icons/PeanutIcon';
import MustardIcon from 'components/Icons/MustardIcon';
import MolluscsIcon from 'components/Icons/MolluscIcon';
import SulphurIcon from 'components/Icons/SulphiteIcon';
import VegetarianIcon from 'components/Icons/VegetarianIcon';
import CrustaceansIcon from 'components/Icons/CrustaceansIcon';

const icons = {
  EggsIcon,
  FishIcon,
  MilkIcon,
  SoyaIcon,
  LupinIcon,
  NutsIcon,
  HalalIcon,
  VeganIcon,
  CeleryIcon,
  GlutenIcon,
  KosherIcon,
  SesameIcon,
  PeanutsIcon,
  MustardIcon,
  MolluscsIcon,
  SulphurIcon,
  VegetarianIcon,
  CrustaceansIcon,
};

const AllergenIcon = ({ allergenName, width, height, fill, className }) => {
  let Icon;
  switch (allergenName) {
    case 'sesame_seeds':
      Icon = icons.SesameIcon;
      break;
    case 'sulphur_dioxide':
      Icon = icons.SulphurIcon;
      break;
    default: {
      // Take the allergen name and convert it to title case to fetch correct icon dynamically.
      if (allergenName.indexOf('nut_') === 0) allergenName = 'nuts';
      if (allergenName.indexOf('gluten_') === 0) {
        allergenName = allergenName.split('_')[0];
      }
      Icon = icons[`${allergenName.charAt(0).toUpperCase()}${allergenName.slice(1)}Icon`];
    }
  }
  return <Icon width={width} height={height} fill={fill} className={className} />;
};

AllergenIcon.propTypes = {
  allergenName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default AllergenIcon;
