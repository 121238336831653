import React from 'react';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import PoweredFooter from 'components/PoweredFooter';
import {
  HomeIcon,
  TermsIcon,
  OrdersIcon,
  WaiterIcon,
  ContactIcon,
  LanguageIcon,
  SettingsIcon,
  FavouriteIcon,
  WebsiteIcon,
  LinkIcon,
  RewardIcon,
} from 'components/Icons';
import { shouldShowContactUs, kioskModeEnabled } from 'selectors/features';
import { getLocales, getAllowedCallTheWaiterServices, selectSidebarLinks } from 'selectors/root';
import SidebarLink from 'components/SidebarLink';
import globalMessages from 'components/globalMessages';
import { getIcon } from 'utils';

import { useLoyalty } from 'hooks';
import { CAPABILITY_GET_OFFERS } from 'actions/loyalty/constants';
import { Container, SidebarList, SidebarMenuItem } from './styles';

const messages = defineMessages({
  settingsPage: {
    defaultMessage: 'User Settings',
  },
  ordersPage: {
    defaultMessage: 'Order History',
  },
  favouritesPage: {
    defaultMessage: 'Favourites',
  },
  termsPage: {
    defaultMessage: 'Terms & Conditions',
  },
  contactPage: {
    defaultMessage: 'Contact Us',
  },
  language: {
    defaultMessage: 'Language',
  },
  callWaiter: {
    defaultMessage: 'Call A Waiter',
  },
  privacySettings: {
    defaultMessage: 'Privacy Settings',
  },
  wiqWebsite: {
    defaultMessage: 'Wi-Q Website',
  },
  myRewards: {
    defaultMessage: 'Rewards',
  },
});

const WiQItem = () => (
  <SidebarMenuItem>
    <SidebarLink
      icon={<WebsiteIcon />}
      label={<FormattedMessage {...messages.wiqWebsite} />}
      href="http://www.wi-q.com/"
      targetBlank={true}
    />
  </SidebarMenuItem>
);

const SidebarItem = (toggleSidebarOpen, { Icon, path, message, displayIf = true }) => {
  if (displayIf) {
    return (
      <SidebarMenuItem>
        <SidebarLink
          icon={<Icon />}
          label={<FormattedMessage {...message} />}
          onClick={toggleSidebarOpen}
          to={{ pathname: path, state: { transition: 'fadeGrow' } }}
        />
      </SidebarMenuItem>
    );
  }
  return null;
};

SidebarItem.propTypes = {
  displayIf: PropTypes.bool,
  message: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

const ExternalLink = ({ iconName, url, name }) => (
  <SidebarMenuItem>
    <SidebarLink
      icon={getIcon(iconName, {
        defaultIcon: <LinkIcon />,
      })}
      label={name}
      href={url}
      targetBlank={true}
    />
  </SidebarMenuItem>
);

ExternalLink.propTypes = {
  iconName: PropTypes.string,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const sidebarItemFactory = toggleSidebarOpen => SidebarItem.bind(null, toggleSidebarOpen);

const SidebarMenu = ({
  callTheWaiterServices,
  isLoggedIn,
  toggleSidebarOpen,
  serviceId,
  localesLength,
  externalLinks,
  showContactUs,
  kioskMode,
}) => {
  const Item = sidebarItemFactory(toggleSidebarOpen);
  const loyalty = useLoyalty();

  return (
    <Container>
      <SidebarList>
        <Item Icon={HomeIcon} path="/" message={globalMessages.home} />
        {loyalty.capabilities?.includes(CAPABILITY_GET_OFFERS) && (
          <Item Icon={RewardIcon} path="/rewards" message={messages.myRewards} />
        )}
        {(callTheWaiterServices.find(service => service.get('id').toString() === serviceId) ||
          (!serviceId && callTheWaiterServices.size >= 1)) && (
          <Item Icon={WaiterIcon} path="/call-a-waiter" message={messages.callWaiter} />
        )}
        <Item
          Icon={SettingsIcon}
          path="/user/settings"
          message={messages.settingsPage}
          displayIf={isLoggedIn}
        />
        <Item Icon={OrdersIcon} path="/user/orders" message={messages.ordersPage} displayIf={isLoggedIn} />
        <Item
          Icon={FavouriteIcon}
          path="/user/favourites"
          message={messages.favouritesPage}
          displayIf={isLoggedIn}
        />
        <Item Icon={TermsIcon} path="/terms_conditions" message={messages.termsPage} />
        {showContactUs && (
          <Item
            Icon={ContactIcon}
            path={`/contact${serviceId ? `/${serviceId}` : ''}`}
            message={messages.contactPage}
          />
        )}
        <Item
          Icon={LanguageIcon}
          path="/language"
          message={messages.language}
          displayIf={localesLength > 1}
        />
        <Item
          Icon={SettingsIcon}
          path="/user/privacy"
          message={messages.privacySettings}
          displayIf={!isLoggedIn}
        />

        {!kioskMode &&
          externalLinks.size >= 1 &&
          externalLinks.map((link, index) => (
            <ExternalLink
              // eslint-disable-next-line react/no-array-index-key
              key={`icon-${link.get('name')}-${index}`}
              iconName={link.get('icon')}
              url={link.get('url')}
              name={link.get('name')}
            />
          ))}

        {!kioskMode && <WiQItem />}
      </SidebarList>

      <PoweredFooter />
    </Container>
  );
};

SidebarMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  externalLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  localesLength: PropTypes.number,
  toggleSidebarOpen: PropTypes.func.isRequired,
  callTheWaiterServices: PropTypes.instanceOf(Map),
  showContactUs: PropTypes.bool,
  kioskMode: PropTypes.bool,
  serviceId: PropTypes.string,
};

SidebarMenu.defaultProps = {
  isLoggedIn: false,
};

const mapStateToProps = state => ({
  kioskMode: kioskModeEnabled(state),
  externalLinks: selectSidebarLinks(state),
  localesLength: getLocales(state)?.size,
  callTheWaiterServices: getAllowedCallTheWaiterServices(state),
  showContactUs: shouldShowContactUs(state),
});

export default connect(mapStateToProps)(SidebarMenu);
