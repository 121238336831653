import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectMenuTypeId, selectServiceId } from 'selectors/browse';
import {
  CAPABILITY_CALCULATE_POINTS,
  CAPABILITY_GET_OFFERS,
  CAPABILITY_SIGN_UP,
} from 'actions/loyalty/constants';
import {
  selectLoyaltyCapabilitiesByService,
  selectLoyaltyTypeByService,
  selectLoyaltyUser,
} from 'selectors/loyalty';

import { selectOrder, selectOrderTotal } from 'selectors/order';
import useSWR from 'swr';
import { fetchHelper } from 'utils';
import { OPEN_ALERT } from 'actions/UI/constants';
import { LOYALTY_POINTSHOP_REDEEMED, LOYALTY_REDEEM } from 'appConstants';
import { addLoyaltyOfferToOrder, removeLoyaltyOfferFromOrder } from 'actions/order';
import { defineMessage, useIntl } from 'react-intl';
import { selectServices } from '../selectors/root';
// import { setLoyaltyUser } from 'actions/loyalty';
// import { fromJS } from 'immutable';

export interface Offer {
  id: string;
  name: string;
  description: string;
  image: string;
  startDate?: Date;
  endDate?: Date;
  redeemable: boolean;
  nonRedeemableReason?: string;
  inBasket: boolean;
  type: string;
  exchangeCost?: number | null;
}

export interface User {
  accountNo: string;
  points: number;
  credit: number;
  tier?: string; // also type tiers better?
}

export const auraEarnableError = defineMessage({
  defaultMessage:
    'There is a technical issue with adding your Aura points. Please contact a team member or Customer Service at support@aura-mena.com for support. Thank you!',
});

const fetcher = (...args) => fetch(...args).then(res => res.json());

/**
 * loyalty hook to provide loading state, user info, loyalty type and earnable points
 */
const useLoyalty = (): {
  type: string; // type loyalty better here in the future?
  hideWiQLogin: boolean;
  capabilities: Array<string>;
  offers: Array<Offer>;
  offersLoading: boolean;
  loading: boolean;
  user?: User;
  earnable: number;
  earnableError?: string;
  redeemOffer: Function;
  removeOffer: Function;
} => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentServiceId = useSelector(selectServiceId);
  const services = useSelector(selectServices);
  const serviceId = currentServiceId ?? services?.toSeq()?.first()?.get('id');
  const menuTypeId = useSelector(selectMenuTypeId);
  const order = useSelector(selectOrder)?.toJS();
  const orderTotal = useSelector(selectOrderTotal);

  const [loading, setLoading] = useState(false);
  const [earnable, setEarnable] = useState<number>(0);
  const [earnableError, setEarnableError] = useState<string>('');

  const type = useSelector(state => selectLoyaltyTypeByService(state, serviceId));
  const capabilities = useSelector(state => selectLoyaltyCapabilitiesByService(state, serviceId));
  const user = useSelector(selectLoyaltyUser);

  const hideWiQLogin = capabilities?.includes(CAPABILITY_SIGN_UP);

  const { data: rawOffers, error } = useSWR(
    () =>
      user && capabilities?.includes(CAPABILITY_GET_OFFERS)
        ? `/api/loyalty/offers?service_id=${serviceId}&menu_type_id=${menuTypeId}`
        : null,
    fetcher
  );

  const offersLoading = !rawOffers && !error;

  const offers: Offer[] = useMemo(
    () =>
      rawOffers?.offers?.map((offer: Offer) => ({
        ...offer,
        inBasket: order?.items.find(item => item?.details?.como_asset_id === offer.id) || false,
      })),
    [rawOffers, order]
  );

  // listen for changes on order and update earnable points
  useEffect(() => {
    if (!type) return;

    if (capabilities && capabilities.includes(CAPABILITY_CALCULATE_POINTS)) {
      setLoading(true);
      setEarnable(0);
      fetchHelper(
        `/api/loyalty/order-to-points`,
        'POST',
        { order },
        data => {
          setLoading(false);
          setEarnable(data.points);
        },
        () => {
          setLoading(false);
          setEarnable(0);
          setEarnableError(intl.formatMessage(auraEarnableError));
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTotal, capabilities, type, user]);

  const redeemOffer = (offer: Offer, inline: boolean) => {
    if (inline) {
      if (!offer.inBasket) {
        dispatch(addLoyaltyOfferToOrder(offer, menuTypeId, serviceId));

        if (offer.id.includes('ps_')) {
          dispatch({
            type: OPEN_ALERT,
            alertType: LOYALTY_POINTSHOP_REDEEMED,
          });
        }
      } else if (offer.inBasket) {
        dispatch(removeLoyaltyOfferFromOrder(offer, menuTypeId, serviceId));
      }

      return;
    }

    const onRedeem = () => {
      dispatch(addLoyaltyOfferToOrder(offer, menuTypeId, serviceId));
    };

    const onRemove = () => {
      dispatch(removeLoyaltyOfferFromOrder(offer, menuTypeId, serviceId));
    };

    dispatch({
      type: OPEN_ALERT,
      alertType: LOYALTY_REDEEM,
      alertProps: { offer, onRedeem, onRemove },
    });
  };

  const removeOffer = (offer: Offer) => {
    const onRedeem = () => {
      dispatch(addLoyaltyOfferToOrder(offer, menuTypeId, serviceId));
    };

    dispatch({
      type: OPEN_ALERT,
      alertType: LOYALTY_REDEEM,
      alertProps: { offer, onRedeem },
    });
  };

  return {
    type,
    capabilities,
    loading,
    user: user && typeof user.toJS === 'function' ? user.toJS() : undefined,
    earnable,
    earnableError,
    offers: offers || [],
    offersLoading,
    hideWiQLogin,
    redeemOffer,
    removeOffer,
  };
};

export default useLoyalty;
