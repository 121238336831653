import styled from 'styled-components';

export const StyledInfoBox = styled.div`
  background: ${({ theme }) => theme.color.secondaryBackground};
  color: ${({ theme }) => theme.color.primaryText};
  border-radius: 5px;
  margin: 2px 0 10px;
  padding: 10px;
  width: 100%;

  h2 {
    font-weight: bold;
    margin: 0;
  }

  p {
    font-style: italic;
    margin: 0 0 10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;
