import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    defaultMessage: 'Kiosk Mode',
  },
  enableKioskMode: {
    defaultMessage: 'Enable Kiosk Mode',
  },
  loginMessage: {
    defaultMessage: 'Please login as an admin to {enable} kiosk mode.',
  },
  adminErrorHeader: {
    defaultMessage: 'Invalid account',
  },
  adminErrorMessage: {
    defaultMessage: 'This account does not have administrator privileges.',
  },
  enabledMessage: {
    defaultMessage: 'Kiosk mode is now {enabled}.',
  },
  deviceError: {
    defaultMessage: 'There was an error setting the ordering device',
  },
});

export default messages;
