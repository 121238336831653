import React, { ReactNode } from 'react';

import { Headline1 } from './styles';

type Props = {
  children?: ReactNode;
  className?: string;
  innerRef?: object;
};

const H1 = ({ children, className, innerRef }: Props) => (
  <Headline1 className={className} ref={innerRef}>
    {children}
  </Headline1>
);

export default H1;
