import styled from 'styled-components';

export const VenuePinContainer = styled.div`
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  transform: translate(-50%, -50%);
  display: block;
  z-index: ${({ selected, theme }) => selected && theme.zIndex.two};
`;

export const PinInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  color: ${({ theme }) => theme.color.darkGrey};
  padding: 10px 15px;
  border-radius: 5px;
  position: absolute;
  top: 40px;
  left: -150%;
  z-index: ${({ theme }) => theme.zIndex.two};
  text-align: left;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const PinInfo = styled.div`
  white-space: nowrap;
`;

export const PinInfoName = styled.h2`
  font-size: 16px;
`;

export const PinInfoClose = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;

  html[dir='rtl'] & {
    left: 2px;
    right: auto;
  }
`;
