import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from 'components/Form/fieldMessages';

import 'react-telephone-input/css/default.css';
import { StyledTelephone, StyledLabel } from './styles';
import allCountries from './allCountries.json';
import { getDefaultTelephoneLocale } from '../../../../selectors/order';

const TelephoneField = ({ value, defaultCountry, required, onChange, onBlur, hasError }) => {
  const excludedCountries = ['gg'];
  const preferredCountries = Array.from(new Set([defaultCountry, 'gb', 'us']));
  const includedCountries = allCountries.filter(country => !excludedCountries.includes(country.iso2));

  return (
    <>
      <StyledLabel asPlaceholder={false} required={required} hasError={hasError}>
        <FormattedMessage {...messages.telephone} />
      </StyledLabel>
      <StyledTelephone
        defaultCountry={defaultCountry}
        flagsImagePath="https://wi-q.cloud/client/assets/flags.png"
        onChange={onChange}
        autoFormat={false}
        preferredCountries={preferredCountries}
        value={value || undefined}
        onlyCountries={includedCountries}
        placeholder="Telephone"
        required={required}
        onBlur={onBlur}
      />
    </>
  );
};

TelephoneField.propTypes = {
  value: PropTypes.string,
  defaultCountry: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
};

const mapStateToProps = state => ({
  defaultCountry: getDefaultCountry(state),
});

export const getDefaultCountry = state => {
  const inferredCountryFromOrderServiceState = getDefaultTelephoneLocale(state);
  const [, country] = navigator.language?.split('-');

  const isoCode =
    inferredCountryFromOrderServiceState?.length > 3
      ? allCountries.find(c => c.name.toLowerCase().includes(inferredCountryFromOrderServiceState))?.iso2
      : null;

  const guess = allCountries.find(c => c.iso2 === country?.toLowerCase())?.iso2;

  return isoCode || inferredCountryFromOrderServiceState || guess || 'gb';
};

export default connect(mapStateToProps)(TelephoneField);
