import URL from 'url-parse';

type Options = {
  width?: number;
  height?: number;
  quality?: number;
};

export default (stringUrl: string, { width, height, quality = 80 }: Options = {}): string => {
  const url = new URL(stringUrl);
  if (url.hostname !== 'wi-q.cloud') return stringUrl;
  if (height || width) {
    return `${url.protocol}//${url.hostname}/i/${width || ''}x${height || ''},q${
      width && width > 350 ? 95 : quality
    }${url.pathname}`;
  }
  return `${url.protocol}//${url.hostname}${url.pathname}`;
};
