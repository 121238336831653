import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Buttons, Container, Content, HeaderMessage, Button } from './styles';

const ComoSplashPage = ({ setPage, loyaltyProgramme }) => (
  <Container>
    <HeaderMessage>
      <FormattedMessage
        defaultMessage="Our new Loyalty & Reward program is here"
        values={{ loyaltyProgramme }}
      />
    </HeaderMessage>
    <Content>
      <FormattedMessage defaultMessage="If you already signed up for Vapiano online ordering, this is now part of our new rewards program -  please join Vapiano Lovers so you can collect reward points on your order,  or if you’d prefer not to, please check out as a guest" />
    </Content>
    <Buttons>
      <Button
        label={<FormattedMessage defaultMessage="Create Account" />}
        onClick={() => setPage('signUp')}
      />
      <Button label={<FormattedMessage defaultMessage="Sign In" />} onClick={() => setPage('signIn')} />
    </Buttons>
  </Container>
);

ComoSplashPage.propTypes = {
  setPage: PropTypes.func,
  loyaltyProgramme: PropTypes.string,
};

export default ComoSplashPage;
