import React from 'react';

interface AuraLogoProps {
  className?: string;
  width?: string;
  height?: string;
  fullColor?: boolean;
}

const AuraLogo: React.FC<AuraLogoProps> = ({
  className,
  width = '100%',
  height = '100%',
  fullColor = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 388.51 162.36"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="37.21"
        y1="35.8"
        x2="128.3"
        y2="126.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e71e6e" />
        <stop offset="0.14" stopColor="#de1e6f" />
        <stop offset="0.37" stopColor="#c62073" />
        <stop offset="0.66" stopColor="#9e217a" />
        <stop offset="0.99" stopColor="#682483" />
        <stop offset="1" stopColor="#662483" />
      </linearGradient>
    </defs>
    <path
      d="M113.69,74.52l-.13-.22-.2.11h0l.17-.17-.1-.21h0a19,19,0,1,0-7.62,26.16,18.73,18.73,0,0,0,4.29-3.13,21.63,21.63,0,0,1-19.27,11.87,22.66,22.66,0,0,1-9.8-2.12c-.62-.33-1.23-.69-1.84-1.06a4,4,0,0,0-5.35,1.12h0a39.26,39.26,0,0,0,3.52,2.42l1.31.76h0A26.54,26.54,0,0,0,90,112.9h.87a26.77,26.77,0,0,0,11.44-2.74v0l.56-.31.13-.06.2-.12h0a26.49,26.49,0,0,0,11.37-33.26A19.74,19.74,0,0,0,113.69,74.52Zm-2.42,13.31a15,15,0,1,1-1.2-11.64c.12.22.22.44.33.65l.24.52c.08.17.15.34.22.5A14.91,14.91,0,0,1,111.27,87.83ZM112.68,46h0l.26-.23,1.85-1.51,1.68,2-1.66,1.42a.92.92,0,0,0-.1,1.29.93.93,0,0,0,1.28.1l1.58-1.35,1.79,2.14L114,54.42,112.2,52.3l1.08-.92a3.48,3.48,0,0,1-.6-5.35ZM82.6,15a66.16,66.16,0,1,0,66.15,66.16A66.24,66.24,0,0,0,82.6,15Zm0,128.44a62.28,62.28,0,1,1,62.28-62.28A62.28,62.28,0,0,1,82.6,143.46ZM67.67,73.77a26.44,26.44,0,0,1,2.79,18.44c-.13.64-.27,1.2-.42,1.71a25.9,25.9,0,0,1-2.2,5.42,25.18,25.18,0,0,1-2,3.21,26.24,26.24,0,0,1-3.4,3.75c-.6.55-1.22,1.07-1.84,1.54l-1,.74c-.44.3-.89.59-1.37.88h0l-.88.51v0a26.66,26.66,0,0,1-11.37,3L45,113a26.59,26.59,0,0,1-7.5-1.1,54.76,54.76,0,0,1-3.37-5.68,21.14,21.14,0,0,0,2.2,1.06,22.45,22.45,0,0,0,19.44-1,23.44,23.44,0,0,0,2-1.32,23,23,0,0,0,2-1.68l.06-.06a22.32,22.32,0,0,0,6.29-10.34,21.44,21.44,0,0,0,.69-3.39,22.23,22.23,0,0,0-1-9.8,6.29,6.29,0,0,0-.26-.7,4.32,4.32,0,0,1,1.87-5.39Zm53.77,36.73-.15.18a4.17,4.17,0,0,1-.79-5.63c.41-.58.79-1.15,1.06-1.59A43.26,43.26,0,0,0,118,54l-.15-.18,3-2.54.15.18a47.06,47.06,0,0,1,.43,59.05Zm-72.2-10a37.78,37.78,0,0,1-1.91-49.27l.15-.18a4.2,4.2,0,0,1,.86,5.61c-.18.26-.34.51-.47.73a33.82,33.82,0,0,0,4.73,40.9C52.06,99,50.07,100.48,49.24,100.48Z"
      fill={fullColor ? 'url(#linear-gradient)' : 'currentColor'}
    />
    <path
      d="M170,108.75,196.4,54h3.29l22.59,47-3.7,7.73L210.2,91.41H185.8L180,103.65a10.94,10.94,0,0,1-2.8,3.58,5.88,5.88,0,0,1-3.45,1.52ZM188.92,85h18.24L198,66.11Z"
      fill={fullColor ? '#1f274b' : 'currentColor'}
    />
    <path
      d="M269.36,88.22a22.57,22.57,0,0,1-1.73,8.63,18.59,18.59,0,0,1-11.25,10.51,27.49,27.49,0,0,1-17.38,0,19.4,19.4,0,0,1-6.86-4.17,19,19,0,0,1-4.48-6.67,23.6,23.6,0,0,1-1.6-8.92V54h7V87.89q0,7,4.07,10.63t10.64,3.6q6.57,0,10.64-3.6t4.07-10.63V62a7.87,7.87,0,0,1,.53-2.78,9.87,9.87,0,0,1,1.44-2.53,8,8,0,0,1,2.1-1.88,4.81,4.81,0,0,1,2.58-.74h.25Z"
      fill={fullColor ? '#1f274b' : 'currentColor'}
    />
    <path
      d="M293.28,86.23h-7.89v14.49a8,8,0,0,1-.53,2.81,10,10,0,0,1-1.44,2.57,8,8,0,0,1-2.09,1.9,4.79,4.79,0,0,1-2.59.75h-.33V54h15.36a24.38,24.38,0,0,1,7.77,1.16,17,17,0,0,1,5.95,3.31,14.51,14.51,0,0,1,3.82,5.26,17.2,17.2,0,0,1,1.36,7c0,4-1.05,7.18-3.16,9.6a16.48,16.48,0,0,1-8.67,5.05l18,23.35h-8.46Zm-7.89-6.46h8.22q5.91,0,8.91-2.07t3-7a9.64,9.64,0,0,0-3-7.57c-2-1.8-5-2.69-8.91-2.69h-8.22Z"
      fill={fullColor ? '#1f274b' : 'currentColor'}
    />
    <path
      d="M319.82,108.75,346.19,54h3.29l22.59,47-3.7,7.73L360,91.41h-24.4l-5.83,12.24a10.94,10.94,0,0,1-2.8,3.58,5.88,5.88,0,0,1-3.45,1.52ZM338.71,85H357l-9.12-18.89Z"
      fill={fullColor ? '#1f274b' : 'currentColor'}
    />
  </svg>
);

export default AuraLogo;
