import styled from 'styled-components';
import { H4 } from 'components/Typography';

export const NotificationContainer = styled.div`
  position: fixed;
  top: 23px;
  left: 50%;
  transform-origin: -100% 0;
  transform: rotate(0deg) translateX(-50%);
  width: 90%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.color.primaryBackground};
  z-index: ${({ theme }) => theme.zIndex.ten};
  color: ${({ theme }) => theme.color.text};
  box-shadow: 0 0 3px ${({ theme }) => theme.color.darkBoxBackgroundColor};
  padding: 10px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  ::before {
    position: absolute;
    display: inline-block;
    border-left: 10px solid transparent;
    border-bottom: 15px solid ${({ theme }) => theme.color.primaryBackground};
    border-right: 10px solid transparent;
    transform: rotate(0deg) translateX(-10px);
    left: 50%;
    top: -15px;
    content: '';
    filter: drop-shadow(-1px -2px 2px rgba(0, 0, 0, 0.2));
  }
`;

export const Icon = styled.div`
  float: left;
  margin-right: 10px;

  html[dir='rtl'] & {
    float: right;
    margin: 0 0 0 10px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const Header = styled(H4)`
  font-weight: 400;
  margin-bottom: 10px;
`;

export const CloseIconDiv = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  cursor: pointer;

  html[dir='rtl'] & {
    left: 5px;
    right: inherit;
  }
`;

export const MessageContainer = styled.div`
  overflow-y: scroll;
  max-height: 180px;
  padding-right: 10px;

  html[dir='rtl'] & {
    padding: 0;
  }

  @media (min-width: 440px) {
    & {
      max-height: 140px;
    }
  }
`;

export const Container = styled.div`
  button {
    margin: 10px 0;
  }
`;
