import React from 'react';
import PropTypes from 'prop-types';

import { MenuShapeStyled, MenuBarElement } from './styles';

const propTypes = {
  fillColorShape: PropTypes.bool,
  reShape: PropTypes.bool,
  customCssClass: PropTypes.string,
};

const MenuShape = ({ fillColorShape, reShape, customCssClass }) => (
  <MenuShapeStyled>
    <MenuBarElement fillColorShape={fillColorShape} reShape={reShape} className={customCssClass} />
    <MenuBarElement fillColorShape={fillColorShape} reShape={reShape} className={customCssClass} />
    <MenuBarElement fillColorShape={fillColorShape} reShape={reShape} className={customCssClass} />
  </MenuShapeStyled>
);

MenuShape.propTypes = propTypes;

export default MenuShape;
