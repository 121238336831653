import {
  ERROR_ORDER_ITEMS,
  ERROR_ORDER_VALIDATION,
  ERROR_ORDER_INVALID,
  EPOS_SITE_OFFLINE,
  EPOS_FULFILMENT_TIME_UNAVAILABLE,
  EPOS_ITEM_ERRORS,
  EPOS_ERROR,
  ERROR_OUTSIDE_RADIUS,
} from 'errorHandling/errorKeys';
import { selectErrorKey, selectViolations } from 'selectors/payment';
import { getFulfilmentFormFields } from 'reducers/fulfilmentReducer';
import globalMessages from 'components/globalMessages';
import {
  selectFulfilmentMethodId,
  isDeliveryToAddressEnabled,
  selectFulfilmentMethodTimeSlots,
  isLoadingFulfilmentMethodTimeSlots,
} from 'selectors/root';
import {
  isLoggedIn,
  selectAddressComponents,
  selectIsUserAddressInRange,
  selectAddressesList,
} from 'selectors/user';
import messages from './errorMessages';

export const getErrorMessageFromKey = (errorKey, formatMessage) => {
  if (!errorKey) return null;

  switch (errorKey) {
    case ERROR_ORDER_INVALID:
      return {
        errorHeader: formatMessage(messages.invalidOrder),
        errorMessage: formatMessage(messages.invalidOrderMessage),
      };
    case ERROR_ORDER_ITEMS:
      return {
        errorHeader: formatMessage(messages.unavailableItems),
        errorMessage: formatMessage(messages.unavailableItemsMessage),
      };
    case EPOS_SITE_OFFLINE:
      return {
        errorHeader: formatMessage(messages.siteOffline),
        errorMessage: formatMessage(messages.siteOfflineMessage),
      };
    case EPOS_FULFILMENT_TIME_UNAVAILABLE:
      return {
        errorHeader: formatMessage(messages.unvailableTime),
        errorMessage: formatMessage(messages.unvailableTimeMessage),
      };
    case EPOS_ITEM_ERRORS:
      return {
        errorHeader: formatMessage(messages.itemErrors),
        errorMessage: formatMessage(messages.itemErrorsMessage),
      };
    case EPOS_ERROR:
      return {
        errorHeader: formatMessage(globalMessages.error),
        errorMessage: formatMessage(messages.orderError),
      };
    case ERROR_OUTSIDE_RADIUS:
      return {
        errorHeader: formatMessage(globalMessages.error),
        errorMessage: formatMessage(globalMessages.deliveryAddressError),
      };
    case ERROR_ORDER_VALIDATION:
      return {
        errorHeader: formatMessage(messages.validationErrors),
        errorMessage: formatMessage(messages.validationErrorsMessage),
      };
    default:
      return null;
  }
};

export const isDeliveryToAddress = state => {
  const fulfilmentMethodId = selectFulfilmentMethodId(state);
  const deliveryToAddressEnabled = isDeliveryToAddressEnabled(state);
  return fulfilmentMethodId === 3 && deliveryToAddressEnabled;
};

export const isAddressOutOfRange = state => {
  if (isDeliveryToAddress(state)) {
    if (isLoggedIn(state)) {
      if (selectAddressesList(state)?.size === 0) return false;

      if (selectAddressesList(state)?.size) {
        const defaultAddress = selectAddressesList(state).find(addr => addr.get('default'));
        const latitude = defaultAddress?.get('latitude');
        const longitude = defaultAddress?.get('longitude');

        return !selectIsUserAddressInRange(state, latitude, longitude);
      }
    }

    return !selectIsUserAddressInRange(state);
  }

  return false;
};

export const addressHasMissingFields = state => {
  if (isLoggedIn(state)) return false;

  const addressComponents = selectAddressComponents(state);
  const hasMissingFields =
    addressComponents?.get('address1') === '' || addressComponents?.get('town_city') === '';
  return isDeliveryToAddress(state) && hasMissingFields;
};

export const hasAddressError = state => isAddressOutOfRange(state) || addressHasMissingFields(state);

export const getTimeslotsField = state =>
  getFulfilmentFormFields(state)?.find(field => field.name === 'fulfilmentDateTime');

export const noTimeslotsError = state => {
  const timeslotsField = getTimeslotsField(state);
  const timeslots = selectFulfilmentMethodTimeSlots(state);
  return !!timeslotsField && !isLoadingFulfilmentMethodTimeSlots(state) && !timeslots.size;
};

export const getCheckoutErrorMessage = (state, formatMessage) => {
  const errorHeader = formatMessage(globalMessages.error);
  const errorMessage = formatMessage(messages.errorMessage);
  const violations = selectViolations(state).toJS();

  if (noTimeslotsError(state)) {
    const timeslotsField = getTimeslotsField(state);
    const message = timeslotsField?.intlIdentifier === 'collection' ? 'noCollectionTimes' : 'noDeliveryTimes';
    return { errorHeader, errorMessage: formatMessage(messages[message]) };
  }

  if (
    isAddressOutOfRange(state) ||
    violations?.fulfilment?.find(error => error?.errorKey === ERROR_OUTSIDE_RADIUS)
  )
    return { errorHeader, errorMessage: formatMessage(globalMessages.deliveryAddressError) };

  if (addressHasMissingFields(state))
    return { errorHeader, errorMessage: formatMessage(globalMessages.missingAddressFields) };

  const errorKeyMessage = getErrorMessageFromKey(selectErrorKey(state), formatMessage);
  if (errorKeyMessage !== null) return errorKeyMessage;

  return { errorHeader, errorMessage };
};

export const getDineInErrorMessage = (state, formatMessage) => {
  const errorHeader = formatMessage(globalMessages.error);
  const errorMessage = formatMessage(messages.orderError);
  const violations = selectViolations(state).toJS();

  if (violations?.passcode) return { errorHeader, errorMessage: formatMessage(messages.passcodeError) };

  const errorKey = selectErrorKey(state);
  const errorKeyMessage = getErrorMessageFromKey(errorKey, formatMessage);
  if (errorKeyMessage !== null && errorKey !== ERROR_ORDER_VALIDATION) return errorKeyMessage;

  return { errorHeader, errorMessage };
};
