import styled, { css } from 'styled-components';
import { Field as FormikField } from 'formik';
import { StyledInput } from 'assets/styles/sharedStyles';

export const StyledField = styled(FormikField)`
  ${StyledInput}
`;

export const FormContent = styled.div`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }

  ${({ widthLimited }) =>
    widthLimited &&
    css`
      max-width: 450px;
      margin: 0 auto;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    `}
`;
