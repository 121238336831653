import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CallWaiterIcon = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon callWaiterIcon"
        fill={fill}
        d="M34,980v-67.5l50-51.6V834h583v26.9l50,51.6V980H34z M681,944v-16.9l-50-51.6V870H120v5.5l-50,51.6V944H681z"
      />
      <path
        className="icon callWaiterIcon"
        fill={fill}
        d="M91.2,801l15.3-63.2c2.6-66.2,29-129.4,74.4-177.9c42.6-45.6,99.2-75.4,160.5-84.8c-2-5.1-3.1-10.5-3.1-16.1 c0-14.5,6.5-27.7,17.7-36.1V406h-34v-37h124v37h-35v16.8c11.3,8.4,17.9,21.5,17.9,35.9c0,5.7-1,11.2-3,16.3 c61.3,9.4,117.9,39.2,160.6,84.9c45.4,48.6,71.8,111.7,74.4,177.9l15.3,63.2H91.2z M632.1,764.9l-3.8-15.2c-0.7-2.4-1.1-4.8-1.2-7.3 c-2.3-63.2-28.6-122.3-74.1-166.3c-45.7-44.2-105.8-68.5-169.3-68.5c-63.5,0-123.7,24.3-169.3,68.5 c-45.6,44.1-71.9,103.1-74.1,166.3c-0.1,2.5-0.5,4.9-1.2,7.3l-3.8,15.2L632.1,764.9z"
      />
      <polygon
        className="icon callWaiterIcon"
        fill={fill}
        points="827.6,59.2 997.1,23.1 997.1,70.5 877.4,94.7 914.1,313 997.1,298.9 997.1,346.2 878.9,364 "
      />
      <path
        className="icon callWaiterIcon"
        fill={fill}
        d="M462.7,353.7c-22.3,0-40.9-17.5-42.3-39.7l-0.5-7.8c-0.7-11.3,3-22.2,10.5-30.7c7-7.9,16.5-12.8,26.9-14l69.3-18.4 l-83.9-11l-220.6,94.2c-8.2,4.7-17.5,7.2-26.9,7.2c-18.9,0-36.2-9.6-46.1-25.7c-7.6-12.3-9.9-26.8-6.6-40.9 c3.3-14,11.9-25.9,24.1-33.4L398.1,84.8c6.3-3.8,14.9-9.1,118.1-9.1c23,0,41.2,0.3,42.4,0.3l262.5,0.8L859.5,291l-163.3,57.6 l-231.3,5C464.2,353.7,463.4,353.7,462.7,353.7z M631.9,216.6l25.5,33.2l-193.3,51.2l-2,0.1c-0.6,0-1.2,0.3-1.6,0.8 c-0.4,0.5-0.6,1.1-0.6,1.7l0.5,7.8c0.1,1.2,1.1,2.2,2.4,2.2l0.9,0l225.3-4.9l125.2-44.1l-26.5-147.9L558.1,116 c-2,0-19.6-0.3-41.9-0.3c-76.4,0-94.7,3.1-98.4,4l-230,147.7c-3.2,2-5.5,5.1-6.3,8.7c-0.9,3.7-0.3,7.4,1.7,10.6	c2.6,4.2,7.1,6.7,12,6.7c2.6,0,5.1-0.7,7.4-2.1l1.3-0.8L437.1,191L631.9,216.6z"
      />
    </g>
  </svg>
);

CallWaiterIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CallWaiterIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CallWaiterIcon;
