import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

import { setDetail } from 'actions/storage';
import { selectVenueName } from 'selectors/root';
import { selectUser } from 'selectors/user';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';

import { Container } from './styles';

const messages = defineMessages({
  header: {
    defaultMessage: 'Venue Confirmation',
  },
  message: {
    defaultMessage: '{ userName } are you ordering from { venueName }?',
  },
});

const ConfirmLocationAlert = ({ closeAlert, acceptConfirmLocation, venueName, user, organisationPath }) => (
  <div>
    <AlertContent>
      <AlertTitle>
        <FormattedMessage {...messages.header} />
      </AlertTitle>
      <Container>
        <p>
          <FormattedMessage
            {...messages.message}
            values={{
              userName: user.get('first_name') ? `${user.get('first_name')}, ` : '',
              venueName,
            }}
          />
        </p>
      </Container>
    </AlertContent>
    <AlertFooter>
      <AlertButtons>
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.no} />}
          onClick={() =>
            closeAlert(() =>
              document.location.replace(__config__.APP_BASE_URL.replace('{{route}}', organisationPath))
            )
          }
        />
        <ActionButton
          label={<FormattedMessage {...globalMessages.yes} />}
          onClick={() => closeAlert(acceptConfirmLocation(true))}
        />
      </AlertButtons>
    </AlertFooter>
  </div>
);

ConfirmLocationAlert.propTypes = {
  closeAlert: PropTypes.func,
  acceptConfirmLocation: PropTypes.func,
  venueName: PropTypes.string,
  organisationPath: PropTypes.string,
  user: PropTypes.instanceOf(Map),
};

const mapStateToProps = state => ({
  venueName: selectVenueName(state),
  user: selectUser(state),
  organisationPath: state.getIn(['venue', 'organisation', 'path']),
});

const mapDispatchToProps = dispatch => ({
  acceptConfirmLocation: value =>
    dispatch(setDetail('confirmLocation', value, { format: 'hours', length: 12 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmLocationAlert);
