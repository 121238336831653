import React from 'react';
import PropTypes from 'prop-types';

// Icon
const LupinIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon lupinIcon"
        fill={fill}
        opacity={opacity}
        d="M333.3,983C158.9,983,17,841.1,17,666.7c0-36.6,6.2-72.6,18.5-106.8c11.9-33.1,29.2-63.8,51.3-91.3	c44.4-55.1,106.5-94.2,174.9-110.1l5.7-1.3l-0.3-5.9c-2.2-38.6,2.9-78.4,14.6-114.8c15.2-47.5,42-91.4,77.5-126.9	C419,49.9,498.4,17,582.9,17c84.5,0,163.9,32.9,223.7,92.6l83.8,83.8c59.7,59.7,92.6,139.2,92.6,223.7c0,84.5-32.9,163.9-92.6,223.7	c-34.3,34.3-76.7,60.6-122.5,76.1l-3.9,1.3l-0.7,4.1c-12.8,72.2-50.9,138.2-107.3,185.9c-57,48.3-129.5,74.9-204.1,74.9H333.3z	 M211.7,426c-43.8,22.2-80.8,56-107,97.7c-26.9,42.8-41.1,92.3-41.1,143c0,148.7,121,269.7,269.7,269.7h118.5	c71.9,0,139.6-28.1,190.6-79.1s79.1-118.7,79.1-190.6c0-51.8-14.8-102.1-42.7-145.5c-27.2-42.3-65.5-76.1-110.7-97.8l-5.1-2.4	l-3.5,4.4c-41.1,52.8-103.3,83-170.6,83c-65.8,0-127.2-29.4-168.5-80.5l-3.6-4.4L211.7,426z M451.8,350.4	c82.6,0,160.8,31.8,220.2,89.5c59.3,57.6,93.3,134.6,95.9,216.8l0.3,10.5l9.6-4.3c29.7-13.5,56.5-32,79.6-55.1	c50.9-50.9,79-118.7,79-190.7c0-30.9-5.1-61.1-15.3-89.9l-1.9-5.3l-5.6,0.7c-9,1.1-18.1,1.7-26.9,1.7c-57.4,0-111.5-22.6-152.5-63.6	c-46.5-46.5-69.2-110.8-62.2-176.3l0.6-5.6l-5.4-1.8c-27.1-8.9-55.4-13.4-84.2-13.4c-72,0-139.8,28.1-190.7,79	c-54,54-82,125.5-78.8,201.4l0.3,7l7-0.3c4.7-0.2,8.8-0.3,12.6-0.3H451.8z M333.3,397c-19.5,0-39,2.1-58,6.3l-12.9,2.8l9.6,9.1	c31.7,30,73.2,46.6,116.9,46.6c44.7,0,86.7-17,118.4-47.9l9.6-9.3l-13.1-2.6c-17.1-3.3-34.5-5-51.9-5H333.3z M717.4,112	c1.2,43.7,18.8,84.8,49.7,115.7c31.6,31.6,73.4,49.3,117.6,49.9l13.4,0.2l-7.5-11.1c-9.7-14.4-20.9-27.9-33.2-40.3l-83.8-83.8	c-13.8-13.8-29.1-26.2-45.5-36.6L717,98.8L717.4,112z"
      />
    </g>
  </svg>
);

LupinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

LupinIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default LupinIcon;
