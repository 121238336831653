import styled from 'styled-components';
import { H4 } from 'components/Typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  background: ${({ theme }) => theme.color.inputBackground};
`;

export const Title = styled(H4)`
  color: ${({ theme }) => theme.color.primaryText};
  font-weight: normal;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.primaryText};
  padding: ${({ theme }) => (theme.dir === 'rtl' ? '0 0 0 10px' : '0 10px 0 0')};
`;

export const QuantityButtonsContainer = styled.div`
  min-width: 140px;
`;
