import React from 'react';
import PropTypes from 'prop-types';

const ChampagneIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 69 85"
  >
    <g>
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M3.39097 77.3413C2.31014 77.1135 1.2493 77.8051 1.02151 78.8859C0.793716 79.9667 1.48524 81.0276 2.56606 81.2553L3.39097 77.3413ZM16.2651 84.1425C17.346 84.3703 18.4068 83.6788 18.6346 82.598C18.8624 81.5171 18.1709 80.4563 17.09 80.2285L16.2651 84.1425ZM2.56606 81.2553L16.2651 84.1425L17.09 80.2285L3.39097 77.3413L2.56606 81.2553Z"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M30.0817 18.9875L32.0677 19.2233L30.0817 18.9875ZM16.9621 17.3506L28.8837 19.8632L29.7086 15.9492L17.787 13.4366L16.9621 17.3506ZM28.0956 18.7517C27.269 25.7144 25.569 35.5869 23.0589 43.4506C21.797 47.4041 20.3778 50.6945 18.8556 52.85C17.2787 55.0828 16.0692 55.4862 15.1891 55.3008L14.3642 59.2148C17.6985 59.9175 20.3006 57.7379 22.123 55.1574C23.9999 52.4996 25.5674 48.7464 26.8695 44.667C29.4876 36.4648 31.2259 26.314 32.0677 19.2233L28.0956 18.7517ZM15.1891 55.3008C14.3091 55.1153 13.3652 54.2581 12.8237 51.5788C12.3009 48.9923 12.3305 45.4091 12.7715 41.2825C13.6488 33.0747 16.078 23.3558 18.1317 16.6517L14.3071 15.4801C12.2157 22.3075 9.70916 32.2963 8.79415 40.8574C8.33907 45.1154 8.25835 49.1819 8.90295 52.3712C9.52878 55.4676 11.0299 58.5121 14.3642 59.2148L15.1891 55.3008ZM28.8837 19.8632C28.4035 19.762 28.0308 19.2972 28.0956 18.7517L32.0677 19.2233C32.2539 17.6548 31.1969 16.2628 29.7086 15.9492L28.8837 19.8632ZM17.787 13.4366C16.2987 13.1229 14.7698 13.9699 14.3071 15.4801L18.1317 16.6517C17.9708 17.1769 17.4423 17.4518 16.9621 17.3506L17.787 13.4366Z"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M12.0023 31.6343C13.53 32.4672 17.2963 33.6698 20.1397 31.8163C22.983 29.9628 26.6806 31.4916 28.1739 32.4876"
        stroke={fill}
        strokeWidth="3"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M8.18069 78.8615C7.9529 79.9423 8.64442 81.0031 9.72524 81.2309C10.8061 81.4587 11.8669 80.7672 12.0947 79.6864L8.18069 78.8615ZM16.6317 58.1593C16.8595 57.0785 16.1679 56.0176 15.0871 55.7898C14.0063 55.562 12.9455 56.2536 12.7177 57.3344L16.6317 58.1593ZM12.0947 79.6864L16.6317 58.1593L12.7177 57.3344L8.18069 78.8615L12.0947 79.6864Z"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M51.9709 78.246C50.8976 78.507 50.239 79.5886 50.5 80.6619C50.7609 81.7352 51.8425 82.3938 52.9158 82.1329L51.9709 78.246ZM66.5196 78.8259C67.5929 78.565 68.2515 77.4834 67.9906 76.4101C67.7297 75.3368 66.6481 74.6782 65.5748 74.9391L66.5196 78.8259ZM52.9158 82.1329L66.5196 78.8259L65.5748 74.9391L51.9709 78.246L52.9158 82.1329Z"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M50.8675 14.0871L52.7609 13.4428L50.8675 14.0871ZM38.326 18.2719L50.1647 15.394L49.2199 11.5072L37.3811 14.385L38.326 18.2719ZM48.9741 14.7314C51.233 21.3692 53.9597 31.0088 55.0887 39.1858C55.6563 43.2969 55.7959 46.8775 55.3528 49.4788C54.8939 52.1735 53.9768 53.0594 53.1028 53.2718L54.0477 57.1587C57.3588 56.3538 58.7656 53.2646 59.296 50.1504C59.8424 46.9428 59.6368 42.8807 59.0511 38.6387C57.8735 30.1098 55.0613 20.2026 52.7609 13.4428L48.9741 14.7314ZM53.1028 53.2718C52.2289 53.4843 51.0075 53.1183 49.3629 50.935C47.7752 48.8273 46.2556 45.5821 44.8728 41.6692C42.1223 33.8863 40.1198 24.0707 39.0795 17.1367L35.1238 17.7301C36.1832 24.7916 38.2325 34.8842 41.1013 43.002C42.5282 47.0395 44.2102 50.7428 46.1679 53.3417C48.0686 55.8649 50.7365 57.9635 54.0477 57.1587L53.1028 53.2718ZM50.1647 15.394C49.6879 15.5099 49.1511 15.2514 48.9741 14.7314L52.7609 13.4428C52.252 11.9475 50.6978 11.1479 49.2199 11.5072L50.1647 15.394ZM37.3811 14.385C35.9032 14.7443 34.8894 16.1681 35.1238 17.7301L39.0795 17.1367C39.161 17.6799 38.8028 18.1559 38.326 18.2719L37.3811 14.385Z"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M40.0151 33.2979C41.7527 33.39 45.6692 32.8496 47.4344 29.9507C49.1996 27.0517 53.1948 26.8352 54.9718 27.0893"
        stroke={fill}
        strokeWidth="3"
      />
      <path
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        d="M56.9472 77.551C57.2081 78.6243 58.2897 79.2829 59.363 79.022C60.4364 78.7611 61.0949 77.6795 60.834 76.6062L56.9472 77.551ZM55.6375 55.2287C55.3765 54.1554 54.2949 53.4968 53.2216 53.7578C52.1483 54.0187 51.4897 55.1003 51.7506 56.1736L55.6375 55.2287ZM60.834 76.6062L55.6375 55.2287L51.7506 56.1736L56.9472 77.551L60.834 76.6062Z"
      />
      <line
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        x1="23.2475"
        y1="2.39085"
        x2="26.9823"
        y2="8.31128"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        x1="1"
        y1="-1"
        x2="8"
        y2="-1"
        transform="matrix(-0.533539 0.845775 0.845775 0.533539 43.8018 2)"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        className="icon champagneIcon"
        fill={fill}
        opacity={opacity}
        x1="33"
        y1="8"
        x2="33"
        y2="1"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

ChampagneIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

ChampagneIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default ChampagneIcon;
