import React from 'react';
import PropTypes from 'prop-types';

const RemoveFavouriteIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon removeFavouriteIcon"
        fill={fill}
        d="M202.3,722.2c-35.8-40.7-67-80.4-92.6-118c-29.9-44-51.2-83.7-63.1-118c-17.6-50.4-33.8-126.3-11.4-201.1	c18.4-61.7,60.5-116.6,115.4-150.6c40.2-24.9,86.2-38.1,133-38.1c82.7,0,161.2,39.9,212.6,107.4c49.6-67,129.8-108,212.7-108	c33.4,0,65.7,6.6,96,19.5l13.6,5.8l-52,52l-5.3-1.2c-7.9-1.7-29.8-6.4-36.1-7.1c-6.1-0.7-12.3-1-18.4-1	c-47.5,0-94.7,19.8-132.8,55.8c-18.7,17.6-32.5,36.4-41.1,55.8c-4.9,11.1-15.1,34.1-36.8,34.4l-0.5,0c-20.6,0-30.4-21.9-35.7-33.7	c-0.5-1.2-1.1-2.4-1.5-3.4c-4.6-9.9-10.7-19.6-18.7-29.9c-12.8-16.6-28.3-31.3-46.1-43.7c-31.8-22.3-69-34.1-107.7-34.1	c-28.6,0-57.3,6.6-83.2,19.1c-27,13-50,31.8-68.4,55.6c-75.1,97.3-28.4,221.9,6.1,287.6c41.1,78.2,109.2,147.3,109.9,148l7,7.1	l-47.8,47.8L202.3,722.2z"
      />
      <path
        className="icon removeFavouriteIcon"
        fill={fill}
        d="M495.5,959.8c-6,0-11.8-1.2-17.2-3.6c-25-11.1-48.4-27.9-71-44.1c-5.7-4.1-11.1-8-16.5-11.7	c-34.6-24.1-65.6-47.6-94.7-71.8l-8.5-7l45.8-45.8l6.9,5.3c0.8,0.6,83.7,63.4,149.5,102.1c1.6,0.9,2.9,1.7,4,2.4	c0.7,0.4,1.4,0.9,1.9,1.2c1.1-0.4,2.7-1,3.3-1.2c9.9-3.9,20.5-11.6,30.7-19.1c4.6-3.4,8.9-6.5,13.2-9.4	c38.7-25.8,72.6-50.8,103.7-76.2c74.3-60.7,130.9-119.8,173.1-180.6c57.9-83.6,84.5-162.6,79-234.8c-4.9-63.7-38-103.3-38.4-103.7	l-6-7l48.6-48.6l7,8.8c55.7,69.9,71.5,163.1,44.5,262.5c-36.1,132.6-133.5,257.9-297.9,383c-34.6,26.4-67.1,49.2-99.4,69.8	c-3.2,2.1-6.7,4.5-10.4,7.1C531.8,947.8,514.6,959.8,495.5,959.8C495.5,959.8,495.5,959.8,495.5,959.8z"
      />
      <rect
        className="icon removeFavouriteIcon"
        fill={fill}
        x="-31.4"
        y="454"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -184.4098 523.6835)"
        width="1142.8"
        height="60.9"
      />
    </g>
  </svg>
);

RemoveFavouriteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

RemoveFavouriteIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default RemoveFavouriteIcon;
