import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import CloseIcon from 'components/Icons/CloseIcon';
import addCssPrefixTo from 'utils/addCssPrefixTo';

import {
  Container,
  CloseIconBtn,
  CompanyLogoImg,
  TextContainer,
  ChromeDotsIconStyled,
  SafariShareIconStyled,
} from './styles';

const messages = defineMessages({
  instructions: {
    defaultMessage: 'To add { companyName } to your home screen: tap { icon } and then { label }.',
  },
  label: {
    defaultMessage: 'Add to Home Screen',
  },
});

type Props = {
  companyName: string;
  logoUrl: string;
  browserName: string;
  onClose: () => void;
};

const AddToHomeScreenPopUp = ({ companyName, logoUrl, browserName, onClose }: Props) => {
  const AddToHomeScreenIcon = browserName === 'chrome' ? ChromeDotsIconStyled : SafariShareIconStyled;

  return (
    <Container>
      <CloseIconBtn onClick={onClose}>
        <CloseIcon width="24px" height="24px" />
      </CloseIconBtn>

      <CompanyLogoImg src={logoUrl} className={addCssPrefixTo('COMPANY_LOGO')} alt="Logo" />

      <TextContainer>
        <FormattedMessage
          {...messages.instructions}
          values={{
            companyName,
            icon: <AddToHomeScreenIcon alt="Homescreen Icon" width="24px" height="24px" />,
            label: (
              <strong>
                <FormattedMessage {...messages.label} />
              </strong>
            ),
          }}
        />
      </TextContainer>
    </Container>
  );
};

export default AddToHomeScreenPopUp;
