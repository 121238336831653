import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';
import StandardAlert from 'components/Alert/StandardAlert';
import { YesIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';
import dineInMessages from 'components/dineInMessages';

const propTypes = {
  name: PropTypes.string,
  theme: PropTypes.object,
};

const CardConfirmedAlert = props => {
  const { theme } = props;

  return (
    <StandardAlert
      {...props}
      Icon={YesIcon}
      IconFill={theme.color.success}
      config={{ buttons: 1 }}
      messages={{ header: dineInMessages.cardAdded, confirm: globalMessages.close }}
    />
  );
};

CardConfirmedAlert.propTypes = propTypes;

export default withTheme(CardConfirmedAlert);
