import fetch from 'isomorphic-fetch';

export default details =>
  fetch('/api/users/me', {
    method: 'PUT',
    body: JSON.stringify(details),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
    .then(response => {
      if (response.status === 403) throw new Error('forbidden');
      return response;
    })
    .then(response => response.json())
    .then(json => {
      if (json.error) throw new Error('failure');
      return json;
    });
