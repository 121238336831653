import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Container, Label, HelpMessage } from 'assets/styles/sharedStyles';
import { StyledField } from './styles';
import { hasValue, getFieldLabel } from './utils';

const propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  field: PropTypes.object,
  value: PropTypes.any,
  error: PropTypes.string,
  status: PropTypes.array,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  venueId: PropTypes.number,
  getFieldLabel: PropTypes.func,
};

const Field = ({
  intl,
  field,
  value,
  error,
  status,
  touched,
  onBlur,
  onChange,
  showLabel,
  component,
  isSubmitting,
  getFieldLabel,
}) => {
  if (field.hidden) return null;

  let label = '';
  if (showLabel) {
    label = getFieldLabel(intl.formatMessage);
  }
  if (field.component) field.formSubmitting = isSubmitting;
  const componentProps = field.component ? field : {};

  const showError = () => touched || hasValue(value);
  const showClientError = () => error && showError();
  const showServerError = () => !showClientError() && status && showError();
  const hasError = () => showClientError() || showServerError();

  return (
    <Container className={hasError() ? 'has-error' : ''} key={field.name}>
      {showLabel && (
        <Label htmlFor={field.name} asPlaceholder={!value} required={field.required} haserror={hasError()}>
          {label}
        </Label>
      )}
      <StyledField
        {...componentProps}
        required={field.required}
        name={field.name}
        type={field.type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        component={component}
        haserror={hasError() && String(hasError())}
      />
      {field.helpMessage && <HelpMessage>{field.helpMessage}</HelpMessage>}
      {showClientError() && <div className="text-danger">{error}</div>}
      {showServerError() && (
        <div className="text-danger">
          {status.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </div>
      )}
    </Container>
  );
};

Field.propTypes = propTypes;

const mapStateToProps = (state, { field }) => ({
  getFieldLabel: formatMessage => getFieldLabel(state, field, formatMessage),
});

export default connect(mapStateToProps)(injectIntl(Field));
