import { fromJS } from 'immutable';

import {
  REQUEST_DONATIONS,
  REQUEST_DONATIONS_SUCCESS,
  REQUEST_DONATIONS_FAILURE,
} from 'actions/donations/constants';

export const initialState = fromJS({
  loading: false,
  donationsList: [],
  hasFetched: false,
  error: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DONATIONS:
      return state.merge(
        fromJS({
          loading: true,
          donationsList: [],
          hasFetched: false,
          error: false,
        })
      );

    case REQUEST_DONATIONS_SUCCESS:
      return state.merge(
        fromJS({
          loading: false,
          donationsList: action.donationsList,
          hasFetched: true,
          error: false,
        })
      );

    case REQUEST_DONATIONS_FAILURE:
      return state.merge(
        fromJS({
          loading: false,
          donationsList: [],
          hasFetched: false,
          error: true,
        })
      );

    default:
      return state;
  }
};
