import React from 'react';
import PropTypes from 'prop-types';

import { Address } from './styles';

const ServiceAddress = ({ service }) => (
  <Address>
    {service.address1 && service.address1}
    {service.address2 && `, ${service.address2}`}
    {service.town_city && `, ${service.town_city}`}
    {service.county && `, ${service.county}`}
  </Address>
);

ServiceAddress.propTypes = {
  service: PropTypes.object,
};

export default ServiceAddress;
