import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { browserHistory } from 'react-router';

import Page from 'components/Pages/container';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  isDineInOrderingFlow,
  selectDineInLocationDetail,
  selectDineInSessionStorage,
  selectDineInFulfilmentType,
  getServiceById,
} from 'selectors/root';
import { isInitialized } from 'selectors/storage';
import { selectSessionId } from 'selectors/session';
import MenuSearch from 'components/MenuSearch';
import MenuSectionCarousel from 'components/MenuSectionCarousel';
import EstimatedWaitMessage from 'components/EstimatedWaitMessage';
import {
  getBackButton,
  selectBrowseInfoTitle,
  selectBrowseInfoDescription,
  selectBrowseInfoImagePath,
  selectMenuTypeId,
  selectMenusByType,
  getMenuById,
  selectSections,
} from 'selectors/browse';
import globalMessages from 'components/globalMessages';
import { setLastMenuPage } from 'actions/UI';
import { setBrowseBackButton } from 'actions/browse';
import { createSession, fetchSession } from 'actions/session';
import { useScrollPositions, usePrevious } from 'hooks';

import { addCssPrefixTo } from 'utils';
import { TransitionContainer, Slide, StyledTitle, StyledDescription } from './styles';

const propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }),
  setLastMenuPage: PropTypes.func,
  setBrowseBackButton: PropTypes.func,
  isDineInFlow: PropTypes.bool,
  storageSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStorageInitialized: PropTypes.bool,
  dineInFulfilment: PropTypes.instanceOf(Map),
  dineInLocation: PropTypes.string,
  createSession: PropTypes.func,
  fetchSession: PropTypes.func,
  subHeaderTitle: PropTypes.string,
  menuDescription: PropTypes.string,
  imagePath: PropTypes.string,
  backButton: PropTypes.object,
  menus: PropTypes.instanceOf(Map),
  sections: PropTypes.instanceOf(List),
  children: PropTypes.node,
};

const Browse = ({
  location,
  params,
  intl,
  setLastMenuPage,
  setBrowseBackButton,
  isDineInFlow,
  isStorageInitialized,
  dineInFulfilment,
  storageSessionId,
  orderSessionId,
  dineInLocation,
  createSession,
  fetchSession,
  subHeaderTitle,
  menuDescription,
  imagePath,
  backButton,
  menus,
  sections,
  children,
}) => {
  const defaultMessage = params?.productId ? '' : intl.formatMessage(globalMessages.menus);
  const cleanPathname = location.pathname.replace(/\//g, '');
  const [positionsObj] = useScrollPositions(cleanPathname);
  const [prevPathname] = usePrevious(cleanPathname);

  const [title, setTitle] = useState(defaultMessage);
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!isDineInFlow || !isStorageInitialized || !!orderSessionId || !dineInFulfilment.size) return;
    if (!storageSessionId) {
      if (!dineInLocation) browserHistory.replace(`/service/${params.serviceId}/location`);
      else createSession();
    } else {
      fetchSession();
    }
  }, [
    isDineInFlow,
    isStorageInitialized,
    storageSessionId,
    orderSessionId,
    dineInFulfilment,
    dineInLocation,
    params,
    createSession,
    fetchSession,
  ]);

  useEffect(() => {
    const { action, state, pathname } = location;

    setTitle(subHeaderTitle || defaultMessage);
    setImage(imagePath);
    setDescription(menuDescription);

    setLastMenuPage(pathname);
    setBrowseBackButton(params?.serviceId, params?.menuId, params?.sectionId, params?.productId);

    if (action === 'POP' || state?.transition === 'Back') {
      const scrollPos = positionsObj[prevPathname];
      if (scrollPos) {
        window.scrollTo(0, scrollPos);
      }
    }
  }, [
    setTitle,
    subHeaderTitle,
    defaultMessage,
    setImage,
    imagePath,
    setDescription,
    menuDescription,
    params,
    location,
    positionsObj,
    prevPathname,
    setLastMenuPage,
    setBrowseBackButton,
  ]);

  return (
    <Page intlTitle={title} headerOnlyTitle={true} image={image} backButton={backButton}>
      {!params?.productId && (
        <>
          <MenuSearch serviceId={params?.serviceId} />
          <MenuSectionCarousel
            menuList={menus?.toList()}
            sectionList={sections}
            serviceId={params?.serviceId}
            menuId={params?.menuId}
            sectionId={params?.sectionId}
          />
          <StyledTitle className={addCssPrefixTo('BROWSE_TITLE')}>{title}</StyledTitle>
          {description && <StyledDescription>{description}</StyledDescription>}
          <EstimatedWaitMessage />
        </>
      )}

      <ErrorBoundary errorMessage="Sorry, this menu could not be displayed. Please try again later.">
        <TransitionContainer>
          <Slide direction={location?.state?.transition}>{children}</Slide>
        </TransitionContainer>
      </ErrorBoundary>
    </Page>
  );
};

Browse.propTypes = propTypes;

const IntlBrowse = injectIntl(Browse);

export default connect(
  (state, { params }) => ({
    isDineInFlow: isDineInOrderingFlow(state, params?.serviceId),
    dineInLocation: selectDineInLocationDetail(state),
    storageSessionId: selectDineInSessionStorage(state),
    orderSessionId: selectSessionId(state),
    isStorageInitialized: isInitialized(state),
    dineInFulfilment: selectDineInFulfilmentType(state),
    backButton: getBackButton(state),
    subHeaderTitle: selectBrowseInfoTitle(state),
    menuDescription: selectBrowseInfoDescription(state),
    imagePath: selectBrowseInfoImagePath(state),
    menus: selectMenusByType(state, selectMenuTypeId(state), params?.serviceId),
    sections: params?.menuId
      ? selectSections(state, getMenuById(state, params.menuId), getServiceById(state, params?.serviceId))
      : new List(),
  }),
  {
    createSession,
    fetchSession,
    setLastMenuPage,
    setBrowseBackButton,
  }
)(IntlBrowse);
