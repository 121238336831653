import styled from 'styled-components';
import { H1 } from 'components/Typography';

export const HeaderStyled = styled.div`
  position: relative;
`;

export const HeaderContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.color.primaryBackground};
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  padding: 18px 10px 0;
`;

export const StyledImage = styled.div`
  background: no-repeat center;
  background-size: cover;
  height: ${({ tall }) => (tall ? '260px' : '140px')};
  margin: -18px -10px 20px;

  @media (min-width: 768px) {
    height: 180px;
  }

  @media (min-width: 1440px) {
    height: 240px;
  }
`;

export const HeaderTitle = styled(H1)`
  font-weight: ${({ settingsPage }) => (settingsPage ? 'normal' : 'bold')};
  margin: 0 auto;
  padding: 0 35px;
  color: ${({ theme }) => theme.color.primaryText};
`;

export const ArrowStyled = styled.div`
  position: absolute;
  top: 99.5%;
  left: 0;
  right: 0;

  > svg {
    height: 40px;
  }
`;
