import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectServiceId } from 'selectors/browse';
import { selectLoyaltyTypeByService } from 'selectors/loyalty';
import AuraModal from './Aura';
import ComoModal from './Como';

const propTypes = {
  type: PropTypes.oneOf(['aura', 'como']),
};

const LoyaltyModal = ({ type, ...props }) => {
  let Modal;

  switch (type) {
    case 'aura':
      Modal = AuraModal;
      break;
    case 'como':
      Modal = ComoModal;
      break;
    default:
      return null;
  }

  return <Modal {...props} />;
};

LoyaltyModal.propTypes = propTypes;

export default connect(state => {
  const serviceId = selectServiceId(state);

  return {
    type: selectLoyaltyTypeByService(state, serviceId),
  };
})(LoyaltyModal);
