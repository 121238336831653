import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { AlertHeader, AlertContent } from 'assets/styles/sharedStyles';
import { H4 } from 'components/Typography';

export const ModalBackdrop = styled.div`
  background-color: ${({ theme }) => theme.color.modalBackdrop};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 250ms;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.eleven};

  ${props =>
    props.fade &&
    css`
      background-color: transparent;
      height: 0;
      transition: background-color 0.4s ease-out, height 0s ease 0.3s;
    `}
`;

export const Alertbox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: -100% 0;
  transform: rotate(0deg) translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 965px;
  max-height: 90%;
  background-color: ${({ theme }) => theme.color.primaryBackground};
  z-index: ${({ theme }) => theme.zIndex.six};
  text-align: center;
  color: #666;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}

  ${({ slim }) =>
    slim &&
    css`
      width: max(100%, 300px);
    `}

  html[dir='rtl'] & {
    direction: rtl;
  }

  ${props =>
    props.hide &&
    !props.fullScreen &&
    css`
      display: none;
    `}

  ${props =>
    props.fullScreen &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      max-width: inherit;
      transform: none;
    `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 10px;
  outline: 0;
  overflow: hidden;

  ${props =>
    props.fullScreen &&
    css`
      ${AlertHeader}, ${AlertContent} {
        margin-left: 66px;
        border-left: 1px solid ${({ theme }) => theme.color.borderColor};
        border-radius: 0;

        html[dir='rtl'] & {
          margin-left: 0;
          margin-right: 56px;
          border-left: none;
          border-right: 1px solid ${({ theme }) => theme.color.borderColor};
        }
      }
    `}
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.primaryBackground};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: ${({ theme }) => theme.zIndex.eight};
  border: 2px solid #666;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  html[dir='rtl'] & {
    right: auto;
    left: -10px;
  }
`;

export const StyledBackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  color: ${({ theme }) => theme.color.iconFill};
  z-index: ${({ theme }) => theme.zIndex.eight};

  html[dir='rtl'] & {
    left: auto;
    right: 0;
    transform: scale(-1, 1);
  }
`;

export const ContentContainer = styled.div`
  padding-bottom: 20px;
`;

export const MessageContainer = styled.span`
  margin: 0 5px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const RadiusContainer = styled.div`
  min-height: 480px;
  margin-top: 10px;
`;

export const DeliveryPartnersContainer = styled.div`
  color: ${({ theme }) => theme.color.success};
`;

export const DeliveryLink = styled.a`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => transparentize(0.6, theme.color.success)};
`;

export const DeliveryName = styled(H4)`
  padding: 0 15px;
  display: inline-block;
`;
