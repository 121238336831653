import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import { usePrevious } from 'hooks';
import PageTitle from 'components/PageTitle';
import { setDetail } from 'actions/storage';
import { kioskModeEnabled, showExitButton } from 'selectors/features';
import { clearLastMenuPage, setIsSettingsPage, openLoginRegisterAlert, openKioskModeAlert } from 'actions/UI';
import KioskExitButton from 'components/KioskExitButton';
import { StyledMenuButton } from 'components/MenuButton';

import { selectLogoUrl, selectVenueName, selectOrganisationName, selectIsOrganisation } from 'selectors/root';
import { setBackButton } from 'actions/browse';
import globalMessages from 'components/globalMessages';
import image from 'utils/image';
import addCssPrefixTo from 'utils/addCssPrefixTo';
import withCookies from 'components/withCookies';

import { CompanyLogo } from './styles';

const messages = defineMessages({
  mobileOrdering: {
    defaultMessage: '{companyName} Mobile Ordering',
  },
});

const propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object,
    hash: PropTypes.string,
    pathname: PropTypes.string,
  }),
  kioskMode: PropTypes.bool,
  openLoginRegisterAlert: PropTypes.func,
  openKioskModeAlert: PropTypes.func,
  clearLastMenuPage: PropTypes.func,
  setIsSettingsPage: PropTypes.func,
  setDetail: PropTypes.func,
  companyName: PropTypes.string,
  setCookie: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  setBackButton: PropTypes.func,
  style: PropTypes.object,
  logoUrl: PropTypes.string,
  showExitButton: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  logoUrl: '/company-logo.png',
};

const HomePage = ({
  location,
  kioskMode,
  openLoginRegisterAlert,
  openKioskModeAlert,
  clearLastMenuPage,
  setIsSettingsPage,
  setDetail,
  companyName,
  setCookie,
  intl,
  setBackButton,
  style,
  logoUrl,
  showExitButton,
  children,
}) => {
  const [prevLocationQuery] = usePrevious(location?.query);
  const [prevLocationPathname] = usePrevious(location?.pathname);

  useEffect(() => {
    if (prevLocationPathname !== location.pathname) {
      const setBackLink = location => {
        const { formatMessage } = intl;
        const text =
          location?.pathname === '/'
            ? formatMessage(globalMessages.home)
            : formatMessage(globalMessages.back);
        setBackButton(text, location?.pathname);
      };

      if (location?.hash === '#register' && !kioskMode) {
        openLoginRegisterAlert('REGISTER_FORM');
      }

      if (location?.pathname === '/kiosk-mode') {
        openKioskModeAlert();
      }

      setBackLink(location);

      clearLastMenuPage();

      setIsSettingsPage(false);
    }

    if (location?.query?.utm_source === 'web_app_manifest') {
      setDetail('added', companyName);
    }
  }, [
    intl,
    location,
    setBackButton,
    kioskMode,
    openLoginRegisterAlert,
    openKioskModeAlert,
    clearLastMenuPage,
    setIsSettingsPage,
    setDetail,
    companyName,
    prevLocationQuery,
    setCookie,
    prevLocationPathname,
  ]);

  return (
    <div className="fullWrapper clientBackground splashBackground" style={style}>
      <PageTitle message={messages.mobileOrdering} messageValues={{ companyName }} />
      {showExitButton && <KioskExitButton withBackground={true} />}
      {!kioskMode && <StyledMenuButton customCssClass="menuBarColor" />}
      <CompanyLogo
        className={`u-displayBlock ${addCssPrefixTo('COMPANY_LOGO')}`}
        alt="Logo"
        src={image(logoUrl, { width: 400 })}
      />
      {children}
    </div>
  );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default compose(
  connect(
    state => ({
      companyName: selectIsOrganisation(state) ? selectOrganisationName(state) : selectVenueName(state),
      logoUrl: selectLogoUrl(state),
      kioskMode: kioskModeEnabled(state),
      showExitButton: showExitButton(state),
    }),
    dispatch => ({
      clearLastMenuPage: () => dispatch(clearLastMenuPage()),
      setIsSettingsPage: value => dispatch(setIsSettingsPage(value)),
      setDetail: (value, companyName, expiry) =>
        dispatch(setDetail(`addToHomeScreen.${companyName}`, value, expiry)),
      setBackButton: (text, href) => dispatch(setBackButton(text, href)),
      openLoginRegisterAlert: section => dispatch(openLoginRegisterAlert(section)),
      openKioskModeAlert: () => dispatch(openKioskModeAlert()),
    })
  ),
  injectIntl
)(withCookies(HomePage));
