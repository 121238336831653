import React from 'react';
import PropTypes from 'prop-types';

import { hasNutritionalInformation } from 'selectors/nutritionalInformation';
import { productIsFoodOrBeverage } from 'selectors/browse';
import { addCssPrefixTo } from 'utils';
import AllergenInformationButton from '../AllergenInformationButton';
import NutritionalInformationButton from '../NutritionalInformationButton';

import { ButtonsContainer } from './styles';

const StandardButtons = ({ product, additionalButtons, smallBtns }) => (
  <ButtonsContainer className={addCssPrefixTo('STANDARD_BUTTONS')} smallBtns={smallBtns}>
    {product.get('allergens') && productIsFoodOrBeverage(product) && (
      <AllergenInformationButton key="allergen" product={product} smallBtn={smallBtns} />
    )}
    {hasNutritionalInformation(product) && (
      <NutritionalInformationButton key="nutritional" product={product} smallBtn={smallBtns} />
    )}
    {additionalButtons}
  </ButtonsContainer>
);

StandardButtons.propTypes = {
  product: PropTypes.object,
  additionalButtons: PropTypes.node,
  smallBtns: PropTypes.bool,
};

export default StandardButtons;
