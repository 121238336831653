import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import ActionButton from 'components/ActionButton';

export const CarouselContainer = styled.div`
  overflow-y: auto;
  display: flex;
  padding: 15px 0;
`;

export const CarouselButton = styled(ActionButton)`
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 21px;
  color: ${({ theme }) =>
    theme.buttons.defaultBg === 'transparent'
      ? theme.color.lightGrey
      : readableColor(theme.buttons.defaultBg, theme.color.darkGrey, theme.color.lightGrey, true)};
  cursor: pointer;
  margin: 0 5px;
  min-height: auto;
  padding: 6px 22px;
  scroll-snap-align: start;
  text-transform: capitalize;
  white-space: nowrap;
  width: auto;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.buttons.primaryBg}!important;
      color: ${({ theme }) => theme.buttons.primaryColor}!important;
      border: 1px solid ${({ theme }) => theme.buttons.primaryBorder}!important;
    `}
`;
