import fetchHelper from 'utils/fetchHelper';
import { getVenueId } from 'selectors/root';
import { updateUser } from 'actions/user';

export const updateSettings = (settings, success, failure) => (dispatch, getState) => {
  const state = getState();
  const venueId = getVenueId(state);
  const updateUserSettings = res => {
    dispatch(
      updateUser({
        settings: [res.settings],
      })
    );
    success(res);
  };
  fetchHelper(`/api/venues/${venueId}/settings`, 'PUT', settings, updateUserSettings, failure);
};

export const updateAllSettings = (settings, success, failure) => dispatch => {
  const updateSettings = res => {
    dispatch(
      updateUser({
        settings: res.settings,
      })
    );
    success(res);
  };
  fetchHelper('/api/update-settings', 'PUT', settings, updateSettings, failure);
};
