import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { PRIVACY_SETTINGS_NOTIFICATION } from 'appConstants';
import { closeNotification } from 'actions/UI';
import { selectNotificationStack } from 'selectors/ui';
import PrivacySettingsNotification from './notifications/PrivacySettingsNotification';

const NOTIFICATION_COMPONENTS = {
  [PRIVACY_SETTINGS_NOTIFICATION]: PrivacySettingsNotification,
};

const NotificationManager = ({ notificationStack, notificationStackSize, closeNotification }) => {
  if (typeof window === 'undefined' || notificationStackSize === 0) return null;

  return notificationStack.map((notification, index) => {
    const Notification = NOTIFICATION_COMPONENTS[notification.get('component')] || false;

    return Notification ? (
      <Notification key={notification.get('component')} closeNotification={() => closeNotification(index)} />
    ) : null;
  });
};

NotificationManager.propTypes = {
  notificationStack: PropTypes.instanceOf(List),
  notificationStackSize: PropTypes.number,
  closeNotification: PropTypes.func,
};

export default connect(
  state => ({
    notificationStack: selectNotificationStack(state),
    notificationStackSize: selectNotificationStack(state)?.size || 0,
  }),
  { closeNotification }
)(NotificationManager);
