import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.color.successBackgroundColor};
  color: ${({ theme }) => theme.color.success};
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 15px;

  white-space: nowrap;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-left: 5px;
  }

  span {
    text-transform: lowercase;
  }

  ${props =>
    !props.gridView &&
    css`
      transform: rotate(180deg);
      writing-mode: vertical-lr;
      align-self: stretch;
      margin-right: 5px;
    `}

  ${props =>
    props.gridView &&
    css`
      position: absolute;
      margin-left: 0;
      transform: none;
      width: auto;
      padding: 5px 10px;
      border-left: 1px solid ${({ theme }) => theme.color.success};
      border-bottom: 1px solid ${({ theme }) => theme.color.success};
      border-bottom-left-radius: 5px;
      top: 0;
      right: 0;
      z-index: 1;

      html[dir='rtl'] & {
        margin-right: 0;
      }
    `}
`;

const QuantityInOrder = ({ quantity, gridView }) => {
  if (!quantity) return null;

  return (
    <Container gridView={gridView}>
      <span>x{quantity}</span>
    </Container>
  );
};

QuantityInOrder.propTypes = {
  quantity: PropTypes.number,
  gridView: PropTypes.bool,
};

export default QuantityInOrder;
