import { fromJS } from 'immutable';

// we can potentially take this out of state entirely, but we would need a way of specifying fulfilmentDateTimeCollection vs. fulfilmentDateTimeDelivery.. maybe in the api?
export const initialState = fromJS({
  fulfilmentForms: {
    // collection from point
    1: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // delivery to table
    2: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // delivery to address
    3: {
      header: 'checkoutHeader_deliveryDetails',
      fields: [],
    },
    // delivery to room
    4: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // delivery to seat
    5: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // delivery to sunbed
    6: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // collection from venue
    7: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
    // delivery to car
    8: {
      header: 'checkoutHeader_orderDetails',
      fields: [],
    },
  },
});
