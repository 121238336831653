import React from 'react';
import PropTypes from 'prop-types';

const VisaCardAltIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 71.974 45.733"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999" transform="translate(0 0)">
        <rect width="69.974" height="43.733" rx="4" />
        <rect x="-0.5" y="-0.5" width="70.974" height="44.733" rx="4.5" />
      </g>
      <path
        fill="#faa929"
        d="M0,0H69.974a0,0,0,0,1,0,0V5a4,4,0,0,1-4,4H4A4,4,0,0,1,0,5V0A0,0,0,0,1,0,0Z"
        transform="translate(0 35)"
      />
      <path
        fill="#005a9b"
        d="M4,0H65.974a4,4,0,0,1,4,4V9a0,0,0,0,1,0,0H0A0,0,0,0,1,0,9V4A4,4,0,0,1,4,0Z"
        transform="translate(0 0.012)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M242.015,825.741l4.707-15.52h4.2l-4.707,15.52Z"
        transform="translate(-217.632 -796.261)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M228.083,810.229l-3.778,6.58a15.918,15.918,0,0,0-1.794,3.681h-.057c.067-1.38-.126-3.075-.144-4.032l-.417-6.229h-7.069l-.073.418a2.93,2.93,0,0,1,3.189,2.779l1.378,12.319h4.351l8.8-15.516Z"
        transform="translate(-205.578 -796.264)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M288.48,825.765l-.115-2.309-5.244,0-1.072,2.312h-4.56l8.267-15.49h5.6l1.4,15.49ZM288,816.61a35.68,35.68,0,0,1-.008-3.645h-.061c-.256.77-1.353,3.083-1.837,4.221l-1.563,3.4h3.682L288,816.61Z"
        transform="translate(-233.316 -796.284)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M260.86,825.911a11.738,11.738,0,0,1-6.337-1.779l2-3.054a7.761,7.761,0,0,0,4.53,1.519,1.949,1.949,0,0,0,1.839-.885c.585-1.01-.134-1.553-1.775-2.482l-.809-.527c-2.432-1.661-3.484-3.24-2.34-5.995.733-1.762,2.665-3.1,5.852-3.1a9.4,9.4,0,0,1,5.46,1.88l-2.3,2.7a5.108,5.108,0,0,0-3.254-1.427,1.956,1.956,0,0,0-1.792.8c-.438.867.141,1.458,1.419,2.251l.964.613c2.952,1.862,3.656,3.815,2.916,5.64-1.273,3.143-3.765,3.844-6.373,3.844Z"
        transform="translate(-223.162 -795.99)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M306.466,812.746h-.182v-1.108h.42c.267,0,.4.095.4.319a.283.283,0,0,1-.289.3l.31.487h-.205l-.286-.473h-.171v.473Zm.2-.629c.136,0,.257-.013.257-.174,0-.133-.131-.149-.241-.149h-.219v.324Zm-.006,1.057a1,1,0,1,1,.979-1,.963.963,0,0,1-.979,1Zm0-1.827a.829.829,0,1,0,.765.826.777.777,0,0,0-.765-.826Z"
        transform="translate(-245.78 -796.682)"
      />
      <path
        fill="#005a9b"
        fillRule="evenodd"
        d="M267.745,785.2s-64.481-.01-65.829.009"
        transform="translate(-199.904 -785.196)"
      />
    </g>
  </svg>
);

VisaCardAltIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

VisaCardAltIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default VisaCardAltIcon;
