import React from 'react';
import PropTypes from 'prop-types';

const NotesIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        d="M23.4,233.8V6.2h127.8l65.5,66.2v114.2l0,1.1l0,12.7v33.4H23.4z M35.2,222.1h169.7V84.6h-66.6V17.9H35.2V222.1z M150,72.8	h50.6L150,22.5V72.8z"
      />
      <rect
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        x="51"
        y="36"
        width="69.2"
        height="11.8"
      />
      <rect
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        x="51"
        y="73.9"
        width="69.2"
        height="11.8"
      />
      <rect
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        x="51"
        y="150.9"
        width="133.5"
        height="11.8"
      />
      <rect
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        x="51"
        y="113"
        width="133.5"
        height="11.8"
      />
      <rect
        className="icon notesIcon"
        fill={fill}
        opacity={opacity}
        x="51"
        y="188.8"
        width="133.5"
        height="11.8"
      />
    </g>
  </svg>
);

NotesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

NotesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default NotesIcon;
