import React from 'react';
import PropTypes from 'prop-types';

import FormattedPrice from 'components/FormattedPrice';
import { StyledPriceItem, StyledPrice } from './styles';

const PriceLineItem = ({ text, price, taxIncludedMsg }) => (
  <StyledPriceItem>
    <span>
      {text} {taxIncludedMsg && <small>{taxIncludedMsg}</small>}
    </span>
    <StyledPrice>
      <FormattedPrice value={price} />
    </StyledPrice>
  </StyledPriceItem>
);

PriceLineItem.propTypes = {
  text: PropTypes.node,
  price: PropTypes.number,
  taxIncludedMsg: PropTypes.node,
};

export default PriceLineItem;
