import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { browserHistory } from 'react-router';

import { getOrganisationVenues } from 'selectors/organisation';
import { getUserGeoLocationData } from 'selectors/user';
import { selectOrganisationName, selectIsOrganisation } from 'selectors/root';
import { fetchAllVenues } from 'actions/venues';

import PageTitle from 'components/PageTitle';
import VenueListWithLinks from './VenueListWithLinks';
import VenueSearch from './VenueSearch';
import Map from './Map';

import calculateVenuesListing from '../../../../shared/sortVenues/calculateVenuesListing';

import { MainContainer, VenuesContainer, MainTitle } from './styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Search by city or postcode to find your nearest {organisationName}.',
  },
});

const propTypes = {
  userGeoLocationData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  venues: PropTypes.array.isRequired,
  fetchAllVenues: PropTypes.func,
  organisationName: PropTypes.string,
  isOrganisation: PropTypes.bool,
};

const OrganisationLanding = ({
  userGeoLocationData,
  venues,
  fetchAllVenues,
  organisationName,
  isOrganisation,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!isOrganisation) return browserHistory.replace(`/`);

    fetchAllVenues();
  }, [fetchAllVenues, isOrganisation]);

  const venuesList = calculateVenuesListing(venues, userGeoLocationData, searchTerm);
  const showSmallMap = useMediaQuery({ query: '(max-width: 999px)' });
  const showFullMap = useMediaQuery({ query: '(min-width: 1000px)' });

  if (!isOrganisation) return null;
  return (
    <MainContainer>
      <VenuesContainer>
        <PageTitle
          message={messages.title}
          messageValues={{ organisationName }}
          data-cy="organisationLandingPageTitle"
        />

        <MainTitle className="u-textCenter">
          <FormattedMessage {...messages.title} values={{ organisationName }} />
        </MainTitle>

        <VenueSearch searchTerm={searchTerm} onChange={value => setSearchTerm(value)} />

        <div>
          <Map visible={showSmallMap} venues={venuesList} />
        </div>

        <div data-cy="organisationLandingVenueList">
          <VenueListWithLinks venues={venuesList} />
        </div>
      </VenuesContainer>

      <Map visible={showFullMap} venues={venuesList} showVenueAddress={true} />
    </MainContainer>
  );
};

OrganisationLanding.propTypes = propTypes;

export default connect(
  state => ({
    userGeoLocationData: getUserGeoLocationData(state) ? getUserGeoLocationData(state).toJS() : false,
    venues: getOrganisationVenues(state) ? getOrganisationVenues(state)?.toJS() : [],
    organisationName: selectOrganisationName(state),
    isOrganisation: selectIsOrganisation(state),
  }),
  { fetchAllVenues }
)(OrganisationLanding);
