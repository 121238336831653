import React from 'react';
import PropTypes from 'prop-types';

// Icon
const SulphiteIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon sulphiteIcon"
        fill={fill}
        opacity={opacity}
        d="M268.8,983C183.5,983,114,913.6,114,828.3c0-23,4.9-45.1,14.7-65.8l201.7-428.5V17h339.2v316.9l201.7,428.5 c9.7,20.7,14.7,42.8,14.7,65.8c0,85.3-69.4,154.7-154.7,154.7H268.8z M170.9,782.3c-6.8,14.4-10.2,29.9-10.2,46	c0,59.6,48.5,108.1,108.1,108.1h462.5c28.8,0,56-11.3,76.4-31.7c20.4-20.4,31.7-47.6,31.7-76.4c0-16.1-3.4-31.5-10.2-46L778,673.7	H222L170.9,782.3z M244,627.1H756L623,344.3V63.6H377v280.7L244,627.1z"
      />
    </g>
  </svg>
);

SulphiteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

SulphiteIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default SulphiteIcon;
