import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const BackIcon = ({ className, width, height, fill }) => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 1000 1000"
    >
      <path
        className="icon"
        fill={fill}
        d="M482.576,969.37c-19.625,0-38.083-7.637-51.971-21.503L53.402,571.093c-39.249-39.403-39.124-103.394,0.28-142.644
        L430.605,52.086c13.847-13.801,32.253-21.424,51.817-21.457h0.124c40.509,0,73.52,32.958,73.587,73.469v199.599h318.775
        c55.631,0.111,100.979,45.46,101.091,101.091v190.108c-0.127,55.52-45.424,100.855-100.976,101.03H556.134v199.951
        c-0.015,19.595-7.651,38.033-21.503,51.907C520.733,961.704,502.247,969.37,482.576,969.37z M482.543,72.623
        c-8.387,0-16.287,3.252-22.243,9.158L83.353,458.3c-22.863,23.026-22.863,60.425-0.041,83.412l377.032,376.669
        c5.909,5.808,13.727,9.028,22.014,9.069h0.155c17.353,0,31.538-14.118,31.621-31.472V653.96H875.2
        c15.779-0.023,30.586-6.183,41.713-17.341c11.127-11.16,17.242-25.984,17.219-41.743V405.09
        c-0.047-32.504-26.513-58.932-58.997-58.932H514.134V104.207c-0.003-8.39-3.256-16.285-9.159-22.238
        C498.999,75.942,491.032,72.623,482.543,72.623z"
      />
    </svg>
  </Container>
);

BackIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

BackIcon.defaultProps = {
  width: '30px',
  height: '30px',
  fill: 'currentColor',
};

export default BackIcon;
