import React from 'react';
import PropTypes from 'prop-types';

const FoodAndDrinkIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M432.105,954.182c-55.281,0-108.924-10.833-159.439-32.199c-48.775-20.631-92.575-50.159-130.183-87.768	c-37.605-37.604-67.134-81.404-87.766-130.183c-21.366-50.516-32.2-104.158-32.2-159.438c0-55.279,10.833-108.923,32.2-159.438	c20.63-48.776,50.159-92.576,87.766-130.183c37.606-37.606,81.407-67.135,130.183-87.766c50.516-21.366,104.159-32.2,159.439-32.2	c55.281,0,108.923,10.833,159.438,32.2c18.134,7.67,35.816,16.696,52.557,26.829l2.629,1.591l0.244,3.063	c0.829,10.375,2.732,20.69,5.656,30.66l4.741,16.171l-13.894-9.535c-62.362-42.796-135.453-65.417-211.372-65.417	c-206.238,0-374.025,167.787-374.025,374.025c0,206.238,167.787,374.025,374.025,374.025	c206.238,0,374.025-167.787,374.025-374.025c0-72.595-20.825-142.998-60.224-203.597l-9.188-14.132l16.05,5.148	c9.9,3.175,20.162,5.336,30.499,6.422l3.058,0.321l1.524,2.669c8.037,14.064,15.319,28.777,21.644,43.729	c21.365,50.515,32.199,104.157,32.199,159.438s-10.834,108.924-32.199,159.438c-20.631,48.777-50.16,92.577-87.768,130.183	c-37.604,37.607-81.404,67.137-130.183,87.768C541.03,943.349,487.387,954.182,432.105,954.182z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M432.105,865.77c-85.79,0-166.444-33.408-227.106-94.07c-60.662-60.661-94.07-141.315-94.07-227.105	s33.408-166.444,94.07-227.105c60.661-60.662,141.315-94.07,227.106-94.07c85.789,0,166.443,33.408,227.105,94.07	s94.07,141.316,94.07,227.105c0,85.79-33.408,166.444-94.07,227.105C598.548,832.361,517.894,865.77,432.105,865.77z	 M432.105,258.98c-157.488,0-285.614,128.126-285.614,285.614c0,157.488,128.126,285.614,285.614,285.614	c157.488,0,285.614-128.126,285.614-285.614C717.719,387.106,589.593,258.98,432.105,258.98z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M799.361,71.003c-62.902,0-113.896,50.993-113.896,113.895s50.993,113.895,113.896,113.895 c62.901,0,113.894-50.993,113.894-113.895S862.263,71.003,799.361,71.003z M866.829,240.068	c8.302-13.414,6.756-27.907,3.126-44.446c-6.951-31.666-32.748-40.773-66.161-24.946c-24.038,11.386-20.846-51.599-78.239-34.101	c15.479-25.011,43.159-41.677,74.736-41.677c48.509,0,87.834,39.325,87.834,87.834	C888.125,204.644,880.099,224.682,866.829,240.068z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M917.578,385.744c-6.728,0-12.963-3.344-16.679-8.944l-29.075-43.822c-22.441,11.009-47.372,16.804-72.463,16.804	c-90.917,0-164.884-73.966-164.884-164.883S708.444,20.015,799.361,20.015s164.883,73.966,164.883,164.883	c0,40.058-14.432,78.327-40.764,108.418c0.214,0.288,0.421,0.583,0.621,0.884l30.96,46.663c2.953,4.451,3.997,9.786,2.938,15.022	c-1.059,5.236-4.094,9.747-8.545,12.7l-20.831,13.822C925.333,384.59,921.514,385.744,917.578,385.744L917.578,385.744z	 M799.361,52.353c-73.086,0-132.546,59.459-132.546,132.545c0,73.085,59.46,132.545,132.546,132.545	s132.545-59.459,132.545-132.545C931.906,111.813,872.447,52.353,799.361,52.353z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M643.217,443.946c0-63.069-33.969-114.199-75.871-114.199c-41.903,0-75.872,51.13-75.872,114.199	c0,56.152,26.933,103.104,62.41,112.673L544.662,978h43.802l-9.212-420.966C615.494,548.434,643.217,500.918,643.217,443.946z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M334,345.392V490h-27V346h-25v144h-26V345.392l-23,15.643V498.7c0,23.373,21.753,58.144,46.651,59.608L270.388,978h43.803	l-5.452-419.555c25.498,0,49.261-35.553,49.261-59.745V361.035L334,345.392z"
      />
      <path
        className="icon foodAndDrinkIcon"
        fill={fill}
        opacity={opacity}
        d="M460.471,978h-43.804c0,0,9.485-261.685,9.485-290.559c-49.376-132.123-44.683-307.966,22.586-365.066	C448.738,370.088,460.471,978,460.471,978z"
      />
    </g>
  </svg>
);

FoodAndDrinkIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

FoodAndDrinkIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default FoodAndDrinkIcon;
