import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import Dropdown from 'components/Dropdown';
import FieldLabel from 'components/Form/Fields/Label';

const messages = defineMessages({
  selectOption: {
    defaultMessage: 'Please select...',
  },
});

const DropdownField = ({
  value,
  options,
  intlIdentifier,
  intlLabel,
  onChange,
  onBlur,
  required,
  placeholder,
}) => {
  if (options && options.length === 1) {
    return null;
  }
  return (
    <span>
      {intlLabel && <FieldLabel intlIdentifier={intlIdentifier} intlLabel={intlLabel} required={required} />}
      <Dropdown
        onChange={value => {
          onChange(value);
          onBlur(value);
        }}
        value={value}
        options={options}
        label={placeholder}
        multi={false}
        required={required}
      />
    </span>
  );
};

DropdownField.defaultProps = {
  placeholder: <FormattedMessage {...messages.selectOption} />,
};

DropdownField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  intlIdentifier: PropTypes.string,
  intlLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.node,
};

export default DropdownField;
