// Stock management errors
export const ERROR_PRODUCT_DISABLED = 'product_disabled';
export const ERROR_SECTION_PRODUCT_DISABLED = 'section_product_disabled';
export const ERROR_OUT_OF_STOCK = 'out_of_stock';
export const ERROR_INSUFFICIENT_STOCK = 'insufficient_stock';
export const ERROR_NO_ITEMS = 'no_items';
export const ERROR_ITEM_ERROR = 'item_error';

// Update / submit order errors
export const ERROR_ORDER_ITEM_NOT_FOUND = 'order_item_not_found';
export const ERROR_ORDER_ITEMS = 'item_errors';
export const ERROR_ORDER_VALIDATION = 'validation_errors';
export const ERROR_ORDER_INVALID = 'invalid_order';
export const ERROR_OUTSIDE_RADIUS = 'outside_radius';

// EPOS order validation errors
export const EPOS_SITE_OFFLINE = 'epos_site_offline';
export const EPOS_FULFILMENT_TIME_UNAVAILABLE = 'epos_fulfilment_time_unavailable';
export const EPOS_ITEM_ERRORS = 'epos_item_errors';
export const EPOS_ERROR = 'epos_error';

// Tab errors
export const ERROR_CREATE_TAB = 'createTabError';
export const ERROR_PAY_TAB = 'payTabError';
export const ERROR_PRE_AUTH = 'preAuthError';
export const ERROR_CLOSED_TAB = 'closed_tab_order';
export const ERROR_PAYMENT_SOURCE_PAYMENT = 'paymentSourcePaymentError';
