import styled, { css } from 'styled-components';

export default styled.button`
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  background: ${({ theme }) => theme.color.secondaryBackground};
  border-radius: 5px;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};
  color: ${({ theme }) => theme.color.text};
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 10px 7px 10px 0;
  max-width: 140px;
  @media (max-width: 767px) {
    & > span {
      display: block;
    }
  }
  svg {
    width: 35px;
    margin-right: 5px;

    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  ${props =>
    !props.smallBtn &&
    css`
      :last-of-type {
        margin-right: 0;

        html[dir='rtl'] & {
          margin-right: 7px;
        }
      }

      :first-of-type {
        html[dir='rtl'] & {
          margin-right: 0;
        }
      }

      @media (max-width: 380px) {
        flex-flow: column;
        text-align: center;
        padding: 5px;
        margin: 10px 10px 10px 0;

        svg {
          margin-right: 0;
        }
      }
    `}

  ${props =>
    props.smallBtn &&
    css`
      display: block;
      text-align: center;
      margin: 0;
      padding: 7px;
      background: none;
      border: none;

      & svg {
        border: 1px solid currentColor;
        border-radius: 50%;
        height: 35px;
        padding: 5px;
        margin: 0;
      }
    `}
`;
