import React from 'react';
import PropTypes from 'prop-types';

import { AddressForm } from 'components/Address';
import { AlertContent } from 'assets/styles/sharedStyles';

const propTypes = {
  closeAlert: PropTypes.func,
};

const AddAddressAlert = ({ closeAlert }) => (
  <AlertContent>
    <AddressForm closeAlert={closeAlert} isModal={true} />
  </AlertContent>
);

AddAddressAlert.propTypes = propTypes;

export default AddAddressAlert;
