import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const FormattedPrice = ({ value, showCurrency }, { currency }) => {
  const options = {
    // Indonesian rupiah should be displayed without the decimal places.
    // Sen are obsolete subunits (no longer produced) and displaying prices with
    // .00 decimals looks strange in Indonesia.
    ...(currency === 'IDR' ? { maximumFractionDigits: 0 } : {}),
  };

  if (!showCurrency) {
    return <FormattedNumber style="decimal" value={value / 100} {...options} />; // eslint-disable-line react/style-prop-object
  }

  return <FormattedNumber style="currency" currency={currency} value={value / 100} {...options} />; // eslint-disable-line react/style-prop-object
};

FormattedPrice.defaultProps = {
  showCurrency: true,
};

FormattedPrice.propTypes = {
  value: PropTypes.number,
  showCurrency: PropTypes.bool,
};

FormattedPrice.contextTypes = {
  currency: PropTypes.string,
};

export default FormattedPrice;
