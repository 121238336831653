import styled from 'styled-components';

import { StyledInput } from 'assets/styles/sharedStyles';

export const Container = styled.div`
  position: relative;
`;

export const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.primaryText};

  html[dir='rtl'] & {
    text-align: right;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const TextAreaStyled = styled.textarea`
  ${StyledInput}
`;
