import React from 'react';
import PropTypes from 'prop-types';

const CareemIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 181.406"
  >
    <rect fill="none" className="icon CareemIcon" width="180" height="181.406" />

    <g transform="translate(50.295 34)">
      <path
        fill="#28bb4e"
        className="icon CareemIcon"
        d="M51.6,0A35.958,35.958,0,0,1,74.688,13.987a29.371,29.371,0,0,1,2.988,4.889c-3.531,2.444-7.061,4.753-10.456,7.2a6.42,6.42,0,0,1-1.63.951,22.227,22.227,0,0,0-8.148-10.185,27.169,27.169,0,0,0-8.963-3.666C49.43,8.827,50.516,4.481,51.6,0ZM18.74,3.938A9.13,9.13,0,0,1,29.6,8.691a9.226,9.226,0,0,1-.951,9.506,9.248,9.248,0,0,1-11.135,2.58,9.128,9.128,0,0,1-4.889-6.247,8.734,8.734,0,0,1,.815-6.111,9.555,9.555,0,0,1,5.3-4.481ZM0,50.245c5.024-.136,9.913,0,14.938,0a29.453,29.453,0,0,0,6.926,14.666,26.011,26.011,0,0,0,10.456,7.061,29.389,29.389,0,0,0,17.11.951A23.917,23.917,0,0,0,62.6,64.639,26.24,26.24,0,0,0,67.626,55c4.481,1.222,8.691,2.852,13.172,4.21a15.52,15.52,0,0,1-1.222,4.074,37.083,37.083,0,0,1-15.752,18.6,40.449,40.449,0,0,1-15.209,5.16,44.808,44.808,0,0,1-30.283-6.654A39.48,39.48,0,0,1,6.382,68.17,40.6,40.6,0,0,1,0,50.245Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);

CareemIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CareemIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default CareemIcon;
