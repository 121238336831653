import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import PoweredFooter from 'components/PoweredFooter';
import { isLoggedIn } from 'selectors/user';
import Page from 'components/Pages/container';
import SettingsIcon from 'components/Icons/SettingsIcon';
import settingsMessages from 'components/settingsMessages';
import AccountSettings from './accountSettings';

const Settings = ({ isLoggedIn, theme }) => (
  <Page
    settingsPage={true}
    titleMessage={settingsMessages.settingsPage}
    redirectCondition={!isLoggedIn}
    Icon={SettingsIcon}
    iconFill={theme.color.settings}
  >
    <AccountSettings />
    <PoweredFooter />
  </Page>
);

Settings.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(withTheme(Settings));
