import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { DeliveryIcon } from 'components/Icons';
import { AlertContent, AlertTitle } from 'assets/styles/sharedStyles';
import { DeliveryPartnersContainer } from 'components/Alert/styles';
import DeliveryPartnersLinks from './DeliveryPartnersLinks';

const messages = defineMessages({
  title: {
    defaultMessage: 'Select a delivery partner',
  },
});

const propTypes = {
  venueId: PropTypes.number,
};

const DeliveryPartnersAlert = ({ venueId }) => (
  <AlertContent>
    <DeliveryPartnersContainer>
      <DeliveryIcon width="60px" height="60px" />
      <AlertTitle>
        <FormattedMessage {...messages.title} />
      </AlertTitle>

      <DeliveryPartnersLinks venueId={venueId} />
    </DeliveryPartnersContainer>
  </AlertContent>
);

DeliveryPartnersAlert.propTypes = propTypes;

export default DeliveryPartnersAlert;
