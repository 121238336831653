import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { selectAddressesList, selectAddressByAddressId } from 'selectors/user';
import SettingsPage from 'components/Pages/User/SettingsPage';
import { AddressForm } from 'components/Address';
import settingsMessages from 'components/settingsMessages';

const propTypes = {
  selectedAddress: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
};

const EditAddress = ({ selectedAddress }) => {
  const [address, setAddress] = useState(false);

  useEffect(() => {
    setAddress(selectedAddress);
  }, [selectedAddress]);

  return (
    <SettingsPage titleMessage={settingsMessages.addressesTitle}>
      {address ? <AddressForm address={address.toJS()} /> : <div />}
    </SettingsPage>
  );
};

EditAddress.propTypes = propTypes;

export default connect((state, { params }) => ({
  addressesListSize: selectAddressesList(state).size,
  selectedAddress: selectAddressByAddressId(state, params?.addressId),
}))(EditAddress);
