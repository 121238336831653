import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MenusIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon menusIcon"
      fill={fill}
      opacity={opacity}
      d="M967,75.1l23,3.8V879l-34.5-5.7c-16.6-2.5-32.2-4.8-46.9-6.7c-14.7-1.9-31.6-3.5-50.7-4.8 c-19.1-1.3-40.2-1.9-63.2-1.9c-16.6,0-33.8,0.7-51.7,1.9c-17.9,1.3-36.4,3.2-55.5,5.7c-19.1,2.6-38.6,6.1-58.4,10.5 c-19.8,4.5-38.6,10.5-56.5,18.2v47.9H427.3v-47.9c-16.6-6.4-33.8-12.1-51.7-17.2c-17.9-5.1-36.4-9.2-55.5-12.4 c-19.1-3.2-38.9-5.7-59.3-7.7c-20.4-1.9-40.9-2.9-61.3-2.9c-21.7,0-42.1,1-61.3,2.9s-35.4,3.8-48.8,5.7c-13.4,1.9-27.8,4.8-43.1,8.6 L10,882.8v-802l21.1-5.7c1.3,0,6-1,14.4-2.9c8.3-1.9,19.8-4.1,34.5-6.7c14.7-2.5,32.2-4.8,52.6-6.7c20.4-1.9,42.7-2.9,67-2.9 c28.1,0,55.2,1.6,81.3,4.8c26.1,3.2,52,7.4,77.5,12.4c25.5,5.1,49.8,11.8,72.7,20.1c23,8.3,45.9,18.2,68.9,29.7 c17.9-10.2,37.6-19.1,59.3-26.8c21.7-7.7,45-14.4,69.9-20.1c24.9-5.7,51-9.9,78.5-12.4c27.4-2.5,56.5-3.8,87.1-3.8 c24.2,0,46.6,1,67,2.9c20.4,1.9,38.3,3.5,53.6,4.8c15.3,1.3,27.4,2.9,36.4,4.8C960.6,74.1,965.7,75.1,967,75.1z M67.4,810.1 c7.7-1.3,16.3-2.5,25.8-3.8c9.6-1.3,19.8-2.5,30.6-3.8c10.8-1.3,22.6-2.2,35.4-2.9c12.7-0.6,26.1-1,40.2-1c25.5,0,50.1,1.3,73.7,3.8	c23.6,2.6,46.6,6.1,68.9,10.5c22.3,4.5,44.3,10.2,66,17.2c21.7,7,42.7,15,63.2,23.9V174.6c-20.4-10.2-41.2-19.1-62.2-26.8	c-21.1-7.7-42.8-13.7-65.1-18.2c-22.3-4.5-45.6-8-69.9-10.5c-24.3-2.5-49.1-3.8-74.6-3.8c-14.1,0-27.8,0.3-41.2,1	c-13.4,0.7-25.8,1.6-37.3,2.9c-11.5,1.3-21.7,2.6-30.6,3.8c-8.9,1.3-16.6,2.6-23,3.8V810.1z M932.6,812V126.8	c-7.7,0-16.3-0.6-25.8-1.9c-9.6-1.3-20.1-2.5-31.6-3.8c-11.5-1.3-24.3-2.2-38.3-2.9c-14.1-0.6-28.1-1-42.1-1 c-19.1,0-39.9,1-62.2,2.9c-22.3,1.9-45.3,4.8-68.9,8.6c-23.6,3.8-46.9,9.6-69.9,17.2c-23,7.7-44.7,16.6-65.1,26.8v679.5	c17.9-7.7,36.7-14.7,56.5-21.1c19.8-6.4,40.8-11.8,63.2-16.3c22.3-4.5,45.6-7.7,69.9-9.6c24.2-1.9,49.8-2.9,76.6-2.9 c14,0,27.8,0.3,41.2,1c13.4,0.7,25.8,1.3,37.3,1.9c11.5,0.7,22.3,1.6,32.5,2.9C916,809.5,924.9,810.7,932.6,812z"
    />
  </svg>
);

MenusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MenusIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default MenusIcon;
