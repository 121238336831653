import React from 'react';
import PropTypes from 'prop-types';

const ChromeDotsIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon ChromeDotsIcon"
      fill={fill}
      d="M544,768.9c14.1,5.7,26.5,13.7,37.3,23.9c10.8,10.2,19.1,22.3,24.9,36.4c5.7,14,8.6,29.3,8.6,45.9c0,15.3-2.9,30-8.6,44 c-5.7,14-14,26.5-24.9,37.3s-23.3,19.1-37.3,24.9c-14,5.7-28.7,8.6-44,8.6c-16.6,0-31.6-2.9-45-8.6c-13.4-5.7-25.5-14-36.4-24.9 c-10.8-10.8-19.1-23.3-24.9-37.3c-5.7-14-8.6-28.7-8.6-44c0-16.6,2.9-31.9,8.6-45.9c5.7-14,14.1-26.2,24.9-36.4 c10.8-10.2,23-18.2,36.4-23.9c13.4-5.7,28.4-8.6,45-8.6C515.3,760.3,530,763.2,544,768.9z M393.8,544c5.7,14.1,14.1,26.5,24.9,37.3 c10.8,10.8,23,19.1,36.4,24.9c13.4,5.7,28.4,8.6,45,8.6c15.3,0,30-2.9,44-8.6c14.1-5.7,26.5-14,37.3-24.9 c10.8-10.8,19.1-23.3,24.9-37.3c5.7-14,8.6-28.7,8.6-44s-2.9-30-8.6-44c-5.7-14-14-26.5-24.9-37.3c-10.8-10.8-23.3-19.1-37.3-24.9 c-14-5.7-28.7-8.6-44-8.6c-16.6,0-31.6,2.9-45,8.6c-13.4,5.7-25.5,14.1-36.4,24.9c-10.8,10.8-19.1,23.3-24.9,37.3 c-5.7,14-8.6,28.7-8.6,44S388,530,393.8,544z M393.8,170.8c5.7,14,14.1,26.2,24.9,36.4c10.8,10.2,23,18.2,36.4,23.9 c13.4,5.7,28.4,8.6,45,8.6c15.3,0,30-2.9,44-8.6c14.1-5.7,26.5-13.7,37.3-23.9c10.8-10.2,19.1-22.3,24.9-36.4 c5.7-14,8.6-29.3,8.6-46c0-15.3-2.9-30-8.6-44c-5.7-14-14-26.5-24.9-37.3S558.1,24.4,544,18.6c-14-5.7-28.7-8.6-44-8.6 c-16.6,0-31.6,2.9-45,8.6c-13.4,5.7-25.5,14.1-36.4,24.9c-10.8,10.8-19.1,23.3-24.9,37.3c-5.7,14-8.6,28.7-8.6,44 C385.2,141.4,388,156.8,393.8,170.8z"
    />
  </svg>
);

ChromeDotsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ChromeDotsIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ChromeDotsIcon;
