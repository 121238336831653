import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Donations from 'components/Donations';
import { addCssPrefixTo } from 'utils';
import { StyledTaxBreakdown, SummaryContainer, Title } from './styles';
import globalMessages from '../../globalMessages';
import { CheckoutTerms as Terms, Summary } from '../styles';
import messages from '../messages';
import ProductLineItem from './ProductLineItem';
import PriceLineItem from './PriceLineItem';
import TaxBreakdown from '../TaxBreakdown';
import PromotionCoupon from '../../PromotionCoupon';
import GratuityInput from '../../GratuityInput';
import GratuityOptions from '../../GratuityOptions';
import { getServiceId, selectGratuityOptions, selectVenueCheckoutTerms } from '../../../selectors/root';
import {
  getGratuity,
  getLoyaltyPromotionItems,
  getOrderChargeItems,
  returnStackedPromotions,
  selectOrderFinalTotal,
  selectOrderTaxTotal,
  selectOrderTaxTotals,
  selectOrderTotal,
  showCheckoutGratuity,
  validatedOrderItems,
} from '../../../selectors/order';
import { shouldShowPriceExcludingTax } from '../../../selectors/features';
import { orderReview, setGratuity } from '../../../actions/order';

const FieldContainer = styled.div`
  display: block;
`;

const CheckoutTerms = styled(Terms)`
  @media (min-width: 800px) {
    display: none;
  }
`;

const OrderVoucherField = ({
  showGratuity,
  gratuity,
  gratuityItemsTotal,
  gratuityOptions,
  orderTotal,
  setGratuity,
  finalTotal,
  checkoutTerms,
  serviceId,
  productItems,
  promotionalItems,
  chargeItems,
  taxTotal,
  taxBreakdown,
  isTaxExcluded,
  loyaltyPromotionItems,
}) => (
  <FieldContainer>
    <SummaryContainer className={addCssPrefixTo('SUMMARY_CONTAINER')}>
      <Summary>
        {!productItems.size ? (
          <p>
            <FormattedMessage {...messages.emptyOrder} />
          </p>
        ) : (
          <>
            <Title>
              <FormattedMessage {...globalMessages.orderSummary} />
            </Title>
            {productItems.map((item, index) =>
              item.get('key') === 'donation' ? null : <ProductLineItem key={index} item={item} />
            )}

            {promotionalItems.map((item, index) => (
              <ProductLineItem key={index} item={item} />
            ))}

            {loyaltyPromotionItems.map((item, index) => (
              <ProductLineItem key={index} item={item} />
            ))}

            {chargeItems.map((item, index) => (
              <PriceLineItem key={index} text={item.get('name')} price={item.get('unit_price')} />
            ))}

            <Donations />

            <PriceLineItem text={<FormattedMessage {...messages.subTotal} />} price={orderTotal} />

            {taxTotal !== 0 && (
              <StyledTaxBreakdown>
                <TaxBreakdown taxBreakdown={taxBreakdown} taxTotal={taxTotal} isTaxExcluded={isTaxExcluded} />
              </StyledTaxBreakdown>
            )}
          </>
        )}
      </Summary>

      <PromotionCoupon />

      {showGratuity &&
        orderTotal !== 0 &&
        finalTotal !== 0 &&
        (gratuityOptions?.size > 0 ? (
          <GratuityOptions
            options={gratuityOptions}
            onChange={setGratuity}
            selectedGratuity={gratuity.toJS()}
            orderTotal={gratuityItemsTotal || orderTotal}
          />
        ) : (
          <GratuityInput
            serviceId={serviceId}
            gratuityPercent={gratuity.get('value')}
            orderTotal={gratuityItemsTotal || orderTotal}
            onChange={setGratuity}
          />
        ))}
      <br />

      <PriceLineItem text={<FormattedMessage {...messages.total} />} price={finalTotal} />

      {checkoutTerms && <CheckoutTerms dangerouslySetInnerHTML={{ __html: checkoutTerms }} />}
    </SummaryContainer>
  </FieldContainer>
);

OrderVoucherField.defaultProps = {
  taxTotal: 0,
};

OrderVoucherField.propTypes = {
  serviceId: PropTypes.string,
  showGratuity: PropTypes.bool.isRequired,
  gratuity: PropTypes.instanceOf(Map),
  gratuityOptions: PropTypes.instanceOf(List),
  setGratuity: PropTypes.func,
  orderTotal: PropTypes.number.isRequired,
  taxTotal: PropTypes.number,
  gratuityItemsTotal: PropTypes.number,
  finalTotal: PropTypes.number.isRequired,
  checkoutTerms: PropTypes.string,
  productItems: PropTypes.instanceOf(List),
  chargeItems: PropTypes.instanceOf(List),
  promotionalItems: PropTypes.instanceOf(List),
  loyaltyPromotionItems: PropTypes.instanceOf(List),
  promotionTotal: PropTypes.number,
  taxBreakdown: PropTypes.instanceOf(List),
  isTaxExcluded: PropTypes.bool,
};

export default connect(
  state => ({
    serviceId: getServiceId(state),
    gratuity: getGratuity(state) || 0,
    gratuityOptions: selectGratuityOptions(state, getServiceId(state)),
    productItems: validatedOrderItems(state),
    promotionalItems: returnStackedPromotions(state),
    loyaltyPromotionItems: getLoyaltyPromotionItems(state),
    chargeItems: getOrderChargeItems(state),
    orderTotal: selectOrderTotal(state),
    taxTotal: selectOrderTaxTotal(state),
    taxBreakdown: selectOrderTaxTotals(state),
    gratuityItemsTotal: selectOrderTotal(state, { gratuityTotal: true }),
    checkoutTerms: selectVenueCheckoutTerms(state),
    isTaxExcluded: shouldShowPriceExcludingTax(state),
    finalTotal: selectOrderFinalTotal(state),
    showGratuity: !!showCheckoutGratuity(state),
  }),
  { openCart: orderReview, setGratuity }
)(OrderVoucherField);
