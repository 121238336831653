import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getServiceLocationNames } from 'selectors/root';
import AutocompleteField from 'components/Form/Fields/AutocompleteField';

const propTypes = {
  locationNames: PropTypes.array,
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const LocationName = props => (
  <AutocompleteField
    {...props}
    type={props.locationNames.length ? 'text' : 'number'}
    autocompleteItems={props.locationNames}
  />
);

LocationName.propTypes = propTypes;

const mapStateToProps = (state, { locationKey, serviceId }) => ({
  locationNames: getServiceLocationNames(state, locationKey, serviceId).toJS(),
});

export default connect(mapStateToProps)(LocationName);
