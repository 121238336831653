import { fetchHelper } from 'utils';
import { REQUEST_CROSS_SELLS, REQUEST_CROSS_SELLS_SUCCESS, REQUEST_CROSS_SELLS_FAILURE } from './constants';

export const fetchCrossSells = (serviceId, menuTypeId) => dispatch => {
  dispatch({ type: REQUEST_CROSS_SELLS });

  const success = crossSellsList => dispatch({ type: REQUEST_CROSS_SELLS_SUCCESS, crossSellsList });
  const failure = () => dispatch({ type: REQUEST_CROSS_SELLS_FAILURE });

  fetchHelper(
    `/api/orders/cross-sell?service_id=${serviceId}&menu_type_id=${menuTypeId}`,
    'GET',
    null,
    success,
    failure
  );
};
