import React from 'react';
import PropTypes from 'prop-types';

const PinterestIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon PinterestIcon"
      fill={fill}
      d="M428.4,662.2C403,795.5,372,923.3,280,990c-28.3-201.4,41.7-352.7,74.3-513.3c-55.5-93.3,6.7-281.3,123.6-235 c144,56.9-124.7,347.1,55.7,383.4C721.8,663,798.7,298.5,682,179.9C513.3,8.8,190.9,176,230.6,421.1c9.6,59.9,71.5,78.1,24.7,160.8 c-108-24-140.2-109.1-136.1-222.6c6.7-185.8,167-315.9,327.8-333.9C650.3,2.6,841.1,100,867.5,291.2 c29.7,215.9-91.7,449.7-309.2,432.9C499.3,719.5,474.6,690.3,428.4,662.2L428.4,662.2z M428.4,662.2"
    />
  </svg>
);

PinterestIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

PinterestIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default PinterestIcon;
