import React from 'react';
import PropTypes from 'prop-types';
import BaseTab from './BaseTab';

/* eslint react/no-unused-state: "off" */

const TabContext = React.createContext();

class TabList extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      content: null,
      setContent: this.setContent,
    };
  }

  setContent = content => {
    this.setState({ content });
  };

  render = () => <TabContext.Provider value={this.state}>{this.props.children}</TabContext.Provider>;
}

const Tab = props => (
  <TabContext.Consumer>
    {({ content, setContent }) => <BaseTab {...props} content={content} setContent={setContent} />}
  </TabContext.Consumer>
);

const TabContent = () => <TabContext.Consumer>{value => value.content}</TabContext.Consumer>;

export { TabList, Tab, TabContent };
