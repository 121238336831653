import React from 'react';
import ReactGA from 'react-ga4';
import { hydrate } from 'react-dom';
import { match, Router, browserHistory as history, createRoutes } from 'react-router';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';

import { PropTypes } from 'prop-types';
import App from 'components/App';
import Root from 'components/Root';
import withCookies from 'components/withCookies';
import { forceLogout } from 'actions/user';
import { checkLocationQueryParams } from 'actions/root';
import { addOfflineChecks } from 'utils/offlineChecker';
import registerAuthMiddleware from 'middleware/authorisationMiddleware';
import { getTrackingId, getVenueDimension } from 'reducers/analytics';
import AppErrorBoundary from 'components/AppErrorBoundary/AppErrorBoundary';
import configureStore from './store';
import getAppRoutes from './routes';

// Require immutable devtools in development
if (process.env.NODE_ENV !== 'production') {
  const Immutable = require('immutable'); // eslint-disable-line global-require
  const installDevTools = require('immutable-devtools'); // eslint-disable-line global-require
  installDevTools(Immutable);
}

// Create a redux store with history
const initialState = window.__INITIAL_STATE__; // eslint-disable-line no-underscore-dangle
const store = configureStore(initialState);

// Setup router with Root Component wrapping all routes
const rootRoute = {
  component: Root,
  childRoutes: getAppRoutes(store),
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://34ac7c5d112648829e59da4dc16010c2@o49472.ingest.sentry.io/105842',

    release: process.env.RELEASE,

    ignoreErrors: [
      // ------------------------
      // wi-Q Specific Ignores
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'Non-Error promise rejection captured',
      'Load failed',
      'Failed to register a ServiceWorker for scope',

      // ------------------------
      // Common values to ignore from
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],

    // Common denyUrls to ignore from
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],

    integrations: [
      new Sentry.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV3Instrumentation(history, createRoutes(rootRoute), match),
        // ... other options
      }),

      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,

    // Capture Replay for 0.5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.005,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Add middleware to intercept 403 requests and log user out
registerAuthMiddleware(() => {
  store.dispatch(forceLogout());
});

// Include internationalisation if not available on client
if (!global.Intl) {
  require.ensure(['intl', 'intl/locale-data/jsonp/en'], require => {
    require('intl');
    require('intl/locale-data/jsonp/en');
  });
}

const initialiseGA = (state, trackingId) => {
  ReactGA.initialize(trackingId);
  const venueDimension = getVenueDimension(state);
  if (venueDimension) ReactGA.set({ [venueDimension]: store.getState().getIn(['venue', 'id']) });
  ReactGA.gtag('set', 'user_properties', {
    venue: store.getState().getIn(['venue', 'id']),
  });
};

const dispatchClientInit = () => store.dispatch({ type: '@@CLIENT_INIT' });

const renderApp = renderProps => {
  const AppWrapper = ({ getCookie, setCookie }) => {
    const allowCookies = getCookie('acceptCookies') === 'true';
    const state = store.getState();
    const trackingId = getTrackingId(state);

    const allowAnalytics = process.env.NODE_ENV === 'production' && trackingId && allowCookies;

    if (allowAnalytics) {
      initialiseGA(state, trackingId);
    }

    if (renderProps?.location?.query) {
      store.dispatch(checkLocationQueryParams(renderProps.location.query, setCookie));
    }

    return (
      <App store={store}>
        <Router history={history} {...renderProps} />
      </App>
    );
  };

  AppWrapper.propTypes = {
    getCookie: PropTypes.func.isRequired,
    setCookie: PropTypes.func.isRequired,
  };

  const AppWithCookies = withCookies(AppWrapper);

  hydrate(
    <AppErrorBoundary>
      <CookiesProvider>
        <AppWithCookies />
      </CookiesProvider>
    </AppErrorBoundary>,
    document.getElementById('root'),
    dispatchClientInit
  );
  addOfflineChecks(store);
};

match({ history, routes: rootRoute }, (error, redirectLocation, renderProps) => {
  renderApp(renderProps);
});
