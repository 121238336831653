import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Label } from './styles';

export const messages = defineMessages({
  collectionPointTitle: {
    defaultMessage: 'Collection Point',
  },
  deliveryAddress: {
    defaultMessage: 'Delivery Address',
  },
  seatingDetails: {
    defaultMessage: 'Seating Details',
  },
});

const FieldLabel = props => (
  <Label>
    {props.intlLabel ? (
      <span>{props.intlLabel}</span>
    ) : (
      <FormattedMessage {...messages[props.intlIdentifier]} />
    )}
  </Label>
);

FieldLabel.propTypes = {
  class: PropTypes.string,
  intlIdentifier: PropTypes.string,
  intlLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  requiredFields: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FieldLabel;
