import styled from 'styled-components';

import { ModalLayer } from 'components/DayPickerCalendar/styles';

export const DayPickerWrapper = styled.div`
  margin: 10px 0 30px;

  @media (min-width: 768px) {
    ${ModalLayer} {
      height: auto;
    }
  }
`;
