import { fromJS } from 'immutable';

import {
  INITIATE_FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST_COMPLETE,
  FORGOT_PASSWORD_CLEAR_STATE,
  INITIATE_RESET_PASSWORD,
  RESET_PASSWORD_REQUEST_COMPLETE,
  VALIDATE_RESET_TOKEN_REQUEST,
  VALIDATE_RESET_TOKEN_COMPLETE,
} from 'actions/user/constants';

export const initialState = fromJS({
  forgotPassword: {
    requesting: false,
    successful: undefined,
    violations: undefined,
  },
  resetPassword: {
    requesting: false,
    successful: undefined,
    violations: undefined,
  },
  validateToken: {
    requesting: false,
    email: undefined,
    successful: undefined,
  },
});

const userPassword = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_FORGOT_PASSWORD:
      return state.merge(
        fromJS({
          forgotPassword: {
            requesting: true,
            successful: undefined,
          },
        })
      );
    case FORGOT_PASSWORD_REQUEST_COMPLETE:
      return state.merge(
        fromJS({
          forgotPassword: {
            requesting: false,
            successful: action.success,
            violations: action.violations,
          },
        })
      );
    case FORGOT_PASSWORD_CLEAR_STATE:
      return state.merge(
        fromJS({
          forgotPassword: {
            requesting: false,
            successful: undefined,
            violations: undefined,
          },
        })
      );
    case INITIATE_RESET_PASSWORD:
      return state.merge(
        fromJS({
          resetPassword: {
            requesting: true,
            successful: undefined,
          },
        })
      );
    case RESET_PASSWORD_REQUEST_COMPLETE:
      return state.merge(
        fromJS({
          resetPassword: {
            requesting: false,
            successful: action.success,
            violations: action.violations,
          },
        })
      );
    case VALIDATE_RESET_TOKEN_REQUEST:
      return state.merge(
        fromJS({
          validateToken: {
            requesting: true,
            email: undefined,
            successful: undefined,
          },
        })
      );
    case VALIDATE_RESET_TOKEN_COMPLETE:
      return state.merge(
        fromJS({
          validateToken: {
            requesting: false,
            email: action.email,
            valid: action.valid,
          },
        })
      );
    default:
      return state;
  }
};

export default userPassword;
