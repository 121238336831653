import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import ActionButton from 'components/ActionButton';
import EmptyFilterIcon from 'components/Icons/FilterIcon';
import SystemIcon from 'components/SystemIcon';

import globalMessages from 'components/globalMessages';
import { selectVenueAllergensMessage } from 'selectors/root';
import { StyledText } from './styles';
import messages from './messages';

const AllergenFilterContactStaff = ({ allergenMessage, closeAlert }) => (
  <>
    <AlertContent>
      <SystemIcon Icon={EmptyFilterIcon} />
      <AlertTitle>
        <FormattedMessage {...messages.header} />
      </AlertTitle>
      <StyledText>{allergenMessage}</StyledText>
    </AlertContent>
    <AlertFooter>
      <AlertButtons>
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.cancel} />}
          onClick={closeAlert}
        />
      </AlertButtons>
    </AlertFooter>
  </>
);

AllergenFilterContactStaff.propTypes = {
  closeAlert: PropTypes.func,
  allergenMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const mapStateToProps = state => ({
  allergenMessage: selectVenueAllergensMessage(state),
});

export default connect(mapStateToProps)(AllergenFilterContactStaff);
