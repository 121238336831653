import React from 'react';
import PropTypes from 'prop-types';

// Icon
const HomeIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M2.3,126.2c0-1.9,0.7-3.5,2.1-4.7L115.6,10.4c1.4-1.4,3-2.1,4.8-2.1c1.6,0,3.2,0.7,4.6,2.1l44.1,44.5V31.6	c0-1.9,0.7-3.5,2-4.8c1.3-1.3,2.9-2,4.7-2c1.9,0,3.6,0.7,5,2c1.4,1.3,2.1,2.9,2.1,4.8v36.7l53.7,53.9c1.2,1.4,1.9,3.1,1.9,4.9	s-0.6,3.5-1.9,4.7c-1.4,1.2-3,1.7-4.8,1.7c-2.1,0-3.7-0.6-4.8-1.7L120.4,24.6L13.8,130.9c-1.2,1.4-2.8,2.1-4.7,2.1 s-3.5-0.7-4.7-2.1C3,129.7,2.3,128.1,2.3,126.2z M34.4,223.6v-96.5c0-1.8,0.6-3.3,1.9-4.6c1.3-1.3,2.8-1.9,4.6-1.9 c1.9,0,3.5,0.6,4.8,1.9c1.3,1.3,2,2.8,2,4.6v89.8h48.2v-38.1c0-1.8,0.7-3.4,2-4.7c1.4-1.3,3-2,4.9-2h34.9c1.8,0,3.4,0.7,4.6,2	c1.3,1.3,1.9,2.9,1.9,4.7v38.1h48.4V124c0-1.9,0.7-3.5,2-4.8s2.9-2,4.8-2c1.8,0,3.3,0.7,4.6,2c1.3,1.3,1.9,2.9,1.9,4.8v99.6	c0,1.8-0.6,3.4-1.9,4.6c-1.3,1.3-2.8,1.9-4.6,1.9h-61.5c-1.9,0-3.5-0.6-4.8-1.9c-1.3-1.3-2-2.8-2-4.6v-37.8h-21.4v37.8 c0,1.8-0.6,3.3-1.9,4.6c-1.3,1.3-2.8,1.9-4.7,1.9H41c-1.8,0-3.4-0.6-4.7-1.9C35.1,226.9,34.4,225.4,34.4,223.6z"
      />
    </g>
  </svg>
);

HomeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

HomeIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default HomeIcon;
