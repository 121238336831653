import React from 'react';
import PropTypes from 'prop-types';

// Icon
const FilterIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon FilterIcon"
        fill={fill}
        d="M93.578,226.387v-96.203L5,13.119h230l-88.583,117.065v70.701l-44.057,25.502H93.578z M136.882,193.304v-66.098 l78.987-104.545h-192l79.005,104.195v86.563L136.882,193.304z"
      />
    </g>
  </svg>
);

FilterIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FilterIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default FilterIcon;
