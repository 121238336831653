import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { selectServiceId } from 'selectors/browse';
import { fetchQuestions } from 'actions/feedback';
import { clearSession } from 'actions/session';
import PageContainer from 'components/Pages/ThankYou/PageContainer';
import {
  ContentContainer,
  ContentInner,
  StyledH1,
  MessageContainer,
  CompleteIconContainer,
  ButtonsContainer,
} from 'components/Pages/ThankYou/styles';
import thankYouMessages from 'components/Pages/ThankYou/messages';
import dineInMessages from 'components/dineInMessages';
import globalMessages from 'components/globalMessages';
import { YesIcon } from 'components/Icons';
import ActionButton from 'components/ActionButton';
import FeedbackButton from 'components/Feedback/FeedbackButton';

export const TabPaid = ({ fetchQuestions, serviceId, clearSession }) => {
  useEffect(() => {
    clearSession();
  }, []);

  useEffect(() => {
    if (serviceId) fetchQuestions(serviceId);
  }, [fetchQuestions, serviceId]);

  return (
    <PageContainer>
      <ContentContainer>
        <ContentInner>
          <StyledH1>
            <FormattedMessage {...thankYouMessages.thankYou} />
            &nbsp;
            <FormattedMessage {...dineInMessages.tabClosed} />
          </StyledH1>
          <MessageContainer>
            <FormattedMessage {...dineInMessages.joinAgain} />
          </MessageContainer>
          <CompleteIconContainer>
            <YesIcon width="85px" height="85px" />
          </CompleteIconContainer>
          <ButtonsContainer>
            <ActionButton
              to={serviceId ? `/service/${serviceId}` : '/'}
              primaryButton={true}
              label={<FormattedMessage {...globalMessages.returnToMenu} />}
            />
            <FeedbackButton serviceId={serviceId} trackingLabel="Tab thankyou page" />
          </ButtonsContainer>
        </ContentInner>
      </ContentContainer>
    </PageContainer>
  );
};

TabPaid.propTypes = {
  fetchQuestions: PropTypes.func,
  clearSession: PropTypes.func,
  serviceId: PropTypes.string,
};

export default connect(
  state => ({
    serviceId: selectServiceId(state),
  }),
  { fetchQuestions, clearSession }
)(TabPaid);
