import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import TimerIcon from 'components/Icons/TimerIcon';
import NextAvailableMessage from 'components/NextAvailableMessage';

import { StyledMessage, StyledTimer, MessageWrapper, MessageHeader } from './styles';

const messages = defineMessages({
  header: {
    defaultMessage: 'Currently Unavailable',
  },
});

const OutOfServiceMessage = ({ nextAvailableTime }) => {
  if (!nextAvailableTime || !nextAvailableTime.time) return null;
  return (
    <StyledMessage>
      <StyledTimer>
        <TimerIcon fill="#bbbbbb" width="21px" height="21px" />
      </StyledTimer>
      <MessageWrapper>
        <MessageHeader>
          {' '}
          <FormattedMessage {...messages.header} />{' '}
        </MessageHeader>
        <NextAvailableMessage nextAvailableTime={nextAvailableTime} />
      </MessageWrapper>
    </StyledMessage>
  );
};

OutOfServiceMessage.propTypes = {
  nextAvailableTime: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      time: PropTypes.number,
    }),
  ]),
};

export default OutOfServiceMessage;
