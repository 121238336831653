import React from 'react';

import OrderReview from 'components/OrderReview';
import OrderCrossSells from 'components/OrderReview/OrderCrossSells';

import { OrderContainer } from './styles';

const Order = () => (
  <div>
    <OrderCrossSells />
    <OrderContainer>
      <OrderReview />
    </OrderContainer>
  </div>
);

export default Order;
