import React from 'react';
import PropTypes from 'prop-types';

import CheckboxInput from './CheckboxInput';
import { StyledCheckbox, StyledLabel } from './styles';

const propTypes = {
  children: PropTypes.node,
};

const Checkbox = ({ children, ...inputProps }) => (
  <StyledCheckbox>
    <StyledLabel>
      <CheckboxInput {...inputProps} type="checkbox" />
      {children}
    </StyledLabel>
  </StyledCheckbox>
);

Checkbox.propTypes = propTypes;

export default Checkbox;
