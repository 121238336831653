import PropTypes from 'prop-types';
import React from 'react';
import MinMaxMessage from '../OptionModifier/MinMaxMessage';
import { Label } from '../OptionModifier/styles';

const OptionLabel = ({ name, browseOnly, required, hasError, minimum, maximum }) => (
  <Label required={browseOnly ? false : required} error={hasError}>
    {name}
    &nbsp;
    <MinMaxMessage minimum={minimum} maximum={maximum} required={required} />
  </Label>
);

OptionLabel.propTypes = {
  name: PropTypes.string,
  browseOnly: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
};

export default OptionLabel;
