import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';

import { useTheme } from 'styled-components';
import Page from 'components/Pages/container';
import LanguageIcon from 'components/Icons/LanguageIcon';
import { setLocale } from 'actions/user';
import { getLocales } from 'selectors/root';
import { getLocale } from 'selectors/user';

import {
  LanguagesList,
  LanguageItem,
  LanguageItemSelected,
  LanguageLabelButton,
  StyledTickIcon,
} from './styles';
import { trackEvent } from '../../../utils/tracking';

const messages = defineMessages({
  title: {
    defaultMessage: 'Language',
  },
  error_msg: {
    defaultMessage: 'We are sorry, there seems to be a problem loading the languages page.',
  },
});

const propTypes = {
  languages: PropTypes.instanceOf(List),
  userLocale: PropTypes.string,
  setLocale: PropTypes.func,
};

const Language = ({ languages, userLocale, setLocale }) => {
  const theme = useTheme();
  return (
    <Page settingsPage={true} titleMessage={messages.title} Icon={LanguageIcon}>
      {languages?.size && (
        <LanguagesList>
          {languages.map(lang => {
            const code = lang.get('code');
            const Item = userLocale === code ? LanguageItemSelected : LanguageItem;

            return (
              <Item key={code}>
                {userLocale === code && (
                  <StyledTickIcon width="20px" height="20px" fill={theme.color.success} />
                )}
                <LanguageLabelButton
                  onClick={() => {
                    trackEvent('select_language', {
                      category: 'settings',
                      language_selected: code,
                    });
                    setLocale(code);
                  }}
                  type="button"
                >
                  {lang.get('label')}
                </LanguageLabelButton>
              </Item>
            );
          })}
        </LanguagesList>
      )}
    </Page>
  );
};

Language.propTypes = propTypes;
export default connect(
  state => ({
    languages: getLocales(state),
    userLocale: getLocale(state),
  }),
  { setLocale }
)(Language);
