import styled from 'styled-components';

export const CompanyLogo = styled.img`
  clear: both;
  max-height: 180px;
  max-width: 180px;
  margin: 48px auto;

  @media only screen and (min-device-width: 375px) and (orientation: portrait) {
    max-height: 220px;
    max-width: 220px;
  }
`;
