import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageTitle from 'components/PageTitle';
import AddToHome from 'components/AddToHome';
import KioskExitButton from 'components/KioskExitButton';
import { StyledMenuButton } from 'components/MenuButton';
import { showExitButton } from 'selectors/features';

import { Container, InnerSection } from './styles';
import messages from './messages';

const PageContainer = ({ children, showExitButton }) => (
  <Container className="fullWrapper">
    <PageTitle message={messages.thankYou} />
    <InnerSection>
      {showExitButton && <KioskExitButton withBackground={true} />}
      <StyledMenuButton customCssClass="menuBarColor" />
      <AddToHome />

      <div className="u-textCenter container">{children}</div>
    </InnerSection>
  </Container>
);

PageContainer.propTypes = {
  showExitButton: PropTypes.bool,
  children: PropTypes.node,
};

export default connect(state => ({
  showExitButton: showExitButton(state),
}))(PageContainer);
