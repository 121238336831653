import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import Page from 'components/Pages/container';
import InfoBox from 'components/InfoBox';
import MessageBlock from 'components/MessageBlock';
import ResetPasswordIcon from 'components/Icons/ResetPasswordIcon';
import { initiateResetPassword, validateResetToken } from 'actions/user';
import {
  selectTokenEmail,
  selectIsTokenValid,
  selectRequestingPasswordReset,
  selectPasswordResetSuccessful,
  selectPasswordResetViolations,
} from 'selectors/user';
import globalMessages from 'components/globalMessages';
import Form from 'components/Form';
import fieldTypes from 'components/Form/fieldTypes';

const messages = defineMessages({
  resetPassword: {
    defaultMessage: 'Reset Password',
  },
  success: {
    defaultMessage: 'Success',
  },
  passwordWasReset: {
    defaultMessage: 'Your password was reset successfully.',
  },
  password_error: {
    defaultMessage: 'Passwords do not match!',
  },
  resetPasswordError: {
    defaultMessage: 'Unable to reset your password.',
  },
  password: {
    defaultMessage: 'Password',
  },
  new_password: {
    defaultMessage: 'New Password',
  },
  retype_password: {
    defaultMessage: 'Confirm New Password',
  },
  processing: {
    defaultMessage: 'Processing',
  },
  checkEmail: {
    defaultMessage:
      'Your reset request was successfully sent. Please check your inbox for "{ passwordResetEmail }" for password reset instructions.',
  },
  reset_intro: {
    defaultMessage: 'You have requested to reset the password for: { passwordResetEmail }',
  },
  linkExpired: {
    defaultMessage: 'Link Expired!',
  },
  invalidResetTokenMessage: {
    defaultMessage:
      'Sorry! We are unable to reset your password. This link has expired. Please try resetting your password again.',
  },
});

class ResetPassword extends React.Component {
  static fetchData(store, props, session) {
    return store.dispatch(validateResetToken(session.passwordResetToken));
  }

  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }),
    passwordResetEmail: PropTypes.string,
    violations: PropTypes.instanceOf(List),
    isTokenValid: PropTypes.bool.isRequired,
    passwordResetSuccessful: PropTypes.bool,
    initiateResetPassword: PropTypes.func.isRequired,
    requestingPasswordReset: PropTypes.bool.isRequired,
  };

  onSubmit = (values, callback) => {
    const { newPassword } = values;

    this.props.initiateResetPassword(newPassword, res => {
      callback(res);
    });
  };

  render() {
    const { violations, passwordResetEmail, isTokenValid, passwordResetSuccessful } = this.props;

    return (
      <Page large={true} settingsPage={true} titleMessage={messages.resetPassword} Icon={ResetPasswordIcon}>
        {isTokenValid && (
          <span>
            <InfoBox
              title={<FormattedMessage {...messages.resetPassword} />}
              message={
                <FormattedMessage
                  {...messages.reset_intro}
                  values={{
                    passwordResetEmail: <strong>{passwordResetEmail || '--'}</strong>,
                  }}
                />
              }
            />
            {passwordResetSuccessful === false && (
              <MessageBlock
                header={<FormattedMessage {...globalMessages.error} />}
                body={
                  <div>
                    <p>
                      <FormattedMessage {...messages.resetPasswordError} />
                    </p>
                    {violations && (
                      <ul>
                        {violations.map((errorMsg, index) => (
                          <li key={index}>{errorMsg}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                }
                type="error"
              />
            )}
            <Form
              noButtonIcon={true}
              fields={[{ ...fieldTypes.newPassword }, { ...fieldTypes.confirmPassword }]}
              onSubmit={this.onSubmit}
              submitButtonLabel={<FormattedMessage {...messages.resetPassword} />}
              submitButtonLoadingLabel={<FormattedMessage {...messages.processing} />}
              successHeader={<FormattedMessage {...messages.success} />}
              successMessage={<FormattedMessage {...messages.passwordWasReset} />}
            />
          </span>
        )}

        {!isTokenValid && (
          <div>
            <MessageBlock
              header={<FormattedMessage {...messages.linkExpired} />}
              body={<FormattedMessage {...messages.invalidResetTokenMessage} />}
              type="error"
            />
          </div>
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  isTokenValid: selectIsTokenValid(state),
  passwordResetEmail: selectTokenEmail(state),
  violations: selectPasswordResetViolations(state),
  requestingPasswordReset: selectRequestingPasswordReset(state),
  passwordResetSuccessful: selectPasswordResetSuccessful(state),
});

const IntlResetPassword = injectIntl(ResetPassword);

export default connect(mapStateToProps, { initiateResetPassword })(IntlResetPassword);
