import React from 'react';

interface RewardIconProps {
  className?: string;
  width: string;
  height: string;
  fill: string;
}

const RewardIcon: React.FC<RewardIconProps> = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 33 33"
    fill={fill}
  >
    <g clipPath="url(#clip0_73_6934)">
      <path
        d="M32.6563 10.0969C32.6563 8.98339 31.7536 8.08059 30.6401 8.08059H27.1983C28.089 7.202 28.6591 6.05022 28.6591 4.63173C28.6591 2.88512 27.656 0.987793 24.8402 0.987793C21.1353 0.987793 18.0403 4.93266 16.586 7.14403C15.1308 4.93317 11.941 0.988297 8.2366 0.988297C5.42085 0.988297 4.41774 2.88562 4.41774 4.63224C4.41774 6.05019 5.00348 7.2025 5.91232 8.0811H2.41204C1.29855 8.0811 0.395752 8.98389 0.395752 10.0974V16.1326H2.42968V29.2562C2.42968 30.3697 3.33244 31.2725 4.44597 31.2725H28.6314C29.7448 31.2725 30.6476 30.3697 30.6476 29.2562V16.1321H32.6564V10.0969H32.6563ZM24.8402 3.00459C26.0868 3.00459 26.6428 3.52835 26.6428 4.63227C26.6428 6.87186 24.1431 8.08009 21.7921 8.08009H18.379C19.8116 5.9524 22.333 3.00459 24.8402 3.00459ZM8.23656 3.00462C10.7443 3.00462 13.3609 5.95243 14.7935 8.07962H11.3794C9.02843 8.07962 6.43397 6.8401 6.43397 4.60051C6.43397 3.4971 6.98996 3.00462 8.23656 3.00462ZM30.6401 14.1159H17.5342V10.0969H30.6401V14.1159ZM2.41201 10.0969H15.5179V14.1159H2.41201V10.0969ZM4.44594 16.1321H15.5179V29.2557H4.44594V16.1321ZM28.6318 29.2557H17.5342V16.1321H28.6318V29.2557Z"
        fill="#949494"
      />
    </g>
    <defs>
      <clipPath id="clip0_73_6934">
        <rect width="32.2606" height="32.2606" fill="white" transform="translate(0.395752)" />
      </clipPath>
    </defs>
  </svg>
);

export default RewardIcon;
