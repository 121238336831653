import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const ButtonContainer = ({ children, containerClass }) => (
  <Container className="container" containerClass={containerClass}>
    {children}
  </Container>
);

ButtonContainer.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.bool,
};

export default ButtonContainer;
