import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { hasFavourite } from 'selectors/user';
import { kioskModeEnabled } from 'selectors/features';
import { InFavouritesThemedIcon, FavouriteThemedIcon } from 'components/Icons';
import { addFavourite, removeFavourite } from 'actions/user';
import AdditionalButton from '../ProductAdditionalButton';

const messages = defineMessages({
  addFavourite: {
    defaultMessage: 'Add Favourite',
  },
  removeFavourite: {
    defaultMessage: 'Remove Favourite',
  },
  favourite: {
    defaultMessage: 'Favourite',
  },
});

const FavouriteButton = ({ isFavourite, addFavourite, removeFavourite, isDisabled, kioskMode, smallBtn }) => {
  if (kioskMode) return null;

  const FavouriteIcon = isFavourite ? InFavouritesThemedIcon : FavouriteThemedIcon;
  const onFavourite = isFavourite ? removeFavourite : addFavourite;
  const buttonMessage = isFavourite ? messages.removeFavourite : messages.addFavourite;

  return (
    <AdditionalButton smallBtn={smallBtn} onClick={isDisabled ? () => null : onFavourite}>
      <FavouriteIcon width="22px" height="22px" />

      <span>
        {smallBtn ? <FormattedMessage {...messages.favourite} /> : <FormattedMessage {...buttonMessage} />}
      </span>
    </AdditionalButton>
  );
};

FavouriteButton.propTypes = {
  product: PropTypes.instanceOf(Map).isRequired,
  isFavourite: PropTypes.bool,
  addFavourite: PropTypes.func,
  removeFavourite: PropTypes.func,
  isDisabled: PropTypes.bool,
  kioskMode: PropTypes.bool,
  smallBtn: PropTypes.bool,
};

const mapStateToProps = (state, { product }) => ({
  isFavourite: hasFavourite(state, product.get('id')),
  kioskMode: kioskModeEnabled(state),
});

const mapDispatchToProps = (dispatch, { product }) => ({
  addFavourite: () => dispatch(addFavourite(product)),
  removeFavourite: () => dispatch(removeFavourite(product.get('id'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteButton);
