import React from 'react';
import PropTypes from 'prop-types';

const InFavouritesIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    style={{ borderColor: fill }}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon inFavouritesIcon"
        fill={fill}
        d="M118.477,221.836c-0.469-0.117-0.938-0.293-1.406-0.527c-0.313-0.156-2.071-1.191-5.273-3.105	c-3.204-1.914-7.364-4.59-12.48-8.027c-5.118-3.438-10.918-7.617-17.402-12.539c-6.485-4.922-13.125-10.43-19.922-16.523	c-4.063-3.672-7.892-7.344-11.484-11.016c-3.594-3.672-6.993-7.344-10.195-11.016c-3.204-3.672-6.192-7.363-8.965-11.074	c-2.774-3.711-5.293-7.402-7.559-11.074c-2.97-4.688-5.566-9.395-7.793-14.121s-4.083-9.414-5.566-14.063	c-1.485-4.647-2.598-9.297-3.34-13.945c-0.743-4.647-1.113-9.238-1.113-13.77c0-8.672,1.659-16.835,4.98-24.492	c3.32-7.656,7.832-14.336,13.535-20.039c5.702-5.702,12.363-10.214,19.98-13.535c7.617-3.32,15.8-4.98,24.551-4.98	c2.89,0,5.839,0.274,8.848,0.82c3.007,0.547,5.995,1.348,8.965,2.402c2.968,1.055,5.918,2.344,8.848,3.867s5.761,3.301,8.496,5.332	c3.203,2.344,6.132,4.903,8.789,7.676c2.655,2.773,4.999,5.645,7.031,8.613c1.952-2.969,4.277-5.84,6.973-8.613	c2.695-2.772,5.644-5.332,8.848-7.676c2.733-2.031,5.546-3.809,8.438-5.332c2.89-1.523,5.819-2.813,8.789-3.867	c2.968-1.055,5.956-1.855,8.965-2.402c3.007-0.546,5.995-0.82,8.965-0.82c8.672,0,16.835,1.66,24.492,4.98 c7.655,3.321,14.335,7.833,20.039,13.535c5.702,5.703,10.214,12.383,13.535,20.039c3.319,7.657,4.98,15.82,4.98,24.492 c0,4.531-0.392,9.122-1.172,13.77c-0.782,4.648-1.915,9.298-3.398,13.945c-1.485,4.648-3.321,9.336-5.508,14.063 c-2.188,4.727-4.767,9.434-7.734,14.121c-2.344,3.672-4.884,7.363-7.617,11.074c-2.735,3.711-5.704,7.402-8.906,11.074 c-3.204,3.672-6.621,7.344-10.254,11.016s-7.441,7.344-11.426,11.016c-6.797,6.094-13.438,11.602-19.922,16.523 c-6.485,4.922-12.286,9.102-17.402,12.539c-5.118,3.438-9.298,6.113-12.539,8.027c-3.243,1.914-4.98,2.949-5.215,3.105 c-0.469,0.234-0.958,0.41-1.465,0.527c-0.509,0.117-0.996,0.176-1.465,0.176C119.452,222.012,118.945,221.953,118.477,221.836z"
      />
    </g>
  </svg>
);

InFavouritesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

InFavouritesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default InFavouritesIcon;
