import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 55 55"
  >
    <g>
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M47.679 51.2H7.321C4.3885 51.2 2 48.8043 2 45.8581V9.61067C2 6.66453 4.3885 4.26667 7.32313 4.26667H47.6769C50.6115 4.26667 53 6.66453 53 9.61067V45.8581C53 48.8043 50.6136 51.2 47.679 51.2V51.2ZM7.32313 8.53333C6.73238 8.53333 6.25 9.0176 6.25 9.61067V45.8581C6.25 46.4512 6.73025 46.9333 7.321 46.9333H47.679C48.2698 46.9333 48.75 46.4512 48.75 45.8581V9.61067C48.75 9.0176 48.2676 8.53333 47.6769 8.53333H7.32313V8.53333ZM50.875 17.0667H4.125C2.952 17.0667 2 16.1109 2 14.9333C2 13.7557 2.952 12.8 4.125 12.8H50.875C52.0501 12.8 53 13.7557 53 14.9333C53 16.1109 52.0501 17.0667 50.875 17.0667ZM12.625 10.6667C11.452 10.6667 10.5 9.71093 10.5 8.53333V2.13333C10.5 0.955733 11.452 0 12.625 0C13.798 0 14.75 0.955733 14.75 2.13333V8.53333C14.75 9.71093 13.798 10.6667 12.625 10.6667ZM42.375 10.6667C41.1999 10.6667 40.25 9.71093 40.25 8.53333V2.13333C40.25 0.955733 41.1999 0 42.375 0C43.5501 0 44.5 0.955733 44.5 2.13333V8.53333C44.5 9.71093 43.5501 10.6667 42.375 10.6667Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M14.75 42.6666C15.9236 42.6666 16.875 41.7114 16.875 40.5332C16.875 39.355 15.9236 38.3999 14.75 38.3999C13.5764 38.3999 12.625 39.355 12.625 40.5332C12.625 41.7114 13.5764 42.6666 14.75 42.6666Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M14.75 34.1334C15.9236 34.1334 16.875 33.1782 16.875 32C16.875 30.8218 15.9236 29.8667 14.75 29.8667C13.5764 29.8667 12.625 30.8218 12.625 32C12.625 33.1782 13.5764 34.1334 14.75 34.1334Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M21.125 42.6666C22.2986 42.6666 23.25 41.7114 23.25 40.5332C23.25 39.355 22.2986 38.3999 21.125 38.3999C19.9514 38.3999 19 39.355 19 40.5332C19 41.7114 19.9514 42.6666 21.125 42.6666Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M21.125 34.1334C22.2986 34.1334 23.25 33.1782 23.25 32C23.25 30.8218 22.2986 29.8667 21.125 29.8667C19.9514 29.8667 19 30.8218 19 32C19 33.1782 19.9514 34.1334 21.125 34.1334Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M21.125 25.6002C22.2986 25.6002 23.25 24.645 23.25 23.4668C23.25 22.2886 22.2986 21.3335 21.125 21.3335C19.9514 21.3335 19 22.2886 19 23.4668C19 24.645 19.9514 25.6002 21.125 25.6002Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M33.875 42.6666C35.0486 42.6666 36 41.7114 36 40.5332C36 39.355 35.0486 38.3999 33.875 38.3999C32.7014 38.3999 31.75 39.355 31.75 40.5332C31.75 41.7114 32.7014 42.6666 33.875 42.6666Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M33.875 34.1334C35.0486 34.1334 36 33.1782 36 32C36 30.8218 35.0486 29.8667 33.875 29.8667C32.7014 29.8667 31.75 30.8218 31.75 32C31.75 33.1782 32.7014 34.1334 33.875 34.1334Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M33.875 25.6002C35.0486 25.6002 36 24.645 36 23.4668C36 22.2886 35.0486 21.3335 33.875 21.3335C32.7014 21.3335 31.75 22.2886 31.75 23.4668C31.75 24.645 32.7014 25.6002 33.875 25.6002Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M40.25 34.1334C41.4236 34.1334 42.375 33.1782 42.375 32C42.375 30.8218 41.4236 29.8667 40.25 29.8667C39.0764 29.8667 38.125 30.8218 38.125 32C38.125 33.1782 39.0764 34.1334 40.25 34.1334Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M40.25 25.6002C41.4236 25.6002 42.375 24.645 42.375 23.4668C42.375 22.2886 41.4236 21.3335 40.25 21.3335C39.0764 21.3335 38.125 22.2886 38.125 23.4668C38.125 24.645 39.0764 25.6002 40.25 25.6002Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M27.5 42.6666C28.6736 42.6666 29.625 41.7114 29.625 40.5332C29.625 39.355 28.6736 38.3999 27.5 38.3999C26.3264 38.3999 25.375 39.355 25.375 40.5332C25.375 41.7114 26.3264 42.6666 27.5 42.6666Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M27.5 34.1334C28.6736 34.1334 29.625 33.1782 29.625 32C29.625 30.8218 28.6736 29.8667 27.5 29.8667C26.3264 29.8667 25.375 30.8218 25.375 32C25.375 33.1782 26.3264 34.1334 27.5 34.1334Z"
      />
      <path
        className="icon calendarIcon"
        fill={fill}
        opacity={opacity}
        d="M27.5 25.6002C28.6736 25.6002 29.625 24.645 29.625 23.4668C29.625 22.2886 28.6736 21.3335 27.5 21.3335C26.3264 21.3335 25.375 22.2886 25.375 23.4668C25.375 24.645 26.3264 25.6002 27.5 25.6002Z"
      />
    </g>
  </svg>
);

CalendarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

CalendarIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default CalendarIcon;
