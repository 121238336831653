import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectCompletedOrderNumber } from 'reducers/root';
import { selectAllowCallTheWaiter } from 'selectors/root';
import AlertIcon from 'components/Icons/AlertIcon';
import globalMessages from 'components/globalMessages';
import messages from './messages';
import { AlertIconDiv, Title, Content, OrderNumber, LinkStyled } from './styles';

const OrderError = ({ allowCallTheWaiter, orderNumber, title, message }) => (
  <div>
    <AlertIconDiv>
      <AlertIcon width="120px" height="120px" />
    </AlertIconDiv>

    <Title>{title}</Title>

    <Content>{message}</Content>

    <OrderNumber>
      <FormattedMessage {...messages.orderNumber} />: {`#${orderNumber}`}
    </OrderNumber>

    {allowCallTheWaiter ? (
      <LinkStyled to="/call-a-waiter">
        <FormattedMessage {...messages.contactWaiter} />
      </LinkStyled>
    ) : (
      <LinkStyled to="/contact">
        <FormattedMessage {...messages.contactUs} />
      </LinkStyled>
    )}
    <LinkStyled to="/">
      <FormattedMessage {...globalMessages.returnToMenu} />
    </LinkStyled>
  </div>
);

OrderError.defaultProps = {
  allowCallTheWaiter: false,
};

OrderError.propTypes = {
  allowCallTheWaiter: PropTypes.bool,
  orderNumber: PropTypes.string,
  title: PropTypes.node,
  message: PropTypes.node,
};

export default connect(state => ({
  allowCallTheWaiter: selectAllowCallTheWaiter(state),
  orderNumber: selectCompletedOrderNumber(state),
}))(OrderError);
