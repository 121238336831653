import styled, { css } from 'styled-components';

export const FilterGroupStyled = styled.div`
  margin-bottom: 10px;
`;

export const GroupTitleStyled = styled.span`
  display: block;
  margin-bottom: 5px;
  text-align: left;
  color: ${({ theme }) => theme.color.primaryText};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const StyledText = styled.p`
  font-style: italic;
  margin: 20px 0;
`;

export const DietaryCheckboxLabel = styled.label`
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  position: relative;
  width: 49%;
  margin-bottom: 10px;

  &:lang(ru) {
    font-size: 12px;
  }
`;

export const Container = styled.span`
  display: flex;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
`;

export const InputStyled = styled.input`
  display: none;
`;

export const Text = styled.span`
  padding: 0 6px;
`;

export const AllergenIconContainer = styled.span`
  transition: all 0.3s ease;
  margin-bottom: 15px;

  ${props =>
    props.checked &&
    css`
      color: ${({ theme }) => theme.color.primaryText};
      svg .icon {
        fill: ${({ theme }) => theme.color.success};
      }
    `}
`;
