import React from 'react';
import PropTypes from 'prop-types';

const VisaCardIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 72 45.75"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999">
        <rect stroke="none" width="70" height="43.75" rx="4" />
        <rect x="-0.5" y="-0.5" width="71" height="44.75" rx="4.5" />
      </g>
      <g transform="translate(14.046 15.078)">
        <g transform="translate(0 0)">
          <path
            fill="#0066b2"
            d="M253.322,169.213H249.9l2.137-13.134h3.42Z"
            transform="translate(-235.155 -155.844)"
          />
          <path
            fill="#0066b2"
            d="M235.348,156.079l-3.26,9.034-.386-1.945h0l-1.151-5.906a1.463,1.463,0,0,0-1.622-1.183H223.54l-.063.222a12.788,12.788,0,0,1,3.578,1.5l2.97,11.411h3.563l5.441-13.134Z"
            transform="translate(-223.477 -155.844)"
          />
          <path
            fill="#0066b2"
            d="M285.8,169.213h3.14L286.2,156.079H283.45a1.572,1.572,0,0,0-1.579.978l-5.1,12.156h3.564l.713-1.951H285.4Zm-3.763-4.645,1.8-4.916,1.011,4.916Z"
            transform="translate(-247.029 -155.844)"
          />
          <path
            fill="#0066b2"
            d="M269.541,159.051l.488-2.821a9.869,9.869,0,0,0-3.075-.573c-1.7,0-5.727.742-5.727,4.348,0,3.394,4.73,3.436,4.73,5.217s-4.243,1.464-5.643.34l-.508,2.948a9.574,9.574,0,0,0,3.86.742c2.333,0,5.854-1.209,5.854-4.5,0-3.415-4.773-3.734-4.773-5.218S268.078,158.245,269.541,159.051Z"
            transform="translate(-239.531 -155.658)"
          />
        </g>
        <path
          fill="#f9a533"
          d="M231.7,163.168l-1.151-5.906a1.463,1.463,0,0,0-1.622-1.183H223.54l-.063.222a13.263,13.263,0,0,1,5.076,2.548A10.236,10.236,0,0,1,231.7,163.168Z"
          transform="translate(-223.477 -155.844)"
        />
      </g>
    </g>
  </svg>
);

VisaCardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

VisaCardIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default VisaCardIcon;
