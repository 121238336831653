import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import { shouldShowGeolocation } from 'selectors/features';
import GeoButton from 'components/GeoButton';
import CloseIcon from 'components/Icons/CloseIcon';

import {
  Container,
  SearchBarContainer,
  StyledSearchIcon,
  SearchBarStyled,
  ClearButtonStyled,
  GeoButtonContainer,
} from './styles';

const messages = defineMessages({
  search: {
    defaultMessage: 'Search....',
  },
});

const VenueSearch = ({ showGeolocation, searchTerm, onChange, intl }) => {
  if (!showGeolocation) return null;

  return (
    <Container className="u-flex u-flexJustifyBetween">
      <SearchBarContainer>
        <StyledSearchIcon width="24px" height="24px" />
        <SearchBarStyled
          value={searchTerm}
          onChange={e => onChange(e.target.value)}
          placeholder={intl.formatMessage(messages.search)}
        />
        {searchTerm && (
          <ClearButtonStyled type="button" onClick={() => onChange('')}>
            <CloseIcon width="24px" height="24px" />
          </ClearButtonStyled>
        )}
      </SearchBarContainer>

      <GeoButtonContainer>
        <GeoButton />
      </GeoButtonContainer>
    </Container>
  );
};

VenueSearch.propTypes = {
  showGeolocation: PropTypes.bool,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default connect(state => ({
  showGeolocation: shouldShowGeolocation(state),
}))(injectIntl(VenueSearch));
