import styled, { css } from 'styled-components';

export const ExitButtonContainer = styled.div`
  width: auto;
  display: none;
  float: left;
  margin: 4px;

  button {
    min-height: 40px;
    padding: 4px 10px;
    border: none;
    color: inherit;
  }

  @media (min-width: 425px) {
    display: inline-block;
  }
  ${props =>
    props.withBackground &&
    css`
      position: absolute;
      left: 0;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      z-index: 1;
    `}
`;
