import styled from 'styled-components';
import { Link } from 'react-router';

export const BackLinkStyled = styled(Link)`
  height: 30px;
  position: absolute;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.two};
  margin-top: 7px;
`;

export const Label = styled.span`
  font-size: 0.714em;
  line-height: 1.1;
  text-transform: uppercase;
  padding: 0 5px;
  display: none;

  &:lang(ru) {
    font-size: 0.65em;
  }
`;
