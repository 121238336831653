import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isDarkModeEnabled } from 'selectors/features';

import globalMessages from 'components/globalMessages';

import { LoadingContainer, LoadingBarsContainer, AnimatedBars, Bar } from './styles';

const LoadingBars = () => (
  <AnimatedBars>
    <Bar className="primaryBackground" />
    <Bar delayTime={-1.1} className="primaryBackground" />
    <Bar delayTime={-1} className="primaryBackground" />
    <Bar delayTime={-0.9} className="primaryBackground" />
    <Bar delayTime={-0.8} className="primaryBackground" />
  </AnimatedBars>
);

const Loading = ({ children, className, darkMode }) => (
  <LoadingContainer className={className}>
    <LoadingBarsContainer darkMode={darkMode}>
      <LoadingBars /> {children || <FormattedMessage {...globalMessages.loading} />}
    </LoadingBarsContainer>
  </LoadingContainer>
);

Loading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
  darkMode: PropTypes.bool,
};

export default connect(state => ({
  darkMode: isDarkModeEnabled(state),
}))(Loading);
