import { fromJS } from 'immutable';
import * as c from 'actions/feedback/constants';

export const initialState = fromJS({
  questions: [],
  questionsLoading: false,
  answersLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case c.REQUEST_FEEDBACK_QUESTIONS:
      return state.merge(
        fromJS({
          questionsLoading: true,
          questions: [],
        })
      );
    case c.REQUEST_FEEDBACK_QUESTIONS_SUCCESS:
      return state.merge(
        fromJS({
          questionsLoading: false,
          questions: action.questions,
        })
      );
    case c.REQUEST_FEEDBACK_QUESTIONS_FAILURE:
      return state.merge(
        fromJS({
          questionsLoading: false,
          questions: [],
        })
      );
    case c.SUBMIT_FEEDBACK_ANSWERS:
      return state.set('answersLoading', true);
    case c.FEEDBACK_SUBMISSION_SUCCESS:
      return state.set('answersLoading', false);
    case c.FEEDBACK_SUBMISSION_FAILURE:
      return state.set('answersLoading', false);
    default:
      return state;
  }
};
