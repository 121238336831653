/* eslint-disable camelcase */
import { fromJS, Map } from 'immutable';
import rtlDetect from 'rtl-detect';

import * as c from 'actions/user/constants';
import userPassword from './password';

const userReducer = (state = new Map(), action) =>
  state.merge(
    fromJS({
      login: userLogin(state.get('login'), action),
      register: userRegister(state.get('register'), action),
      password: userPassword(state.get('password'), action),
      data: userData(state.get('data'), action),
      orders: userOrders(state.get('orders'), action),
      locale: action.type === c.SET_LOCALE ? action.locale : state.get('locale'),
      localeDir:
        action.type === c.SET_LOCALE
          ? rtlDetect.getLangDir(action.locale.split('_')[0])
          : state.get('localeDir'),
      userStatus: userStatus(state.get('userStatus'), action),
      userGeoLocation: userGeoLocation(state.get('userGeoLocation'), action),
    })
  );

export const loginInitialState = fromJS({
  loading: false,
  requestFailed: undefined,
});
export const userLogin = (state = loginInitialState, action) => {
  switch (action.type) {
    case c.LOGIN_REQUEST_START:
      return state.merge(
        fromJS({
          loading: true,
          requestFailed: undefined,
        })
      );
    case c.LOGIN_REQUEST_SUCCESSFUL:
      return state.merge(
        fromJS({
          loading: false,
          requestFailed: false,
        })
      );
    case c.LOGIN_REQUEST_FAILED:
      return state.merge(
        fromJS({
          loading: false,
          requestFailed: true,
        })
      );
    case c.LOGIN_CLEAR_STATE:
      return loginInitialState;
    default:
      return state;
  }
};

export const registerInitialState = fromJS({
  loading: false,
  requestFailed: undefined,
  validation: undefined,
});
export const userRegister = (state = registerInitialState, action) => {
  switch (action.type) {
    case c.REGISTER_REQUEST_START:
      return state.merge(
        fromJS({
          loading: true,
          requestFailed: undefined,
          validation: undefined,
        })
      );
    case c.REGISTER_REQUEST_SUCCESSFUL:
      return state.merge(
        fromJS({
          loading: false,
          requestFailed: false,
          validation: undefined,
        })
      );
    case c.REGISTER_REQUEST_FAILED:
      return state.merge(
        fromJS({
          loading: false,
          requestFailed: true,
          validation: action.validation,
        })
      );
    case c.REGISTER_CLEAR_STATE:
      return registerInitialState;
    default:
      return state;
  }
};

export const userData = (state = new Map(), action) => {
  switch (action.type) {
    case c.LOGIN:
    case c.SET_USER:
      return fromJS(action.user);

    case c.UPDATE_USER:
      return state.mergeDeep(fromJS(action.userDetails));

    case c.ADD_FAVOURITE: {
      const favourites = state.get('favourites');
      if (favourites) {
        return state.set('favourites', favourites.push(action.product.get('id')));
      }
      return state;
    }

    case c.REMOVE_FAVOURITE: {
      const index = state.get('favourites').findKey(product => product === action.productId);
      if (index !== undefined) {
        return state.set('favourites', state.get('favourites').delete(index));
      }
      return state;
    }

    case c.SET_PAYMENT_SOURCES: {
      return state.set('paymentSources', fromJS(action.paymentSources));
    }

    case c.DELETE_PAYMENT_SOURCE: {
      const index = state
        .get('paymentSources')
        .findIndex(paymentSource => paymentSource.get('id') === action.paymentSourceId);

      return state.deleteIn(['paymentSources', index]);
    }

    case c.LOGOUT:
      return new Map();

    case c.REQUEST_ADDRESSES_START:
      return state.setIn(['addresses', 'loadingAddresses'], true);
    case c.REQUEST_ADDRESSES_FAILURE:
      return state.mergeIn(
        ['addresses'],
        fromJS({
          loadingAddresses: false,
          errorLoadingAddresses: true,
        })
      );
    case c.REQUEST_ADDRESSES_SUCCESS: {
      const { addressesList } = action;

      return state.mergeIn(
        ['addresses'],
        fromJS({
          loadingAddresses: false,
          errorLoadingAddresses: false,
          addressesList,
          defaultAddressId:
            addressesList.length > 0 ? addressesList.find(address => address.default)?.id : '',
        })
      );
    }

    case c.ADD_ADDRESS_SUCCESS: {
      const { newAddress } = action;
      const hasAddressesList = state.getIn(['addresses', 'addressesList'], false);

      if (!hasAddressesList) return state;

      return state
        .updateIn(['addresses', 'addressesList'], addressesList => addressesList.push(fromJS(newAddress)))
        .updateIn(['addresses', 'defaultAddressId'], defaultAddressId =>
          newAddress.default ? newAddress.id : defaultAddressId
        );
    }

    case c.EDIT_ADDRESS_SUCCESS: {
      const { editedAddress } = action;
      const index = state
        .getIn(['addresses', 'addressesList'])
        .findIndex(addr => addr.get('id') === editedAddress.id);

      return state
        .setIn(['addresses', 'addressesList', index], fromJS(editedAddress))
        .updateIn(['addresses', 'defaultAddressId'], defaultAddressId =>
          editedAddress.default ? editedAddress.id : defaultAddressId
        );
    }

    case c.DELETE_ADDRESS_SUCCESS: {
      const index = state
        .getIn(['addresses', 'addressesList'])
        .findIndex(addr => addr.get('id') === action.addressId);

      return state.deleteIn(['addresses', 'addressesList', index]);
    }

    case c.SET_DEFAULT_ADDRESS_START:
      return state.setIn(['addresses', 'settingIsDefault'], true);
    case c.SET_DEFAULT_ADDRESS_FAILURE:
      return state.mergeIn(
        ['addresses'],
        fromJS({
          settingIsDefault: false,
          errorSettingIsDefault: true,
        })
      );
    case c.SET_DEFAULT_ADDRESS_SUCCESS:
      return state.mergeIn(
        ['addresses'],
        fromJS({
          settingIsDefault: false,
          errorSettingIsDefault: false,
          defaultAddressId: action.addressId,
        })
      );

    default:
      return state;
  }
};

export const userGeoLocationInitialState = fromJS({ data: undefined, loading: false, error: [] });

export const userGeoLocation = (state = userGeoLocationInitialState, action) => {
  switch (action.type) {
    case c.LOADING_USER_GEO_LOCATION: {
      return state.merge(
        fromJS({
          data: undefined,
          loading: true,
          error: false,
        })
      );
    }
    case c.ERROR_USER_GEO_LOCATION: {
      return state.merge(
        fromJS({
          data: undefined,
          loading: false,
          error: action.error,
        })
      );
    }
    case c.SET_USER_GEO_LOCATION: {
      const { latitude, longitude, postcode } = action.location;

      return state.merge(
        fromJS({
          data: { latitude, longitude, postcode },
          loading: false,
          error: false,
        })
      );
    }
    case c.REMOVE_USER_GEO_LOCATION: {
      return state.merge(
        fromJS({
          data: undefined,
          loading: false,
          error: false,
        })
      );
    }

    case c.LOADING_USER_PREDICTIONS: {
      return state.setIn(['predictions', 'isLoadingPredictions'], true);
    }
    case c.SET_USER_PREDICTIONS: {
      return state.mergeIn(
        ['predictions'],
        fromJS({
          isLoadingPredictions: false,
          error: false,
          predictionsList: action.predictions,
        })
      );
    }
    case c.ERROR_USER_PREDICTIONS: {
      return state.mergeIn(
        ['predictions'],
        fromJS({
          isLoadingPredictions: false,
          error: action.error,
        })
      );
    }

    case c.LOADING_USER_COORDS: {
      return state.setIn(['predictions', 'isLoadingCoords'], true);
    }
    case c.LOADING_USER_ADDRESS_FROM_COORDS: {
      return state.setIn(['predictions', 'isLoadingAddressFromCoords'], true);
    }
    case c.SET_USER_COORDS: {
      return state.mergeIn(
        ['predictions'],
        fromJS({
          isLoadingCoords: false,
          isLoadingAddressFromCoords: false,
          errorCoords: false,
          coordinates: action.coords,
          description: action.description,
          addressComponents: action.addressComponents,
        })
      );
    }
    case c.ERROR_USER_COORDS: {
      return state.mergeIn(
        ['predictions'],
        fromJS({
          isLoadingCoords: false,
          isLoadingAddressFromCoords: false,
          errorCoords: action.error,
        })
      );
    }
    case c.CLEAR_USER_PREDICTIONS_AND_COORDS: {
      return state.mergeIn(
        ['predictions'],
        fromJS({
          isLoadingPredictions: false,
          predictionsList: undefined,
          coordinates: undefined,
          description: undefined,
          addressComponents: undefined,
          error: undefined,
          errorCoords: undefined,
        })
      );
    }

    default:
      return state;
  }
};

export const userOrdersInitialState = fromJS({
  loading: false,
  list: [],
  venues: [],
  selectedVenue: null,
  filteredOrders: {},
});

export const userOrders = (state = userOrdersInitialState, action) => {
  switch (action.type) {
    case c.REQUEST_ORDERS:
      return state.set('loading', true);

    case c.RECEIVE_ORDERS:
      return state.merge(
        fromJS({
          list: action.orders,
          venues: action.venues,
          loading: false,
        })
      );

    case c.LOGOUT:
      return userOrdersInitialState;

    case c.SET_SELECTED_VENUE:
      return state.set('selectedVenue', action.venueId.toString());

    case c.TOGGLE_FILTER: {
      const venueId = action.venueId.toString();
      const isVenueIdSet = state.getIn(['filteredOrders', venueId]);
      const isFilterOpen = state.getIn(['filteredOrders', venueId, 'isFilterOpen']);

      return state.setIn(['filteredOrders', venueId, 'isFilterOpen'], isVenueIdSet ? !isFilterOpen : true);
    }

    case c.CLEAR_FILTER: {
      const venueId = action.venueId.toString();

      return state.setIn(['filteredOrders', venueId], new Map());
    }

    case c.APPLY_FILTER: {
      const { venueId, from, to } = action;
      const venueIdToString = venueId.toString();

      return state
        .setIn(['filteredOrders', venueIdToString, 'from'], from)
        .setIn(['filteredOrders', venueIdToString, 'to'], to);
    }

    default:
      return state;
  }
};

export const userStatus = (state = fromJS({ offline: false }), action) => {
  switch (action.type) {
    case c.SET_OFFLINE:
      return state.set('offline', true);

    case c.SET_ONLINE:
      return state.set('offline', false);

    default:
      return state;
  }
};

export default userReducer;
