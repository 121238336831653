import React, { FC } from 'react';

interface RefreshIconProps {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const RefreshIcon: FC<RefreshIconProps> = ({
  className,
  width = '24px',
  height = '24px',
  fill = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon refreshIcon"
      fill={fill}
      d="M500,954.4c-50.8,0-100.2-8.1-146.7-24c-46.4-15.9-89.3-38.8-127.5-68.1c-38.2-29.3-71.8-64.8-99.7-105.4 c-28-40.7-49.3-85.8-63.2-134.1l-2.8-9.5l73.4-20.3l2.6,9.5c10.9,40,28.4,77.5,52,111.4c23.7,34,52,63.6,84.1,87.8 c32.1,24.3,67.8,43.4,106.1,56.7c38.1,13.3,79,20.1,121.5,20.1c43.7,0,86.4-7.4,127.1-21.9c40.7-14.6,77.6-35.2,109.7-61.2 c32.1-26.1,60.1-57.8,83.2-94.2c20.9-33,36.4-68.9,46.4-106.9H630.9v-75.9H990v359.1h-75.9V686.5c-12.8,27.8-28.2,54.2-46,79 c-28,38.8-61.2,72.6-98.7,100.6c-37.6,28-79.8,49.9-125.6,65.2C598,946.6,549.6,954.4,500,954.4z M10,481.7V122.5h75.9v190.9 c12.8-27.8,28.2-54.2,46-79c27.9-38.7,61.1-72.6,98.7-100.6c37.5-28,79.8-49.9,125.6-65.2c45.8-15.3,94.2-23,143.8-23	c50.8,0,100.2,8.1,146.7,24c46.5,16,89.4,38.9,127.5,68.1c38.2,29.3,71.7,64.7,99.7,105.4c28,40.7,49.2,85.8,63.2,134.1l2.8,9.5	L866.4,407l-2.6-9.5c-10.9-40-28.4-77.5-52-111.4c-23.6-33.9-52-63.5-84.1-87.8c-32.2-24.3-67.9-43.3-106.1-56.7 c-38.2-13.3-79.1-20.1-121.5-20.1c-43.7,0-86.4,7.4-127,21.9c-40.7,14.6-77.6,35.2-109.7,61.2c-32.2,26.1-60.2,57.8-83.2,94.2	c-20.9,33-36.5,68.9-46.4,106.9h235.5v75.9H10z"
    />
  </svg>
);

export default RefreshIcon;
