import { fromJS } from 'immutable';

import { SET_PAYMENT_ERROR, CLEAR_PAYMENT_ERROR } from 'actions/payment/constants';

export const initialState = fromJS({
  showError: false,
  message: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_ERROR:
      return state.merge(
        fromJS({
          showError: action.error,
          message: action.message || null,
        })
      );

    case CLEAR_PAYMENT_ERROR:
      return initialState;

    default:
      return state;
  }
};
