import React from 'react';
import PropTypes from 'prop-types';

// Icon
const WaiterIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon waiterIcon"
        fill={fill}
        opacity={opacity}
        d="M489.5,465.9c-60.9,0-118.1-23.7-161.2-66.8c-43.1-43.1-66.8-100.3-66.8-161.2s23.7-118.1,66.8-161.2	C371.4,33.7,428.6,10,489.5,10c60.9,0,118.1,23.7,161.2,66.8c43.1,43.1,66.8,100.3,66.8,161.2c0,60.9-23.7,118.1-66.8,161.2	C607.6,442.2,550.4,465.9,489.5,465.9z M489.5,51.7c-49.8,0-96.5,19.4-131.7,54.6c-35.2,35.2-54.6,82-54.6,131.7 s19.4,96.5,54.6,131.7c35.2,35.2,82,54.6,131.7,54.6c49.8,0,96.5-19.4,131.7-54.6c35.2-35.2,54.6-82,54.6-131.7	c0-49.8-19.4-96.5-54.6-131.7C586,71.1,539.2,51.7,489.5,51.7z"
      />
      <path
        className="icon waiterIcon"
        fill={fill}
        opacity={opacity}
        d="M346.4,216.9c-8.2,0-15.5-6.4-16.9-15.5c-1.6-10.1,4.7-19.7,14-21.5c24.8-4.6,43.9-13.9,56.7-27.8	c10.8-11.6,16.9-26,18.4-43c0.8-8.8,7.1-15.8,15.3-16.8c8.1-1,15.7,4.4,18.2,12.8c5.3,17.9,20.5,42.6,63.2,58.8	c32.7,12.4,74.6,16.5,115.1,11.2c9.4-1.2,18,6,19.1,16.2c1.1,10.2-5.6,19.5-15,20.7c-46.2,6.1-92.6,1.4-130.6-13	c-27-10.3-48.4-24.8-63.4-43c-16.4,31.2-48,52.5-91.2,60.6C348.4,216.9,347.4,216.9,346.4,216.9z"
      />
      <circle className="icon waiterIcon" fill={fill} opacity={opacity} cx="492.7" cy="556.5" r="39.8" />
      <path
        className="icon waiterIcon"
        fill={fill}
        opacity={opacity}
        d="M582.5,627.6c-4,0-7.9-1.4-11.1-4l-67.4-55.4c-4.1-3.3-6.4-8.3-6.4-13.6s2.3-10.2,6.4-13.6l67.4-55.4c3.2-2.6,7.2-4,11.1-4	c2.5,0,5.1,0.6,7.5,1.7c6.1,2.9,10.1,9.1,10.1,15.9V610c0,6.8-3.9,13-10.1,15.9C587.6,627,585,627.6,582.5,627.6z"
      />
      <path
        className="icon waiterIcon"
        fill={fill}
        opacity={opacity}
        d="M398.6,629.9c4,0,7.9-1.4,11.1-4l67.4-55.4c4.1-3.3,6.4-8.3,6.4-13.6s-2.3-10.2-6.4-13.6L409.8,488c-3.2-2.6-7.2-4-11.1-4	c-2.5,0-5.1,0.6-7.5,1.7c-6.1,2.9-10.1,9.1-10.1,15.9v110.8c0,6.8,3.9,13,10.1,15.9C393.5,629.4,396.1,629.9,398.6,629.9z"
      />
      <path
        className="icon waiterIcon"
        fill={fill}
        opacity={opacity}
        d="M113.8,990V836.2c0-37.3,7.4-73.6,21.9-107.9c14.1-33.1,34.2-62.8,59.8-88.4c7-7,3.8-0.8,11.4-6.9s29.4,228-8.8,357.1h584.3	c-48.3-130.2-22.7-382.9-15.2-378.1c17.1,10.9,22.6,13.4,37.2,27.9c25.6,25.6,45.7,55.4,59.8,88.4c14.5,34.2,21.9,70.5,21.9,107.9	V990h53.4V836.2c0-43.1-8.5-85.1-25.3-124.6c-16.2-38.2-39.4-72.5-68.9-102c-29.5-29.5-63.8-52.7-102-68.9	c-27.9-11.8-56.9-19.6-86.8-23.1l-12.8,28.3c-22.9,129.8-77.1,258.8-157,387C417,809.8,364.7,680.3,329.3,544.6l-10.4-23.1	c-21.2,4.2-42,10.6-62.1,19.2c-38.2,16.2-72.5,39.4-102,68.9c-29.5,29.5-52.7,63.8-68.9,102C69,751.1,60.5,793.1,60.5,836.2V990	H113.8z"
      />
    </g>
  </svg>
);

WaiterIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

WaiterIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default WaiterIcon;
