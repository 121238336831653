import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openRadiusAlert } from 'actions/UI';
import { PointerIcon, EditIcon } from 'components/Icons';
import { isDeliveryToAddressEnabled } from 'selectors/root';
import { selectDescription } from 'selectors/user';
import globalMessages from 'components/globalMessages';

import { MessageContainer, TextMessage } from './styles';

type Props = {
  isDeliveryToAddressEnabled?: boolean;
  openRadiusAlert?: () => void;
  addressDescription?: string;
  onChange?: (arg: string) => void;
};

const AddressInRadius = ({
  isDeliveryToAddressEnabled,
  openRadiusAlert,
  addressDescription,
  onChange,
}: Props) => {
  useEffect(() => {
    onChange('true');
  }, []);

  if (!isDeliveryToAddressEnabled) return null;

  return (
    <MessageContainer>
      <div>
        <PointerIcon width="18px" height="18px" />
        <TextMessage>
          {addressDescription || <FormattedMessage {...globalMessages.deliveryAddressMsg} />}
        </TextMessage>
      </div>

      <button type="button" onClick={() => openRadiusAlert()}>
        <EditIcon width="18px" height="18px" />
      </button>
    </MessageContainer>
  );
};

export default connect(
  state => ({
    isDeliveryToAddressEnabled: isDeliveryToAddressEnabled(state),
    addressDescription: selectDescription(state),
  }),
  { openRadiusAlert }
)(AddressInRadius);
