import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import publicConfig from 'publicConfig';
import address from '../../../../shared/sortVenues/address';
import VenuePin from './VenuePin';

import { MapContainer } from './styles';

const propTypes = {
  venues: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  showVenueAddress: PropTypes.bool,
};

const Map = ({ venues, visible, showVenueAddress }) => {
  if (!visible) return null;

  const [firstVenue] = venues;
  const center =
    firstVenue?.lat && firstVenue?.lng ? { lat: Number(firstVenue.lat), lng: Number(firstVenue.lng) } : null;

  return (
    <MapContainer small={!showVenueAddress}>
      <GoogleMapReact bootstrapURLKeys={{ key: publicConfig.googleMapsApiKey }} zoom={13} center={center}>
        {venues.map(venue =>
          venue.lat && venue.lng ? (
            <VenuePin
              key={venue.id}
              lat={venue.lat}
              lng={venue.lng}
              name={venue.name}
              address={showVenueAddress && address(venue)}
              showCloseIcon={showVenueAddress}
              venueId={venue.id}
            />
          ) : null
        )}
      </GoogleMapReact>
    </MapContainer>
  );
};

Map.defaultProps = {
  showVenueAddress: false,
};

Map.propTypes = propTypes;

export default Map;
