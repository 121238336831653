import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import Dropdown, { generateDropdownOptions } from 'components/Dropdown';

import messages from './messages';

const VenueSelect = ({ venueId, venues, onChange, onFocus, onBlur }) => (
  <Dropdown
    onFocus={onFocus}
    onBlur={onBlur}
    options={generateDropdownOptions(venues)}
    value={venueId}
    onChange={onChange}
    label={<FormattedMessage {...messages.venue} />}
  />
);

VenueSelect.propTypes = {
  venueId: PropTypes.number,
  venues: PropTypes.instanceOf(List),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default VenueSelect;
