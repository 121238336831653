import React from 'react';
import PropTypes from 'prop-types';

import AlertIcon from 'components/Icons/AlertIcon';
import { getIcon } from 'utils';
import { AlertContainer, IconContainer, Title } from './styles';

const propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info', 'warning']),
  large: PropTypes.bool,
  header: PropTypes.node,
  body: PropTypes.node,
  className: PropTypes.string,
};

const defaultProps = {
  large: false,
};

const MessageBlock = ({ type, large, header, body, className }) => (
  <AlertContainer type={type} large={large} className={className}>
    <IconContainer large={large}>{getIcon(type, { defaultIcon: <AlertIcon /> })}</IconContainer>

    <div>
      {header && <Title>{header}</Title>}
      <div>{body}</div>
    </div>
  </AlertContainer>
);

MessageBlock.propTypes = propTypes;
MessageBlock.defaultProps = defaultProps;

export default MessageBlock;
