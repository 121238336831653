import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ChevronUp = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M5.768,166.104c0.375-0.893,0.942-1.678,1.7-2.361l107.887-97.547c1.509-1.369,3.322-2.053,5.439-2.053 c2.115,0,3.927,0.684,5.439,2.053l106.301,96.314c1.508,1.367,2.266,3.01,2.266,4.928c0,1.916-0.758,3.559-2.266,4.93	c-1.514,1.367-3.326,2.053-5.439,2.053c-2.119,0-3.932-0.686-5.439-2.053L120.793,80.98L18.347,173.805	c-1.512,1.367-3.325,2.053-5.439,2.053c-2.118,0-3.931-0.686-5.44-2.053c-1.512-1.371-2.267-3.014-2.267-4.93	C5.201,167.916,5.389,166.992,5.768,166.104z"
      />
    </g>
  </svg>
);

ChevronUp.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ChevronUp.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ChevronUp;
