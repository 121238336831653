import { List } from 'immutable';
import { getVenueOrganisationId } from 'selectors/root';

export const selectAlertStack = state => state.getIn(['ui', 'alertStack'], new List());
export const selectNotificationStack = state => state.getIn(['ui', 'notificationStack'], new List());
export const selectLastMenuPage = state => state.getIn(['ui', 'lastMenuPage']);
export const selectIsSettingsPage = state => state.getIn(['ui', 'isSettingsPage']);

export const selectKioskTimeout = state => {
  const organisationId = getVenueOrganisationId(state);

  // These venues require a different timeout
  if ([195].includes(organisationId)) {
    return 90 * 1000;
  }

  return 30 * 1000;
};
