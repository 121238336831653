import styled from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

export const ContentArea = styled.div`
  color: ${({ theme }) => theme.color.text};
  padding: 0 10px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: bold;
    line-height: 1.1;
    color: inherit;
  }

  h3 {
    font-size: 16px;
    line-height: normal;
    margin-top: 20px;
  }

  ol,
  ul {
    padding-left: 20px;
    margin-bottom: 15px;
    font-size: 0.85em;

    ul,
    ol {
      margin-bottom: 0;
      margin-top: 10px;
    }

    li {
      padding-bottom: 10px;
    }
  }

  p {
    ${fontSizeMedium};
  }
`;

export const ListLowerLatin = styled.ul`
  list-style: lower-latin;
`;

export const ListLowerRoman = styled.ul`
  list-style: lower-roman;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};

  @media (min-width: 870px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeMediumOne};
  }
`;
