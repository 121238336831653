import { fromJS } from 'immutable';
import {
  getSelectedBaseModifierProduct,
  getTax,
  getModifiersPriceForTrackEvent,
  selectMenuTypeId,
  selectServiceId,
} from 'selectors/browse';
import { getOrderItemTotal, selectOrderTotal } from 'selectors/order';
import { selectVenueCurrencyCode } from 'selectors/root';

import { ADD_PRODUCT_TO_ORDER, REMOVE_PRODUCT_FROM_ORDER, UPDATE_ITEM } from '../actions/order/constants';
import { trackEvent } from '../utils';

export const getTaxPercent = taxList => {
  const taxPercent =
    taxList &&
    taxList?.flatMap(el => typeof el.get === 'function' && el.get('rates')?.map(el => el.getIn(['amount'])));

  // If we've got an Immuatable list, we can return it
  if (typeof taxPercent.get === 'function') {
    return taxPercent.get(0);
  }

  // We weren't able to find a tax percent
  return undefined;
};

const getModifiersAvailable = mods => {
  if (mods) {
    return mods.size > 0 ? 'Yes' : 'No';
  }
  return null;
};

export default ({ getState }) =>
  next =>
  action => {
    const state = getState();
    if (action.type === ADD_PRODUCT_TO_ORDER) {
      const { item, menuType, modifiers, quantity = 1 } = action;

      const menuItemId = menuType.get('id').toString();

      const itemPrice = item.getIn(['prices', menuItemId, 'price']);
      const baseModifierProd = getSelectedBaseModifierProduct(modifiers);

      const defaultTaxIds = item.getIn(['product', 'tax_categories']) || [];
      const taxIds = baseModifierProd ? baseModifierProd.get('tax_categories') : defaultTaxIds;
      const unitPrice = baseModifierProd?.get('price') || itemPrice;
      const taxPercentAmount = getTaxPercent(defaultTaxIds) || null;

      const modifiersAdded = modifiers !== null ? 'Yes' : 'No';
      const modifiersPrice = getModifiersPriceForTrackEvent(modifiers);

      const modifiersAvailable = item.getIn(['product', 'modifier_groups']).size > 0 ? 'Yes' : 'No';

      const taxAmountPrice = taxPercentAmount
        ? getTax(taxPercentAmount, itemPrice) / 100 +
          (modifiersPrice ? getTax(taxPercentAmount, modifiersPrice) / 100 : 0)
        : null;

      const position = item.getIn(['sort_order']) + 1;

      const value =
        getOrderItemTotal(
          state,
          fromJS({
            quantity,
            unit_price: unitPrice,
            tax_category_ids: taxIds,
            modifiers,
          })
        ) / 100;

      trackEvent('add_to_cart', {
        currency: selectVenueCurrencyCode(state),
        value,
        category: 'item',
        location: 'menu',
        modifiers_added: modifiersAdded,
        modifiers_available: modifiersAvailable,
        position,
        items: [
          {
            item_id: item.get('productId'),
            item_name: item.get('name'),
            quantity,
            price: unitPrice / 100,
            modifier_value: modifiersPrice,
            tax_amount: taxAmountPrice,
          },
        ],
      });
    }

    if (action.type === REMOVE_PRODUCT_FROM_ORDER) {
      const { item, index } = action;

      const modifiers = item.getIn(['modifiers']) || [];
      const modifierPriceNoTaxes = getModifiersPriceForTrackEvent(modifiers);

      const taxTotal = item.getIn(['tax_total']) || null;
      const itemTax = taxTotal ? taxTotal / item.get('quantity') / 100 : null;

      const itemId = item.getIn(['base_product_id']);

      const unitPrice = item.getIn(['unit_price']);

      const hasModifiers =
        state
          ?.getIn(['browse', 'data', 'products'])
          ?.find(el => el.get('id') === itemId)
          ?.get('modifier_groups') || null;

      const modifiersAvailable = getModifiersAvailable(hasModifiers);
      const modifiersAdded = modifiers.size > 0 ? 'Yes' : 'No';

      const position = index + 1;

      trackEvent('remove_from_cart', {
        currency: selectVenueCurrencyCode(state),
        value: item.get('total') / 100,
        modifiers_added: modifiersAdded,
        category: 'item',
        location: 'review',
        modifiers_available: modifiersAvailable,
        position,
        items: [
          {
            item_id: itemId,
            item_name: item.get('name'),
            quantity: item.get('quantity'),
            price: unitPrice / 100,
            modifier_value: modifierPriceNoTaxes,
            tax_amount: itemTax,
          },
        ],
      });
    }

    if (action.type === UPDATE_ITEM) {
      const { itemIndex, serviceId, menuTypeId, modifiers } = action;

      const modifierPriceNoTaxes = getModifiersPriceForTrackEvent(modifiers);

      if (!serviceId || !menuTypeId) return next(action);

      const orderIndex = state
        .get('orderList')
        .findIndex(
          order =>
            order.get('serviceId') === action.serviceId && order.get('menuTypeId') === action.menuTypeId
        );

      const currentItem = state.getIn(['orderList', orderIndex, 'items', itemIndex]);

      const unitPrice = currentItem.getIn(['unit_price']);

      const itemId = currentItem.getIn(['base_product_id']);

      const totalPrice = currentItem.get('total');

      const hasModifiers =
        state
          .getIn(['browse', 'data', 'products'])
          .find(el => el.get('id') === itemId)
          .get('modifier_groups').size > 0
          ? 'Yes'
          : 'No';

      const modifiersAvailable = getModifiersAvailable(hasModifiers);

      const modifiersAdded = modifiers.size > 0 ? 'Yes' : 'No';

      const taxCategories = state
        ?.getIn(['browse', 'data', 'products'])
        ?.find(el => el.get('id') === itemId)
        ?.get('tax_categories');

      const taxPercent = getTaxPercent(taxCategories) || null;

      const taxAmountPrice = taxPercent
        ? getTax(taxPercent, unitPrice) / 100 +
          (modifierPriceNoTaxes ? getTax(taxPercent, modifierPriceNoTaxes) / 100 : 0)
        : null;

      // if the quantity is being decreased, fire the remove_from_cart event
      if (currentItem.get('quantity') > action.quantity) {
        const timesClicked = currentItem.get('quantity') - action.quantity;
        const fullItemPrice = totalPrice / currentItem.get('quantity');

        const fullValue = fullItemPrice * timesClicked;

        trackEvent('remove_from_cart', {
          currency: selectVenueCurrencyCode(state),
          value: fullValue / 100,
          position: itemIndex + 1,
          category: 'item',
          location: 'review',
          modifiers_added: modifiersAdded,
          modifiers_available: modifiersAvailable,
          items: [
            {
              item_id: itemId,
              item_name: currentItem.get('name'),
              quantity: timesClicked,
              price: unitPrice / 100,
              modifier_value: modifierPriceNoTaxes,
              tax_amount: taxAmountPrice,
            },
          ],
        });
      }

      // if the quantity is being increased, fire the add_to_cart event
      if (currentItem.get('quantity') < action.quantity) {
        const timesClicked = action.quantity - currentItem.get('quantity');

        const fullItemPrice = totalPrice / currentItem.get('quantity');

        const fullValue = fullItemPrice * timesClicked;

        trackEvent('add_to_cart', {
          currency: selectVenueCurrencyCode(state),
          value: fullValue / 100,
          category: 'item',
          location: 'review',
          modifiers_available: modifiersAvailable,
          position: itemIndex + 1,
          modifiers_added: modifiersAdded,
          items: [
            {
              item_id: itemId,
              item_name: currentItem.getIn(['name']),
              quantity: timesClicked,
              price: unitPrice / 100,
              modifier_value: modifierPriceNoTaxes,
              tax_amount: taxAmountPrice,
            },
          ],
        });
      }
    }

    return next(action);
  };

export const sendEventBeginCheckout = state => {
  const value = selectOrderTotal(state) / 100;

  const orderIndex = state
    .get('orderList')
    .findIndex(
      order =>
        order.get('serviceId') === selectServiceId(state) &&
        order.get('menuTypeId') === selectMenuTypeId(state)
    );

  const items = state.getIn(['orderList', orderIndex, 'items']).toArray();

  trackEvent('begin_checkout', {
    currency: selectVenueCurrencyCode(state),
    value,
    category: 'checkout',
    items: items.map(item => {
      const modifiers = item.getIn(['modifiers']);
      const modifierPrice = getModifiersPriceForTrackEvent(modifiers);

      const baseId = item.getIn(['base_product_id']);
      const itemid = baseId || item.getIn(['id']);

      const name = item.getIn(['name']);

      const quantity = item.getIn(['quantity']);
      const price = item.getIn(['unit_price']) / 100;

      const taxTotal = item.getIn(['tax_total']);
      const itemTax = taxTotal / quantity / 100;

      return {
        item_id: itemid,
        item_name: name,
        quantity,
        price,
        modifier_value: modifierPrice,
        tax_amount: itemTax,
      };
    }),
  });
};
