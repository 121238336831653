import React from 'react';
import PropTypes from 'prop-types';

// Icon
const PayPalIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,500H55c-30.3,0-55-24.7-55-55V54C0,23.7,24.7-1,55-1h670c30.3,0,55,24.7,55,55v391C780,475.3,755.3,500,725,500z M55,29c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V54c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M648,211.5c-7.3-4-18.7-6.1-34.2-6.1c-7.7,0-15.4,0.6-23.2,1.8c-5.7,0.9-6.3,1-9.8,1.8c-7.3,1.6-8.4,8.9-8.4,8.9l-2.3,9.6c-1.3,6.1,2.2,5.9,3.7,5.4c3.2-1,4.9-1.9,11.4-3.4c6.2-1.4,12.7-2.5,18-2.4c7.7,0,13.5,0.8,17.4,2.5c3.9,1.7,5.8,4.6,5.8,8.7c0,1,0,1.9-0.3,2.7c-0.3,0.8-0.9,1.5-2.8,1.7c-10.9,0.6-18.8,1.6-28.2,3c-9.3,1.3-17.5,3.6-24.3,6.7c-7.3,3.2-12.7,7.5-16.5,13c-3.6,5.5-5.5,12.2-5.5,20c0,7.4,2.7,13.5,7.8,18.2c5.2,4.6,12,6.9,20.2,6.9c5.1,0,9.1-0.4,12-1.1c2.8-0.7,6-1.7,9.2-3.1c2.4-1,5.1-2.5,7.9-4.4c2.8-1.9,4.7-3.2,7.2-4.8l0.1,0.2l-0.7,3.1c0,0,0,0,0,0.1l0,0.1c-0.8,3.7,1.4,6.8,5.1,7.2l0.1,0.1h0.3l0,0c2.4,0,10.8,0,14.7,0h2.7c0.2,0,0.2-0.1,0.3-0.1c3.7-0.5,7.4-3.5,8.2-7.2l14-60.4c0.3-1.4,0.6-3.1,0.7-5c0.2-1.9,0.4-3.5,0.3-4.6C658.9,221.9,655.2,215.5,648,211.5z M614.1,283.4c-2.4,1.5-4.9,2.8-7.5,3.9c-3.5,1.4-6.9,2.2-10,2.2c-4.8,0-8.4-0.7-10.9-2.1c-2.5-1.4-3.8-3.7-3.7-7.1c0-3.9,0.9-7,2.8-9.4c1.9-2.3,4.7-4.1,8.2-5.5c3.5-1.2,7.7-2.1,12.6-2.8c4.4-0.6,13-1.6,14.1-1.6c1.1,0,1.8-0.6,1.4,2.3c-0.2,1.3-2.7,11.5-3.9,16.2C616.8,280.8,614.9,282.9,614.1,283.4z"
      />
      <path
        className="icon"
        fill={fill}
        d="M407.3,206.7c-4.1,0-9.5,3.3-12,7.3c0,0-27.5,47.1-30.1,51.8c-1.5,2.5-3,0.9-3.2,0c-0.2-1.1-8.5-51.4-8.5-51.4c-0.9-4-5.2-7.5-10.5-7.5l-17.1,0c-4.1,0-6.7,3.3-5.8,7.3c0,0,13.1,74.2,15.6,91.6c1.3,9.6-0.1,11.4-0.1,11.4l-17,29.6c-2.5,4-1.1,7.3,3,7.3l19.8,0c4.1,0,9.5-3.3,12-7.3l76.3-129.1c0,0,7.3-11,0.6-10.9C425.7,206.8,407.3,206.7,407.3,206.7"
      />
      <path
        className="icon"
        fill={fill}
        d="M166.4,232.9c-5.8,4.2-13.8,6.3-24.2,6.3h-4.7c-4.1,0-6.8-3.3-5.8-7.3l5.8-24.8c0.9-4,5-7.3,9.1-7.3l6.3,0c7.3,0,12.9,1.3,16.7,3.7c3.7,2.4,5.6,6.2,5.5,11.4C175.2,222.7,172.2,228.7,166.4,232.9 M207.8,194.6c-2.4-4.6-5.9-8.4-10.5-11.3c-4.7-2.9-10.2-4.9-16.6-6.1c-6.5-1.2-14-1.7-22.6-1.8l-40,0c-4.2,0.1-8.2,3.3-9.2,7.3L81.9,300c-0.9,4,1.7,7.3,5.8,7.3h19.2c4.1,0,8.2-3.2,9.2-7.3l6.6-28.5c0.9-4,5-7.3,9.1-7.3h5.5c23.4,0,41.6-4.8,54.7-14.4c13-9.6,19.6-22.3,19.5-38C211.5,205,210.3,199.2,207.8,194.6"
      />
      <path
        className="icon"
        fill={fill}
        d="M514.2,233.4c-5.7,4.2-13.8,6.3-24.2,6.3h-4.8c-4.1,0-6.8-3.3-5.8-7.3l5.8-24.9c0.9-3.9,5-7.3,9.1-7.3l6.3,0c7.4,0,12.9,1.3,16.7,3.7c3.7,2.5,5.5,6.3,5.6,11.4C522.9,223.1,520,229.1,514.2,233.4 M555.6,195.1c-2.4-4.6-6-8.4-10.5-11.3c-4.7-2.9-10.2-4.9-16.7-6.1c-6.4-1.1-13.9-1.8-22.5-1.8l-40.1,0c-4.1,0.1-8.2,3.3-9.1,7.3l-26.9,117.3c-1,4,1.7,7.3,5.7,7.3l19.2,0c4.1,0,8.2-3.3,9.2-7.3l6.5-28.4c0.9-4,5.1-7.3,9.2-7.3h5.5c23.5,0,41.7-4.8,54.7-14.4c13-9.6,19.5-22.3,19.5-38C559.2,205.4,558,199.7,555.6,195.1"
      />
      <path
        className="icon"
        fill={fill}
        d="M300.1,211.4c-7.3-4.1-18.6-6.1-34.2-6.1c-7.7,0-15.4,0.6-23.2,1.8c-5.7,0.9-6.3,1-9.8,1.8c-7.3,1.6-8.4,8.9-8.4,8.9l-2.3,9.6c-1.3,6.2,2.2,5.9,3.7,5.4c3.2-1,4.9-1.9,11.4-3.4c6.2-1.4,12.7-2.5,18-2.4c7.7,0,13.5,0.8,17.4,2.4c3.9,1.7,5.8,4.6,5.8,8.7c0,1,0.1,1.9-0.3,2.7c-0.3,0.8-0.9,1.5-2.8,1.7c-10.9,0.7-18.8,1.7-28.3,3c-9.3,1.3-17.5,3.6-24.3,6.7c-7.3,3.2-12.7,7.6-16.5,13c-3.7,5.5-5.5,12.2-5.5,20c0,7.4,2.7,13.5,7.8,18.2c5.2,4.6,12,6.9,20.2,6.9c5.1,0,9.1-0.4,12-1.1c2.8-0.7,5.9-1.7,9.2-3.2c2.5-1,5.1-2.5,7.9-4.4c2.8-1.9,4.7-3.2,7.2-4.8l0.1,0.1l-0.7,3.1c0,0,0,0,0,0.1l0,0.1c-0.8,3.7,1.4,6.8,5.1,7.2l0,0.1h0.3l0,0c2.5,0,10.8,0,14.7,0h2.7c0.2,0,0.2-0.1,0.3-0.1c3.8-0.5,7.4-3.5,8.2-7.2l14-60.4c0.3-1.4,0.6-3,0.7-4.9c0.2-1.9,0.4-3.5,0.3-4.6C311,221.8,307.4,215.4,300.1,211.4z M266.2,283.3c-2.4,1.5-4.9,2.8-7.5,3.9c-3.5,1.4-6.9,2.2-10,2.2c-4.7,0-8.4-0.7-10.9-2.1c-2.5-1.4-3.8-3.7-3.7-7.1c0-3.9,0.9-7,2.8-9.4c1.9-2.3,4.7-4.1,8.1-5.5c3.5-1.2,7.7-2.1,12.6-2.8c4.4-0.6,13-1.5,14.1-1.6c1.1,0,1.8-0.6,1.3,2.3c-0.2,1.3-2.7,11.5-3.9,16.2C268.9,280.8,267.1,282.8,266.2,283.3z"
      />
      <path
        className="icon"
        fill={fill}
        d="M714,176.8h-17.1c0,0,0,0,0,0l0,0c-4,0-8,3.1-9.1,7c0,0.1-0.1,0.2-0.1,0.3c0,0-0.3,1.6-1,4.3L663,295.9c-0.6,2.5-0.9,4.1-1,4.5l0,0.1c-0.8,3.7,1.4,6.9,5.1,7.2l0,0H685c4,0,8.1-3.1,9.1-7c0-0.1,0.1-0.2,0.1-0.3l25.7-116.2l0,0C720.7,180.1,718.1,176.8,714,176.8z"
      />
    </g>
  </svg>
);

PayPalIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

PayPalIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default PayPalIcon;
