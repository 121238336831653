import React from 'react';
import PropTypes from 'prop-types';

// Icon
const TermsIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M51,5v30H18v200h170v-30h33V5H51z M176,223H30V47h88v58h58V223z M130,93V52.18L171.093,93H130z M209,193h-21V93.152	L130.491,35H63V17h146V193z"
      />
      <rect className="icon" fill={fill} x="42" y="61" width="62" height="12" />
      <rect className="icon" fill={fill} x="42" y="94" width="62" height="12" />
      <rect className="icon" fill={fill} x="42" y="161" width="118" height="12" />
      <rect className="icon" fill={fill} x="42" y="128" width="118" height="12" />
      <rect className="icon" fill={fill} x="42" y="194" width="118" height="12" />
    </g>
  </svg>
);

TermsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TermsIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default TermsIcon;
