import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage, defineMessages } from 'react-intl';

import { NutritionThemedIcon } from 'components/Icons';
import ActionButton from 'components/ActionButton';
import { selectMessageByLocale } from 'selectors/root';
import globalMessages from 'components/globalMessages';

import { AlertContent, AlertFooter, AlertButtons, AlertTitle } from 'assets/styles/sharedStyles';
import { Msg, NutritionList, NutritionRow, NutritionColumn } from './styles';

const messages = defineMessages({
  nutrition_per: {
    defaultMessage: 'Nutrition (per 100g)',
  },
  nutritional_information: {
    defaultMessage: 'Nutritional Information',
  },
  product_contains: {
    defaultMessage: 'Your selected products contains:',
  },
  energy_kcal: {
    defaultMessage: 'Energy (kcal):',
  },
  energy_kj: {
    defaultMessage: 'Energy (kJ):',
  },
  fat: {
    defaultMessage: 'Fat (g):',
  },
  fat_saturates: {
    defaultMessage: 'of which saturates (g):',
  },
  carbohydrates: {
    defaultMessage: 'Carbohydrates (g):',
  },
  carbohydrates_sugars: {
    defaultMessage: 'of which sugars (g):',
  },
  fibre: {
    defaultMessage: 'Fibre (g):',
  },
  protein: {
    defaultMessage: 'Protein (g):',
  },
  salt: {
    defaultMessage: 'Salt (g):',
  },
  added_sugars: {
    defaultMessage: 'Added Sugars (g):',
  },
});

const hasNutritionalInfo = (product, nutrientName) => !!product.get(nutrientName);

const getNutritionalInfo = (product, nutrientName) => (
  <NutritionRow key={nutrientName}>
    <NutritionColumn type="label">
      <FormattedMessage {...messages[nutrientName]} />
    </NutritionColumn>
    <NutritionColumn type="value">
      <FormattedNumber value={product.get(nutrientName)} />
    </NutritionColumn>
  </NutritionRow>
);

const NutritionalInformation = ({ nutritionalMessageByLocale, product, closeAlert }) => (
  <>
    <AlertContent>
      <NutritionThemedIcon width="60px" height="60px" />
      <AlertTitle>
        <FormattedMessage {...messages.nutritional_information} />
      </AlertTitle>

      {nutritionalMessageByLocale && <Msg>{nutritionalMessageByLocale}</Msg>}

      <NutritionList>
        {[
          'energy_kcal',
          'energy_kj',
          'fat',
          'fat_saturates',
          'carbohydrates',
          'carbohydrates_sugars',
          'fibre',
          'protein',
          'salt',
          'added_sugars',
        ]
          .filter(hasNutritionalInfo.bind(null, product))
          .map(name => getNutritionalInfo(product, name))}
      </NutritionList>
    </AlertContent>
    <AlertFooter>
      <AlertButtons>
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.cancel} />}
          onClick={closeAlert}
        />
      </AlertButtons>
    </AlertFooter>
  </>
);

NutritionalInformation.propTypes = {
  closeAlert: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Map).isRequired,
  nutritionalMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default connect(state => ({
  nutritionalMessageByLocale: selectMessageByLocale(state, 'nutritional_information_message'),
}))(NutritionalInformation);
