import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectContentAreas } from 'selectors/root';
import TextBlock from './TextBlock';

import { Container } from './styles';

const components = {
  TextBlock,
};

const propTypes = {
  contentAreas: PropTypes.object,
};

const ContentArea = ({ contentAreas }) => {
  if (!contentAreas) return null;

  return contentAreas.map((content, index) => {
    const Component = components[content.get('component')];

    return (
      <Container key={index}>
        <Component content={content.get('content')} />
      </Container>
    );
  });
};

ContentArea.propTypes = propTypes;

export default connect((state, { area }) => ({
  contentAreas: selectContentAreas(state)(area),
}))(ContentArea);
