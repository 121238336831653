import { defineMessages } from 'react-intl';

export default defineMessages({
  collectionDate: {
    defaultMessage: 'Collection Date',
  },
  collectionTime: {
    defaultMessage: 'Collection Time',
  },
  deliveryDate: {
    defaultMessage: 'Delivery Date',
  },
  deliveryTime: {
    defaultMessage: 'Delivery Time',
  },
  noTimesAvailable: {
    defaultMessage: 'No times available',
  },
});
