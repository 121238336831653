import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { selectMenuTypeId, selectServiceId } from 'selectors/browse';
import { isDineInOrderingFlow } from 'selectors/root';
import { selectSessionId } from 'selectors/session';
import Page from 'components/Pages/container';
import Form from 'components/Form';
import globalMessages from 'components/globalMessages';
import { createSession } from 'actions/session';
import TabLoading from 'components/Tabs/TabLoading';
import messages from './messages';

// hardcoded to assume table type location
// this selector would need reworking for dynamic location types:
// const locationField = useSelector(selectDineInLocationField);
const locationField = {
  required: true,
  type: 'component',
  name: 'tableNumber',
  expiry: {
    format: 'hours',
    length: 1,
  },
  errorKey: 'fulfilment.location.table_number',
  component: 'LocationName',
  locationKey: 'tables',
  fulfilmentKey: 'table',
  showLabel: true,
  storeDetail: true,
  updateOrderOnBlur: true,
  formSubmitting: false,
};

interface Props {
  createSession: (locationRef?: string) => Promise<void>;
}

const EnterLocation: React.FC<Props> = ({ createSession }) => {
  const isDineInOrderFlow = useSelector(isDineInOrderingFlow);
  const orderSessionId = useSelector(selectSessionId);
  const menuTypeId = useSelector(selectMenuTypeId);
  const serviceId = useSelector(selectServiceId);
  const tableNumber = new URLSearchParams(
    typeof document !== 'undefined' ? document.location.search : ''
  ).get('tableNumber');

  const redirect = () => {
    if (tableNumber) {
      createSession(tableNumber).then(() => browserHistory.replace(`/service/${serviceId}/order`));
    } else if (
      !isDineInOrderingFlow ||
      !!orderSessionId ||
      (!Object.keys(locationField).length && !!menuTypeId)
    ) {
      browserHistory.replace(`/service/${serviceId}/order`);
    }
  };

  useEffect(redirect, [isDineInOrderFlow, orderSessionId, serviceId, menuTypeId, tableNumber, createSession]);

  return (
    <Page
      settingsPage={true}
      titleMessage={(!tableNumber && locationField?.name && messages[locationField.name]) || null}
      hideBackButton={true}
    >
      {tableNumber && <TabLoading />}
      {!tableNumber && (
        <Form
          fields={[locationField]}
          onSubmit={(values, callback) => {
            createSession().then(callback);
          }}
          submitButtonLabel={<FormattedMessage {...globalMessages.confirm} />}
          errorMessage={<FormattedMessage {...globalMessages.errorMsg} />}
          values={undefined}
        />
      )}
    </Page>
  );
};

export default connect(null, { createSession })(EnterLocation);
