import React from 'react';
import { FormattedMessage } from 'react-intl';
import dineInMessages from 'components/dineInMessages';
import LoadingSpinner from '../../Loading/LoadingSpinner';
import { Container, Heading, Messages } from './styles';

const TabLoading = () => (
  <Container>
    <LoadingSpinner height="100px" width="100px" />
    <Messages>
      <Heading>
        <FormattedMessage {...dineInMessages.oneMoment} />
      </Heading>
    </Messages>
  </Container>
);

export default TabLoading;
