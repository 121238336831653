import React from 'react';
import PropTypes from 'prop-types';

// Icon
const EatInIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M939.2,308.1c26-32.5,40.2-72.6,40.2-114.5C979.4,92.4,897,10,795.8,10C717.2,10,650,59.6,623.8,129.2 c-52.2-22.3-109.8-34.7-170.2-34.7c-239.2,0-433.1,193.9-433.1,433.1s193.9,433.1,433.1,433.1s433.1-193.9,433.1-433.1 c0-56.7-10.9-110.9-30.7-160.5c4.1-1.4,8.1-3,12.1-4.7l25.6,38.5c5.9,8.8,15.7,14.1,26.3,14.1c0,0,0,0,0,0c6.2,0,12.2-1.8,17.4-5.3 l21.9-14.5c7-4.7,11.8-11.8,13.5-20c1.7-8.2,0-16.6-4.6-23.7L939.2,308.1z M718.3,792.2c-70.7,70.7-164.7,109.6-264.6,109.6 s-194-38.9-264.6-109.6C118.3,721.5,79.4,627.5,79.4,527.6s38.9-194,109.6-264.6s164.7-109.6,264.6-109.6 c55.7,0,109.6,12.1,158.6,35.1c0,1.7-0.1,3.5-0.1,5.3c0,101.2,82.4,183.6,183.6,183.6c0.3,0,0.6,0,0.8,0 c20.5,46.8,31.3,97.7,31.3,150.4C827.9,627.5,789,721.5,718.3,792.2z M795.8,322.2c-70.9,0-128.7-57.7-128.7-128.7 c0-70.9,57.7-128.7,128.7-128.7c70.9,0,128.7,57.7,128.7,128.7C924.4,264.5,866.7,322.2,795.8,322.2z"
      />
      <path
        className="icon"
        fill={fill}
        d="M794.3,88.2c-57.4,0-103.9,46.5-103.9,103.9c0,57.4,46.5,103.9,103.9,103.9c57.4,0,103.9-46.5,103.9-103.9 C898.3,134.7,851.8,88.2,794.3,88.2z M851.9,237.6c7-11.3,14.1-25,11.1-39c-5.9-26.8-27.8-33.9-56.1-20.5 c-20.3,9.6-25.9-42.7-74.4-27.9c13.1-21.1,36.5-35.2,63.1-35.2c41,0,74.2,33.2,74.2,74.2C869.9,207.6,863.1,224.6,851.9,237.6z"
      />
      <path
        className="icon"
        fill={fill}
        d="M339.2,984.8l11-402.2c-13.2-2.1-26.7-9.9-37.5-21.8c-11.3-12.5-18.1-27.4-18.1-39.9V386l38-20.7v141.8h20.9V369.6h40.6 v137.5h20.9V365.5l39.3,20.4v134.9c0,12.7-7.4,28.3-19.4,40.9c-11.5,12.1-25.6,19.6-39.2,21.2l6.6,401.8H339.2z"
      />
      <path
        className="icon"
        fill={fill}
        d="M526.8,990l0.4-10.9c0.1-2.5,8.7-240.9,9.1-275.8c-23.1-62.7-34.4-136.9-31-204c3.6-72.3,23.7-127.5,56.4-155.2l17.3-14.7 v22.7c0,45,11.1,621.4,11.2,627.2l0.2,10.7H526.8z"
      />
    </g>
  </svg>
);

EatInIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

EatInIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default EatInIcon;
