import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';

export const Container = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50% - 10px);
    float: left;

    :nth-child(odd) {
      margin-right: 10px;
    }

    :nth-child(even) {
      margin-left: 10px;
    }
  }
`;

export const StyledListOption = styled.div`
  margin: 10px 5px;
  ${fontSizeBig};
`;

export const OptionInfo = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;

export const SuitableForContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const InfoButton = styled.button`
  margin-inline: auto 1rem;

  html[dir='rtl'] & {
    right: initial;
    left: 0;
  }

  @media (min-width: 768px) {
    top: 10px;
  }
`;
