import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';
import { exportOrderRequestStart } from 'actions/user';
import ExportOrderForm from 'components/ExportOrderForm';
import { AlertContent } from 'assets/styles/sharedStyles';

const messages = defineMessages({
  message: {
    defaultMessage: 'A copy of your order will be sent to the email address below.',
  },
});

const propTypes = {
  orderReference: PropTypes.string,
  closeAlert: PropTypes.func,
  exportOrderRequestStart: PropTypes.func,
};

const ExportSingleOrderAlert = ({ orderReference, closeAlert, exportOrderRequestStart }) => {
  const handleOnSubmit = ({ email }, callback) => {
    exportOrderRequestStart(email, orderReference, res => {
      if (res.success) closeAlert();
      callback(res);
    });
  };

  return (
    <>
      <AlertContent>
        <ExportOrderForm
          header={<FormattedMessage {...globalMessages.exportPdf} />}
          message={<FormattedMessage {...messages.message} />}
          handleOnSubmit={handleOnSubmit}
        />
      </AlertContent>
    </>
  );
};

ExportSingleOrderAlert.propTypes = propTypes;

export default connect(null, {
  exportOrderRequestStart,
})(ExportSingleOrderAlert);
