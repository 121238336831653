/**
 * Browse actions
 */

import { REQUEST_MENU, RECEIVE_MENU, REQUEST_SECTION, RECEIVE_SECTION } from './constants';

import { getMenu, getSection } from '../../../shared/dataLoader';

function requestMenu(id) {
  return {
    type: REQUEST_MENU,
    payload: { id },
  };
}

function receiveMenu(menu) {
  return {
    type: RECEIVE_MENU,
    menu,
    receivedAt: Date.now(),
  };
}

function requestSection(id) {
  return {
    type: REQUEST_SECTION,
    payload: { id },
  };
}

function receiveSection(section) {
  return {
    type: RECEIVE_SECTION,
    section,
    receivedAt: Date.now(),
  };
}

// Using async, fetch a single menu.
// Returning a function causes redux-thunk to get involved
// and passes in our dispatch function
export const fetchMenu = (id, venueId) => dispatch => {
  dispatch(requestMenu(id));

  return getMenu(id, venueId, res => dispatch(receiveMenu(res)));
};

export const fetchSection = id => dispatch => {
  dispatch(requestSection(id));

  getSection(id, res => dispatch(receiveSection(res)));
};
