import React from 'react';
import PropTypes from 'prop-types';

const ChatIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 205.000000 205.000000"
  >
    <g transform="translate(0.000000,205.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        className="icon"
        fill={fill}
        d="M965 2015 c-366 -80 -643 -391 -681 -765 -7 -64 -9 -67 -79 -142 -82
-87 -135 -184 -161 -293 -23 -99 -15 -246 18 -341 l25 -72 -44 -128 c-51 -149
-52 -183 -3 -226 42 -39 82 -36 223 14 l119 41 66 -22 c238 -79 475 -26 650
145 l73 72 81 12 c85 12 194 42 241 67 27 14 39 11 222 -52 177 -60 197 -65
233 -56 56 15 102 72 102 124 0 14 -28 108 -63 209 l-62 183 27 71 c79 207 76
445 -9 649 -104 251 -352 453 -623 511 -81 17 -275 17 -355 -1z m333 -121
c320 -66 565 -348 589 -679 9 -123 -12 -225 -79 -393 -12 -32 -9 -45 54 -230
37 -108 65 -198 62 -202 -3 -3 -95 26 -205 63 l-198 68 -70 -30 c-102 -45
-191 -63 -311 -62 -155 0 -279 38 -405 122 -145 98 -258 254 -306 424 -32 113
-32 279 -1 390 78 274 302 479 583 534 72 14 205 12 287 -5z m-994 -926 c18
-114 107 -278 210 -385 120 -127 260 -212 414 -251 40 -10 73 -23 74 -28 2
-18 -128 -95 -195 -116 -97 -29 -244 -22 -338 15 -36 15 -71 27 -77 27 -7 0
-64 -18 -128 -40 -64 -22 -120 -40 -124 -40 -5 0 9 53 31 117 21 64 39 127 39
139 0 12 -12 53 -26 90 -50 131 -42 280 21 406 30 60 75 119 85 113 4 -2 10
-23 14 -47z"
      />
      <path
        className="icon"
        fill={fill}
        d="M740 1470 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33
-20 405 -20 372 0 386 1 405 20 24 24 25 48 4 78 l-15 22 -390 0 c-376 0 -390
-1 -409 -20z"
      />
      <path
        className="icon"
        fill={fill}
        d="M740 1220 c-24 -24 -25 -48 -4 -78 l15 -22 394 0 394 0 15 22 c21 30
20 54 -4 78 -19 19 -33 20 -405 20 -372 0 -386 -1 -405 -20z"
      />
      <path
        className="icon"
        fill={fill}
        d="M740 970 c-24 -24 -25 -48 -4 -78 15 -22 18 -22 268 -22 232 0 254 1
269 18 23 25 21 58 -3 82 -19 19 -33 20 -265 20 -232 0 -246 -1 -265 -20z"
      />
    </g>
  </svg>
);

ChatIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ChatIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ChatIcon;
