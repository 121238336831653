import { fromJS } from 'immutable';
import { SET_KIOSK_MODE, SET_SHOW_EXIT_BUTTON } from 'actions/features/constants';

export const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_KIOSK_MODE: {
      return state.set('kiosk_mode', action.enabled);
    }
    case SET_SHOW_EXIT_BUTTON: {
      return state.set('show_exit_button', action.enabled);
    }
    default:
      return state;
  }
};
