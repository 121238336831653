import styled from 'styled-components';
import { StyledInput } from 'assets/styles/sharedStyles';

export const Container = styled.div`
  background-color: transparent;
  padding: 20px 0;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
`;

export const InputStyled = styled.input`
  max-width: 500px;
  ${StyledInput}
`;

export const TextQuestionContainer = styled.div`
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
`;
