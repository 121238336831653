import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  padding: 10px;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
