import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from 'components/Pages/container';
import PaymentInformation from 'components/PaymentInformation';
import CartFullIcon from 'components/Icons/CartIcon';
import CartEmptyIcon from 'components/Icons/CartEmptyIcon';
import Order from 'components/Order';
import DineInConfirmation from 'components/DineInConfirmation';
import { isDineInOrderingFlow } from 'selectors/root';
import { orderQuantity as selectOrderQuantity, selectOrder } from 'selectors/order';
import globalMessages from 'components/globalMessages';
import CheckoutButton from 'components/Order/CheckoutButton';
import { sendEventBeginCheckout } from 'middleware/analyticsMiddleware';

const propTypes = {
  orderQuantity: PropTypes.number,
  isDineInOrderingFlow: PropTypes.bool,
  sendState: PropTypes.func,
};

const OrderReview = ({ orderQuantity, isDineInOrderingFlow, sendState }) => {
  const CartIcon = orderQuantity > 0 ? CartFullIcon : CartEmptyIcon;

  return (
    <Page
      settingsPage={true}
      titleMessage={globalMessages.orderSummary}
      Icon={CartIcon}
      hideCartButton={true}
    >
      <Order />
      {isDineInOrderingFlow ? (
        <DineInConfirmation />
      ) : (
        <>
          <CheckoutButton onClick={sendState} />
          <PaymentInformation />
        </>
      )}
    </Page>
  );
};

OrderReview.propTypes = propTypes;

const mapStateToProps = (state, { params }) => ({
  orderQuantity: selectOrderQuantity(state),
  isDineInOrderingFlow: isDineInOrderingFlow(state, params?.serviceId),
  order: selectOrder(state),
  sendState: () => sendEventBeginCheckout(state),
});

export default connect(mapStateToProps)(OrderReview);
