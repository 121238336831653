import { browserHistory } from 'react-router';

import fetchHelper from 'utils/fetchHelper';
import { setDetail, removeDetail } from 'actions/storage';
import { selectServiceId } from 'selectors/browse';
import {
  selectDineInSessionStorage,
  selectDineInLocationField,
  selectDineInLocationDetail,
  getServiceId,
} from 'selectors/root';
import { SET_SESSION, CLEAR_SESSION } from 'actions/session/constants';
import { TAB_STATE_CLOSED } from 'appConstants';

export const setSession =
  (sessionData = null) =>
  dispatch => {
    if (sessionData?.tab?.state === TAB_STATE_CLOSED) return browserHistory.replace('/thankyou/tab');

    dispatch({
      type: SET_SESSION,
      session: sessionData,
    });
  };

const sessionFailure = () => (dispatch, getState) => {
  const serviceId = getServiceId(getState());
  const enterLocationRoute = `/service/${serviceId}/location`;
  if (typeof window !== 'undefined' && window.location.pathname !== enterLocationRoute) {
    return browserHistory.replace(enterLocationRoute);
  }
};

export const fetchSession = () => (dispatch, getState) => {
  const sessionId = selectDineInSessionStorage(getState());
  if (!sessionId) return;

  const success = res => {
    dispatch(setSession(res));
  };

  const fail = () => dispatch(sessionFailure());

  return fetchHelper(`/api/sessions/${sessionId}`, 'GET', null, success, fail);
};

export const createSession =
  (locationRef = undefined) =>
  (dispatch, getState) => {
    const state = getState();
    const serviceId = selectServiceId(state);
    const body = {
      serviceId,
      locationRef: locationRef ?? selectDineInLocationDetail(state),
    };

    const success = res => {
      dispatch(setSession(res));
      dispatch(setDetail(`sessionId_${serviceId}`, res?.id, { format: 'hours', length: 1 }));
    };

    const fail = () => dispatch(sessionFailure());

    return fetchHelper(`/api/sessions`, 'POST', body, success, fail);
  };

export const clearSession = () => (dispatch, getState) => {
  const state = getState();
  const serviceId = selectServiceId(state);
  const locationDetailName = selectDineInLocationField(state)?.name;

  if (locationDetailName) dispatch(removeDetail(locationDetailName));

  if (!serviceId) return;

  dispatch({ type: CLEAR_SESSION, serviceId });
  dispatch(removeDetail(`sessionId_${serviceId}`));
};
