import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router';

import imageUtil from 'utils/image';
import BackButton from 'components/BackButton';
import { setIsSettingsPage } from 'actions/UI';
import addCssPrefixTo from 'utils/addCssPrefixTo';

import SystemIcon from 'components/SystemIcon';
import { HeaderStyled, HeaderContainer, StyledImage, HeaderTitle } from './styles';

const propTypes = {
  backButton: PropTypes.instanceOf(Map),
  hideBackButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iconFill: PropTypes.string,
  setIsSettingsPage: PropTypes.func,
  settingsPage: PropTypes.bool,
  pageTitleRef: PropTypes.object,
  theme: PropTypes.object,
  params: PropTypes.object,
};

const defaultProps = {
  backButton: new Map(),
  settingsPage: false,
};

const PageHeader = ({
  setIsSettingsPage,
  params,
  image,
  theme,
  backButton,
  hideBackButton,
  Icon,
  iconFill,
  pageTitleRef,
  settingsPage,
  title,
}) => {
  useEffect(() => {
    setIsSettingsPage(true);

    return () => setIsSettingsPage(false);
  }, [setIsSettingsPage]);

  const isProductPage = () => {
    const { serviceId, menuId, sectionId, productId } = params;

    return Boolean(serviceId && menuId && sectionId && productId);
  };

  return (
    <HeaderStyled className="primaryBackground">
      <HeaderContainer>
        {image && (
          <StyledImage
            tall={isProductPage()}
            className={
              isProductPage() ? addCssPrefixTo('PRODUCT_HEADER_IMAGE') : addCssPrefixTo('HEADER_IMAGE')
            }
            style={{ backgroundImage: `url(${imageUtil(image, { width: window.innerWidth })})` }}
          />
        )}
        {!hideBackButton && <BackButton fill={theme.color.iconFill} link={backButton.get('href')} />}
        {Icon && <SystemIcon Icon={Icon} fill={iconFill} />}
        <HeaderTitle innerRef={pageTitleRef} settingsPage={settingsPage}>
          {title}
        </HeaderTitle>
      </HeaderContainer>
    </HeaderStyled>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default connect(null, { setIsSettingsPage })(withTheme(withRouter(PageHeader)));
