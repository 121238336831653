import styled from 'styled-components';

import { H2 } from 'components/Typography';
import ActionButton from 'components/ActionButton';

export const StyledTitle = styled(H2)`
  text-align: center;
  margin: 10px 0 20px;
`;

export const Container = styled.div`
  margin: 20px auto;
`;

export const StyledText = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledButton = styled(ActionButton)`
  margin-bottom: 10px;
  text-transform: none;
`;
