import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';

import orderMessages from 'components/OrderReview/messages';
import { SummaryContainer } from './styles';
import TabOrderSummary from '../../DineIn/TabOrderSummary';
import GratuityOptions from '../../GratuityOptions';
import GratuityInput from '../../GratuityInput';
import PriceLineItem from './PriceLineItem';
import { getGratuity, getGratuityPrice, showCheckoutGratuity } from '../../../selectors/order';
import { selectTabOrder } from '../../../selectors/session';
import { getServiceId, selectGratuityOptions } from '../../../selectors/root';
import { setGratuity } from '../../../actions/order';
import { Summary } from '../styles';

const TabOrderSummaryField = ({ gratuity, setGratuity, gratuityOptions, order, showGratuity, serviceId }) => {
  const gratuityPrice = getGratuityPrice(order.get('total'), gratuity);
  return (
    <SummaryContainer>
      <Summary>
        <TabOrderSummary order={order} />
      </Summary>

      {showGratuity &&
        order.get('total') !== 0 &&
        (gratuityOptions?.size > 0 ? (
          <GratuityOptions
            options={gratuityOptions}
            selectedGratuity={gratuity.toJS()}
            onChange={setGratuity}
            orderTotal={order.get('total')}
          />
        ) : (
          <GratuityInput
            serviceId={serviceId}
            gratuityPercent={gratuity.get('value')}
            orderTotal={order.get('total')}
            onChange={setGratuity}
          />
        ))}
      <br />

      <PriceLineItem
        text={<FormattedMessage {...orderMessages.total} />}
        price={order.get('total') + gratuityPrice}
      />
    </SummaryContainer>
  );
};

TabOrderSummaryField.propTypes = {
  order: PropTypes.instanceOf(Map),
  gratuity: PropTypes.instanceOf(Map),
  showGratuity: PropTypes.bool,
  gratuityOptions: PropTypes.instanceOf(List),
  gratuityItemsTotal: PropTypes.number,
  setGratuity: PropTypes.func,
  serviceId: PropTypes.string,
};

const mapStateToProps = state => ({
  order: selectTabOrder(state),
  gratuity: getGratuity(state),
  showGratuity: !!showCheckoutGratuity(state),
  gratuityOptions: selectGratuityOptions(state, getServiceId(state)),
  serviceId: getServiceId(state),
});

export default connect(mapStateToProps, { setGratuity })(injectIntl(TabOrderSummaryField));
