import { defineMessages } from 'react-intl';

export default defineMessages({
  thankYou: {
    defaultMessage: 'Thank you!',
  },
  thankYouHeader: {
    defaultMessage: 'Your order was successful.',
  },
  tabCreatedThankYouHeader: {
    defaultMessage: 'A tab has been created and your items have been added.',
  },
  tabAddedThankYouHeader: {
    defaultMessage: 'Your items have been added to your tab.',
  },
  beingProcessed: {
    defaultMessage: 'It is now being processed ',
  },
  registerActionHeader: {
    defaultMessage: 'Want to save your favourites?',
  },
  registerActionBody: {
    defaultMessage:
      'With an account, you can save your favourites, view your order history and checkout even faster!',
  },
  orderMessage: {
    defaultMessage: `{ hasOrderNumber, select,
      true { Confirmation of your order {orderNumber} has been sent to your email {email} }
      other { Confirmation of your order has been sent to your email {email} }
    }`,
  },
});
