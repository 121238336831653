import React, { useEffect } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import InfoBox from 'components/InfoBox';
import { ContactIcon } from 'components/Icons';
import FeedbackButton from 'components/Feedback/FeedbackButton';
import FeedbackInfo from 'components/Feedback/FeedbackInfo';
import PoweredFooter from 'components/PoweredFooter';
import { selectServiceName, selectServicesList } from 'selectors/root';
import { fetchQuestions } from 'actions/feedback';
import { showFeedbackButton } from 'selectors/feedback';
import { shouldShowServiceAddress } from 'selectors/features';
import globalMessages from 'components/globalMessages';
import Page from '../container';

import { Container, CompanyDetails, Label, CompanyName, Draft, FeedbackContainer } from './styles';

const messages = defineMessages({
  contactUs: {
    defaultMessage: 'Contact Us',
  },
  title: {
    defaultMessage: 'Get in Touch',
  },
  message: {
    defaultMessage:
      'If you would like to contact us then please give us a call, send us an email or a letter.',
  },
  is_draft: {
    defaultMessage: 'Draft',
  },
});

const propTypes = {
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  services: PropTypes.instanceOf(List).isRequired,
  getServiceName: PropTypes.func.isRequired,
  showsAddress: PropTypes.bool.isRequired,
  fetchQuestions: PropTypes.func,
  questions: PropTypes.instanceOf(List),
};

const ContactUs = ({ serviceId, getServiceName, services, showsAddress, fetchQuestions }) => {
  useEffect(() => {
    if (serviceId) {
      fetchQuestions(serviceId);
    }
  }, [fetchQuestions, serviceId]);

  const servicesList = services.sort((a, b) => {
    const id = serviceId;
    // Put the current service at the top
    if (a.get('id') === id) return -1;
    return b.get('id') === id ? 1 : 0;
  });

  return (
    <Page titleMessage={messages.contactUs} settingsPage={true} Icon={ContactIcon}>
      <Container>
        <InfoBox
          title={<FormattedMessage {...messages.title} />}
          message={<FormattedMessage {...messages.message} />}
        />
        {servicesList.map(service => (
          <CompanyDetails key={service.get('id')}>
            <CompanyName>{getServiceName(service.get('id'))}</CompanyName>

            {showsAddress && (
              <>
                <Label>
                  <FormattedMessage {...globalMessages.address} />
                </Label>
                <p>
                  {service.get('address1') && <span>{service.get('address1')}, </span>}
                  {service.get('address2') && <span>{service.get('address2')}, </span>}
                  {service.get('county') && <span>{service.get('county')}, </span>}
                  {service.get('country') && <span>{service.get('country')}. </span>}
                </p>
              </>
            )}

            {service.get('phone_number') && (
              <>
                <Label>
                  <FormattedMessage {...globalMessages.telephone} />
                </Label>
                <a href={`tel:${service.get('phone_number')} `}>{service.get('phone_number')}</a>
              </>
            )}

            {service.get('email') && (
              <>
                <Label>
                  <FormattedMessage {...globalMessages.email} />
                </Label>
                <a
                  href={`mailto:${service.get('email')}?Subject=${encodeURIComponent(
                    getServiceName(service.get('id'))?.trim()
                  )}%20Contact%20Us`}
                >
                  {service.get('email')}
                </a>
              </>
            )}

            {service.get('publish_status') === 'draft' && (
              <Draft>
                <FormattedMessage {...messages.is_draft} />
              </Draft>
            )}
          </CompanyDetails>
        ))}

        {showFeedbackButton && (
          <FeedbackContainer>
            <FeedbackInfo />
            <FeedbackButton serviceId={serviceId} trackingLabel="Contact page" />
          </FeedbackContainer>
        )}
      </Container>

      <div className="containerFullPad">
        <PoweredFooter />
      </div>
    </Page>
  );
};

ContactUs.propTypes = propTypes;

export default connect(
  (state, { params }) => {
    const serviceId = params?.serviceId || false;
    return {
      services: selectServicesList(state).toList(),
      serviceId,
      getServiceName: id => selectServiceName(state, id),
      showsAddress: shouldShowServiceAddress(state),
      showFeedbackButton: showFeedbackButton(state, serviceId),
    };
  },
  { fetchQuestions }
)(ContactUs);
