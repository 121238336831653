import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';

import { orderQuantity } from 'selectors/order';
import { selectIsOrganisation } from 'selectors/root';
import CartEmptyIcon from 'components/Icons/CartEmptyIcon';

import { Cart, CartCount } from './styles';

const propTypes = {
  orderQuantity: PropTypes.number,
  className: PropTypes.string,
  isOrganisation: PropTypes.bool,
};

const CartButton = ({ orderQuantity, className, isOrganisation }) => {
  if (isOrganisation) return null;

  return (
    <Cart className={className}>
      <CartEmptyIcon />

      {orderQuantity > 0 && (
        <CartCount>
          <FormattedNumber value={orderQuantity} />
        </CartCount>
      )}
    </Cart>
  );
};

CartButton.propTypes = propTypes;

export default connect((state, { serviceId }) => ({
  orderQuantity: orderQuantity(state, serviceId),
  isOrganisation: selectIsOrganisation(state),
}))(CartButton);
