import React from 'react';
import FormattedPrice from 'components/FormattedPrice';
import { GratuityOptionAmount, GratuityOptionButton } from './styles';
import { GratuityOptionProps } from './types';

const GratuityOption = ({ fade, onClick, option, orderTotal, selected }: GratuityOptionProps) => (
  <GratuityOptionButton
    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      selected ? onClick(null) : onClick(option.amount);
    }}
    fade={fade}
    selected={selected}
  >
    <GratuityOptionAmount>{option.type === 'percentage' && `${option.amount}%`}</GratuityOptionAmount>
    {option.type === 'percentage' && (
      <FormattedPrice value={(orderTotal / 100) * option.amount} showCurrency={false} />
    )}
  </GratuityOptionButton>
);

export default GratuityOption;
