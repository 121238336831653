import React, { ElementType } from 'react';
import { Link } from 'react-router';

import { IconLabel, LinkContainer } from './styles';

type Props = {
  targetBlank?: boolean;
  href?: string;
  icon?: ElementType;
  label?: ElementType;
  onClick?: () => void;
  to?: object;
};

const SidebarLink = ({ icon, label, targetBlank, href, onClick, to }: Props) => {
  const LinkElement = href ? 'a' : Link;

  return (
    <LinkContainer>
      <LinkElement target={targetBlank ? '_blank' : ''} href={href} onClick={onClick} to={to}>
        {icon}
        <IconLabel>{label}</IconLabel>
      </LinkElement>
    </LinkContainer>
  );
};

export default SidebarLink;
