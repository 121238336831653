import React from 'react';
import PropTypes from 'prop-types';

import CheckboxInput from 'components/Checkbox/CheckboxInput';
import { PaymentChoiceLabel, PaymentMethodImg, PaymentMethodIcon, PaymentMethodDetail } from './styles';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.node,
  detail: PropTypes.node,
  image: PropTypes.string,
  icon: PropTypes.node,
  checked: PropTypes.bool,
  grouped: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const PaymentChoice = ({ id, checked, label, detail, image, icon, grouped, disabled, onChange }) => (
  <PaymentChoiceLabel htmlFor={id} grouped={grouped} disabled={disabled}>
    <CheckboxInput type="checkbox" checked={checked} onChange={onChange} id={id} />

    <span className="u-flex u-flexAlignItemsCenter">
      {image && <PaymentMethodImg src={image} alt="" height="28" />}
      {icon && <PaymentMethodIcon>{icon}</PaymentMethodIcon>}
      <span className="u-flex u-flexCol">
        <p>{label}</p>
        {detail && <PaymentMethodDetail>{detail}</PaymentMethodDetail>}
      </span>
    </span>
  </PaymentChoiceLabel>
);

PaymentChoice.propTypes = propTypes;

export default PaymentChoice;
