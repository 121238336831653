import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { AlertButtons } from 'assets/styles/sharedStyles';
import { setLoyaltyUser } from 'actions/loyalty';
import fetchData from 'utils/fetchHelper';

const AuraSignOut = ({ closeAlert, dispatch, setPage }) => {
  const handleSignOut = async () => {
    const res = await fetchData(`/api/loyalty/user/sign_out`, 'POST');

    if (res.ok) {
      dispatch(setLoyaltyUser());
      setPage('signOutSuccess');
    }
  };

  return (
    <>
      <FormattedMessage defaultMessage="Are you sure you'd like to sign out of your Aura account?" />
      <AlertButtons>
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage defaultMessage="Cancel" />}
          onClick={closeAlert}
        />
        <ActionButton label={<FormattedMessage defaultMessage="Sign Out" />} onClick={handleSignOut} />
      </AlertButtons>
    </>
  );
};

AuraSignOut.propTypes = {
  setPage: PropTypes.func,
  closeAlert: PropTypes.func,
  dispatch: PropTypes.func,
};

export default connect()(AuraSignOut);
