import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { generateMenuTypeMsg } from 'utils';
import {
  getOrganisationVenue,
  getVenueEnabledMenuTypesIds,
  selectVenueDeliveryPartnersByVenueId,
} from 'selectors/organisation';
import { openDeliveryPartnersAlert } from 'actions/UI';
import globalMessages from 'components/globalMessages';
import { showExitButton } from 'selectors/features';
import { selectGetStartedLink } from 'selectors/browse';
import { ContainerLink } from './styles';

const propTypes = {
  enabledMenuTypesIds: PropTypes.instanceOf(List),
  getLink: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  venueDeliveryPartners: PropTypes.instanceOf(Map),
  openDeliveryPartnersAlert: PropTypes.func,
  venueId: PropTypes.number,
  showExitButton: PropTypes.bool,
};

const FulfilmentTypes = ({
  enabledMenuTypesIds,
  getLink,
  disabled,
  venueDeliveryPartners,
  openDeliveryPartnersAlert,
  venueId,
  showExitButton,
}) => {
  const getVenueUrl = id => {
    const link = getLink(id);
    let url = `${link}?menuTypeId=${id}`;
    if (showExitButton) url += '&showExitButton=1';
    return url;
  };

  return (
    <div>
      {enabledMenuTypesIds?.size > 0 &&
        enabledMenuTypesIds.map(id => (
          <ContainerLink href={disabled ? undefined : getVenueUrl(id)} disabled={disabled} key={id}>
            {generateMenuTypeMsg(id)}
          </ContainerLink>
        ))}

      {venueDeliveryPartners?.size > 0 && (
        <ContainerLink onClick={() => openDeliveryPartnersAlert(venueId)} disabled={disabled} as="button">
          <FormattedMessage {...globalMessages.delivery} />
        </ContainerLink>
      )}
    </div>
  );
};

FulfilmentTypes.propTypes = propTypes;

export default connect(
  (state, { venueId }) => ({
    getLink: menuTypeId => {
      const venue = getOrganisationVenue(state, venueId);
      const base = __config__.APP_BASE_URL.replace('{{route}}', venue.getIn(['routes', 0, 'path']));
      return base + selectGetStartedLink(new Map({ venue }), menuTypeId);
    },
    enabledMenuTypesIds: getVenueEnabledMenuTypesIds(state, venueId),
    venueDeliveryPartners: selectVenueDeliveryPartnersByVenueId(state, venueId),
    showExitButton: showExitButton(state),
  }),
  { openDeliveryPartnersAlert }
)(FulfilmentTypes);
