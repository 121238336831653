import { defineMessages } from 'react-intl';
import {
  ERROR_CREATE_TAB,
  ERROR_PAY_TAB,
  ERROR_PRE_AUTH,
  ERROR_PAYMENT_SOURCE_PAYMENT,
} from 'errorHandling/errorKeys';

export default defineMessages({
  loadingTab: {
    defaultMessage: 'Getting your tab...',
  },
  createTab: {
    defaultMessage: 'Create a Tab',
  },
  noTabTitle: {
    defaultMessage: 'No tab found',
  },
  noTabMessage: {
    defaultMessage: "You don't currently have an open tab",
  },
  noTabOrdersTitle: {
    defaultMessage: 'Tab Empty',
  },
  noTabOrdersMessage: {
    defaultMessage: 'No orders have been placed against your tab yet',
  },
  tabOpen: {
    defaultMessage: 'Tab Open',
  },
  selectTabPayment: {
    defaultMessage:
      'Select a payment method to secure your tab against. Please note that no money will be deducted from your card at this point.',
  },
  loadingPaymentSources: {
    defaultMessage: 'Loading your payment methods...',
  },
  noPaymentSources: {
    defaultMessage: 'Sorry, there was an error retrieving your payment methods',
  },
  addPaymentMethod: {
    defaultMessage: 'Add Payment Method',
  },
  addPaymentMethodError: {
    defaultMessage: 'Sorry, we are currently unable to add a payment method. Please try again later.',
  },
  [ERROR_CREATE_TAB]: {
    defaultMessage: 'Sorry, there was an error creating your tab',
  },
  [`${ERROR_PRE_AUTH}Header`]: {
    defaultMessage: 'Authorisation Declined',
  },
  [ERROR_PRE_AUTH]: {
    defaultMessage: 'There was a problem authorising your card. Please check the details or try another card',
  },
  [ERROR_PAY_TAB]: {
    defaultMessage: 'Sorry, there was an error paying your tab',
  },
  [`${ERROR_PAYMENT_SOURCE_PAYMENT}Header`]: {
    defaultMessage: 'Payment Error',
  },
  [ERROR_PAYMENT_SOURCE_PAYMENT]: {
    defaultMessage:
      'There was a problem paying your tab. Please check your card details or select another card',
  },
  tabPaidHeader: {
    defaultMessage: 'Payment Successful',
  },
  tabPaid: {
    defaultMessage: 'Thank you for your payment! Your tab has been paid.',
  },
  tabInformation: {
    defaultMessage: 'Tab Information',
  },
  tabFrom: {
    defaultMessage: 'Tab From:',
  },
  tabTotalLabel: {
    defaultMessage: 'Tab Total:',
  },
  tabCreatedTime: {
    defaultMessage: 'Tab Created:',
  },
  tabCompletedTime: {
    defaultMessage: 'Tab Completed:',
  },
  tabStatus: {
    defaultMessage: 'Tab Status:',
  },
  open: {
    defaultMessage: 'Open',
  },
  paid: {
    defaultMessage: 'Paid',
  },
  tabOrdersSummary: {
    defaultMessage: 'Tab Orders Summary',
  },
  tabTotal: {
    defaultMessage: 'Tab Total',
  },
});
