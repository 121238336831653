import styled, { keyframes } from 'styled-components';

const scaleBar = keyframes`
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1f907e;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: ${spin} 2s linear infinite;
  margin: 0 auto;
`;

export const SpinnerContainer = styled.div`
  padding: ${({ noPad }) => (noPad ? '0' : '10px')};
  position: relative;
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: absolute;
`;

export const LoadingBarsContainer = styled.div`
  color: ${({ darkMode }) => (darkMode ? '#f1f1f1' : '#444')};
  position: absolute;
  top: 40%;
  left: 50%;
  padding: 15px;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
  background: ${({ darkMode }) => (darkMode ? 'rgba(63, 63, 63, 0.4)' : 'rgba(255, 255, 255, 0.6)')};
  box-shadow: ${({ darkMode }) =>
    darkMode ? '0 0 100px 40px rgba(63, 63, 63, 0.4)' : '0 0 20px 10px rgba(255, 255, 255, 0.6)'};
  white-space: nowrap;
`;

export const AnimatedBars = styled.div`
  height: 60px;
  text-align: center;
`;

export const Bar = styled.div`
  background-color: #1f907e;
  height: 100%;
  width: 6px;
  margin-right: 3px;
  display: inline-block;
  animation: ${scaleBar} 1.2s infinite ease-in-out;
  animation-delay: ${({ delayTime }) => (delayTime ? `${delayTime}s` : '0')};
`;
