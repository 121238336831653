import styled, { css } from 'styled-components';

import { StyledInput } from 'assets/styles/sharedStyles';
import TickIcon from 'components/Icons/TickIcon';

export const Input = styled.input`
  ${StyledInput}
`;

export const Requirement = styled.span`
  ${props =>
    props.success &&
    css`
      color: ${({ theme }) => theme.color.success};
    `}
`;

export const StyledTickIcon = styled(TickIcon)`
  margin-left: 5px;
`;
