import { fromJS } from 'immutable';

const donationTransformer = don =>
  fromJS({
    productId: don.get('product_id'),
    product: don.get('product'),
    name: don.get('name'),
    price: don.get('price'),
    key: don.get('key'),
  });

export default donationTransformer;
