import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { ImageIcon, MinusIcon, TickIcon, AddToCartIcon, InfoIcon } from 'components/Icons';
import ProductPrice from 'components/Product/price';
import SuitableFor from 'components/SuitableFor';
import image from 'utils/image';
import globalMessages from 'components/globalMessages';
import { getLocale } from 'selectors/user';

import {
  MiniProduct,
  Image,
  AwaitingIcon,
  AwaitingMsg,
  CheckedIcon,
  InfoContainer,
  Icon,
  Name,
  Description,
} from './styles';

const ExtendedOption = ({
  product,
  name,
  price,
  onChange,
  onInfo,
  checked,
  showImage = true,
  imagePath,
  browseOnly,
  currentLocale,
}) => {
  let ActionIcon = !checked ? AddToCartIcon : MinusIcon;
  if (browseOnly) ActionIcon = InfoIcon;
  return (
    <MiniProduct checked={checked} onClick={onInfo}>
      {showImage && (
        <Image awaiting={!imagePath} checked={checked}>
          {imagePath && <img src={image(imagePath, { height: 700 })} alt={name} />}
          {!imagePath && (
            <>
              <AwaitingIcon>
                <ImageIcon />
              </AwaitingIcon>
              <AwaitingMsg>
                <FormattedMessage {...globalMessages.awaitingImage} />
              </AwaitingMsg>
            </>
          )}
          {checked && (
            <CheckedIcon>
              <TickIcon width="20px" height="20px" />
            </CheckedIcon>
          )}
        </Image>
      )}
      <InfoContainer>
        <Name>{name}</Name>
        <Description>
          {product?.get('translations')?.get(currentLocale)?.get('description') ??
            product?.get('description')}
        </Description>
        <div>
          <ProductPrice price={price} />
          {product && <SuitableFor product={product} />}
        </div>
      </InfoContainer>
      <Icon
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (browseOnly) onInfo();
          else onChange();
        }}
      >
        <ActionIcon width="20px" height="20px" />
      </Icon>
    </MiniProduct>
  );
};

ExtendedOption.propTypes = {
  product: PropTypes.instanceOf(Map),
  name: PropTypes.string,
  price: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  imagePath: PropTypes.string,
  onInfo: PropTypes.func,
  showImage: PropTypes.bool,
  required: PropTypes.bool,
  browseOnly: PropTypes.bool,
  currentLocale: PropTypes.string,
};

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(ExtendedOption);
