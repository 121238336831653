import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerContainer, Spinner } from './styles';

const LoadingSpinner = ({ width, height, className, noPad }) => (
  <SpinnerContainer className={className} width={width} height={height} noPad={noPad}>
    <Spinner />
  </SpinnerContainer>
);

LoadingSpinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  noPad: PropTypes.bool,
  className: PropTypes.string,
};

export default LoadingSpinner;
