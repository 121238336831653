import React from 'react';
import PropTypes from 'prop-types';

import FieldLabel from 'components/Form/Fields/Label';
import Choosey from 'components/Choosey';
import { onChangeSetIdFromIndex } from 'components/Form/utils';

const propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  intlIdentifier: PropTypes.string,
  intlLabel: PropTypes.string,
  required: PropTypes.bool,
};

const CustomFieldsMultiChoice = ({ options, value, onChange, intlIdentifier, intlLabel, required }) => {
  if (options?.length === 0) return null;

  const selectedValueIndex = options.findIndex(opt => opt.id === value);

  return (
    <div>
      <FieldLabel intlIdentifier={intlIdentifier} intlLabel={intlLabel} required={required} />

      <Choosey
        maxChoices={1}
        id={intlIdentifier}
        values={[selectedValueIndex]}
        choices={options.map(option => option.name)}
        onChange={onChangeSetIdFromIndex(options, onChange)}
      />
    </div>
  );
};

CustomFieldsMultiChoice.propTypes = propTypes;

export default CustomFieldsMultiChoice;
