import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { AllergenThemedIcon } from 'components/Icons';
import { openAllergenInfo } from 'actions/UI';
import AdditionalButton from '../ProductAdditionalButton';

const messages = defineMessages({
  allergen_information: {
    defaultMessage: 'Allergen Information',
  },
  allergens: {
    defaultMessage: 'Allergens',
  },
});

const AllergenInformationButton = ({ openAllergenInfo, smallBtn }) => (
  <AdditionalButton onClick={openAllergenInfo} smallBtn={smallBtn}>
    <AllergenThemedIcon width="24px" height="24px" />

    <span>
      {smallBtn ? (
        <FormattedMessage {...messages.allergens} />
      ) : (
        <FormattedMessage {...messages.allergen_information} />
      )}
    </span>
  </AdditionalButton>
);

AllergenInformationButton.propTypes = {
  openAllergenInfo: PropTypes.func,
  smallBtn: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, { product }) => ({
  openAllergenInfo: () => dispatch(openAllergenInfo(product)),
});

export default connect(undefined, mapDispatchToProps)(AllergenInformationButton);
