import dietaryFilters from 'components/Alert/alerts/AllergenFilter/dietaryFilters';
import { allergenContactStaff } from 'selectors/features';

import { getDetail } from '../storage';

export const selectSetAllergenFilters = state => selectSetFilters(state, 'allergens');

export const selectSetLifestyleFilters = state => selectSetFilters(state, 'lifestyleChoices');

export const selectSetFilters = (state, category) => {
  if (!getDetail(state, category) || allergenContactStaff(state)) {
    return [];
  }
  return dietaryFilters
    .get(category)
    .filter(key => getDetail(state, category).indexOf(key) !== -1)
    .toJS();
};

export const selectIsFiltered = state =>
  !allergenContactStaff(state) &&
  !!(selectSetAllergenFilters(state).length || selectSetLifestyleFilters(state).length);
