import React from 'react';
import PropTypes from 'prop-types';

import { LineItemContainer, LineItemPrice, PriceLabel } from '../OrderItem/styles';

const OrderLineItem = ({ message, price, taxIncludedMsg }) => (
  <LineItemContainer>
    <PriceLabel>
      {message} {taxIncludedMsg && <small>{taxIncludedMsg}</small>}
    </PriceLabel>
    <LineItemPrice>{price}</LineItemPrice>
  </LineItemContainer>
);

OrderLineItem.propTypes = {
  message: PropTypes.node,
  price: PropTypes.object,
  taxIncludedMsg: PropTypes.node,
};

export default OrderLineItem;
