import React from 'react';
import PropTypes from 'prop-types';

// Icon
const VeganIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon veganIcon"
        fill={fill}
        opacity={opacity}
        d="M625.7,101.6L370.8,890.2h-106L10,101.6h94.2c10.2,0,18.5,3.1,24.9,9.3c6.4,6.2,11.2,14.2,14.4,24l148.8,481.1
        c4.9,15.6,9.7,32.8,14.2,51.5c4.5,18.7,8.8,38.3,12.9,58.6c3.5-20.4,7.3-39.9,11.3-58.6c4.1-18.7,8.6-35.9,13.5-51.5l147.9-481.1
        c2.3-8.4,6.9-16,13.7-22.9c6.8-6.9,15.2-10.4,25.1-10.4H625.7z"
      />
      <path
        className="icon veganIcon"
        fill={fill}
        opacity={opacity}
        d="M687.6,627.4c1.7,28.7,5.8,53.5,12.2,74.4c6.4,20.9,14.8,38.2,25.3,51.8c10.5,13.6,22.9,23.8,37.3,30.5
      c14.4,6.7,30.3,10.1,47.8,10.1c17.5,0,32.5-2.5,45.2-7.6c12.7-5.1,23.7-10.7,33.2-16.9c9.4-6.2,17.7-11.8,24.9-16.9
      c7.1-5.1,14-7.6,20.7-7.6c9,0,15.7,4.2,20.1,12.5l31,49.1c-11.9,17.5-25.3,32.1-40.1,43.9c-14.8,11.8-30.3,21.3-46.5,28.4
      c-16.1,7.1-32.6,12.1-49.3,15c-16.7,2.9-32.9,4.4-48.7,4.4c-31.1,0-60.1-6.5-86.8-19.4c-26.8-12.9-50-32-69.8-57.3
      c-19.8-25.3-35.3-56.5-46.7-93.8c-11.3-37.3-17-80.4-17-129.5c0-38.2,4.9-74.1,14.8-107.7c9.9-33.6,24.1-62.9,42.5-87.8
      c18.5-24.9,41-44.6,67.6-59.2c26.6-14.5,56.6-21.8,90.1-21.8c28.2,0,54.2,5.6,78.1,16.9c23.8,11.3,44.4,27.7,61.5,49.4
      c17.2,21.6,30.6,48.2,40.4,79.6c9.7,31.5,14.6,67.4,14.6,107.7c0,20.4-1.7,34.1-5.2,41.2c-3.5,7.1-10.2,10.6-20.1,10.6H687.6z
        M893.1,546.1c0-17.5-2-33.9-5.9-49.4c-3.9-15.5-9.8-29-17.7-40.6c-7.9-11.6-17.8-20.8-29.9-27.5c-12.1-6.7-26.1-10.1-42.1-10.1
      c-31.1,0-55.6,11.1-73.5,33.3c-17.9,22.2-29.3,53.6-34.3,94.4H893.1z"
      />
    </g>
  </svg>
);

VeganIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

VeganIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default VeganIcon;
