import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { useTheme } from 'styled-components';
import NotesIcon from 'components/Icons/NotesIcon';
import CrossIcon from 'components/Icons/CrossIcon';
import ProductPrice from 'components/Product/price';
import FormattedModifierValue from 'components/FormattedModifierValue';
import { getModifierPrice } from 'selectors/browse';
import { getOrderItemTotal } from 'selectors/order';
import ItemName from 'components/OrderReview/ItemName';
import globalMessages from 'components/globalMessages';

import messages from '../messages';
import {
  OrderLineItemContainer,
  EditContainer,
  ItemDetails,
  StyledName,
  RemoveContainer,
  Modifier,
  ModifierPrice,
  Price,
} from './styles';

const OrderItem = ({ item, onEdit, onRemove, orderItemTotal, getModifierPrice }) => {
  const theme = useTheme();
  return (
    <OrderLineItemContainer>
      <ItemDetails>
        <StyledName>
          <ItemName item={item} />
        </StyledName>
        {onEdit && (
          <EditContainer onClick={onEdit}>
            <FormattedMessage {...globalMessages.edit} />
          </EditContainer>
        )}
        {onRemove && (
          <RemoveContainer onClick={onRemove}>
            <CrossIcon width="14px" height="14px" fill={theme.color.danger} />
          </RemoveContainer>
        )}
      </ItemDetails>
      {item.get('modifiers') && (
        <div>
          {item.get('modifiers').map(modifier =>
            modifier.get('values').map((value, index) => (
              <Modifier key={index}>
                {`${modifier.getIn(['modifier', 'name'])}: `}
                <FormattedModifierValue
                  value={value.get('value')}
                  type={modifier.getIn(['modifier', 'type'])}
                />
                {value.get('quantity') ? ` x ${value.get('quantity')}` : ''}

                <ModifierPrice>
                  {!value.get('price_adjustment') ? (
                    ' - '
                  ) : (
                    <ProductPrice price={getModifierPrice(value, item.get('tax_category_ids'))} />
                  )}
                </ModifierPrice>
              </Modifier>
            ))
          )}
        </div>
      )}
      {(item.get('notes') || null) && (
        <div>
          <NotesIcon height="20px" />
          <FormattedMessage {...messages.has_notes} />
        </div>
      )}
      <Price>
        <ProductPrice price={orderItemTotal} />
      </Price>
    </OrderLineItemContainer>
  );
};

OrderItem.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  item: PropTypes.instanceOf(Map).isRequired,
  getModifierPrice: PropTypes.func,
  orderItemTotal: PropTypes.number,
};

const mapStateToProps = (state, { item }) => ({
  getModifierPrice: (modifier, taxCategories) => getModifierPrice(state, modifier, taxCategories),
  orderItemTotal: getOrderItemTotal(state, item),
});

export default connect(mapStateToProps)(OrderItem);
