import React from 'react';
import PropTypes from 'prop-types';

const SurveyIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 52.5 70"
  >
    <path
      fill={fill}
      className="icon surveyIcon"
      d="M178.438,18.75H166.324a7.221,7.221,0,0,0,.082-1.094,7.656,7.656,0,1,0-15.312,0,7.235,7.235,0,0,0,.082,1.094H139.063a6.564,6.564,0,0,0-6.562,6.563V73.437A6.564,6.564,0,0,0,139.063,80h39.375A6.564,6.564,0,0,0,185,73.437V25.312A6.564,6.564,0,0,0,178.438,18.75ZM158.75,14.375a3.281,3.281,0,1,1-3.281,3.281A3.273,3.273,0,0,1,158.75,14.375Zm21.875,59.062a2.194,2.194,0,0,1-2.188,2.188H139.063a2.194,2.194,0,0,1-2.187-2.187V25.312a2.194,2.194,0,0,1,2.188-2.187h6.563v2.734a1.645,1.645,0,0,0,1.641,1.641h22.969a1.645,1.645,0,0,0,1.641-1.641V23.125h6.563a2.194,2.194,0,0,1,2.188,2.188Zm-8.053-32.32a1.655,1.655,0,0,0-2.324-.014L154.156,57.072l-6.891-6.918a1.642,1.642,0,0,0-2.324,0l-1.162,1.162a1.642,1.642,0,0,0,0,2.324l9.215,9.242a1.642,1.642,0,0,0,2.324,0L173.748,44.6a1.655,1.655,0,0,0,.014-2.324l-1.189-1.162Z"
      transform="translate(-132.5 -10)"
    />
  </svg>
);

SurveyIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SurveyIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default SurveyIcon;
