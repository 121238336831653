import React from 'react';
import PropTypes from 'prop-types';

import Choosey from 'components/Choosey';
import QuestionTitle from './QuestionTitle';

const MultipleChoice = ({ id, question, choices, maxChoices, value = [], onUpdate }) => (
  <div>
    <QuestionTitle>{question}</QuestionTitle>
    <Choosey id={id} values={value} choices={choices} onChange={onUpdate} maxChoices={maxChoices} />
  </div>
);

MultipleChoice.propTypes = {
  id: PropTypes.string,
  value: PropTypes.array,
  onUpdate: PropTypes.func,
  choices: PropTypes.array,
  question: PropTypes.string,
  maxChoices: PropTypes.number,
};

export default MultipleChoice;
