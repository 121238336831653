import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { editAddress } from 'actions/user';
import Checkbox from 'components/Checkbox';
import { AddressText } from 'components/Address';
import globalMessages from 'components/globalMessages';
import { AddressContainer, AddressChoiceLabel, VerifyButton, DisabledMsg } from './styles';

type Props = {
  address?: Map<string, any>;
  isDefault?: boolean;
  checked?: boolean;
  addressId?: string;
  disabled?: boolean;
  onChange?: () => void;
  editAddress?: (address: {}, addressId: string) => void;
  disabledMsg?: ReactNode;
};

const AddressChoice = ({
  address,
  isDefault,
  checked,
  addressId,
  disabled,
  onChange,
  editAddress,
  disabledMsg,
}: Props) => (
  <AddressContainer disabled={disabled}>
    {!address.get('latitude') && !address.get('longitude') && (
      <VerifyButton onClick={() => editAddress(address?.toJS(), addressId)} type="button">
        <FormattedMessage {...globalMessages.verify} />
      </VerifyButton>
    )}

    <AddressChoiceLabel htmlFor={addressId}>
      <Checkbox checked={checked} id={addressId} onChange={onChange} disabled={disabled} />
      <AddressText address={address} isDefault={isDefault} />
    </AddressChoiceLabel>

    {disabled && disabledMsg && <DisabledMsg>{disabledMsg}</DisabledMsg>}
  </AddressContainer>
);

export default connect(null, { editAddress })(AddressChoice);
