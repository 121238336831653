import { SET_KIOSK_MODE, SET_SHOW_EXIT_BUTTON } from './constants';

export const setKioskMode = enabled => ({
  type: SET_KIOSK_MODE,
  enabled,
});

export const setShowExitButton = enabled => ({
  type: SET_SHOW_EXIT_BUTTON,
  enabled,
});
