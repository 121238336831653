import styled, { css } from 'styled-components';
import { addCssPrefixTo } from '../../utils';

export const StyledPage = styled.div.attrs({
  className: addCssPrefixTo('PAGE'),
})`
  background: ${({ theme }) => theme.color.primaryBackground};
  color: ${({ theme }) => theme.color.text};
  padding: 48px 0 20px 0;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  ${props =>
    props.container &&
    css`
      position: relative;
      min-height: 320px;

      ${props =>
        props.showFooter &&
        css`
          padding-bottom: 58px;
        `}
    `}
`;
