import styled from 'styled-components';
import { fontSizeBig, fontSizeMedium } from 'assets/styles/fonts';

export const OrderContainer = styled.div`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const OrderInfoContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
`;

export const OrderInfoRow = styled.div`
  ${fontSizeMedium};
  padding: 2px 0;
  display: flex;

  & > * {
    width: 60%;
  }
`;

export const InfoLabel = styled.span`
  color: #999;
  width: 40%;
`;

export const Title = styled.h3`
  ${fontSizeBig};
  font-weight: 400;
  margin: 0 0 5px;
`;

export const CellsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  }
`;

export const Cell = styled.div`
  flex-grow: ${({ fillSpace }) => fillSpace && '1'};
  margin: ${({ fillSpace }) => (fillSpace ? '0' : '0 0 0 2px')};
  min-width: ${({ fillSpace }) => !fillSpace && '75px'};

  html[dir='rtl'] & {
    margin: ${({ fillSpace }) => (fillSpace ? '0' : '0 2px 0 0')};
  }
`;

export const ProductName = styled.p`
  ${fontSizeBig};
`;

export const Modifier = styled.span`
  font-style: italic;
  ${fontSizeMedium};
`;

export const TabOrderLabel = styled.p`
  font-weight: bold;
`;

export const TabItemsContainer = styled.div`
  margin: 0 0 10px 20px;

  html[dir='rtl'] & {
    margin: 0 20px 10px 0;
  }

  ${CellsContainer} {
    border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  }
`;

export const TotalLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`;

export const Price = styled.div`
  min-width: 75px;
  text-align: right;
  font-weight: bold;
  align-self: flex-end;
  ${fontSizeMedium};

  html[dir='rtl'] & {
    text-align: left;
  }
`;

export const SubCell = styled.div`
  padding-top: 10px;
  font-size: 13px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
`;
