import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage, defineMessages } from 'react-intl';

import MinusIcon from 'components/Icons/MinusIcon';
import PlusIcon from 'components/Icons/AddToCartIcon';

import { Container, MinusButton, PlusButton, Quantity, HiddenQuantityText } from './styles';

const messages = defineMessages({
  quantityLabel: {
    defaultMessage: 'Quantity:',
  },
});

const QuantityButtons = ({ quantity, onChange, maxQuantity, allowZero }) => (
  <Container>
    <HiddenQuantityText>
      <FormattedMessage {...messages.quantityLabel} />
    </HiddenQuantityText>
    <MinusButton
      disabled={(!allowZero && quantity === 1) || quantity === 0}
      type="button"
      onClick={() => onChange(Math.max(quantity - 1, allowZero ? 0 : 1))}
    >
      <MinusIcon />
    </MinusButton>
    <Quantity>
      <FormattedNumber value={quantity} />
    </Quantity>
    <PlusButton
      type="button"
      disabled={maxQuantity !== null && maxQuantity === quantity}
      onClick={() => onChange(quantity + 1)}
    >
      <PlusIcon />
    </PlusButton>
  </Container>
);

QuantityButtons.defaultProps = {
  maxQuantity: null,
};

QuantityButtons.propTypes = {
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  maxQuantity: PropTypes.number,
  allowZero: PropTypes.bool,
};

export default QuantityButtons;
