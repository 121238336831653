import styled from 'styled-components';
import { transparentize } from 'polished';
import { H4 } from 'components/Typography';
import ToggleCheckbox from 'components/ToggleCheckbox';

export const MainContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.inputBackground};
  background-size: cover;
  background-position-y: bottom;
  border-radius: 5px;
  margin-bottom: 1rem;

  html[dir='rtl'] & {
    background-position: left;
  }
`;

export const OptInToggle = styled(ToggleCheckbox)`
  margin: 0;
  min-width: 110px;
`;

export const Overlay = styled.div`
  padding: 15px;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${({ optIn, theme }) => (optIn ? theme.color.borderColor : 'transparent')};
  width: 100%;
  height: 100%;
  transition: background-color 0.4s ease-out;
  background-color: ${({ optIn, theme }) => transparentize(optIn ? 0.2 : 0.1, theme.color.inputBackground)};
`;

export const Container = styled.div`
  transition: opacity 0.4s ease-out;
  opacity: ${({ optIn, override }) => {
    if (!optIn) return override ? '1' : '0.3';
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${({ optIn, override }) => {
    if (!optIn) return override ? 'normal' : 'none';
  }};
  width: 100%;
`;

export const Spacer = styled.div`
  margin-block: 2rem;
`;

export const DonationName = styled(H4)`
  align-items: center;
  color: ${({ theme }) => theme.color.primaryText};
  display: flex;
  font-weight: normal;
  gap: 0.5rem;
`;

export const DonationPrice = styled.span`
  color: ${({ theme }) => theme.color.primaryText};
`;

export const InfoButton = styled.button`
  color: ${({ theme }) => theme.color.primaryText};
  border-bottom: 1px solid ${({ theme }) => theme.color.primaryText};
`;

export const QuantityButtonsContainer = styled.div`
  width: 140px;
`;

export const TermsContainer = styled.div`
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  padding: 15px 0 0;
  transition: opacity 0.4s ease-out;
  opacity: ${({ optIn }) => (optIn ? '1' : '0.3')};
  font-size: 0.8em;
`;

export const SlimDonation = styled.div`
  align-items: center;
  border-top: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const SlimPrice = styled.div`
  align-items: center;
  gap: 0.5rem;
  display: flex;
  margin-left: 10px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 10px;
  }
`;
