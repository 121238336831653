import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';
import { openRadiusAlert } from 'actions/UI';
import { DeliveryIcon } from 'components/Icons';
import { isDeliveryToAddressEnabled } from 'selectors/root';
import {
  isLoggedIn,
  selectUserLatitude,
  selectUserLongitude,
  selectIsUserAddressInRange,
} from 'selectors/user';

import { Container, TextMessage, ButtonChange } from './styles';

const propTypes = {
  isLoggedIn: PropTypes.bool,
  isDeliveryToAddressEnabled: PropTypes.bool,
  userLatitude: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  userLongitude: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  openRadiusAlert: PropTypes.func,
  isAddressUserInRange: PropTypes.bool,
};

const RadiusMessage = ({
  isLoggedIn,
  isDeliveryToAddressEnabled,
  userLatitude,
  userLongitude,
  openRadiusAlert,
  isAddressUserInRange,
}) => {
  if (isLoggedIn || !isDeliveryToAddressEnabled || (!userLatitude && !userLongitude)) return null;

  return (
    <Container>
      <div>
        <DeliveryIcon width={24} height={24} />
        <TextMessage>
          {isAddressUserInRange ? (
            <FormattedMessage {...globalMessages.deliveryAddressAvailable} />
          ) : (
            <FormattedMessage {...globalMessages.deliveryAddressUnavailable} />
          )}
        </TextMessage>
      </div>

      <ButtonChange type="button" onClick={() => openRadiusAlert()}>
        Change
      </ButtonChange>
    </Container>
  );
};

RadiusMessage.propTypes = propTypes;

export default connect(
  state => ({
    isLoggedIn: isLoggedIn(state),
    isDeliveryToAddressEnabled: isDeliveryToAddressEnabled(state),
    userLatitude: selectUserLatitude(state),
    userLongitude: selectUserLongitude(state),
    isAddressUserInRange: selectIsUserAddressInRange(state),
  }),
  { openRadiusAlert }
)(RadiusMessage);
