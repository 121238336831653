import React from 'react';
import PropTypes from 'prop-types';

const FormErrors = ({ errors }) => (
  <div className="text-danger">
    {errors.map((error, i) => (
      <div key={i}>{error.message}</div>
    ))}
  </div>
);

FormErrors.propTypes = {
  errors: PropTypes.array,
};

const ErrorContainer = ({ errors, children, className = '' }) => (
  <div className={`${className} ${errors && errors.length ? 'has-error' : ''}`}>
    {children}
    {errors && errors.length && <FormErrors errors={errors} />}
  </div>
);

ErrorContainer.propTypes = {
  errors: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ErrorContainer;
