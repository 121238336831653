import { fromJS } from 'immutable';

import { SET_DETAILS, REMOVE_DETAIL, CLEAR_ALL_DETAILS } from 'actions/storage/constants';
import { findGlobalItems, clearAll } from 'utils/localStorage';
import { LOGOUT } from 'actions/user/constants';

const initialState = fromJS({
  initialized: false,
  data: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case '@@CLIENT_INIT': {
      const data = {
        ...(findGlobalItems('checkout_details.') || {}), // migrate existing storage
        ...(findGlobalItems('general_details.') || {}), // migrate existing storage
        ...(findGlobalItems('storage.') || {}),
      };

      return state.mergeDeep(fromJS({ initialized: true, data }));
    }

    case LOGOUT:
    case CLEAR_ALL_DETAILS: {
      clearAll();
      return state.set('data', fromJS({}));
    }

    case SET_DETAILS: {
      if (action.data) {
        Object.keys(action.data).forEach(key => {
          state = state.setIn(['data', key], fromJS(action.data[key]));
        });
        return state;
      }
      return state;
    }

    case REMOVE_DETAIL: {
      if (action.key) {
        return state.deleteIn(['data', action.key]);
      }
      return state;
    }

    default:
      return state;
  }
};
