import { getMinutesFromTime, getTimeFromMinutes } from './getMinutes';

export default (times, interval) =>
  times
    .map(timeSet => buildTimesList(timeSet.startTime, timeSet.endTime, interval))
    .reduce((array1, array2) => array1.concat(array2), []);

export const buildTimesList = (startTime, endTime, interval) => {
  const startMinutes = getMinutesFromTime(startTime);
  const endMinutes = getMinutesFromTime(endTime);

  if (isNaN(startMinutes) || isNaN(endMinutes))
    throw Error('startTime and endTime must be of the format { hours: <int>, minutes: <int> }.');
  if (startMinutes >= endMinutes) return [];
  if (isNaN(interval)) throw Error('Interval must be defined and a number.');
  const length = Math.floor((endMinutes - startMinutes) / interval) + 1;

  return new Array(length)
    .fill()
    .map((el, i) => startMinutes + i * interval)
    .map(getTimeFromMinutes);
};
