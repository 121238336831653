import React from 'react';
import PropTypes from 'prop-types';

const LinkedInIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon linkedInIcon"
      fill={fill}
      d="M16.1,145c0-31.1,10.9-56.8,32.7-77.1c21.8-20.2,50.1-30.4,85-30.4c34.3,0,62,10,83.1,29.9c21.8,20.6,32.7,47.3,32.7,80.3
	c0,29.9-10.6,54.8-31.8,74.7c-21.8,20.6-50.4,30.8-85.9,30.8H131c-34.3,0-62-10.3-83.1-30.8S16.1,176.2,16.1,145z M28.3,962.4v-624
	h207.4v624H28.3z M350.5,962.4h207.4V614c0-21.8,2.5-38.6,7.5-50.4c8.7-21.2,22-39.1,39.7-53.7c17.7-14.6,40-22,66.8-22
	c69.7,0,104.6,47,104.6,141.1v333.5h207.4V604.6c0-92.2-21.8-162.1-65.4-209.7c-43.6-47.6-101.2-71.5-172.8-71.5
	c-80.3,0-142.9,34.6-187.8,103.7v1.9H557l0.9-1.9v-88.7H350.5c1.2,19.9,1.9,81.9,1.9,185.9C352.4,628.3,351.8,774.3,350.5,962.4z"
    />
  </svg>
);

LinkedInIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LinkedInIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default LinkedInIcon;
