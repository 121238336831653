import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import { H1, H3 } from 'components/Typography';

export const StyledH1 = styled(H1)`
  font-weight: bold;
  margin: 0 0 5px;
`;

export const IconContainer = styled.div`
  margin: 50px 10px;
  color: ${({ theme }) => theme.color.success};
  background-color: ${({ theme }) => theme.color.white};
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
`;

export const StyledH2 = styled(H3)`
  margin: 0 0 8px;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const PrimaryButton = styled(ActionButton)`
  border-color: ${({ theme }) => theme.buttons.primaryBg};
`;
