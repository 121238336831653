import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import dompurify from 'dompurify';

import { AlertContent, AlertTitle } from 'assets/styles/sharedStyles';
import { Description } from './styles';

const propTypes = {
  donation: PropTypes.instanceOf(Map),
};

const DonationsAlert = ({ donation }) => (
  <AlertContent>
    <AlertTitle>{donation.get('modal_title')}</AlertTitle>

    <Description
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(donation.get('modal_description'), { ADD_ATTR: ['target', 'style'] }),
      }}
    />
  </AlertContent>
);

DonationsAlert.propTypes = propTypes;

export default DonationsAlert;
