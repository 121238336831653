import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';

import Page from 'components/Pages/container';
import dineInMessages from 'components/dineInMessages';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { isDineInOrderingFlow } from 'selectors/root';

import { StyledTitle, Container, StyledText, StyledButton } from './styles';

const PreAuthWaiting = ({ isDineInFlow, serviceId }) => {
  useEffect(() => {
    if (!isDineInFlow) browserHistory.replace(`/service/${serviceId}`);
  }, [isDineInFlow, serviceId]);

  return (
    <Page
      settingsPage={true}
      titleMessage={dineInMessages.addPaymentMethod}
      headerOnlyTitle={true}
      hideBackButton={true}
    >
      <StyledTitle>
        <FormattedMessage {...dineInMessages.preAuthWaiting} />
      </StyledTitle>
      <Container className="u-flex u-flexJustifyCenter">
        <LoadingSpinner width="100px" height="100px" />
      </Container>
      <StyledText>
        <FormattedMessage {...dineInMessages.singleCardRequired} />
      </StyledText>
      <StyledButton to="" label={<FormattedMessage {...dineInMessages.addMyCard} />} />
    </Page>
  );
};

PreAuthWaiting.propTypes = {
  isDineInFlow: PropTypes.bool,
  serviceId: PropTypes.string,
};

export default connect((state, { params }) => ({
  isDineInFlow: isDineInOrderingFlow(state, params?.serviceId),
  serviceId: params?.serviceId,
}))(PreAuthWaiting);
