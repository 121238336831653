import React from 'react';
import PropTypes from 'prop-types';

import { NotesContainer, NotesTextArea } from './styles';

const propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

const Notes = ({ field, onChange, onBlur, className }) => (
  <NotesContainer className={className}>
    <NotesTextArea
      name={field.name}
      value={field.value}
      onChange={onChange}
      onBlur={e => onBlur(e.target.value)}
    />
  </NotesContainer>
);

Notes.propTypes = propTypes;

export default Notes;
