/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { REQUESTED_DIGITS, DIGIT_1 } from 'appConstants';
import passcodeTransformer from 'components/Passcode/passcodeTransformer';
import { Container, SingleDigitInput } from './styles';

const propTypes = {
  onChange: PropTypes.func,
};

const EnterPasscode = ({ onChange }) => {
  const [values, setValues] = useState({});

  const handleOnChange = (digit, target) => {
    const { value, maxLength } = target;
    const isNumber = value === '0' || Number(value) || value === '';

    if (isNumber) {
      const passcodeString = passcodeTransformer({
        ...values,
        [digit]: value,
      }).join('');

      setValues(values => ({
        ...values,
        [digit]: value,
      }));

      onChange(passcodeString);

      if (value.length >= maxLength) focusNextInput(digit);
    }
  };

  const getCurrentDigitIndex = currentDigit => REQUESTED_DIGITS.findIndex(digit => digit === currentDigit);

  const focusInput = fieldName => {
    const fieldInput = document.querySelector(`input[name=${fieldName}]`);
    if (fieldInput !== null) fieldInput.focus();
  };

  const onKeyDown = (e, currentDigit) => {
    if (e.keyCode === 8 && e.target.value === '') {
      const digitIndex = getCurrentDigitIndex(currentDigit);
      const prevFieldName = REQUESTED_DIGITS[digitIndex - 1];
      if (!prevFieldName) return;

      focusInput(prevFieldName);
    }
  };

  const focusNextInput = currentDigit => {
    const digitIndex = getCurrentDigitIndex(currentDigit);
    const nextFieldName = REQUESTED_DIGITS[digitIndex + 1];
    if (!nextFieldName) return;

    focusInput(nextFieldName);
  };

  return (
    <div>
      <Container>
        {REQUESTED_DIGITS.map((digit, i) => (
          <SingleDigitInput
            value={values[digit] || ''}
            name={digit}
            key={i}
            type="text"
            maxLength="1"
            inputMode="decimal"
            autoFocus={digit === DIGIT_1 && true}
            onChange={({ target }) => handleOnChange(digit, target)}
            onKeyDown={e => onKeyDown(e, digit)}
            autoComplete="off"
          />
        ))}
      </Container>
    </div>
  );
};

EnterPasscode.propTypes = propTypes;

export default EnterPasscode;
