import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { InfoIcon, AlertIcon, AddToCartIcon, TimerIcon } from 'components/Icons';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { isServiceBrowseOnly } from 'selectors/root';

const propTypes = {
  isAvailable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  browseOnly: PropTypes.bool.isRequired,
};

const ProductIcon = ({ isAvailable, isDisabled, isLoading, browseOnly }) => {
  let Icon;

  if (browseOnly) {
    Icon = <InfoIcon width="20px" />;
  } else if (isDisabled) {
    Icon = (
      <div>
        <AlertIcon width="20px" />
      </div>
    );
  } else if (isLoading) {
    Icon = <LoadingSpinner />;
  } else if (isAvailable) {
    Icon = <AddToCartIcon />;
  } else {
    Icon = (
      <div>
        <TimerIcon width="20px" />
      </div>
    );
  }

  return <div>{Icon}</div>;
};

ProductIcon.propTypes = propTypes;

const mapStateToProps = (state, { serviceId }) => ({
  browseOnly: isServiceBrowseOnly(state, serviceId),
});

export default connect(mapStateToProps)(ProductIcon);
