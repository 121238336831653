import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import InfoBox from 'components/InfoBox';

const messages = defineMessages({
  yourFeedback: {
    defaultMessage: 'Your Feedback',
  },
  feedbackMessage: {
    defaultMessage: 'We value your opinion in order for us to continuously improve our services.',
  },
});

const FeedbackInfo = () => (
  <InfoBox
    title={<FormattedMessage {...messages.yourFeedback} />}
    message={<FormattedMessage {...messages.feedbackMessage} />}
  />
);

export default FeedbackInfo;
