import styled from 'styled-components';
import { Link } from 'react-router';

export const Container = styled.div`
  position: relative;
  padding: 10px 60px 10px 10px;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 5px;
  margin-bottom: 10px;

  html[dir='rtl'] & {
    padding: 10px 10px 10px 60px;
  }

  span {
    font-weight: bold;
  }
`;

export const EditLinkStyled = styled(Link)`
  position: absolute;
  top: 10px;
  right: 12px;

  html[dir='rtl'] & {
    left: 12px;
    right: initial;
  }
`;
