import React from 'react';
import { connect } from 'react-redux';

import { setDetail } from 'actions/storage';
import { isInitialized, getDetail } from 'selectors/storage';
import { getBrowserName, showAddToHomeScreen } from 'selectors/user';
import { selectVenueName, selectAddToHomeScreenUrl } from 'selectors/root';
import AddToHomeScreenPopUp from './AddToHomeScreenPopUp';

type Props = {
  companyName: string;
  logoUrl?: string;
  browserName?: string;
  setDetail: (value: string, companyName: string, expiry: {}) => void;
  homeScreenPopUpStatus?: string;
  showAddToHomeScreen?: boolean;
  initialized: boolean;
};

class AddToHome extends React.Component<Props> {
  state = {
    showPopUp: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.initialized === false &&
      this.props.initialized === true &&
      !this.props.homeScreenPopUpStatus
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showPopUp: true });
      this.props.setDetail('shown', this.props.companyName, {
        format: 'days',
        length: 30,
      });
    }
  }

  onClose = () => {
    this.setState({ showPopUp: false });
    this.props.setDetail('closed', this.props.companyName, {
      format: 'days',
      length: 90,
    });
  };

  render() {
    const { companyName, logoUrl, browserName, showAddToHomeScreen } = this.props;
    if (!showAddToHomeScreen || !this.state.showPopUp || !logoUrl) return null;

    return (
      <AddToHomeScreenPopUp
        companyName={companyName}
        browserName={browserName}
        logoUrl={logoUrl}
        onClose={this.onClose}
      />
    );
  }
}

const mapStateToProps = state => {
  const companyName = selectVenueName(state);

  return {
    companyName,
    logoUrl: selectAddToHomeScreenUrl(state),
    browserName: getBrowserName(state),
    showAddToHomeScreen: showAddToHomeScreen(state),
    homeScreenPopUpStatus: getDetail(state, `addToHomeScreen.${companyName}`),
    initialized: isInitialized(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setDetail: (value, companyName, expiry) =>
    dispatch(setDetail(`addToHomeScreen.${companyName}`, value, expiry)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToHome);
