import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import image from 'utils/image';
import ProductPrice from 'components/Product/price';
import TimerIcon from 'components/Icons/TimerIcon';
import { ImageIcon } from 'components/Icons';
import SuitableFor from 'components/SuitableFor';
import QuantityInOrder from 'components/Product/QuantityInOrder';
import { Chilli } from 'components/Chilli';

import { getLocale } from 'selectors/user';

import {
  Container,
  DisabledMessage,
  ProductInfo,
  ProductName,
  ProductDescription,
  StyledPrice,
  Spinner,
  StyledTimerIcon,
} from './styles';

const propTypes = {
  product: PropTypes.instanceOf(Map),
  imagePath: PropTypes.string,
  price: PropTypes.number,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isAvailable: PropTypes.bool,
  inOrder: PropTypes.number,
  disabledMessage: PropTypes.node,
  currentLocale: PropTypes.string,
};

const ProductGridView = ({
  product,
  imagePath,
  price,
  onClick,
  isLoading,
  isAvailable,
  inOrder,
  disabledMessage,
  currentLocale,
}) => (
  <Container
    onClick={onClick}
    hasImage={!!imagePath}
    loading={isLoading ? 1 : undefined}
    style={
      imagePath && {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0.7) 100%),
        url(${image(imagePath, { height: 700 })})`,
      }
    }
  >
    {disabledMessage && <DisabledMessage>{disabledMessage}</DisabledMessage>}
    <QuantityInOrder quantity={inOrder} gridView={true} />
    {!imagePath && <ImageIcon width="100px" height="100px" />}
    {isLoading && <Spinner width="65px" height="65px" />}
    {!isAvailable && (
      <StyledTimerIcon>
        <TimerIcon width="20px" />
      </StyledTimerIcon>
    )}
    <ProductInfo hasImage={!!imagePath}>
      <div>
        <ProductName>
          {product?.get('translations')?.get(currentLocale)?.get('name') ?? product?.get('name')}
          <SuitableFor product={product} />
          <Chilli product={product} />
        </ProductName>
        <ProductDescription>
          {product?.get('translations')?.get(currentLocale)?.get('short_description') ??
            product?.get('short_description') ??
            product?.get('translations')?.get(currentLocale)?.get('description') ??
            product?.get('description')}
        </ProductDescription>
      </div>
      <StyledPrice>
        <ProductPrice price={price} />
      </StyledPrice>
    </ProductInfo>
  </Container>
);

ProductGridView.propTypes = propTypes;

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(ProductGridView);
