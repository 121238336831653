import { List, Map } from 'immutable';

import { selectProducts } from 'selectors/browse';
import {
  getVenueId,
  selectFulfilmentMethodRadius,
  getVenueLatitude,
  getVenueLongitude,
} from 'selectors/root';
import { getDetail } from 'selectors/storage';
import { isAddressUserInRange } from 'utils';

export const loginRequestLoading = state => state.getIn(['user', 'login', 'loading']);
export const loginRequestFailed = state => state.getIn(['user', 'login', 'requestFailed']);

export const registerRequestLoading = state => state.getIn(['user', 'register', 'loading']);
export const registerRequestFailed = state => state.getIn(['user', 'register', 'requestFailed']);
export const registerRequestFailedValidationErrors = state => state.getIn(['user', 'register', 'validation']);

export const selectRequestingForgotPassword = state =>
  state.getIn(['user', 'password', 'forgotPassword', 'requesting']);
export const selectForgotPasswordRequestSuccessful = state =>
  state.getIn(['user', 'password', 'forgotPassword', 'successful']);
export const selectForgotPasswordViolations = state =>
  state.getIn(['user', 'password', 'forgotPassword', 'violations']);

export const selectRequestingPasswordReset = state =>
  state.getIn(['user', 'password', 'resetPassword', 'requesting']);
export const selectPasswordResetSuccessful = state =>
  state.getIn(['user', 'password', 'resetPassword', 'successful']);
export const selectPasswordResetViolations = state =>
  state.getIn(['user', 'password', 'resetPassword', 'violations']);

export const selectTokenEmail = state => state.getIn(['user', 'password', 'validateToken', 'email']);
export const selectIsTokenValid = state => state.getIn(['user', 'password', 'validateToken', 'valid']);

export const isLoggedIn = state => {
  const user = selectUser(state);
  return !!user.get('id', null);
};

export const selectUser = state => state.getIn(['user', 'data']);
export const selectAllUserThirdPartySettings = state =>
  state.getIn(['user', 'data', 'settings']) || new List();
export const selectUserThirdPartySetting = (state, venueId = getVenueId(state)) => {
  const settings = selectAllUserThirdPartySettings(state);
  const venueSetting = settings && settings.find(setting => setting.get('venue_id') === venueId);
  return venueSetting ? venueSetting.get('third_party_permission') : false;
};

export const selectUserFavourites = state => state.getIn(['user', 'data', 'favourites']);
export const selectUserDetail = (state, detail) => state.getIn(['user', 'data', detail]);
export const selectUserEmail = state => state.getIn(['user', 'data', 'email']);
export const selectFirstName = state => state.getIn(['user', 'data', 'first_name']) || null;
export const selectUserDefaultLocale = state => state.getIn(['user', 'data', 'default_locale'], false);
export const selectFavouriteProducts = state => {
  const products = selectProducts(state);
  const favourites = selectUserFavourites(state);
  return getFavouritesProducts(state, products, favourites);
};

export const getFavouritesProducts = (state, products, favourites) => {
  if (products && favourites) {
    return favourites.map(favouriteId => products.get(favouriteId.toString()));
  }
  return undefined;
};

export const getFavouriteIndex = (state, productId) => {
  if (!isLoggedIn(state)) return undefined;
  const favourites = selectUserFavourites(state);
  if (favourites) {
    return favourites.find(product => product === productId);
  }
};

export const hasFavourite = (state, productId) => {
  if (!isLoggedIn(state)) return false;
  return getFavouriteIndex(state, productId) !== undefined;
};

export const getOrdersLoading = state => state.getIn(['user', 'orders', 'loading']);

export const getUserOfflineStatus = state => state.getIn(['user', 'userStatus', 'offline']);

export const getBrowserName = state => state.getIn(['user', 'browserName']);

export const showAddToHomeScreen = state => state.getIn(['user', 'showAddToHomeScreen']);

export const getOrders = state => state.getIn(['user', 'orders', 'list'], new List());

export const getOrder = (state, orderReference) => {
  const orders = getOrders(state);
  return orders.find(order => order.get('reference') === orderReference) || new Map();
};

export const getOrdersVenueList = state => state.getIn(['user', 'orders', 'venues']);

export const getLocale = state => state.getIn(['user', 'locale']);

export const getLocaleDir = state => state.getIn(['user', 'localeDir'], 'ltr');

export const getUserGeoLocationLoading = state => state.getIn(['user', 'userGeoLocation', 'loading']);

export const getUserGeoLocation = state => state.getIn(['user', 'userGeoLocation']);
export const getUserGeoLocationData = state =>
  getUserGeoLocation(state)?.get('data') || selectUserCoords(state);
export const getAllowGeoLocation = state =>
  Boolean(getDetail(state, 'authorizedGeoLocation') && getUserGeoLocationData(state));
export const isUserGeoLocationLoading = state =>
  getUserGeoLocationLoading(state) || isLoadingAddressFromCoords(state);

export const isLoadingAddressFromCoords = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'isLoadingAddressFromCoords'], false);
export const isLoadingPredictions = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'isLoadingPredictions'], false);
export const selectPredictionsList = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'predictionsList'], new List());
export const selectPredictionsError = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'error'], false);

export const isLoadingCoords = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'isLoadingCoords'], false);
export const selectUserCoords = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'coordinates'], false);
export const selectUserLatitude = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'coordinates', 'latitude'], false);
export const selectUserLongitude = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'coordinates', 'longitude'], false);
export const selectCoordsError = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'errorCoords'], false);
export const selectDescription = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'description'], '');
export const selectAddressComponents = state =>
  state.getIn(['user', 'userGeoLocation', 'predictions', 'addressComponents'], new Map());

export const selectIsUserAddressInRange = (state, addressLatitude, addressLongitude) => {
  const userLatitude = selectUserLatitude(state);
  const userLongitude = selectUserLongitude(state);
  const venueLatitude = getVenueLatitude(state);
  const venueLongitude = getVenueLongitude(state);
  const radius = selectFulfilmentMethodRadius(state);

  if (radius === 0) return true;

  const hasParams = Boolean(addressLatitude && addressLongitude);

  const lat = hasParams ? addressLatitude : userLatitude;
  const lng = hasParams ? addressLongitude : userLongitude;

  const response =
    lat && lng
      ? isAddressUserInRange(
          radius,
          { latitude: lat, longitude: lng },
          { latitude: venueLatitude, longitude: venueLongitude }
        )
      : false;

  return response;
};

export const selectSelectedVenue = state => state.getIn(['user', 'orders', 'selectedVenue']);

export const selectIsFilterOpen = (state, venueId) =>
  state.getIn(['user', 'orders', 'filteredOrders', venueId.toString(), 'isFilterOpen']);

export const selectDateFrom = (state, venueId) =>
  state.getIn(['user', 'orders', 'filteredOrders', venueId.toString(), 'from']);

export const selectDateTo = (state, venueId) =>
  state.getIn(['user', 'orders', 'filteredOrders', venueId.toString(), 'to']);

export const selectLoadingAddresses = state =>
  state.getIn(['user', 'data', 'addresses', 'loadingAddresses'], false);
export const selectErrorLoadingAddresses = state =>
  state.getIn(['user', 'data', 'addresses', 'errorLoadingAddresses'], false);
export const selectAddressesList = state =>
  state.getIn(['user', 'data', 'addresses', 'addressesList'], new List());

export const selectSettingIsDefault = state =>
  state.getIn(['user', 'data', 'addresses', 'settingIsDefault'], false);
export const selectErrorSettingIsDefault = state =>
  state.getIn(['user', 'data', 'addresses', 'errorSettingIsDefault'], false);
export const selectDefaultAddressId = state =>
  state.getIn(['user', 'data', 'addresses', 'defaultAddressId'], '');

export const selectAddressByAddressId = (state, addressId) => {
  if (!addressId) return false;

  const addressesList = selectAddressesList(state);
  const singleAddress = addressesList.find(add => add.get('id') === addressId);

  return singleAddress || false;
};

export const selectPaymentSources = state =>
  state
    .getIn(['user', 'data', 'paymentSources'], new List())
    .sort((a, b) => a.get('expired') - b.get('expired'));
