import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { Container, Content, ModalFooter, StyledActionButton, StyledError } from './styles';

const OTP = ({ handleOtp, handleResend, error, loading }) => {
  const intl = useIntl();

  return (
    <form onSubmit={handleOtp}>
      <Container>
        <Content>
          <p>
            <FormattedMessage defaultMessage="We've sent you a One-time PIN code. Enter that below to verify your account" />
          </p>

          {error && <StyledError>{error}</StyledError>}

          <div className="input-group">
            <input
              type="text"
              maxLength={4}
              name="code"
              placeholder={intl.formatMessage({ defaultMessage: 'Enter PIN' })}
            />
            <small>
              <FormattedMessage defaultMessage="PIN is numbers only, and 4 characters long" />
            </small>
          </div>

          <div className="otp-resend">
            <FormattedMessage
              defaultMessage="Didn't receive an OTP? <link>Resend</link>"
              values={{
                link: (...chunks) => (
                  <button type="button" onClick={handleResend}>
                    {chunks}
                  </button>
                ),
              }}
            />
          </div>
        </Content>
        <ModalFooter>
          <StyledActionButton
            buttonType="submit"
            label={
              loading ? (
                <LoadingSpinner onPad={true} height="1.5rem" width="1.5rem" />
              ) : (
                <FormattedMessage defaultMessage="Verify" />
              )
            }
            disabled={loading}
          />
        </ModalFooter>
      </Container>
    </form>
  );
};

OTP.propTypes = {
  handleOtp: PropTypes.func,
  handleResend: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

export default OTP;
