export const OPEN_ALERT = 'app/Root/OPEN_ALERT';
export const CLOSE_ALERT = 'app/Root/CLOSE_ALERT';
export const CLEAR_ALERTS = 'app/Root/CLEAR_ALERTS';
export const OPEN_NOTIFICATION = 'app/Root/OPEN_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'app/Root/CLOSE_NOTIFICATION';
export const SET_LAST_MENU_PAGE = 'app/Root/SET_LAST_MENU_PAGE';
export const CLEAR_LAST_MENU_PAGE = 'app/Root/CLEAR_LAST_MENU_PAGE';
export const SET_IS_SETTINGS_PAGE = 'app/Root/SET_IS_SETTINGS_PAGE';
export const POP_INFO_AND_CUSTOMISATION = 'app/Root/POP_INFO_AND_CUSTOMISATION';
export const UPDATE_LOGIN_REGISTER_ALERT = 'app/Root/UPDATE_LOGIN_REGISTER_ALERT';
