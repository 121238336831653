import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Map, List } from 'immutable';

import { openProductInfo } from 'actions/UI';
import { addProductToOrder, customiseProduct } from 'actions/order';
import {
  selectMenuType,
  productHasModifiers,
  selectActiveMenus,
  getItemPrice,
  selectDefaultModifiers,
} from 'selectors/browse';
import { hideIfInCart, getOrderMenuTypeId, selectOrderServiceId, validatedOrderItems } from 'selectors/order';
import { selectCrossSellsList } from 'selectors/crossSells';
import { useIsMounted } from 'hooks';
import buildProductsList from './buildProductsList';
import ProductOption from './ProductOption';

import { CrossSellsContainer, CrossSellList, SliderItem } from './styles';
import { Title } from '../styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'You may also like',
  },
});

const propTypes = {
  crossSellsList: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(List)]),
  productsInCart: PropTypes.instanceOf(List),
  activeMenus: PropTypes.instanceOf(Map),
  menuType: PropTypes.instanceOf(Map),
  serviceId: PropTypes.string,
  menuTypeId: PropTypes.number,
  openProductInfo: PropTypes.func,
  addToCart: PropTypes.func,
  customise: PropTypes.func,
  getItemPrice: PropTypes.func,
};

const defaultProps = {
  productsInCart: new List(),
  activeMenus: new Map(),
  menuType: new Map(),
  serviceId: undefined,
  menuTypeId: undefined,
  openProductInfo: () => undefined,
  addToCart: () => undefined,
  customise: () => undefined,
};

const OrderCrossSells = ({
  crossSellsList,
  productsInCart,
  activeMenus,
  menuType,
  serviceId,
  menuTypeId,
  openProductInfo,
  addToCart,
  customise,
  getItemPrice,
}) => {
  const [crossSellsProducts, setCrossSellsProducts] = useState(new List());
  const [isMounted] = useIsMounted();

  const crossSellsProductsSize = crossSellsProducts?.size;
  const productsInCartSize = productsInCart?.size;
  const productsNotInCart = hideIfInCart(crossSellsProducts, productsInCart);
  const productsNotInCartSize = productsNotInCart?.size;
  const productNoImages = productsNotInCart.filter(
    item => item.getIn(['product', 'images', 0, 'path'], false) === false
  );
  const showImage = !productNoImages?.size >= 1;

  const handleAddToCart = (item, origin) => () =>
    productHasModifiers(item.get('product'))
      ? customise(
          item.merge({ modifiers: selectDefaultModifiers(item.get('product')) }),
          menuType,
          serviceId,
          origin
        )
      : addToCart(item, menuType, serviceId);

  useEffect(() => {
    if (crossSellsList && isMounted.current) {
      setCrossSellsProducts(buildProductsList(crossSellsList, activeMenus));
    }
  }, [crossSellsList, isMounted, activeMenus]);

  return crossSellsProductsSize && productsInCartSize && productsNotInCartSize ? (
    <CrossSellsContainer>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      <CrossSellList>
        {productsNotInCart.map(item => {
          const productImage = item.getIn(['product', 'images', 0, 'path']) || null;
          item = item.set('productId', item.getIn(['product', 'id']));
          const price = getItemPrice(
            item.getIn(['prices', menuTypeId.toString(), 'price']),
            item.getIn(['product', 'tax_categories'])
          );
          item = item.set('is_upsell', true);
          return (
            <SliderItem key={item.get('id')}>
              <ProductOption
                name={item.getIn(['product', 'name'])}
                price={price}
                imagePath={productImage}
                showImage={showImage}
                onInfo={() => openProductInfo(item)}
                onChange={handleAddToCart(item)}
              />
            </SliderItem>
          );
        })}
      </CrossSellList>
    </CrossSellsContainer>
  ) : null;
};

OrderCrossSells.propTypes = propTypes;
OrderCrossSells.defaultProps = defaultProps;

const mapStateToProps = state => {
  const menuTypeId = getOrderMenuTypeId(state);
  const serviceId = selectOrderServiceId(state);

  return {
    activeMenus: selectActiveMenus(state, menuTypeId, serviceId),
    menuType: selectMenuType(state),
    crossSellsList: selectCrossSellsList(state),
    getItemPrice: (price, taxCategories) => getItemPrice(state, price, taxCategories),
    menuTypeId,
    serviceId,
    productsInCart: validatedOrderItems(state),
  };
};

export default connect(mapStateToProps, {
  openProductInfo,
  addToCart: addProductToOrder,
  customise: customiseProduct,
})(OrderCrossSells);
