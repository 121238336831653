import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormattedPrice from '../FormattedPrice';
import { checkoutMessages } from './index';

export const PaymentCTA = ({ orderTotal, couponApplied, ctaLabel }) => {
  if (couponApplied && orderTotal === 0) {
    return <FormattedMessage {...checkoutMessages.completeOrder} />;
  }

  if (ctaLabel) {
    return (
      <>
        {ctaLabel}: <FormattedPrice value={orderTotal} />
      </>
    );
  }

  return (
    <FormattedMessage
      {...checkoutMessages.payNow}
      values={{ price: <FormattedPrice value={orderTotal} /> }}
    />
  );
};

PaymentCTA.propTypes = {
  orderTotal: PropTypes.number,
  couponApplied: PropTypes.bool,
  ctaLabel: PropTypes.string,
};
