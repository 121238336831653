import {
  isValidLocationName,
  isValidLocationNumber,
  getFulfilmentFieldLabel,
  selectFulfilmentMethodRequireFullRegistration,
} from 'selectors/root';

import fieldMessages from './fieldMessages';
import messages from './validationMessages';

export const hasValue = value =>
  !!(
    value &&
    value !== '' &&
    value !== undefined &&
    value !== null &&
    value !== [''] &&
    value !== [] &&
    value !== {}
  );

const checkCarRegistrationLabel = (state, field, label, formatMessage) => {
  if (field.name === 'registration' && !selectFulfilmentMethodRequireFullRegistration(state)) {
    return formatMessage(fieldMessages.partRegistration, { label });
  }
  return label;
};

export const getFieldLabel = (state, field, formatMessage) => {
  if (field.fulfilmentKey) {
    let label = getFulfilmentFieldLabel(state, field.fulfilmentKey);
    if (label) {
      label = checkCarRegistrationLabel(state, field, label, formatMessage);
      return label;
    }
  }

  if (field.intlLabel) {
    return field.intlLabel;
  }

  if (field.name === 'registration') {
    return checkCarRegistrationLabel(state, field, formatMessage(fieldMessages.registration), formatMessage);
  }

  return formatMessage(fieldMessages[field.name]);
};

export const getLocationFieldError = (state, value, field, formatMessage) => {
  if (isNaN(value)) {
    if (!isValidLocationName(state, field.locationKey, value)) {
      return formatMessage(messages.invalidLocationName);
    }
  } else if (!isValidLocationNumber(state, field.locationKey, value)) {
    return formatMessage(messages.invalidLocationNumber);
  }
  return false;
};

export const validateFields = (state, values, fields, formatMessage) => {
  const errors = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    const field = fields.find(field => field.name === key);
    if (field && field.required && (!hasValue(value) || (field.name === 'telephone' && value.length <= 3))) {
      let message = messages.valueRequired;
      if (field.validationMessage) {
        if (!messages[field.validationMessage]) {
          throw new Error(`Unable to find validation message with index: ${field.validationMessage}`);
        }
        message = messages[field.validationMessage];
      }
      errors[key] = formatMessage(message);
    } else if (field.locationKey) {
      const locationError = getLocationFieldError(state, value, field, formatMessage);
      if (locationError) errors[key] = locationError;
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.invalidEmail);
  }
  if (values.covers) {
    const value = parseInt(values.covers, 10);
    const maxCoversValue = 50;
    if (value < 1 || value > maxCoversValue) errors.covers = formatMessage(messages.invalidCovers);
  }
  if (values.newPassword) {
    if (
      !hasPasswordLengthRequirement(values.newPassword) ||
      !hasPasswordLowercaseRequirement(values.newPassword) ||
      !hasPasswordUppercaseRequirement(values.newPassword) ||
      !hasPasswordNumberRequirement(values.newPassword)
    ) {
      errors.newPassword = formatMessage(messages.invalidPassword);
    }
  }
  if (values.newPassword && values.confirmPassword) {
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = formatMessage(messages.passwordsDoNotMatch);
    }
  }
  return errors;
};

export const hasPasswordLengthRequirement = value => value.length >= 8;
export const hasPasswordLowercaseRequirement = value => /[a-z]/.test(value);
export const hasPasswordUppercaseRequirement = value => /[A-Z]/.test(value);
export const hasPasswordNumberRequirement = value => /[0-9]/.test(value);

export const onChangeSetIdFromIndex = (options, onChange) => valueIndexList => {
  const [valueIndex] = valueIndexList;
  const selectedOptionObj = options[valueIndex];

  onChange(selectedOptionObj?.id);
};
