import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { isLoggedIn, selectUser } from 'selectors/user';
import { EditIcon } from 'components/Icons';

import { Container, EditLinkStyled } from './styles';

const messages = defineMessages({
  message: {
    defaultMessage: '{ userName }, your order confirmation will be sent to { email }',
  },
});

const CheckoutUserDetails = ({ isLoggedIn, user }) => {
  if (!isLoggedIn) return null;
  return (
    <Container>
      <FormattedMessage
        {...messages.message}
        values={{
          userName: <span>{user.get('name')}</span>,
          email: <span>{user.get('email')}</span>,
        }}
      />
      <EditLinkStyled to="/user/settings">
        <EditIcon width="18px" height="18px" />
      </EditLinkStyled>
    </Container>
  );
};

CheckoutUserDetails.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.instanceOf(Map),
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  user: selectUser(state),
});

export default connect(mapStateToProps)(CheckoutUserDetails);
