import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import RadiusCheck from 'components/RadiusCheck';
import globalMessages from 'components/globalMessages';
import { PointerIconFull, DeliveryIcon } from 'components/Icons';
import { AlertContent, AlertTitle } from 'assets/styles/sharedStyles';
import { openLoginRegisterAlert } from 'actions/UI';
import ActionButton from 'components/ActionButton';
import {
  selectUserLatitude,
  selectUserLongitude,
  selectIsUserAddressInRange,
  selectDescription,
  selectAddressesList,
  isLoggedIn,
} from 'selectors/user';

import {
  ContentContainer,
  ButtonContainer,
  RadiusContainer,
  MessageContainer,
} from 'components/Alert/styles';

import { LoginMessageContainer } from './styles';

const propTypes = {
  isLoggedIn: PropTypes.bool,
  theme: PropTypes.object,
  openLoginRegisterAlert: PropTypes.func,
  userLatitude: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  userLongitude: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  description: PropTypes.string,
  isAddressUserInRange: PropTypes.bool,
  closeAlert: PropTypes.func,
  addressesListSize: PropTypes.number,
};

const RadiusAlert = ({
  theme,
  userLatitude,
  userLongitude,
  isAddressUserInRange,
  description,
  closeAlert,
  openLoginRegisterAlert,
  isLoggedIn,
  addressesListSize,
}) => {
  useEffect(() => {
    if (isLoggedIn && addressesListSize !== 0) {
      closeAlert();
    }
  }, [isLoggedIn, addressesListSize, closeAlert]);

  const showDeliveryUnavailable = userLatitude && userLongitude && !isAddressUserInRange;

  return (
    <AlertContent>
      <RadiusContainer>
        <>
          {showDeliveryUnavailable ? (
            <DeliveryIcon fill={theme.color.danger} width="60px" height="60px" />
          ) : (
            <PointerIconFull fill={theme.color.success} width="60px" height="60px" />
          )}

          <AlertTitle>
            {showDeliveryUnavailable ? (
              <FormattedMessage {...globalMessages.deliveryAddressUnavailable} />
            ) : (
              <FormattedMessage {...globalMessages.deliveryAddress} />
            )}
          </AlertTitle>

          <ContentContainer>
            {showDeliveryUnavailable ? (
              <FormattedMessage
                {...globalMessages.deliveryAddressMsgUnavailable}
                values={{ address: <strong>{description}</strong> }}
              />
            ) : (
              <>
                {description ? (
                  <strong>{description}</strong>
                ) : (
                  <FormattedMessage {...globalMessages.deliveryAddressMsg} />
                )}
              </>
            )}
          </ContentContainer>

          <RadiusCheck closeAlert={closeAlert} />

          {showDeliveryUnavailable && (
            <ButtonContainer>
              <ActionButton
                primaryButton={true}
                to="/"
                label={<FormattedMessage {...globalMessages.home} />}
              />
            </ButtonContainer>
          )}

          {!isLoggedIn && (
            <LoginMessageContainer>
              <button type="button" onClick={() => openLoginRegisterAlert()} className="linkUnderlined">
                <FormattedMessage {...globalMessages.login} />
              </button>
              <MessageContainer>
                <FormattedMessage {...globalMessages.recentAddresses} />
              </MessageContainer>
            </LoginMessageContainer>
          )}
        </>
      </RadiusContainer>
    </AlertContent>
  );
};

RadiusAlert.propTypes = propTypes;

export default connect(
  state => ({
    isLoggedIn: isLoggedIn(state),
    userLatitude: selectUserLatitude(state),
    userLongitude: selectUserLongitude(state),
    isAddressUserInRange: selectIsUserAddressInRange(state),
    description: selectDescription(state),
    addressesListSize: selectAddressesList(state)?.size,
  }),
  { openLoginRegisterAlert }
)(withTheme(RadiusAlert));
