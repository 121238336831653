import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    defaultMessage: 'Filter Menu',
  },
  header: {
    defaultMessage: 'Filter menu items',
  },
  contact_staff: {
    defaultMessage: 'Please contact a member of staff for allergen information',
  },
  message: {
    defaultMessage:
      'To filter the menu items for special dietary needs, simply select the relevant options below and click the "APPLY FILTERS" button.',
  },
  clear_filters: {
    defaultMessage: 'Clear Filters',
  },
  apply_filters: {
    defaultMessage: 'Apply Filters',
  },
  allergenSelect: {
    defaultMessage: 'Select to hide items containing: ',
  },
  dietarySelect: {
    defaultMessage: 'Select dietary requirements: ',
  },
  celery: {
    defaultMessage: 'Celery',
  },
  crustaceans: {
    defaultMessage: 'Crustaceans',
  },
  eggs: {
    defaultMessage: 'Eggs',
  },
  fish: {
    defaultMessage: 'Fish',
  },
  gluten: {
    defaultMessage: 'Gluten',
  },
  gluten_wheat: {
    defaultMessage: 'Wheat',
  },
  gluten_rye: {
    defaultMessage: 'Rye',
  },
  gluten_barley: {
    defaultMessage: 'Barley',
  },
  gluten_oats: {
    defaultMessage: 'Oats',
  },
  lupin: {
    defaultMessage: 'Lupin',
  },
  milk: {
    defaultMessage: 'Milk',
  },
  molluscs: {
    defaultMessage: 'Molluscs',
  },
  mustard: {
    defaultMessage: 'Mustard',
  },
  nuts: {
    defaultMessage: 'Nuts',
  },
  nut_almonds: {
    defaultMessage: 'Almonds',
  },
  nut_hazelnuts: {
    defaultMessage: 'Hazelnuts',
  },
  nut_walnuts: {
    defaultMessage: 'Walnuts',
  },
  nut_cashews: {
    defaultMessage: 'Cashews',
  },
  nut_pecan: {
    defaultMessage: 'Pecans',
  },
  nut_brazil: {
    defaultMessage: 'Brazil nuts',
  },
  nut_pistachio: {
    defaultMessage: 'Pistachio',
  },
  nut_macadamia: {
    defaultMessage: 'Macadamia nuts',
  },
  peanuts: {
    defaultMessage: 'Peanuts',
  },
  sesame_seeds: {
    defaultMessage: 'Sesame seeds',
  },
  soya: {
    defaultMessage: 'Soya',
  },
  sulphur_dioxide: {
    defaultMessage: 'Sulphur dioxide',
  },
  halal: {
    defaultMessage: 'Halal',
  },
  kosher: {
    defaultMessage: 'Kosher',
  },
  vegan: {
    defaultMessage: 'Vegan',
  },
  vegetarian: {
    defaultMessage: 'Vegetarian',
  },
});

export default messages;
