export const getMinutesFromTime = time => 60 * time.hours + time.minutes;
export const getTimeFromMinutes = minutes => ({
  hours: Math.floor(minutes / 60),
  minutes: minutes % 60,
});

export const getCurrentTime = time => ({
  hours: time.getHours(),
  minutes: time.getMinutes(),
});

export const returnObjectTimeInMinutes = timeObject => {
  let timeInMinutes = 0;
  if (timeObject.format === 'hours' || timeObject.format === 'hour') {
    timeInMinutes = timeObject.value * 60;
  }
  if (timeObject.format === 'minutes' || timeObject.format === 'minute') {
    timeInMinutes = timeObject.value;
  }
  return timeInMinutes;
};
