import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
`;

export const DisabledMessage = styled.span`
  color: ${({ theme }) => theme.color.danger};
`;

export const StyledImg = styled.img`
  border-radius: 5px;
  margin-right: 10px;

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: 10px;
  }
`;

export const Details = styled.div`
  flex-grow: 1;
`;

export const Breadcrumbs = styled.span`
  font-size: 12px;
  color: #999;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const Price = styled.div`
  color: #999;
`;
