import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { selectCompletedOrderServiceId } from 'reducers/root';
import { selectUserEmail } from 'selectors/user';
import OrderNumber from 'components/Pages/ThankYou/orderNumber';
import messages from 'components/Pages/ThankYou/messages';
import { getServiceOrderConfirmationMessage } from 'selectors/root';
import { CustomConfirmationMessage } from '../styles';

const ThankYouHeaderMessage = ({ orderNumber, userEmail, orderConfirmationMessage }) => (
  <>
    <p>
      <FormattedMessage {...messages.thankYouHeader} />
    </p>
    <p>
      <FormattedMessage
        {...messages.orderMessage}
        values={{
          hasOrderNumber: !!orderNumber,
          orderNumber: <OrderNumber number={orderNumber} />,
          email: <strong>{userEmail}</strong>,
        }}
      />
    </p>
    {orderConfirmationMessage && (
      <CustomConfirmationMessage> {orderConfirmationMessage} </CustomConfirmationMessage>
    )}
  </>
);

ThankYouHeaderMessage.propTypes = {
  orderNumber: PropTypes.string,
  userEmail: PropTypes.string,
  orderConfirmationMessage: PropTypes.string,
};

const mapStateToProps = state => {
  const serviceId = selectCompletedOrderServiceId(state) && selectCompletedOrderServiceId(state).toString();
  return {
    userEmail: selectUserEmail(state),
    orderConfirmationMessage: getServiceOrderConfirmationMessage(state, serviceId),
  };
};

export default connect(mapStateToProps)(ThankYouHeaderMessage);
