import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import globalMessages from 'components/globalMessages';
import Venue from 'components/Venue';
import address from '../../../../shared/sortVenues/address';

import { NoResults } from './styles';

const messages = defineMessages({
  kmDistance: {
    defaultMessage: '{ distance } km',
  },
});

const propTypes = {
  venues: PropTypes.array.isRequired,
};

const VenueListWithLinks = ({ venues }) => {
  if (venues.length === 0) {
    return (
      <NoResults>
        <FormattedMessage {...globalMessages.noResults} />
      </NoResults>
    );
  }

  return venues.map(venue => (
    <Venue
      key={venue.id}
      name={venue.name}
      address={address(venue)}
      image={venue.default_image && venue.default_image.path}
      highlighted={true}
      distance={
        venue.distance && (
          <FormattedMessage {...messages.kmDistance} values={{ distance: venue.distance.toFixed(1) }} />
        )
      }
      venueId={venue.id}
    />
  ));
};

VenueListWithLinks.propTypes = propTypes;

export default VenueListWithLinks;
