import React from 'react';
import PropTypes from 'prop-types';

import arrayRemoveIndex from 'utils/arrayRemoveIndex';
import Choice from './choice';

type RenderPropSignature<T> = (
  onChange: (maxQuantity: number, increment: boolean) => void,
  checked: number | undefined,
  text: T,
  id: string
) => React.ReactNode;

interface Props<T> {
  id?: string;
  choices: Array<T>;
  values: Array<T>;
  onChange: (v: Array<T>) => void;
  required: boolean;
  maxChoices?: number;
  renderProps: RenderPropSignature<T>;
}

const Choosey = <T,>({
  id = '',
  choices,
  values,
  onChange,
  required,
  maxChoices = Infinity,
  renderProps,
}: Props<T>) => {
  const valuesFrequencyMap = values.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
  /* valuesFrequencyMap is a Map of values and their frequencies, e.g.:
    for [5, 5, 5, 6, 6, 1, 1, 1, 1] valuesFrequencyMap will be { 5: 3, 6: 1, 1: 4 }
    Use valuesFrequencyMap.keys() to get unique elements
    Use valuesFrequencyMap.values() to get the occurrences
    Use valuesFrequencyMap.entries() to get the pairs [element, frequency]
   */

  const uniqueValues = [...values.keys()];

  const onCheck = (choice, maxQuantity = 1, increment = null) => {
    const valueIndex = values.indexOf(choice);
    // If the choice isn't already in the array of values (valueIndex === -1)
    if (valueIndex === -1) {
      // If maxChoices is 1, just replace all values.
      if (maxChoices === 1) return onChange([choice]);
      // if maxChoices is greater than 1, and
      // hasn't been exceeded, add the choice.
      if (uniqueValues.length < maxChoices) onChange(values.concat([choice]));
    } else if (increment === true && valuesFrequencyMap.get(choice) < maxQuantity) {
      // If we can have more than one of each value, and we haven't exceeded the max, increment quantity.
      if (maxChoices <= values.length) return;
      return onChange([...values, choice]);
    } else if (increment === false) {
      // If we can have more than one of each value, and we haven't exceeded the max, decrement quantity.
      return onChange(values.slice(0, valueIndex).concat(values.slice(valueIndex + 1)));
    } else if (uniqueValues.length > 1 || !required) {
      // If the option is already in the array of values (valueIndex > -1)
      // and if required isn't set, and you aren't removing the last selected value,
      // remove from the array.
      onChange(arrayRemoveIndex(values, valueIndex));
    }
  };

  const renderDefaultChoice: RenderPropSignature<T> = (onChange, checked, text, id) => (
    <Choice onChange={onChange} checked={!!checked} key={id} text={text} id={id} />
  );

  const renderChoice = renderProps || renderDefaultChoice;

  return (
    <>
      {choices?.length &&
        choices.map((choice, index) =>
          renderChoice(
            (maxQuantity, increment) => onCheck(index, maxQuantity, increment),
            valuesFrequencyMap.get(index),
            choice,
            `${id}${index}`
          )
        )}
    </>
  );
};

Choosey.propTypes = {
  id: PropTypes.string,
  choices: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  maxChoices: PropTypes.number,
  renderProps: PropTypes.func,
};

export default Choosey;
