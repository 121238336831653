import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { AllergenIcon } from 'components/Icons';
import dietaryFilters from 'components/Alert/alerts/AllergenFilter/dietaryFilters';
import AllergenItem from 'components/AllergenItem';
import { allergenContactStaff } from 'selectors/features';
import { selectVenueAllergensMessage } from 'selectors/root';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import SystemIcon from 'components/SystemIcon';

import { StyledText, AllergenList, NoAllergens } from './styles';

const messages = defineMessages({
  product_contains: {
    defaultMessage:
      'This product contains the below allergens. It may contain traces of other allergens, please check before ordering.',
  },
  allergen_information: {
    defaultMessage: 'Allergen Information',
  },
  nutrition_per: {
    defaultMessage: 'Nutrition (per 100g)',
  },
  no_allergens: {
    defaultMessage: 'No Allergens found',
  },
});

const AllergenInformation = ({ product, closeAlert, allergenContactStaff, allergenMessage }) => {
  const productAllergens = dietaryFilters
    .get('allergens')
    .filter(allergen => product?.get('allergens')?.includes(allergen));

  return (
    <>
      <AlertContent>
        <SystemIcon Icon={AllergenIcon} />
        <AlertTitle>
          <FormattedMessage {...messages.allergen_information} />
        </AlertTitle>
        <StyledText>
          {allergenContactStaff ? allergenMessage : <FormattedMessage {...messages.product_contains} />}
        </StyledText>
        {!allergenContactStaff && !!productAllergens?.size && (
          <AllergenList>
            {productAllergens
              .map(filter => <AllergenItem key={filter} allergenName={filter} active={true} />)
              .toList()}
          </AllergenList>
        )}
        {!allergenContactStaff && !productAllergens?.size && (
          <NoAllergens>
            <FormattedMessage {...messages.no_allergens} />
          </NoAllergens>
        )}
      </AlertContent>
      <AlertFooter>
        <AlertButtons>
          <ActionButton
            dangerButton={true}
            label={<FormattedMessage {...globalMessages.cancel} />}
            onClick={closeAlert}
          />
        </AlertButtons>
      </AlertFooter>
    </>
  );
};

AllergenInformation.propTypes = {
  closeAlert: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Map).isRequired,
  allergenContactStaff: PropTypes.bool,
  allergenMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const mapStateToProps = state => ({
  allergenContactStaff: allergenContactStaff(state),
  allergenMessage: selectVenueAllergensMessage(state),
});

export default connect(mapStateToProps)(AllergenInformation);
