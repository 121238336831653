import React from 'react';

import { getLocale } from 'selectors/user';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import ProductImage from 'components/ProductImage';
import StandardButtons from 'components/Product/ProductAdditional/StandardButtons';
import ProductPrice from 'components/Product/price';
import { Chilli } from 'components/Chilli';
import SuitableFor from 'components/SuitableFor';
import BaseModifier from 'components/Product/BaseModifier';

import { connect } from 'react-redux';
import {
  Container,
  ProductInfo,
  StyledH1,
  AdditionalButtonWrapper,
  ImageWrapper,
  OptionWrapper,
  ProductQuantityWrapper,
  HeaderWrapper,
  ProductDescription,
} from './styles';

const propTypes = {
  product: PropTypes.object,
  price: PropTypes.number,
  additionalButtons: PropTypes.array,
  productDescription: PropTypes.string,
  productImages: PropTypes.instanceOf(List),
  baseModifier: PropTypes.instanceOf(Map),
  selectedBaseModifierId: PropTypes.number,
  pageView: PropTypes.bool,
  changeModifier: PropTypes.func,
  currentLocale: PropTypes.string,
  quantity: PropTypes.element,
};

const KioskProductDetails = ({
  productDescription,
  productImages,
  product,
  price,
  baseModifier,
  selectedBaseModifierId,
  changeModifier,
  pageView = false,
  additionalButtons = [],
  currentLocale,
  quantity,
}) => (
  <>
    <Container>
      {productImages.size > 0 && (
        <ImageWrapper>
          <ProductImage
            images={productImages}
            altText={product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}
          />
        </ImageWrapper>
      )}

      <HeaderWrapper>
        <StyledH1>
          {product?.get('translations')?.get(currentLocale)?.get('name') ?? product.get('name')}
        </StyledH1>
        <SuitableFor product={product} />
        <Chilli product={product} />
        <ProductPrice price={price} />
      </HeaderWrapper>

      <ProductInfo>
        <OptionWrapper>
          <AdditionalButtonWrapper>
            <StandardButtons smallBtns={pageView} product={product} additionalButtons={additionalButtons} />
          </AdditionalButtonWrapper>
          <ProductDescription>{productDescription}</ProductDescription>
        </OptionWrapper>
        <OptionWrapper>
          {baseModifier && (
            <BaseModifier
              baseModifier={baseModifier}
              selectedId={selectedBaseModifierId}
              changeModifier={changeModifier}
            />
          )}
          <ProductQuantityWrapper>{quantity}</ProductQuantityWrapper>{' '}
        </OptionWrapper>
      </ProductInfo>
    </Container>
  </>
);

KioskProductDetails.propTypes = propTypes;

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(KioskProductDetails);
