import React, { ElementType } from 'react';
import { withTheme } from 'styled-components';

type Props = {
  Icon?: ElementType;
  height?: string;
  width?: string;
  fill?: string;
  theme?: { color: { systemIcon: string } };
};

const SystemIcon = ({ Icon, height = '60px', width = '60px', fill, theme }: Props) => (
  <Icon height={height} width={width} fill={fill || theme.color.systemIcon} />
);

export default withTheme(SystemIcon);
