import styled, { keyframes } from 'styled-components';

import { H1 } from 'components/Typography';

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

export const TransitionContainer = styled.div`
  height: calc(100% - 48px);
  min-height: calc(100% - 48px);
  position: relative;
`;

export const Slide = styled.div`
  & > * {
    animation-name: ${({ direction }) => {
      if (direction === 'Forward') return slideInRight;
      if (direction === 'Back') return slideInLeft;
    }};
    animation-duration: 350ms;
    animation-timing-function: ease-out;
  }
`;

export const StyledTitle = styled(H1)`
  font-weight: 'bold';
  margin: 0.5rem auto 0;
  color: ${({ theme }) => theme.color.primaryText};
  text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
`;

export const StyledDescription = styled.p`
  font-size: 16px;
  white-space: pre-line;
`;
