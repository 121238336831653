import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { REQUESTED_DIGITS } from 'appConstants';
import { confirmOrder } from 'actions/order/submitOrder';
import Page from 'components/Pages/container';
import Form from 'components/Form';
import MessageBlock from 'components/MessageBlock';
import fieldTypes from 'components/Form/fieldTypes';
import globalMessages from 'components/globalMessages';
import { getDineInErrorMessage } from 'components/CheckoutForm/checkoutErrorsUtil';
import messages from './messages';

const propTypes = {
  confirmOrder: PropTypes.func,
  dineInFulfilmentTypeId: PropTypes.number,
  orderSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getErrorMessage: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

const Passcode = ({ getErrorMessage, confirmOrder, intl }) => {
  const [disabled, setDisabled] = useState(true);
  const { errorHeader, errorMessage } = getErrorMessage(intl.formatMessage);

  const handleOnSubmit = ({ passcode }, callback) => {
    confirmOrder({ passcode }, callback);
  };

  const onChange = passcode => {
    const isValid = passcode.length === REQUESTED_DIGITS.length;

    setDisabled(!isValid);
  };

  return (
    <Page settingsPage={true} titleMessage={globalMessages.enterPasscode} hideBackButton={true}>
      <Form
        disabled={disabled}
        fields={[{ ...fieldTypes.passcode }]}
        onSubmit={handleOnSubmit}
        onChange={onChange}
        submitButtonLabel={<FormattedMessage {...globalMessages.sendToKitchen} />}
        errorHeader={errorHeader}
        errorMessage={errorMessage}
      />

      <MessageBlock body={<FormattedMessage {...messages.askPasscodeMsg} />} type="info" />
    </Page>
  );
};

Passcode.propTypes = propTypes;

export default connect(
  state => ({
    getErrorMessage: formatMessage => getDineInErrorMessage(state, formatMessage),
  }),
  { confirmOrder }
)(injectIntl(Passcode));
