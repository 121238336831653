import React from 'react';
import PropTypes from 'prop-types';
import EditIconAlt from 'components/Icons/editIconAlt';

import { SettingsItem, SettingDetails, SettingTitle, SettingDescription, SettingLink } from './styles';

const SettingsLink = ({ path, title, description }) => (
  <SettingsItem className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
    <SettingDetails>
      <SettingTitle>{title}</SettingTitle>
      <SettingDescription>{description}</SettingDescription>
    </SettingDetails>

    <SettingLink to={path}>
      <EditIconAlt width="28px" height="28px" />
    </SettingLink>
  </SettingsItem>
);

SettingsLink.propTypes = {
  path: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
};

export default SettingsLink;
