import ActionButton from 'components/ActionButton';
import React from 'react';

interface Props {
  callback: () => void;
  label: string;
}

export const Customise: React.FC<Props> = ({ callback, label }) => (
  <ActionButton onClick={callback} label={label} />
);
