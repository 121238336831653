import styled from 'styled-components';
import LoadingSpinner from 'components/Loading/LoadingSpinner';

export const Wrapper = styled.div`
  padding: 20px 0;
`;
export const Container = styled.div`
  margin: 20px auto;
`;

export const SelectionContainer = styled(Container)`
  margin-top: 0;
`;

export const AddressContainer = styled.div`
  position: relative;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};

  & + & {
    border-top: none;
  }

  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.color.grayLighter : theme.color.inputBackground};
`;

export const AddressLabelContainer = styled.div`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;
export const AddressLabel = styled.p`
  color: ${({ theme, primaryText }) => primaryText && theme.color.primaryText};
  font-size: 1.2em;
  line-height: 1.4;
  text-transform: capitalize;
`;

export const DefaultAddress = styled.span`
  text-transform: uppercase;
  font-size: 0.6em;
  padding: 0 5px;
`;

export const DeleteContainer = styled.button`
  color: ${({ theme }) => theme.color.danger};
  display: inline-block;
  padding: 7px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoadingSpinnerStyled = styled(LoadingSpinner)`
  margin: 13px 5px;
`;

export const AddressChoiceLabel = styled.label`
  display: flex;
  align-content: center;
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  color: ${({ theme }) => theme.color.text};
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextMessage = styled.span`
  padding: 0 5px;
  vertical-align: middle;
`;

export const VerifyButton = styled.button`
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.buttons.primaryBg};
  color: ${({ theme }) => theme.buttons.primaryColor};
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;

  html[dir='rtl'] & {
    left: 10px;
    right: initial;
  }
`;

export const DisabledMsg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.danger};
  color: #fff;
  opacity: 0.55;
  text-align: center;
`;
