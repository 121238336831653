import { openVenuesListErrorAlert } from 'actions/UI';
import { fetchHelper } from '../../utils';

import { REQUEST_ALL_VENUES, REQUEST_ALL_VENUES_SUCCESS, REQUEST_ALL_VENUES_FAILURE } from './constants';

function requestAllVenues() {
  return {
    type: REQUEST_ALL_VENUES,
  };
}

function requestAllVenuesSuccess(venuesList) {
  return {
    type: REQUEST_ALL_VENUES_SUCCESS,
    venuesList,
  };
}

function requestAllVenuesFailure() {
  return {
    type: REQUEST_ALL_VENUES_FAILURE,
  };
}

export const fetchAllVenues = () => dispatch => {
  dispatch(requestAllVenues());

  const success = venuesList => dispatch(requestAllVenuesSuccess(venuesList));

  const failure = () => {
    dispatch(openVenuesListErrorAlert());
    dispatch(requestAllVenuesFailure());
  };

  fetchHelper(`api/organisations/venues`, 'GET', null, success, failure);
};
