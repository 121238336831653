import React from 'react';
import { ChilliIcon } from './ChilliIcon';

interface Props {
  product: Map<string, any>;
}

export const Chilli = ({ product }: Props) => {
  const spiceLevel = product?.get('spice_level');

  if (!spiceLevel) {
    return null;
  }

  return <ChilliIcon amount={spiceLevel} />;
};
