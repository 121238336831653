import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  isDineInOrderingFlow,
  selectDineInLocationField,
  selectDineInPasscodeForConfirmationPage,
} from 'selectors/root';
import { selectCompletedOrderLocationRef } from 'reducers/root';
import { selectVenuePath, selectServiceId } from 'selectors/browse';
import YesIcon from 'components/Icons/YesIcon';
import { ShowPasscode } from 'components/Passcode';
import ShareButton from 'components/ShareButton';
import globalMessages from 'components/globalMessages';
import { makeUrl } from 'utils/externalLinks';
import { ContentContainer, ContentInner } from 'components/Pages/ThankYou/styles';
import messages from './messages';

import { StyledH1, IconContainer, StyledH2, Wrapper, PrimaryButton } from './styles';

const propTypes = {
  serviceId: PropTypes.string,
  passcode: PropTypes.string,
  locationField: PropTypes.object,
  locationRef: PropTypes.string,
  venuePath: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isDineInOrderFlow: PropTypes.bool,
};

const OrderInTheKitchen = ({
  serviceId,
  passcode,
  locationField,
  locationRef,
  venuePath,
  intl,
  isDineInOrderFlow,
}) => {
  let shareURL;
  const canShare = serviceId && locationField && locationRef && passcode && venuePath;

  if (canShare) {
    const params = `?passcode_${serviceId}=${passcode}&${locationField?.name}=${locationRef}`;
    const sharePath = `/service/${serviceId}${params}`;

    shareURL = `${makeUrl(venuePath)}${sharePath}`;
  }

  const { viewTab, orderAgain } = globalMessages;

  return (
    <ContentContainer>
      <ContentInner>
        <StyledH1>
          <FormattedMessage {...messages.orderInTheKitchenMsg} />
        </StyledH1>

        <IconContainer>
          <YesIcon width="85px" height="85px" />
        </IconContainer>

        {passcode && (
          <div>
            <StyledH2>
              <FormattedMessage {...globalMessages.yourPasscode} />
            </StyledH2>

            <Wrapper>
              <ShowPasscode passcode={passcode} />
            </Wrapper>

            {canShare && (
              <Wrapper>
                <ShareButton
                  btnLabel={<FormattedMessage {...globalMessages.sharePasscode} />}
                  shareText={intl.formatMessage(globalMessages.yourPasscode)}
                  shareURL={shareURL}
                />
              </Wrapper>
            )}
          </div>
        )}

        <PrimaryButton
          to={`/service/${serviceId}/order`}
          primaryButton={true}
          label={<FormattedMessage {...(isDineInOrderFlow ? viewTab : orderAgain)} />}
        />
      </ContentInner>
    </ContentContainer>
  );
};

OrderInTheKitchen.propTypes = propTypes;

export default injectIntl(
  connect(state => ({
    serviceId: selectServiceId(state),
    passcode: selectDineInPasscodeForConfirmationPage(state),
    locationField: selectDineInLocationField(state),
    locationRef: selectCompletedOrderLocationRef(state),
    venuePath: selectVenuePath(state),
    isDineInOrderFlow: isDineInOrderingFlow(state),
  }))(OrderInTheKitchen)
);
