import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import withCookies from 'components/withCookies';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import OrderingDeviceSelection from 'components/OrderingDeviceSelection';
import { registerOrderingDevice, unregisterOrderingDevice } from 'actions/order';
import MessageBlock from 'components/MessageBlock';
import { AlertButtons, AlertFooter, AlertContent, AlertTitle } from 'assets/styles/sharedStyles';
import messages from './intl';

const propTypes = {
  enabled: PropTypes.bool.isRequired,
  getCookie: PropTypes.func,
  unregisterOrderingDevice: PropTypes.func,
  removeCookie: PropTypes.func,
  setCookie: PropTypes.func,
  closeAlert: PropTypes.func.isRequired,
  registerOrderingDevice: PropTypes.func,
};

const KioskModeConfirmed = ({
  enabled,
  getCookie,
  unregisterOrderingDevice,
  removeCookie,
  setCookie,
  closeAlert,
  registerOrderingDevice,
}) => {
  const [selectedDevice, setSelectedDevice] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const enabledMsg = enabled ? globalMessages.enabled : globalMessages.disabled;

  useEffect(() => {
    const deviceId = getCookie('orderingDevice');

    if (!enabled && deviceId) {
      unregisterOrderingDevice(deviceId);

      removeCookie('orderingDevice');
    }
  }, [enabled]);

  const navigateHome = () => browserHistory.push('/');

  const handleOnClick = () => {
    const success = res => {
      setCookie('orderingDevice', res.id, {
        maxLifetime: true,
      });

      closeAlert();

      navigateHome();
    };

    const fail = err => setErrorMsg(err.error);

    if (selectedDevice) {
      registerOrderingDevice(selectedDevice, success, fail);
    } else {
      closeAlert();

      navigateHome();
    }
  };

  return (
    <>
      <AlertContent>
        <div className="containerFullPad">
          <AlertTitle>
            <FormattedMessage {...messages.header} />
          </AlertTitle>

          <p>
            <FormattedMessage
              {...messages.enabledMessage}
              values={{
                enabled: (
                  <strong>
                    <FormattedMessage {...enabledMsg} />
                  </strong>
                ),
              }}
            />
          </p>
        </div>

        {enabled && <OrderingDeviceSelection onChange={id => setSelectedDevice(id)} />}

        {errorMsg && (
          <div className="containerPad">
            <MessageBlock
              header={<FormattedMessage {...globalMessages.error} />}
              body={<FormattedMessage {...messages.deviceError} />}
              type="error"
            />
          </div>
        )}
      </AlertContent>

      <AlertFooter>
        <AlertButtons>
          <ActionButton
            primaryButton={true}
            label={<FormattedMessage {...globalMessages.confirm} />}
            onClick={handleOnClick}
          />
        </AlertButtons>
      </AlertFooter>
    </>
  );
};

KioskModeConfirmed.propTypes = propTypes;

export default withCookies(
  connect(null, { unregisterOrderingDevice, registerOrderingDevice })(KioskModeConfirmed)
);
