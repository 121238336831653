import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  canShowCouponField,
  selectPromotionCodeDetail,
  selectPromotionCouponValidating,
  selectPromotionCouponApplied,
  getCouponUpdatedBalance,
} from 'selectors/order';
import { getVenueId } from 'selectors/root';
import { updateOrderDetails } from 'actions/order';
import { setDetail } from 'actions/storage';
import { venuesWithMembershipNotDiscount } from 'reducers/fulfilmentReducer';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';

import { RefreshIconSpin } from 'assets/styles/iconStyles';
import { Label, ErrorMessage } from 'assets/styles/sharedStyles';
import messages from './messages';
import { CouponContainer, InputContainer, Input, BtnContainer, CouponDetails } from './styles';
import FormHeader from '../Form/FormHeader';
import RemainingBalance from '../RemainingBalance';

const propTypes = {
  showCouponField: PropTypes.bool,
  value: PropTypes.string,
  validatingCoupon: PropTypes.bool,
  couponApplied: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  balance: PropTypes.number,
  venueId: PropTypes.number,
  fulfilmentId: PropTypes.number,
  checkPromotion: PropTypes.func.isRequired,
  setDetail: PropTypes.func.isRequired,
  fieldTouched: PropTypes.bool,
};

const defaultProps = {
  fieldTouched: false,
};

const PromotionCoupon = ({
  showCouponField,
  value,
  validatingCoupon,
  couponApplied,
  balance,
  venueId,
  checkPromotion,
  setDetail,
  fieldTouched,
}) => {
  const [touched, setTouched] = useState(fieldTouched);

  if (!showCouponField) return null;

  const label = venuesWithMembershipNotDiscount.includes(venueId) ? 'membership_number' : 'promotion_coupon';
  const intlLabel = messages[label];
  const errorMessage = messages[`${label}_invalid`];
  const successMessage = messages[`${label}_applied`];
  const showMsg = value && touched && !validatingCoupon;

  return (
    <>
      <FormHeader
        title={<FormattedMessage {...globalMessages.redeemYourVoucherCode} />}
        requiredFields={false}
      />
      <CouponContainer>
        <InputContainer>
          <Label htmlFor="coupon" asPlaceholder={!value} required={false}>
            <FormattedMessage {...intlLabel} />
          </Label>

          <Input
            name="coupon"
            type="text"
            value={value}
            onChange={e => {
              setDetail(e.target.value);
              setTouched(false);
            }}
          />
        </InputContainer>

        <BtnContainer>
          {couponApplied && value && (
            <ActionButton
              label={<FormattedMessage defaultMessage="Remove" />}
              onClick={() => {
                setDetail('');
                checkPromotion();
                setTouched(true);
              }}
              buttonType="button"
              disabled={!value}
              dangerButton={true}
            />
          )}
          <ActionButton
            label={validatingCoupon ? '' : <FormattedMessage {...globalMessages.apply} />}
            onClick={() => {
              checkPromotion();
              setTouched(true);
            }}
            buttonIcon={validatingCoupon ? <RefreshIconSpin /> : null}
            buttonType="button"
            disabled={!value}
          />
        </BtnContainer>
      </CouponContainer>

      {showMsg && !couponApplied && (
        <ErrorMessage>
          <FormattedMessage {...errorMessage} />
        </ErrorMessage>
      )}

      {showMsg && couponApplied && (
        <>
          <CouponDetails>
            <FormattedMessage {...successMessage} />
            <RemainingBalance amount={balance} />
          </CouponDetails>
        </>
      )}
    </>
  );
};

PromotionCoupon.propTypes = propTypes;
PromotionCoupon.defaultProps = defaultProps;

const mapStateToProps = state => ({
  showCouponField: canShowCouponField(state),
  value: selectPromotionCodeDetail(state),
  validatingCoupon: selectPromotionCouponValidating(state),
  couponApplied: selectPromotionCouponApplied(state),
  balance: getCouponUpdatedBalance(state),
  venueId: getVenueId(state),
});

const mapDispatchToProps = dispatch => ({
  checkPromotion: () => dispatch(updateOrderDetails()),
  setDetail: value => dispatch(setDetail('promotion_coupon', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCoupon);
