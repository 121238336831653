import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import Form from 'components/Form';
import FormHeader from 'components/Form/FormHeader';
import fieldTypes from 'components/Form/fieldTypes';
import SettingsPage from 'components/Pages/User/SettingsPage';
import { selectUser } from 'selectors/user';
import { setUser } from 'actions/user';
import settingsMessages from 'components/settingsMessages';
import updateDetails from '../updateDetails';

const messages = defineMessages({
  editUserNameTitle: {
    defaultMessage: 'Edit User Name',
  },
  nameUpdated: {
    defaultMessage: 'Name updated successfully.',
  },
  errorUpdating: {
    defaultMessage: 'Could not update name, please try again later.',
  },
});

const formFields = [
  {
    ...fieldTypes.firstName,
    required: true,
  },
  {
    ...fieldTypes.lastName,
    required: true,
  },
  {
    ...fieldTypes.password,
    helpMessage: <FormattedMessage {...settingsMessages.passwordMessage} />,
  },
];

class EditUserName extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(Map),
    setUser: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  onSubmit = (values, callback) => {
    const email = this.props.user.get('email');
    const newDetails = {
      first_name: values.firstName,
      last_name: values.lastName,
    };

    updateDetails({ email, password: values.password, newDetails })
      .then(({ user }) => {
        this.props.setUser(user);
        callback({ success: true });
      })
      .catch(error => {
        const message = error.message === 'forbidden' ? settingsMessages.forbidden : messages.errorUpdating;
        this.setState({ error: message });
        callback({ error: true });
      });
  };

  render() {
    return (
      <SettingsPage>
        <FormHeader title={<FormattedMessage {...messages.editUserNameTitle} />} requiredFields={true} />
        <Form
          fields={formFields}
          onSubmit={this.onSubmit}
          submitButtonLabel={<FormattedMessage {...settingsMessages.saveChanges} />}
          errorHeader={<FormattedMessage {...settingsMessages.updateError} />}
          errorMessage={this.state.error ? <FormattedMessage {...this.state.error} /> : ''}
          successHeader={<FormattedMessage {...settingsMessages.success} />}
          successMessage={<FormattedMessage {...messages.nameUpdated} />}
        />
      </SettingsPage>
    );
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
});

export default connect(mapStateToProps, { setUser })(EditUserName);
