import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  orderNo: {
    defaultMessage: '#{number}',
  },
});

const OrderNumber = ({ number }) => (
  <strong>
    <FormattedMessage {...messages.orderNo} values={{ number }} />
  </strong>
);

OrderNumber.propTypes = {
  number: PropTypes.string,
};

export default OrderNumber;
