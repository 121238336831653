import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import ActionButton from '../../ActionButton';

export interface PointShopRedeemedProps {
  closeAlert: Function;
}

const PointShopRedeemedContainer = styled.div`
  text-align: start;
`;

const Title = styled.h2`
  padding: 1rem;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fonts.headline3FontSize};
  letter-spacing: -0.5px;
`;

const Content = styled.div`
  padding: 1rem;
`;

const Description = styled.p`
  padding-block-end: 1rem;
`;

const ButtonContainer = styled.div`
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row;
  direction: ltr;
  gap: 2px;

  [dir='rtl'] {
    flex-direction: row-reverse;
    direction: rtl;
  }
`;

const StyledActionButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.color.brandSecondary};
  color: ${({ theme }) => theme.color.brandPrimary};
  border-radius: 0;
  padding: 1.5rem 0;
`;

const PointShopRedeemed: React.FC<PointShopRedeemedProps> = ({ closeAlert }) => (
  <PointShopRedeemedContainer>
    <Title>
      <FormattedMessage defaultMessage="Points redeemed" />
    </Title>
    <Content>
      <Description>
        <FormattedMessage defaultMessage="You have successfully redeemed your points. Please ensure this item is added to your order." />
      </Description>
    </Content>
    <ButtonContainer>
      <StyledActionButton
        buttonType="button"
        onClick={closeAlert}
        primaryButton={false}
        label={<FormattedMessage defaultMessage="Back" />}
      />
    </ButtonContainer>
  </PointShopRedeemedContainer>
);

export default PointShopRedeemed;
