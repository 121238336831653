import React from 'react';
import { defineMessages } from 'react-intl';

import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  message: {
    defaultMessage: 'We are sorry! We cannot show more venues right now.',
  },
  confirm: {
    defaultMessage: 'Close',
  },
});

export default props => <StandardAlert {...props} messages={messages} config={{ buttons: 1 }} />;
