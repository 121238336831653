import React from 'react';
import PropTypes from 'prop-types';

// Icon
const SwitchIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391 C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55 c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M294.2,271.1L263,302.9c21.2,20.8,50.5,33.7,82.9,33.7c64.5,0,116.8-50.9,116.8-113.7 c0-33.4-14.9-63.3-38.4-84.1l-30.9,31.5c14,13.1,22.8,31.8,22.8,52.7c0,39.5-31.4,71.6-70.2,71.6C325.4,294.5,307,285.4,294.2,271.1 z"
      />
      <path
        className="icon"
        fill={fill}
        d="M434.1,73.1c-64.5,0-116.8,50.9-116.8,113.7c0,33.4,14.9,63.3,38.4,84.1l30.9-31.5 c-14-13.1-22.8-31.8-22.8-52.7c0-39.5,31.4-71.6,70.2-71.6c20.5,0,38.9,9,51.7,23.3l31.2-31.8C495.8,86,466.5,73.1,434.1,73.1z"
      />
      <path
        className="icon"
        fill={fill}
        d="M246.2,434.4c-6.9,0-11.9-1.9-14.9-5.6c-3-3.7-3.6-8.7-1.7-14.9l0.4-1.4h10.3l-0.6,2.4 c-0.6,2.5-0.6,4.5-0.1,5.9c0.3,0.9,1.4,3.8,8.8,3.8c2.4,0,4.7-0.3,6.8-1c3.9-1.2,6.3-3.3,7.2-6.4c0.6-2,0.5-3.3-0.2-3.9 c-0.8-0.7-2.6-1.6-6.6-2.6l-6.5-1.6c-4.5-1.1-7.6-2.3-9.3-3.7c-2.2-1.8-4.5-5.3-2.6-11.6c1.5-5,4.5-9.1,8.8-12.3 c4.4-3.2,9.7-4.8,15.9-4.8c5.8,0,10.4,1.5,13.7,4.3c3.6,3.1,4.4,7.9,2.5,14.4l-0.4,1.4h-10.2l0.4-2.3c0.5-2.9,0.2-4.4-0.2-5.1 c-0.9-1.9-3.6-2.9-7.8-2.9c-3.6,0-6.5,0.7-8.4,2.2c-2,1.5-3.2,3.1-3.8,5c-0.7,2.4,0.2,3,0.7,3.3c0.5,0.3,2.2,1.1,7.8,2.5l6.7,1.6 c3.6,0.9,6.1,2.1,7.9,3.7c3.1,2.9,3.9,7.1,2.3,12.3c-1.8,6.1-5.5,10.6-10.8,13.3C257.4,433.1,251.9,434.4,246.2,434.4z"
      />
      <polygon
        className="icon"
        fill={fill}
        points="321.1,432.9 321.8,396.1 300.6,432.9 290.2,432.9 292.3,378 303.7,378 301.3,413.3 321.7,378 332.2,378 331.5,413.4 350.3,378 362.3,378 331.4,432.9"
      />
      <polygon className="icon" fill={fill} points="356.2,432.9 372.6,378 383.6,378 367.2,432.9" />
      <polygon
        className="icon"
        fill={fill}
        points="394.4,432.9 407.8,387.9 390.7,387.9 393.6,378 439,378 436.1,387.9 418.9,387.9 405.4,432.9"
      />
      <path
        className="icon"
        fill={fill}
        d="M454.7,434.2c-6.5,0-11.5-2-14.8-6.1c-4.3-5.3-4.9-13.2-1.7-23.7c2.3-7.8,6.4-14.4,12.1-19.4 c6.2-5.5,13.4-8.3,21.4-8.3c7.1,0,12.1,2,15,5.9c2.9,3.8,3.8,8.3,2.8,13.1l-0.3,1.5h-10.4l0.1-2.1c0.2-2.8-0.4-4.9-1.8-6.4 c-1.3-1.5-3.8-2.2-7.3-2.2c-4.6,0-8.7,1.6-12.5,4.8c-3.8,3.3-6.8,8.4-8.9,15.3c-1.6,5.5-1.7,9.9-0.3,13.2c1.3,3,4.3,4.4,9.1,4.4 c4.6,0,8.5-1.7,12-5.3c1.9-1.9,3.6-4.5,5.2-7.6l0.5-1.1H486l-1.1,2.7c-2.3,5.6-5.9,10.4-10.6,14.3 C468.6,431.8,462,434.2,454.7,434.2z"
      />
      <polygon
        className="icon"
        fill={fill}
        points="524.1,432.9 531.3,409 508.8,409 501.6,432.9 490.6,432.9 507.1,378 518,378 511.7,399.1 534.2,399.1 540.5,378 551.5,378 535.1,432.9"
      />
    </g>
  </svg>
);

SwitchIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

SwitchIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default SwitchIcon;
