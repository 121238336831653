import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon InfoIcon"
        fill={fill}
        opacity={opacity}
        d="M189.3,24.3c9.7,7.2,18.1,15.6,25.3,25.3c7.2,9.7,12.8,20.5,16.8,32.5c4,12,6,24.4,6,37.1c0,15.9-3.1,31.2-9.3,45.7 c-6.2,14.6-14.6,27.1-25,37.6c-10.5,10.5-23,18.8-37.6,25c-14.6,6.2-29.8,9.3-45.7,9.3c-15.9,0-31.2-3.1-45.7-9.3	c-14.6-6.2-27.1-14.6-37.6-25c-10.5-10.5-18.8-23-25-37.6C5.1,150.4,2,135.1,2,119.2c0-12.7,2-25.1,6-37.1c4-12,9.6-22.9,16.8-32.5 c7.2-9.7,15.6-18.1,25.3-25.3c9.7-7.2,20.5-12.8,32.5-16.8c12-4,24.4-6,37.1-6c12.7,0,25.1,2,37.1,6 C168.8,11.5,179.6,17.1,189.3,24.3z M159.6,214.2c12.7-5.4,23.7-12.8,32.9-22.1c9.2-9.3,16.5-20.3,21.9-33c5.4-12.7,8.2-26,8.2-40	c0-13.9-2.7-27.3-8.2-40c-5.4-12.7-12.8-23.7-21.9-32.9c-9.2-9.2-20.1-16.5-32.9-21.9c-12.7-5.4-26-8.2-40-8.2 c-13.9,0-27.3,2.7-40,8.2C66.9,29.9,56,37.2,46.8,46.4S30.3,66.5,24.9,79.2c-5.4,12.7-8.2,26-8.2,40c0,11.2,1.7,22,5.2,32.5	c3.4,10.5,8.3,20,14.7,28.4c6.4,8.4,13.7,15.9,22.2,22.3c8.4,6.4,17.9,11.4,28.4,14.8c10.5,3.4,21.3,5.2,32.5,5.2	C133.6,222.4,146.9,219.7,159.6,214.2z M139.5,172.9c1.5,1.5,2.2,3.2,2.2,5.2c0,2-0.7,3.7-2.2,5.2c-1.5,1.5-3.2,2.2-5.2,2.2h-29.4 	c-2,0-3.7-0.7-5.2-2.2c-1.5-1.5-2.2-3.2-2.2-5.2c0-2,0.7-3.7,2.2-5.2c1.5-1.5,3.2-2.2,5.2-2.2h7.4v-58.8h-7.4c-2,0-3.7-0.7-5.2-2.2 c-1.5-1.5-2.2-3.2-2.2-5.2c0-2,0.7-3.7,2.2-5.2c1.5-1.5,3.2-2.2,5.2-2.2h14.7c2,0,3.7,0.7,5.2,2.2c1.5,1.5,2.2,3.2,2.2,5.2v0.2v0.2 v65.7h7.4C136.3,170.7,138.1,171.4,139.5,172.9z M104.9,67.7c0-4.1,1.4-7.6,4.2-10.5c2.8-2.8,6.3-4.3,10.5-4.3 c4.1,0,7.6,1.4,10.5,4.3c2.8,2.8,4.3,6.3,4.3,10.5c0,4.1-1.4,7.6-4.3,10.5c-2.8,2.8-6.3,4.3-10.5,4.3c-4.1,0-7.6-1.4-10.5-4.3	C106.4,75.3,104.9,71.9,104.9,67.7z"
      />
    </g>
  </svg>
);

InfoIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

InfoIcon.defaultProps = {
  width: '24px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default InfoIcon;
