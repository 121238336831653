import React from 'react';
import PropTypes from 'prop-types';

// Icon
const LoginIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M29,237V113h31V62.5c0-8.184,1.878-15.954,5.088-23.095c3.196-7.099,7.642-13.398,12.968-18.725	c5.333-5.333,11.695-9.653,18.787-12.843C103.974,4.628,111.776,3,119.969,3c8.185,0,15.971,1.628,23.11,4.838	c7.1,3.196,13.407,7.517,18.732,12.843c5.325,5.324,9.399,11.624,12.596,18.723C177.618,46.544,179,54.314,179,62.5V82h-12V62.5	c0-6.245-1.402-12.248-3.869-17.842c-2.473-5.603-5.982-10.627-10.281-14.935c-3.991-4.316-8.942-7.728-14.691-10.194	c-5.767-2.47-11.882-3.723-18.139-3.723c-6.264,0-12.369,1.252-18.128,3.723c-5.747,2.462-10.664,5.874-14.606,10.139	c-4.347,4.349-7.936,9.374-10.415,14.991C74.406,50.251,73,56.254,73,62.5V113h138v124H29z M198,224v-97H42v97H198z"
      />
    </g>
  </svg>
);

LoginIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LoginIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default LoginIcon;
