import styled, { css } from 'styled-components';

export const LanguageSelectionContainer = styled.div`
  backdrop-filter: brightness(0.5);
  border-radius: 10px;
  left: 50%;
  opacity: ${({ hide }) => hide && 0};
  padding: 2rem;
  position: absolute;
  transform: translate(-50%, -125%);
  transition: opacity 200ms ease-out;
`;

export const LanguageOptions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-block: 1rem;
`;

export const LanguageButton = styled.button`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.buttons.defaultBorder};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.text};
  opacity: 0.5;
  padding: 1rem;
  transition: opacity 200ms ease-out;
  white-space: nowrap;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 0 10px ${({ theme }) => theme.color.iconFill};
      opacity: 1;
      pointer-events: none;
    `}
`;

export const OtherLanguageButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.buttons.primaryColour};
  border-radius: 10px;
  padding: 1rem;
  white-space: nowrap;
  width: 45%;
`;

export const LanguageSelectPrompt = styled.p`
  /* color: ${({ theme }) => theme.color.text}; */
`;
