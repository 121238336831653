import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import { AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import ActionButton from 'components/ActionButton';
import { expireSession } from 'actions/order';
import globalMessages from 'components/globalMessages';

import { Container } from './styles';

const messages = defineMessages({
  header: {
    defaultMessage: 'Session Timeout',
  },
  message: {
    defaultMessage: 'You are being timed out due to inactivity. Session will timeout in {seconds} seconds',
  },
  keepSession: {
    defaultMessage: 'Keep My Session',
  },
});

export class InactiveAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 30,
    };
    this.interval = 0;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => {
        if (prevState.seconds - 1 === 0) {
          this.props.clearSession();
          this.closeAlert();
        } else return { seconds: prevState.seconds - 1 };
      });
    }, 1000);
  }

  closeAlert = () => {
    clearInterval(this.interval);
    this.props.closeAlert();
  };

  render() {
    return (
      <div>
        <AlertTitle>
          <FormattedMessage {...messages.header} />
        </AlertTitle>
        <Container>
          <p>
            <FormattedMessage
              {...messages.message}
              values={{
                seconds: this.state.seconds,
              }}
            />
          </p>
        </Container>
        <AlertFooter>
          <AlertButtons>
            <ActionButton
              dangerButton={true}
              label={<FormattedMessage {...globalMessages.clearSession} />}
              onClick={this.props.clearSession}
            />
            <ActionButton
              primaryButton={true}
              label={<FormattedMessage {...messages.keepSession} />}
              onClick={this.closeAlert}
            />
          </AlertButtons>
        </AlertFooter>
      </div>
    );
  }
}

InactiveAlert.propTypes = {
  closeAlert: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  clearSession: () => dispatch(expireSession()),
});

export default connect(undefined, mapDispatchToProps)(InactiveAlert);
