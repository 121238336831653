import React from 'react';
import PropTypes from 'prop-types';

const ToyouIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient id="a" y1="0.501" x2="1" y2="0.501" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#7bc580" />
        <stop offset="1" stopColor="#00b0df" />
      </linearGradient>
    </defs>

    <rect fill="none" className="icon ToyouIcon" width="180" height="180" />
    <g transform="translate(23.432 34.5)">
      <path
        fill="#003462"
        stroke="#003462"
        strokeMiterlimit="10"
        strokeWidth="0.926px"
        className="icon ToyouIcon"
        d="M174.858,92.806H209.78a1.95,1.95,0,0,0,1.958-1.958v-.49A1.95,1.95,0,0,0,209.78,88.4H174.858a1.95,1.95,0,0,0-1.958,1.958v.49A1.95,1.95,0,0,0,174.858,92.806Z"
        transform="translate(-78.875 -40.543)"
      />
      <path
        fill="url(#a)"
        className="icon ToyouIcon"
        d="M113.662,161.786c-12.457,0-19.637-6.636-19.637-18.168V112.993c0-1.305.707-1.958,2.067-1.958h1.469a1.777,1.777,0,0,1,1.958,1.958v30.625c0,8.431,5,13.109,14.143,13.109,9.084,0,14.088-4.678,14.088-13.109V112.993a1.777,1.777,0,0,1,1.958-1.958h1.523a1.777,1.777,0,0,1,1.958,1.958v30.625C133.244,155.2,126.119,161.786,113.662,161.786ZM22.55,161.3H21.081c-1.36,0-2.067-.653-2.067-1.958V140.9L.248,114.081a1.691,1.691,0,0,1-.109-1.741,1.837,1.837,0,0,1,1.577-.762h1.9a2.48,2.48,0,0,1,2.611,1.305l15.611,22.574,15.666-22.683a2.56,2.56,0,0,1,2.611-1.251H41.86a1.837,1.837,0,0,1,1.577.762,1.675,1.675,0,0,1-.054,1.741l-.054.054L24.508,140.953v18.386A1.777,1.777,0,0,1,22.55,161.3Zm58.8-45.964C77.87,112.177,72.92,110.6,66.61,110.6c-12.729,0-20.072,6.419-20.072,17.624v16.319c0,11.205,7.289,17.624,20.072,17.624,6.31,0,11.26-1.577,14.741-4.732,3.373-3.046,5.168-7.507,5.168-12.892V128.224C86.519,122.893,84.724,118.433,81.351,115.332ZM81.025,144.6c0,8.1-5.113,12.565-14.415,12.565-9.41,0-14.578-4.46-14.578-12.565V128.279c0-8.1,5.168-12.565,14.578-12.565,9.3,0,14.415,4.46,14.415,12.565Z"
        transform="translate(0 -50.667)"
      />
      <path
        fill="#003462"
        className="icon ToyouIcon"
        d="M120.413,4.232C116.932,1.077,111.982-.5,105.672-.5,92.889-.5,85.6,5.973,85.6,17.178V33.5c0,11.205,7.289,17.624,20.072,17.624,6.31,0,11.26-1.577,14.741-4.732,3.373-3.046,5.168-7.507,5.168-12.892V17.178c0-5.44-1.8-9.9-5.168-12.946ZM120.087,33.5c0,8.1-5.113,12.565-14.415,12.565-9.41,0-14.578-4.46-14.578-12.565V17.178c0-8.1,5.168-12.565,14.578-12.565,9.3,0,14.415,4.46,14.415,12.565Z"
        transform="translate(-39.062)"
      />
      <path
        fill="#003462"
        className="icon ToyouIcon"
        d="M25.091,52.017H23.622c-1.36,0-2.067-.653-2.067-1.958V7.359H7.467c-1.305,0-2.067-.653-2.067-1.9v-1.2C5.4,2.953,6.107,2.3,7.467,2.3H41.083c1.36,0,2.067.653,2.067,1.958v1.2c0,1.2-.707,1.9-2.067,1.9H27.049v42.7A1.753,1.753,0,0,1,25.091,52.017Z"
        transform="translate(-2.487 -1.277)"
      />
    </g>
  </svg>
);

ToyouIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ToyouIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default ToyouIcon;
