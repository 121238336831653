const sortVenuesByDistance = venues =>
  venues.sort((firstVenue, secondVenue) => {
    if (isNaN(firstVenue.distance) && isNaN(secondVenue.distance)) {
      return 0;
    }
    if (isNaN(firstVenue.distance)) {
      return 1;
    }
    if (isNaN(secondVenue.distance)) {
      return -1;
    }
    return firstVenue.distance > secondVenue.distance ? 1 : -1;
  });

module.exports = sortVenuesByDistance;
