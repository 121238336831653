import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';
import { H1 } from 'components/Typography';

export const Details = styled.div`
  text-align: left;
  display: flex;
  margin: 0 -10px;

  @media (max-width: 780px) {
    flex-flow: row wrap;
    margin: 0;
  }

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const FlexBox = styled.div`
  @media (max-width: 779px) {
    flex-grow: 1;
  }

  @media (min-width: 767px) {
    padding: 0 5px;
    width: ${({ hasImage }) => hasImage && '50%'};
  }
`;

export const ProductName = styled.div`
  color: ${({ theme }) => theme.color.primaryText};
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const StyledH1 = styled(H1)`
  display: inline-block;
  line-height: 1.2;
`;

export const Price = styled.p`
  color: ${({ theme }) => theme.color.primaryText};
  ${fontSizeBig};
`;

export const Description = styled.p`
  white-space: pre-line;
`;
