import React from 'react';
import PropTypes from 'prop-types';

const UbereatsIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" className="icon UbereatsIcon" width="180" height="180" />
    <g transform="translate(30 44)">
      <path
        fill={fill}
        className="icon UbereatsIcon"
        d="M175.553,76.683c-3.779,0-6.46,2.957-6.46,7.5V100.83h-5.769V71.522h5.7V75.1a7.731,7.731,0,0,1,6.868-3.786h2.073v5.368Zm-16.215,9.493c0-8.74-6.218-15.276-14.633-15.276a15.309,15.309,0,0,0,.546,30.614,15.317,15.317,0,0,0,12.643-6.26l-4.187-3.068a10.212,10.212,0,0,1-8.457,4.263,9.825,9.825,0,0,1-9.673-8.388h23.774ZM135.7,83.488a9.209,9.209,0,0,1,8.933-7.5,9.087,9.087,0,0,1,8.864,7.5ZM111.451,70.9a14.592,14.592,0,0,0-10.364,4.332V60.578H95.319V100.83h5.7V97.113a14.82,14.82,0,0,0,10.446,4.4,15.309,15.309,0,1,0,0-30.617Zm-.415,25.46a4.186,4.186,0,1,0,0-.028Zm-36.963-.276c5.569,0,9.894-4.27,9.894-10.667V60.578h5.976V100.83H84.037V97.023a14.675,14.675,0,0,1-10.647,4.47c-8.588,0-15.2-6.259-15.2-15.752V60.578h6.087V85.45C64.277,91.883,68.422,96.083,74.074,96.083Z"
        transform="translate(-58.062 -60.578)"
      />
      <path
        fill="#5fb709"
        className="icon UbereatsIcon"
        d="M57.926,163.595h28.3v6.923H65.569v9.831H85.646v6.688H65.569v10H86.221v6.923h-28.3Zm106.437,41.089c8.675,0,13.563-4.154,13.563-9.859,0-4.064-2.894-7.1-8.952-8.412l-6.4-1.308c-3.718-.692-4.888-1.385-4.888-2.769,0-1.793,1.793-2.894,5.1-2.894,3.579,0,6.231.962,6.923,4.272h7.5c-.409-6.231-4.888-10.343-13.971-10.343-7.851,0-13.362,3.24-13.362,9.519,0,4.341,3.032,7.166,9.575,8.543l7.159,1.655c2.825.554,3.579,1.315,3.579,2.485,0,1.862-2.132,3.032-5.58,3.032-4.334,0-6.812-.962-7.775-4.272h-7.546c1.1,6.217,5.712,10.35,15.079,10.35Zm-17.26-.755h-8.121c-5.1,0-7.92-3.171-7.92-7.172V180.9h-5.712v-6.757h5.739v-8.481h7.546v8.481H147.1V180.9h-8.467v13.923c0,1.585,1.1,2.347,2.825,2.347H147.1Zm-32.539-29.77v2.686a14.649,14.649,0,0,0-9.506-3.462,15.652,15.652,0,1,0,0,31.3,14.607,14.607,0,0,0,9.506-3.465v2.693h7.526V174.146Zm-8.882,23.989a9.1,9.1,0,1,1,9-9.1,9,9,0,0,1-9.014,9.083Z"
        transform="translate(-57.926 -113.596)"
      />
    </g>
  </svg>
);

UbereatsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

UbereatsIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default UbereatsIcon;
