import React, { ReactNode } from 'react';

import { Headline3 } from './styles';

type Props = {
  children?: ReactNode;
  className?: string;
};

const H3 = ({ children, className }: Props) => <Headline3 className={className}>{children}</Headline3>;

export default H3;
