import styled from 'styled-components';

import { StyledDropdown } from 'assets/styles/sharedStyles';

export const MenuStyle = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.seven};
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow: scroll;
  ${StyledDropdown}
`;

export const Item = styled.button`
  background: ${({ highlighted, theme }) => highlighted && theme.color.secondaryBackground};
  border-color: ${({ highlighted, theme }) => highlighted && theme.color.greyLighter};
  padding: 4px;
  text-align: left;
  width: 100%;
`;
