import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';

import productMessages from 'components/Product/messages';
import globalMessages from 'components/globalMessages';
import { trackEvent } from 'utils/tracking';
import messages from './messages';

const NavigationButtons = ({ cancel, browseOnly, editing, addToOrder }) => (
  <AlertFooter>
    <AlertButtons>
      <ActionButton
        dangerButton={true}
        label={
          browseOnly ? (
            <FormattedMessage {...globalMessages.close} />
          ) : (
            <FormattedMessage {...globalMessages.cancel} />
          )
        }
        onClick={cancel}
      />
      {!browseOnly && (
        <ActionButton
          label={
            editing ? (
              <FormattedMessage {...messages.update_item} />
            ) : (
              <FormattedMessage {...productMessages.addToOrder} />
            )
          }
          primaryButton={true}
          onClick={() => {
            if (editing) {
              trackEvent('update_item', {
                category: 'item',
                location: 'review',
              });
            }
            addToOrder();
          }}
        />
      )}
      {browseOnly && (
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.close} />}
          onClick={cancel}
        />
      )}
    </AlertButtons>
  </AlertFooter>
);

NavigationButtons.propTypes = {
  editing: PropTypes.bool,
  browseOnly: PropTypes.bool,
  cancel: PropTypes.func,
  addToOrder: PropTypes.func,
};

export default NavigationButtons;
