import React from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getLocale } from 'selectors/user';
import { Title, LinkMenuStyled } from './styles';

const messages = defineMessages({
  label: {
    defaultMessage: 'Other Menus',
  },
});

type Props = {
  menus?: Map<string, any>;
  serviceId?: string;
  menuId?: string;
  toggleSidebarOpen: () => void;
  currentLocale: string;
};

const SidebarMenuChoices = ({ menus, serviceId, menuId, toggleSidebarOpen, currentLocale }: Props) => {
  if (serviceId && menuId && menus?.size > 1) {
    return (
      <div className="u-textCenter">
        <Title>
          <FormattedMessage {...messages.label} />
        </Title>
        <ul>
          {menus
            .valueSeq()
            .toArray()
            .map(menu => {
              if (menu.get('id').toString() === menuId || !menu.get('active')) return null;
              const path = `/service/${serviceId}/menu/${menu.get('id')}/`;

              return (
                <li key={path}>
                  <LinkMenuStyled onClick={toggleSidebarOpen} to={path}>
                    {menu?.get('translations')?.get(currentLocale)?.get('name') ?? menu.get('name')}
                  </LinkMenuStyled>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(SidebarMenuChoices);
