/* eslint-disable jsx-a11y/alt-text */
import styled from 'styled-components';
import React from 'react';

const StyledObject = styled.object`
  margin-bottom: -3px;
  pointer-events: none;
`;

const ExternalSvg = ({
  width,
  height,
  customer,
  iconName,
}: {
  width: string;
  height: string;
  customer: string;
  iconName: string;
}) => <StyledObject data={`/assets/icons/${customer}/${iconName}.svg`} height={height} width={width} />;

export default ExternalSvg;
