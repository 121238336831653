import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isDineInOrderingFlow } from 'selectors/root';

import { clearAlerts } from 'actions/UI';
import { AlertButtons, AlertContent, AlertFooter } from 'assets/styles/sharedStyles';
import DineInConfirmation from 'components/DineInConfirmation';
import Order from 'components/Order';
import CheckoutButton from 'components/Order/CheckoutButton';
import PaymentInformation from 'components/PaymentInformation';
import { sendEventBeginCheckout } from 'middleware/analyticsMiddleware';

const propTypes = {
  clearAlerts: PropTypes.func,
  isDineInOrderingFlow: PropTypes.bool,
  sendState: PropTypes.func,
};

const OrderAlert = ({ clearAlerts, isDineInOrderingFlow, sendState }) => {
  const onClick = () => {
    sendState();
    clearAlerts();
  };

  return (
    <>
      <AlertContent>
        <Order />
        {isDineInOrderingFlow && <DineInConfirmation />}
        {isDineInOrderingFlow || <PaymentInformation />}
      </AlertContent>
      {isDineInOrderingFlow || (
        <AlertFooter>
          <AlertButtons>
            <CheckoutButton onClick={onClick} />
          </AlertButtons>
        </AlertFooter>
      )}
    </>
  );
};

OrderAlert.propTypes = propTypes;

export default connect(
  state => ({
    isDineInOrderingFlow: isDineInOrderingFlow(state),
    sendState: () => sendEventBeginCheckout(state),
  }),
  {
    clearAlerts,
  }
)(OrderAlert);
