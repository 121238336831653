import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import ActionButton from 'components/ActionButton';
import TermsAndConditions from 'components/TermsAndConditions';
import { setDetail } from 'actions/storage';
import { AlertHeader, AlertContent, AlertButtons, AlertFooter } from 'assets/styles/sharedStyles';
import globalMessages from 'components/globalMessages';

import { StyledTitle } from './styles';

const messages = defineMessages({
  header: {
    defaultMessage: 'Terms & Conditions',
  },
  confirm: {
    defaultMessage: 'I Agree',
  },
});

const TermsAndConditionsAlert = ({ closeAlert = () => {}, acceptTermsAction, confirmMethod }) => (
  <>
    <AlertHeader>
      <StyledTitle>
        <FormattedMessage {...messages.header} />
      </StyledTitle>
    </AlertHeader>
    <AlertContent>
      <TermsAndConditions className="u-textLeft" />
    </AlertContent>
    <AlertFooter>
      <AlertButtons>
        <ActionButton
          dangerButton={true}
          label={<FormattedMessage {...globalMessages.cancel} />}
          onClick={closeAlert}
        />
        <ActionButton
          primaryButton={true}
          label={<FormattedMessage {...messages.confirm} />}
          onClick={() => {
            confirmMethod();
            closeAlert(acceptTermsAction(true));
          }}
        />
      </AlertButtons>
    </AlertFooter>
  </>
);

TermsAndConditionsAlert.propTypes = {
  closeAlert: PropTypes.func,
  acceptTermsAction: PropTypes.func,
  confirmMethod: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  acceptTermsAction: value => dispatch(setDetail('terms', value)),
});

const ConnectedTermsAndConditionsAlert = connect(undefined, mapDispatchToProps)(TermsAndConditionsAlert);

export default ConnectedTermsAndConditionsAlert;
