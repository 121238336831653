import styled, { css } from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';

export const ModifierContainer = styled.div`
  margin: 10px 0 30px;
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ModifierOptions = styled.div`
  clear: both;
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  ${props =>
    props.extended &&
    css`
      @media (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
      }
    `}
`;

export const Label = styled.p`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.primaryText};
  ${fontSizeBig}

  ${props =>
    props.required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        font-size: inherit;
        color: ${({ theme }) => theme.color.danger};
      }
    `}

  ${props =>
    props.error &&
    css`
      color: ${({ theme }) => theme.color.danger};
    `}
`;

export const Error = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.danger};
  background-color: ${({ theme }) => theme.color.errorBackgroundColor};
  color: ${({ theme }) => theme.color.danger};
  line-height: 1.3;
  padding: 10px;
  margin-top: 20px;
`;
