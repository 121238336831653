import React from 'react';
import PropTypes from 'prop-types';

// Icon
const EggIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon eggIcon"
        fill={fill}
        opacity={opacity}
        d="M499.9,990c-49.9,0-98.7-11.9-145.1-35.2c-44.3-22.3-84.2-53.9-118.7-94.1c-70.8-82.3-109.8-189.1-109.8-300.8	c0-71.6,10.3-142.3,30.6-210.1c19.5-65.1,47.2-124.2,82.3-175.5c35-51.2,75.1-91.6,119.3-120C404.3,24.9,451.8,10,500,10	c48.1,0,95.7,14.9,141.4,44.3c44.2,28.4,84.3,68.8,119.3,120c35.1,51.4,62.8,110.5,82.3,175.5c20.3,67.8,30.6,138.4,30.6,210.1	c0,111.7-39,218.5-109.8,300.8c-34.5,40.1-74.5,71.8-118.7,94C598.6,978.1,549.8,990,499.9,990z M500,59.2	c-41.8,0-83.4,14.1-123.5,42c-38.1,26.5-72.9,63.8-103.3,111.1c-62.1,96.4-97.6,223.1-97.6,347.7c0,206.5,148.5,380.9,324.3,380.9	c175.9,0,324.5-174.4,324.5-380.9c0-124.5-35.6-251.2-97.7-347.7c-30.4-47.2-65.1-84.6-103.3-111.1C583.4,73.3,541.8,59.2,500,59.2z"
      />
      <path
        className="icon eggIcon"
        fill={fill}
        opacity={opacity}
        d="M493.9,842.4c-53.3-1.9-105.4-29.6-147.3-78.4c-45.9-53.4-72.8-125.6-74.2-198.9h48.6c1.4,61.9,23.9,122.5,62.4,167.2 c32.5,37.8,71.5,59.4,110.5,61.4V842.4z"
      />
    </g>
  </svg>
);

EggIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

EggIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default EggIcon;
