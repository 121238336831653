import React, { forwardRef, ChangeEvent, FocusEvent } from 'react';
import styled from 'styled-components';

import { Container, Label, StyledInput as InputStyles, HelpMessage } from 'assets/styles/sharedStyles';

const StyledInput = styled.input`
  ${InputStyles}
`;

interface InputProps {
  value?: string;
  name?: string;
  type?: string;
  required?: boolean;
  label: React.ReactNode;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  helpBlock?: React.ReactNode;
  className?: string;
  error?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { value = '', name, type = 'text', required, label, onChange, onBlur, helpBlock, className, error },
    ref
  ) => (
    <Container>
      <Label htmlFor={name} asPlaceholder={!value} required={required}>
        {label}
      </Label>
      <StyledInput
        ref={ref}
        haserror={error}
        className={className}
        name={name}
        type={type}
        value={value}
        inputMode={type === 'number' ? 'numeric' : 'text'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => (onBlur ? onBlur(e.target.value) : undefined)}
      />
      {helpBlock && <HelpMessage $error={error}>{helpBlock}</HelpMessage>}
    </Container>
  )
);

export default Input;
