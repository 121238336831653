import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';

class NumberModifier extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    updateValues: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.defaultValue,
  };

  updateValue = value => {
    this.setState({ value }, () => this.props.updateValues(value));
  };

  render = () => (
    <Input
      name="number"
      type="number"
      label={this.props.label}
      value={this.state.value}
      onChange={value => this.updateValue(value)}
    />
  );
}

export default NumberModifier;
