import styled, { css } from 'styled-components';

export const LineItemContainer = styled.div`
  padding: 10px;
  font-size: 16px;
  display: flex;
  color: ${({ theme }) => theme.color.primaryText};

  :not(:last-of-type) {
    border-bottom: 5px solid ${({ theme }) => theme.color.primaryBackground};
  }
`;

export const OrderLineItemContainer = styled(LineItemContainer)`
  flex-direction: column;
`;

export const LineItemPrice = styled.span`
  font-weight: bold;
`;

export const EditContainer = styled.div`
  color: #5798fd;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 5px;
  line-height: 27px;

  svg {
    margin: 5px;
  }
`;

export const RemoveContainer = styled.div`
  padding: 0 5px;
  position: relative;
  top: -1px;
`;

export const Modifier = styled.div`
  font-style: italic;
  font-size: 13px;
  color: ${({ theme }) => theme.color.text};
`;

export const ModifierPrice = styled.div`
  float: right;
`;

export const ItemDetails = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledName = styled.div`
  display: inline-block;
  padding-right: 70px;
  margin-bottom: 10px;
  flex-grow: 1;

  html[dir='rtl'] & {
    padding-right: 0;
    padding-left: 70px;
  }
`;

export const sharedLabelStyles = css`
  text-align: right;
  flex-grow: 1;
  padding: 0 20px;
`;

export const PriceLabel = styled.span`
  font-weight: bold;
  ${sharedLabelStyles};
`;

export const Price = styled.div`
  min-width: 75px;
  text-align: right;
  font-weight: bold;
  align-self: flex-end;

  html[dir='rtl'] & {
    text-align: left;
  }
`;
