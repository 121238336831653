import styled, { css } from 'styled-components';

export const MiniProduct = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  color: ${({ theme }) => theme.color.text};
  padding: 7px;
  display: flex;
  position: relative;
  width: 100%;

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.color.secondaryBackground};
    `}

  @media (min-width: 768px) {
    width: 49%;

    &:nth-child(odd) {
      margin-right: 1%;
    }

    &:nth-child(even) {
      margin-left: 1%;
    }
  }
`;

export const Image = styled.div`
  position: relative;
  border-radius: 5px;
  width: 70px;
  min-width: 70px;
  height: 70px;
  margin-right: 10px;
  align-self: center;

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: 10px;
  }

  img {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props.awaiting &&
    css`
      background: ${({ theme, ...props }) =>
        props.checked ? theme.color.primaryBackground : theme.color.secondaryBackground};
      text-align: center;
      color: #999;
    `}
`;

export const AwaitingIcon = styled.div`
  svg {
    height: auto;
    padding-top: 10%;
    width: 35%;
    display: block;
    margin: 0 auto;
  }
`;

export const AwaitingMsg = styled.p`
  font-size: 11px;
  text-transform: uppercase;
`;

export const CheckedIcon = styled.div`
  position: absolute;
  right: -5px;
  bottom: -3px;
  width: 35px;
  height: 35px;
  padding: 3px;
  text-align: center;
  background: ${({ theme }) => theme.color.success};
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;

  html[dir='rtl'] & {
    right: auto;
    left: -5px;
  }
`;

export const InfoContainer = styled.div`
  flex-grow: 1;
  align-self: center;
`;

export const Icon = styled.div`
  padding: 15px;
  text-align: center;
  align-self: center;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.color.primaryText};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: block;
    white-space: normal;
    position: relative;
    line-height: 1.2em;
    max-height: 4.8em;
    padding-right: 1em;
    overflow: hidden;

    ::before {
      content: '...';
      position: absolute;
      right: 5px;
      bottom: 2px;
    }
  }
`;
