import styled from 'styled-components';

export const ButtonStyled = styled.button`
  padding: 0 10px;

  html[dir='rtl'] & {
    right: 0;
    left: auto;
  }
`;
