import styled, { css } from 'styled-components';

const focusRule = css`
  &:focus {
    outline: none;
  }
`;

const thumbStyles = css`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.success};
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  cursor: pointer;

  ${focusRule}
`;

const trackStyles = css`
  cursor: pointer;
  width: 100%;
`;

const borderRadius = css`
  border-radius: 5px;
`;

export const SliderContainer = styled.div`
  position: relative;
  background-color: #ddd;
  ${borderRadius}

  input[type='range'] {
    display: block;
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    outline: none;
    background-color: transparent;
    z-index: ${({ theme }) => theme.zIndex.one};

    ${borderRadius}

    &::-webkit-slider-thumb {
      -webkit-appearance: none;

      ${thumbStyles}
    }

    &::-moz-range-thumb {
      ${thumbStyles}
    }

    &::-ms-thumb {
      ${thumbStyles}
    }

    &::-webkit-slider-runnable-track {
      ${trackStyles}
      ${focusRule}
    }

    &::-moz-range-track {
      ${trackStyles}
      ${focusRule}
    }

    &::-ms-track {
      ${trackStyles}
      ${focusRule}
    }
  }
`;

export const FillIndicator = styled.span`
  background-color: ${({ theme }) => theme.color.success};
  display: inline-block;
  height: 4px;
  width: ${({ value }) => `${value}%`};
  position: absolute;
  top: 0;
  left: 0;

  html[dir='rtl'] & {
    left: auto;
    right: 0;
  }

  ${borderRadius}
`;
