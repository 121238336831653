/* eslint-disable global-require */
import * as Pages from 'components/Pages';
import OnBoarder from 'components/OnBoarder';
import ServiceSelection from 'components/ServiceSelection';
import ServiceCategorySelection from 'components/ServiceCategorySelection';
import MenuSelection from 'components/MenuSelection';
import Menu from 'components/Menu';
import Section from 'components/Section';
import ProductOptionInformation from 'components/Alert/alerts/ProductOptionInformation';

import { isOfflineVenueMode } from 'selectors/root';
import setBrowseData from './handlers/setBrowseData';
import redirectIfSingleMenu from './handlers/redirectIfSingleMenu';
import redirectInvalidBrowse from './handlers/redirectInvalidBrowse';

export default function getAppRoutes(store) {
  const handle = handler => handler(store.dispatch, store.getState);
  const state = store.getState();
  const isOfflineVenue = isOfflineVenueMode(state);

  const onBoarder = {
    component: OnBoarder,
    indexRoute: {},
  };

  const contactUs = {
    path: '/contact(/:serviceId)',
    component: Pages.ContactUs,
  };

  const language = {
    path: '/language',
    component: Pages.Language,
  };

  const feedback = {
    path: '/feedback(/:serviceId)',
    component: Pages.Feedback,
  };

  const passwordReset = {
    path: '/password_reset',
    component: Pages.ResetPassword,
  };

  const termsConditions = {
    path: '/terms_conditions',
    component: Pages.TermsAndConditions,
  };

  const userOrders = {
    path: '/user/orders',
    component: Pages.User.Orders,
  };

  const userSettings = {
    path: '/user/settings',
    component: Pages.User.Settings,
  };

  const userFavourites = {
    path: '/user/favourites',
    component: Pages.User.Favourites,
  };

  const userEditEmail = {
    path: '/user/edit_email',
    component: Pages.User.EditEmailAddress,
  };

  const userEditPassword = {
    path: '/user/edit_password',
    component: Pages.User.EditPassword,
  };

  const userEditPhoneNumber = {
    path: '/user/edit_phone_number',
    component: Pages.User.EditPhoneNumber,
  };

  const userEditUsername = {
    path: '/user/edit_username',
    component: Pages.User.EditUserName,
  };

  const userAddresses = {
    path: '/user/addresses',
    component: Pages.User.Addresses,
  };

  const userAddAddress = {
    path: '/user/add_address',
    component: Pages.User.AddAddress,
  };

  const userEditAddress = {
    path: '/user/edit_address/:addressId',
    component: Pages.User.EditAddress,
  };

  const userEditNotificationSettings = {
    path: '/user/edit_notification_settings',
    component: Pages.User.EditNotificationSettings,
  };

  const userPrivacy = {
    path: '/user/privacy',
    component: Pages.User.PrivacySettings,
  };

  const offlineRoutes = [
    { ...userFavourites },
    { ...userEditEmail },
    { ...userEditPassword },
    { ...userEditPhoneNumber },
    { ...userEditUsername },
    { ...userAddresses },
    { ...userAddAddress },
    { ...userEditAddress },
    { ...userEditNotificationSettings },
    { ...userPrivacy },
    { ...userSettings },
    { ...passwordReset },
    { ...userOrders },
    { ...feedback },
    { ...contactUs },
    { ...termsConditions },
    { ...language },
    {
      path: '/*',
      component: Pages.HomePage,
      childRoutes: [{ ...onBoarder }],
    },
  ];

  const routes = [
    {
      path: '/',
      component: Pages.HomePage,
      childRoutes: [
        {
          ...onBoarder,
          childRoutes: [
            {
              path: '/kiosk-mode',
            },
            {
              path: 'venues',
              component: Pages.OrganisationLanding,
            },
            {
              path: 'services',
              component: ServiceSelection,
            },
            {
              path: 'service-categories',
              component: ServiceCategorySelection,
            },
            {
              path: 'service-categories/:categoryId',
              component: ServiceSelection,
            },
          ],
        },
      ],
    },
    { ...contactUs },
    {
      path: '/rewards',
      component: Pages.RewardsPage,
    },
    {
      path: '/service/:serviceId/location',
      component: Pages.EnterLocation,
      onEnter: handle(setBrowseData),
    },
    {
      path: '/service/:serviceId/pre-auth',
      component: Pages.PreAuthRequired,
    },
    {
      path: '/service/:serviceId/pre-auth-waiting',
      component: Pages.PreAuthWaiting,
    },
    {
      path: '/service/:serviceId/order',
      component: Pages.TabOrder,
    },
    {
      path: '/service/:serviceId/order/no-order',
      component: Pages.NoOrderAtLocation,
    },
    {
      path: '/service/:serviceId',
      component: Pages.Browse,
      onEnter: handle(setBrowseData),
      childRoutes: [
        {
          indexRoute: {
            component: MenuSelection,
            onEnter: handle(redirectIfSingleMenu),
          },
          childRoutes: [
            {
              path: 'menu/:menuId',
              component: Menu,
            },
            {
              path: 'menu/:menuId/section/:sectionId',
              component: Section,
              onEnter: handle(redirectInvalidBrowse),
            },
            {
              path: 'menu/:menuId/section/:sectionId/product/:productId',
              component: ProductOptionInformation,
              onEnter: handle(redirectInvalidBrowse),
            },
          ],
        },
      ],
    },
    {
      path: '/review',
      component: Pages.OrderReview,
    },
    {
      path: '/checkout/tab/:sessionId',
      component: Pages.TabCheckout,
    },
    {
      path: '/checkout/:serviceId/:menuTypeId',
      component: Pages.Checkout,
    },
    { ...language },
    {
      path: '/processing',
      component: Pages.Processing,
    },
    {
      path: '/passcode',
      component: Pages.Passcode,
    },
    {
      path: '/thankyou',
      component: Pages.ThankYou,
    },
    {
      path: '/thankyou/tab',
      component: Pages.TabPaid,
    },
    { ...feedback },
    {
      path: '/call-a-waiter',
      component: Pages.CallAWaiter,
    },
    { ...passwordReset },
    { ...termsConditions },
    { ...userOrders },
    { ...userSettings },
    { ...userFavourites },
    { ...userEditEmail },
    { ...userAddresses },
    { ...userAddAddress },
    { ...userEditAddress },
    { ...userEditPassword },
    { ...userEditPhoneNumber },
    { ...userEditUsername },
    { ...userEditNotificationSettings },
    { ...userPrivacy },
  ];

  return isOfflineVenue ? offlineRoutes : routes;
}
