import React from 'react';
import styled from 'styled-components';

import OfferItem from 'components/Loyalty/Offer';

const Container = styled.div`
  margin-block-end: 1rem;
  width: 100%;
`;

const Carousel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  gap: 0.5rem;
  scroll-snap-type: x mandatory;

  > div {
    width: 96%;
    scroll-snap-align: start;
    flex: 0 0 auto;
  }
  > div:only-child {
    width: 100%;
  }
`;

const LoyaltyOffer: React.FC = ({ offers, offersLoading, onRedeem }) => {
  if (offersLoading || offers.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <Carousel>
        {offers.map(offer => (
          <OfferItem key={offer.id} inline={true} highlight={true} offer={offer} onRedeem={onRedeem} />
        ))}
      </Carousel>
    </Container>
  );
};

export default LoyaltyOffer;
