import { defineMessages } from 'react-intl';

import { ERROR_OUT_OF_STOCK, ERROR_INSUFFICIENT_STOCK } from 'errorHandling/errorKeys';

export default defineMessages({
  header: {
    defaultMessage: '{ quantity, plural, one { Item } other { Items } } Unavailable',
  },
  unavailableMessage: {
    defaultMessage:
      "We're sorry but { quantity } of your items are now unavailable to be ordered and have been removed from your basket.",
  },
  itemsRemoved: {
    defaultMessage: 'The following items have been removed:',
  },
  [ERROR_OUT_OF_STOCK]: {
    defaultMessage: '(out of stock)',
  },
  [ERROR_INSUFFICIENT_STOCK]: {
    defaultMessage: '(insufficient stock)',
  },
  lineItem: {
    defaultMessage: '{ item } { reason }',
  },
});
