import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from 'components/Icons/CloseIcon';

import { NotificationContainer, Icon, Header, CloseIconDiv, MessageContainer } from './styles';

const propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  message: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const Notification = ({ header, icon, message, children, onClose }) => (
  <NotificationContainer>
    <Icon>{icon}</Icon>
    <Header>{header}</Header>
    <CloseIconDiv onClick={onClose}>
      <CloseIcon fill="#fff" width="24px" height="24px" />
    </CloseIconDiv>
    <MessageContainer>
      {message}
      {children}
    </MessageContainer>
  </NotificationContainer>
);

Notification.propTypes = propTypes;

export default Notification;
