export const SET_BROWSE_INFO = 'app/Browse/SET_BROWSE_INFO';
export const SET_BACK_BUTTON = 'app/Browse/SET_BACK_BUTTON';
export const SET_MENU_TYPE_ID = 'app/Browse/SET_MENU_TYPE_ID';
export const SET_SERVICE_ID = 'app/Browse/SET_SERVICE_ID';
export const SET_MENU_ID = 'app/Browse/SET_MENU_ID';
export const REQUEST_PRODUCTS = 'app/Browse/REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'app/Browse/RECEIVE_PRODUCTS';
export const UPDATE_PRODUCTS = 'app/Browse/UPDATE_PRODUCTS';
export const REQUEST_MENU_LIST = 'app/Browse/REQUEST_MENU_LIST';
export const RECEIVE_MENU_TYPES = 'app/Browse/RECEIVE_MENU_TYPES';
export const RECEIVE_MENU_LIST = 'app/Browse/RECEIVE_MENU_LIST';
export const SET_IS_MENU_ACTIVE = 'app/Browse/SET_IS_MENU_ACTIVE';
