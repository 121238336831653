import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import MenuShape from 'components/MenuShape';
import { toggleSidebarOpen } from 'actions/sidebar';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import { kioskModeEnabled } from 'selectors/features';

import { MenuButtonStyled } from './styles';

const propTypes = {
  menuButton: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  className: PropTypes.string,
  customCssClass: PropTypes.string,
  toggleSidebarOpen: PropTypes.func,
  fillColorShape: PropTypes.bool,
  kioskMode: PropTypes.bool,
};

const MenuButton = ({
  sidebarOpen,
  className,
  menuButton,
  toggleSidebarOpen,
  fillColorShape,
  customCssClass,
  kioskMode,
}) => {
  if (kioskMode) return null;
  const onClick = () => (menuButton || toggleSidebarOpen)();

  return (
    <MenuButtonStyled
      data-cy="menuButton"
      className={`${className} ${sidebarOpen ? 'sidebarOpen' : ''}`}
      onClick={onClick}
    >
      <MenuShape fillColorShape={fillColorShape} reShape={sidebarOpen} customCssClass={customCssClass} />
    </MenuButtonStyled>
  );
};

MenuButton.propTypes = propTypes;

const ConnectedMenuButton = connect(
  state => ({
    sidebarOpen: selectSidebarIsOpen(state),
    kioskMode: kioskModeEnabled(state),
  }),
  { toggleSidebarOpen }
)(MenuButton);

export const StyledMenuButton = styled(ConnectedMenuButton)`
  position: absolute;

  &.sidebarOpen {
    background-color: ${({ theme }) => theme.color.brandPrimary};
  }
`;

export default ConnectedMenuButton;
