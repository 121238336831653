import React from 'react';
import PropTypes from 'prop-types';

import { Container, SingleDigit } from './styles';

const propTypes = {
  passcode: PropTypes.string,
};

const ShowPasscode = ({ passcode }) => {
  if (!passcode) return null;

  return (
    <Container>
      {[...passcode].map((digit, i) => (
        <SingleDigit key={i}>{digit}</SingleDigit>
      ))}
    </Container>
  );
};

ShowPasscode.propTypes = propTypes;

export default ShowPasscode;
