import React from 'react';
import { fromJS } from 'immutable';
import leftPad from 'left-pad';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import Dropdown, { generateDropdownOptions } from 'components/Dropdown';
import timeRangesToList from './timePointsToList';
import { getMinutesFromTime, getTimeFromMinutes } from './getMinutes';

import { TimeSelectorNoTimes } from './styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Time:',
  },
  noTimes: {
    defaultMessage: 'No times found',
  },
});

const propTypes = {
  allowASAP: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  timePoints: PropTypes.array.isRequired,
  interval: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  allowASAP: false,
};

class TimeSelector extends React.PureComponent {
  onChange = value => {
    if (value === 'ASAP') {
      this.props.onChange(value);
    } else {
      this.props.onChange(getTimeFromMinutes(value));
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getValue = value => {
    if (value === 'ASAP') return value;
    return getMinutesFromTime(value);
  };

  getTimeSlots = timeRangesList => {
    const timeOptions = [];
    if (this.props.allowASAP) timeOptions.push({ id: 'ASAP', name: 'ASAP' });
    timeOptions.push(
      ...timeRangesList.map(time => ({
        id: getMinutesFromTime(time),
        name: `${leftPad(time.hours, 2, '0')}:${leftPad(time.minutes, 2, '0')}`,
      }))
    );
    return generateDropdownOptions(fromJS(timeOptions));
  };

  render = () => {
    const { value, timePoints, interval } = this.props;
    const timeRangesList = timeRangesToList(timePoints, interval);

    if (timeRangesList.length === 0) {
      return (
        <TimeSelectorNoTimes>
          <FormattedMessage {...messages.title} />
          &nbsp;
          <FormattedMessage {...messages.noTimes} />
        </TimeSelectorNoTimes>
      );
    }

    return (
      <Dropdown
        onChange={this.onChange}
        value={this.getValue(value)}
        options={this.getTimeSlots(timeRangesList)}
        label={<FormattedMessage {...messages.title} />}
      />
    );
  };
}

TimeSelector.propTypes = propTypes;
TimeSelector.defaultProps = defaultProps;

export default TimeSelector;
