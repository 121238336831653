import { fromJS } from 'immutable';
import { SET_SESSION, CLEAR_SESSION, SET_TAB_ORDERS } from 'actions/session/constants';
import { mergeOrders } from 'utils';

export const initialState = fromJS([]);

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION: {
      const sessionIndex = state.findIndex(session => session.get('serviceId') === action.session?.serviceId);
      const index = sessionIndex === -1 ? state.size : sessionIndex;
      return state.setIn([index], fromJS(action.session));
    }

    case SET_TAB_ORDERS: {
      const sessionIndex = state.findIndex(session => session.getIn(['tab', 'id']) === action.tabId);
      if (sessionIndex === -1) return state;

      const orders = action?.orders || [];
      const { items, tax_totals: taxTotals } = mergeOrders(orders);
      const total = orders.reduce((total, order) => total + order.total, 0);
      return state
        .setIn([sessionIndex, 'tab', 'items'], fromJS(items))
        .setIn([sessionIndex, 'tab', 'tax_totals'], fromJS(taxTotals))
        .setIn([sessionIndex, 'tab', 'total'], total)
        .setIn([sessionIndex, 'tab', 'state'], action.state)
        .setIn([sessionIndex, 'tab', 'pos_sync_state'], action.pos_sync_state);
    }

    case CLEAR_SESSION: {
      return state.filter(session => session.get('serviceId') !== parseInt(action.serviceId, 10));
    }

    default:
      return state;
  }
};

export default sessionReducer;
