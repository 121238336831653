import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { withTheme } from 'styled-components';
import StandardAlert from 'components/Alert/StandardAlert';
import { AlertIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';

const messages = defineMessages({
  message: {
    defaultMessage: 'Sorry, there was an error deleting your {cardName}. Please try again later.',
  },
});

const propTypes = {
  name: PropTypes.string,
  theme: PropTypes.object,
};

const DeletePaymentSourceErrorAlert = props => {
  const { name, theme } = props;

  return (
    <StandardAlert
      {...props}
      Icon={AlertIcon}
      IconFill={theme.color.danger}
      config={{ buttons: 1 }}
      messages={{ ...messages, header: globalMessages.error, confirm: globalMessages.close }}
      mainMessage={<FormattedMessage {...messages.message} values={{ cardName: name }} />}
    />
  );
};

DeletePaymentSourceErrorAlert.propTypes = propTypes;

export default withTheme(DeletePaymentSourceErrorAlert);
