import styled from 'styled-components';

import AllergenIcon from 'components/AllergenIcon';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3px;
`;

export const StyledAllergenIcon = styled(AllergenIcon)`
  color: ${({ theme }) => theme.color.success};
  margin: 0 3px;
`;
