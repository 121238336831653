import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import QuantityButtons from 'components/QuantityButtons';
import { Container, Title, Description, QuantityButtonsContainer } from './styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Sets of Cutlery',
  },
  description: {
    defaultMessage: 'Help us reduce single-use waste by choosing cutlery only if you really need it.',
  },
});

type Props = {
  value: string | number;
  onChange: () => void;
};

const Cutlery = ({ value, onChange }: Props) => (
  <Container>
    <div>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      <Description>
        <FormattedMessage {...messages.description} />
      </Description>
    </div>

    <QuantityButtonsContainer>
      <QuantityButtons quantity={value || 0} onChange={onChange} allowZero={true} />
    </QuantityButtonsContainer>
  </Container>
);

export default Cutlery;
