import React, { useEffect } from 'react';
import { Map, List } from 'immutable';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';

import { usePrevious } from 'hooks';
import Sidebar from 'components/Sidebar';
import FooterMenu from 'components/FooterMenu';
import AlertManager from 'components/Alert/AlertManager';
import { NotificationManager } from 'components/Notification';
import OfflineBanner from 'components/OfflineBanner';
import { openPrivacySettingsNotification } from 'actions/UI';
import { getExternalLinks } from 'actions/root';
import withCookies from 'components/withCookies';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import { selectExternalLinks } from 'selectors/root';
import { isDarkModeEnabled, kioskModeEnabled } from 'selectors/features';
import { selectAlertStack } from 'selectors/ui';
import { getLocaleDir } from 'selectors/user';
import { getTrackingId, getFacebookPixelId, getCustomMetaTags, getGtmId } from 'reducers/analytics';
import { addCssPrefixTo, reactPixel, showFooterMenu } from 'utils';

import { getTheme } from 'assets/styles/theme';
import 'sanitize.css/sanitize.css';
// eslint-disable-next-line import/no-unresolved
import 'assets/styles/styles.scss';
import { GlobalStyles } from 'assets/styles/globalStyle';

import { StyledRoot, MainApp } from './styles';

const getUpdateAnalytics = locationPathname => {
  ReactGA.set({ page: locationPathname });
};

const propTypes = {
  getCookie: PropTypes.func,
  openPrivacy: PropTypes.func,
  externalLinks: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.bool]),
  getExternalLinks: PropTypes.func,
  facebookPixelId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  location: PropTypes.object,
  trackingId: PropTypes.string,
  customMetaTags: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
  gtmId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  kioskMode: PropTypes.bool,
  darkModeEnabled: PropTypes.bool,
  branding: PropTypes.instanceOf(Map),
  sidebarOpen: PropTypes.bool,
  alertStack: PropTypes.instanceOf(List),
  children: PropTypes.node,
  dir: PropTypes.string,
};

const Root = ({
  getCookie,
  openPrivacy,
  externalLinks,
  getExternalLinks,
  facebookPixelId,
  location,
  trackingId,
  customMetaTags,
  gtmId,
  kioskMode,
  darkModeEnabled,
  branding,
  sidebarOpen,
  alertStack,
  dir,
  children,
}) => {
  const [prevLocationPathname] = usePrevious(location.pathname);

  useEffect(() => {
    const alreadyAsked = () => !!getCookie('acceptCookies');
    const acceptCookies = () => getCookie('acceptCookies') === 'true';
    const allowAnalytics = () => isProduction() && trackingId && acceptCookies();
    const allowFacebookPixelId = () => isProduction() && acceptCookies() && facebookPixelId;
    const allowGoogleTagManager = () => isProduction() && acceptCookies() && gtmId;

    if (!externalLinks) {
      getExternalLinks();
    }

    if (prevLocationPathname !== location.pathname) {
      if (!alreadyAsked()) openPrivacy();

      if (allowGoogleTagManager()) {
        TagManager.initialize({ gtmId });
      }

      window.scrollTo(0, 0);

      if (allowAnalytics()) getUpdateAnalytics(location.pathname);

      if (allowFacebookPixelId()) {
        if (reactPixel.isInitTrue() === false) {
          reactPixel.init(facebookPixelId);
        }

        reactPixel.pageView();
      } else {
        reactPixel.resetInit();
      }
    }
  }, [
    getCookie,
    externalLinks,
    getExternalLinks,
    openPrivacy,
    facebookPixelId,
    gtmId,
    location.pathname,
    trackingId,
    prevLocationPathname,
  ]);

  const outputCustomMetaTags = metaTags =>
    metaTags && metaTags.map((value, key) => <meta key={key} name={key} content={value} />).entrySeq();

  const isProduction = () => process.env.NODE_ENV === 'production' || false;

  const allowCustomMetaTags = () => isProduction() && customMetaTags?.size;

  const pathparts = location.pathname.substring(1).split('/');
  const hasSplashBackground =
    pathparts[0] === '' || pathparts[0] === 'thankyou' || pathparts[0] === 'processing';
  const rootClasses = `clientBackground ${kioskMode ? addCssPrefixTo('KIOSK') : ''} ${
    hasSplashBackground ? 'splashBackground' : ''
  } ${pathparts[0] === 'thankyou' ? 'thankyouPage' : ''}`;

  const theme = getTheme(branding.toJS(), darkModeEnabled, dir);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />

      <StyledRoot isThankYouPage={pathparts[0] === 'thankyou'} className={rootClasses}>
        <Helmet
          titleTemplate="%s | Wi-Q"
          htmlAttributes={{
            class: sidebarOpen || alertStack?.size ? 'noScroll' : '',
            dir,
          }}
        >
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0" />
          {allowCustomMetaTags() && outputCustomMetaTags(customMetaTags)}
        </Helmet>

        <MainApp hidden={sidebarOpen}>
          {children}
          {showFooterMenu(location.pathname) && (
            <FooterMenu locationParams={children.props.params} isHomepage={pathparts[0] === ''} />
          )}
          <OfflineBanner />
        </MainApp>
        <NotificationManager />
      </StyledRoot>
      <Sidebar
        serviceId={children.props?.params?.serviceId}
        menuId={children.props?.params?.menuId}
        sidebarOpen={sidebarOpen}
      />

      <AlertManager />
    </ThemeProvider>
  );
};

Root.propTypes = propTypes;

export default connect(
  state => ({
    facebookPixelId: getFacebookPixelId(state),
    trackingId: getTrackingId(state),
    customMetaTags: getCustomMetaTags(state),
    gtmId: getGtmId(state),
    kioskMode: kioskModeEnabled(state),
    darkModeEnabled: isDarkModeEnabled(state),
    branding: state.get('branding'),
    sidebarOpen: selectSidebarIsOpen(state),
    alertStack: selectAlertStack(state),
    externalLinks: selectExternalLinks(state),
    dir: getLocaleDir(state),
  }),
  dispatch => ({
    openPrivacy: () => dispatch(openPrivacySettingsNotification()),
    getExternalLinks: () => dispatch(getExternalLinks()),
  })
)(withCookies(Root));
