import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { selectAcceptedCardTypes, orderQuantity } from 'selectors/order';
import { getIcon } from 'utils';

import { Container, CardIconsList, CardIcon, CompanyName, InfoContainer } from './styles';

const messages = defineMessages({
  tel: {
    defaultMessage: 'Tel: { telephoneNumber }',
  },
  email: {
    defaultMessage: 'Email: { emailAddress }',
  },
  regNo: {
    defaultMessage: 'Reg No. { registrationNumber } ',
  },
  registeredIn: {
    defaultMessage: 'Registered in { registrationCountry }',
  },
});

class PaymentInformation extends React.Component {
  static propTypes = {
    acceptedCardTypes: PropTypes.instanceOf(List),
    telephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    companyName: PropTypes.string,
    companyAddress: PropTypes.string,
    registrationNumber: PropTypes.string,
    registrationCountry: PropTypes.string,
    orderQuantity: PropTypes.number,
  };

  // eslint-disable-next-line class-methods-use-this
  getCardIcon = card => <CardIcon key={card}>{getIcon(card)}</CardIcon>;

  render() {
    const {
      acceptedCardTypes,
      orderQuantity,
      companyName,
      telephoneNumber,
      emailAddress,
      companyAddress,
      registrationNumber,
      registrationCountry,
    } = this.props;

    if (!acceptedCardTypes.size || !orderQuantity) {
      return null;
    }

    return (
      <Container>
        <CardIconsList>{acceptedCardTypes.map(card => this.getCardIcon(card))}</CardIconsList>

        {companyName && <CompanyName>{companyName}</CompanyName>}

        <InfoContainer>
          {telephoneNumber && (
            <span>
              <FormattedMessage {...messages.tel} values={{ telephoneNumber }} />
            </span>
          )}

          {emailAddress && (
            <span>
              <FormattedMessage {...messages.email} values={{ emailAddress }} />
            </span>
          )}
        </InfoContainer>

        {companyAddress && <div>{companyAddress}</div>}

        <InfoContainer>
          {registrationNumber && (
            <span>
              <FormattedMessage {...messages.regNo} values={{ registrationNumber }} />
            </span>
          )}

          {registrationCountry && (
            <span>
              <FormattedMessage {...messages.registeredIn} values={{ registrationCountry }} />
            </span>
          )}
        </InfoContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  telephoneNumber: state.getIn(['venue', 'organisation', 'company_phone']),
  emailAddress: state.getIn(['venue', 'organisation', 'company_phone']),
  companyName: state.getIn(['venue', 'organisation', 'company_legal_title']),
  companyAddress: state.getIn(['venue', 'organisation', 'company_full_address']),
  registrationNumber: state.getIn(['venue', 'organisation', 'company_no']),
  registrationCountry: state.getIn(['venue', 'organisation', 'company_reg_country']),
  acceptedCardTypes: selectAcceptedCardTypes(state),
  orderQuantity: orderQuantity(state),
});

export default connect(mapStateToProps)(PaymentInformation);
