import React from 'react';
import PropTypes from 'prop-types';

const GrubhubIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" className="icon GrubhubIcon" width="180" height="180" />
    <path
      fill="#f63440"
      fillRule="evenodd"
      className="icon GrubhubIcon"
      d="M206.387,61.328a1.243,1.243,0,0,1,1.714-1.171c3.813,1.255,7.639,2.486,11.452,3.741.678.191,1.342.729,1.289,1.422q0,13.7,0,27.39c3.893,1.315,7.825,2.534,11.717,3.836q0-13.3,0-26.626a1.229,1.229,0,0,1,.717-1.219,2.3,2.3,0,0,1,1.329.108c3.68,1.207,7.36,2.39,11.04,3.609.7.2,1.421.729,1.382,1.458q.02,34.256,0,68.5a1.118,1.118,0,0,1-.624,1.183,2.3,2.3,0,0,1-1.594-.084c-3.653-1.207-7.333-2.4-11-3.609a1.749,1.749,0,0,1-1.249-1.267c-.04-9.525.04-19.061-.04-28.586-3.892-1.243-7.785-2.522-11.677-3.788v27.761a1.032,1.032,0,0,1-.7,1.1,2.931,2.931,0,0,1-1.289-.06c-3.746-1.243-7.532-2.438-11.279-3.681a1.626,1.626,0,0,1-1.182-1.637Q206.387,95.488,206.387,61.328ZM175.54,32.4a21.616,21.616,0,0,1,7.639-.609,17.356,17.356,0,0,1,9.924,4.159,14.407,14.407,0,0,1,4.876,11.723c-.013,1.315.027,2.629-.013,3.956-.053.574-.717.86-1.262,1.016-3.667,1.171-7.307,2.4-10.986,3.561a1.708,1.708,0,0,1-1.488-.287,8.831,8.831,0,0,1-.173-2.641c-.013-1.9.133-3.9-.784-5.665a4.509,4.509,0,0,0-3.1-2.45,8.537,8.537,0,0,0-5.978,1.685,10.09,10.09,0,0,0-3.866,6.023,21.309,21.309,0,0,0-.545,5.019V80.114c.027,3.167-.2,6.394.678,9.5a5.5,5.5,0,0,0,2.6,3.549,5.887,5.887,0,0,0,4.65-.2,9.283,9.283,0,0,0,4.9-4.971,16.546,16.546,0,0,0,1.382-5.9c.027-1.912,0-3.812.013-5.724-1.793.574-3.587,1.159-5.38,1.745a1.378,1.378,0,0,1-1.581-.418,3.456,3.456,0,0,1-.146-1.219c.013-2.964-.013-5.927.013-8.891a1.483,1.483,0,0,1,1.143-1.494q9.127-2.994,18.28-5.963c.757-.251,1.7.275,1.621,1.028.013,5.378,0,10.755,0,16.145a76.292,76.292,0,0,1-.2,7.983,24.4,24.4,0,0,1-7.533,15.512,25.734,25.734,0,0,1-13.869,6.477l-.119.084a33.819,33.819,0,0,1-4.4.167,17.64,17.64,0,0,1-10.867-4.446,17.3,17.3,0,0,1-5.58-12.739v-33.6a25.435,25.435,0,0,1,7.227-17.173A26.972,26.972,0,0,1,175.54,32.4Z"
      transform="translate(-111.128 2.324)"
    />
  </svg>
);

GrubhubIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

GrubhubIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default GrubhubIcon;
