import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headerTitle: {
    defaultMessage: 'Join {loyaltyName}',
  },
  splashBody: {
    defaultMessage: 'The loyalty programme rewarding you at 70+ brands',
  },
  signInBody1: {
    defaultMessage: 'Already an Aura member?',
  },
  signInBody2: {
    defaultMessage: 'Enter your details to earn points on this order.',
  },
  joinAura: {
    defaultMessage: 'Join Aura',
  },
  signInAura: {
    defaultMessage: 'Sign in',
  },
  signOut: {
    defaultMessage: 'Sign out',
  },
  earnWithAura: {
    defaultMessage: 'Earn with Aura',
  },
  congratulations: {
    defaultMessage: 'Congratulations',
  },
  buildYourAura: {
    defaultMessage: 'You can now start earning points every time you shop online or in-store.',
  },
  userCardBody: {
    defaultMessage: 'You are signed into Aura.',
  },
  userPointsAvailable: {
    defaultMessage: '({ points }) points available',
  },
  signOutConfirmHeader: {
    defaultMessage: 'Sign Out?',
  },
  signOutConfirmBody: {
    defaultMessage: 'Are you sure you want to sign out?',
  },
});
