import styled from 'styled-components';

export const DayPickerButton = styled.button`
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 5px;
  color: ${({ theme }) => theme.color.text};
  display: block;
  font-size: ${({ theme }) => theme.fonts.inputTextFontSize};
  text-align: left;
  height: 42px;
  padding: 6px;
  position: relative;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.one};

  &::after {
    border-color: ${({ theme }) => theme.color.inputBorder} transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    right: 7px;
    top: 19px;
    width: 0;
  }
  html[dir='rtl'] &::after {
    right: auto;
    left: 7px;
  }

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ModalLayer = styled.div`
  background-color: ${({ theme }) => theme.color.modalBackdrop};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 250ms;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.twelve};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalInner = styled.div`
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.six};
  text-align: center;
  color: #666;
  padding: 15px;
`;

export const Close = styled.div`
  cursor: pointer;
`;

export const CloseIconWrapper = styled.span`
  padding: 0 5px;
  display: inline-block;
`;

export const CloseLabel = styled.span`
  text-transform: uppercase;
  display: inline-block;
  margin-top: 3px;
`;

export const CalendarWrapper = styled.div`
  max-height: 100vh;
  overflow-y: auto;

  & > div {
    display: block;
  }

  .rdp {
    margin: 0;
  }

  .rdp-button:focus,
  .rdp-button:active {
    border-color: ${({ theme }) => theme.color.brandPrimary};
  }

  .rdp-day_selected,
  .rdp-day_selected:hover {
    background-color: ${({ theme }) => theme.color.brandPrimary};
    color: white;
  }

  .rdp-day_today {
    color: ${({ theme }) => theme.color.brandPrimary};
  }
`;
