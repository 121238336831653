import React from 'react';
import PropTypes from 'prop-types';

// Icon
const AlertIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <path
      className="icon alertIcon"
      fill={fill}
      d="M231.3,82.1c-4-12-9.6-22.9-16.8-32.5c-7.2-9.7-15.6-18.1-25.3-25.3c-9.7-7.2-20.5-12.8-32.5-16.8c-12-4-24.4-6-37.1-6	c-12.7,0-25.1,2-37.1,6c-12,4-22.9,9.6-32.5,16.8c-9.7,7.2-18.1,15.6-25.3,25.3C17.5,59.2,12,70.1,8,82.1c-4,12-6,24.4-6,37.1	c0,15.9,3.1,31.2,9.3,45.7c6.2,14.6,14.6,27.1,25,37.6c10.5,10.5,23,18.8,37.6,25c14.6,6.2,29.8,9.3,45.7,9.3	c15.9,0,31.2-3.1,45.7-9.3c14.6-6.2,27.1-14.6,37.6-25c10.5-10.5,18.8-23,25-37.6c6.2-14.6,9.3-29.8,9.3-45.7	C237.3,106.5,235.3,94.1,231.3,82.1z M214.4,159.2c-5.4,12.7-12.8,23.7-21.9,33c-9.2,9.3-20.1,16.6-32.9,22.1	c-12.7,5.4-26,8.2-40,8.2c-11.2,0-22-1.7-32.5-5.2c-10.5-3.4-20-8.4-28.4-14.8c-8.4-6.4-15.8-13.9-22.2-22.3 c-6.4-8.4-11.3-17.9-14.7-28.4c-3.4-10.5-5.2-21.3-5.2-32.5c0-13.9,2.7-27.3,8.2-40c5.4-12.7,12.8-23.7,21.9-32.9	s20.1-16.5,32.9-21.9c12.7-5.4,26-8.2,40-8.2c13.9,0,27.3,2.7,40,8.2c12.7,5.4,23.7,12.8,32.9,21.9c9.2,9.2,16.5,20.1,21.9,32.9 c5.4,12.7,8.2,26,8.2,40C222.6,133.1,219.9,146.5,214.4,159.2z"
    />
    <rect className="icon alertIcon" fill={fill} x="110.7" y="164" width="18.1" height="20.6" />
    <polygon className="icon alertIcon" fill={fill} points="110.9,152.6 107.9,55.4 132.1,55.4 129.1,152.6" />
  </svg>
);

AlertIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

AlertIcon.defaultProps = {
  width: '24px',
  height: '24px',
  fill: 'currentColor',
};

export default AlertIcon;
