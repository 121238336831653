import { fromJS } from 'immutable';
import { SET_LOYALTY_USER, SET_LOYALTY_SPEND } from 'actions/loyalty/constants';

export const initialState = fromJS({
  loyaltyTypeByService: undefined,
  user: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOYALTY_USER: {
      return state.set('user', fromJS(action.user));
    }
    case SET_LOYALTY_SPEND: {
      return state.set('loyaltySpend', action.loyaltySpend);
    }
    default:
      return state;
  }
};
