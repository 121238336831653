import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import Page from 'components/Pages/container';
import { SettingsThemedIcon } from 'components/Icons';
import { isLoggedIn } from 'selectors/user';
import globalMessages from 'components/globalMessages';
import settingsMessages from 'components/settingsMessages';

const SettingsPage = ({ children, titleMessage, isLoggedIn, loginRequired, location }) => {
  const { pathname } = location;
  const href = pathname.includes('_address') ? '/user/addresses' : '/user/settings';

  return (
    <Page
      settingsPage={true}
      titleMessage={titleMessage || settingsMessages.settingsPage}
      redirectCondition={loginRequired ? !isLoggedIn : undefined}
      Icon={SettingsThemedIcon}
      backButton={
        isLoggedIn
          ? new Map({
              href,
              text: <FormattedMessage {...globalMessages.back} />,
            })
          : undefined
      }
    >
      {children}
    </Page>
  );
};

SettingsPage.defaultProps = {
  loginRequired: true,
};

SettingsPage.propTypes = {
  children: PropTypes.node,
  titleMessage: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  loginRequired: PropTypes.bool,
  location: PropTypes.object,
};

const mapStateToProps = (state, { location }) => ({
  isLoggedIn: isLoggedIn(state),
  location,
});

export default connect(mapStateToProps)(withRouter(SettingsPage));
