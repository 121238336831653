import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { openClearOrderAlert } from 'actions/UI';
import { ExitIcon } from 'components/Icons';

import globalMessages from 'components/globalMessages';
import { ExitButtonContainer } from './styles';

const KioskExitButton = ({ openClearOrderAlert, withBackground }) => (
  <ExitButtonContainer withBackground={withBackground} className="primaryColor">
    <ActionButton
      buttonIcon={<ExitIcon />}
      label={<FormattedMessage {...globalMessages.exit} />}
      onClick={() => openClearOrderAlert()}
    />
  </ExitButtonContainer>
);

KioskExitButton.propTypes = {
  openClearOrderAlert: PropTypes.func.isRequired,
  withBackground: PropTypes.bool,
};

export default connect(undefined, { openClearOrderAlert })(KioskExitButton);
