const gtmDataLayerPush = (eventName, options = {}) => {
  if (!eventName) return null;

  if (window && window?.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        ...options,
      },
    });
  }
};

export default gtmDataLayerPush;
