import { defineMessages } from 'react-intl';

const messages = defineMessages({
  login_error: {
    defaultMessage: 'Login Error!',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  badPassword: {
    defaultMessage: 'Incorrect email or password',
  },
  login: {
    defaultMessage: 'Login',
  },
  forgotPassword: {
    defaultMessage: 'Forgot password?',
  },
  createAccount: {
    defaultMessage: 'Create An Account',
  },
});

export default messages;
