import React, { ReactNode } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getVenueCountryCode } from 'selectors/root';

const StyledMessage = styled.div`
  color: ${({ theme }) => theme.color.danger};
  font-size: 12px;
  font-style: italic;
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.inputBorder};
    border-right-color: #cbcbcb;
    html[dir='rtl'] & {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left-color: #cbcbcb;
      border-right-color: ${({ theme }) => theme.color.inputBorder};
      &.invalid-number {
        border-left-color: #cbcbcb;
        border-right-color: ${({ theme }) => theme.color.danger};
      }
    }
    &.invalid-number {
      border-color: ${({ theme }) => theme.color.danger};
      border-right-color: #cbcbcb;
    }
  }

  input[type='tel'] {
    border-radius: 5px;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.color.inputBorder};
    &.invalid-number {
      border: ${({ theme }) => `1px solid ${theme.color.danger}`};
    }
  }

  input[type='tel']:focus {
    border: 1px solid ${({ theme }) => theme.color.inputBorder};
    &.invalid-number {
      border: ${({ theme }) => `1px solid ${theme.color.danger}`};
    }
  }

  .country-list {
    text-align: start;
    width: 15rem;
    max-height: 140px;
  }

  .form-control {
    html[dir='rtl'] & {
      padding-right: 48px;
    }
  }
  .selected-flag {
    html[dir='rtl'] & {
      padding: 0 8px 0 0;
    }
    z-index: 1;
  }
  .selected-flag .arrow {
    html[dir='rtl'] & {
      right: 20px;
    }
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  showOnlyCountries?: string[];
  name: string;
  required?: boolean;
  placeholder?: string;
  rgxPattern?: RegExp | null;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: ReactNode;
  inputProps?: object;
}

const PhoneNumberInput: React.FC<Props> = ({
  value,
  onChange,
  showOnlyCountries = [],
  name,
  required = true,
  placeholder = 'Mobile number',
  rgxPattern = null,
  isValid,
  setIsValid,
  errorMessage,
  inputProps = {},
}) => {
  const venueCountryCode = useSelector(getVenueCountryCode);

  // Check that input value matches the regex
  const validatePhoneNumber = (phoneNumber: string): boolean =>
    rgxPattern ? rgxPattern.test(phoneNumber) : true;

  const handlePhoneInputChange = (value: string): void => {
    onChange(value);
    setIsValid(validatePhoneNumber(value));
  };

  const handleInputBlur = (): void => {
    // Perform validation when the input field loses focus
    setIsValid(validatePhoneNumber(value));
  };
  return (
    <div>
      <StyledPhoneInput
        value={value}
        autoFormat={true}
        onChange={handlePhoneInputChange}
        onlyCountries={showOnlyCountries}
        placeholder={placeholder}
        onBlur={handleInputBlur}
        // initial country in dropdown in ui
        country={venueCountryCode}
        inputProps={{
          ...inputProps,
          name,
          required,
          autoFocus: true,
        }}
        isValid={!!isValid}
      />
      {!isValid && (
        <StyledMessage>
          {errorMessage || (
            <>
              <FormattedMessage defaultMessage="Mobile number is not recognised." />
              <br />
              <FormattedMessage defaultMessage="Please enter a valid mobile number." />
            </>
          )}
        </StyledMessage>
      )}
    </div>
  );
};

export default PhoneNumberInput;
