import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ChevronDown = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M5.201,71.125c0-1.916,0.754-3.559,2.267-4.929c1.509-1.367,3.322-2.054,5.44-2.054c2.114,0,3.927,0.687,5.439,2.054	l102.447,92.824l100.861-91.386c1.508-1.367,3.32-2.054,5.439-2.054c2.113,0,3.926,0.687,5.439,2.054	c1.508,1.37,2.266,3.013,2.266,4.929c0,1.918-0.758,3.562-2.266,4.929l-106.301,96.314c-1.512,1.369-3.324,2.053-5.439,2.053 c-2.118,0-3.931-0.684-5.439-2.053L7.467,76.258c-0.758-0.684-1.324-1.47-1.7-2.362C5.389,73.008,5.201,72.083,5.201,71.125z"
      />
    </g>
  </svg>
);

ChevronDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ChevronDown.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ChevronDown;
