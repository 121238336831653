import styled from 'styled-components';
import TextArea from 'components/TextArea/index';

export const NotesContainer = styled.div`
  height: auto;
`;

export const NotesTextArea = styled(TextArea)`
  border: none;
  background-color: transparent;
  width: 100%;
  min-height: 65px;
  padding: 3px 0;
`;
