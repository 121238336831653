/* eslint-disable camelcase */
const { ORDER_STATE_CONFIRMED } = require('appConstants');

const getOrderRedirectUrl = ({ order, tabId }) => {
  const baseRedirectUrl = '/thankyou';

  if (!order || !order.state) {
    if (tabId) {
      return `${baseRedirectUrl}/tab`;
    }

    return null;
  }

  if (order.state === ORDER_STATE_CONFIRMED) {
    const { number: order_number, fulfilment = {} } = order;
    const { location_type, location = {} } = fulfilment;
    const {
      table_number,
      room_number,
      collection_point,
      seat,
      sunbed_number,
      registration,
      address1,
      address2,
      town_city,
      county,
      country,
      postcode,
    } = location;

    const queryParams = new URLSearchParams({
      ...(order_number ? { order_number } : {}),
      ...(location_type ? { location_type } : {}),
      ...(table_number ? { table_number } : {}),
      ...(room_number ? { room_number } : {}),
      ...(collection_point ? { collection_point } : {}),
      ...(seat ? { seat } : {}),
      ...(sunbed_number ? { sunbed_number } : {}),
      ...(registration ? { registration } : {}),
      ...(address1 ? { address1 } : {}),
      ...(address2 ? { address2 } : {}),
      ...(town_city ? { town_city } : {}),
      ...(county ? { county } : {}),
      ...(country ? { country } : {}),
      ...(postcode ? { postcode } : {}),
    });

    const baseRedirectUrl = '/thankyou';
    const redirectUrl = tabId ? `${baseRedirectUrl}/tab` : baseRedirectUrl;

    return `${redirectUrl}?${queryParams.toString()}`;
  }

  return '/processing';
};

module.exports = getOrderRedirectUrl;
