import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { HelpMessage } from 'assets/styles/sharedStyles';
import {
  hasPasswordLengthRequirement,
  hasPasswordUppercaseRequirement,
  hasPasswordLowercaseRequirement,
  hasPasswordNumberRequirement,
} from 'components/Form/utils';
import messages from './messages';
import { Input } from './styles';
import PasswordRequirement from './PasswordRequirement';

const NewPassword = ({ value, required, autoComplete, onChange, onBlur }) => (
  <div>
    <Input
      name="newPassword"
      type="password"
      value={value}
      required={required}
      autoComplete={autoComplete}
      onChange={e => onChange(e.target.value)}
      onBlur={e => onBlur(e.target.value)}
    />
    <HelpMessage>
      <FormattedMessage
        {...messages.requirements}
        values={{
          charactersRequirement: (
            <PasswordRequirement
              success={hasPasswordLengthRequirement(value)}
              messageKey="charactersRequirement"
            />
          ),
          uppercaseRequirement: (
            <PasswordRequirement
              success={hasPasswordUppercaseRequirement(value)}
              messageKey="uppercaseRequirement"
            />
          ),
          lowercaseRequirement: (
            <PasswordRequirement
              success={hasPasswordLowercaseRequirement(value)}
              messageKey="lowercaseRequirement"
            />
          ),
          numberRequirement: (
            <PasswordRequirement
              success={hasPasswordNumberRequirement(value)}
              messageKey="numberRequirement"
            />
          ),
        }}
      />
    </HelpMessage>
  </div>
);

NewPassword.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NewPassword;
