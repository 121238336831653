import localstore from 'store/dist/store.modern';
import expirePlugin from 'store/plugins/expire';

localstore.addPlugin(expirePlugin);

const serialize = JSON.stringify;

const deserialize = JSON.parse;

export const setItem = (key, value, expiry) => {
  if (expiry && expiry.length !== 0) {
    localstore.set(key, serialize(value), makeExpiryTime(expiry));
  } else if (!expiry) {
    localstore.set(key, serialize(value));
  }
};

export const getItem = key => {
  if (localstore.get(key) && localstore.get(key) !== 'undefined') {
    return deserialize(localstore.get(key));
  }
  return null;
};

export const findGlobalItems = prefix => {
  const matchingKeys = {};
  const regex = new RegExp(`^${prefix}`, 'i');
  if (localstore && localstore.each) {
    localstore.each((key, value) => {
      if (value && value.match(regex)) {
        if (getItem(value) !== null) {
          const prefixRemovedString = value && value.substring(prefix.length, value.length);
          matchingKeys[prefixRemovedString] = getItem(value);
        }
      }
    });
  }
  return matchingKeys;
};

export const makeExpiryTime = expiry => {
  if (expiry && expiry.format === 'days') {
    const today = new Date();
    return today.setDate(today.getDate() + expiry.length);
  }
  if (expiry && expiry.format === 'hours') {
    const today = new Date();
    return today.setHours(today.getHours() + expiry.length);
  }
  if (expiry && expiry.format === 'minutes') {
    const today = new Date();
    return today.setMinutes(today.getMinutes() + expiry.length);
  }
  return null;
};

export const clearAll = () => {
  localstore.clearAll();
};

export const removeItem = key => {
  localstore.remove(key);
};

export const removeExpiredKeys = () => localstore.removeExpiredKeys();

export const returnStore = () => localstore;
