import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { Container, StyledActionButton, SignInBodyContainer, StyledError, ModalFooter } from './styles';
import SignInMethods from './SignInMethods';

const ComoSignInPage = ({ handleSignIn, error, loading }) => {
  const [signInMethod, setSignInMethod] = useState('mobile');

  return (
    <Container>
      <SignInBodyContainer>
        <FormattedMessage defaultMessage="We just need to send you a one-time-pin to verify access. How would you like to be sent this?" />
      </SignInBodyContainer>

      <form onSubmit={handleSignIn}>
        {error && (
          <StyledError>
            <FormattedMessage defaultMessage="Unable to send your one-time-pin. Please try again." />
          </StyledError>
        )}

        <SignInMethods onChange={setSignInMethod} method={signInMethod} />

        <ModalFooter>
          <StyledActionButton
            height="3.5rem"
            disabled={loading}
            type="submit"
            label={
              loading ? (
                <LoadingSpinner noPad={true} height="1.5rem" width="1.5rem" />
              ) : (
                <FormattedMessage defaultMessage="Verify" />
              )
            }
          />
        </ModalFooter>
      </form>
    </Container>
  );
};

ComoSignInPage.propTypes = {
  handleSignIn: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

export default ComoSignInPage;
