import React from 'react';
import PropTypes from 'prop-types';

const MrsoolIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient id="a" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#f3e500" />
        <stop offset="1" stopColor="#1dcad3" />
      </linearGradient>
    </defs>

    <g transform="translate(-0.267)">
      <rect fill="none" className="icon MrsoolIcon" width="180" height="180" transform="translate(0.267)" />
      <path
        fill="url(#a)"
        className="icon MrsoolIcon"
        d="M179.251,374.443H178.9l-4.074-13.931a12.034,12.034,0,1,0-17.584-10.677,11.968,11.968,0,0,0,5.081,9.811l-17.631,39.266a8.259,8.259,0,0,0-1.522,0l-17.631-39.266a12.036,12.036,0,1,0-19.012-9.811,12.138,12.138,0,0,0,6.415,10.653l-4.191,13.955h-.164a8.673,8.673,0,1,0,4.636,1.335l4.191-13.955a11.264,11.264,0,0,0,3.863-.258l17.631,39.266a7.422,7.422,0,1,0,10.021,0l17.631-39.266a12.062,12.062,0,0,0,3.793.258l4.121,14.048a8.688,8.688,0,1,0,13.463,7.258A8.669,8.669,0,0,0,179.251,374.443Zm-70.687,12.714a4.027,4.027,0,1,1,4.027-4.027A4.027,4.027,0,0,1,108.563,387.157Zm17.373-37.322a7.375,7.375,0,1,1-7.375-7.375A7.375,7.375,0,0,1,125.937,349.835Zm20.745,56.5a2.763,2.763,0,1,1-2.763-2.763A2.738,2.738,0,0,1,146.682,406.333Zm29.97-56.5a7.375,7.375,0,1,1-7.375-7.375A7.375,7.375,0,0,1,176.652,349.835Zm6.626,33.295a4.027,4.027,0,1,1-4.027-4.027A4.027,4.027,0,0,1,183.278,383.13Z"
        transform="translate(-53.652 -303.8)"
      />

      <g transform="translate(61.718 117.095)">
        <g transform="translate(0.03)">
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M797.9,401.791h-1.35a1.7,1.7,0,0,0-1.7,1.7v5.235h-5.144V402.55a.85.85,0,1,0-1.7,0v8.558a1.632,1.632,0,0,1-1.639,1.639h-.819a.85.85,0,0,0,0,1.7h.819a3.354,3.354,0,0,0,3.353-3.353v-.668h8.194a4.317,4.317,0,1,0-.015-8.634Zm0,6.934h-1.335v-5.22H797.9a2.61,2.61,0,0,1,0,5.22Z"
            transform="translate(-745.174 -398.362)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M626.564,406.343V402.55a.85.85,0,0,0-1.7,0v3.793a2.377,2.377,0,0,1-2.382,2.382h-.046a.7.7,0,0,1-.7-.7V402.55a.85.85,0,1,0-1.7,0v3.793a2.377,2.377,0,0,1-2.382,2.382h-.743V402.55a.85.85,0,1,0-1.7,0v6.176H603.774V403.49a1.7,1.7,0,0,0-1.7-1.7h-1.35a4.324,4.324,0,0,0,0,8.649h1.335v.668a1.632,1.632,0,0,1-1.639,1.639H599.6a.85.85,0,1,0,0,1.7h.819a3.354,3.354,0,0,0,3.353-3.353v-.668H617.9c.061,0,.121-.015.182-.015a4.069,4.069,0,0,0,2.367-1.077,2.447,2.447,0,0,0,2.018,1.092h.03A4.07,4.07,0,0,0,626.564,406.343Zm-24.55,1.047v1.35h-1.335a2.61,2.61,0,0,1,0-5.22h1.335Z"
            transform="translate(-585.445 -398.362)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M532.56,379.7a.848.848,0,0,0-.85.85v10.363a2.9,2.9,0,1,1-2.9-2.9.85.85,0,0,0,0-1.7,4.613,4.613,0,1,0,4.613,4.613V380.565A.885.885,0,0,0,532.56,379.7Z"
            transform="translate(-524.2 -379.7)"
          />
        </g>

        <path
          fill="#7b868c"
          className="icon MrsoolIcon"
          d="M690.3,384.43a1.374,1.374,0,1,0-2.322,1.472,1.44,1.44,0,0,0,.577.516l-1.411.9a.32.32,0,0,0-.106.44.339.339,0,0,0,.273.152.306.306,0,0,0,.167-.046L689.8,386.4c.015,0,.015-.015.03-.015.015-.015.03-.015.045-.03a1.371,1.371,0,0,0,.607-.865A1.437,1.437,0,0,0,690.3,384.43Zm-1.882.561a.77.77,0,0,1,.728-.592.622.622,0,0,1,.167.015.76.76,0,0,1,.47.334.726.726,0,0,1,.091.561.759.759,0,0,1-.334.47.748.748,0,0,1-1.017-.228A.674.674,0,0,1,688.42,384.992Z"
          transform="translate(-662.261 -383.171)"
        />

        <g transform="translate(0 17.829)">
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M524.865,509.476h0a.864.864,0,0,1-.865-.865v-8.558a1.153,1.153,0,0,1,1.153-1.153h0a1.136,1.136,0,0,1,1.062.713l2.61,6.176,2.64-6.16a1.18,1.18,0,0,1,1.077-.713h0a1.169,1.169,0,0,1,1.168,1.168v8.543a.864.864,0,0,1-.865.865h0a.864.864,0,0,1-.865-.865v-3.991l.182-2.868-2.367,5.644a1.026,1.026,0,0,1-.941.622h0a1.01,1.01,0,0,1-.941-.622l-2.367-5.675.167,2.928v3.991A.842.842,0,0,1,524.865,509.476Z"
            transform="translate(-524 -498.642)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M667.675,502.161c1.851.349,3.915,1.017,3.763,3.505-.091,2.018-2.018,2.959-3.945,2.959a4.287,4.287,0,0,1-3.414-1.639.87.87,0,0,1,.334-1.32h0a.842.842,0,0,1,1.032.243,2.607,2.607,0,0,0,2.064,1.017c1.077,0,2.109-.47,2.17-1.4.091-1.2-1.092-1.5-2.4-1.7-1.775-.273-3.566-1.092-3.581-3.2.121-1.927,1.942-2.928,3.793-2.928a4.033,4.033,0,0,1,3.247,1.366.852.852,0,0,1-.334,1.32h0a.845.845,0,0,1-1-.243,2.339,2.339,0,0,0-1.927-.835c-1.032,0-2,.486-2.064,1.381C665.369,501.6,666.553,501.948,667.675,502.161Z"
            transform="translate(-642.503 -497.624)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M727.279,497.2c2.139,0,4.294,1.138,4.294,3.429v4.2c0,2.291-2.155,3.444-4.294,3.444-2.155,0-4.279-1.153-4.279-3.444v-4.2C723.015,498.338,725.124,497.2,727.279,497.2Zm-2.549,7.632c0,1.138,1.275,1.715,2.549,1.715,1.3,0,2.549-.577,2.564-1.715v-4.2c0-1.214-1.29-1.715-2.564-1.715-1.29.015-2.549.531-2.549,1.715Z"
            transform="translate(-692.805 -497.2)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M794.479,497.2c2.139,0,4.294,1.138,4.294,3.429v4.2c0,2.291-2.155,3.444-4.294,3.444-2.155,0-4.279-1.153-4.279-3.444v-4.2C790.2,498.338,792.324,497.2,794.479,497.2Zm-2.549,7.632c0,1.138,1.275,1.715,2.549,1.715,1.3,0,2.549-.577,2.564-1.715v-4.2c0-1.214-1.29-1.715-2.564-1.715-1.29.015-2.549.531-2.549,1.715Z"
            transform="translate(-749.809 -497.2)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M862.775,509.476h-4.946a.728.728,0,0,1-.728-.728v-8.983a.864.864,0,0,1,.865-.865h0a.864.864,0,0,1,.865.865v8h3.96a.864.864,0,0,1,.865.865h0A.9.9,0,0,1,862.775,509.476Z"
            transform="translate(-806.558 -498.642)"
          />
          <path
            fill="#7b868c"
            className="icon MrsoolIcon"
            d="M604.415,503.471v-3.11H607.1c1.153-.03,1.957.379,1.957,1.548,0,1.183-.759,1.684-1.957,1.684h-1.2a.522.522,0,0,0-.41.85l3.429,4.431a.831.831,0,1,0,1.29-1.047l-2.2-2.792a2.937,2.937,0,0,0,2.792-3.156c-.061-2.337-1.623-3.277-3.7-3.277h-3.672a.728.728,0,0,0-.728.728v8.937a.89.89,0,0,0,.789.9.87.87,0,0,0,.956-.865v-4.825Z"
            transform="translate(-590.759 -498.388)"
          />
        </g>
      </g>
    </g>
  </svg>
);

MrsoolIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

MrsoolIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default MrsoolIcon;
