import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { loginRequestStart, loginClearState } from 'actions/user';

import Form from 'components/Form';
import fieldTypes from 'components/Form/fieldTypes';
import { AlertTitle } from 'assets/styles/sharedStyles';
import { LoginIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';
import SystemIcon from 'components/SystemIcon';

import messages from './intl';

const LoginMessage = styled.p`
  margin-bottom: 10px;
`;

class LoginForm extends Component {
  static defaultProps = {
    closeAlert: () => undefined,
    onSubmit: () => undefined,
    loginRequestStart: () => undefined,
    loginClearState: () => undefined,
    children: () => undefined,
  };

  static propTypes = {
    header: PropTypes.node,
    message: PropTypes.node,
    closeAlert: PropTypes.func,
    onSubmit: PropTypes.func,
    loginRequestStart: PropTypes.func,
    loginClearState: PropTypes.func,
    children: PropTypes.func,
    email: PropTypes.string,
  };

  componentWillUnmount() {
    this.props.loginClearState();
  }

  onSubmit = (values, callback) => {
    const { loginRequestStart, closeAlert, onSubmit } = this.props;
    loginRequestStart(values.email, values.password, res => {
      if (res.success && onSubmit) onSubmit(res);
      if (res.success && closeAlert) closeAlert();
      callback(res);
    });
  };

  render() {
    const header = this.props.header || <FormattedMessage {...globalMessages.login} />;
    return (
      <>
        <SystemIcon Icon={LoginIcon} />
        <AlertTitle>{header}</AlertTitle>
        {this.props.message && <LoginMessage>{this.props.message}</LoginMessage>}
        <Form
          noButtonIcon={true}
          widthLimited={true}
          fields={[{ ...fieldTypes.email }, { ...fieldTypes.password }]}
          onSubmit={this.onSubmit}
          submitButtonLabel={<FormattedMessage {...messages.login} />}
          errorHeader={<FormattedMessage {...messages.login_error} />}
          errorMessage={<FormattedMessage {...messages.badPassword} />}
          solidSubmitButton={false}
        >
          {this.props.children()}
        </Form>
      </>
    );
  }
}

export default connect(undefined, {
  loginRequestStart,
  loginClearState,
})(LoginForm);
