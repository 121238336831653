export default () => {
  const objectCache = {};
  const arrayCache = [];

  return options => {
    arrayCache.length = 0;

    options.forEach(option => {
      if (typeof option === 'object') {
        arrayCache.push(option);
        return;
      }

      if (!objectCache[option]) {
        objectCache[option] = { value: option, label: option };
      }

      arrayCache.push(objectCache[option]);
    });

    return arrayCache;
  };
};
