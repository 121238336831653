import fetchIntercept from 'fetch-intercept';

// Intercept all fetch calls
const register = logout =>
  fetchIntercept.register({
    response: response => {
      // Inspect response status
      if (response.status === 403) logout();

      return response;
    },
  });

export default register;
