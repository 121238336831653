import React from 'react';
import PropTypes from 'prop-types';

// Icon
const LocationIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon locationIcon"
        fill={fill}
        opacity={opacity}
        d="M990,472.4v57.2H871.8c-3.8,45.8-15.3,88.7-34.3,128.7c-19.1,40-43.9,75.6-74.4,106.8c-30.5,31.2-66.1,55.9-106.8,74.4	c-40.7,18.4-83.9,29.6-129.6,33.4V989h-57.2V872.7c-45.8-3.8-88.7-15.3-128.7-34.3c-40-19.1-75-43.9-104.9-74.4	c-29.9-30.5-54.3-65.8-73.4-105.8c-19.1-40-30.5-82.9-34.3-128.7H10v-57.2h118.2c3.8-45.8,15.3-88.7,34.3-128.7	c19.1-40,43.5-75.3,73.4-105.8c29.9-30.5,64.8-55.3,104.9-74.4c40-19.1,82.9-30.5,128.7-34.3V11h57.2v118.2	c45.8,3.8,89,15,129.6,33.4c40.7,18.4,76.3,43.2,106.8,74.4c30.5,31.2,55.3,66.7,74.4,106.8c19.1,40,30.5,82.9,34.3,128.7H990z M526.7,815.5c38.1-3.8,74-13.6,107.7-29.6c33.7-15.9,63.2-36.5,88.7-62c25.4-25.4,46.1-54.6,62-87.7c15.9-33,25.7-68.6,29.6-106.8 H702.1v-57.2h112.5c-3.8-38.1-13.7-73.7-29.6-106.8c-15.9-33-36.6-62.3-62-87.7c-25.4-25.4-55-46.1-88.7-62	c-33.7-15.9-69.6-25.7-107.7-29.6v110.6h-57.2V186.4c-38.1,3.8-73.4,13.7-105.8,29.6c-32.4,15.9-61.3,36.6-86.8,62 c-25.4,25.4-46.1,54.7-62,87.7c-15.9,33.1-25.7,68.6-29.6,106.8h112.5v57.2H185.4c3.8,38.1,13.6,73.7,29.6,106.8 c15.9,33.1,36.5,62.3,62,87.7c25.4,25.4,54.3,46.1,86.8,62c32.4,15.9,67.7,25.7,105.8,29.6V701.1h57.2V815.5z"
      />
    </g>
  </svg>
);

LocationIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

LocationIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default LocationIcon;
