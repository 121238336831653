import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import withCookies from 'components/withCookies';
import InfoBox from 'components/InfoBox';
import SettingsPage from 'components/Pages/User/SettingsPage';
import ToggleCheckbox from 'components/ToggleCheckbox';

import { LinkStyled } from './styles';
import { trackEvent } from '../../../../utils/tracking';

const messages = defineMessages({
  title: {
    defaultMessage: 'Privacy Settings',
  },
  cookies: {
    defaultMessage: 'Cookies',
  },
  message: {
    defaultMessage:
      'This site uses anonymous cookies in order to constantly improve our service. See our { privacyLink } for further information.',
  },
  saveSettings: {
    defaultMessage: 'Save Changes',
  },
  privacyLabel: {
    defaultMessage: 'I agree to the Privacy Settings & Policy',
  },
  privacySettingsPolicy: {
    defaultMessage: 'Privacy Settings & Policy',
  },
});

const PrivacyLink = () => (
  <LinkStyled
    className="u-inlineBlock u-fontWeightBold u-textDecorationUnderline"
    to="/terms_conditions#privacyPolicy"
  >
    <FormattedMessage {...messages.privacySettingsPolicy} />
  </LinkStyled>
);

export const PrivacySettings = ({ getCookie, setCookie }) => {
  const setCookieAccept = checked => {
    trackEvent('privacy_settings', { category: 'settings', privacy_toggle: checked ? 'on' : 'off' });
    setCookie('acceptCookies', checked);
  };

  return (
    <SettingsPage titleMessage={messages.title} loginRequired={false}>
      <InfoBox
        title={<FormattedMessage {...messages.cookies} />}
        message={<FormattedMessage {...messages.message} values={{ privacyLink: <PrivacyLink /> }} />}
      />
      <ToggleCheckbox
        onChange={setCookieAccept}
        label={<FormattedMessage {...messages.privacyLabel} />}
        checked={getCookie('acceptCookies') === 'true'}
      />
    </SettingsPage>
  );
};

PrivacySettings.propTypes = {
  getCookie: PropTypes.func.isRequired,
  setCookie: PropTypes.func.isRequired,
};

export default withCookies(PrivacySettings);
