import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import { TextContainer, ServiceName, Info, IsDraft } from './styles';

const messages = defineMessages({
  is_draft: {
    defaultMessage: 'Draft',
  },
});

const ServiceLinkDetails = ({ compact, isDraft, name, image, address, availabilityInfo }) => (
  <div>
    {image}

    <TextContainer compact={compact}>
      <ServiceName compact={compact}>{name}</ServiceName>

      {address}
      {availabilityInfo && <Info>{availabilityInfo}</Info>}
    </TextContainer>

    {isDraft && (
      <IsDraft>
        <FormattedMessage {...messages.is_draft} />
      </IsDraft>
    )}
  </div>
);

ServiceLinkDetails.propTypes = {
  compact: PropTypes.bool,
  isDraft: PropTypes.bool,
  image: PropTypes.node,
  address: PropTypes.node,
  additionalText: PropTypes.node,
  availabilityInfo: PropTypes.node,
  name: PropTypes.string.isRequired,
};

export default ServiceLinkDetails;
