import React from 'react';

import image from 'utils/image';
import { SpaIcon, FoodAndDrinkIcon, GuestServicesIcon, LocalServicesIcon, CloseIcon } from 'components/Icons';

import { Container, StyledLink, Name } from './styles';

const iconComponents = {
  f_b: FoodAndDrinkIcon,
  spa: SpaIcon,
  guest: GuestServicesIcon,
  local: LocalServicesIcon,
};

type Props = {
  icon?: string;
  name: string;
  link: string;
  images?: { path: string }[];
};

const ServiceCategory = ({ name, icon, link, images }: Props) => {
  const Icon = iconComponents[icon] || CloseIcon;
  const imagePath = images?.[0]?.path;
  const hasimage = imagePath ? 'hasimage' : undefined;

  return (
    <Container
      className="primaryColor"
      hasimage={hasimage}
      style={imagePath && { backgroundImage: `url(${image(imagePath, { height: 700 })})` }}
    >
      <StyledLink to={link} hasimage={hasimage}>
        {hasimage === undefined && <Icon width="70px" height="70px" opacity="0.6" />}
        <Name hasimage={hasimage}>{name}</Name>
      </StyledLink>
    </Container>
  );
};

export default ServiceCategory;
