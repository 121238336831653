import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import { showExitButton } from 'selectors/features';
import StandardAlert from 'components/Alert/StandardAlert';
import globalMessages from 'components/globalMessages';
import { ExitIcon } from 'components/Icons';
import { H2 } from 'components/Typography';

const messages = defineMessages({
  confirmMessage: {
    defaultMessage: 'Are you sure you want to exit?',
  },
  exitMessage: {
    defaultMessage: 'Doing so will clear all order information and return you to the main menu',
  },
  message: {
    defaultMessage: 'Doing so will cancel your current order, and not save any order information.',
  },
  confirm: {
    defaultMessage: 'Yes, exit my order',
  },
});

const ConfirmClearOrder = props => (
  <StandardAlert
    {...props}
    Icon={ExitIcon}
    messages={Object.assign(messages, { header: globalMessages.exit })}
    mainMessage={
      <>
        <H2>
          <FormattedMessage {...messages.confirmMessage} />
        </H2>
        {props.showExitButton && <FormattedMessage {...messages.exitMessage} />}
        {!props.showExitButton && <FormattedMessage {...messages.message} />}
      </>
    }
  />
);

ConfirmClearOrder.propTypes = {
  showExitButton: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  showExitButton: showExitButton(state),
});

export default connect(mapStateToProps)(ConfirmClearOrder);
