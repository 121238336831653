import React from 'react';
import PropTypes from 'prop-types';

// Icon
const GlutenIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon glutenIcon"
        fill={fill}
        opacity={opacity}
        d="M19.9,946.3l182.5-182.5l-24-218.6L703.7,19.9l33.8,33.8l-67.6,67.6l17.4,157.8L870.8,95.4l33.8,33.8L720.9,312.8	l157.8,17.4l67.6-67.6l33.8,33.8L454.8,821.6l-218.6-24L53.7,980.1L19.9,946.3z M279.4,754.3l157.8,17.4l103.9-103.9l-157.8-17.4	L279.4,754.3z M228.3,562.8l17.4,157.8l103.9-103.9l-17.4-157.8L228.3,562.8z M426.6,607.2l157.8,17.4l103.9-103.9l-157.8-17.4	L426.6,607.2z M375.5,415.6l17.4,157.8l103.9-103.9l-17.4-157.8L375.5,415.6z M573.8,460l157.8,17.4l103.9-103.9l-157.8-17.4	L573.8,460z M522.6,268.5L540,426.2l103.9-103.9l-17.4-157.8L522.6,268.5z"
      />
    </g>
  </svg>
);

GlutenIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

GlutenIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default GlutenIcon;
