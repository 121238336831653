import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedPrice from 'components/FormattedPrice';
import {
  Buttons,
  HeaderMessage,
  StyledActionButton,
  StyledPointsBalance,
  StyledBalanceContainer,
} from './styles';

interface LoyaltyUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: number;
  accountNo: string;
  points: number;
  credit: number;
  tier?: string;
}

interface UserCardProps {
  user: LoyaltyUser;
  loyaltyProgramme: string;
  handleSignOut: Function;
}

const UserCard: React.FC<UserCardProps> = ({ user, loyaltyProgramme, handleSignOut }) => (
  <div style={{ paddingBlockEnd: '1rem' }}>
    <HeaderMessage>
      <small>
        <FormattedMessage
          defaultMessage="You Are Signed in as {loyaltyProgramme}"
          values={{ loyaltyProgramme }}
        />
      </small>
      {[user?.firstName, user?.lastName].filter(name => name).join(' ') ||
        user?.email ||
        user?.mobilePhone ||
        user?.accountNo}
    </HeaderMessage>

    <StyledBalanceContainer>
      <StyledPointsBalance>
        <FormattedMessage
          defaultMessage="<small>Points Balance:</small> {points} Pts"
          values={{
            points: (user?.points / 100).toFixed(2),
            small: content => (
              <>
                <small>{content}</small>
              </>
            ),
          }}
        />
      </StyledPointsBalance>

      <StyledPointsBalance>
        <FormattedMessage
          defaultMessage="<small>Credit Balance:</small> {credit}"
          values={{
            credit: <FormattedPrice value={user?.credit} />,
            small: content => (
              <>
                <small>{content}</small>
              </>
            ),
          }}
        />
      </StyledPointsBalance>
    </StyledBalanceContainer>

    <Buttons>
      <StyledActionButton label={<FormattedMessage defaultMessage="Sign Out" />} onClick={handleSignOut} />
    </Buttons>
  </div>
);

export default UserCard;
