import React from 'react';
import PropTypes from 'prop-types';

const JahezIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" width="180" height="180" />
    <path
      fill="#f5a800"
      className="icon JahezIcon"
      d="M20.782,21.065A5.96,5.96,0,0,1,15,15.282,5.96,5.96,0,0,1,20.782,9.5a5.96,5.96,0,0,1,5.782,5.782,5.733,5.733,0,0,1-5.782,5.782"
      transform="translate(37.415 76.381)"
    />
    <path
      fill="#ea0029"
      className="icon JahezIcon"
      d="M22.8,42.576V3.8h6.463V18.426A10.741,10.741,0,0,1,38.106,14a10,10,0,0,1,7.483,3.061c2.041,2.041,3.061,4.762,3.061,8.5V42.576h-6.8V27.269c0-4.762-2.381-7.143-6.122-7.143-4.082,0-6.8,3.061-6.8,7.483V42.576H22.8Z"
      transform="translate(56.146 62.693)"
    />
    <path
      fill="#ea0029"
      className="icon JahezIcon"
      d="M38.163,19.045H53.129c-.68-3.741-3.4-6.463-7.143-6.463-4.082,0-6.8,2.381-7.823,6.463m21.429,4.762H38.5a8.222,8.222,0,0,0,8.5,6.463,17.106,17.106,0,0,0,9.184-2.721l2.721,4.762c-3.4,2.721-7.483,3.741-12.245,3.741-10.544,0-14.966-7.143-14.966-14.626a14.633,14.633,0,0,1,4.082-10.544A14.092,14.092,0,0,1,46.326,6.8a13.609,13.609,0,0,1,9.864,3.741c2.721,2.381,3.741,5.782,3.741,10.2a9.417,9.417,0,0,0-.34,3.061"
      transform="translate(77.518 69.897)"
    />
    <path
      fill="#ea0029"
      className="icon JahezIcon"
      d="M50.284,29.209H64.91v5.442H41.1V30.229L55.386,12.542H41.78V7.1H64.569v4.422Z"
      transform="translate(100.09 70.617)"
    />
    <path
      fill="#ea0029"
      className="icon JahezIcon"
      d="M28.15,7.1H14.544v6.8h7.143V29.549c0,4.762-2.721,7.823-7.143,7.823a8.989,8.989,0,0,1-7.143-3.4L4,39.073a15.122,15.122,0,0,0,11.224,4.422c4.082,0,7.143-1.02,9.524-3.4s3.741-5.782,3.741-10.2V13.563h0V7.1Z"
      transform="translate(11 70.617)"
    />
    <path
      fill="#ea0029"
      className="icon JahezIcon"
      d="M35.969,7.58V9.961A13.321,13.321,0,0,0,27.126,6.9,14.518,14.518,0,0,0,12.5,21.526,14.518,14.518,0,0,0,27.126,36.152a13.321,13.321,0,0,0,8.844-3.061v2.381h6.122V7.58ZM26.786,30.369a8.844,8.844,0,1,1,8.844-8.844A8.782,8.782,0,0,1,26.786,30.369Z"
      transform="translate(31.412 70.137)"
    />
  </svg>
);

JahezIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

JahezIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default JahezIcon;
