import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { withTheme } from 'styled-components';
import StandardAlert from 'components/Alert/StandardAlert';
import { DeleteIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';

const messages = defineMessages({
  header: {
    defaultMessage: 'Delete Address?',
  },
  message: {
    defaultMessage: 'Are you sure you want to delete the address labeled "{ addressLabel }"?',
  },
});

const propTypes = {
  addressLabel: PropTypes.string,
  theme: PropTypes.object,
};

const DeleteAddressAlert = props => {
  const { addressLabel, theme } = props;

  return (
    <StandardAlert
      {...props}
      Icon={DeleteIcon}
      IconFill={theme.color.danger}
      messages={{ ...messages, cancel: globalMessages.no, confirm: globalMessages.yes }}
      mainMessage={<FormattedMessage {...messages.message} values={{ addressLabel }} />}
    />
  );
};

DeleteAddressAlert.propTypes = propTypes;

export default withTheme(DeleteAddressAlert);
