import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

const FormattedModifierValue = ({ type, value }) => {
  switch (type) {
    case 'date':
      return <FormattedDate value={value} />;
    default:
      return value;
  }
};

FormattedModifierValue.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};

export default FormattedModifierValue;
