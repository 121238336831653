import React from 'react';
import PropTypes from 'prop-types';

// Icon
const TakeawayIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M990,233H827.3V102.8c0-12-2.4-23.2-7.2-33.4c-4.8-10.2-11.5-19.3-19.9-27.1c-8.4-7.8-18.1-14.2-28.9-19 c-10.8-4.8-22.3-7.2-34.3-7.2H426c-12.1,0-23.5,2.4-34.3,7.2c-10.8,4.8-20.2,11.2-28,19c-7.9,7.9-14.2,16.9-19,27.1 c-4.8,10.3-7.2,21.4-7.2,33.4V233H172.9v133.1H69.7L50,438.2H10v124.9l40.5,0.8l19.2,419.9h314.8l2-42.3H990V233z M391.7,102.8 c0-3.6,0.9-7.5,2.7-11.7c1.8-4.2,4.2-7.8,7.2-10.8c3-3,6.6-5.4,10.8-7.2c4.2-1.8,8.7-2.7,13.6-2.7h310.9c4.8,0,9.3,0.9,13.6,2.7 c4.2,1.8,7.8,4.2,10.8,7.2c3,3,5.4,6.6,7.2,10.8c1.8,4.2,2.7,8.1,2.7,11.7V233H391.7V102.8z M99.2,406.1H355l9.8,33.5H89.4 L99.2,406.1z M49.4,524.1v-45.3h354.2v45.3H49.4z M349,945H106L89,564.1h276.1L349,945z M935.8,887H389.1l15.1-323.6h38.7V438.2 h-38.7l-19.7-72.1H227.2v-77h110.3v34.3h54.2V289h379.6v34.3h56V289h108.5V887z"
      />
    </g>
  </svg>
);

TakeawayIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TakeawayIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default TakeawayIcon;
