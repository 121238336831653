import React from 'react';
import PropTypes from 'prop-types';

const ArrowDesign = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    preserveAspectRatio="none"
    width={width}
    height={height}
    viewBox="0 0 100 100"
  >
    <g>
      <polygon className="icon arrowDesignBack" fill={fill} opacity={opacity} points="5,0 95,0 50,100" />
      <polygon className="icon arrowDesignFront" fill={fill} points="25,0 75,0 50,80" />
    </g>
  </svg>
);

ArrowDesign.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

ArrowDesign.defaultProps = {
  width: '100%',
  height: '40px',
  fill: 'currentColor',
  opacity: '0.6',
};

export default ArrowDesign;
