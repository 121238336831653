import React from 'react';
import PropTypes from 'prop-types';

import { Container, Option, OptionBar } from './styles';

const BaseModifier = ({ baseModifier, selectedId, changeModifier }) => {
  if (!baseModifier) return null;
  return (
    <Container>
      {baseModifier.get('values').map(modifierValue => (
        <Option key={modifierValue.get('id')} onClick={() => changeModifier(modifierValue)}>
          <span>{modifierValue.get('value')}</span>
          <OptionBar selected={modifierValue.get('id') === selectedId} />
        </Option>
      ))}
    </Container>
  );
};

BaseModifier.propTypes = {
  baseModifier: PropTypes.object,
  selectedId: PropTypes.number,
  changeModifier: PropTypes.func,
};

export default BaseModifier;
