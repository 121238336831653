import fetchHelper from 'utils/fetchHelper';

const makeFetch = (path, opts = { credentials: 'same-origin' }) =>
  fetch(`${window.location.protocol}//${window.location.hostname}:${window.location.port}${path}`, opts).then(
    response => response.json()
  );

export const getMenuList = () => makeFetch('/api/menu-list');

export const getMenu = (id, venueId, cb) => fetchHelper(`/api/menu/${id}`, 'GET', null, cb);

export const getSection = (id, cb) => fetchHelper(`/api/sections/${id}`, 'GET', null, cb);

export const getFavourites = cb => fetchHelper('/api/users/me/favourites', 'GET', null, cb);

export const getProducts = (id, cb) => fetchHelper(`/api/products/${id}`, 'GET', null, cb);

export const getLanguages = (success, fail) => fetchHelper(`/api/locales`, 'GET', null, success, fail);
