import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MustardIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon mustardIcon"
        fill={fill}
        opacity={opacity}
        d="M154.8,983l-54.2-431.8c-1.9-15.5-2.9-31.4-2.9-47.1c0-81.3,25.5-158.5,73.9-223.3l1.4-1.9v-94.1h-20.2V17h694.6v167.9	h-20.2v94.1l1.4,1.9c48.3,64.8,73.9,142.1,73.9,223.3c0,15.7-1,31.5-2.9,47.1L845.2,983H154.8z M195.9,936.4h608.2l11.9-94.8H184 L195.9,936.4z M145.7,473.2c-1,10.3-1.4,20.7-1.4,31c0,13.8,0.9,27.7,2.6,41.3l31.3,249.5h643.7l31.3-249.5	c1.7-13.6,2.6-27.5,2.6-41.3c0-10.2-0.5-20.6-1.4-31l-0.6-6.4H146.3L145.7,473.2z M214.5,301.3c-26.1,33.1-45.3,70.1-57,109.9	l-2.6,9h690.2l-2.6-9c-11.7-39.8-30.9-76.8-57-109.9l-5-6.3V184.9h-561V295L214.5,301.3z M199.3,138.2h601.4V63.6H199.3V138.2z"
      />
      <path
        className="icon mustardIcon"
        fill={fill}
        opacity={opacity}
        d="M384.9,622.8c-22.3,0-40.4,18.1-40.4,40.4c0,22.3,18.1,40.4,40.4,40.4c22.3,0,40.4-18.1,40.4-40.4	C425.3,640.9,407.1,622.8,384.9,622.8z"
      />
      <path
        className="icon mustardIcon"
        fill={fill}
        opacity={opacity}
        d="M603.1,622.8c-22.3,0-40.4,18.1-40.4,40.4c0,22.3,18.1,40.4,40.4,40.4c22.3,0,40.4-18.1,40.4-40.4	C643.5,640.9,625.4,622.8,603.1,622.8z"
      />
      <path
        className="icon mustardIcon"
        fill={fill}
        opacity={opacity}
        d="M494,558.1c-22.3,0-40.4,18.1-40.4,40.4c0,22.3,18.1,40.4,40.4,40.4c22.3,0,40.4-18.1,40.4-40.4	C534.4,576.3,516.3,558.1,494,558.1z"
      />
    </g>
  </svg>
);

MustardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MustardIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default MustardIcon;
