import React from 'react';
import PropTypes from 'prop-types';

const TwitterIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon TwitterIcon"
      fill={fill}
      d="M990,196.1c-36.1,16-74.8,26.8-115.5,31.7c41.5-24.9,73.4-64.3,88.4-111.2c-38.9,23-81.9,39.8-127.7,48.8 c-36.7-39.1-88.9-63.5-146.8-63.5c-111,0-201.1,90-201.1,201.1c0,15.8,1.8,31.1,5.2,45.8c-167.1-8.4-315.2-88.4-414.4-210.1 C60.9,168.3,51,202.8,51,239.7C51,309.4,86.5,371,140.5,407c-33-1-64-10.1-91.1-25.1c0,0.8,0,1.7,0,2.5 c0,97.4,69.3,178.7,161.3,197.1c-16.9,4.6-34.6,7.1-53,7.1c-13,0-25.6-1.3-37.8-3.6c25.6,79.9,99.8,138,187.8,139.6 c-68.8,53.9-155.5,86.1-249.7,86.1c-16.2,0-32.2-0.9-48-2.8c89,57.1,194.7,90.3,308.2,90.3c369.8,0,572.1-306.4,572.1-572.1 c0-8.7-0.2-17.4-0.6-26C929,271.8,963,236.4,990,196.1z M800.4,508c-23.3,62.1-56.7,118.3-99.5,166.8 c-45.6,51.7-99.7,92.3-160.8,120.6c-67.1,31.1-141.7,46.8-221.9,46.8c-30.4,0-60.6-4-90.2-9.2c131-43,189-151,189-151 s-130,9-202.4-55.2C283,615,311,548,311,548s-123,3-184.8-91.6C223,464,229,406,229,406s-109-50-121.7-156.7  c38.5,33.8,80.9,62.8,127,86.5c79.7,41.1,165.7,64.2,255.5,68.7l74.2,3.7L547.2,336c-2.5-10.7-3.7-21.8-3.7-33.1  c0-80,65.1-145.1,145.1-145.1c40.6,0,82.1,13,105.9,45.8C815,232,825,260,834.3,326.2C842.5,385.4,822.6,448.9,800.4,508z"
    />
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TwitterIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default TwitterIcon;
