import React from 'react';
import PropTypes from 'prop-types';

// Icon
const AlmondIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon almondIcon"
        fill={fill}
        opacity={opacity}
        d="M392.4,982.9c-99,0-193.3-38.8-265.7-109.2l-0.5-0.5c-72.6-74.6-111.3-171.9-109.1-274c2.5-111.7,53.4-219,147.2-310.3	c84.6-82.3,187-151.8,296.2-200.8c102-45.8,205.1-71.1,290.3-71.1c75.7,0,135.5,19.8,172.8,57.3l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6	l0.1,0.1l0.2,0.2l0.1,0.1l0.1,0.1c0.5,0.5,1,1,1.4,1.5l0.2,0.2c62.2,62.9,73.8,185.1,31.8,335.2C916,563.3,825.9,717.6,711,835.7 C617.3,932,507.1,982.9,392.4,982.9L392.4,982.9z M915.8,179.5c-19.4,41.6-51.4,93.6-95,154.7c-76.5,107.2-176.4,224.5-281.2,330.3 c-89.4,90.2-175.4,157.9-248.6,195.8c-19.3,10-37.6,17.8-54.5,23.2l-15.6,5l14.4,7.8c48.3,26.1,102.6,39.9,157.1,39.9	c101.9,0,200.5-46,285.2-133.1c109.6-112.7,195.5-259.6,235.7-403.3c23.4-83.9,29-161.6,15.6-219l-4.6-19.7L915.8,179.5z M880.6,110.9c-17,4-68.8,22-181.5,101.7c-106.9,75.6-224.4,175.3-330.9,280.8C258.5,602.1,205.9,682.3,181,730.4	c-31,60.1-31.9,95.8-22.3,109.2l0.7,1l1,0.7c4.9,3.5,12.5,5.3,22.1,5.3c15.3,0,42.9-4.8,87.1-27.6c48.1-24.8,128.2-77.5,236.9-187.1	c105.5-106.5,205.2-224,280.8-330.9C867,188.2,884.9,136.4,889,119.4l2.6-11L880.6,110.9z M750.8,63.7c-169.7,0-397.5,106.4-554,258.6C111.9,404.9,65.9,501,63.7,600.3c-1.3,57.2,12.5,114.1,39.9,164.5l7.8,14.4l5-15.5	c5.5-16.9,13.3-35.3,23.3-54.6c37.8-73.2,105.5-159.2,195.7-248.6c105.8-104.8,223.1-204.7,330.3-281.2c60.3-43,114-75.9,155-95.1	l18.3-8.5l-19.6-4.6C799.1,66.1,776,63.7,750.8,63.7z"
      />
    </g>
  </svg>
);

AlmondIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

AlmondIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default AlmondIcon;
