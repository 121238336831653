import styled, { css } from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  ${({ isThankYouPage }) =>
    isThankYouPage &&
    css`
      background-color: ${({ theme }) => theme.color.thankyouColour};
    `};
`;

export const MainApp = styled.div`
  transition: transform 200ms ease-in;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.hidden &&
    css`
      transform: translateX(
        ${({ theme }) => (theme.dir === 'rtl' ? 'calc(100% - 48px)' : 'calc(-100% + 48px)')}
      );

      &::after {
        background: ${({ theme }) => theme.color.modalBackdrop};
        content: '';
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: ${({ theme }) => theme.zIndex.four};
      }

      @media (min-width: 768px) {
        transform: translateX(${({ theme }) => (theme.dir === 'rtl' ? '320px' : '-320px')});
      }
    `}
`;
