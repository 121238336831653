import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Chevron = ({ className, fill, width, height, direction }) => (
  <Container direction={direction} className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 240 240"
    >
      <g>
        <path
          className="icon"
          fill={fill}
          d="M71.125,234.799c-1.916,0-3.559-0.754-4.93-2.267c-1.367-1.509-2.053-3.322-2.053-5.439c0-2.114,0.686-3.928,2.053-5.439	l92.824-102.447L67.633,18.346c-1.367-1.508-2.053-3.32-2.053-5.439c0-2.113,0.686-3.926,2.053-5.439	c1.371-1.508,3.014-2.266,4.93-2.266c1.918,0,3.561,0.758,4.928,2.266l96.314,106.301c1.369,1.512,2.053,3.324,2.053,5.438 c0,2.118-0.684,3.931-2.053,5.439L76.258,232.532c-0.684,0.758-1.469,1.324-2.361,1.7 C73.008,234.611,72.084,234.799,71.125,234.799z"
        />
      </g>
    </svg>
  </Container>
);

Chevron.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.oneOf(['forward', 'back']),
};

Chevron.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  direction: 'forward',
};

export default Chevron;
