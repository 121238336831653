import styled from 'styled-components';
import { fontSizeSmall } from 'assets/styles/fonts';

export const LinkContainer = styled.span`
  a {
    color: ${({ theme }) => theme.color.darkGrey};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 17px 15px;
    text-decoration: none;
    height: 100px;

    > svg {
      height: 70%;
      margin-bottom: 5px;
      color: ${({ theme }) => theme.color.lightGrey};
    }
  }
`;

export const IconLabel = styled.span`
  ${fontSizeSmall};

  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
`;
