import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import image from 'utils/image';
import globalMessages from 'components/globalMessages';
import QuantityButtons from 'components/QuantityButtons';
import { selectDonations } from 'selectors/donations';
import { getServiceId } from 'selectors/root';
import { selectMenuType } from 'selectors/browse';
import { donationOrderItemsSize, donationOrderItems } from 'selectors/order';
import { openDonationsAlert } from 'actions/UI';
import { addDonationToOrder, removeDonationFromOrder, decreaseDonationQuantity } from 'actions/order';
import FormattedPrice from 'components/FormattedPrice';
import InfoIcon from 'components/Icons/InfoIcon';
import donationTransformer from './donationTransformer';

import {
  MainContainer,
  Overlay,
  Container,
  OptInToggle,
  InfoButton,
  QuantityButtonsContainer,
  SlimDonation,
  SlimPrice,
  Spacer,
  DonationName,
  TermsContainer,
} from './styles';

type Props = {
  donationOrderItems?: List<Map<string, any>>;
  donationOrderItemsSize?: number;
  donationsList?: List<Map<string, any>>;
  openDonationsAlert?: (donation: Map<string, any>) => void;
  addDonationToOrder?: (
    donation: Map<string, any>,
    menuType: {},
    serviceId: string,
    quantity: number,
    source: string
  ) => void;
  removeDonationFromOrder?: (id: string, source: string) => void;
  decreaseDonationQuantity?: (id: string) => void;
  menuType?: object;
  serviceId?: string;
};

const Donations = ({
  donationOrderItems,
  donationOrderItemsSize,
  donationsList,
  openDonationsAlert,
  menuType,
  serviceId,
  addDonationToOrder,
  removeDonationFromOrder,
  decreaseDonationQuantity,
}: Props) => {
  const [optIn, setOptIn] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [donation] = donationsList.filter(donation => !!donation?.get('enabled'));
  const [expanded, setExpanded] = useState<boolean>();

  useEffect(() => {
    const addByDefault = donation?.get('added_by_default');
    setExpanded(!addByDefault);
  }, [donation]);

  useEffect(() => {
    if (donationOrderItemsSize === 0) {
      setOptIn(false);
      setQuantity(0);
    }

    if (donationOrderItemsSize >= 1) {
      const [donationInCart] = donationOrderItems;
      const quantity = donationInCart.get('quantity');

      setOptIn(true);
      setQuantity(quantity);
    }
  }, [donationOrderItemsSize, donationOrderItems]);

  if (!donation) return null;

  const handleOptInToggle = checked => {
    if (checked) {
      setOptIn(true);
      setQuantity(1);
      addDonationToOrder(donationTransformer(donation), menuType, serviceId, 1, donation.get('key'));
    } else {
      setOptIn(false);
      setQuantity(0);
      removeDonationFromOrder(donation.get('product'), donation.get('key'));
    }
  };
  const handleQuantityChange = valueQ => {
    setQuantity(valueQ);
    if (valueQ > quantity) {
      addDonationToOrder(donationTransformer(donation), menuType, serviceId, 1, donation.get('key'));
    } else {
      decreaseDonationQuantity(donation.get('product'));
    }
  };

  if (!donation.get('enabled')) return null;

  return (
    <>
      <SlimDonation>
        <DonationName>
          {quantity} x {donation.get('name')}{' '}
          <img width="34" src={donation?.get('icon')?.get('path')} alt={donation.get('name')} />
        </DonationName>

        <SlimPrice>
          <button onClick={() => setExpanded(!expanded)}>
            <InfoIcon fill="#009E00" />{' '}
          </button>{' '}
          <FormattedPrice value={quantity * donation.get('price')} />
        </SlimPrice>
      </SlimDonation>
      {expanded && (
        <MainContainer
          style={{
            backgroundImage: `url(${image(donation?.get('background_image')?.get('path'), {
              height: 320,
            })})`,
          }}
        >
          <Overlay optIn={optIn}>
            <Container optIn={optIn} style={{ marginRight: '7rem' }} override={true}>
              <span>{donation.get('description')}</span>
              <OptInToggle
                name={donation.get('name')}
                onChange={handleOptInToggle}
                checked={optIn}
                label={<FormattedMessage {...globalMessages.optIn} />}
              />
            </Container>

            <Spacer />

            <Container optIn={optIn}>
              <InfoButton type="button" onClick={() => openDonationsAlert(donation)}>
                <FormattedMessage {...globalMessages.findOutMore} />
              </InfoButton>

              <QuantityButtonsContainer>
                <QuantityButtons quantity={quantity} onChange={handleQuantityChange} />
              </QuantityButtonsContainer>
            </Container>

            <TermsContainer optIn={optIn}>{donation.get('terms')}</TermsContainer>
          </Overlay>
        </MainContainer>
      )}
    </>
  );
};

export default connect(
  state => ({
    donationOrderItems: donationOrderItems(state),
    donationOrderItemsSize: donationOrderItemsSize(state),
    donationsList: selectDonations(state),
    menuType: selectMenuType(state),
    serviceId: getServiceId(state),
  }),
  { addDonationToOrder, removeDonationFromOrder, decreaseDonationQuantity, openDonationsAlert }
)(Donations);
