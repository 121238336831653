import { defineMessages } from 'react-intl';

export default defineMessages({
  ordersPage: {
    defaultMessage: 'Order History',
  },
  error_header: {
    defaultMessage: 'Sorry',
  },
  error_body: {
    defaultMessage:
      'There have been no orders placed as yet, if you need help please contact a member of staff.',
  },
  orderNumber: {
    defaultMessage: 'Order No:',
  },
  venue: {
    defaultMessage: 'Venue',
  },
  header: {
    defaultMessage: 'Order: #{ number }',
  },
  orderInformation: {
    defaultMessage: 'Order Information',
  },
  orderFrom: {
    defaultMessage: 'Order From:',
  },
  orderTime: {
    defaultMessage: 'Order Time:',
  },
  orderTotal: {
    defaultMessage: 'Order Total:',
  },
  orderStatus: {
    defaultMessage: 'Order Status:',
  },
  total: {
    defaultMessage: 'Total:',
  },
  covers: {
    defaultMessage: 'Covers:',
  },
  has_notes: {
    defaultMessage: 'Has Notes',
  },
  promotion: {
    defaultMessage: 'Promotion: ',
  },
  lineItem: {
    defaultMessage: '{formattedNumber} x {promotion} {name}',
  },
});
