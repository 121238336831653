import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';

export const Msg = styled.p`
  font-style: italic;
  margin: 20px 0px;
`;

export const NutritionList = styled.ul`
  list-style: none;
  text-align: left;
  color: ${({ theme }) => theme.color.primaryText};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const NutritionRow = styled.li`
  line-height: 26px;
  display: flex;
  align-items: center;
  ${fontSizeBig}

  &:nth-child(odd) {
    background: #e5e5e5;
    background: ${({ theme }) => theme.color.secondaryBackground};
  }
`;

export const NutritionColumn = styled.span`
  flex: 1;
  padding: 0 5px;
  border-right: ${({ theme, type }) => (type === 'label' ? `1px solid ${theme.color.borderColor}` : 'none')};
  text-align: ${({ type }) => type === 'value' && 'center'};

  html[dir='rtl'] & {
    border-right: none;
    border-left: ${({ theme, type }) => (type === 'label' ? `1px solid ${theme.color.borderColor}` : 'none')};
  }

  @media (min-width: 768px) {
    padding: 6px;
  }
`;
