import React from 'react';
import PropTypes from 'prop-types';

// Icon
const YesIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon YesIcon TickIcon"
        fill={fill}
        d="M120,235c-15.76,0-30.837-2.999-44.812-8.912c-13.979-5.912-26.331-14.177-36.71-24.564	c-10.384-10.377-18.65-22.728-24.565-36.709C7.998,150.836,5,135.758,5,120c0-15.758,2.999-30.835,8.912-44.812	c5.908-13.966,14.173-26.317,24.566-36.71c10.393-10.393,22.744-18.658,36.71-24.566C89.165,7.999,104.242,5,120,5	c15.759,0,30.836,2.999,44.812,8.912c13.952,5.902,26.303,14.167,36.71,24.566c10.396,10.404,18.662,22.755,24.566,36.71	C232.001,89.166,235,104.243,235,120c0,15.757-2.999,30.834-8.912,44.814c-5.912,13.97-14.178,26.32-24.566,36.709	c-10.393,10.394-22.744,18.658-36.71,24.564C150.838,232.001,135.761,235,120,235z M120,17.954c-14.163,0-27.556,2.712-39.81,8.062	c-12.29,5.371-23.255,12.65-32.59,21.638C38.662,56.93,31.383,67.894,26.014,80.19c-5.35,12.242-8.062,25.636-8.062,39.81	c0,14.184,2.712,27.578,8.061,39.808c5.363,12.293,12.643,23.258,21.637,32.592c9.283,8.942,20.247,16.223,32.538,21.585	c12.232,5.35,25.626,8.062,39.811,8.062c14.175,0,27.569-2.712,39.81-8.062c12.296-5.367,23.261-12.647,32.591-21.638	c8.934-9.279,16.214-20.245,21.584-32.538c5.351-12.247,8.063-25.641,8.063-39.809c0-14.158-2.712-27.552-8.063-39.811	c-5.376-12.297-12.656-23.262-21.637-32.59c-9.279-8.93-20.244-16.21-32.538-21.585C147.547,20.666,134.153,17.954,120,17.954z M104.08,168.136c-1.223,0-2.34-0.103-3.318-0.307c-1.651-0.32-2.558-1.239-3.047-2.06l-30.05-30.051	c-1.789-1.777-2.699-3.943-2.699-6.431c0-2.455,0.929-4.493,2.69-5.914c1.426-1.759,3.462-2.686,5.909-2.686	c2.466,0,4.628,0.906,6.427,2.693l24.108,24.107l71.211-69.132c1.429-1.817,3.493-2.776,5.98-2.776c2.468,0,4.631,0.907,6.43,2.694	l0.102,0.102l0.086,0.115c1.316,1.759,1.983,3.851,1.983,6.219c0,2.498-0.968,4.57-2.8,6L109.9,165.788	c-0.495,0.813-1.404,1.722-3.054,2.046C105.878,168.033,104.941,168.136,104.08,168.136z"
      />
    </g>
  </svg>
);

YesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

YesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default YesIcon;
