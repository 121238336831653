import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import { ProcessingContainer, ProcessingLabel } from './styles';

const messages = defineMessages({
  processing: {
    defaultMessage: 'Processing...',
  },
});

const ProcessingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="processingIcon" x="0px" y="0px" viewBox="0 0 192 67">
    <g className="mobile">
      <path
        d="M1.9,1.5v44.2h26.9V1.5H1.9z M26.9,43.7H3.9v-6h19.1c0.6,0,1-0.4,1-1s-0.4-1-1-1H3.9V3.5&#10;&#9;&#9;h22.9V43.7z"
      />
      <path d="M15.9,39.8h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S16.5,39.8,15.9,39.8z" />
      <path d="M13.1,7.8h4.5c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4.5c-0.6,0-1,0.4-1,1S12.6,7.8,13.1,7.8z" />
    </g>
    <g className="clouds">
      <path
        d="M162.1,24.2c-0.4-3.4-2.6-6.5-5.9-7.7c-2.3-0.9-4.8-0.8-7.1,0.2c-2,0.9-3.5,2.4-4.5,4.2&#10;&#9;&#9;c-0.5-0.1-1.1-0.2-1.7-0.2c-1.7,0-3.2,0.7-4.4,1.8c-1.1,1.1-1.8,2.6-1.8,4.3c-3.3,0.8-5.6,3.7-5.6,7.1c0,1.9,0.8,3.8,2.1,5.1&#10;&#9;&#9;c1.4,1.4,3.2,2.1,5.1,2.1c0,0,0,0,0,0l22.8,0c4.7,0,8.5-3.8,8.5-8.5C169.7,28.3,166.4,24.7,162.1,24.2z M161.2,39.2l-22.8,0&#10;&#9;&#9;c0,0,0,0,0,0c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.3-1.5-3.7c0-2.7,2-5,4.7-5.3l1-0.1l-0.1-1c0-0.2,0-0.4,0-0.5c0-1.1,0.4-2.2,1.2-3&#10;&#9;&#9;c0.8-0.8,1.9-1.2,3-1.2c0.6,0,1.2,0.1,1.8,0.4l1,0.4l0.4-1c0.7-1.8,2.1-3.2,3.8-4c1.8-0.8,3.7-0.8,5.6-0.1c2.8,1.1,4.7,3.8,4.7,6.8&#10;&#9;&#9;l0,1h1c3.6,0,6.5,2.9,6.5,6.5C167.7,36.3,164.8,39.2,161.2,39.2z"
      />
      <path
        d="M187.1,17.3c-1.8-1.7-4.1-2.7-6.6-2.9c-0.4-4.4-3.2-8.2-7.4-9.8c-5.7-2.2-12,0.4-14.6,5.7&#10;&#9;&#9;c-0.7-0.2-1.5-0.3-2.2-0.3c0,0,0,0,0,0c-2.6,0-5.1,1.3-6.5,3.5c-0.3,0.5-0.2,1.1,0.3,1.4c0.5,0.3,1.1,0.2,1.4-0.3&#10;&#9;&#9;c1.1-1.6,2.9-2.6,4.8-2.6c0,0,0,0,0,0c0.8,0,1.6,0.2,2.4,0.5l1,0.5l0.4-1c0.9-2.4,2.7-4.3,5.1-5.3c2.3-1,4.9-1.1,7.3-0.2&#10;&#9;&#9;c3.7,1.4,6.1,5,6.1,8.9l0,1l1,0c2.3-0.1,4.5,0.8,6.2,2.3c1.7,1.6,2.6,3.7,2.7,6c0.1,2.3-0.8,4.5-2.3,6.2c-1.6,1.7-3.7,2.6-6,2.7&#10;&#9;&#9;c-0.2,0-0.3,0-0.5,0l-6.1-0.1c-0.5,0-1,0.4-1,1c0,0.6,0.4,1,1,1l6.1,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0&#10;&#9;&#9;c2.8-0.1,5.5-1.3,7.4-3.3s3-4.8,2.9-7.6C190.4,21.9,189.2,19.3,187.1,17.3z"
      />
    </g>
    <path
      className="procesingBasePath"
      d="M165.9,53.9c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.6,1.1,3,2.6,3.4v6.6H17.4v-6.7&#10;&#9;c1.4-0.4,2.5-1.8,2.5-3.3c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.6,1.1,2.9,2.5,3.3v8.7h148.1v-8.7&#10;&#9;C164.9,56.7,165.9,55.4,165.9,53.9z"
    />
    <path
      id="processingEndPoint"
      className="processing"
      d="M161.6,65.9h2v-8.7c1.4-0.5,2.4-1.8,2.4-3.3c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5&#10;&#9;c0,1.6,1.1,3,2.6,3.4L161.6,65.9L161.6,65.9z"
    />
    <path
      id="processingStartPoint"
      className="processing"
      d="M17.4,57.2c1.4-0.4,2.5-1.8,2.5-3.3c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5&#10;&#9;c0,1.6,1.1,2.9,2.5,3.3v8.7h2L17.4,57.2z"
    />
    <g id="processingPath" data-animator-group="true" data-animator-type="2">
      <rect className="processing" x="17.4" y="63.9" width="144.2" height="2" />
    </g>
  </svg>
);

const Processing = ({ children }) => (
  <ProcessingContainer>
    <ProcessingIcon />
    <ProcessingLabel>{children || <FormattedMessage {...messages.processing} />}</ProcessingLabel>
  </ProcessingContainer>
);

Processing.propTypes = {
  children: PropTypes.node,
};

export default Processing;
