import TextQuestion from './TextQuestion';
import MultipleChoice from './MultipleChoice';

const getQuestionComponent = type => {
  switch (type) {
    case 'multiple_choice':
      return MultipleChoice;
    case 'text':
      return TextQuestion;
    default:
      return null;
  }
};

export default getQuestionComponent;
