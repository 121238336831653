import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { RemoveFavouriteIcon } from 'components/Icons';

const propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  theme: PropTypes.object,
};

const RemoveFavouriteThemedIcon = ({ className, width, height, theme }) => (
  <RemoveFavouriteIcon className={className} width={width} height={height} fill={theme.color.favourites} />
);

RemoveFavouriteThemedIcon.propTypes = propTypes;

export default withTheme(RemoveFavouriteThemedIcon);
