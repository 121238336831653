import styled from 'styled-components';
import { StyledInput } from 'assets/styles/sharedStyles';
import SearchIcon from 'components/Icons/SearchIcon';

export const Container = styled.div`
  margin: 15px 0;
`;

export const SearchBarContainer = styled.div`
  position: relative;
  flex-grow: 1;
  color: #666;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  top: 9px;
  z-index: ${({ theme }) => theme.zIndex.three};

  html[dir='rtl'] & {
    right: 10px;
    left: 0;
  }
`;

export const SearchBarStyled = styled.input`
  ${StyledInput}

  padding: 9px;
  padding-left: 40px;
  border-color: ${({ theme }) => theme.color.borderGray};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.darkGrey};
  border-radius: 21px;
  overflow: hidden;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999;
  }

  html[dir='rtl'] & {
    padding-left: 9px;
    padding-right: 40px;
  }
`;

export const ClearButtonStyled = styled.button`
  z-index: ${({ theme }) => theme.zIndex.two};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  right: 7px;
  top: 7px;
`;

export const GeoButtonContainer = styled.div`
  width: 57%;

  @media (min-width: 420px) {
    width: 40%;
  }
`;
