import styled from 'styled-components';
import { TickIcon } from 'components/Icons';

export const LanguagesList = styled.ul`
  list-style: none;
  padding: 10px 0;
`;

export const LanguageItem = styled.li`
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  }

  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  display: block;
  position: relative;
`;

export const StyledTickIcon = styled(TickIcon)`
  position: absolute;
  top: 14px;
  left: 4px;

  html[dir='rtl'] & {
    left: auto;
    right: 4px;
  }
`;

export const LanguageItemSelected = styled(LanguageItem)`
  font-weight: bold;
`;

export const LanguageLabelButton = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 0 10px 30px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 30px;
    text-align: right;
  }
`;
