import { defineMessages } from 'react-intl';

export default defineMessages({
  tableNumber: {
    defaultMessage: 'Please enter your table number',
  },
  sunbedNumber: {
    defaultMessage: 'Please enter your sunbed number',
  },
  roomNumber: {
    defaultMessage: 'Please enter your room number',
  },
});
