import React from 'react';
import PropTypes from 'prop-types';

const ImageIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    preserveAspectRatio="none"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon ImgIcon"
        fill={fill}
        opacity={opacity}
        d="M159.3,192.6l-26.8-29l46.1-38.9l50.9,55.1v12.8H159.3z M10.5,156.9l60.9-51.3l80.5,87.1H10.5V156.9z M229.5,47.4v124.3l-50.5-54.6l-50.3,42.4L71.8,98l-61.4,51.7V47.4H229.5z M5,198h230V42H5V198z M129.7,115.5	c12.1,0,21.9-9.8,21.9-21.9c0-12.1-9.8-21.9-21.9-21.9s-21.9,9.8-21.9,21.9C107.8,105.6,117.6,115.5,129.7,115.5z M129.7,77.1	c9.1,0,16.4,7.4,16.4,16.4s-7.4,16.4-16.4,16.4s-16.4-7.4-16.4-16.4S120.6,77.1,129.7,77.1z"
      />
    </g>
  </svg>
);

ImageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

ImageIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default ImageIcon;
