import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { getServiceId } from 'selectors/root';
import {
  selectOrderTotal,
  selectOrderTaxTotal,
  selectOrderTaxTotals,
  returnStackedPromotions,
  validatedOrderItems,
  getOrderChargeItems,
  selectOrderFinalTotal,
} from 'selectors/order';
import OrderSummary from 'components/OrderReview/OrderSummary';

const CurrentOrderSummary = ({
  serviceId,
  productItems,
  promotionalItems,
  chargeItems,
  orderTotal,
  taxTotal,
  taxBreakdown,
  finalTotal,
}) => (
  <OrderSummary
    serviceId={serviceId}
    productItems={productItems}
    promotionalItems={promotionalItems}
    chargeItems={chargeItems}
    orderTotal={orderTotal}
    taxTotal={taxTotal}
    taxBreakdown={taxBreakdown}
    finalTotal={finalTotal}
    showCoupon={true}
  />
);

CurrentOrderSummary.propTypes = {
  serviceId: PropTypes.string,
  productItems: PropTypes.instanceOf(List),
  chargeItems: PropTypes.instanceOf(List),
  promotionalItems: PropTypes.instanceOf(List),
  orderTotal: PropTypes.number,
  taxTotal: PropTypes.number,
  taxBreakdown: PropTypes.instanceOf(List),
  finalTotal: PropTypes.number,
};

export default connect(state => ({
  serviceId: getServiceId(state),
  productItems: validatedOrderItems(state),
  promotionalItems: returnStackedPromotions(state),
  chargeItems: getOrderChargeItems(state),
  orderTotal: selectOrderTotal(state),
  taxTotal: selectOrderTaxTotal(state),
  taxBreakdown: selectOrderTaxTotals(state),
  finalTotal: selectOrderFinalTotal(state),
}))(CurrentOrderSummary);
