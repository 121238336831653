import React from 'react';
import { connect } from 'react-redux';

import Checkbox from 'components/Checkbox';
import { selectVenueConsentMessage } from 'selectors/root';

import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};

  @media (min-width: 870px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeMediumOne};
  }
`;

type Props = {
  consentMessage: string;
  value?: string | boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: (checked: boolean) => void;
};

const ConsentCheckbox = ({ value, onChange, onBlur, consentMessage }: Props) => (
  <StyledCheckbox>
    <Checkbox
      id="consentCheckbox"
      checked={value}
      onChange={event => {
        onChange(event.target.checked);
        onBlur(event.target.checked);
      }}
    >
      {consentMessage}
    </Checkbox>
  </StyledCheckbox>
);
export default connect(state => ({
  consentMessage: selectVenueConsentMessage(state),
}))(ConsentCheckbox);
