import styled from 'styled-components';
import { Link } from 'react-router';
import { H1 } from 'components/Typography';

export const PageContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
`;

export const ProcessingIconDiv = styled.div`
  width: 75%;
  margin: 60px auto;
`;

export const AlertIconDiv = styled.div`
  color: ${({ theme }) => theme.color.danger};
  margin-bottom: 10px;
`;

export const Title = styled(H1)`
  margin-bottom: 10px;
`;

export const Content = styled.h2`
  font-size: 18px;
  font-weight: normal;
`;

export const OrderNumber = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0;
`;

export const LinkStyled = styled(Link)`
  font-size: 18px;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 10px 20px;
  padding: 10px;
  min-width: 195px;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
`;

export const ProcessingMainSection = styled.section`
  color: #444;
  display: block;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;
  min-height: 300px;
  width: 80%;
  max-width: 600px;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
