import React from 'react';
import { defineMessages } from 'react-intl';

import { LogoutIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';
import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  message: {
    defaultMessage: 'Sorry, your session has expired. Please log in again to continue',
  },
});

export default props => (
  <StandardAlert
    {...props}
    Icon={LogoutIcon}
    messages={{ ...messages, header: globalMessages.loggedOut, confirm: globalMessages.login }}
  />
);
