import React from 'react';
import PropTypes from 'prop-types';

const PointerIconFull = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    version="1.1"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g>
      <path
        className="icon pointerIconFull"
        fill={fill}
        opacity={opacity}
        d="M256,103.278c-39.429,0-71.505,32.077-71.505,71.505c0,39.429,32.077,71.505,71.505,71.505
			c39.428,0,71.505-32.077,71.505-71.505C327.505,135.355,295.429,103.278,256,103.278z"
      />
    </g>

    <g>
      <path
        className="icon pointerIconFull"
        fill={fill}
        opacity={opacity}
        d="M256,0C158.107,0,78.465,79.642,78.465,177.535c0,40.042,28.089,106.034,83.486,196.143
			c40.502,65.88,81.577,121.48,81.987,122.033L256,512l12.062-16.289c0.41-0.553,41.485-56.153,81.987-122.033
			c55.397-90.109,83.486-156.101,83.486-196.143C433.535,79.642,353.893,0,256,0z M256,276.306
			c-55.98,0-101.522-45.543-101.522-101.522c0-55.98,45.543-101.522,101.522-101.522s101.522,45.543,101.522,101.522
			C357.522,230.763,311.98,276.306,256,276.306z"
      />
    </g>
  </svg>
);

PointerIconFull.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

PointerIconFull.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default PointerIconFull;
