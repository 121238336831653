import React from 'react';
import PropTypes from 'prop-types';

const DeliverooIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" className="icon DeliverooIcon" width="180" height="180" />
    <g transform="translate(30 33)">
      <path
        fill="#35b8b2"
        fillRule="evenodd"
        className="icon DeliverooIcon"
        d="M742.175,2850.707h3.894l-.646-2.992v-17.868H741.7V2838a5.8,5.8,0,0,0-4.481-2.045c-3.723,0-6.608,3.071-6.608,7.539s2.885,7.531,6.608,7.531a5.8,5.8,0,0,0,4.567-2.133Zm73.642-10.755a3.621,3.621,0,0,1,2.1.651l1.766-3.955a5.189,5.189,0,0,0-2.693-.723,4.516,4.516,0,0,0-3.974,2.167l-.395-1.837h-3.835l.618,2.823v11.629h3.721V2841.5a2.931,2.931,0,0,1,2.689-1.548h0Zm10.921,7.162a3.643,3.643,0,1,1,3.755-3.625,3.522,3.522,0,0,1-3.755,3.625h0Zm-92.517-3.625a3.755,3.755,0,1,1,3.753,3.625A3.524,3.524,0,0,1,734.221,2843.489Zm109.018,3.625a3.643,3.643,0,1,1,3.753-3.625A3.52,3.52,0,0,1,843.24,2847.114Zm-88.122-7.491a3.3,3.3,0,0,1,3.585,2.558h-7.172A3.334,3.334,0,0,1,755.118,2839.623Zm44.565,0a3.294,3.294,0,0,1,3.585,2.558H796.1A3.33,3.33,0,0,1,799.683,2839.623Zm-18.177,11.084H787.5l3.894-14.452H787.33l-2.855,11.7-2.857-11.7H777.61Zm-9.61,0h3.723v-14.452H771.9Zm-16.211.258a10.777,10.777,0,0,0,5.827-1.645l-1.426-3.184a9.379,9.379,0,0,1-4.341,1.123c-1.961,0-3.332-.762-3.922-2.166H762.3a7.829,7.829,0,0,0,.17-1.708,7.067,7.067,0,0,0-7.284-7.459c-4.2,0-7.282,3.009-7.282,7.516,0,4.6,3.051,7.523,7.787,7.523h0Zm94.923-7.475a7.369,7.369,0,1,0-7.369,7.531A7.161,7.161,0,0,0,850.608,2843.489Zm-50.422,7.531a10.915,10.915,0,0,0,5.826-1.653l-1.427-3.191a9.481,9.481,0,0,1-4.343,1.115c-1.961,0-3.33-.753-3.924-2.166H806.8a7.9,7.9,0,0,0,.168-1.7,7.09,7.09,0,0,0-7.284-7.475c-4.2,0-7.282,3.024-7.282,7.539,0,4.587,3.051,7.531,7.787,7.531h0Zm26.554,0a7.537,7.537,0,1,0-7.369-7.531,7.167,7.167,0,0,0,7.369,7.531h0Zm-61.9-.313h3.725v-20.859h-3.725Zm8.934-16.442a2.345,2.345,0,1,0-2.352-2.341,2.279,2.279,0,0,0,2.352,2.341Z"
        transform="translate(-730.608 -2736.945)"
      />
      <path
        fill="#35b8b2"
        fillRule="evenodd"
        className="icon DeliverooIcon"
        d="M1246.832,795.6l-4.006,37.594-6.837-31.694-21.418,4.491,6.829,31.691-30.889,6.474,5.45,25.313,54.32,11.383,12.423-27.506,5.915-55.485Zm-17.162,54.536c-1.119,1.015-2.6.919-4.171.4s-2.266-2.388-1.676-4.644c.445-1.684,2.53-1.933,3.573-1.947a2.629,2.629,0,0,1,1.147.227c.738.331,1.985,1.034,2.242,2.1a3.7,3.7,0,0,1-1.115,3.854h0Zm15.727,1.744c-.818,1.4-2.951,1.58-5.048.564-1.424-.684-1.412-2.428-1.26-3.489a3.388,3.388,0,0,1,.69-1.586,3.488,3.488,0,0,1,2.326-1.48,4.1,4.1,0,0,1,3.725,1.931c.778,1.263.385,2.662-.425,4.06h0Z"
        transform="translate(-1169.565 -795.602)"
      />
    </g>
  </svg>
);

DeliverooIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

DeliverooIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default DeliverooIcon;
