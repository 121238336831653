import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { isDineInOrderingFlow, selectDineInLocationField } from 'selectors/root';
import { selectSessionLocationRef } from 'selectors/session';
import { getFieldLabel } from 'components/Form/utils';
import { Location } from './styles';

const DineInLocation = ({ isDineInFlow, locationRef, locationLabel }) => {
  if (!isDineInFlow || !locationLabel || !locationRef) return null;

  return (
    <Location>
      {locationLabel}: {locationRef}
    </Location>
  );
};

DineInLocation.propTypes = {
  isDineInFlow: PropTypes.bool,
  locationRef: PropTypes.string,
  locationLabel: PropTypes.string,
};

export default injectIntl(
  connect((state, ownProps) => {
    const locationField = selectDineInLocationField(state);
    return {
      isDineInFlow: isDineInOrderingFlow(state),
      locationRef: selectSessionLocationRef(state),
      locationLabel: locationField?.name && getFieldLabel(state, locationField, ownProps.intl?.formatMessage),
    };
  })(DineInLocation)
);
