import { defineMessages } from 'react-intl';

export default defineMessages({
  addPaymentMethod: {
    defaultMessage: 'Add Payment Method',
  },
  groupAddPaymentMethod: {
    defaultMessage: 'One member of your group must add a payment method to get started',
  },
  addNow: {
    defaultMessage: "Yes, I'll add it now",
  },
  noAction: {
    defaultMessage: 'No, someone else is doing this',
  },
  preAuthWaiting: {
    defaultMessage: 'Waiting for a card to be added',
  },
  singleCardRequired: {
    defaultMessage: 'You do not need to add your card if someone else in your group is adding theirs.',
  },
  addMyCard: {
    defaultMessage: 'Add my card',
  },
  cardAdded: {
    defaultMessage: 'Your card has been added!',
  },
  currentOrder: {
    defaultMessage: 'Current Order',
  },
  currentTab: {
    defaultMessage: 'Current Tab',
  },
  addItems: {
    defaultMessage: 'Add Items',
  },
  payBill: {
    defaultMessage: 'Pay the Bill',
  },
  tabClosed: {
    defaultMessage: 'Your payment is confirmed and your tab has now been closed.',
  },
  joinAgain: {
    defaultMessage: 'Thank you for your visit, join us again soon!',
  },
  oneMoment: {
    defaultMessage: 'One moment...',
  },
});
