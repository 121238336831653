import { fetchHelper } from 'utils';
import { selectTabId, selectTabOrder } from 'selectors/session';
import { selectCompletedOrderTabId } from 'reducers/root';
import { SET_TAB_ORDERS } from 'actions/session/constants';
import { selectLoyaltyUser } from '../../selectors/loyalty';

export const fetchTab = () => (dispatch, getState) => {
  const state = getState();
  const tabId = selectTabId(state) || selectCompletedOrderTabId(state);
  if (!tabId) return false;

  const success = res => {
    dispatch({
      type: SET_TAB_ORDERS,
      tabId,
      orders: res.orders,
      state: res.state,
      pos_sync_state: res.pos_sync_state,
    });
  };

  return fetchHelper(`/api/tabs/${tabId}`, 'GET', null, success, () => ({}));
};

export const setTabMembershipCode = () => (_dispatch, getState) => {
  const state = getState();

  const tab = selectTabOrder(state);
  const loyaltyUser = selectLoyaltyUser(state);

  return fetchHelper(`/api/tabs/${tab.get('id')}/membershipCode`, 'POST', {
    membership_code: loyaltyUser?.get('accountNo') || '',
  });
};
