import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import CurrencyCodeProvider from 'providers/CurrencyCodeProvider';
import { selectVenueCurrencyCode } from 'selectors/root';
import { getLocale } from 'selectors/user';

import _ from 'lodash';
import localeData from '../../../build/locales';

const propTypes = {
  locale: PropTypes.string,
  currency: PropTypes.string,
  children: PropTypes.object,
};

const IntlApp = ({ locale, currency, children }) => {
  const getLanguage = () => {
    if (locale) return locale;

    if (typeof navigator === 'undefined') return 'en';

    return (
      (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en'
    );
  };

  const getIntlLocale = () => getLanguage().replace('_', '-');

  const removeRegionCode = language => language.toLowerCase().split(/[_-]+/)[0];

  const getMessages = () => {
    const localeCode = getLanguage().replace('-', '_');
    const languageWithoutRegionCode = removeRegionCode(localeCode);

    const result = localeData[localeCode] || localeData[languageWithoutRegionCode];
    return _.mapValues(result, v => v.message);
  };

  return (
    <IntlProvider locale={getIntlLocale()} messages={getMessages()}>
      <CurrencyCodeProvider currency={currency}>{children}</CurrencyCodeProvider>
    </IntlProvider>
  );
};

IntlApp.propTypes = propTypes;

export default connect(state => ({
  locale: getLocale(state),
  currency: selectVenueCurrencyCode(state),
}))(IntlApp);
