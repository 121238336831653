import React from 'react';
import { defineMessages } from 'react-intl';

import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  header: {
    defaultMessage: 'Clear Notes?',
  },
  message: {
    defaultMessage: 'Are you sure you want to clear the notes for this product?',
  },
  confirm: {
    defaultMessage: 'Clear Notes',
  },
});

export default props => <StandardAlert {...props} messages={messages} />;
