import styled from 'styled-components';

export const Container = styled.span`
  display: inline-block;
  transform: scale(${({ direction }) => direction === 'back' && '-1, 1'});

  html[dir='rtl'] & {
    transform: scale(${({ direction }) => (direction === 'back' ? '1, 1 ' : '-1, 1')});
  }
`;
