import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Choosey from 'components/Choosey';
import Choice from 'components/Choosey/choice';
import { setLoyaltyUser } from 'actions/loyalty';
import PhoneNumberInput from 'components/PhoneNumberInput';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { messages } from './messages';
import {
  Container,
  StyledActionButton,
  LoginChoiceContainer,
  SignInBodyContainer,
  StyledInput,
} from './styles';

const regexPhoneNumberPattern =
  // eslint-disable-next-line max-len
  /^(\+?973(?:[0-9] ?-?){7}[0-9]$)|(\+?966(?:[0-9] ?-?){8}[0-9]$)|(\+?965(?:[0-9] ?-?){7}[0-9]$)|(\+?971(?:[0-9] ?-?){8}[0-9]$)|(\+?974(?:[0-9] ?-?){7}[0-9]$)/;

const regexEmailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const AuraSignInPage = ({ setPage, dispatch }) => {
  const signInMethods = [
    {
      name: 'mobilePhone',
      label: 'Mobile number',
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
    },
  ];

  const [signInMethod, setSignInMethod] = useState(0);
  const mobileOption = signInMethods[signInMethod].name === 'mobilePhone';
  const [loading, setLoading] = useState(false);

  const [signInInput, setSignInInput] = useState('');

  // Set the validity of the input value for email and phone number
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  // Check that inserted email matches the email pattern
  const isEmail = email => regexEmailPattern.test(email);

  const handleEmailChange = value => {
    setSignInInput(value);
    setIsValidEmail(isEmail(value));
  };

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    const params = new URLSearchParams(formData);
    const res = await fetch(`/api/loyalty/user/sign_in?${params}`, {
      method: 'POST',
    }).then(res => {
      setLoading(false);
      return res;
    });

    const resData = res.ok ? await res.json() : false;

    if (resData) {
      dispatch(setLoyaltyUser(resData));
      setPage('signInSuccess');
    } else mobileOption ? setIsValidPhoneNumber(null) : setIsValidEmail(null);
  }

  return (
    <Container>
      <SignInBodyContainer>
        <div>
          <FormattedMessage {...messages.signInBody1} />
        </div>
        <div>
          <FormattedMessage {...messages.signInBody2} />
        </div>
      </SignInBodyContainer>
      <LoginChoiceContainer>
        <Choosey
          maxChoices={1}
          required={true}
          onChange={([methodIndex]) => setSignInMethod(methodIndex)}
          choices={signInMethods}
          values={[signInMethod]}
          renderProps={(onChange, checked, choice, id) => (
            <Choice onChange={onChange} checked={!!checked} key={id} text={choice.label} id={id} />
          )}
        />
      </LoginChoiceContainer>
      <form onSubmit={handleSubmit}>
        {mobileOption ? (
          <PhoneNumberInput
            error={!!isValidPhoneNumber}
            errorMessage={
              <>
                {isValidPhoneNumber === false ? (
                  <FormattedMessage defaultMessage="The Mobile number you have entered is invalid." />
                ) : (
                  <FormattedMessage defaultMessage="This mobile number is not linked to an Aura account." />
                )}
                <br />
                <FormattedMessage defaultMessage="Please enter a valid mobile number." />
              </>
            }
            value={signInInput}
            onChange={setSignInInput}
            showOnlyCountries={['bh', 'ae', 'sa', 'kw', 'qa']}
            name="mobilePhone"
            rgxPattern={regexPhoneNumberPattern}
            isValid={!!isValidPhoneNumber}
            setIsValid={setIsValidPhoneNumber}
          />
        ) : (
          <StyledInput
            label={signInMethods[signInMethod].label}
            onChange={handleEmailChange}
            error={!isValidEmail}
            helpBlock={
              !isValidEmail && (
                <>
                  {isValidEmail === false ? (
                    <FormattedMessage defaultMessage="The Email address you have entered is invalid." />
                  ) : (
                    <FormattedMessage defaultMessage="This Email address is not linked to an Aura account." />
                  )}
                  <br />
                  <FormattedMessage defaultMessage="Please enter a valid email address." />
                </>
              )
            }
            name={signInMethods[signInMethod].name}
            value={signInInput}
            type={signInMethods[signInMethod].type}
          />
        )}

        <StyledActionButton
          height="3.5rem"
          disabled={loading || (mobileOption ? !isValidPhoneNumber : !isValidEmail)}
          type="submit"
          label={
            loading ? (
              <LoadingSpinner noPad={true} height="1.5rem" width="1.5rem" />
            ) : (
              <FormattedMessage defaultMessage="Sign In" />
            )
          }
        />
      </form>
    </Container>
  );
};

AuraSignInPage.propTypes = { setPage: PropTypes.func, dispatch: PropTypes.func };

export default connect()(AuraSignInPage);
