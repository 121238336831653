import React from 'react';
import PropTypes from 'prop-types';

const CheckoutIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <rect className="icon" x="29" y="137" width="52" height="9" fill={fill} />
      <path
        className="icon"
        fill={fill}
        d="M178.617,169.473c1.684,1.519,3.793,2.527,6.158,2.527h17.205c2.357,0,4.589-1.009,6.28-2.527	c1.686-1.518,2.739-3.705,2.739-6.069v-0.506c0-2.356-1.054-4.528-2.739-6.22c-1.691-1.684-3.923-2.678-6.28-2.678h-17.205	c-2.365,0-4.475,0.994-6.158,2.678c-1.693,1.691-2.617,3.863-2.617,6.22v0.506C176,165.768,176.924,167.955,178.617,169.473z"
      />
      <rect className="icon" x="29" y="163" width="86" height="9" fill={fill} />
      <path
        className="icon"
        fill={fill}
        d="M235.185,50.048c-0.68-1.684-1.421-3.036-2.433-4.048c-1.352-1.344-2.734-2.332-4.252-3.012	c-1.518-0.672-3.074-0.988-4.76-0.988H16.26c-1.692,0-3.29,0.315-4.808,0.988c-1.518,0.68-2.957,1.68-4.301,3.024	c-1.012,1.012-1.663,2.358-2.335,4.042C4.137,51.746,4,53.424,4,55.108v129.549c0,1.692,0.137,3.377,0.816,5.061	c0.672,1.692,1.42,3.036,2.433,4.049c1.344,1.352,2.734,2.457,4.252,3.129c1.518,0.68,3.067,1.104,4.759,1.104h207.48	c1.686,0,3.291-0.425,4.809-1.104c1.518-0.672,2.949-1.731,4.301-3.083c1.012-1.012,1.655-2.379,2.335-4.071	c0.672-1.684,0.815-3.392,0.815-5.084V55.108C236,53.424,235.856,51.74,235.185,50.048z M12,55.108	c0-1.012,0.525-1.967,1.371-2.813C14.209,51.457,15.248,51,16.26,51h207.48c1.014,0,2.043,0.457,2.889,1.295	c0.84,0.847,1.371,1.801,1.371,2.813V68H12V55.108z M228,184.657c0,1.013-0.531,2.093-1.371,2.931 c-0.846,0.846-1.875,1.412-2.889,1.412H16.26c-1.012,0-2.051-0.566-2.889-1.412C12.525,186.75,12,185.67,12,184.657V103h216	V184.657z"
      />
    </g>
  </svg>
);

CheckoutIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CheckoutIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CheckoutIcon;
