import React from 'react';
import PropTypes from 'prop-types';

// Icon
const HalalIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon halalIcon"
        fill={fill}
        opacity={opacity}
        d="M226.5,265.1l-19,20.9l6.4,25.4c6.7,25.1,6.7,25.1,6.7,175.9v151l-13.4,12.6c-18.2,17.9-41.4,25.1-80.2,25.1	c-24.1,0-30.2-1.1-42.2-6.4c-27.3-12.3-43.3-40.6-43.3-75.9c0-11,2.9-31.8,6.7-48.7c3.7-16.3,7.5-33.2,8.3-37.4l1.3-8l-6.2,7.5	c-7.2,8.3-28.9,61.2-35.8,87.4c-5.3,21.1-6.7,73.8-1.9,89.6c9.4,29.9,33.4,55.1,62,64.7c22.4,7.2,65.5,6.7,89-1.3	c46.3-16,87.4-57.2,101.1-101.6c5.1-16,5.3-25.1,5.3-150.8V361.6l8-19c4.5-10.2,8-19.8,8-21.4c0-1.3-3.5-7.5-7.8-13.6	c-9.4-13.6-22.5-39.8-26.7-53.5c-1.9-5.3-4.3-9.9-5.6-9.9C246.3,244.2,236.7,253.6,226.5,265.1z"
      />
      <path
        className="icon halalIcon"
        fill={fill}
        opacity={opacity}
        d="M534.5,298.5c-12.8,20-13.4,21.7-11.5,33.4c4,25.7,5.6,116.8,2.7,147.8c-5.3,56.9-20.6,101.9-46.5,136.6	c-6.2,8.3-11.5,14.7-12,14.2c-0.5-0.5,0.5-10.4,2.4-21.9c1.9-11.5,3.5-39,3.2-61c0-46.8-4.8-69.5-22.2-104.5	c-28.1-57.5-92.2-124.8-108.8-114.4c-7.5,4.5-16,21.9-21.1,42c-7.5,30.5-2.1,49.2,21.4,75.7c11.2,12.8,13.4,14.2,30.2,17.1	c32.1,5.9,37.4,9.6,54.8,35.8c18.7,28.9,27.8,74.6,24.3,123c-1.9,27.3-1.6,27-35,48.9c-27.5,18.2-58.8,32.6-97.6,45.4	c-16.3,5.3-31,10.4-32.1,11.5c-4.3,3.7,34.8,9.1,66.3,8.8c51.9-0.3,83.1-9.4,115.5-33.9c23.5-17.9,44.4-44.1,59.9-75.1	c15.8-32.1,23.5-60.1,25.9-96.2l2.1-26.7l3.5,20c6.2,34.5,18.7,75.1,31.3,101.3c9.9,20,16,28.9,32.6,45.4	c35.8,35.8,70,53.5,114.4,59.4c25.7,3.2,34.2,2.4,100-12c42.8-9.4,84-15,107.7-15h20l13.9-35.3c8.3-21.4,12.6-35.8,10.7-36.4	c-11.8-4-43-30.2-83.1-69.2c-67.4-65.5-91.4-79.9-132.1-77.8c-32.3,1.9-53.2,15-74.6,47c-17.9,27.3-18.2,28.4-3.2,21.9	c46.5-19.8,77.8-17.4,114.4,8.8c27.3,19.5,69.8,66.3,64.7,71.1c-7.5,6.9-81.8,19.5-116,19.5c-20.6,0.3-29.4-1.1-46.8-6.9 c-11.8-4-25.7-9.9-30.8-13.1c-44.1-26.5-79.7-73-96-125.7c-11.8-38.8-27-124.3-35.8-201l-3.2-29.4L534.5,298.5z"
      />
    </g>
  </svg>
);

HalalIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

HalalIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default HalalIcon;
