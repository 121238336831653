import styled, { css } from 'styled-components';
import ComoLogo from 'components/Icons/ComoLogo';
import ActionButton from 'components/ActionButton';
import Input from 'components/Input';
import { AlertContent, AlertTitle } from 'assets/styles/sharedStyles';

export const Container = styled.div`
  padding: 1rem;

  input[type='text'],
  input[type='email'] {
    border: 1px solid #aaa;
    padding: 0.5rem;
    width: 100%;
  }

  .input-container {
    padding-block-start: 1rem;
    display: grid;
    grid-template-columns: 20px 1fr;
  }

  .input-container label {
    padding-inline-start: 1rem;
  }

  input[type='radio'] + label {
    margin-inline-start: 0.25rem;
  }

  .input-group {
    padding-block-start: 1rem;
    padding-block-end: 1rem;
  }

  .otp-resend {
    text-align: center;
  }

  .otp-resend button {
    color: ${({ theme }) => theme.color.link};
  }
`;

export const TitleInContent = styled.h3`
  margin-block-end: 2rem;
  text-align: left;
`;

export const StyledLogo = styled(ComoLogo)`
  height: 3.2rem;
  display: inline-block;
  fill: ${({ theme }) => theme.color.brandSecondary};
  width: auto;
`;

export const HeaderMessage = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.brandSecondary};
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;

  small {
    font-size: 0.8rem;
    display: block;
  }
`;

export const Content = styled.div`
  text-align: initial;
`;

export const StyledBalanceContainer = styled.div`
  display: flex;
`;

export const StyledPointsBalance = styled.div`
  font-weight: normal;
  margin-block-start: 1rem;
  flex: 1;

  small {
    font-size: 1rem;
    display: block;
    font-weight: bold;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-block-start: 1rem;
`;

export const Button = styled(ActionButton)`
  border-color: ${({ theme }) => theme.color.brandSecondary};
  color: ${({ theme }) => theme.color.brandSecondary};
  font-size: 0.8rem;
  min-height: unset;
  padding: 0.5rem;
  text-transform: none;
  width: fit-content;

  & > span {
    white-space: nowrap;
  }

  html[dir='rtl'] & {
    left: 1rem;
    right: unset;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  border-color: ${({ theme }) => theme.buttons.defaultBorder};
  color: ${({ theme }) => theme.buttons.defaultColor};
  height: ${({ height }) => height || 'auto'};
  margin-inline: auto;
  padding-block: 1rem;
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      border-color: #999;
    `}
`;

export const BrandText = styled.div`
  color: ${({ theme }) => theme.color.brandSecondary};
`;

export const LightInputWrapper = styled.div`
  input {
    ${({ theme }) => css`
      background-color: ${theme.color.primaryText};
      color: ${theme.color.primaryBackground};
    `}
  }
`;

export const ModalFooter = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-start: 2rem;
  width: 100%;
`;

export const LoginChoiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
  margin-bottom: 15px;

  input {
    display: inline-block;
    padding: 0.5rem 0;
  }
  label {
    padding: 0.5rem 0.25rem;
  }
`;

export const SignInBodyContainer = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

export const StyledInput = styled(Input)`
  background-color: white;
  color: #5e6d70;
`;

export const StyledTitle = styled(AlertTitle)`
  padding: 20px;
  background-color: white;
  text-align: left;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
`;

export const StyledAlertContent = styled(AlertContent)`
  background-color: white;
  text-align: initial;
  color: #5e6d70;
`;

export const StyledError = styled.p`
  color: ${({ theme }) => theme.color.danger};
  border: 1px solid ${({ theme }) => theme.color.danger};
  padding: 0.5rem;
`;
