import React from 'react';
import PropTypes from 'prop-types';

const FriesIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      fill={fill}
      d="M382.8,145.446c12.619-0.854,22.237-11.713,21.38-24.379l-3.952-60.807l31.428-2.572l11,145.996 c0.952,12,11.047,21.19,22.904,21.19l1.714-0.047c12.619-0.953,22.143-12,21.238-24.62L475.655,31.211 c-0.476-6.095-3.238-11.713-7.905-15.665c-4.714-3.999-10.714-5.952-16.809-5.476l-76.903,6.284 c-12.476,1.003-21.857,11.858-21.047,24.335l5.429,83.331C359.277,136.687,370.134,146.257,382.8,145.446"
    />
    <path
      fill={fill}
      d="M526.188,229.621l1.714,0.049c11.905,0,21.952-9.143,22.857-21.19l8.143-109.474l31.38,2.526l-3.095,45.142
      c-0.809,12.617,8.714,23.571,21.38,24.428c12.619,0.854,23.571-8.716,24.428-21.382l4.571-67.666
      c0.809-12.474-8.524-23.379-21.047-24.428l-76.903-6.237c-6.143-0.477-12.143,1.43-16.762,5.429
      c-4.714,3.999-7.523,9.62-7.952,15.715l-9.905,132.472C504.045,217.67,513.568,228.67,526.188,229.621"
    />
    <path
      fill={fill}
      d="M718.416,470.774l-5.143-4.142c-9.048-6.621-22.571-11.811-38.761-11.716
      c-17.618-0.096-31.523,6.667-40.523,13.477l-18.142-235.901l31.332-0.381l12.952,155.285c0.905,10.759,9.238,19.426,19.999,20.807
      c10.666,1.383,20.952-4.952,24.476-15.142l74.665-211.566l37.856,14.285L718.416,470.774z M854.461,160.827l-80.998-30.569
      c-5.762-2.191-12.238-1.953-17.809,0.619c-5.524,2.619-9.857,7.429-12,13.189L696.94,276.779l-5.857-69.762
      c-0.952-12-11.095-21.144-23.047-21.048l-77.189,0.907c-6.286,0.093-12.381,2.808-16.666,7.475c-4.286,4.618-6.429,10.951-6,17.238
      L589.61,489.63l-1.381-0.857l-2.667-2.523l-2.524-3.334l-0.048-0.096c-1.524-2-6.666-9.809-15.714-16.188
      c-9.095-6.621-22.618-11.811-38.808-11.716c-18.619-0.096-33.142,7.429-42.047,14.619l-8.333,7.998l-1.428-186.423h0.048
      l31.285-0.334l1.286,122.666c0.143,12.666,10.619,22.856,23.19,22.713c12.714-0.192,22.856-10.524,22.714-23.239l-1.524-145.519
      c-0.143-12.715-10.476-22.856-23.142-22.713l-19.428,0.189l-34.761,0.334l-23,0.238c-12.666,0.096-22.809,10.428-22.666,23.094
      l1.571,208.186c-2.714-3.095-6.238-6.81-11-10.094c-9.048-6.621-22.619-11.811-38.809-11.716
      c-18.571-0.096-33.19,7.429-41.999,14.619c-7.761,6.333-12,12.332-13.666,14.857c-0.714,0.808-6.095,8.141-17.381,7.998
      c-7.761-0.093-10.809-1.95-13.19-3.618l-2.714-2.523l-2.571-3.334c-0.905-0.43-5.524-8.809-14.999-16.43l-93.141-283.47
      l34.618-10.332l66.427,206.425c3.333,10.286,13.428,16.903,24.237,15.761c10.762-1.096,19.286-9.571,20.428-20.333l18.381-170.235
      l31.714,2.619l-17.38,210.328c-1.048,12.666,8.381,23.716,20.999,24.762c12.571,1.046,23.714-8.333,24.809-20.952l19.19-233.233
      c0.524-5.999-1.428-12.093-5.333-16.761c-3.905-4.572-9.619-7.475-15.619-7.952l-76.903-6.333c-12.428-1-23.38,7.998-24.714,20.379
      l-9.19,85.093l-40.285-125.09c-3.81-11.904-16.428-18.525-28.38-14.953l-78.713,23.667c-6,1.811-10.905,5.856-13.809,11.332
      c-2.857,5.525-3.381,11.904-1.381,17.81l92.712,282.374c-12.666,4.572-22.714,13.762-27.714,23.286
      c-5.905,11.094-6.809,21.379-6.904,28.57c0,6.095,0.857,10.286,1.047,11.285l87.236,450.848
      C280.331,982.237,289.759,990,300.759,990h353.849c10.857,0,20.238-7.667,22.476-18.333l79.379-382.705
      c2.571-12.381-5.428-24.57-17.809-27.143c-12.428-2.572-24.571,5.429-27.142,17.857l-75.617,364.421H319.711l-83.713-432.372v-0.142
      l-0.095-2.334l1.143-6.429c0.905-0.95,0.095-1.285,4.619-2.665l1-0.142l5.238,2.953l6.905,8.283
      c2.857,4.906,21.476,27.762,54.57,27.047c18.238,0.049,32.666-6.903,41.571-13.855c9.095-6.906,13.333-13.573,14.381-15.192
      c0.857-1.046,6.381-8.713,17.095-8.428c7.762,0.096,10.333,1.907,12.524,3.43l5.905,6.952c2.857,4.952,21.523,27.808,54.57,27.093
      h0.143h0.048h0.096c18.095,0,32.428-6.952,41.285-13.855c9.048-6.906,13.333-13.573,14.381-15.192c0.809-1,6.381-8.713,17.095-8.428
      c7.761,0.096,10.333,1.907,12.571,3.43l5.809,6.952v0.046c2.905,4.906,21.523,27.762,54.618,27.047c7,0,13.476-1,19.19-2.665
      l0.429-0.142c9.095-2.668,16.523-6.856,21.904-11.047c9.095-6.952,13.428-13.619,14.428-15.192
      c0.905-1.096,6.476-8.713,17.095-8.428c7.714,0.096,10.333,1.907,12.571,3.43l5.905,6.999l-0.048-0.046
      c1.81,3.191,10.238,13.715,24.762,20.667l2.667,1.285l1.047,0.238c7.381,2.999,16,5.141,26.095,4.903
      c12.714,0,22.952-10.283,22.952-22.952c0-8.713-4.905-16.188-12.095-20.095l109.617-305.23
      C872.222,178.256,866.222,165.21,854.461,160.827"
    />
  </svg>
);

FriesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FriesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default FriesIcon;
