import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import settingsMessages from 'components/settingsMessages';
import { AddAddressButton, Address } from 'components/Address';
import SettingsPage from 'components/Pages/User/SettingsPage';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import MessageBlock from 'components/MessageBlock';
import globalMessages from 'components/globalMessages';
import { setDefaultAddress } from 'actions/user';
import {
  selectLoadingAddresses,
  selectErrorLoadingAddresses,
  selectAddressesList,
  selectDefaultAddressId,
} from 'selectors/user';

import { Container } from './styles';

const messages = defineMessages({
  errorMsg: {
    defaultMessage: 'We are sorry, there seems to be a problem loading your addresses.',
  },
});

const propTypes = {
  loadingAddresses: PropTypes.bool,
  errorLoadingAddresses: PropTypes.bool,
  addressesList: PropTypes.instanceOf(List),
  setDefaultAddress: PropTypes.func,
  defaultAddressId: PropTypes.string,
  addressesListSize: PropTypes.number,
};

const Addresses = ({
  loadingAddresses,
  errorLoadingAddresses,
  addressesList,
  setDefaultAddress,
  defaultAddressId,
  addressesListSize,
}) => {
  const [isSelectedId, setIsSelectedId] = useState('');

  const handleSetDefault = addressId => () => {
    setIsSelectedId(addressId);
    setDefaultAddress(addressId);
  };

  return (
    <SettingsPage titleMessage={settingsMessages.addressesTitle}>
      <Container>
        <AddAddressButton route="/user/add_address" />

        {loadingAddresses && (
          <Container className="u-flex u-flexJustifyCenter">
            <LoadingSpinner />
          </Container>
        )}

        {errorLoadingAddresses && (
          <MessageBlock
            header={<FormattedMessage {...globalMessages.error} />}
            body={<FormattedMessage {...messages.errorMsg} />}
            type="error"
          />
        )}

        {!loadingAddresses && !errorLoadingAddresses && addressesListSize > 0 && (
          <Container>
            {addressesList.map(address => {
              const addressId = address?.get('id');

              return (
                <Address
                  key={addressId}
                  address={address}
                  isSelected={isSelectedId === addressId}
                  isDefault={defaultAddressId === addressId}
                  onChange={handleSetDefault(addressId)}
                />
              );
            })}
          </Container>
        )}
      </Container>
    </SettingsPage>
  );
};

Addresses.propTypes = propTypes;

export default connect(
  state => ({
    loadingAddresses: selectLoadingAddresses(state),
    errorLoadingAddresses: selectErrorLoadingAddresses(state),
    addressesList: selectAddressesList(state),
    addressesListSize: selectAddressesList(state).size,
    defaultAddressId: selectDefaultAddressId(state),
  }),
  {
    setDefaultAddress,
  }
)(Addresses);
