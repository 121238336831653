import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: right;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;

  html[dir='rtl'] & {
    float: left;
  }
`;

export const NoFundsLeft = styled.span`
  color: red;
`;
