import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MastercardIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391 C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55 c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M719,250.5c0,112.4-91.6,203.8-204.2,203.8 c-47,0-90.3-15.9-124.8-42.6c-34.5,26.7-77.8,42.6-124.8,42.6C152.6,454.3,61,362.8,61,250.5c0-112.4,91.6-203.8,204.2-203.8 c47,0,90.3,15.9,124.8,42.6c34.5-26.7,77.8-42.6,124.8-42.6C627.4,46.7,719,138.1,719,250.5L719,250.5z M514.5,64.7 c-41.4,0-79.7,13.5-110.5,36.4c28,26,48.7,59.8,58.6,97.9h-18.9c-9.7-33.7-28.6-63.4-54-86.3c-25.3,22.9-44.2,52.6-53.9,86.3h-19 c9.9-38.1,30.6-71.9,58.7-97.9c-30.9-22.9-69.1-36.4-110.6-36.4C162.3,64.7,79,147.8,79,250.3c0,102.5,83.2,185.5,185.9,185.5 c41.4,0,79.7-13.5,110.6-36.4c-26.4-24.5-46.3-55.9-56.8-91.2h19.1c10.2,30.9,28.2,58.3,51.8,79.6c23.6-21.4,41.7-48.7,51.9-79.6 h19.1c-10.5,35.3-30.4,66.7-56.8,91.2c30.9,22.9,69.1,36.4,110.5,36.4c102.7,0,185.9-83.1,185.9-185.5 C700.4,147.8,617.2,64.7,514.5,64.7L514.5,64.7z"
      />
      <path
        className="icon"
        fill={fill}
        d="M189.7,205.7H160l-18.6,55.3l-0.8-55.3H113l-15,89.4h18l11.5-68.3 l1.6,68.3h13.1l24.5-68.7l-11,68.7h19.2L189.7,205.7L189.7,205.7z"
      />
      <path
        className="icon"
        fill={fill}
        d="M239.1,295.1h-15.9l0.4-7.5c-4.9,6-11.3,8.8-20.1,8.8 c-10.4,0-17.5-8.1-17.5-19.8c0-17.7,12.4-28,33.6-28c2.2,0,5,0.2,7.8,0.6c0.6-2.4,0.7-3.4,0.7-4.7c0-4.8-3.3-6.6-12.3-6.6 c-9.4-0.1-17.1,2.2-20.3,3.3c0.2-1.2,2.7-16.3,2.7-16.3c9.5-2.8,15.8-3.9,22.9-3.9c16.5,0,25.2,7.4,25.2,21.3 c0,3.7-0.6,8.4-1.5,14.4C243.2,267,239.6,289.5,239.1,295.1L239.1,295.1z M225.7,263c-2-0.2-2.9-0.3-4.3-0.3 c-10.9,0-16.4,3.7-16.4,11c0,4.5,2.7,7.4,6.8,7.4C219.7,281.2,225.3,273.8,225.7,263L225.7,263z"
      />
      <path
        className="icon"
        fill={fill}
        d="M490.2,203.7c-25.4,0-43.2,22.3-43.2,54.3c0,23.7,12.9,38.6,33.5,38.6 c5.3,0,9.9-0.7,16.9-2.6l3.4-20.4c-6.3,3.1-11.6,4.6-16.3,4.6c-11.1,0-17.9-8.2-17.9-21.8c0-19.7,10-33.5,24.3-33.5 c5.3,0,10.3,1.4,17.1,4.8l3.1-19.4C508.5,207.3,498.6,203.7,490.2,203.7L490.2,203.7z"
      />
      <path
        className="icon"
        fill={fill}
        d="M559.2,295.1h-15.9l0.4-7.5c-4.9,6-11.3,8.8-20.1,8.8 c-10.4,0-17.5-8.1-17.5-19.8c0-17.7,12.4-28,33.7-28c2.2,0,5,0.2,7.8,0.6c0.6-2.4,0.7-3.4,0.7-4.7c0-4.8-3.3-6.6-12.3-6.6 c-9.4-0.1-17.1,2.2-20.3,3.3c0.2-1.2,2.7-16.3,2.7-16.3c9.6-2.8,15.9-3.9,22.9-3.9c16.5,0,25.2,7.4,25.2,21.3 c0,3.7-0.6,8.4-1.5,14.4C563.2,267,559.7,289.5,559.2,295.1L559.2,295.1z M545.7,263c-2-0.2-2.9-0.3-4.3-0.3 c-10.9,0-16.4,3.7-16.4,11c0,4.5,2.7,7.4,6.9,7.4C539.7,281.2,545.4,273.8,545.7,263L545.7,263z"
      />
      <path
        className="icon"
        fill={fill}
        d="M333.5,278.2c-1.3,0.5-3,0.7-5.2,0.7c-4.6,0-6.6-1.6-6.6-5.4 c0-2.3,3.2-19.5,5.4-33.3h9.8l2.6-17.5h-9.8l2.2-11H313c0,0-7.2,42.8-8.3,49.6c-1.2,7.7-2.8,16.2-2.7,19.4c0,10.4,5.3,16,15.1,16 c4.4,0,8.5-0.7,13.8-2.4L333.5,278.2L333.5,278.2z"
      />
      <path
        className="icon"
        fill={fill}
        d="M392.1,293.4c-6.6,2-12.9,2.9-19.6,2.9c-21.4,0-32.5-11.2-32.5-32.5 c0-24.9,14.2-43.2,33.4-43.2c15.7,0,25.8,10.2,25.8,26.3c0,5.3-0.7,10.5-2.4,17.9h-37.9c-1.3,10.5,5.5,14.9,16.6,14.9 c6.8,0,13-1.4,19.8-4.6L392.1,293.4L392.1,293.4z M382.1,250.7c0.1-1.5,2-13-8.9-13c-6.1,0-10.4,4.7-12.2,13H382.1L382.1,250.7z"
      />
      <path
        className="icon"
        fill={fill}
        d="M254.3,245.3c0,9.2,4.4,15.5,14.6,20.3c7.8,3.7,9,4.7,9,8 c0,4.5-3.4,6.5-11,6.5c-5.7,0-11-0.9-17.2-2.8c0,0-2.5,16-2.6,16.8c4.4,0.9,8.3,1.8,20,2.2c20.2,0,29.6-7.7,29.6-24.3 c0-10-3.9-15.8-13.5-20.3c-8-3.7-9-4.5-9-7.9c0-3.9,3.2-5.9,9.4-5.9c3.7,0,8.9,0.4,13.8,1.1l2.7-16.9c-5-0.8-12.5-1.4-16.9-1.4 C261.7,220.7,254.2,231.9,254.3,245.3L254.3,245.3z"
      />
      <path
        className="icon"
        fill={fill}
        d="M667.1,295.1h-18.1l0.9-6.8c-5.3,5.6-10.7,8-17.7,8 c-13.9,0-23.1-12-23.1-30.2c0-24.2,14.3-44.6,31.2-44.6c7.4,0,13.1,3,18.3,9.9l4.2-25.8h18.9L667.1,295.1L667.1,295.1z M638.8,278.5 c8.9,0,15.2-10.1,15.2-24.5c0-9.3-3.6-14.3-10.2-14.3c-8.7,0-14.9,10.1-14.9,24.4C629,273.7,632.3,278.5,638.8,278.5z"
      />
      <path
        className="icon"
        fill={fill}
        d="M579.9,222.5c-2.4,22.5-6.7,45.3-10,67.8l-0.9,4.9h19.2 c6.9-44.5,8.5-53.2,19.3-52.1c1.7-9.1,4.9-17.1,7.3-21.1c-8-1.7-12.5,2.9-18.4,11.5c0.5-3.7,1.3-7.3,1.1-11H579.9L579.9,222.5z"
      />
      <path
        className="icon"
        fill={fill}
        d="M411.9,222.5c-2.4,22.5-6.7,45.3-10,67.8l-0.9,4.9h19.2 c6.9-44.5,8.5-53.2,19.3-52.1c1.7-9.1,4.9-17.1,7.3-21.1c-8-1.7-12.5,2.9-18.4,11.5c0.5-3.7,1.3-7.3,1.1-11H411.9L411.9,222.5z"
      />
    </g>
  </svg>
);

MastercardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MastercardIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default MastercardIcon;
