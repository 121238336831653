import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import DateModifier from 'components/DateModifier';
import TimeModifier from 'components/TimeModifier';
import NumberModifier from 'components/NumberModifier';
import OptionModifier from '../OptionModifier';

const Modifier = ({
  modifier,
  schedule,
  updateValues,
  values,
  hasError,
  taxCategories,
  showModifierInfo,
  browseOnly,
  accordionOption,
  errors,
}) => {
  const name = modifier.get('display_name');

  switch (modifier.get('type')) {
    case 'number':
      return <NumberModifier label={name} updateValues={updateValues} defaultValue={values?.[0]?.value} />;

    case 'date': {
      const date = values?.[0]?.value ? new Date(values?.[0]?.value) : null;
      return <DateModifier label={name} updateValues={updateValues} date={date} />;
    }

    case 'time':
      return (
        <TimeModifier
          label={name}
          schedule={schedule}
          updateValues={updateValues}
          defaultValue={values?.[0]?.value}
        />
      );

    default: {
      return (
        <OptionModifier
          values={values}
          options={modifier
            .get('values')
            .sortBy(group => group.get('sort_order'))
            .toJS()}
          name={name}
          multiple={modifier.get('multiple')}
          minimum={modifier.get('minimum')}
          maximum={modifier.get('maximum')}
          required={modifier.get('required')}
          displayType={modifier.get('display_type')}
          updateValues={updateValues}
          showModifierInfo={showModifierInfo}
          hasError={hasError}
          taxCategories={taxCategories}
          browseOnly={browseOnly}
          accordionOption={accordionOption}
          errors={errors}
        />
      );
    }
  }
};

Modifier.propTypes = {
  modifier: PropTypes.instanceOf(Map),
  schedule: PropTypes.instanceOf(Map),
  updateValues: PropTypes.func.isRequired,
  values: PropTypes.array,
  hasError: PropTypes.bool,
  taxCategories: PropTypes.instanceOf(List),
  showModifierInfo: PropTypes.func,
  browseOnly: PropTypes.bool,
  accordionOption: PropTypes.bool,
  errors: PropTypes.array,
};

Modifier.defaultProps = {
  accordionOption: false,
};

export default Modifier;
