import { getAddresses } from 'actions/user';
import { LOGIN, LOGOUT, ADD_FAVOURITE, REMOVE_FAVOURITE } from 'actions/user/constants';
import { postBroadcastMessage } from 'middleware/broadcastChannelMiddleware';
import { BC_LOGOUT } from 'middleware/broadcastChannelMiddleware/constants';
import fetchHelper from 'utils/fetchHelper';
import { isLoggedIn, selectAddressesList } from 'selectors/user';

const timeouts = {};

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const result = next(action);

    switch (action.type) {
      case '@@CLIENT_INIT':
      case LOGIN: {
        const state = getState();

        if (isLoggedIn(state) && selectAddressesList(state)?.size === 0) {
          dispatch(getAddresses());
        }

        return;
      }

      case LOGOUT: {
        if (!action.broadcastedEvent) postBroadcastMessage({ type: BC_LOGOUT });
        return;
      }

      case ADD_FAVOURITE: {
        const productId = action.product.get('id');

        timeouts[productId] = setTimeout(
          () =>
            fetchHelper(
              '/api/users/favourites',
              'POST',
              {
                product_id: productId,
              },
              () => delete timeouts[productId],
              () => delete timeouts[productId]
            ),
          1500
        );

        break;
      }

      case REMOVE_FAVOURITE: {
        const productId = action.productId;

        if (timeouts[productId]) {
          clearTimeout(timeouts[productId]);
          delete timeouts[productId];

          break;
        } else {
          fetchHelper('/api/users/favourites', 'DELETE', {
            product_id: productId,
          });

          break;
        }
      }

      default:
        break;
    }

    return result;
  };
