import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SliderContainer, FillIndicator } from './styles';

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

const RangeSlider = ({ min, max, defaultValue, step, onChange }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = ({ target }) => onChange(Number(target.value));

  return (
    <SliderContainer>
      <input type="range" min={min} max={max} value={value} step={step} onChange={handleOnChange} />
      <FillIndicator value={value} />
    </SliderContainer>
  );
};

RangeSlider.propTypes = propTypes;

export default RangeSlider;
