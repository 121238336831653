/*
const services = [
  { id: 1, name: "Zero Beach", categories: [{ id: 1, name: "Food and Beverage", icon: 'f&b' }] },
  { id: 2, name: "The Pool", categories: [{ id: 1, name: "Food and Beverage", icon: 'f&b' }] },
  { id: 3, name: "Guest Services", categories: [{ id: 2, name: "Spa Services", icon: 'spa' }] },
  { id: 4, name: "The Lighthouse Spa", categories: [{ id: 3, name: "Guest Services", icon: 'guest' }] },
];
*/

export const filterServicesByCategory = (categoryId, services) =>
  services.filter(serviceHasCategory(categoryId));

const serviceHasCategory =
  categoryId =>
  ({ categories }) =>
    !!categories.find(category => category?.id === categoryId);

export const filterActiveCategoriesByServices = services => {
  const flatCats = services.reduce((acc, serv) => acc.concat(serv.categories), []).filter(cat => cat?.id);
  return flatCats.reduce((uniq, cat) => {
    if (uniq.map(({ id }) => id).indexOf(cat.id) > -1) return uniq;
    return [...uniq, cat];
  }, []);
};
