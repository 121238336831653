import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { generateMenuTypeMsg } from 'utils';
import { setMenuTypeId } from 'actions/browse';
import ButtonContainer from 'components/PrimaryButton/Container';
import { kioskModeEnabled } from 'selectors/features';
import { selectMessageByLocale, selectVenueMenuTypes, selectVenueDeliveryPartners } from 'selectors/root';
import { selectGetStartedLink, selectGetStartedServiceId } from 'selectors/browse';
import { openDeliveryPartnersAlert } from 'actions/UI';
import globalMessages from 'components/globalMessages';

import { HomePagePrimaryButton } from 'assets/styles/sharedStyles';
import { trackEvent, setUserProperties } from '../../utils/tracking';

const messages = defineMessages({
  serviceNotAvailable: {
    defaultMessage: 'Service Currently Unavailable',
  },
});

const propTypes = {
  serviceId: PropTypes.func,
  kioskMode: PropTypes.bool,
  getLink: PropTypes.func,
  onClick: PropTypes.func,
  menuTypes: PropTypes.instanceOf(List),
  setMenuTypeId: PropTypes.func.isRequired,
  openDeliveryPartnersAlert: PropTypes.func,
  venueDeliveryPartners: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
  getStartedMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconsArray: PropTypes.instanceOf(Map),
};

const GetStarted = ({
  serviceId,
  kioskMode,
  menuTypes,
  setMenuTypeId,
  onClick,
  getLink,
  openDeliveryPartnersAlert,
  venueDeliveryPartners,
  getStartedMessageByLocale,
  iconsArray,
}) => {
  if (menuTypes.size >= 1) {
    const clickMethod = menuTypeId => () => {
      setMenuTypeId(menuTypeId);
      trackEvent('select_menu_type', {
        category: 'service',
        menu_type_id: menuTypeId,
      });
      if (serviceId(menuTypeId)) {
        setUserProperties({
          service: serviceId(menuTypeId),
        });
      }

      if (typeof onClick === 'function') onClick();
    };

    const showMenuTypes = menuTypes.size > 1;

    const getIcon = id => {
      if (iconsArray && iconsArray.size > 0 && iconsArray.get(id)) return iconsArray.get(id);
      return null;
    };

    return (
      <ButtonContainer containerClass={showMenuTypes}>
        {!showMenuTypes && (
          <HomePagePrimaryButton
            onClick={clickMethod(menuTypes.first()?.get('id'))}
            text={getStartedMessageByLocale || <FormattedMessage {...globalMessages.get_started} />}
            link={getLink()}
            icon={getIcon(menuTypes.first()?.get('id')?.toString())}
          />
        )}
        {showMenuTypes &&
          menuTypes.map(menuType => (
            <HomePagePrimaryButton
              key={menuType.get('id')}
              onClick={clickMethod(menuType.get('id'))}
              text={generateMenuTypeMsg(menuType.get('id'))}
              link={getLink(menuType.get('id'))}
              icon={getIcon(menuType.get('id')?.toString())}
            />
          ))}

        {!kioskMode && venueDeliveryPartners?.size > 0 && (
          <HomePagePrimaryButton
            dataCy="delivery-partners-button"
            test="it works"
            onClick={() => openDeliveryPartnersAlert()}
            text={<FormattedMessage {...globalMessages.delivery} />}
          />
        )}
      </ButtonContainer>
    );
  }

  return (
    <div>
      <FormattedMessage {...messages.serviceNotAvailable} />
    </div>
  );
};

GetStarted.propTypes = propTypes;

const mapStateToProps = state => ({
  iconsArray: state.getIn(['branding', 'menu_type_icon']),
  serviceId: menuTypeId => selectGetStartedServiceId(state, menuTypeId),
  menuTypes: selectVenueMenuTypes(state),
  getLink: menuTypeId => selectGetStartedLink(state, menuTypeId),
  venueDeliveryPartners: selectVenueDeliveryPartners(state),
  kioskMode: kioskModeEnabled(state),
  getStartedMessageByLocale: selectMessageByLocale(state, 'getting_started_button'),
});

export default connect(mapStateToProps, { setMenuTypeId, openDeliveryPartnersAlert })(GetStarted);
