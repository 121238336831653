import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'components/Autocomplete';

const propTypes = {
  value: PropTypes.string,
  autocompleteItems: PropTypes.array,
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const AutocompleteField = ({
  value,
  autocompleteItems,
  type,
  required,
  name,
  className,
  onChange,
  onBlur,
}) => (
  <Autocomplete
    value={value}
    suggestionsList={autocompleteItems}
    type={type}
    required={required}
    name={name}
    className={className}
    setValueOnChange={onChange}
    validateValueOnBlur={onBlur}
  />
);

AutocompleteField.propTypes = propTypes;

export default AutocompleteField;
