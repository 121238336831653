import React from 'react';
import PropTypes from 'prop-types';

// Icon
const KosherIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon kosherIcon"
        fill={fill}
        opacity={opacity}
        d="M640.8,224h71.4v313.6c0,54.5-6.1,97.9-18.4,129.9c-12.3,32.1-34.5,58.2-66.6,78.5	c-31.9,20-73.9,30-125.9,30c-50.5,0-91.9-8.8-124-26.3c-32.1-17.5-55.1-42.8-68.8-75.9c-13.7-33.3-20.6-78.7-20.6-136.2V224h71.4	v313.2c0,47.1,4.3,81.9,12.9,104.4c8.8,22.2,23.8,39.4,44.9,51.5c21.3,12.1,47.4,18.1,78,18.1c52.5,0,89.9-12,112.3-35.9	c22.3-23.9,33.5-70,33.5-138.1V224"
      />
      <path
        className="icon kosherIcon"
        fill={fill}
        opacity={opacity}
        d="M452.6-2.8C304.9,12.3,173.4,91.5,90.1,215.8c-30.7,46-59.4,114.4-70.3,168.2c-8.2,40.6-9.8,56.5-9.8,101.5	s1.5,60.5,9.8,101.5c10.7,53.1,39.5,121.7,70.3,168.2c92.2,137.8,245.8,220.7,409.5,220.7c185.1,0,355.6-106,438.6-272.3	c83.2-166.9,65.3-363.1-46.8-512.4C813.8,88,699.2,20,568.5-0.1C544.6-3.8,477.1-5.5,452.6-2.8z M540.9,43.2	c89.9,8.6,174.4,44.1,244.1,102.1c20.5,17.2,54,52.7,70.5,74.9c44.6,60,72.2,126.9,84.1,202.3c4.6,30.5,4.6,95.8-0.2,126.3	c-11.5,75.1-39.8,142.9-84.3,202.9c-19.5,26.1-63.4,69.9-89.5,89.5c-60,44.5-127.8,72.8-202.9,84.3c-30.5,4.8-95.8,4.8-126.3,0.2	c-123.2-19.2-228-83.7-300.5-184.9C76.3,657.1,47,547.3,57.1,444.2c8-84.1,37.8-159.4,90.6-229.7c14.8-19.7,60.9-65.9,80.7-80.7	c58.8-44.1,117.1-70.5,184.9-83.9C457.2,41.3,499.2,39,540.9,43.2z"
      />
    </g>
  </svg>
);

KosherIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

KosherIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default KosherIcon;
