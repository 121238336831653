import React from 'react';
import PropTypes from 'prop-types';

const SafariShareIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon SafariShareIcon"
        fill={fill}
        d="M176,990c-17.1,0-31-14.2-31-31.6V298.3c0-17.4,13.9-31.6,31-31.6h203.3c12,0,21.8,10,21.8,22.2s-9.8,22.2-21.8,22.2H199.7 c-6.1,0-11,5-11,11.2v611.9c0,6.2,4.9,11.2,11,11.2h600.6c6.1,0,11-5,11-11.2V322.4c0-6.2-4.9-11.2-11-11.2H619.5 c-12,0-21.8-10-21.8-22.2s9.8-22.2,21.8-22.2H824c17.1,0,31,14.2,31,31.6v660.1c0,17.4-13.9,31.6-31,31.6L176,990L176,990z"
      />
      <path
        className="icon SafariShareIcon"
        fill={fill}
        d="M499.4,637.4c-12.4,0-22.5-10.3-22.5-22.9V88.9l-80.5,82c-4.2,4.3-9.9,6.7-15.9,6.7s-11.7-2.4-15.9-6.7 c-8.8-8.9-8.8-23.5,0-32.4L480.7,20.2c4.2-6.4,11.2-10.2,18.7-10.2c0.1,0,0.2,0,0.4,0h0.3c6,0,11.7,2.4,15.9,6.7l119.5,121.7 c8.8,8.9,8.8,23.5,0,32.4c-4.2,4.3-9.9,6.7-15.9,6.7s-11.7-2.4-15.9-6.7L522,87.6v526.9C521.9,627.2,511.8,637.4,499.4,637.4z"
      />
    </g>
  </svg>
);

SafariShareIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SafariShareIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default SafariShareIcon;
