import React from 'react';
import PropTypes from 'prop-types';

// Icon
const OptionsIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon OptionsIcon"
        fill={fill}
        d="M31.616,50.253c1.423,1.423,2.134,3.112,2.134,5.048v14.38c0,1.952-0.711,3.636-2.134,5.048	c-1.422,1.412-3.112,2.139-5.049,2.139h-14.38c-1.952,0-3.637-0.717-5.049-2.139C5.722,73.307,5,71.633,5,69.682v-14.38	c0-1.952,0.711-3.636,2.139-5.048c1.423-1.412,3.107-2.134,5.049-2.134h14.38C28.504,48.119,30.194,48.831,31.616,50.253z	 M31.616,107.753c1.423,1.422,2.134,3.112,2.134,5.048v14.38c0,1.946-0.711,3.637-2.134,5.049c-1.422,1.433-3.102,2.139-5.049,2.139	h-14.38c-1.952,0-3.637-0.706-5.049-2.139C5.711,130.818,5,129.128,5,127.182v-14.38c0-1.952,0.711-3.636,2.139-5.048	c1.423-1.412,3.107-2.134,5.049-2.134h14.38C28.504,105.619,30.194,106.33,31.616,107.753z M31.616,165.259	c1.423,1.412,2.134,3.102,2.134,5.048v14.386c0,1.957-0.711,3.637-2.134,5.048c-1.422,1.444-3.102,2.141-5.049,2.141h-14.38	c-1.952,0-3.637-0.696-5.049-2.141C5.711,188.329,5,186.649,5,184.692v-14.386c0-1.946,0.711-3.636,2.139-5.048	c1.423-1.434,3.107-2.129,5.049-2.129h14.38C28.504,163.119,30.194,163.825,31.616,165.259z M71.821,67.548	c-1.433-1.428-2.134-3.113-2.134-5.048c0-1.941,0.701-3.637,2.134-5.054c1.412-1.423,3.102-2.134,5.049-2.134h150.932	c1.947,0,3.637,0.711,5.048,2.134c1.434,1.428,2.141,3.112,2.141,5.054c0,1.936-0.707,3.637-2.141,5.048	c-1.411,1.412-3.101,2.134-5.048,2.134H76.87C74.934,69.682,73.233,68.971,71.821,67.548z M232.872,114.935	c1.411,1.428,2.128,3.112,2.128,5.054c0,1.936-0.717,3.631-2.128,5.053c-1.434,1.412-3.103,2.129-5.049,2.129H76.87	c-1.947,0-3.637-0.717-5.049-2.129c-1.433-1.433-2.134-3.117-2.134-5.053c0-1.941,0.701-3.636,2.134-5.054	c1.412-1.422,3.102-2.134,5.049-2.134h150.932C229.749,112.801,231.438,113.513,232.872,114.935z M232.872,172.445	c1.411,1.412,2.128,3.103,2.128,5.049c0,1.947-0.717,3.638-2.128,5.049c-1.434,1.434-3.103,2.139-5.049,2.139H76.87	c-1.947,0-3.637-0.705-5.049-2.139c-1.433-1.411-2.134-3.102-2.134-5.049c0-1.946,0.701-3.637,2.134-5.049	c1.412-1.433,3.102-2.139,5.049-2.139h150.932C229.749,170.307,231.438,171.013,232.872,172.445z"
      />
    </g>
  </svg>
);

OptionsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OptionsIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default OptionsIcon;
