import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import settingsMessages from 'components/settingsMessages';
import SettingsLink from './settingsLink';
import { AccountSettingsContainer } from './styles';

const messages = defineMessages({
  userNameTitle: {
    defaultMessage: 'Name',
  },
  userNameDescription: {
    defaultMessage: 'Edit your account name.',
  },
  emailAddressTitle: {
    defaultMessage: 'Email Address',
  },
  emailAddressDescription: {
    defaultMessage: 'Edit your account email address.',
  },
  phoneNumberTitle: {
    defaultMessage: 'Phone Number',
  },
  phoneNumberDescription: {
    defaultMessage: 'Add/Edit your phone number to aid checkout automation.',
  },
  changePasswordTitle: {
    defaultMessage: 'Change Password',
  },
  changePasswordDescription: {
    defaultMessage: 'Edit your account password.',
  },
  addressesDescription: {
    defaultMessage: 'Add/Edit your delivery addresses.',
  },
  changeNotificationSettingsTitle: {
    defaultMessage: 'Notifications',
  },
  changeNotificationSettingsDescription: {
    defaultMessage: 'Set your promotional email preferences.',
  },
  privacySettingsTitle: {
    defaultMessage: 'Privacy Settings',
  },
  privacySettingsDescription: {
    defaultMessage: 'Set your Privacy Policy preferences.',
  },
  languageSettingsTitle: {
    defaultMessage: 'Language Settings',
  },
  languageSettingsDescription: {
    defaultMessage: 'Set your default language.',
  },
});

const AccountSettings = () => (
  <AccountSettingsContainer>
    <SettingsLink
      path="/user/edit_username"
      title={<FormattedMessage {...messages.userNameTitle} />}
      description={<FormattedMessage {...messages.userNameDescription} />}
    />
    <SettingsLink
      path="/user/edit_email"
      title={<FormattedMessage {...messages.emailAddressTitle} />}
      description={<FormattedMessage {...messages.emailAddressDescription} />}
    />
    <SettingsLink
      path="/user/edit_password"
      title={<FormattedMessage {...messages.changePasswordTitle} />}
      description={<FormattedMessage {...messages.changePasswordDescription} />}
    />
    <SettingsLink
      path="/user/addresses"
      title={<FormattedMessage {...settingsMessages.addressesTitle} />}
      description={<FormattedMessage {...messages.addressesDescription} />}
    />
    <SettingsLink
      path="/user/edit_phone_number"
      title={<FormattedMessage {...messages.phoneNumberTitle} />}
      description={<FormattedMessage {...messages.phoneNumberDescription} />}
    />
    <SettingsLink
      path="/user/edit_notification_settings"
      title={<FormattedMessage {...messages.changeNotificationSettingsTitle} />}
      description={<FormattedMessage {...messages.changeNotificationSettingsDescription} />}
    />
    <SettingsLink
      path="/user/privacy"
      title={<FormattedMessage {...messages.privacySettingsTitle} />}
      description={<FormattedMessage {...messages.privacySettingsDescription} />}
    />
    <SettingsLink
      path="/language"
      title={<FormattedMessage {...messages.languageSettingsTitle} />}
      description={<FormattedMessage {...messages.languageSettingsDescription} />}
    />
  </AccountSettingsContainer>
);

export default AccountSettings;
