import styled from 'styled-components';
import { StyledInput } from 'assets/styles/sharedStyles';
import GeoButton from 'components/GeoButton';

export const Container = styled.div`
  position: relative;
  display: block;
  max-width: 500px;
  margin: 0 auto 20px;
`;

export const GeoButtonStyled = styled(GeoButton)`
  border-radius: 5px;
  margin: 10px auto 0;
  max-width: 70%;
`;
export const MenuStyle = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.seven};
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow: scroll;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  background: ${({ theme }) => theme.color.primaryBackground};
  color: ${({ theme }) => theme.color.text};
  border-radius: 5px;
  margin-top: 1px;
`;

export const Item = styled.div`
  padding: 10px 4px;
  cursor: pointer;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  }
`;

export const PostcodeInput = styled.input`
  ${StyledInput}
  padding-right: 55px;

  html[dir='rtl'] & {
    padding-left: 55px;
    padding-right: 9px;
  }
`;

export const ClearButtonStyled = styled.button`
  z-index: ${({ theme }) => theme.zIndex.two};
  color: ${({ theme }) => theme.color.text};
  position: absolute;
  right: 55px;
  top: 11px;

  &:disabled {
    color: ${({ theme }) => theme.color.text};
    background-color: transparent;
  }

  html[dir='rtl'] & {
    left: 55px;
    right: initial;
  }
`;

export const ButtonStyled = styled.button`
  display: inline-block;
  padding: 9px;
  background-color: ${({ theme }) => theme.buttons.defaultColor};
  z-index: ${({ theme }) => theme.zIndex.two};
  color: white;
  border-radius: 10px;
  position: absolute;
  right: 2px;
  top: 2px;

  html[dir='rtl'] & {
    left: 2px;
    right: initial;
  }
`;
