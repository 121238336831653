import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { isLoggedIn } from 'selectors/user';
import { addPaymentSource } from 'actions/user';
import Page from 'components/Pages/container';
import Loading from 'components/Loading';
import MessageBlock from 'components/MessageBlock';
import tabMessages from 'components/tabMessages';
import globalMessages from 'components/globalMessages';
import { CheckoutIcon } from 'components/Icons';

const AddPaymentSource = ({ userIsLoggedIn, addPaymentSource, location, router }) => {
  const [loading, setLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener('message', iframeCallback);

    if (userIsLoggedIn) {
      addPaymentSource(res => {
        setLoading(false);
        setIframeUrl(res?.iframeUrl);
        setError(!res?.iframeUrl);
      });
    }

    return () => {
      window.removeEventListener('message', iframeCallback);
    };
  }, []);

  const iframeCallback = event => {
    if (['paymentSourceAdded', 'paymentSourceCancelled'].indexOf(event?.data?.method) !== -1) {
      if (location.state?.prevPath) return router.push(location.state.prevPath);
      router.goBack();
    }
  };

  return (
    <Page
      settingsPage={true}
      titleMessage={tabMessages.addPaymentMethod}
      redirectCondition={!userIsLoggedIn}
      hideSidebar={true}
      Icon={CheckoutIcon}
      backButton={
        new Map({
          href: location.state?.prevPath,
          text: <FormattedMessage {...globalMessages.back} />,
        })
      }
    >
      {loading && <Loading />}
      {!loading && error && (
        <MessageBlock type="error" body={<FormattedMessage {...tabMessages.addPaymentMethodError} />} />
      )}
      {!loading && !error && (
        <Iframe url={iframeUrl} width="100%" height="400px" display="initial" position="relative" />
      )}
    </Page>
  );
};

AddPaymentSource.propTypes = {
  userIsLoggedIn: PropTypes.bool.isRequired,
  addPaymentSource: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      prevPath: PropTypes.string.isRequired,
    }).isRequired,
  }),
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userIsLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  addPaymentSource: callback => dispatch(addPaymentSource(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPaymentSource));
