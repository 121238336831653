import React from 'react';
import PropTypes from 'prop-types';

// Icon
const DiscoverIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M55,30h670c13.8,0,25,11.2,25,25v219.9c0,0-75.6,51.1-163.2,86.4 C447.7,425.6,246.2,471,232.4,471H55c-13.8,0-25-11.2-25-25V55C30,41.2,41.2,30,55,30z M0,55v391c0,30.4,24.6,55,55,55h670 c30.4,0,55-24.6,55-55V55c0-30.4-24.6-55-55-55H55C24.6,0,0,24.6,0,55L0,55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M67.4,164.4v99.1h30c15.9,0,27.5-3.5,37.6-11.4 c12-9.4,19.1-23.5,19.1-38.1c0-29.3-23.2-49.6-56.5-49.6H67.4z M121.6,238.9c-6.5,5.5-14.8,7.9-28.1,7.9h-5.5v-65.6h5.5 c13.3,0,21.3,2.2,28.1,8c7.1,6,11.4,15.2,11.4,24.7C132.9,223.4,128.7,232.9,121.6,238.9L121.6,238.9z"
      />
      <path className="icon" fill={fill} d="M163.5,263.5h20.5v-99.1h-20.5V263.5L163.5,263.5z" />
      <path
        className="icon"
        fill={fill}
        d="M234.3,202.2c-12.3-4.3-15.9-7.1-15.9-12.5c0-6.2,6.4-11,15.3-11 c6.2,0,11.2,2.4,16.6,8l10.7-13.2c-8.8-7.3-19.4-11-30.9-11c-18.6,0-32.8,12.2-32.8,28.4c0,13.7,6.6,20.6,25.9,27.2 c8,2.7,12.1,4.5,14.2,5.6c4.1,2.5,6.2,6.1,6.2,10.3c0,8-6.8,14-15.9,14c-9.8,0-17.7-4.6-22.4-13.2l-13.3,12 c9.5,13.1,20.8,18.9,36.5,18.9c21.3,0,36.3-13.4,36.3-32.6C264.6,217.4,257.7,210.2,234.3,202.2"
      />
      <path
        className="icon"
        fill={fill}
        d="M271.1,213.8c0,29.1,24.3,51.7,55.6,51.7c8.8,0,16.4-1.6,25.8-5.8V237 c-8.2,7.7-15.5,10.9-24.8,10.9c-20.7,0-35.4-14.1-35.4-34.2c0-19,15.2-34.1,34.4-34.1c9.8,0,17.2,3.3,25.8,11.2V168 c-9-4.3-16.4-6.1-25.3-6.1C296,161.9,271.1,184.9,271.1,213.8"
      />
      <path
        className="icon"
        fill={fill}
        d="M515.3,230.8l-28.1-66.6h-22.4l44.7,101.7h11.1L566,164.2h-22.3 L515.3,230.8"
      />
      <path
        className="icon"
        fill={fill}
        d="M575.3,263.5h58.3v-16.8h-37.7V220h36.3v-16.8h-36.3v-22h37.7v-16.8 h-58.3V263.5"
      />
      <path
        className="icon"
        fill={fill}
        d="M714.9,193.7c0-18.6-13.6-29.3-37.3-29.3h-30.5v99.1h20.5v-39.8 h2.7l28.4,39.8H724l-33.1-41.8C706.3,218.8,714.9,208.9,714.9,193.7L714.9,193.7z M673.7,210h-6v-30h6.3c12.8,0,19.7,5,19.7,14.7 C693.7,204.7,686.8,210,673.7,210L673.7,210z"
      />
      <path
        className="icon"
        fill={fill}
        d="M359.1,214C359.1,214,359.1,214,359.1,214c0-29.2,25.1-52.7,56-52.7	s56,23.6,56,52.7v0c0,29.1-25.1,52.7-56,52.7S359.1,243.1,359.1,214"
      />
    </g>
  </svg>
);

DiscoverIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

DiscoverIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default DiscoverIcon;
