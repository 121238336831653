import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { selectLogoUrl, selectOrganisationName } from 'selectors/root';
import { isDarkModeEnabled } from 'selectors/features';
import { messages } from './messages';

import {
  Buttons,
  Container,
  Content,
  HeaderMessage,
  SignInOrOutButton,
  StyledLogo,
  TitleInContent,
} from './styles';

const AuraSplashPage = ({ setPage }) => {
  const logoUrl = useSelector(selectLogoUrl);
  const companyName = useSelector(selectOrganisationName);
  const darkMode = useSelector(isDarkModeEnabled);
  return (
    <Container>
      <TitleInContent>
        <img src={logoUrl} alt={`${companyName} Logo`} width="120rem" />
      </TitleInContent>
      <HeaderMessage>
        <FormattedMessage
          {...messages.headerTitle}
          values={{ loyaltyName: <StyledLogo fullColor={!darkMode} /> }}
        />
      </HeaderMessage>
      <Content>
        <FormattedMessage {...messages.splashBody} />
      </Content>
      <Buttons>
        <SignInOrOutButton
          label={<FormattedMessage {...messages.signInAura} />}
          onClick={() => setPage('signIn')}
        />
      </Buttons>
    </Container>
  );
};

AuraSplashPage.propTypes = { setPage: PropTypes.func };

export default AuraSplashPage;
