import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import ChevronBack from 'components/Icons/ChevronBack';
import ServiceCategory from 'components/ServiceCategory';
import { filterActiveCategoriesByServices } from 'components/Pages/Browse/filterCategoryServices';
import { selectMenuTypeId, selectServicesForMenuType } from 'selectors/browse';

import { Container, StyledLink, Title, ServiceCategoriesWrap } from './styles';

const messages = defineMessages({
  selectAService: {
    defaultMessage: 'Select a service',
  },
});

type Props = {
  categories: {
    sort_order?: number;
    id?: number;
    name: string;
  }[];
};

export const ServiceCategorySelection = ({ categories }: Props) => (
  <Container>
    <StyledLink to="/">
      <ChevronBack />
    </StyledLink>

    <Title className="primaryColor">
      <FormattedMessage {...messages.selectAService} />
    </Title>

    <ServiceCategoriesWrap>
      {categories
        .sort((a, b) => {
          if (a.sort_order === b.sort_order) return 0;
          return a.sort_order - b.sort_order;
        })
        .map(category => (
          <ServiceCategory key={category.id} link={`/service-categories/${category.id}`} {...category} />
        ))}
    </ServiceCategoriesWrap>
  </Container>
);

const mapStateToProps = state => {
  const menuTypeId = selectMenuTypeId(state);
  const services = selectServicesForMenuType(state, menuTypeId);
  const serviceCategories = filterActiveCategoriesByServices(services);

  return { categories: serviceCategories };
};

export default connect(mapStateToProps)(ServiceCategorySelection);
