import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QuantityButtons from 'components/QuantityButtons';

import { getProductStockQuantity } from 'selectors/browse';

const ProductQuantity = ({ productId, quantity, onChange, stockQuantity }) => (
  <QuantityButtons
    productId={productId}
    quantity={quantity}
    onChange={onChange}
    maxQuantity={stockQuantity}
  />
);

ProductQuantity.defaultProps = {
  stockQuantity: null,
};

ProductQuantity.propTypes = {
  productId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  quantity: PropTypes.node.isRequired,
  stockQuantity: PropTypes.node,
};

const mapStateToProps = (state, { productId }) => ({
  stockQuantity: getProductStockQuantity(state, productId),
});

export default connect(mapStateToProps)(ProductQuantity);
