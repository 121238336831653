import React from 'react';
import PropTypes from 'prop-types';

const QRCodeIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M10.2,429.6h420V9.6h-420V429.6z M80.2,79.6h280v280h-280V79.6z M570.2,9.6v420h420V9.6H570.2z
        M920.2,359.6h-280v-280h280V359.6z M10.2,989.6h420v-420h-420V989.6z M80.2,639.6h280v280h-280V639.6z M150.2,149.6h139.9v139.9
       H150.2V149.6z M850.2,289.5H710.2V149.6h139.9V289.5z M150.2,709.6h139.9v139.9H150.2V709.6z M920.2,569.7h70v280H710.2v-70h-70v210
       h-70v-420h210v70h139.9V569.7z M920.2,919.7h70v70h-70V919.7z M780.2,919.7h70v70h-70V919.7z"
      />
    </g>
  </svg>
);

QRCodeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

QRCodeIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default QRCodeIcon;
