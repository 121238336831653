export const isExternalLink = path =>
  !!(
    path &&
    (path.indexOf('https://') > -1 || path.indexOf('http://') > -1) &&
    path.indexOf('wi-q.com') === -1
  );

export const canUseBrowserHistory = path =>
  !!(path && path.indexOf('https://') === -1 && path.indexOf('http://') === -1);

export const makeUrl = (path, config = __config__) => config.APP_BASE_URL.replace('{{route}}', path);
