import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 475px) {
    flex-direction: column;
  }
`;

export const Text = styled.div`
  flex: 1;
  text-align: left;
  margin: 10px 0;

  @media (max-width: 475px) {
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  margin: 0 20px;
`;

export const CouponDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
