import React from 'react';
import PropTypes from 'prop-types';

const SpaIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 100 70"
  >
    <path
      className="icon spaIcon"
      fill={fill}
      opacity={opacity}
      d="M96.0003 33.3C95.5003 33.2 88.2003 31.2 79.5003 32.1C80.4003 23.4 78.4003 16 78.3003 15.6C78.0003 14.5 76.9003 13.8 75.8003 14.1C75.3003 14.2 68.0003 16.2 60.9003 21.3C57.3003 13.3 51.9003 7.9 51.6003 7.6C50.8003 6.8 49.5003 6.8 48.7003 7.6C48.4003 7.9 43.0003 13.3 39.4003 21.3C32.3003 16.2 24.9003 14.2 24.5003 14.1C23.4003 13.8 22.3003 14.5 22.0003 15.6C21.9003 16.1 19.9003 23.4 20.8003 32.1C11.8003 31.2 4.50034 33.2 4.00034 33.3C2.90034 33.6 2.20034 34.7 2.50034 35.8C2.70034 36.5 7.20034 53 20.3003 60.5C26.3003 64 33.0003 65 38.6003 65C44.1003 65 48.5003 64.1 50.0003 63.7C51.5003 64.1 55.8003 65 61.4003 65C67.0003 65 73.7003 64 79.7003 60.6C92.8003 53 97.2003 36.6 97.4003 35.9C97.7003 34.7 97.1003 33.6 96.0003 33.3ZM74.7003 18.8C75.2003 21.6 75.9003 27 75.2003 32.8C72.0003 33.5 68.8003 34.6 65.8003 36.3C65.2003 36.7 64.6003 37.1 64.0003 37.5C64.1003 36.8 64.1003 36.1 64.1003 35.3C64.1003 31.8 63.5003 28.4 62.5003 25.4C67.0003 21.8 71.9003 19.8 74.7003 18.8ZM50.0003 12.2C53.1003 15.8 59.8003 24.9 59.8003 35.3C59.8003 37.4 59.5003 39.5 59.0003 41.5C54.5003 46 51.7003 51.4 50.0003 55.3C48.3003 51.3 45.4003 46 40.9003 41.4C40.4003 39.4 40.1003 37.3 40.1003 35.2C40.2003 24.9 46.9003 15.8 50.0003 12.2ZM25.3003 18.8C28.0003 19.8 33.0003 21.8 37.6003 25.4C36.6003 28.5 36.0003 31.8 36.0003 35.3C36.0003 36 36.0003 36.7 36.1003 37.5C35.5003 37.1 34.9003 36.7 34.3003 36.3C31.3003 34.5 28.0003 33.5 24.9003 32.8C24.1003 27 24.8003 21.7 25.3003 18.8ZM22.4003 56.9C13.4003 51.7 8.90034 41.3 7.30034 36.8C11.9003 35.9 23.2003 34.6 32.2003 39.9C41.2003 45.1 45.7003 55.5 47.3003 60C42.7003 60.8 31.4003 62.2 22.4003 56.9ZM77.6003 56.9C68.6003 62.1 57.3003 60.8 52.7003 60C54.3003 55.6 58.8003 45.2 67.8003 39.9C76.8003 34.7 88.1003 36 92.7003 36.8C91.2003 41.3 86.6003 51.7 77.6003 56.9Z"
    />
  </svg>
);

SpaIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

SpaIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default SpaIcon;
