import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChevronForward from 'components/Icons/ChevronForward';
import TimerIcon from 'components/Icons/TimerIcon';
import NextAvailableMessage from 'components/NextAvailableMessage';
import { isServiceActiveById, getMenuNextActiveDetails } from 'selectors/browse';
import image from 'utils/image';
import addCssPrefixTo from 'utils/addCssPrefixTo';

import { Item, StyledLink, Details, Name, MenuIndicator, IndicatorIcon, ActiveIndicator } from './styles';

const indicatorIconProps = { fill: '#bbbbbb', width: '21px', height: '21px' };

const propTypes = {
  serviceId: PropTypes.string,
  menuId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionId: PropTypes.number,
  name: PropTypes.string,
  imagePath: PropTypes.string,
  isServiceActive: PropTypes.bool,
  active: PropTypes.bool,
  isFiltered: PropTypes.bool,
  listView: PropTypes.bool,
  menuActiveTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const BrowseLink = ({
  serviceId,
  menuId,
  sectionId,
  name,
  imagePath,
  isServiceActive,
  active,
  isFiltered,
  menuActiveTime,
  listView,
}) => (
  <Item
    hasImage={!!imagePath}
    listView={listView}
    filtered={isFiltered}
    style={imagePath && { backgroundImage: `url(${image(imagePath, { height: 700 })})` }}
    className={sectionId ? addCssPrefixTo('SECTION_LINK') : addCssPrefixTo('MENU_LINK')}
  >
    <StyledLink
      image={imagePath ? 1 : 0}
      to={{
        pathname: sectionId
          ? `/service/${serviceId}/menu/${menuId}/section/${sectionId}`
          : `/service/${serviceId}/menu/${menuId}`,
        state: { transition: 'Forward' },
      }}
    >
      <Details hasImage={!!imagePath} className={`${imagePath ? addCssPrefixTo('HAS_IMAGE_DETAILS') : ''}`}>
        <span>
          <Name>{name}</Name>
        </span>
      </Details>
      <MenuIndicator hasImage={!!imagePath}>
        <IndicatorIcon className={`${imagePath ? addCssPrefixTo('HAS_IMAGE_INDICATOR_ICON') : ''}`}>
          {isServiceActive && active ? (
            <ChevronForward {...indicatorIconProps} />
          ) : (
            <TimerIcon {...indicatorIconProps} />
          )}
        </IndicatorIcon>
      </MenuIndicator>
    </StyledLink>
    {!active && menuActiveTime && !sectionId && (
      <ActiveIndicator className="primaryBackground primaryColor">
        <NextAvailableMessage
          nextAvailableTime={{ type: 'menu', time: menuActiveTime.time, date: menuActiveTime.date }}
        />
      </ActiveIndicator>
    )}
  </Item>
);

BrowseLink.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
  isServiceActive: isServiceActiveById(state, ownProps.serviceId),
  menuActiveTime: getMenuNextActiveDetails(state, ownProps.menuId, ownProps.serviceId),
});

export default connect(mapStateToProps)(BrowseLink);
