import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Form from 'components/Form';
import fieldTypes from 'components/Form/fieldTypes';
import { AlertTitle, AlertMessage } from 'assets/styles/sharedStyles';
import { DownloadIcon } from 'components/Icons';
import globalMessages from 'components/globalMessages';
import SystemIcon from 'components/SystemIcon';

const propTypes = {
  header: PropTypes.node,
  message: PropTypes.node,
  handleOnSubmit: PropTypes.func,
};

const ExportOrderForm = ({ header, message, handleOnSubmit }) => (
  <>
    <SystemIcon Icon={DownloadIcon} />
    <AlertTitle>{header}</AlertTitle>
    <AlertMessage>{message}</AlertMessage>

    <Form
      noButtonIcon={true}
      widthLimited={true}
      fields={[{ ...fieldTypes.email }]}
      onSubmit={handleOnSubmit}
      submitButtonLabel={<FormattedMessage {...globalMessages.confirmExport} />}
      errorHeader={<FormattedMessage {...globalMessages.error} />}
      errorMessage={<FormattedMessage {...globalMessages.errorMsg} />}
    />
  </>
);

ExportOrderForm.propTypes = propTypes;

export default ExportOrderForm;
