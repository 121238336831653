import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingSpinner from 'components/Loading/LoadingSpinner';
import PrimaryButton from 'components/PrimaryButton';
import { ButtonContainer, LoadingMessage, LoadingOverlayContainer } from './styles';

const TIMEOUT_DISPLAY = 30 * 1000;

const LoadingOverlay: React.FC = () => {
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasTimedOut(true);
    }, TIMEOUT_DISPLAY);

    return () => clearTimeout(timer);
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <LoadingOverlayContainer>
      <LoadingMessage>
        <FormattedMessage defaultMessage="Loading" />
      </LoadingMessage>
      <LoadingSpinner width="64px" height="64px" />
      {hasTimedOut && (
        <ButtonContainer>
          <PrimaryButton
            onClick={handleRefresh}
            text={<FormattedMessage defaultMessage="Something has gone wrong. Click to refresh the page" />}
          />
        </ButtonContainer>
      )}
    </LoadingOverlayContainer>
  );
};

export default LoadingOverlay;
