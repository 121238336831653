import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { initiateForgotPassword, forgotPasswordClearState } from 'actions/user';

import Form from 'components/Form';
import fieldTypes from 'components/Form/fieldTypes';
import { ForgotPasswordIcon } from 'components/Icons';
import { AlertTitle, AlertMessage } from 'assets/styles/sharedStyles';
import globalMessages from 'components/globalMessages';
import SystemIcon from 'components/SystemIcon';

import messages from './intl';

class ForgotPasswordForm extends Component {
  static defaultProps = {
    initiateForgotPassword: () => undefined,
    forgotPasswordClearState: () => undefined,
    children: () => undefined,
    email: '',
  };

  static propTypes = {
    initiateForgotPassword: PropTypes.func,
    forgotPasswordClearState: PropTypes.func,
    children: PropTypes.func,
    email: PropTypes.string,
  };

  state = { email: undefined };

  componentWillUnmount() {
    this.props.forgotPasswordClearState();
  }

  onSubmit = (values, callback) => {
    this.props.initiateForgotPassword(values.email, res => {
      if (res.success) this.setState({ email: values.email });
      callback(res);
    });
  };

  render() {
    const { email } = this.state;

    return (
      <>
        <SystemIcon Icon={ForgotPasswordIcon} />
        <AlertTitle>
          <FormattedMessage {...messages.title} />
        </AlertTitle>
        <AlertMessage>
          <FormattedMessage {...messages.cantLoginForgot} />
          &nbsp;
          <FormattedMessage {...messages.resetInstructions} />
        </AlertMessage>
        <Form
          noButtonIcon={true}
          widthLimited={true}
          fields={[{ ...fieldTypes.email }]}
          onSubmit={this.onSubmit}
          submitButtonLabel={<FormattedMessage {...globalMessages.submitRequest} />}
          errorHeader={<FormattedMessage {...messages.error} />}
          errorMessage={<FormattedMessage {...messages.forgotPasswordError} />}
          successHeader={<FormattedMessage {...messages.passwordRequestSuccess} />}
          successMessage={<FormattedMessage {...messages.checkEmail} values={{ registeredEmail: email }} />}
          solidSubmitButton={false}
        >
          {this.props.children()}
        </Form>
      </>
    );
  }
}

export default connect(undefined, {
  initiateForgotPassword,
  forgotPasswordClearState,
})(ForgotPasswordForm);
