import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CollectionIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M793,22c24.8,0,48.4,4.8,70.7,14.4c22.3,9.6,41.7,22.6,58.1,39.2c16.4,16.6,29.4,35.9,38.9,58c9.5,22.1,14.3,46,14.3,71.8 c0,36.1-8.6,74-25.8,113.7c-17.2,39.7-36,76.2-56.5,109.3c-20.5,33.1-39.7,60.9-57.6,83.4c-17.9,22.5-28,34.8-30.2,37L793,563 l-12.1-14.4c-2.2-2.2-12.2-14.5-30.2-37c-17.9-22.4-37.1-50.2-57.6-83.4c-20.5-33.1-39.3-69.6-56.5-109.3 c-17.2-39.7-25.8-77.6-25.8-113.7c0-25.8,4.7-49.7,14.3-71.8c9.5-22.1,22.5-41.4,38.9-58C680.6,59,700,45.9,722.3,36.4 C744.6,26.8,768.1,22,793,22z M793,511.1c9.5-11.8,22.6-29.1,39.5-51.9c16.8-22.8,33.3-48.6,49.3-77.3 c16.1-28.7,30.2-58.5,42.2-89.4c12.1-30.9,18.1-60,18.1-87.2c0-20.6-4-40.1-12.1-58.5c-8.1-18.4-18.8-34.2-32.3-47.5 c-13.5-13.2-29.2-23.9-47.1-32c-17.9-8.1-37.1-12.1-57.6-12.1c-20.5,0-39.7,4.1-57.6,12.1c-17.9,8.1-33.6,18.8-47.1,32 c-13.5,13.2-24.3,29.1-32.3,47.5c-8.1,18.4-12.1,37.9-12.1,58.5c0,27.2,6,56.3,18.1,87.2c12.1,30.9,26.1,60.7,42.2,89.4 c16.1,28.7,32.5,54.5,49.3,77.3C770.3,482,783.5,499.3,793,511.1z M793,110.3c13.9,0,26.9,2.6,38.9,7.7c12.1,5.2,22.5,12.1,31.2,21 c8.8,8.8,15.7,19.3,20.8,31.5c5.1,12.1,7.7,25.2,7.7,39.2c0,14-2.6,26.9-7.7,38.6c-5.1,11.8-12.1,22.3-20.8,31.5 c-8.8,9.2-19.2,16.4-31.2,21.5c-12.1,5.2-25,7.7-38.9,7.7c-13.9,0-26.9-2.6-38.9-7.7c-12.1-5.1-22.5-12.3-31.2-21.5 c-8.8-9.2-15.7-19.7-20.8-31.5c-5.1-11.8-7.7-24.7-7.7-38.6c0-14,2.6-27.1,7.7-39.2c5.1-12.1,12.1-22.6,20.8-31.5 c8.8-8.8,19.2-15.8,31.2-21C766.1,112.9,779.1,110.3,793,110.3z M793,275.9c8.8,0,17.2-1.7,25.2-5c8-3.3,15.2-8.1,21.4-14.4 c6.2-6.2,11-13.4,14.3-21.5c3.3-8.1,4.9-16.6,4.9-25.4c0-9.6-1.6-18.4-4.9-26.5c-3.3-8.1-8.1-15.1-14.3-21 c-6.2-5.9-13.3-10.5-21.4-13.8c-8.1-3.3-16.4-5-25.2-5c-8.8,0-17.2,1.7-25.2,5c-8.1,3.3-15.2,7.9-21.4,13.8 c-6.2,5.9-11,12.9-14.3,21c-3.3,8.1-4.9,16.9-4.9,26.5c0,8.8,1.6,17.3,4.9,25.4c3.3,8.1,8,15.3,14.3,21.5 c6.2,6.3,13.3,11,21.4,14.4C775.8,274.3,784.2,275.9,793,275.9z"
      />

      <path
        className="icon"
        fill={fill}
        d="M72.2,983L57.6,648.8l-31-0.7V541.8h31.4l15-49.7h70.5l7.1-49.6l-63.1,0.1l82.5-128.1l419.4-0.6l94.5,636.9l-363.6,0.3 l-1.4,31.8H72.2z M283.5,944.7l11.3-295l-200.4-0.5l12.5,295.5H283.5z M321.8,912.4l145.5-0.2l74.2-518.8l-31.3,48.7l-322.3,0.4 l-7.1,49.6h137.2l15,49.7h30.4v106.7h-29.7L321.8,912.4z M640.8,909.3l-68.5-458.7l-65.6,458.7l64.9-39.1L640.8,909.3z M326.6,610.2v-29h-22.5L290.4,531H100.5l-12.3,50.2H63.3v29H326.6z M156.9,403.9l333.6-0.4l32.6-50.6l-333.6,0.4L156.9,403.9z"
      />
    </g>
  </svg>
);

CollectionIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CollectionIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CollectionIcon;
