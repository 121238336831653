import React from 'react';

import image from 'utils/image';
import { ImageIcon } from 'components/Icons';

type Props = {
  src?: string;
};

const ServiceImage = ({ src: imageSrc }: Props) =>
  imageSrc ? (
    <img src={image(imageSrc, { width: 400 })} alt="Service" width="70" height="70" />
  ) : (
    <ImageIcon width="70" height="70" />
  );

export default ServiceImage;
