import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { browserHistory } from 'react-router';

import { fetchFavourites } from 'actions/favourites';
import Loading from 'components/Loading';
import Dropdown, { generateDropdownOptions } from 'components/Dropdown';
import Page from 'components/Pages/container';
import MessageBlock from 'components/MessageBlock';
import FavouriteProduct from 'components/FavouriteProduct';
import { FavouriteThemedIcon } from 'components/Icons';
import { selectServices, selectServiceName, selectVenueMenuTypes } from 'selectors/root';
import { setMenuTypeId, setService } from 'actions/browse';
import { selectUserFavourites, selectFavouriteProducts, isLoggedIn } from 'selectors/user';
import {
  selectMenuTypeId,
  selectLowestAvailableMenuItemsForProducts,
  selectServiceId,
} from 'selectors/browse';

import { ListStyled } from './styles';

const messages = defineMessages({
  loadingFavourites: {
    defaultMessage: 'Loading Favourites...',
  },
  favouritesPage: {
    defaultMessage: 'Favourites',
  },
  noFavouritesTitle: {
    defaultMessage: 'Sorry',
  },
  no_favourites: {
    defaultMessage: 'You currently have no favourites added.',
  },
  no_favourites_available: {
    defaultMessage: 'There are no favourites available for that service.',
  },
  selectOptions: {
    defaultMessage: 'Please select Menu types',
  },
  selectOptionsAdvice: {
    defaultMessage: 'This will allow us to find suitable information for your available favourites.',
  },
  menuDropdownDefault: {
    defaultMessage: 'Select Menu Type',
  },
  servicesDropdownDefault: {
    defaultMessage: 'Select Service Type',
  },
});

const filterUndefined = list => {
  if (!list) return undefined;

  return list.filter(item => item);
};

const propTypes = {
  userIsLoggedIn: PropTypes.bool.isRequired,
  fetchFavourites: PropTypes.func.isRequired,
  menuTypes: PropTypes.instanceOf(List).isRequired,
  menuTypesSize: PropTypes.number,
  menuTypeId: PropTypes.number,
  setMenuTypeId: PropTypes.func,
  services: PropTypes.instanceOf(Map).isRequired,
  servicesSize: PropTypes.number,
  serviceId: PropTypes.string,
  getServiceName: PropTypes.func.isRequired,
  setService: PropTypes.func,
  lowestAvailableMenuItems: PropTypes.instanceOf(List),
  favourites: PropTypes.instanceOf(List).isRequired,
  favouritesProducts: PropTypes.instanceOf(List),
};

const Favourites = ({
  userIsLoggedIn,
  fetchFavourites,
  menuTypes,
  menuTypesSize,
  menuTypeId,
  setMenuTypeId,
  services,
  servicesSize,
  getServiceName,
  serviceId,
  setService,
  lowestAvailableMenuItems,
  favourites,
  favouritesProducts,
}) => {
  useEffect(() => {
    if (!userIsLoggedIn) browserHistory.push('/');

    fetchFavourites();

    if (menuTypesSize === 1 && !menuTypeId) setMenuTypeId(menuTypes.getIn(['0', 'id']));
    if (servicesSize === 1 && !serviceId) setService(services.first().get('id'));
  }, []);

  const noFiltersSelected = (menuTypesSize >= 1 && !menuTypeId) || (servicesSize >= 1 && !serviceId);

  const filteredLowestAvailableMenuItems = filterUndefined(lowestAvailableMenuItems);

  if (!favourites || !favouritesProducts) {
    return (
      <div>
        <Loading>
          <FormattedMessage {...messages.loadingFavourites} />
        </Loading>
      </div>
    );
  }

  if (favourites?.size === 0) {
    return (
      <Page
        large={true}
        settingsPage={true}
        titleMessage={messages.favouritesPage}
        redirectCondition={!userIsLoggedIn}
        Icon={FavouriteThemedIcon}
      >
        <MessageBlock
          header={<FormattedMessage {...messages.noFavouritesTitle} />}
          body={<FormattedMessage {...messages.no_favourites} />}
        />
      </Page>
    );
  }

  return (
    <Page
      large={true}
      settingsPage={true}
      titleMessage={messages.favouritesPage}
      redirectCondition={!userIsLoggedIn}
      Icon={FavouriteThemedIcon}
    >
      {noFiltersSelected && (
        <MessageBlock
          header={<FormattedMessage {...messages.selectOptions} />}
          body={<FormattedMessage {...messages.selectOptionsAdvice} />}
        />
      )}

      {(menuTypesSize > 1 || servicesSize > 1) && <h3> Filters: </h3>}

      {menuTypesSize > 1 && (
        <Dropdown
          options={generateDropdownOptions(menuTypes)}
          value={menuTypeId}
          onChange={setMenuTypeId}
          label={<FormattedMessage {...messages.menuDropdownDefault} />}
        />
      )}

      {servicesSize > 1 && (
        <Dropdown
          options={generateDropdownOptions(
            services.map(service => service.set('name', getServiceName(service.get('id'))))
          )}
          value={serviceId}
          onChange={value => {
            setService(value);
          }}
          label={<FormattedMessage {...messages.servicesDropdownDefault} />}
        />
      )}

      <ListStyled>
        {(!filteredLowestAvailableMenuItems || filteredLowestAvailableMenuItems?.size === 0) &&
          menuTypeId &&
          serviceId && (
            <MessageBlock
              header={<FormattedMessage {...messages.noFavouritesTitle} />}
              body={<FormattedMessage {...messages.no_favourites_available} />}
            />
          )}

        {filteredLowestAvailableMenuItems?.map(item => (
          <FavouriteProduct
            key={`${item?.get('productId')}`}
            item={item}
            menuTypeId={menuTypeId}
            serviceId={serviceId}
          />
        ))}
      </ListStyled>
    </Page>
  );
};

Favourites.propTypes = propTypes;

const mapStateToProps = state => {
  const menuTypeId = selectMenuTypeId(state);
  const serviceId = selectServiceId(state) || undefined;
  const favouritesProducts = selectFavouriteProducts(state);
  const lowestAvailableMenuItems = selectLowestAvailableMenuItemsForProducts(
    state,
    favouritesProducts,
    menuTypeId,
    serviceId
  );
  const menuTypes = selectVenueMenuTypes(state);
  const services = selectServices(state);

  return {
    menuTypes,
    menuTypesSize: menuTypes?.size || 0,
    favourites: selectUserFavourites(state) || new List(),
    userIsLoggedIn: isLoggedIn(state),
    services,
    servicesSize: services?.size || 0,
    getServiceName: id => selectServiceName(state, id),
    menuTypeId,
    serviceId,
    favouritesProducts,
    lowestAvailableMenuItems,
  };
};

export default connect(mapStateToProps, {
  fetchFavourites,
  setMenuTypeId,
  setService,
})(Favourites);
