import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocale } from 'selectors/user';

const TextBlock = ({ content, locale, className }) => {
  const text = content.get(locale);
  if (text) {
    return (
      <div className={className}>
        {text.split('/n').map((textBlock, index) => (
          <span key={index}>
            {textBlock}
            <br />
          </span>
        ))}
      </div>
    );
  }
  return null;
};

TextBlock.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default connect(state => ({
  locale: getLocale(state),
}))(TextBlock);
