import styled, { css } from 'styled-components';
import { ChromeDotsIcon, SafariShareIcon } from 'components/Icons';

const iconStyles = css`
  max-height: 22px;
  display: inline-block;
  margin: 0 5px;
`;

export const Container = styled.div`
  position: fixed;
  box-shadow: 0 3px 10px 3px #31363669;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  background: #f3f3f3;
  color: black;
  max-width: 90%;
  z-index: ${({ theme }) => theme.zIndex.six};
  padding: 5px;
  margin: 20px 5%;
  right: 0;

  @media (orientation: portrait) and (max-width: 414px), (orientation: landscape) and (max-width: 568px) {
    bottom: 20px;
  }
`;

export const CloseIconBtn = styled.button`
  padding: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyLogoImg = styled.img`
  max-width: 60px;
  display: block;
  margin: auto;
  padding: 10px 0;
`;

export const TextContainer = styled.div`
  text-align: center;
  margin: auto;
  padding: 5px 10px;
`;

export const ChromeDotsIconStyled = styled(ChromeDotsIcon)`
  ${iconStyles};
`;

export const SafariShareIconStyled = styled(SafariShareIcon)`
  ${iconStyles};
`;
