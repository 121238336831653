import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: number, condition: boolean = true) => {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!condition) return;
    const tick = () => savedCallback.current();

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [condition, delay]);
};

export default useInterval;
