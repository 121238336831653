import React from 'react';

import OfferDetail from 'components/Loyalty/OfferDetail/OfferDetail';
import { Offer } from 'hooks/useLoyalty';

interface LoyaltyOfferRedeemProps {
  onRedeem: Function;
  onRemove: Function;
  closeAlert: Function;
  offer: Offer;
}

const LoyaltyOfferRedeem: React.FC<LoyaltyOfferRedeemProps> = props => {
  const onRedeem = (offer: Offer) => {
    props.onRedeem(offer);
    props.closeAlert();
  };

  const onRemove = (offer: Offer) => {
    props.onRemove(offer);
    props.closeAlert();
  };

  return (
    <OfferDetail onClose={props.closeAlert} offer={props.offer} onRedeem={onRedeem} onRemove={onRemove} />
  );
};

export default LoyaltyOfferRedeem;
