import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  selectGratuityMessageByLocale,
  selectVenueCurrencyCode,
  selectVenueCurrencyDecimals,
} from 'selectors/root';
import FormattedPrice from 'components/FormattedPrice';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import FieldCurrency from 'components/FieldCurrency';
import GratuityOption from './GratuityOption';
import {
  GratuityOptionAmount,
  GratuityOptionButton,
  GratuityOptionsContainer,
  Label,
  OtherGratuityOption,
} from './styles';
import { GratuityOptionsProps } from './types';
import messages from './messages';

const GratuityOptions = ({
  onChange,
  options,
  orderTotal,
  selectedGratuity,
  gratuityMessage,
  currencyCode,
  currencyDecimals,
}: GratuityOptionsProps) => {
  const [otherGratuity, setOtherGratuity] = useState<number>(undefined);
  const [showOtherField, setShowOtherField] = useState<boolean>(false);

  return (
    <>
      <GratuityOptionsContainer>
        <Label>{gratuityMessage || <FormattedMessage {...messages.gratuity} />}</Label>
        {options.map(option => (
          <GratuityOption
            key={`${option.type}_${option.amount}`}
            onClick={(percentage: number) => {
              setShowOtherField(false);
              onChange(percentage, { type: 'percentage' });
            }}
            option={option}
            orderTotal={orderTotal}
            selected={selectedGratuity.value === option.amount && selectedGratuity.type === 'percentage'}
            fade={showOtherField || (!!selectedGratuity.value && selectedGratuity.value !== option.amount)}
          />
        ))}
        <GratuityOptionButton
          fade={!!selectedGratuity && !showOtherField}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            setShowOtherField(!showOtherField);
          }}
          selected={showOtherField}
        >
          <GratuityOptionAmount>
            <FormattedMessage {...messages.other} />
          </GratuityOptionAmount>
          <FormattedPrice value={otherGratuity ?? 0} showCurrency={false} />
        </GratuityOptionButton>
      </GratuityOptionsContainer>
      {showOtherField && (
        <OtherGratuityOption>
          <FieldCurrency
            venueDecimals={currencyDecimals}
            onChange={(value: number) => setOtherGratuity(Math.max(0, value))}
            label={<FormattedMessage {...messages.enterTipAmount} values={{ currencyCode }} />}
            value={otherGratuity}
          />
          <ActionButton
            primaryButton={true}
            disabled={!otherGratuity}
            label={<FormattedMessage {...globalMessages.apply} />}
            onClick={event => {
              event.preventDefault();
              onChange(otherGratuity, { type: 'fixed' });
            }}
          />
        </OtherGratuityOption>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  options: ownProps.options?.toJS(),
  gratuityMessage: selectGratuityMessageByLocale(state),
  currencyCode: selectVenueCurrencyCode(state),
  currencyDecimals: selectVenueCurrencyDecimals(state),
});

export default connect(mapStateToProps)(GratuityOptions);
