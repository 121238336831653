import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { useHandleOutsideClick } from 'hooks';
import { CloseIcon, SearchIcon } from 'components/Icons';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import globalMessages from 'components/globalMessages';
import {
  getUserPredictions,
  getUserCoordsFromPrediction,
  clearPredictionsAndCoordsFromStateAndSession,
} from 'actions/user';

import {
  isLoadingPredictions,
  selectPredictionsList,
  selectPredictionsError,
  isLoadingCoords,
  selectCoordsError,
  getAllowGeoLocation,
} from 'selectors/user';
import ErrorContainer from 'components/ErrorContainer';

import { Label } from 'assets/styles/sharedStyles';
import {
  Container,
  PostcodeInput,
  ClearButtonStyled,
  ButtonStyled,
  MenuStyle,
  Item,
  GeoButtonStyled,
} from './styles';

const propTypes = {
  getUserPredictions: PropTypes.func,
  getUserCoordsFromPrediction: PropTypes.func,
  isLoadingPredictions: PropTypes.bool,
  predictionsList: PropTypes.instanceOf(List),
  predictionsError: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(List)]),
  isLoadingCoords: PropTypes.bool,
  coordsError: PropTypes.bool,
  clearPredictionsAndCoordsFromStateAndSession: PropTypes.func,
  closeAlert: PropTypes.func,
  allowGeoLocation: PropTypes.bool,
};

const RadiusCheck = ({
  getUserPredictions,
  getUserCoordsFromPrediction,
  isLoadingPredictions,
  predictionsList,
  predictionsError,
  isLoadingCoords,
  coordsError,
  clearPredictionsAndCoordsFromStateAndSession,
  closeAlert,
  allowGeoLocation,
}) => {
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [focus, setFocus] = useState(false);

  const autocompleteRef = useRef();
  useHandleOutsideClick(isOpen, autocompleteRef, setIsOpen);

  useEffect(() => {
    setPredictions(predictionsList);

    if (focus && predictionsList?.size > 0) {
      setIsOpen(true);
    }
  }, [focus, predictionsList]);

  const handleOnClear = () => {
    setValue('');
    setIsOpen(false);
    clearPredictionsAndCoordsFromStateAndSession();
  };

  const handleOnClick = item => {
    getUserCoordsFromPrediction(item.get('place_id'), closeAlert);
    setValue(item.get('description'));
    setIsOpen(false);
  };

  const handleOnFocus = () => {
    setFocus(true);

    if (predictions?.size > 0) setIsOpen(true);
  };

  const errors = () => {
    if (predictionsError) {
      return predictionsError.toJS();
    }

    if (coordsError) {
      return coordsError.toJS();
    }

    return null;
  };

  return (
    <ErrorContainer errors={errors()}>
      <Container>
        <GeoButtonStyled callback={closeAlert} />
      </Container>

      <Container ref={autocompleteRef}>
        <fieldset disabled={allowGeoLocation || isLoadingPredictions || isLoadingCoords}>
          <Label htmlFor="addressPredictions" asPlaceholder={!value}>
            <FormattedMessage {...globalMessages.enterYourAddress} />
          </Label>
          <PostcodeInput
            type="text"
            id="addressPredictions"
            value={value}
            onChange={({ target }) => setValue(target.value)}
            onFocus={() => handleOnFocus()}
          />

          {value && (
            <ClearButtonStyled type="button" onClick={handleOnClear}>
              <CloseIcon />
            </ClearButtonStyled>
          )}

          <ButtonStyled type="button" onClick={() => getUserPredictions(value)} disabled={!value}>
            {isLoadingPredictions || isLoadingCoords ? (
              <LoadingSpinner width="24px" height="25px" noPad={true} />
            ) : (
              <SearchIcon width="24px" />
            )}
          </ButtonStyled>
        </fieldset>

        {isOpen && predictions.size > 0 ? (
          <MenuStyle>
            {predictions.map(item => (
              <Item key={item.get('place_id')} onClick={() => handleOnClick(item)}>
                {item.get('description')}
              </Item>
            ))}
          </MenuStyle>
        ) : null}
      </Container>
    </ErrorContainer>
  );
};

RadiusCheck.propTypes = propTypes;

export default connect(
  state => ({
    isLoadingPredictions: isLoadingPredictions(state),
    predictionsList: selectPredictionsList(state),
    predictionsError: selectPredictionsError(state),
    isLoadingCoords: isLoadingCoords(state),
    coordsError: selectCoordsError(state),
    allowGeoLocation: getAllowGeoLocation(state),
  }),
  { getUserPredictions, getUserCoordsFromPrediction, clearPredictionsAndCoordsFromStateAndSession }
)(RadiusCheck);
