import styled, { keyframes } from 'styled-components';
import { RefreshIcon } from 'components/Icons';

const spin = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(-360deg);
}
`;

export const RefreshIconSpin = styled(RefreshIcon)`
  transform-origin: 50% 50%;
  animation-name: ${spin};
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
