import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isExternalLink } from 'utils/externalLinks';

import { StyledItem, StyledLink, StyledHref, StyledLabel } from './styles';
import { addCssPrefixTo } from '../../utils';

const propTypes = {
  path: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  message: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
  intlMessage: PropTypes.string,
  sidebarOpen: PropTypes.bool,
  className: PropTypes.string,
};

const FooterItem = ({ Icon, path, message, intlMessage, onClick, className }) => {
  const iconComponent = typeof Icon === 'function' ? <Icon /> : Icon;
  const isExternal = isExternalLink(path);
  const Link = isExternal ? StyledHref : StyledLink;
  const linkProps = isExternal ? { href: path, target: '_blank' } : { to: path };

  return (
    <StyledItem className={`${className} ${addCssPrefixTo('FOOTER_ITEM')}`}>
      <Link {...linkProps} onClick={onClick}>
        {iconComponent}
        <StyledLabel>{intlMessage || <FormattedMessage {...message} />}</StyledLabel>
      </Link>
    </StyledItem>
  );
};

FooterItem.propTypes = propTypes;

export default FooterItem;
