import React from 'react';
import { defineMessages } from 'react-intl';

import StandardAlert from 'components/Alert/StandardAlert';

const messages = defineMessages({
  header: {
    defaultMessage: 'We are sorry!',
  },
  message: {
    defaultMessage:
      'Please contact a member of staff about full translation of this system in your desired language.',
  },
  confirm: {
    defaultMessage: 'Close',
  },
});

export default props => <StandardAlert {...props} messages={messages} config={{ buttons: 1 }} />;
