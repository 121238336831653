import { defineMessages } from 'react-intl';

export default defineMessages({
  addressLabel: {
    defaultMessage: 'Address Label (e.g. Home, Office)',
  },
  addressNotes: {
    defaultMessage: 'Do we need additional notes to find this address?',
  },
  addressPin: {
    defaultMessage: 'Building access code/pin',
  },
  address1: {
    defaultMessage: 'Address Line 1',
  },
  address2: {
    defaultMessage: 'Address Line 2',
  },
  town_city: {
    defaultMessage: 'Town City',
  },
  county: {
    defaultMessage: 'County',
  },
  postcode: {
    defaultMessage: 'Postcode',
  },
  block: {
    defaultMessage: 'Block',
  },
  covers: {
    defaultMessage: 'Number of people ordering food',
  },
  email: {
    defaultMessage: 'Email',
  },
  name: {
    defaultMessage: 'Name',
  },
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  password: {
    defaultMessage: 'Password',
  },
  newPassword: {
    defaultMessage: 'Password',
  },
  confirmPassword: {
    defaultMessage: 'Confirm Password',
  },
  roomNumber: {
    defaultMessage: 'Room Number',
  },
  row: {
    defaultMessage: 'Row',
  },
  seat: {
    defaultMessage: 'Seat Number',
  },
  telephone: {
    defaultMessage: 'Telephone',
  },
  tableNumber: {
    defaultMessage: 'Table number',
  },
  tableOrSunbedNumber: {
    defaultMessage: 'Table or Sunbed number',
  },
  sunbedNumber: {
    defaultMessage: 'Sunbed number',
  },
  notes: {
    defaultMessage: 'Order Notes',
  },
  registration: {
    defaultMessage: 'Car Registration Number',
  },
  partRegistration: {
    defaultMessage: '{label} (Last 3 Digits)',
  },
  bay_number: {
    defaultMessage: 'Bay Number',
  },
  make: {
    defaultMessage: 'Car Make',
  },
  model: {
    defaultMessage: 'Car Model',
  },
  colour: {
    defaultMessage: 'Car Colour',
  },
});
