/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React from 'react';

// We use this instead of `createGlobalStyle` as there is a memory leak when used on server
// https://github.com/styled-components/styled-components/issues/3022
export const GlobalStyles = ({ theme }) => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
          body {
              font-family: '${theme.fonts.baseFontName}', Verdana, sans-serif;
              font-size: ${theme.fonts.baseFontSize};
              background-color: ${theme.color.primaryBackground};
              color: ${theme.color.darkGrey}; 
          }

          .linkUnderlined {
            color: ${theme.color.link};
            text-decoration: underline;
            line-height: 22px;
          }

          .linkUnderlined:hover {
            text-decoration: none;
          }
          .endButtons.fixedBtns {
            z-index: ${theme.zIndex.two};
          }

          .alertBtns button#alertYes,
          .endButtons button.addToOrderButton,
          .endButtons button.updateOrderButton,
          .endButtons button.addToOrderButton.greenButton,
          .endButtons .greenButton,
          .greenButton {
            background-color: ${theme.color.success};
            color: ${theme.color.white};
          }

          .redButton {
            background-color: ${theme.color.danger};
            color: ${theme.color.white};
          }
        
          .endButtons .whiteButton {
            border: 1px solid ${theme.color.borderGray};
          }
        
          .endButtons button.disabledButton {
            background: ${theme.color.darkGrey};
            color: ${theme.color.white};
          }
        
          .endButtons.twoBtns button.cancelButton,
          .endButtons.twoBtns a.cancelButton,
          .alertBtns > button#alertNo {
            background-color: ${theme.color.danger};
            color: ${theme.color.white};
          }

          .endButtons:not(.twoBtns) button.cancelButton {
            background-color: ${theme.color.white};
            color: ${theme.color.danger};
          }
        
          .slimButton {
            background-color: ${theme.color.white};
          }
        
          .slimButtonCancel {
            color: ${theme.color.danger};
          }

          .slimButtonCancelSideBorder {
            border-right: 1px solid ${theme.color.borderGray};
          }

          html[dir='rtl'] .slimButtonCancelSideBorder {
            border-right: none;
            border-left: 1px solid ${theme.color.borderGray};
          }

          .slimButtonSubmit {
            color: ${theme.color.success};
          }

          button:disabled,
          button.disabled {
            background: ${theme.color.darkGrey};
            color: ${theme.color.white};
          }

          .alert {
            color: ${theme.color.darkGrey};
            background: ${theme.color.grayLighter};
          }

          .alert.alert-danger {
            background: ${theme.color.danger};
            color: ${theme.color.white};
          }
      
          .alert.alert-info {
            background: ${theme.color.info};
            color: ${theme.color.white};
          }
      
          .alert.alert-success {
            background: ${theme.color.successBackgroundColor};
            color: ${theme.color.white};
          }
      
          .alert.alert-warning {
            background-color: ${theme.color.warning};
            color: ${theme.color.white};
          }

          .text-danger {
            color: ${theme.color.danger};
          }

          .ui-checkbox input[type='checkbox']::before {
            background-color: ${theme.color.white};
            border: 1px solid rgba(3, 2, 2, 0.2);
          }

          .ui-checkbox input[type='checkbox']:disabled + span::before {
            border: 1px solid ${theme.color.borderGray};
          }

          .ui-checkbox input[type='checkbox']:disabled:checked + span::before {
            background-color: ${theme.color.borderGray};
            border: 1px solid ${theme.color.borderGray};
          }

          .ui-checkbox input[type='checkbox']:checked + span::before {
            background-color: ${theme.color.white};
            border: 1px solid ${theme.color.borderGray};
          }

          .ui-checkbox input[type='checkbox']:checked + span::after {
            background-color: ${theme.color.success};
          }

          .ui-checkbox.checkbox-circle input[type='checkbox']:checked + span::before {
            background-color: ${theme.color.success};
          }

          .primaryBackground {
            background-color: ${theme.color.brandPrimary};
          }
        
          .primaryBorderColor {
            border-color: ${theme.color.brandPrimary};
          }
        
          .primaryColor,
          .primaryColor:focus,
          a.primaryColor,
          a.primaryColor:focus {
            color: ${theme.color.brandTextColor};
          }
        
          .menuBarColor {
            background-color: ${theme.color.brandSecondary};
          }
        
          .secondaryBorderColor {
            border-color: ${theme.color.brandTextColor};
          }
        
          .secondaryBackground {
            background-color: ${theme.color.brandPrimary};
          }
          `,
    }}
  />
);
