import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CartButton from 'components/CartButton';
import { orderReview } from 'actions/order';
import { orderQuantity } from 'selectors/order/';
import { selectServiceName } from 'selectors/root';
import { setService } from 'actions/browse';
import NextAvailableMessage from 'components/NextAvailableMessage';
import addCssPrefixTo from 'utils/addCssPrefixTo';
import ServiceLinkDetails from './ServiceLinkDetails';
import ServiceAddress from './ServiceAddress';

import { ServiceContainer, CartButtonStyled } from './styles';

import { trackEvent } from '../../utils/tracking';

export const ServiceLink = ({
  service,
  serviceName,
  image,
  showAddress,
  setService,
  openOrder,
  orderQuantity,
}) => {
  const onCartClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setService(service.id.toString());
    openOrder();
    trackEvent('initiate_checkout', {
      category: 'checkout',
      location: 'on_service',
    });
  };
  const hasImage = !!image;

  return (
    <ServiceContainer className={addCssPrefixTo('SERVICE_CONTAINER')} compact={hasImage}>
      <ServiceLinkDetails
        compact={hasImage}
        isDraft={service.publish_status === 'draft'}
        image={image}
        name={serviceName}
        address={showAddress && <ServiceAddress service={service} />}
        availabilityInfo={
          !service.active &&
          service.nextActiveTime && (
            <NextAvailableMessage
              nextAvailableTime={{
                type: 'service',
                time: service.nextActiveTime,
                date: service.nextActiveDate,
              }}
            />
          )
        }
      />
      {!!orderQuantity && (
        <CartButtonStyled onClick={onCartClick}>
          <CartButton serviceId={service.id.toString()} />
        </CartButtonStyled>
      )}
    </ServiceContainer>
  );
};

ServiceLink.propTypes = {
  image: PropTypes.node,
  showAddress: PropTypes.bool,
  service: PropTypes.object.isRequired,
  setService: PropTypes.func,
  openOrder: PropTypes.func.isRequired,
  orderQuantity: PropTypes.number,
  serviceName: PropTypes.string,
};

const mapStateToProps = (state, { service }) => ({
  orderQuantity: orderQuantity(state, service?.id.toString()),
  serviceName: selectServiceName(state, service?.id.toString()),
});

export default connect(mapStateToProps, { setService, openOrder: orderReview })(ServiceLink);
