import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';

import { useLoyalty } from 'hooks';
import MessageBlock from 'components/MessageBlock';
import FormattedPrice from 'components/FormattedPrice';
import FormHeader from 'components/Form/FormHeader';
import CheckoutLoginRegister from 'components/CheckoutLoginRegister';
import CheckoutUserDetails from 'components/CheckoutUserDetails';
import EstimatedWaitMessage from 'components/EstimatedWaitMessage';
import CheckoutForm from 'components/CheckoutForm';
import Page from 'components/Pages/container';
import CheckoutIcon from 'components/Icons/CheckoutIcon';
import { TabList, Tab } from 'components/Tabs/TabList';
import { confirmLocation, setFulfilmentMethodId } from 'actions/order';
import { clearErrorMessage, clearPaymentError } from 'actions/payment';
import { setService, fetchProducts } from 'actions/browse';
import {
  selectOrderItems,
  selectOrderTotal,
  selectOrderFulfilmentMethodId,
  selectProductsInCartIdsWhereReportingCatNull,
} from 'selectors/order';
import { selectAvailableFulfilmentMethods, selectFulfilmentMethod } from 'selectors/root';
import { isLocationConfirmed } from 'selectors/storage';
import { getFulfilmentFormFields, getFulfilmentFormHeader } from 'reducers/fulfilmentReducer';
import { addCssPrefixTo, reactPixel, gtmDataLayerPush } from 'utils';

import globalMessages from 'components/globalMessages';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { Container } from 'components/Pages/User/Addresses/styles';
import { PageContent, StyledTabs } from './styles';
import LoyaltyOffer from './LoyaltyOffer';

const messages = defineMessages({
  minimumAmount: {
    defaultMessage: 'To proceed you need to have a minimum amount of { amount }',
  },
  proceedError: {
    defaultMessage: 'Unable to proceed',
  },
});

const propTypes = {
  items: PropTypes.instanceOf(List),
  itemsSize: PropTypes.number,
  serviceId: PropTypes.string,
  menuTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setService: PropTypes.func,
  productsInCartIdsWhereReportingCatNull: PropTypes.instanceOf(List),
  getProducts: PropTypes.func,
  availableFulfilmentMethods: PropTypes.instanceOf(List),
  fulfilmentMethodId: PropTypes.number,
  confirmedLocation: PropTypes.bool,
  confirmLocation: PropTypes.func,
  clearPaymentError: PropTypes.func,
  clearErrorMessage: PropTypes.func,
  setFulfilmentMethodId: PropTypes.func,
  fulfilmentMethod: PropTypes.any,
  orderTotal: PropTypes.number,
  orderFullTotal: PropTypes.number,
  fulfilmentFields: PropTypes.array,
  fulfilmentHeader: PropTypes.string,
};

const defaultProps = {
  fulfilmentMethod: new Map(),
};

const Checkout = ({
  items,
  itemsSize,
  serviceId,
  menuTypeId,
  setService,
  productsInCartIdsWhereReportingCatNull,
  getProducts,
  availableFulfilmentMethods,
  fulfilmentMethodId,
  confirmedLocation,
  confirmLocation,
  clearPaymentError,
  clearErrorMessage,
  setFulfilmentMethodId,
  fulfilmentMethod,
  orderTotal,
  orderFullTotal,
  fulfilmentFields,
  fulfilmentHeader,
}) => {
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  useEffect(() => {
    if (itemsSize && serviceId && menuTypeId) {
      setService(serviceId);

      if (productsInCartIdsWhereReportingCatNull?.size > 0) {
        getProducts(productsInCartIdsWhereReportingCatNull);
      }
    }

    reactPixel.track('InitiateCheckout');
    gtmDataLayerPush('InitiateCheckout');

    // Get the selected fulfilment method, default it to the first.
    const selectedMethod =
      availableFulfilmentMethods.find(method => method.get('id') === parseInt(fulfilmentMethodId, 10)) ||
      availableFulfilmentMethods.first();

    if (selectedMethod && typeof selectedMethod.get === 'function') {
      setFulfilmentMethod(selectedMethod);
    }
  }, []);

  useEffect(() => {
    if (itemsSize === 0 || !serviceId || !menuTypeId) {
      setTimeout(() => {
        browserHistory.replace('/');
      }, 1000);
    }

    if (!openConfirmAlert && !confirmedLocation) {
      setTimeout(() => {
        confirmLocation();
        setOpenConfirmAlert(true);
      }, 0);
    }
  }, [itemsSize, serviceId, menuTypeId, openConfirmAlert, confirmedLocation, confirmLocation]);

  useEffect(() => () => clearPaymentError(), []);

  const setFulfilmentMethod = method => {
    clearErrorMessage();
    setFulfilmentMethodId(method.get('id'));
  };

  const mustRedirect = itemsSize === 0 || !serviceId || !menuTypeId;

  const minimumReached =
    (!fulfilmentMethod.get('min_order_amount') && items) ||
    (fulfilmentMethod.get('min_order_amount_includes_promotions') ? orderTotal : orderFullTotal) >
      fulfilmentMethod.get('min_order_amount');

  const { offers, offersLoading, redeemOffer } = useLoyalty();
  const sortedOffers = offers.sort((a, b) => {
    if (a.type === 'offer' && b.type !== 'offer') return -1; // Offers first
    if (a.type !== 'offer' && b.type === 'offer') return 1;
    if (a.type === 'offer' && b.type === 'offer') {
      if (a.redeemable && !b.redeemable) return -1; // Active offers before inactive offers
      if (!a.redeemable && b.redeemable) return 1;
    }
    if (a.type === 'exchange' && b.type !== 'exchange') return 1; // Pointshop purchases last
    if (a.type !== 'exchange' && b.type === 'exchange') return -1;
    return 0;
  });

  return (
    <Page settingsPage={true} titleMessage={globalMessages.checkout} Icon={CheckoutIcon} container={false}>
      <PageContent>
        {mustRedirect && (
          <Container className="u-flex u-flexJustifyCenter">
            <LoadingSpinner />
          </Container>
        )}
        {!mustRedirect && fulfilmentFields && (
          <>
            <CheckoutLoginRegister />
            <CheckoutUserDetails />
            <EstimatedWaitMessage />

            <LoyaltyOffer offers={sortedOffers} offersLoading={offersLoading} onRedeem={redeemOffer} />

            {availableFulfilmentMethods?.size > 1 && (
              <TabList>
                <StyledTabs className={`${addCssPrefixTo('TAB_AREA')}`}>
                  {availableFulfilmentMethods.map(type => {
                    const label = type.get('name');
                    const onClick = () => setFulfilmentMethod(type);
                    const selected = fulfilmentMethodId === type.get('id');

                    return (
                      <Tab
                        key={type.get('fulfilment_method_id')}
                        label={label}
                        onClick={onClick}
                        selected={selected}
                        className="tabSelected"
                      />
                    );
                  })}
                </StyledTabs>
              </TabList>
            )}
            {minimumReached && (
              <>
                <FormHeader intlIdentifier={fulfilmentHeader} requiredFields={true} />
                <CheckoutForm fields={fulfilmentFields} fulfilmentMethod={fulfilmentMethod} />
              </>
            )}
            {!minimumReached && (
              <MessageBlock
                type="error"
                header={<FormattedMessage {...messages.proceedError} />}
                body={
                  <FormattedMessage
                    {...messages.minimumAmount}
                    values={{
                      amount: <FormattedPrice value={fulfilmentMethod?.get('min_order_amount')} />,
                    }}
                  />
                }
              />
            )}
          </>
        )}
      </PageContent>
    </Page>
  );
};

Checkout.defaultProps = defaultProps;
Checkout.propTypes = propTypes;

const mapStateToProps = (state, { params }) => ({
  fulfilmentMethod: selectFulfilmentMethod(state),
  fulfilmentFields: getFulfilmentFormFields(state),
  fulfilmentHeader: getFulfilmentFormHeader(state),
  items: selectOrderItems(state),
  itemsSize: selectOrderItems(state)?.size || 0,
  orderTotal: selectOrderTotal(state),
  orderFullTotal: selectOrderTotal(state, { gross: true }),
  fulfilmentMethodId: selectOrderFulfilmentMethodId(state),
  availableFulfilmentMethods: selectAvailableFulfilmentMethods(state),
  confirmedLocation: isLocationConfirmed(state),
  serviceId: params.serviceId,
  menuTypeId: params.menuTypeId,
  productsInCartIdsWhereReportingCatNull: selectProductsInCartIdsWhereReportingCatNull(state),
});

const mapDispatchToProps = dispatch => ({
  setFulfilmentMethodId: id => dispatch(setFulfilmentMethodId(id)),
  confirmLocation: () => dispatch(confirmLocation()),
  clearErrorMessage: () => dispatch(clearErrorMessage()),
  clearPaymentError: () => dispatch(clearPaymentError()),
  setService: serviceId => dispatch(setService(serviceId)),
  getProducts: idsList => dispatch(fetchProducts(idsList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
