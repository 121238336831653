import styled, { keyframes } from 'styled-components';
import { H2 } from 'components/Typography';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const Container = styled.div`
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: ${({ showServices }) => (showServices ? 2 : 0)};
`;

export const WelcomeContent = styled.div`
  transition: 0.15s opacity;
  opacity: ${({ isCollapsed }) => (isCollapsed ? '1' : '0')};
  padding-bottom: 53px;
`;

export const CompanyName = styled(H2)`
  font-weight: bold;
`;

export const WelcomeMessage = styled.div`
  line-height: normal;
  padding: 20px 20px 10px;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 300;

  @media (min-width: 800px) {
    font-size: 0.875em;
  }
`;

export const WelcomeStyled = styled.div`
  height: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '0')};
  border-radius: 0;
  transition: 1s height cubic-bezier(0.615, -0.41, 0.015, 1.025) !important;
  width: 100%;
`;

export const BoarderContainer = styled.div`
  min-height: ${({ isCollapsed }) => (isCollapsed ? '180px' : '100%')};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? '50% / 17%' : '0')};
  transition: 1s min-height cubic-bezier(0.615, -0.41, 0.015, 1.025), 1s border-radius ease;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  align-self: flex-end;
`;

export const VenuesContent = styled.div`
  opacity: 0;
  animation-name: ${({ isCollapsed }) => (isCollapsed ? fadeOut : fadeIn)};
  animation-delay: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.6s')};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  height: 100%;
  overflow-y: auto;
  padding: ${({ isOrganisation }) => isOrganisation && '0'};
  width: ${({ isOrganisation }) => isOrganisation && '100%'};
`;

export const Venues = styled.section`
  display: block;
  padding: 0.1px 0 0;
  position: absolute;
  height: ${({ isCollapsed }) => (isCollapsed ? '0' : '95vh')};
  transition: 1s height cubic-bezier(0.615, -0.41, 0.015, 1.025) !important;
  width: 100%;
`;

export const OfflineMessage = styled.div`
  text-align: center;
  padding: 0 20px 20px;
`;
