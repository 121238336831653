import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AuraLogo from 'components/Icons/AuraLogo';
import ComoIcon from 'components/Icons/ComoIcon';
import { selectLoyaltyTypeByService } from '../../selectors/loyalty';
import { selectServiceId } from '../../selectors/browse';

export const Container = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    fill: #fff;
  }
`;

const propTypes = {
  type: PropTypes.oneOf(['aura', 'como']),
};

const LoyaltyButton = ({ type }) => {
  let Icon;
  const containerStyle = { width: 90, height: 48 };
  const iconSize = { width: 80, height: 48 };

  switch (type) {
    case 'aura':
      Icon = AuraLogo;
      break;
    case 'como':
      Icon = ComoIcon;
      containerStyle.width = 48;
      containerStyle.padding = 10;
      iconSize.height = 28;
      iconSize.width = 28;
      break;
    default:
      return null;
  }

  return (
    <Container style={containerStyle}>
      <Icon width={iconSize.width} height={iconSize.height} />
    </Container>
  );
};

LoyaltyButton.propTypes = propTypes;

export default connect(state => {
  const serviceId = selectServiceId(state);

  return {
    type: selectLoyaltyTypeByService(state, serviceId),
  };
})(LoyaltyButton);
