import { defineMessages } from 'react-intl';

export default defineMessages({
  addToOrder: {
    defaultMessage: 'Add to Order',
  },
  itemNotes: {
    defaultMessage: 'Item notes',
  },
  addNotes: {
    defaultMessage: 'Add item notes',
  },
  outOfStock: {
    defaultMessage: 'Out of stock',
  },
  currentlyUnavailable: {
    defaultMessage: 'Currently Unavailable',
  },
});
