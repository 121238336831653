import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  select: {
    defaultMessage: 'please select { quantity }',
  },
  youMaySelect: {
    defaultMessage: 'you may select { quantity }',
  },
  minimum: {
    defaultMessage: 'minimum { quantity }',
  },
  maximum: {
    defaultMessage: 'maximum { quantity }',
  },
  optional: {
    defaultMessage: 'optional',
  },
});

const MinMaxMessage = ({ minimum, maximum, required }) => {
  if (!minimum && !maximum && required) return null;
  const selectMessage = required ? messages.select : messages.youMaySelect;
  return (
    <span>
      ({!required && <FormattedMessage {...messages.optional} />}
      {!required && (minimum || maximum) && <span>,&nbsp;</span>}
      {(minimum || maximum) && (
        <FormattedMessage {...selectMessage} values={{ quantity: minimum === maximum ? minimum : false }} />
      )}
      {minimum !== maximum && (
        <>
          {minimum && <FormattedMessage {...messages.minimum} values={{ quantity: minimum }} />}
          {minimum && maximum && <span>,&nbsp;</span>}
          {maximum && <FormattedMessage {...messages.maximum} values={{ quantity: maximum }} />}
        </>
      )}
      )
    </span>
  );
};

MinMaxMessage.propTypes = {
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  required: PropTypes.bool,
};

export default MinMaxMessage;
