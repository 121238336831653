import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import globalMessages from 'components/globalMessages';

export const getRelativeDate = (date, currentDate = new Date()) => {
  const formatedDate = date?.includes(':') ? date : `${date}T00:00:00`;
  const dateObj = new Date(formatedDate);

  if (isToday(dateObj, currentDate)) return <FormattedMessage {...globalMessages.today} />;
  if (isTomorrow(dateObj, currentDate)) return <FormattedMessage {...globalMessages.tomorrow} />;
  return <FormattedDate value={new Date(dateObj)} year="numeric" month="long" day="numeric" />;
};

export const isToday = (date: Date, currentDate: Date = new Date(), timezone?: string): boolean => {
  const compareDate = convertDateToVenueTimezone(date, timezone);

  const currentDateAdjusted = convertDateToVenueTimezone(currentDate, timezone);
  return isSameDate(compareDate, currentDateAdjusted);
};

export const isTomorrow = (date: Date, currentDate: Date = new Date(), timezone?: string): boolean => {
  const compareDate = convertDateToVenueTimezone(date, timezone);
  const tomorrow = convertDateToVenueTimezone(new Date(currentDate.getTime()), timezone);

  tomorrow.setDate(tomorrow.getDate() + 1);

  return isSameDate(compareDate, tomorrow);
};

export const isSameDate = (date1: Date, date2: Date): boolean =>
  new Date(date1).setHours(0, 0, 0, 0) === new Date(date2).setHours(0, 0, 0, 0);

export const convertDateToVenueTimezone = (date: Date, tzString: string): Date =>
  new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString })
  );
