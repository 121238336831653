import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { selectLogoUrl, selectOrganisationName } from 'selectors/root';
import { Container, SignInOrOutButton, TitleInContent, BrandText, ModalFooter } from './styles';
import { FooterItem } from './components/FooterItem';

const AuraUserCard = ({ setPage, user }) => {
  const logoUrl = useSelector(selectLogoUrl);
  const companyName = useSelector(selectOrganisationName);
  return (
    <Container>
      <TitleInContent>
        <img src={logoUrl} alt={`${companyName} Logo`} width="120rem" />
      </TitleInContent>
      <div style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '0.9rem' }}>
        <BrandText>
          <FormattedMessage defaultMessage="You are signed into Aura" />
        </BrandText>
        <BrandText style={{ fontSize: '1.4rem' }}>{user.accountNo}</BrandText>
      </div>
      <ModalFooter>
        <FooterItem title={<FormattedMessage defaultMessage="Points Balance" />}>
          {user.points} pts
        </FooterItem>

        <FooterItem title={<FormattedMessage defaultMessage="Your Tier" />}>{user.tier}</FooterItem>

        <SignInOrOutButton
          label={<FormattedMessage defaultMessage="Sign out" />}
          onClick={() => setPage('signOut')}
        />
      </ModalFooter>
    </Container>
  );
};

AuraUserCard.propTypes = {
  setPage: PropTypes.func,
  user: PropTypes.shape({
    points: PropTypes.number,
    accountNo: PropTypes.string,
    tier: PropTypes.string,
  }),
};

export default AuraUserCard;
