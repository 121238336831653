import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, injectIntl } from 'react-intl';

import { selectHasFetchedDonations } from 'selectors/donations';
import { fetchDonations } from 'actions/donations';
import { isInitialized, getDetail } from 'selectors/storage';
import {
  isLoadingFulfilmentMethodTimeSlots,
  isOfflinePaymentMethod,
  isDineInOrderingFlow,
  isTabPaymentMethod,
  selectPaymentMethodCTAText,
} from 'selectors/root';
import Form from 'components/Form';
import { selectViolations } from 'selectors/payment';
import CurrentOrderSummary from 'components/OrderReview/OrderSummary/CurrentOrderSummary';
import { clearViolations } from 'actions/payment';
import PaymentError from 'components/PaymentError';
import { updateOrderDetails } from 'actions/order';
import { confirmOrder } from 'actions/order/submitOrder';
import { selectOrderFinalTotal, selectOrderIsSyncing, selectPromotionCouponApplied } from 'selectors/order';
import { kioskModeEnabled } from 'selectors/features';
import { clearDetails } from 'actions/storage';
import { RefreshIconSpin } from 'assets/styles/iconStyles';

import { FormContainer, SummaryContainer, InnerContainer, ButtonText } from './styles';
import { getCheckoutErrorMessage, hasAddressError, noTimeslotsError } from './checkoutErrorsUtil';
import { PaymentCTA } from './PaymentCTA';
import LoadingOverlay from './LoadingOverlay';

export const checkoutMessages = defineMessages({
  payNow: {
    defaultMessage: 'Pay Now: {price}',
  },
  completeOrder: {
    defaultMessage: 'Complete Order',
  },
  orderNow: {
    defaultMessage: 'Place Order: {price}',
  },
  createTab: {
    defaultMessage: 'Continue & Create Tab',
  },
  addTab: {
    defaultMessage: 'Continue & Add to Tab',
  },
  yourDetails: {
    defaultMessage: 'Your Details',
  },
});

const propTypes = {
  hasFetchedDonations: PropTypes.bool,
  fetchDonations: PropTypes.func,
  fields: PropTypes.array,
  fulfilmentMethod: PropTypes.instanceOf(Map).isRequired,
  updateOrderDetails: PropTypes.func,
  violations: PropTypes.instanceOf(Map),
  confirmOrder: PropTypes.func.isRequired,
  clearViolations: PropTypes.func,
  orderTotal: PropTypes.number,
  kioskMode: PropTypes.bool,
  clearDetails: PropTypes.func,
  checkoutErrorMessage: PropTypes.func,
  hasAddressError: PropTypes.bool,
  noTimeslotsError: PropTypes.bool,
  loadingTimeslots: PropTypes.bool,
  orderIsSyncing: PropTypes.bool,
  isDineInFlow: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  couponApplied: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  ctaLabel: PropTypes.string,
};

export class CheckoutForm extends React.Component {
  componentDidMount() {
    const { isDineInFlow, hasFetchedDonations, fetchDonations } = this.props;
    if (!isDineInFlow && !hasFetchedDonations) {
      fetchDonations();
    }

    this.updateFulfilmentMethod();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fulfilmentMethod.get('fulfilment_method_id') !==
      this.props.fulfilmentMethod.get('fulfilment_method_id')
    ) {
      this.updateFulfilmentMethod();
    }
  }

  componentWillUnmount() {
    if (this.props.kioskMode) this.props.clearDetails();
  }

  updateFulfilmentMethod = () => {
    this.props.updateOrderDetails();
  };

  onChange = (value, field) => {
    if (this.props.violations.get(field.errorKey || field.name)) {
      this.props.clearViolations(field.errorKey || field.name);
    }
  };

  onSubmit = (values, callback) => {
    this.props.confirmOrder(
      { ...values, fulfilment_method: this.props.fulfilmentMethod.get('id') },
      callback
    );
  };

  render() {
    const { ctaLabel } = this.props;
    const loading = this.props.loadingTimeslots || this.props.orderIsSyncing;
    const showManualError = this.props.noTimeslotsError || this.props.hasAddressError;
    const disabled = loading || showManualError;
    const { errorHeader, errorMessage } = this.props.checkoutErrorMessage(this.props.intl.formatMessage);

    return (
      <>
        {this.props.orderIsSyncing && <LoadingOverlay />}
        <FormContainer
          ref={c => {
            this.form = c;
          }}
        >
          <Form
            disabled={disabled}
            showManualError={showManualError}
            fields={this.props.fields}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            submitButtonIcon={loading && <RefreshIconSpin />}
            errors={this.props.violations.toJS()}
            errorHeader={errorHeader}
            errorMessage={errorMessage}
            submitButtonLabel={
              <ButtonText>
                <PaymentCTA
                  ctaLabel={ctaLabel}
                  orderTotal={this.props.orderTotal}
                  couponApplied={this.props.couponApplied}
                />
              </ButtonText>
            }
          >
            <PaymentError />
          </Form>
        </FormContainer>
        <SummaryContainer formHeight={this.form?.offsetHeight}>
          <InnerContainer>
            <CurrentOrderSummary />
          </InnerContainer>
        </SummaryContainer>
      </>
    );
  }
}

CheckoutForm.propTypes = propTypes;

const mapStateToProps = state => ({
  hasFetchedDonations: selectHasFetchedDonations(state),
  paymentMethodKey: getDetail(state, 'payment'),
  violations: selectViolations(state),
  checkoutErrorMessage: formatMessage => getCheckoutErrorMessage(state, formatMessage),
  hasAddressError: hasAddressError(state),
  noTimeslotsError: noTimeslotsError(state),
  initialized: isInitialized(state),
  orderTotal: selectOrderFinalTotal(state),
  isOfflineMethod: value => isOfflinePaymentMethod(state, value),
  isTabMethod: value => isTabPaymentMethod(state, value),
  kioskMode: kioskModeEnabled(state),
  loadingTimeslots: isLoadingFulfilmentMethodTimeSlots(state),
  orderIsSyncing: selectOrderIsSyncing(state),
  isDineInFlow: isDineInOrderingFlow(state),
  couponApplied: selectPromotionCouponApplied(state),
  ctaLabel: selectPaymentMethodCTAText(state, getDetail(state, 'payment')),
});

const mapDispatchToProps = dispatch => ({
  confirmOrder: (details, callback) => dispatch(confirmOrder(details, callback)),
  clearViolations: errorKey => dispatch(clearViolations(errorKey)),
  updateOrderDetails: () => dispatch(updateOrderDetails()),
  clearDetails: () => dispatch(clearDetails()),
  fetchDonations: () => dispatch(fetchDonations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckoutForm));
