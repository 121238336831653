import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { NutritionThemedIcon } from 'components/Icons';
import { openNutritionalInfo } from 'actions/UI';
import AdditionalButton from '../ProductAdditionalButton';

const messages = defineMessages({
  nutritional_information: {
    defaultMessage: 'Nutritional Information',
  },
  nutrition: {
    defaultMessage: 'Nutrition',
  },
});

const NutritionalInformationButton = ({ openNutritionalInfo, smallBtn }) => (
  <AdditionalButton onClick={openNutritionalInfo} smallBtn={smallBtn}>
    <NutritionThemedIcon width="24px" height="24px" />

    <span>
      {smallBtn ? (
        <FormattedMessage {...messages.nutrition} />
      ) : (
        <FormattedMessage {...messages.nutritional_information} />
      )}
    </span>
  </AdditionalButton>
);

NutritionalInformationButton.propTypes = {
  openNutritionalInfo: PropTypes.func,
  smallBtn: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, { product }) => ({
  openNutritionalInfo: () => dispatch(openNutritionalInfo(product)),
});

export default connect(undefined, mapDispatchToProps)(NutritionalInformationButton);
