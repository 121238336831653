import React from 'react';
import PropTypes from 'prop-types';

const ZomatoIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 180 180"
  >
    <rect fill="none" className="icon ZomatoIcon" width="180" height="180" />
    <g transform="translate(15 74)">
      <path
        fill="#e23744"
        className="icon ZomatoIcon"
        d="M53.577,193.41l-.138,4.368L42.051,210.159c4.757,0,7.776-.048,9.513-.145-.5,2.352-.914,4.271-1.326,7.15-2.286-.191-5.855-.239-9.422-.239-3.979,0-7.456.048-10.247.239l.091-4.414,11.39-12.333c-4.986,0-6.816.048-8.875.1.458-2.207.778-4.655,1.1-7.1,3.614.145,5.032.191,9.742.191C48.362,193.6,50.833,193.555,53.577,193.41Zm8.1,13.695c0,2.928.961,4.416,2.606,4.416,2.2,0,3.934-3.55,3.934-7.869,0-2.975-.961-4.414-2.562-4.414C63.457,199.236,61.673,202.737,61.673,207.1ZM76.63,203.3c0,7.966-5.626,14.589-13.082,14.589-6.678,0-10.109-4.559-10.109-10.508,0-7.917,5.673-14.54,13.082-14.54C73.29,192.837,76.63,197.4,76.63,203.3Zm89.635,3.806c0,2.928.961,4.416,2.606,4.416,2.2,0,3.934-3.55,3.934-7.869,0-2.975-.961-4.414-2.562-4.414C168.05,199.236,166.265,202.737,166.265,207.1Zm15.263-3.975c0,8.071-5.7,14.78-13.254,14.78-6.766,0-10.242-4.619-10.242-10.648,0-8.021,5.747-14.731,13.254-14.731C178.144,192.532,181.528,197.151,181.528,203.13ZM115.1,200.561c.6-4.08.274-7.775-4.254-7.775-3.293,0-6.86,2.784-9.377,7.437.549-3.839.229-7.437-4.254-7.437-3.385,0-7.045,2.927-9.56,7.775a24.935,24.935,0,0,0,.32-7.535,56.39,56.39,0,0,1-8.1.768,43.4,43.4,0,0,1-.458,7.821l-1.052,7.2c-.411,2.83-.869,6.1-1.326,8.158h8.508c.045-1.248.365-3.216.594-4.944l.732-4.943c.594-3.216,3.156-7.007,5.123-7.007,1.143,0,1.1,1.1.778,3.168l-.823,5.566c-.411,2.83-.869,6.1-1.326,8.158h8.6c.046-1.248.32-3.216.549-4.944l.732-4.943c.594-3.216,3.156-7.007,5.123-7.007,1.145,0,1.1,1.055.914,2.495l-2.054,14.4h7.847Zm41.09,9.644-.914,5.664a15.547,15.547,0,0,1-7.136,1.871c-5.215,0-6.266-2.782-5.444-8.685l1.326-9.5h-2.565l.726-6.149,2.8-.136,1.052-4.464,7.912-2.975-1.005,7.439h5.444c-.184.768-.823,4.991-1.007,6.285h-5.306l-1.19,8.782c-.32,2.255-.136,3.071,1.419,3.071A10.5,10.5,0,0,0,156.187,210.206Z"
        transform="translate(-30.57 -185.83)"
      />
      <path
        fill="#e23744"
        className="icon ZomatoIcon"
        d="M537.048,243.206c2.878-.353,4.86-3.13,5.337-5.9l.081-.742a14.585,14.585,0,0,0-4.76-.275,5.468,5.468,0,0,0-3.765,1.883,3.386,3.386,0,0,0-.714,2.582A3.264,3.264,0,0,0,537.048,243.206Zm-2.453,4.388c-4.063.5-6.74-1.114-7.544-4.784a8.331,8.331,0,0,1,1.42-6.507,11.2,11.2,0,0,1,7.571-3.77,21.766,21.766,0,0,1,6.9.229l.086-.355a7.657,7.657,0,0,0,.034-1.813c-.213-1.737-1.587-2.775-4.972-2.359a17.319,17.319,0,0,0-6.149,2.047l-1.641-4.963a22.6,22.6,0,0,1,8.474-2.718c6.306-.775,10.736,1.238,11.3,5.858a15.081,15.081,0,0,1,.014,3.715q-1.216,8.573-1.556,12.9a22.99,22.99,0,0,0,.009,2.3l-7.824-.007a10.95,10.95,0,0,0,.448-1.827,16.762,16.762,0,0,0,.191-1.911A9.533,9.533,0,0,1,534.595,247.595Z"
        transform="translate(-441.441 -216.137)"
      />
    </g>
  </svg>
);

ZomatoIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ZomatoIcon.defaultProps = {
  width: '50px',
  height: '50px',
};

export default ZomatoIcon;
