import isEmpty from 'lodash/isEmpty';

import { DIGIT_1, DIGIT_2, DIGIT_3, DIGIT_4 } from 'appConstants';

const passcodeTransformer = obj => {
  if (!obj || isEmpty(obj)) return [];

  const passcodeArray = [obj[DIGIT_1], obj[DIGIT_2], obj[DIGIT_3], obj[DIGIT_4]].join('').split('');

  return passcodeArray;
};

export default passcodeTransformer;
