import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Fuse from 'fuse.js';

import CloseIcon from 'components/Icons/CloseIcon';
import globalMessages from 'components/globalMessages';

import {
  StyledSearch,
  StyledSearchIcon,
  SearchInput,
  StyledCloseIcon,
  ResultsContainer,
  Results,
  NoResult,
} from './styles';
import { addCssPrefixTo } from '../../utils';

class Search extends React.Component {
  state = {
    searchTerm: '',
    searchResults: [],
    showResults: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showResults: false });
      if (this.props.onBlur) this.props.onBlur();
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  onChange = searchTerm => {
    this.setState({ searchTerm });
    let searchResults = [];
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    if (searchTerm.length >= 1) {
      const fuse = new Fuse(this.props.searchData, {
        keys: this.props.searchKeys,
        location: 0,
        distance: 500,
        threshold: 0.3,
        includeMatches: true,
      });
      searchResults = fuse.search(searchTerm);
    }

    this.setState({ searchResults });

    if (this.props.onChange) this.props.onChange(searchTerm);
  };

  onClear = () => {
    this.setState({ searchTerm: '' });
    this.onChange('');
    this.props.onClear();
  };

  onFocus = () => {
    this.setState({ showResults: true });
    if (this.props.onFocus) this.props.onFocus();
  };

  render() {
    const { searchResults, showResults } = this.state;
    const { intl, filtersActive } = this.props;

    return (
      <StyledSearch className={addCssPrefixTo('SEARCH_BAR')} ref={this.setWrapperRef}>
        <StyledSearchIcon width="24px" height="24px" />
        <SearchInput
          onFocus={this.onFocus}
          onChange={e => this.onChange(e.target.value)}
          value={this.state.searchTerm}
          placeholder={intl.formatMessage(globalMessages.searchMenu)}
        />
        {this.state.searchTerm && (
          <StyledCloseIcon onClick={this.onClear}>
            <CloseIcon width="24px" height="24px" />
          </StyledCloseIcon>
        )}
        {this.state.searchTerm && showResults && (
          <ResultsContainer>
            <Results>
              {!!searchResults &&
                searchResults.map((result, index) => (
                  <React.Fragment key={index}>{this.props.render(result.item, index)}</React.Fragment>
                ))}
              {!searchResults.length && (
                <NoResult>
                  <span>
                    <FormattedMessage {...globalMessages.noResults} />
                  </span>
                  {filtersActive && (
                    <span>
                      &nbsp;
                      <FormattedMessage {...globalMessages.checkFilters} />
                    </span>
                  )}
                </NoResult>
              )}
            </Results>
          </ResultsContainer>
        )}
      </StyledSearch>
    );
  }
}

Search.propTypes = {
  searchKeys: PropTypes.array.isRequired,
  searchData: PropTypes.array.isRequired,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  filtersActive: PropTypes.bool,
  render: PropTypes.func,
};

export default injectIntl(Search);
