import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import ComoLogo from 'components/Icons/ComoLogo';
import { useDispatch } from 'react-redux';
import { openLoyaltyModal } from 'actions/loyalty';

interface CheckoutLoyaltyLoginProps {
  type: string;
  user?: Object;
}

const LoyaltyAccountBanner = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1.5px solid ${({ theme }) => theme.color.brandSecondary};
  margin-block-end: 1rem;

  > button {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    text-align: start;
  }
`;

const CheckoutLoyaltyLogin: React.FC<CheckoutLoyaltyLoginProps> = ({ type, user }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    openLoyaltyModal()(dispatch);
  };

  return (
    <LoyaltyAccountBanner>
      <button type="button" onClick={handleClick}>
        <div style={{ width: '65px' }}>
          <ComoLogo width={65} height={65} />
        </div>
        <div>
          {user && type === 'como' && (
            <FormattedMessage defaultMessage="To redeem a reward, make sure you have added the corresponding item to the basket first." />
          )}
          {!user && <FormattedMessage defaultMessage="Sign in or create an account to earn rewards" />}
        </div>
      </button>
    </LoyaltyAccountBanner>
  );
};

export default CheckoutLoyaltyLogin;
