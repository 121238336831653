import fetchHelper from 'utils/fetchHelper';

import {
  LOADING_FULFILMENT_TIMESLOTS,
  SET_FULFILMENT_TIMESLOTS,
  SET_VENUE_OFFLINE,
} from 'actions/venue/constants';

import { selectServiceId } from 'selectors/browse';

export const getFulfilmentMethodTimeSlots = methodId => (dispatch, getState) => {
  const state = getState();
  const serviceId = selectServiceId(state);

  dispatch({
    type: LOADING_FULFILMENT_TIMESLOTS,
    serviceId,
    methodId,
  });

  const setTimeslots = timeslots => {
    dispatch({
      type: SET_FULFILMENT_TIMESLOTS,
      serviceId,
      methodId,
      timeslots: timeslots || {},
    });
  };

  const successCallback = res => setTimeslots(res);
  const failCallback = () => setTimeslots({});

  fetchHelper(`/api/fulfilment-methods/${methodId}/time-slots`, 'GET', null, successCallback, failCallback);
};

export const setVenueOffline = isOffline => ({
  type: SET_VENUE_OFFLINE,
  offline: isOffline,
});
