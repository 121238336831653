import React from 'react';
import PropTypes from 'prop-types';

const GuestDirectoryIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 205.000000 205.000000"
  >
    <g transform="translate(0.000000,205.000000) scale(0.100000,-0.100000)" fill={fill}>
      <path
        className="icon"
        fill={fill}
        d="M115 2031 c-48 -22 -79 -54 -100 -103 -13 -33 -15 -144 -15 -905 0
-823 1 -870 19 -908 22 -48 54 -79 103 -100 33 -13 144 -15 903 -15 759 0 870
2 903 15 49 21 81 52 103 100 18 38 19 85 19 908 0 761 -2 872 -15 905 -21 49
-52 81 -100 103 -38 18 -85 19 -910 19 -825 0 -872 -1 -910 -19z m1787 -128
c17 -15 18 -56 18 -876 0 -787 -1 -862 -17 -879 -15 -17 -56 -18 -876 -18
-787 0 -862 1 -879 17 -17 15 -18 56 -18 876 0 787 1 862 17 879 15 17 56 18
876 18 787 0 862 -1 879 -17z"
      />
      <path
        className="icon"
        fill={fill}
        d="M402 1588 c-8 -8 -12 -49 -12 -120 0 -130 -6 -125 142 -118 l103 5 3
109 c2 71 -1 113 -9 122 -15 18 -209 20 -227 2z"
      />
      <path d="M740 1470 l0 -60 460 0 460 0 0 60 0 60 -460 0 -460 0 0 -60z" />
      <path
        className="icon"
        fill={fill}
        d="M394 1137 c-2 -7 -3 -62 -2 -123 l3 -109 120 0 120 0 0 120 0 120
-118 3 c-91 2 -119 0 -123 -11z"
      />
      <path d="M740 1025 l0 -65 460 0 460 0 0 65 0 65 -460 0 -460 -1 0 -64z" />
      <path
        className="icon"
        fill={fill}
        d="M397 694 c-12 -13 -8 -219 5 -232 18 -18 212 -16 227 2 8 9 11 51 9
122 l-3 109 -116 3 c-63 1 -118 -1 -122 -4z m213 -119 l0 -95 -95 0 -95 0 0
95 0 95 95 0 95 0 0 -95z"
      />
      <path d="M740 580 l0 -60 460 0 460 0 0 60 0 60 -460 0 -460 0 0 -60z" />
    </g>
  </svg>
);

GuestDirectoryIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

GuestDirectoryIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default GuestDirectoryIcon;
