import styled, { css } from 'styled-components';
import { fontSizeBig, fontSizeMedium } from 'assets/styles/fonts';

const sharedTextStyles = css`
  ${fontSizeBig};
  color: ${({ theme }) => theme.color.darkGrey};
`;

export const MainContainer = styled.div`
  padding-top: 3rem;
  height: 100%;
  display: flex;
`;

export const VenuesContainer = styled.div`
  padding: 10px;
  padding-bottom: 60px;
  margin: auto;
  border-radius: 21px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;

  @media (min-width: 400px) {
    width: 95%;
  }

  @media (min-width: 700px) {
    width: 70%;
  }

  @media (min-width: 1000px) {
    margin: 0;
    width: 50%;
    border-radius: 0;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 50%;

  ${props =>
    props.small &&
    `
    margin: 10px 0;
    width: 100%;
    height: 200px;
  `}

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

export const MainTitle = styled.div`
  ${sharedTextStyles}
`;

export const LocationButton = styled.button`
  background-color: ${({ theme, located }) => (located ? theme.color.danger : theme.color.white)};
  border: 1px solid ${({ theme, located }) => (located ? theme.color.danger : theme.color.borderGray)};
  width: 100%;
  color: ${({ theme, located }) => (located ? theme.color.white : theme.color.darkGrey)};
  padding: 8px;
  font-size: 1.2em;
  height: 42px;
  border-radius: 21px;
  ${fontSizeMedium};
  margin-left: 5px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }
`;

export const LocationIconContainer = styled.span`
  color: ${({ theme, located }) => (located ? theme.color.white : theme.color.success)};
`;

export const Text = styled.span`
  margin-left: 5px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }
`;

export const NoResults = styled.div`
  ${sharedTextStyles};

  margin: 1em auto;
`;
