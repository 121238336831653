import { LOGIN, LOGOUT, SET_ONLINE } from 'actions/user/constants';
import { getPaymentMethods } from 'actions/payment';

export default ({ dispatch }) =>
  next =>
  action => {
    const result = next(action);

    if (
      action.type === LOGIN ||
      action.type === LOGOUT ||
      action.type === SET_ONLINE // needed to get the methods on page load and for change of ip address
    ) {
      dispatch(getPaymentMethods());
    }

    return result;
  };
