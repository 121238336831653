import fetchHelper from 'utils/fetchHelper';
import fieldTypes from 'components/Form/fieldTypes';
import { setDetail } from 'actions/storage';
import { setShowExitButton } from 'actions/features';
import { RECEIVE_EXTERNAL_LINKS } from './constants';

export const getExternalLinks = () => dispatch => {
  const success = res => dispatch({ type: RECEIVE_EXTERNAL_LINKS, payload: res });
  fetchHelper(`/api/external-links`, 'GET', null, success);
};

export const checkLocationQueryParams =
  (query, setCookie = () => {}) =>
  dispatch => {
    if (!query || !Object.keys(query).length) return;

    Object.keys(query).forEach(param => {
      // set field values from QR code
      if (fieldTypes[param] && fieldTypes[param].storeDetail) {
        const expiry = fieldTypes[param].expiry;
        dispatch(setDetail(param, query[param], expiry));
      }

      if (param.indexOf('passcode_') === 0) {
        dispatch(setDineInPasscode(param.replace('passcode_', ''), query[param]));
      }

      if (param === 'showExitButton' && query[param] === '1') {
        setCookie('showExitButton', true);
        dispatch(setShowExitButton(true));
      }
    });
  };

export const setDineInPasscode = (serviceId, passcode) => dispatch => {
  dispatch(setDetail(`passcode_${serviceId}`, passcode, { format: 'hours', length: 1 }));
};
