import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ElvIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391	C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55	c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M557.2,327.3c14.6-89.5,29.2-178.9,43.9-268.6c18.8,0,37.3,0,56.5,0c-24.4,127.9-48.8,255.5-73.2,383.2 c-19,0-37.6,0-56.5,0c-24.4-127.6-48.7-255.1-73.2-383c18.8,0,37.5,0,56.8,0c14.5,89.3,29.1,178.8,43.7,268.4	C555.9,327.2,556.5,327.2,557.2,327.3z"
      />
      <path
        className="icon"
        fill={fill}
        d="M277.1,59.2c0,15.9,0,31.5,0,48.1c-2.1,0-4.3,0-6.5,0c-28.5,0-56.9,0.1-85.4-0.1c-6-0.1-7.9,1.6-7.9,7.8 c0.3,33.3,0.3,66.6,0,99.8c0,5.9,1.9,7.5,7.6,7.4c25.9-0.2,51.9-0.1,78.3-0.1c0,15.6,0,31,0,47.2c-2.1,0-4.3,0-6.5,0 c-23.9,0-47.7,0.2-71.6-0.1c-6.2-0.1-7.7,2.1-7.7,8c0.2,35.8,0.2,71.6,0,107.4c0,5.9,2,7.4,7.5,7.3c29.3-0.2,58.6-0.1,87.9-0.1 c2,0,4.1,0,6.3,0c0,16.4,0,32.3,0,48.6c-52,0-104.2,0-156.6,0c0-127,0-253.9,0-381.2C174.1,59.2,225.5,59.2,277.1,59.2z"
      />
      <path
        className="icon"
        fill={fill}
        d="M319.8,441.8c0-128,0-255.7,0-383.7c19,0,37.9,0,57.5,0c0,110.7,0,221.3,0,332.4c31.3,0,62,0,93.1,0	c0,17.3,0,34.1,0,51.3C420.4,441.8,370.4,441.8,319.8,441.8z"
      />
    </g>
  </svg>
);

ElvIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

ElvIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default ElvIcon;
