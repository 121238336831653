import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { useLoyalty, usePrevious } from 'hooks';
import { viewTerms } from 'actions/order';
import Checkbox from 'components/Checkbox';
import { getDetail } from 'selectors/storage';
import { StyledCheckbox } from './styles';

const messages = defineMessages({
  termsAndConditions: {
    defaultMessage: 'Terms & Conditions',
  },
  termsAndConditionsLabel: {
    defaultMessage: 'I have read and accept the',
  },
  loyaltyLabel: {
    defaultMessage:
      'By checking this box, I agree to <link>wi-Q Technologies</link> and { vapianoTermsAndConditionsLink } terms and conditions. This is required to continue with my order.',
  },
});

const propTypes = {
  termsAccepted: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  viewTerms: PropTypes.func,
};

const TermsCheckbox = ({ termsAccepted, value, onChange, onBlur, viewTerms }) => {
  const [prevTermsAccepted] = usePrevious(termsAccepted);
  const { type } = useLoyalty();

  useEffect(() => {
    if (prevTermsAccepted !== termsAccepted && termsAccepted === true) {
      onChange(termsAccepted);
      onBlur(termsAccepted);
    }
  }, [prevTermsAccepted, termsAccepted, onChange, onBlur]);

  return (
    <StyledCheckbox>
      {type !== 'como' && (
        <>
          <Checkbox
            id="acceptsTerms"
            checked={value || termsAccepted === 'true'}
            onChange={event => {
              onChange(event.target.checked);
              onBlur(event.target.checked);
            }}
          >
            <FormattedMessage {...messages.termsAndConditionsLabel} />
          </Checkbox>
          <button type="button" className="linkUnderlined" onClick={viewTerms}>
            <FormattedMessage {...messages.termsAndConditions} />
          </button>
        </>
      )}
      {type === 'como' && (
        <>
          <Checkbox
            id="acceptsTerms"
            checked={value || termsAccepted === 'true'}
            onChange={event => {
              onChange(event.target.checked);
              onBlur(event.target.checked);
            }}
          />
          <p>
            <FormattedMessage
              {...messages.loyaltyLabel}
              values={{
                link: parts => (
                  <button
                    type="button"
                    style={{ display: 'inline' }}
                    className="linkUnderlined"
                    onClick={viewTerms}
                  >
                    {parts}
                  </button>
                ),
                vapianoTermsAndConditionsLink: (
                  <a
                    className="linkUnderlined"
                    href="https://app4mobilebiz.wpengine.com/business-end-user-terms-of-use.html"
                    target="_blank"
                  >
                    Vapiano UK
                  </a>
                ),
              }}
            />
          </p>
        </>
      )}
    </StyledCheckbox>
  );
};

TermsCheckbox.propTypes = propTypes;

export default connect(
  state => ({
    termsAccepted: getDetail(state, 'terms'),
  }),
  { viewTerms }
)(TermsCheckbox);
