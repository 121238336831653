import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'assets/styles/sharedStyles';
import { Container, TextAreaStyled } from './styles';

class TextArea extends React.Component {
  componentDidMount() {
    const { reference } = this.props;
    if (reference) reference(this.textarea);
  }

  render = () => {
    const props = { ...this.props };
    delete props.reference;
    delete props.label;

    return (
      <Container>
        <Label asPlaceholder={!this.props.value} required={this.props.required}>
          {this.props.label}
        </Label>
        <TextAreaStyled
          {...props}
          name={this.props.name}
          rows={3}
          ref={node => {
            this.textarea = node;
          }}
          onChange={e => this.props.onChange(e.target.value)}
          className={this.props.className}
          value={this.props.value}
        />
      </Container>
    );
  };
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  reference: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
};

export default TextArea;
