import React from 'react';
import PropTypes from 'prop-types';

// Card Type Icon
const EloIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,500H55c-30.3,0-55-24.7-55-55V54C0,23.7,24.7-1,55-1h670c30.3,0,55,24.7,55,55v391 C780,475.3,755.3,500,725,500z M55,29c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V54 c0-13.8-11.2-25-25-25H55z"
      />

      <path
        className="icon"
        fill={fill}
        d="M150.7 170.6c6.8-2.3 14.1-3.5 21.7-3.5 33.2 0 60.9 23.6 67.2 54.9l47-9.6c-10.8-53.2-57.8-93.3-114.2-93.3-12.9 0-25.3 2.1-36.9 6l15.2 45.5z"
      />

      <path
        className="icon"
        fill={fill}
        d="M95.2 323l31.8-36c-14.2-12.6-23.1-30.9-23.1-51.4 0-20.4 8.9-38.8 23.1-51.3l-31.8-35.9c-24.1 21.4-39.3 52.5-39.3 87.3 0 34.7 15.2 65.9 39.3 87.3z"
      />

      <path
        className="icon"
        fill={fill}
        d="M239.6 249.4c-6.4 31.3-34 54.8-67.2 54.8-7.6 0-14.9-1.2-21.8-3.5l-15.2 45.5c11.6 3.9 24.1 6 37 6 56.4 0 103.4-40 114.2-93.2l-47-9.6z"
      />

      <path
        className="icon"
        fill={fill}
        d="M443.2 281.6c-7.8 7.6-18.3 12.2-29.9 12-8-.1-15.4-2.5-21.6-6.5l-15.6 24.8c10.7 6.7 23.2 10.7 36.8 10.9 19.7.3 37.7-7.5 50.8-20.2l-20.5-21zM415 180.5c-39.2-.6-71.6 30.8-72.2 70-.2 14.7 4 28.5 11.5 39.9l128.8-55.1c-7.2-30.9-34.8-54.2-68.1-54.8zm-42.7 75.6c-.2-1.6-.3-3.3-.3-5 .4-23.1 19.4-41.6 42.5-41.2 12.6.2 23.8 5.9 31.3 14.9l-73.5 31.3zm151.3-107.6v137.3l23.8 9.9-11.3 27.1-23.6-9.8c-5.3-2.3-8.9-5.8-11.6-9.8-2.6-4-4.6-9.6-4.6-17V148.5h27.3zm85.9 63.5c4.2-1.4 8.6-2.1 13.3-2.1 20.3 0 37.1 14.4 41 33.5l28.7-5.9c-6.6-32.5-35.3-56.9-69.7-56.9-7.9 0-15.5 1.3-22.5 3.6l9.2 27.8zm-33.9 92.9L595 283c-8.7-7.7-14.1-18.9-14.1-31.4s5.5-23.7 14.1-31.3l-19.4-21.9c-14.7 13-24 32.1-24 53.3 0 21.2 9.3 40.2 24 53.2zm88.2-44.8c-3.9 19.1-20.8 33.5-41 33.5-4.6 0-9.1-.8-13.3-2.2l-9.3 27.8c7.1 2.4 14.7 3.7 22.6 3.7 34.4 0 63.1-24.4 69.7-56.9l-28.7-5.9z"
      />
    </g>
  </svg>
);

EloIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

EloIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default EloIcon;
