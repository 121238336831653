import { Map, List, fromJS } from 'immutable';

import {
  OPEN_ALERT,
  CLOSE_ALERT,
  CLEAR_ALERTS,
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_LAST_MENU_PAGE,
  CLEAR_LAST_MENU_PAGE,
  SET_IS_SETTINGS_PAGE,
  POP_INFO_AND_CUSTOMISATION,
  UPDATE_LOGIN_REGISTER_ALERT,
} from 'actions/UI/constants';

export const initialState = fromJS({
  alertStack: [],
  notificationStack: [],
  lastMenuPage: undefined,
  isSettingsPage: false,
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case POP_INFO_AND_CUSTOMISATION:
      return state.update('alertStack', alertStack => alertStack.skipLast(2));
    case UPDATE_LOGIN_REGISTER_ALERT: {
      const index = state
        .get('alertStack')
        .findIndex(alert => alert.get('component') === 'LOGIN_REGISTER_ALERT');
      return state.setIn(['alertStack', index, 'props', 'section'], action.section);
    }
    case OPEN_ALERT:
      if (typeof window === 'undefined') return state; // don't open alerts on server load
      return state.update('alertStack', alertStack =>
        alertStack.push(new Map({ component: action.alertType, props: action.alertProps }))
      );
    case CLOSE_ALERT:
      return state.update('alertStack', alertStack => alertStack.splice(action.alertIndex, 1));
    case CLEAR_ALERTS:
      return state.set('alertStack', new List());

    case OPEN_NOTIFICATION:
      if (typeof window === 'undefined') return state;

      return state.update('notificationStack', notificationStack =>
        notificationStack.push(
          new Map({
            component: action.notificationType,
            props: action.notificationProps,
          })
        )
      );
    case CLOSE_NOTIFICATION: {
      return state.update('notificationStack', notificationStack =>
        notificationStack.splice(action.notificationIndex, 1)
      );
    }

    case SET_LAST_MENU_PAGE:
      return state.set('lastMenuPage', action.menuPage);
    case CLEAR_LAST_MENU_PAGE:
      return state.set('lastMenuPage', null);
    case SET_IS_SETTINGS_PAGE:
      return state.set('isSettingsPage', action.payload);
    default:
      return state;
  }
};

export default appReducer;
