import { TCoordinates } from 'types';
import calculateDistance from '../../shared/sortVenues/calculateDistance';

const isAddressUserInRange = (
  radius: number,
  userCoords: TCoordinates,
  venueCoords: TCoordinates
): boolean => {
  if (!radius || !userCoords || !venueCoords) return false;

  const distance = calculateDistance(userCoords, venueCoords);

  return distance && distance.toFixed(3) <= radius;
};

export default isAddressUserInRange;
