import { defineMessages } from 'react-intl';

export default defineMessages({
  location: {
    defaultMessage:
      'Your order for { location } {locationValue } is being processed and will be with you {date, select, today {today} tomorrow {tomorrow} noDate {} other {on { date }}} {time, select, asap {as soon as possible} noTime {} other {at { time } } }',
  },
  singleCollection: {
    defaultMessage:
      'Your order will be ready to collect {date, select, today {today} tomorrow {tomorrow} noDate {} other {on { date }}} {time, select, asap {as soon as possible} noTime {} other {at { time } } }',
  },
  collection: {
    defaultMessage:
      'Your order will be ready to collect from { collectionPoint } { pointValue } {date, select, today {today} tomorrow {tomorrow} noDate {} other {on { date }}} {time, select, asap {as soon as possible} noTime {} other {at { time } } }',
  },
  address: {
    defaultMessage:
      'Your order will be delivered to { address } {date, select, today {today} tomorrow {tomorrow} noDate {} other {on { date }}} {time, select, asap {as soon as possible} noTime {} other {at { time } } }',
  },
});
