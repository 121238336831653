import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import SystemIcon from 'components/SystemIcon';

import { AlertMessage } from './styles';

const messagePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.element]);

const propTypes = {
  mainMessage: messagePropType,
  headerMessage: messagePropType,
  cancelMessage: messagePropType,
  confirmMessage: messagePropType,
  closeAlert: PropTypes.func,
  confirmMethod: PropTypes.func,
  messages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
    })
  ),
  config: PropTypes.object,
  Icon: PropTypes.func,
  IconFill: PropTypes.string,
};

const defaultProps = {
  closeAlert: () => undefined,
  confirmMethod: () => undefined,
  messages: {},
};

const StandardAlert = ({
  closeAlert,
  confirmMethod,
  headerMessage,
  mainMessage,
  confirmMessage,
  cancelMessage,
  messages,
  config,
  Icon,
  IconFill,
}) => {
  const header = headerMessage || (messages.header && <FormattedMessage {...messages.header} />);
  const message = mainMessage || (messages.message && <FormattedMessage {...messages.message} />);
  const confirm = confirmMessage || (messages.confirm && <FormattedMessage {...messages.confirm} />);
  const cancel =
    cancelMessage ||
    (messages.cancel ? (
      <FormattedMessage {...messages.cancel} />
    ) : (
      <FormattedMessage {...globalMessages.cancel} />
    ));
  const twoButtons = !config || (config && config.buttons === 2);
  return (
    <>
      <AlertContent>
        {Icon && <SystemIcon Icon={Icon} fill={IconFill} />}
        <AlertTitle>{header}</AlertTitle>
        {message && <AlertMessage>{message}</AlertMessage>}
      </AlertContent>

      <AlertFooter>
        <AlertButtons>
          {twoButtons && <ActionButton label={cancel} onClick={closeAlert} dangerButton={true} />}
          <ActionButton label={confirm} onClick={() => closeAlert(confirmMethod)} />
        </AlertButtons>
      </AlertFooter>
    </>
  );
};

StandardAlert.propTypes = propTypes;
StandardAlert.defaultProps = defaultProps;

export default StandardAlert;
export { StandardAlert };
