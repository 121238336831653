import React from 'react';
import PropTypes from 'prop-types';

// Icon
const SoyaIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon soyaIcon"
        fill={fill}
        opacity={opacity}
        d="M717.3,401.9c-31.9,0-61.8-12.4-84.3-34.9c-22.5-22.5-34.9-52.4-34.9-84.3s12.4-61.9,34.9-84.3	c22.5-22.5,52.5-34.9,84.3-34.9c31.9,0,61.8,12.4,84.3,34.9c22.5,22.5,34.9,52.5,34.9,84.3c0,31.9-12.4,61.8-34.9,84.3	C779.2,389.5,749.2,401.9,717.3,401.9z M717.3,210c-19.4,0-37.7,7.6-51.4,21.3c-13.7,13.7-21.3,32-21.3,51.4s7.5,37.7,21.3,51.4	c13.7,13.7,32,21.3,51.4,21.3c19.4,0,37.7-7.6,51.4-21.3c13.7-13.7,21.3-32,21.3-51.4c0-19.4-7.6-37.7-21.3-51.4 C755,217.5,736.7,210,717.3,210z"
      />
      <path
        className="icon soyaIcon"
        fill={fill}
        opacity={opacity}
        d="M500,619.3c-31.9,0-61.8-12.4-84.3-34.9c-22.5-22.5-34.9-52.4-34.9-84.3c0-31.9,12.4-61.9,34.9-84.3	c22.5-22.5,52.5-34.9,84.3-34.9c31.9,0,61.8,12.4,84.3,34.9c22.5,22.5,34.9,52.4,34.9,84.3s-12.4,61.9-34.9,84.3	C561.8,606.9,531.9,619.3,500,619.3z M500,427.3c-19.4,0-37.7,7.6-51.4,21.3c-13.7,13.7-21.3,32-21.3,51.4	c0,19.4,7.5,37.7,21.3,51.4c13.7,13.7,32,21.3,51.4,21.3c19.4,0,37.7-7.6,51.4-21.3c13.7-13.7,21.3-32,21.3-51.4	c0-19.4-7.5-37.7-21.3-51.4C537.7,434.9,519.4,427.3,500,427.3z"
      />
      <path
        className="icon soyaIcon"
        fill={fill}
        opacity={opacity}
        d="M222,983c-54.5,0-108.4-13.7-156-39.6l-6-3.3l-3.3-6c-33-60.6-46-131.8-36.6-200.5c9.7-71,41.7-135.4,92.6-186.3	c36.9-36.9,82.7-64.8,132.3-80.4l3.5-1.1l1.1-3.5c15.7-49.6,43.5-95.4,80.4-132.3c36.9-36.9,82.7-64.8,132.3-80.4l3.5-1.1l1.1-3.5	c15.7-49.6,43.5-95.4,80.4-132.3C608.9,50.9,690.9,17,778,17c54.5,0,108.4,13.7,156,39.6l6,3.3l3.3,6c33,60.6,46,131.8,36.6,200.5	c-9.7,71-41.7,135.4-92.6,186.3c-36.9,36.9-82.7,64.8-132.3,80.4l-3.5,1.1l-1.1,3.5c-15.7,49.6-43.5,95.4-80.4,132.3	c-36.9,36.9-82.7,64.8-132.3,80.4l-3.5,1.1l-1.1,3.5c-15.7,49.6-43.5,95.4-80.4,132.3C391.1,949.1,309.1,983,222,983L222,983z	 M273.1,507.2c-48.1,12.4-92.2,37.7-127.6,73c-41.6,41.6-68.6,94.7-78.1,153.5C58,792.4,67,851.2,93.3,903.6l1,2.1l2.1,1 c38.7,19.4,82.1,29.7,125.6,29.7c74.7,0,145-29.1,197.8-81.9c35.3-35.3,60.6-79.5,73-127.6l3.4-13.3l13.3-3.4	c48.1-12.4,92.2-37.7,127.6-73c35.3-35.3,60.6-79.5,73-127.6l3.4-13.3l13.3-3.4c48.1-12.4,92.2-37.7,127.6-73	c41.6-41.6,68.6-94.7,78.1-153.5c9.4-58.7,0.5-117.4-25.8-169.9l-1-2.1l-2.1-1C864.9,73.9,821.5,63.6,778,63.6	c-74.7,0-145,29.1-197.8,81.9c-35.3,35.3-60.6,79.5-73,127.6l-3.4,13.3l-13.3,3.4c-48.1,12.4-92.2,37.7-127.6,73	c-35.3,35.3-60.6,79.5-73,127.6l-3.4,13.3L273.1,507.2z"
      />
      <path
        className="icon soyaIcon"
        fill={fill}
        opacity={opacity}
        d="M282.7,836.6c-31.9,0-61.8-12.4-84.3-34.9c-22.5-22.5-34.9-52.5-34.9-84.3c0-31.9,12.4-61.8,34.9-84.3	c22.5-22.5,52.5-34.9,84.3-34.9c31.9,0,61.8,12.4,84.3,34.9c22.5,22.5,34.9,52.4,34.9,84.3s-12.4,61.9-34.9,84.3		C344.5,824.2,314.5,836.6,282.7,836.6z M282.7,644.7c-19.4,0-37.7,7.6-51.4,21.3c-13.7,13.7-21.3,32-21.3,51.4	c0,19.4,7.6,37.7,21.3,51.4c13.7,13.7,32,21.3,51.4,21.3c19.4,0,37.7-7.6,51.4-21.3c13.7-13.7,21.3-32,21.3-51.4	c0-19.4-7.5-37.7-21.3-51.4C320.3,652.2,302.1,644.7,282.7,644.7z"
      />
    </g>
  </svg>
);

SoyaIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

SoyaIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default SoyaIcon;
