import React from 'react';
import PropTypes from 'prop-types';

const MinusIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M15,127c-2.75,0-5-2.25-5-5v-4c0-2.75,2.25-5,5-5h210c2.75,0,5,2.25,5,5v4c0,2.75-2.25,5-5,5H15z"
      />
    </g>
  </svg>
);

MinusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

MinusIcon.defaultProps = {
  width: '24px',
  height: '24px',
  fill: 'currentColor',
};

export default MinusIcon;
