import React from 'react';

interface ComoIconProps {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const ComoIcon: React.FC<ComoIconProps> = ({
  className,
  width = '100%',
  height = '100%',
  fill = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 190 200"
    xmlSpace="preserve"
    className={className}
  >
    <path
      d="m86.51 182.65-1.64-4.2c-.71-.94-2.79-3.55-8.82-10.76l-6.85-8.15a8604 8604 0 0 0-34.52-40.56l-.56-.66-.4-.76s-3.79-7.17-8.88-16.74l-.24-.46-.18-.49a169.72 169.72 0 0 0-5.46-12.61c-2.01-4.2-4.17-8.45-6.26-12.56C5.76 61.07 1.49 52.38 1.18 45.91c-.06-1.3.03-2.49.27-3.62.64-2.93 1.85-7 3.7-12.45 2.26-6.65 4.63-12.81 4.73-13.07l.77-2 1.69-1.32C26.16 2.61 46.16-.38 47.01-.51l2.2-.32 23.9 10.55L94.88 34l21.35-20.75 33.54-15.1 2.35.41c.64.11 15.8 2.8 24.15 6.11l.37.15.36.18c7.36 3.85 9.7 11.37 11.76 18 .28.9.56 1.81.87 2.74 2.91 8.96 6.18 18.73 6.18 18.74l1.07 3.21-1.62 2.97a958.91 958.91 0 0 1-17.39 30.08 651.47 651.47 0 0 1-20.47 31.96c-4.64 6.74-9 12.66-12.97 17.62a154.1 154.1 0 0 1-7.02 8.21c-3.92 4.29-8.36 9.13-12.81 13.95-27.45 29.7-28.54 30.11-32.52 30.14l-5.57.03"
      fill="#fff"
    />
    <path
      d="M182.25 28.14c-2.34-7.19-3.64-13.54-8.84-16.27-7.71-3.06-22.63-5.68-22.63-5.68l-30.12 13.56L94.5 45.18 78.51 27.37l-7.8-8.69-2.27-2.53L48.1 7.17s-18.64 2.7-30.99 12.38c0 0-2.38 6.2-4.63 12.78a124.07 124.07 0 0 0-3.47 11.59c-.1.47-.13 1-.1 1.6.35 7.11 9.64 22.91 17.03 38.39a178.76 178.76 0 0 1 5.73 13.26c5.1 9.58 8.89 16.75 8.89 16.75s19.61 22.88 34.56 40.6c2.45 2.91 4.78 5.67 6.87 8.18 5.62 6.72 9.58 11.56 9.78 12.1.37.99 13.79-13.18 27.11-27.6 4.44-4.81 8.87-9.64 12.81-13.95 2.15-2.33 4.38-4.96 6.66-7.8 4.12-5.14 8.4-11.01 12.64-17.17a646.08 646.08 0 0 0 20.22-31.57c2.09-3.46 4.05-6.76 5.85-9.83 6.87-11.7 11.38-19.96 11.38-19.96s-3.27-9.79-6.19-18.78"
      fill={fill}
    />
    <path
      d="M144.77 50.81c-.08 1.13-1.8 4.06-5.24 9.91l-.09.13-.02.04a760.96 760.96 0 0 1-7.04 10.68c-.38.56-.23.35 0 0h-.01l-1.16 1.75a466.49 466.49 0 0 0-6.83 10.7 43.6 43.6 0 0 1-3.08 6.09 175.61 175.61 0 0 1-4.71 7.45l-.04.05-1.18 1.73-1.22 1.76a256.12 256.12 0 0 1-6.39 8.73c-.96 2.05-2.42 4.64-4.17 7.76l-2.22 3.97-.13.24c-2.32 3.49-4.82 4.88-7.45 4.7-.27-.02-.54-.04-1.05-.24v-.01a4.84 4.84 0 0 1-1.4-.91l.01-.01c-.26-.23-.59-.56-1-.98a215.34 215.34 0 0 1-11-12.9c-7.69-9.19-13.47-16.68-17.3-22.46-4.2-6.33-6.22-11-6.01-14.03.06-.84.12-1.68.87-2.62l.01-.02c.42-.39.79-.83 1.25-1.16a8.93 8.93 0 0 1 3.02-1.33l5.79.39.83 1.81c.97 2.08 2.49 4.93 4.35 8.44 1.12 2.11 2.36 4.45 3.26 6.18.46.72.96 1.47 1.51 2.24.52.74 1.19 1.63 1.99 2.67l-.01.01c3.27 4.26 7.93 9.62 14.06 16.12l45.16-61.24 2.48-3.37 2.76 3.16c1.59 1.79 1.5 3.18 1.4 4.57"
      fill="#fff"
    />
    <path
      d="M39.85 35.27c.4-.81-3.08-9.03-3.08-9.03l.57-4.23 2.61-1.43 3.52 14.26 4.22-.03 7.8-7.09.43 3.42-5.19 6.07 1.03 3.61 8.46 1.15-4.2 3.65-6.45-1.36-4.24-1.98-1.75 8.46-1.79 2.83-3.43-1.99.94-8.86-7.66-2.16-5.03-.17 1.19-2.82 12.05-2.3z"
      fill="#60bc56"
    />
  </svg>
);

export default ComoIcon;
