import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CeleryIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon celeryIcon"
        fill={fill}
        opacity={opacity}
        d="M242.9,983c-60.4,0-117.2-23.5-159.8-66.1C40.5,874.3,17,817.5,17,757.1s23.5-117.2,66.1-159.8l476.5-476.5l0.5-0.9 c11.1-21.1,25.3-40.4,42.2-57.3C617.2,47.6,633.4,35,650.6,25l11.8-6.9l11.7,7c15.4,9.2,30,20.4,43.3,33.1 c6.9,6.6,13.5,13.7,19.6,21.1l2.9,3.5l4.3-1.2c26.2-6.9,53.3-10.4,80.6-10.4c26.1,0,52.2,3.2,77.6,9.6l13.5,3.4l3.4,13.5 c13.1,52.1,12.8,106.8-0.8,158.2l-1.2,4.3l3.5,2.9c7.4,6.1,14.5,12.7,21.1,19.6c12.7,13.3,23.9,27.9,33.1,43.3l7,11.7l-6.9,11.8	c-10,17.1-22.6,33.4-37.5,48.3c-17,17.1-36.4,31.3-57.5,42.4l-0.9,0.5L402.7,916.9C360.1,959.5,303.4,983,242.9,983z M250.7,877.7	c-17,17-36.9,29.9-59.3,38.5l-19.4,7.4l19.9,5.9c16.5,4.9,33.7,7.3,51,7.3c48,0,93.2-18.7,127.1-52.5l417-417l-17.1,0	c-29.9,0-59-5.5-86.5-16.2l-4.3-1.7L250.7,877.7z M155.1,782c-24.3,24.3-37.4,57.8-35.8,92l0.3,6.4l6.4,0.3c1.8,0.1,3.6,0.1,5.4,0.1	c32.7,0,63.4-12.8,86.6-36l418.6-418.6l-6.6-4.8c-9.8-7.2-19.1-15.2-27.7-23.8c-8.6-8.6-16.6-17.9-23.8-27.7l-4.8-6.6L155.1,782z	 M115.8,630C69.4,676.5,52,744.7,70.6,808l5.8,19.9l7.4-19.4c8.5-22.3,21.5-42.2,38.5-59.2l428.5-428.5l-1.7-4.3	c-10.8-27.7-16.3-56.9-16.2-86.7l0-16.9L115.8,630z M657.9,75.5C650,81.4,642.3,88,635,95.3c-74.3,74.3-74.3,195.3,0,269.7 c36,36,83.9,55.9,134.8,55.9S868.7,401,904.7,365c7.3-7.3,13.9-15,19.7-22.9l3.1-4.2l-3.1-4.2c-4.9-6.6-10.4-13.1-16.2-19.1	c-9.4-9.8-19.9-18.7-31.2-26.4l-14.5-9.9l5.7-16.7c14.6-42.8,18.1-89.2,10-134.2l-0.9-4.8l-4.8-0.9c-15.8-2.8-31.8-4.2-47.8-4.2	c-29.6,0-58.6,4.8-86.4,14.2l-16.7,5.7l-9.9-14.5c-7.7-11.3-16.6-21.8-26.4-31.2c-6-5.8-12.5-11.2-19.1-16.2l-4.2-3.1L657.9,75.5z"
      />
    </g>
  </svg>
);

CeleryIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

CeleryIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default CeleryIcon;
