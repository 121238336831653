import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';

import { confirmOrder } from 'actions/order/submitOrder';
import { selectOrderSyncStatus, orderQuantity } from 'selectors/order';
import { selectDineInPasscode } from 'selectors/root';
import fieldTypes from 'components/Form/fieldTypes';
import MessageBlock from 'components/MessageBlock';
import { RefreshIconSpin } from 'assets/styles/iconStyles';
import Form from 'components/Form';
import { getDineInErrorMessage } from 'components/CheckoutForm/checkoutErrorsUtil';
import globalMessages from 'components/globalMessages';
import messages from './messages';

import { Container } from './styles';

const formFields = [
  {
    ...fieldTypes.terms,
  },
];

const propTypes = {
  orderSyncStatus: PropTypes.instanceOf(Map),
  confirmOrder: PropTypes.func,
  orderQuantity: PropTypes.number,
  getErrorMessage: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  passcode: PropTypes.string,
};

const DineInConfirmation = ({
  orderSyncStatus,
  confirmOrder,
  orderQuantity,
  getErrorMessage,
  intl,
  passcode,
}) => {
  const status = orderSyncStatus?.get('status');
  const isSyncing = status === 'syncing';
  const { errorHeader, errorMessage } = getErrorMessage(intl.formatMessage);

  return orderQuantity > 0 ? (
    <>
      <Container>
        <div>
          <Form
            submitButtonIcon={isSyncing && <RefreshIconSpin />}
            disabled={isSyncing}
            fields={formFields}
            onSubmit={confirmOrder}
            submitButtonLabel={<FormattedMessage {...globalMessages.sendToKitchen} />}
            errorHeader={errorHeader}
            errorMessage={errorMessage}
          >
            {!passcode && <MessageBlock body={<FormattedMessage {...messages.passcodeMsg} />} type="info" />}
          </Form>
        </div>
      </Container>
    </>
  ) : null;
};

DineInConfirmation.propTypes = propTypes;

export default connect(
  state => ({
    passcode: selectDineInPasscode(state),
    orderSyncStatus: selectOrderSyncStatus(state),
    orderQuantity: orderQuantity(state),
    getErrorMessage: formatMessage => getDineInErrorMessage(state, formatMessage),
  }),
  { confirmOrder }
)(injectIntl(DineInConfirmation));
