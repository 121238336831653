import React from 'react';
import PropTypes from 'prop-types';

// Icon
const DinersIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391 C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55 c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M425,82.8h-78.2c-92,0-167.7,68.7-167.7,168.1c0,90.9,75.7,165.6,167.7,165.2H425 c90.9,0.4,173.9-74.3,173.9-165.2C598.9,151.5,516,82.8,425,82.8z M347.2,401.8c-84.1,0-152.2-68.3-152.2-152.6 c0-84.3,68.1-152.6,152.2-152.6c84.1,0,152.2,68.3,152.2,152.6C499.4,333.5,431.3,401.7,347.2,401.8z"
      />
      <path
        className="icon"
        fill={fill}
        d="M250.7,249.2c0.1-41.2,25.7-76.3,61.9-90.3v180.5C276.4,325.5,250.8,290.4,250.7,249.2z"
      />
      <path
        className="icon"
        fill={fill}
        d="M381.7,339.5V158.9c36.2,13.9,61.9,49.1,62,90.3C443.6,290.4,417.9,325.5,381.7,339.5z"
      />
    </g>
  </svg>
);

DinersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

DinersIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default DinersIcon;
