import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requirements: {
    defaultMessage:
      'Password must contain at least {uppercaseRequirement}, {lowercaseRequirement}, {numberRequirement} and {charactersRequirement}.',
  },
  charactersRequirement: {
    defaultMessage: 'be a minimum of 8 characters',
  },
  uppercaseRequirement: {
    defaultMessage: '1 uppercase character',
  },
  lowercaseRequirement: {
    defaultMessage: '1 lowercase character',
  },
  numberRequirement: {
    defaultMessage: '1 number',
  },
});

export default messages;
