import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { selectAvailableFulfilmentMethods } from 'selectors/root';
import { getCheckoutUrl, orderQuantity as selectOrderQuantity, selectOrderSyncStatus } from 'selectors/order';
import { retryOrderUpdate } from 'actions/order';
import { AlertIcon } from 'components/Icons';

import globalMessages from 'components/globalMessages';
import { RefreshIconSpin } from 'assets/styles/iconStyles';
import { ButtonLabel, SyncLabel, RetryButton } from './styles';

const CheckoutButton = ({
  orderQuantity,
  fulfilmentMethods,
  orderSyncStatus,
  checkoutUrl,
  onClick,
  retryOrderUpdate,
}) => {
  const status = orderSyncStatus?.get('status');
  const syncing = status === 'syncing';
  const checkoutDisabled = !fulfilmentMethods.size || !orderQuantity || syncing;

  return (
    <>
      {status === 'failed' ? (
        <div>
          <SyncLabel>
            <FormattedMessage {...globalMessages.orderSyncFailed} />
          </SyncLabel>
          <RetryButton
            onClick={retryOrderUpdate}
            label={<FormattedMessage {...globalMessages.retry} />}
            buttonIcon={<AlertIcon />}
          />
        </div>
      ) : (
        <ActionButton
          to={checkoutDisabled ? undefined : checkoutUrl}
          onClick={checkoutDisabled ? undefined : onClick}
          disabled={checkoutDisabled}
          label={
            <ButtonLabel>
              <FormattedMessage {...globalMessages.checkout} />
            </ButtonLabel>
          }
          primaryButton={true}
          buttonIcon={syncing ? <RefreshIconSpin /> : null}
        />
      )}
    </>
  );
};

CheckoutButton.propTypes = {
  onClick: PropTypes.func,
  orderQuantity: PropTypes.number,
  fulfilmentMethods: PropTypes.instanceOf(List),
  orderSyncStatus: PropTypes.instanceOf(Map),
  checkoutUrl: PropTypes.string.isRequired,
  retryOrderUpdate: PropTypes.func,
};

export default connect(
  state => ({
    orderQuantity: selectOrderQuantity(state),
    fulfilmentMethods: selectAvailableFulfilmentMethods(state),
    orderSyncStatus: selectOrderSyncStatus(state),
    checkoutUrl: getCheckoutUrl(state),
  }),
  dispatch => ({
    retryOrderUpdate: () => dispatch(retryOrderUpdate()),
  })
)(CheckoutButton);
