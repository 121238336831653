import React from 'react';
import PropTypes from 'prop-types';

const YelpIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon YelpIcon"
      fill={fill}
      d="M592.7,635.4c-16.5,16.7-2.6,47-2.6,47l124.5,207.9c0,0,20.4,27.4,38.2,27.4c17.8,0,35.4-14.6,35.4-14.6l98.4-140.7	c0,0,9.9-17.7,10.1-33.3c0.4-22.1-33-28.2-33-28.2l-233-74.8C630.7,626.1,607.9,620,592.7,635.4z M580.9,530.6 c11.9,20.2,44.8,14.3,44.8,14.3l232.5-68c0,0,31.7-12.9,36.2-30.1c4.4-17.2-5.2-37.9-5.2-37.9L778.1,278.1c0,0-9.6-16.5-29.6-18.2	c-22-1.9-35.6,24.8-35.6,24.8L581.5,491.4C581.5,491.4,569.9,512,580.9,530.6z M471,450c27.4-6.7,31.7-46.5,31.7-46.5l-1.9-331	c0,0-4.1-40.8-22.5-51.9c-28.8-17.5-37.3-8.3-45.6-7.1l-193,71.7c0,0-18.9,6.3-28.7,22c-14.1,22.3,14.3,55.1,14.3,55.1L426,435.8	C426,435.8,445.8,456.2,471,450z M423.4,584c0.7-25.5-30.7-40.9-30.7-40.9L185.2,438.3c0,0-30.7-12.7-45.7-3.8 c-11.4,6.8-21.6,19-22.6,29.8l-13.5,166.3c0,0-2,28.8,5.4,41.9c10.6,18.6,45.4,5.6,45.4,5.6l242.2-53.5	C406,618.3,422.5,617.7,423.4,584z M483.6,673.7c-20.8-10.7-45.7,11.4-45.7,11.4L275.7,863.7c0,0-20.2,27.3-15.1,44.1	c4.8,15.7,12.9,23.5,24.2,29l162.9,51.4c0,0,19.7,4.1,34.7-0.2c21.2-6.2,17.3-39.4,17.3-39.4l3.7-241.7	C503.5,706.9,502.6,683.6,483.6,673.7z"
    />
  </svg>
);

YelpIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

YelpIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default YelpIcon;
