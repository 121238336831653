import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, defineMessages } from 'react-intl';
import MessageBlock from 'components/MessageBlock';

const messages = defineMessages({
  error_header: {
    defaultMessage: "There's been a problem",
  },
});

class ErrorBoundary extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    children: PropTypes.object,
    errorInfo: PropTypes.object,
    errorMessage: PropTypes.string,
  };

  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    // eslint-disable-next-line no-undef
    if (typeof Sentry !== 'undefined') {
      // eslint-disable-next-line no-undef
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        // eslint-disable-next-line no-undef
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container">
          <MessageBlock
            header={<FormattedMessage {...messages.error_header} />}
            body={this.props.errorMessage}
          />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
