import { NotesIcon } from 'components/Icons';
import SystemIcon from 'components/SystemIcon';
import TextArea from 'components/TextArea';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import productMessages from 'components/Product/messages';
import { NotesFlexBox, AddNotesBtn, StyledIcon, NotesTxt } from './styles';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export const Notes: React.FC<Props> = ({ onChange, value }) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <NotesFlexBox>
      {!showNotes && (
        <AddNotesBtn onClick={() => setShowNotes(true)}>
          <StyledIcon>
            <SystemIcon Icon={NotesIcon} width="20" height="20" />
          </StyledIcon>
          <NotesTxt>
            <FormattedMessage {...productMessages.addNotes} />
          </NotesTxt>
        </AddNotesBtn>
      )}
      {showNotes && (
        <TextArea
          autoFocus={true}
          value={value}
          onChange={onChange}
          onBlur={() => {
            if (!value) setShowNotes(false);
          }}
          label={<FormattedMessage {...productMessages.addNotes} />}
          className="form-control"
        />
      )}
    </NotesFlexBox>
  );
};
