import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';
import { exportOrders } from 'actions/user';
import ExportOrderForm from 'components/ExportOrderForm';
import { AlertContent } from 'assets/styles/sharedStyles';

const messages = defineMessages({
  message: {
    defaultMessage: 'A copy of your orders will be sent to the email address below.',
  },
});

const propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  closeAlert: PropTypes.func,
  exportOrders: PropTypes.func,
};

const ExportOrdersAlert = ({ dateFrom, dateTo, closeAlert, exportOrders }) => {
  const handleOnSubmit = ({ email }, callback) => {
    exportOrders(dateFrom, dateTo, email, res => {
      if (res.success) closeAlert();
      callback(res);
    });
  };

  return (
    <>
      <AlertContent>
        <ExportOrderForm
          header={<FormattedMessage {...globalMessages.exportOrders} />}
          message={<FormattedMessage {...messages.message} />}
          handleOnSubmit={handleOnSubmit}
        />
      </AlertContent>
    </>
  );
};

ExportOrdersAlert.propTypes = propTypes;

export default connect(null, {
  exportOrders,
})(ExportOrdersAlert);
