import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SidebarList = styled.ul`
  list-style: none;
  margin-bottom: 11px;
`;

export const SidebarMenuItem = styled.li`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 33%;
`;
