import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink, StyledButton } from './styles';

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
  onClick: PropTypes.func,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataCy: PropTypes.string,
  icon: PropTypes.string,
};

const PrimaryButton = ({ link, onClick, text, className, icon, dataCy }) =>
  link ? (
    <StyledLink
      className={className}
      onClick={onClick}
      to={{ pathname: link, state: { transition: 'fadeGrow' } }}
    >
      {icon && (
        <figure>
          <img src={icon} alt={text} />
        </figure>
      )}
      {text}
    </StyledLink>
  ) : (
    <StyledButton className={className} dataCy={dataCy} onClick={onClick}>
      {text}
    </StyledButton>
  );

PrimaryButton.propTypes = propTypes;

export default PrimaryButton;
