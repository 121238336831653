import React from 'react';
import PropTypes from 'prop-types';

import { Title } from './styles';

const QuestionTitle = ({ children }) => <Title>{children}</Title>;

QuestionTitle.propTypes = {
  children: PropTypes.node,
};

export default QuestionTitle;
