import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';
import { TickIcon } from 'components/Icons';

export const CheckboxInput = styled.input`
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
  float: left;
  margin: 0 10px 0 0;
  width: 28px;
  height: 28px;

  html[dir='rtl'] & {
    float: right;
    margin: 0 0 0 10px;
  }

  &::before {
    content: '';
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.borderColor};
    border-radius: 50%;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &:checked::before {
    background-color: ${({ theme }) => theme.color.success};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  align-self: start;
`;

export const StyledCheckbox = styled.div`
  text-align: left;
  margin: 10px 5px;
  ${fontSizeBig};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const StyledTickIcon = styled(TickIcon)`
  position: absolute;
  left: 5px;
  top: 5px;
  width: 18px;
  height: 18px;
  color: #fff;

  html[dir='rtl'] & {
    left: initial;
    right: 7px;
  }
`;
