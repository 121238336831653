import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { getServiceEstimatedWait } from 'selectors/order';
import MessageBlock from 'components/MessageBlock';

const messages = defineMessages({
  estimatedWait: {
    defaultMessage: 'The wait time for orders is currently {estimatedWaitMinutes} minutes.',
  },
});

const EstimatedWaitMessage = ({ estimatedWaitMinutes }) => {
  if (!estimatedWaitMinutes) return null;
  return (
    <MessageBlock
      type="info"
      body={<FormattedMessage {...messages.estimatedWait} values={{ estimatedWaitMinutes }} />}
    />
  );
};

EstimatedWaitMessage.propTypes = {
  estimatedWaitMinutes: PropTypes.number,
};

const mapStateToProps = state => ({
  estimatedWaitMinutes: getServiceEstimatedWait(state),
});

export default connect(mapStateToProps)(EstimatedWaitMessage);
