import styled from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

export const VenueContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  background: #fff;
  color: #666;
  display: block;
  min-height: 50px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  padding: 5px 0;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  & + & {
    border-top: none;
  }
`;

export const VenueImageContainer = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    height: 100%;
  }
`;

export const Details = styled.div`
  min-height: 75px;
  padding: 0 7px;
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const VenueName = styled.h2`
  font-size: 1.2em;
  line-height: 1.1;
`;

export const DistanceContainer = styled.div`
  display: inline-block;
  margin: 0 4px;
`;

export const Distance = styled.span`
  ${fontSizeMedium}

  color: ${({ theme, highlighted }) => (highlighted ? theme.color.danger : theme.color.lightGrey)};
`;

export const VenueAddress = styled.div`
  font-size: 0.8em;
  color: #999;
`;
