import React from 'react';
import PropTypes from 'prop-types';

const MastercardCardIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 72 45.749"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999">
        <rect className="f" width="70" height="43.749" rx="4" />
        <rect className="g" x="-0.5" y="-0.5" width="71" height="44.749" rx="4.5" />
      </g>
      <g transform="translate(14.018 9.317)">
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M428.455,170.452l.647-.017.64-.047.627-.08.622-.111.606-.14.6-.169.584-.2.572-.225.559-.254.543-.275.525-.305.513-.326.492-.351.474-.373.458-.394.437-.419.419-.437.394-.458.373-.474.352-.492.326-.512.3-.526.276-.542.255-.559.224-.573.2-.585.171-.6.139-.606.11-.622.081-.627.047-.64.016-.648-.016-.644-.047-.635-.081-.627-.11-.615-.139-.605-.171-.594-.2-.584-.224-.568-.255-.555-.276-.543-.3-.521-.326-.512-.352-.492-.373-.47-.394-.457-.419-.437-.437-.415-.458-.394-.474-.373-.492-.351-.513-.326-.525-.3-.543-.275-.559-.255-.572-.224-.584-.2-.6-.169-.606-.14-.622-.11-.627-.081-.64-.047-.647-.017-.645.017-.64.047-.626.081-.619.11-.606.14-.6.169-.581.2-.572.224-.555.255-.538.275-.525.3-.508.326-.491.351-.475.373-.453.394-.437.415-.415.437-.394.457-.373.47-.347.492-.326.512-.3.521-.279.543-.25.555-.225.568-.194.584-.17.594-.14.605-.11.615-.08.627-.047.635-.017.644.017.648.047.64.08.627.11.622.14.606.17.6.194.585.225.573.25.559.279.542.3.526.326.512.347.492.373.474.394.458.415.437.437.419.453.394.475.373.491.351.508.326.525.305.538.275.555.254.572.225.581.2.6.169.606.14.619.111.626.08.64.047.645.017Z"
          transform="translate(-399.072 -145.335)"
        />
        <path
          fill="#ec1c2e"
          fillRule="evenodd"
          d="M398.313,170.452l.643-.017.64-.047.628-.08.618-.111.606-.14.6-.169.581-.2.572-.225.555-.254.538-.275.525-.305.508-.326.492-.351.474-.373.453-.394.437-.419.415-.437.394-.458.372-.474.347-.492.327-.512.3-.526.279-.542.25-.559.225-.573.194-.585.169-.6.14-.606.11-.622.081-.627.046-.64.017-.648-.017-.644-.046-.635-.081-.627-.11-.615-.14-.605-.169-.594-.194-.584-.225-.568-.25-.555-.279-.543-.3-.521-.327-.512-.347-.492-.372-.47-.394-.457-.415-.437-.437-.415-.453-.394-.474-.373-.492-.351-.508-.326-.525-.3-.538-.275-.555-.255-.572-.224-.581-.2-.6-.169-.606-.14-.618-.11-.628-.081-.64-.047-.643-.017-.649.017-.639.047-.627.081-.623.11-.606.14-.6.169-.585.2-.571.224-.559.255-.542.275-.525.3-.513.326-.492.351-.474.373-.458.394-.436.415-.42.437-.394.457-.373.47-.352.492-.326.512-.305.521-.275.543-.255.555-.225.568-.2.584-.169.594-.14.605-.11.615-.081.627-.047.635-.017.644.017.648.047.64.081.627.11.622.14.606.169.6.2.585.225.573.255.559.275.542.305.526.326.512.352.492.373.474.394.458.42.437.436.419.458.394.474.373.492.351.513.326.525.305.542.275.559.254.571.225.585.2.6.169.606.14.623.111.627.08.639.047.649.017Z"
          transform="translate(-385.731 -145.335)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M420.695,153.426h6.672v-.682h-6.1l-.576.682Z"
          transform="translate(-401.182 -148.609)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M418.9,156.143h7.672v-.682h-7.269l-.4.682Z"
          transform="translate(-400.39 -149.81)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M417.567,158.854h8.417v-.682h-8.121l-.3.682Z"
          transform="translate(-399.8 -151.008)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M416.648,161.571h8.93v-.682H416.86l-.212.682Z"
          transform="translate(-399.394 -152.209)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M417.012,174.7h8.654v-.682H416.8l.212.682Z"
          transform="translate(-399.461 -158.012)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M418.074,177.421h8.015v-.682h-8.333l.318.682Z"
          transform="translate(-399.884 -159.213)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M419.6,180.131h7.117v-.682h-7.544l.427.682Z"
          transform="translate(-400.508 -160.411)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M421.679,182.845h5.88v-.686h-6.477l.6.686Z"
          transform="translate(-401.353 -161.608)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M416.11,164.281h9.231V163.6h-9.1l-.131.682Z"
          transform="translate(-399.156 -153.407)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M426.189,171.994h3.52v-.683h-3.393l-.128.683Z"
          transform="translate(-403.611 -156.815)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M426.691,169.28h3.24v-.686h-3.113l-.127.686Z"
          transform="translate(-403.832 -155.614)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M415.692,168.594h.916v.686h-.873l-.043-.686Z"
          transform="translate(-398.971 -155.614)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M409.814,166.268l-.059,0-.059-.008-.055-.008-.055-.009-.052-.008-.055-.009-.055-.008-.06-.009-.059-.008-.068,0-.076,0-.08,0h-.089l-.1,0-.11.009-.123.008-.085.013-.081.013-.072.025-.063.025-.06.03-.054.034-.043.043-.039.038-.025.047-.021.047-.009.051v.051l.013.055.017.055.033.055.043.055.084.08.1.064.118.055.127.047.135.043.14.047.14.051.135.054.127.072.119.089.106.107.089.131.068.161.043.191.016.229-.016.267-.03.2-.051.174-.068.161-.084.14-.1.123-.111.105-.119.093-.131.081-.137.063-.14.055-.144.043-.148.034-.144.026-.139.012-.14.009-.132,0h-.183l-.169,0h-.148l-.14,0-.127,0-.114-.009-.1-.008-.1-.013-.089-.009-.08-.017-.081-.013-.072-.02-.072-.017-.067-.026-.068-.025-.072-.026.191-.936.038.008.046.013.06.016.072.018.076.017.085.017.093.021.1.017.106.017.11.017.114.012.119.013.122,0,.124,0h.122l.123,0,.123-.025.1-.034.076-.046.06-.059.042-.059.034-.068.022-.067.016-.064.009-.123-.038-.106-.073-.085-.105-.072-.127-.063-.148-.064-.165-.06-.165-.067-.17-.076-.161-.089-.148-.11-.136-.131-.106-.161-.076-.191-.038-.228,0-.271.021-.165.03-.157.042-.144.05-.135.068-.127.076-.11.089-.106.106-.092.123-.081.135-.068.152-.063.17-.047.191-.039.208-.029.228-.017.251,0h.156l.144,0,.132,0,.118,0,.106.008.1.008.085.008.076.009.072.008.06.013.055.009.052.008.042.008.038,0,.038.008.034,0-.217.956Z"
          transform="translate(-394.802 -154.114)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M409.814,166.268h0l-.059,0-.059-.008-.055-.008-.055-.009-.052-.008-.055-.009-.055-.008-.06-.009-.059-.008-.068,0-.076,0-.08,0h-.089l-.1,0-.11.009-.123.008h0l-.085.013-.081.013-.072.025-.063.025-.06.03-.054.034-.043.043-.039.038-.025.047-.021.047-.009.051v.051l.013.055.017.055.033.055.043.055h0l.084.08.1.064.118.055.127.047.135.043.14.047.14.051.135.054.127.072.119.089.106.107.089.131.068.161.043.191.016.229-.016.267h0l-.03.2-.051.174-.068.161-.084.14-.1.123-.111.105-.119.093-.131.081-.137.063-.14.055-.144.043-.148.034-.144.026-.139.012-.14.009-.132,0h-.183l-.169,0h-.148l-.14,0-.127,0-.114-.009-.1-.008-.1-.013-.089-.009-.08-.017-.081-.013-.072-.02-.072-.017-.067-.026-.068-.025-.072-.026.191-.936h0l.038.008.046.013.06.016.072.018.076.017.085.017.093.021.1.017.106.017.11.017.114.012.119.013.122,0,.124,0h.122l.123,0h0l.123-.025.1-.034.076-.046.06-.059.042-.059.034-.068.022-.067.016-.064h0l.009-.123-.038-.106-.073-.085-.105-.072-.127-.063-.148-.064-.165-.06-.165-.067-.17-.076-.161-.089-.148-.11-.136-.131-.106-.161-.076-.191-.038-.228,0-.271h0l.021-.165.03-.157.042-.144.05-.135.068-.127.076-.11.089-.106.106-.092.123-.081.135-.068.152-.063.17-.047.191-.039.208-.029.228-.017.251,0h.156l.144,0,.132,0,.118,0,.106.008.1.008.085.008.076.009.072.008.06.013.055.009.052.008.042.008.038,0,.038.008.034,0-.217.956"
          transform="translate(-394.802 -154.114)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M413.771,163.524h1.174l-.174,1.025h.707l-.153.957-.746-.021-.406,2.363,0,.085.021.072.043.055.055.046.076.03.084.025.093.017.1.009.093,0,.084-.008.077-.013.068-.017.055-.017.038-.012.025-.009.008,0-.148.788-.03.021-.03.017-.03.017-.029.017-.03.013-.034.013-.038.013-.038.013-.042.012-.043.009-.05.008-.05.009-.055.009-.064.008-.068.008-.072.008h-.322l-.169-.013-.148-.017-.135-.025-.114-.03-.1-.038-.08-.041-.072-.051-.055-.055-.043-.059-.03-.072-.02-.073-.013-.08,0-.089v-.093l.013-.1.013-.106.809-4.621Z"
          transform="translate(-397.754 -153.373)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M413.771,163.524h1.174l-.174,1.025h.707l-.153.957-.746-.021-.406,2.363h0l0,.085.021.072.043.055.055.046.076.03.084.025.093.017.1.009h0l.093,0,.084-.008.077-.013.068-.017.055-.017.038-.012.025-.009.008,0-.148.788h0l-.03.021-.03.017-.03.017-.029.017-.03.013-.034.013-.038.013-.038.013-.042.012-.043.009-.05.008-.05.009-.055.009-.064.008-.068.008-.072.008h-.322l-.169-.013-.148-.017-.135-.025-.114-.03-.1-.038-.08-.041-.072-.051-.055-.055-.043-.059-.03-.072-.02-.073-.013-.08,0-.089v-.093l.013-.1.013-.106.809-4.621"
          transform="translate(-397.754 -153.373)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M418.189,167.844h0l-.013.072,0,.076.008.081.017.081.03.081.035.077.046.08.05.072.059.072.063.064.068.063.076.05.077.047.084.038.084.03.085.017h0l.11.017.106.013.11.013.11.009.11,0h.115l.11,0,.114-.009.114-.017.115-.022.119-.025.114-.038.119-.046.122-.052.119-.067.123-.072-.169,1.042h0l-.068.034-.067.034-.068.025-.063.026-.064.021-.068.022-.068.016-.072.013-.076.013-.085.009-.089.008-.1.008-.11,0h-.114l-.132,0h-.14l-.207,0-.211-.021-.213-.03-.212-.051-.207-.063-.2-.081-.191-.105-.178-.123-.165-.144-.144-.174-.123-.2-.1-.225-.071-.25-.046-.284-.013-.313.022-.347h0l.017-.136.025-.157.034-.165.046-.179.064-.181.076-.183.1-.186.119-.178.135-.17.165-.161.187-.144.212-.127.245-.106.276-.076.3-.05.344-.018h0l.165,0,.174.017.174.029.174.042.169.064.166.076.153.1.14.118.127.144.106.17.084.195.06.225.034.253,0,.288-.03.322-.064.356-.021.258h-3.088l.194-.835H419.9l.009-.105,0-.1-.009-.093-.016-.084-.03-.081-.029-.073-.043-.063-.042-.059-.05-.051-.056-.043-.064-.038-.064-.034-.068-.025-.068-.017-.072-.013-.076-.009h0l-.081,0-.076.013-.072.017-.072.025-.073.034-.063.038-.065.047-.054.051-.056.059-.051.063-.046.068-.038.072-.038.076-.029.085-.025.085-.017.089-.169.877"
          transform="translate(-399.528 -154.114)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M433.85,164.652l-.055-.029-.055-.026-.06-.03-.063-.03-.064-.025-.071-.026-.073-.026-.076-.025-.081-.021-.081-.017-.088-.017-.089-.013-.1-.009-.1-.008h-.314l-.11.008-.115.013-.118.021-.119.034-.118.047-.119.059-.119.076-.114.093-.111.11-.106.136-.1.157-.094.186-.089.212-.081.238-.067.271-.027.152-.016.148-.009.149,0,.144.012.144.029.136.038.127.047.127.064.115.076.109.09.1.1.089.114.076.131.064.144.055.161.038.055.012.064.013.076.009.085.008.092.008h.2l.106-.008.11-.013.11-.021.106-.03.106-.034.106-.047.1-.055.089-.068.085-.076-.19,1.131-.026.021-.025.026-.034.03-.038.024-.047.034-.06.03-.068.03-.076.025-.1.03-.11.021-.127.017-.149.017-.165.008-.191,0-.215,0-.238-.013-.195-.021-.195-.043-.195-.063-.195-.085-.187-.1-.178-.128-.169-.148-.157-.169-.14-.191-.118-.212-.1-.237-.076-.259-.051-.284-.017-.3.009-.33.046-.352.035-.173.037-.169.043-.165.043-.157.046-.148.055-.148.056-.14.059-.131.064-.131.063-.123.072-.118.076-.11.077-.106.084-.1.084-.093.089-.089.1-.085.1-.08.1-.072.106-.068.114-.063.114-.055.119-.051.123-.047.132-.042.13-.034.136-.03.145-.025.143-.022.153-.017.153-.008.161-.005.137.005.13.008.127.013.127.017.119.016.114.026.11.021.1.025.1.025.085.025.081.026.067.025.06.021.051.017.038.017.026.009-.213,1.173Z"
          transform="translate(-405.087 -153.222)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M433.85,164.652h0l-.055-.029-.055-.026-.06-.03-.063-.03-.064-.025-.071-.026-.073-.026-.076-.025-.081-.021-.081-.017-.088-.017-.089-.013-.1-.009-.1-.008h-.314l-.11.008-.115.013-.118.021-.119.034-.118.047-.119.059-.119.076-.114.093-.111.11-.106.136-.1.157-.094.186-.089.212-.081.238-.067.271h0l-.027.152-.016.148-.009.149,0,.144.012.144.029.136.038.127.047.127.064.115.076.109.09.1.1.089.114.076.131.064.144.055.161.038h0l.055.012.064.013.076.009.085.008.092.008h.2l.106-.008.11-.013.11-.021.106-.03.106-.034.106-.047.1-.055.089-.068.085-.076-.19,1.131h0l-.026.021-.025.026-.034.03-.038.024-.047.034-.06.03-.068.03-.076.025-.1.03-.11.021-.127.017-.149.017-.165.008-.191,0-.215,0-.238-.013h0l-.195-.021-.195-.043-.195-.063-.195-.085-.187-.1-.178-.128-.169-.148-.157-.169-.14-.191-.118-.212-.1-.237-.076-.259-.051-.284-.017-.3.009-.33.046-.352h0l.035-.173.037-.169.043-.165.043-.157.046-.148.055-.148.056-.14.059-.131.064-.131.063-.123.072-.118.076-.11.077-.106.084-.1.084-.093.089-.089.1-.085.1-.08.1-.072.106-.068.114-.063.114-.055.119-.051.123-.047.132-.042.13-.034.136-.03.145-.025.143-.022.153-.017.153-.008.161-.005h0l.137.005.13.008.127.013.127.017.119.016.114.026.11.021.1.025.1.025.085.025.081.026.067.025.06.021.051.017.038.017.026.009-.213,1.173"
          transform="translate(-405.087 -153.222)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M452.888,169.1h-1.173l.085-.47v-.043l-.068.085-.072.076-.071.068-.076.06-.076.055-.076.046-.076.038-.081.038-.08.03-.081.021-.081.021-.085.012-.084.013-.081.008-.085,0h-.085l-.22-.017-.2-.047-.186-.072-.17-.1-.152-.123-.136-.143-.119-.157-.1-.177-.084-.191-.068-.2-.047-.208-.034-.216-.013-.216,0-.22.021-.216.038-.216.063-.251.076-.237.089-.216.105-.2.11-.191.128-.17.131-.152.14-.14.148-.123.157-.106.161-.093.161-.072.169-.06.169-.038.169-.025.174-.008.123,0,.119.013.11.021.1.03.1.034.085.037.085.047.076.047.068.05.063.056.055.055.051.055.042.055.038.055.034.051.025.051h0l.3-1.665h1.152l-.958,5.541Z"
          transform="translate(-413.695 -153.39)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M452.888,169.1h-1.173l.085-.47v-.043h0l-.068.085-.072.076-.071.068-.076.06-.076.055-.076.046-.076.038-.081.038-.08.03-.081.021-.081.021-.085.012-.084.013-.081.008-.085,0h-.085l-.22-.017-.2-.047-.186-.072-.17-.1-.152-.123-.136-.143-.119-.157-.1-.177-.084-.191-.068-.2-.047-.208-.034-.216-.013-.216,0-.22.021-.216.038-.216h0l.063-.251.076-.237.089-.216.105-.2.11-.191.128-.17.131-.152.14-.14.148-.123.157-.106.161-.093.161-.072.169-.06.169-.038.169-.025.174-.008h0l.123,0,.119.013.11.021.1.03.1.034.085.037.085.047.076.047.068.05.063.056.055.055.051.055.042.055.038.055.034.051.025.051h0l.3-1.665h1.152l-.958,5.541"
          transform="translate(-413.695 -153.39)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M452.049,169.681l.118-.008.11-.025.1-.03.1-.047.089-.05.084-.064.081-.071.072-.077.063-.085.06-.093.055-.1.047-.1.042-.107.034-.11.029-.114.022-.114.021-.118.013-.123v-.115l0-.118-.013-.11-.017-.106-.03-.1-.034-.1-.042-.084-.051-.081-.055-.068-.064-.059-.072-.051-.076-.034-.081-.021-.088-.009-.115.009-.106.017-.1.034-.1.042-.085.055-.085.068-.072.072-.072.081-.063.092-.059.1-.056.1-.046.11-.042.114-.033.115-.034.122-.025.123-.022.187.009.2.029.207.056.2.084.174.11.14.14.093.169.034Z"
          transform="translate(-414.774 -154.993)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M402.852,168.913l-.17.94h-1.068l.03-.174.022-.157.016-.119.017-.064-.059.068-.068.068-.08.064-.089.059-.1.06-.106.05-.111.051-.114.042-.123.038-.122.03-.123.025-.126.017-.123.009-.122,0-.119-.013L400,169.9l-.148-.038-.135-.043-.119-.055-.106-.064-.093-.067-.084-.077-.068-.081-.059-.084-.05-.089-.043-.094-.034-.092-.026-.093-.017-.1-.013-.1-.008-.093v-.093l.012-.165.026-.156.043-.148.055-.144.068-.135.076-.123.089-.118.1-.107.106-.1.118-.089.123-.08.126-.068.135-.058.14-.047.144-.038.148-.026h.008l.021,0,.03,0,.046,0,.051-.008.059,0,.063-.008.073-.008.072-.008.073-.008.077,0,.067-.008.068,0,.063,0,.06,0h.144l.1,0,.093,0h.093l.076,0,.06,0,.042,0h.013l.009-.03.025-.076.021-.089.009-.08L402,166.6l-.038-.086-.051-.076-.068-.064-.08-.054-.089-.039-.1-.026-.11-.008-.119,0-.128,0h-.131l-.135,0-.14.009-.136.012-.135.013-.131.017-.128.017-.123.021-.11.021-.1.021-.093.026-.077.025-.059.03-.047.025.3-1.021.093-.038.093-.034.1-.029.1-.022.1-.021.11-.016.11-.017.114-.009.118-.008.123,0,.127,0h.407l.148,0,.148,0,.118.008.128.022.131.03.135.038.132.051.127.059.127.072.12.08.1.094.094.1.085.114.063.127.043.136.025.144v.161l-.025.169-.428,2.237Z"
          transform="translate(-391.591 -154.141)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M402.852,168.913l-.17.94h-1.068l.03-.174.022-.157.016-.119.017-.064h0l-.059.068-.068.068-.08.064-.089.059-.1.06-.106.05-.111.051-.114.042-.123.038-.122.03-.123.025-.126.017-.123.009-.122,0-.119-.013L400,169.9h0l-.148-.038-.135-.043-.119-.055-.106-.064-.093-.067-.084-.077-.068-.081-.059-.084-.05-.089-.043-.094-.034-.092-.026-.093-.017-.1-.013-.1-.008-.093v-.093h0l.012-.165.026-.156.043-.148.055-.144.068-.135.076-.123.089-.118.1-.107.106-.1.118-.089.123-.08.126-.068.135-.058.14-.047.144-.038.148-.026h.008l.021,0,.03,0,.046,0,.051-.008.059,0,.063-.008.073-.008.072-.008.073-.008.077,0,.067-.008.068,0,.063,0,.06,0h.144l.1,0,.093,0h.093l.076,0,.06,0,.042,0h.013l.009-.03.025-.076.021-.089.009-.08h0L402,166.6l-.038-.086-.051-.076-.068-.064-.08-.054-.089-.039-.1-.026-.11-.008h0l-.119,0-.128,0h-.131l-.135,0-.14.009-.136.012-.135.013-.131.017-.128.017-.123.021-.11.021-.1.021-.093.026-.077.025-.059.03-.047.025.3-1.021h0l.093-.038.093-.034.1-.029.1-.022.1-.021.11-.016.11-.017.114-.009.118-.008.123,0,.127,0h.407l.148,0,.148,0h0l.118.008.128.022.131.03.135.038.132.051.127.059.127.072.12.08.1.094.094.1.085.114.063.127.043.136.025.144v.161l-.025.169-.428,2.237"
          transform="translate(-391.591 -154.141)"
        />
        <path
          fill="#ec1c2e"
          fillRule="evenodd"
          d="M402.874,169.763l0-.021-.017-.021-.025-.013-.042-.008-.047-.008-.055,0h-.128l-.067,0-.072.008-.068.008-.068.009-.063.008-.059.012-.05.013-.047.012-.029.008-.034.013-.047.017-.047.021-.055.026-.055.03-.059.029-.055.034-.059.042-.055.042-.051.051-.046.051-.043.054-.029.06-.025.067-.014.068,0,.144.02.114.043.085.055.063.064.042.072.025.068.013.068,0,.127,0,.115-.013.11-.017.1-.025.084-.03.081-.038.072-.038.064-.047.055-.043.05-.051.043-.046.034-.051.029-.051.027-.051.021-.047.012-.047.035-.122.029-.14.025-.135.021-.111Z"
          transform="translate(-392.635 -156.097)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M389.024,169.086h-1.152l.962-5.562h2l.191,3.3,1.385-3.3h2.092l-.983,5.562h-1.152l.729-4.155h-.046l-1.725,4.155h-1.3l-.009-.174-.03-.462-.038-.656-.043-.755-.043-.758-.038-.665-.03-.483-.009-.2h-.063l-.7,4.155Z"
          transform="translate(-386.677 -153.373)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M389.024,169.086h-1.152l.962-5.562h2l.191,3.3,1.385-3.3h2.092l-.983,5.562h-1.152l.729-4.155h-.046l-1.725,4.155h-1.3l-.009-.174-.03-.462-.038-.656-.043-.755-.043-.758-.038-.665-.03-.483-.009-.2h-.063l-.7,4.155"
          transform="translate(-386.677 -153.373)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M440.542,168.913l-.174.94H439.3l.008-.174,0-.152v-.114l.008-.051-.055.063-.064.063-.076.059-.085.06-.088.054-.1.051-.106.051-.106.042-.114.034-.119.034-.118.022-.119.017-.123.009-.118,0-.115-.013-.115-.017-.152-.038-.135-.043-.119-.055-.111-.064-.093-.067-.081-.077-.068-.081-.059-.084-.051-.089-.038-.094-.034-.092-.021-.093-.018-.1-.012-.1-.009-.093v-.093l.009-.165.025-.156.038-.148.055-.144.063-.135.081-.123.089-.118.1-.107.11-.1.114-.089.123-.08.132-.068.131-.058.139-.047.14-.038.144-.026h.009l.02,0,.03,0,.042,0,.055-.008.059,0,.064-.008.067-.008.073-.008.076-.008.072,0,.072-.008.068,0,.059,0,.06,0h.148l.1,0,.1,0h.093l.081,0,.068,0,.038,0h.017l.009-.03.013-.076.012-.089.008-.08-.016-.093-.034-.086-.055-.076-.065-.064-.076-.054-.089-.039-.093-.026-.1-.008-.119,0-.126,0H438.8l-.136,0-.139.009-.136.012-.135.013-.132.017-.127.017-.122.021-.111.021-.1.021-.093.026-.076.025-.06.03-.046.025.3-1.021.093-.038.1-.034.1-.029.1-.022.106-.021.106-.016.109-.017.115-.009.118-.008.123,0,.123,0h.4l.144,0,.144,0,.119.008.127.022.131.03.136.038.132.051.131.059.127.072.119.08.107.094.1.1.084.114.064.127.047.136.03.144v.161l-.022.169-.444,2.237Z"
          transform="translate(-408.256 -154.141)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M443.249,172.772l.025-.026.174-.941-.051-.008-.174.941.026-.026v.06l.02,0,0-.021-.025.026Z"
          transform="translate(-411.138 -157.03)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M441.316,173.466l.025.03H442.4v-.06h-1.064l.026.03h-.051l0,.03h.029l-.025-.03Z"
          transform="translate(-410.293 -157.754)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M441.387,172.612l-.039-.033-.017.068,0,.114v.152l-.008.174h.051l.008-.174.009-.152,0-.114v-.035l-.038-.033.042.033Z"
          transform="translate(-410.296 -157.375)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M438.487,173.155h0l.119.017.115.012.118,0,.123-.009.122-.017.119-.022.119-.033.118-.034.106-.042.11-.05.1-.051.088-.059.089-.06.076-.059.068-.068.055-.064-.042-.033-.055.064-.059.059-.077.059-.08.059-.089.051-.1.051-.1.051-.106.042-.11.034-.119.035-.119.021-.114.016-.123.009-.118,0-.115-.013-.11-.016h0l-.009.051Z"
          transform="translate(-409.045 -157.375)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M436.65,171.311h0l0,.093.009.093.013.1.017.1.021.093.034.1.038.1.055.089.059.089.068.08.084.081.1.068.11.068.123.055.139.043.153.038.009-.051-.153-.038-.131-.042-.115-.055-.11-.06-.089-.067-.076-.073-.068-.08-.06-.081-.046-.089-.038-.089-.035-.088-.021-.094-.017-.1-.013-.093-.009-.093,0-.093h-.059Z"
          transform="translate(-408.233 -156.815)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M438.171,168.4h0l-.148.025-.144.038-.14.046-.135.059-.132.068-.126.085-.115.089-.11.1-.1.106-.089.119-.085.127-.063.14-.055.144-.038.152-.026.161-.013.165h.059l0-.165.025-.152.038-.144.055-.144.063-.132.076-.119.09-.119.093-.105.11-.093.115-.089.118-.076.133-.068.126-.059.14-.047.135-.038.14-.025h0V168.4Z"
          transform="translate(-408.233 -155.527)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M440.248,168.237H440.2l-.06.009-.059,0-.068,0-.072.008-.072,0-.076.008-.073.008-.067.009-.064.008-.059,0-.055.009-.042,0-.034,0-.017,0h-.009v.051h.009l.025,0,.025,0,.042,0,.055-.008.059,0,.064-.008.067-.009.073-.008.076-.008.072,0,.072-.009.068,0,.059,0h.106v-.059Z"
          transform="translate(-409.437 -155.456)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M441.511,168.283l.026-.021h-.018l-.038,0-.068,0-.081-.008h-.19l-.1-.009h-.1v.059h.2l.1.008h.174l.068,0,.038,0h.018l.025-.021-.025.021.021,0,0-.025-.051-.009Z"
          transform="translate(-410.128 -155.456)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M442.006,167.835h0l0,.08-.014.085-.013.076-.008.029.051.009.008-.03.013-.077.012-.093.013-.08h-.055Z"
          transform="translate(-410.582 -155.278)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M441.136,167.04v0l.1,0,.089.026.085.038.072.051.059.059.051.072.035.076.017.089h.051l-.017-.1-.033-.094-.06-.08-.067-.069-.081-.059-.093-.038-.1-.025-.106-.013v.055Z"
          transform="translate(-410.216 -154.903)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M437.88,167.237l.042.025.042-.021.055-.03.073-.025.092-.025.1-.021.109-.022.123-.021.123-.016.132-.018.135-.013.136-.012.139-.008h.394l.119,0v-.051l-.119,0-.126-.009h-.132l-.136.009-.139.008-.136.013-.135.013-.132.017-.131.016-.123.022-.109.021-.1.021-.093.025-.08.026-.064.029-.051.03.042.025-.051-.008Z"
          transform="translate(-408.777 -154.896)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M438.194,165.58l-.018.022-.3,1.021.051.008.3-1.021-.017.022-.016-.051-.013,0,0,.017.018-.022Z"
          transform="translate(-408.777 -154.282)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M440.327,165.222h0l-.144-.008-.144,0h-.4l-.123.009-.123,0-.118.009-.115.007-.114.017-.105.017-.106.021-.1.021-.1.029-.1.034-.093.038.016.051.094-.038.1-.033.093-.031.1-.021.106-.022.1-.017.106-.017.115-.008.118-.009.123,0h.522l.144,0h.144v-.05Z"
          transform="translate(-409.025 -154.118)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M443.273,166.668h0l.021-.174v-.161l-.029-.149-.047-.14-.068-.131-.086-.119-.1-.106-.105-.093-.123-.081-.127-.076-.135-.059-.131-.051-.139-.038-.132-.03-.131-.021-.119-.009v.05l.119.009.123.021.131.029.131.038.132.051.127.06.127.068.114.08.106.093.093.1.084.109.059.124.047.131.03.14v.161l-.021.166h0l.051.008Z"
          transform="translate(-410.518 -154.128)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M443.586,170.035h0l.445-2.236-.051-.008-.445,2.236h0l.051.008Z"
          transform="translate(-411.276 -155.259)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M440.559,169.759l-.008-.025-.016-.021-.03-.013-.042-.012-.047,0-.055,0H440.3l-.063,0-.068.009-.068.008-.068.009-.064.008-.062.013-.056.008-.055.013-.042.008-.029,0-.034.013-.042.013-.047.017-.055.025-.05.026-.06.03-.055.038-.056.038-.055.047-.046.046-.047.056-.038.059-.03.063-.025.068-.013.072,0,.144.02.114.035.085.05.063.063.042.073.025.072.013.072,0,.127,0,.118-.013.111-.022.1-.029.092-.034.081-.038.076-.043.068-.05.06-.047.05-.055.047-.051.038-.055.029-.05.022-.051.017-.05.012-.047.017-.119.022-.123.016-.119.008-.106Z"
          transform="translate(-409.3 -156.093)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M457.379,172l.01-.1.032-.114.06-.1.072-.089.089-.072.1-.051.11-.034.118-.012.119.012.11.034.1.051.089.072.072.089.059.1.034.114.009.1h-.128l0-.077-.026-.093-.042-.084-.055-.073-.067-.059-.081-.042-.093-.03-.1-.009-.1.009-.093.03-.081.042-.068.059-.055.073-.042.084-.026.093L457.5,172Z"
          transform="translate(-417.394 -156.865)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M458.063,172.25V172h.233l.052,0,.047.008.034.013.03.017.021.026.017.026.008.029,0,.03,0,.038-.008.03-.013.026-.009.009H458.3l.017,0,.03,0,.025-.008.021-.009.017-.013.009-.013.008-.02v-.03l0-.021-.008-.017-.012-.008-.013-.008-.021,0-.021,0h-.174v.165Z"
          transform="translate(-417.696 -157.117)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M457.377,172.451l0,.025.013.123.032.114.06.1.072.085.089.071.1.056.11.034.118.013.119-.013.11-.034.1-.056.089-.071.072-.085.059-.1.033-.114.012-.123,0-.025h-.128l0,.025-.008.1-.026.093-.042.081-.055.071-.067.06-.081.046-.093.03-.1.008-.1-.008-.093-.03-.081-.046-.068-.06-.055-.071-.042-.081-.026-.093-.009-.1v-.025Z"
          transform="translate(-417.391 -157.318)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M458.063,172.451v.258h.106V172.5h.105l.127.212h.086l-.127-.212h.03l.029-.009.026-.008.022-.017.008-.012H458.3l-.021,0h-.105v0Z"
          transform="translate(-417.696 -157.318)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M457.373,172.023h0l.013-.123.032-.114.06-.1.072-.089.089-.071.1-.051.11-.035.118-.012h0l.119.012.11.035.1.051.089.071.072.089.059.1.034.114.012.123h0l-.012.123-.034.114-.059.1-.072.085-.089.071-.1.056-.11.033-.119.013h0l-.118-.013-.11-.033-.1-.056-.089-.071-.072-.085-.06-.1-.032-.114-.013-.123m.492.233h-.107v-.513h.233l.052,0,.046.008.034.013.03.017.022.026.017.026.008.029,0,.03h0l0,.038-.008.03-.013.026-.017.021-.021.017-.026.008-.03.009h-.03l.127.212H458.1l-.127-.212h-.105v.212m.105-.254h0l.038-.009.03,0,.025-.008.021-.008.017-.013.008-.013.008-.02v-.03h0l0-.021-.009-.017-.012-.008-.013-.009-.022,0-.021,0h-.174V172h.105m0,.512h0l.1-.008.093-.03.081-.046.067-.06.055-.071.042-.081.026-.093.008-.1h0l-.008-.1-.026-.094-.042-.084-.055-.073-.067-.059-.081-.042-.093-.03-.1-.008h0l-.1.008-.093.03-.08.042-.069.059-.055.073-.042.084-.026.094-.008.1h0l.008.1.026.093.042.081.055.071.069.06.08.046.093.03.1.008"
          transform="translate(-417.391 -156.865)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M444.151,169.86h1.195l.427-2.41.017-.093.026-.094.025-.093.034-.093.042-.093.051-.089.055-.084.068-.081.076-.072.084-.068.1-.064.11-.051.123-.042.136-.034.148-.026.165-.009h.106l.191-1.088-.119.008-.114.013-.106.021-.1.029-.093.034-.089.043-.085.046-.081.051-.076.06-.073.059-.068.068-.068.067-.064.072-.063.08-.063.076-.059.085.127-.767h-1.191l-.792,4.537Z"
          transform="translate(-411.548 -154.148)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M444.151,169.86h1.195l.427-2.41h0l.017-.093.026-.094.025-.093.034-.093.042-.093.051-.089.055-.084.068-.081.076-.072.084-.068.1-.064.11-.051.123-.042.136-.034.148-.026.165-.009h.106l.191-1.088h0l-.119.008-.114.013-.106.021-.1.029-.093.034-.089.043-.085.046-.081.051-.076.06-.073.059-.068.068-.068.067-.064.072-.063.08-.063.076-.059.085.127-.767h-1.191l-.792,4.537h0"
          transform="translate(-411.548 -154.148)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M424.05,169.86h1.215l.407-2.41.017-.093.021-.089.021-.089.03-.085.034-.085.043-.081.05-.076.059-.072.068-.064.076-.063.094-.051.1-.046.119-.039.132-.033.144-.022.165-.013h.013l.017,0,.021,0h.021l.021,0,.017,0,.012,0h0l0-.017.013-.051.017-.076.021-.093.029-.11.03-.119.038-.119.037-.119.035-.08.042-.081.042-.08.042-.072.038-.063.03-.051.021-.03.008-.013h0l-.017,0-.021,0-.03,0h-.029l-.034,0-.03,0h-.03l-.131.008-.118.013-.11.021-.1.026-.1.034-.089.043-.081.042-.076.051-.071.056-.068.064-.063.064-.063.071-.064.073-.059.081-.063.084-.064.085.152-.767h-1.216l-.792,4.537Z"
          transform="translate(-402.665 -154.148)"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="2.613"
          strokeWidth="0.216px"
          d="M424.05,169.86h1.215l.407-2.41h0l.017-.093.021-.089.021-.089.03-.085.034-.085.043-.081.05-.076.059-.072.068-.064.076-.063.094-.051.1-.046.119-.039.132-.033.144-.022.165-.013h.013l.017,0,.021,0h.021l.021,0,.017,0,.012,0h0l0-.017.013-.051.017-.076.021-.093.029-.11.03-.119.038-.119.037-.119h0l.035-.08.042-.081.042-.08.042-.072.038-.063.03-.051.021-.03.008-.013h0l-.017,0-.021,0-.03,0h-.029l-.034,0-.03,0h-.03l-.131.008-.118.013-.11.021-.1.026-.1.034-.089.043-.081.042-.076.051-.071.056-.068.064-.063.064-.063.071-.064.073-.059.081-.063.084-.064.085.152-.767h-1.216l-.792,4.537h0"
          transform="translate(-402.665 -154.148)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M457.4,181.1v-.017l.032-.11.06-.1.072-.084.089-.068.1-.051.11-.029.118-.013.119.013.11.029.1.051.089.068.072.084.059.1.033.11v.017h-.122l-.026-.088-.042-.081-.055-.072-.067-.059-.081-.047-.093-.029-.1-.009-.1.009-.093.029-.081.047-.068.059-.055.072-.042.081-.026.088Z"
          transform="translate(-417.402 -160.934)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M458.063,181.359v-.148h.284l.047.008.034.008.03.017.021.022.017.026.008.029,0,.038h-.085v-.021l0-.021-.008-.017-.012-.008-.013-.009-.021,0-.021,0h-.174v.084Z"
          transform="translate(-417.696 -161.19)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M457.373,181.569l.013-.093h.122v0l-.009.089Z"
          transform="translate(-417.391 -161.307)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M458.063,181.569v-.093h.106v.084h.105l.038-.008.03,0,.025-.009.021-.008.017-.013.009-.013.008-.02v-.009h.085l0,.029-.008.026-.013.022-.017.016Z"
          transform="translate(-417.696 -161.307)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M459.28,181.569l-.008-.089v0h.122l.012.093Z"
          transform="translate(-418.23 -161.307)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M457.373,181.642v.013l.013.123.032.114.06.1.072.089.089.072.1.05.11.034.118.013.119-.013.11-.034.1-.05.089-.072.072-.089.059-.1.033-.114.012-.123v-.013h-.126v.013l-.008.1-.026.093-.042.08-.055.071-.067.061-.081.046-.093.03-.1.008-.1-.008-.093-.03-.081-.046-.068-.061-.055-.071-.042-.08-.026-.093-.009-.1v-.013Z"
          transform="translate(-417.391 -161.38)"
        />
        <path
          fill="#fbb231"
          fillRule="evenodd"
          d="M458.063,181.642v.246h.106v-.212h.105l.127.212h.086l-.127-.212h.03l.029-.008.026-.009.022-.017h-.4Z"
          transform="translate(-417.696 -161.38)"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M421.044,166.64l-.034-.253-.06-.225-.084-.194-.106-.17-.127-.144-.14-.118-.153-.1-.166-.076-.169-.064-.174-.042-.174-.03-.174-.017-.165,0-.344.018-.3.05-.276.076-.246.106-.212.127-.187.144-.165.161-.135.17-.119.178-.1.186-.075.183-.064.181-.046.179-.035.164-.025.157-.017.136-.022.347.013.313.046.284.071.25.1.224.123.2.144.174.165.144.177.123.191.106.2.081.208.063.211.051.213.03.211.021.208,0h.14l.132,0h.114l.11,0,.1-.008.089-.008.084-.008.076-.013.072-.013.068-.016.068-.022.064-.021.063-.026.068-.025.067-.034.069-.034.169-1.042-.122.072-.119.067-.122.052-.119.046-.114.038-.118.025-.115.021-.114.017-.114.008-.11,0H419.5l-.11,0-.11-.008-.11-.013-.107-.013-.11-.017-.085-.017-.084-.03-.085-.037-.076-.047-.076-.05-.069-.063-.063-.064-.059-.073-.051-.071-.046-.08-.035-.077-.03-.081-.017-.08-.008-.081,0-.071.011-.055h2.748l.021-.258.064-.357.03-.322Zm-1.149.39h-1.548l0,0h0l.011-.059.017-.089.026-.085.03-.084.038-.076.038-.073.046-.069.051-.063.056-.059.054-.051.065-.046.063-.038.073-.034.072-.025.072-.017.076-.013.08,0,.076.008.072.013.068.017.068.025.064.034.064.038.056.042.05.051.042.059.043.063.03.073.03.081.016.084.008.093.005.1Z"
          transform="translate(-399.528 -154.114)"
        />
      </g>
    </g>
  </svg>
);

MastercardCardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

MastercardCardIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default MastercardCardIcon;
