import { fromJS, Map } from 'immutable';
import { selectVenueName } from 'selectors/root';
import { RECEIVE_EXTERNAL_LINKS } from 'actions/root/constants';
import {
  SET_COMPLETED_ORDER,
  SET_GRATUITY,
  UPDATE_COMPLETED_ORDER_NUMBER,
  UPDATE_COMPLETED_ORDER_STATE,
} from 'actions/order/constants';

export const selectCompletedOrder = state => state.getIn(['global', 'completedOrder']);
export const selectCompletedOrderNumber = state => state.getIn(['global', 'completedOrder', 'number']);
export const selectCompletedOrderState = state => state.getIn(['global', 'completedOrder', 'state']);
export const selectCompletedOrderFulfilment = state =>
  state.getIn(['global', 'completedOrder', 'fulfilment']);
export const selectCompletedOrderServiceId = state => state.getIn(['global', 'completedOrder', 'service']);
export const selectCompletedOrderTab = state => state.getIn(['global', 'completedOrder', 'tab']);
export const selectCompletedOrderTabId = state =>
  state.getIn(['global', 'completedOrder', 'tab', 'id'], null);
export const selectCompletedOrderPasscode = state =>
  state.getIn(['global', 'completedOrder', 'tab', 'passcode'], null);
export const selectCompletedOrderLocationRef = state =>
  state.getIn(['global', 'completedOrder', 'tab', 'locationRef'], null);
export const selectCompletedOrderPromotionCoupon = state =>
  state.getIn(['global', 'completedOrder', 'promotionCoupon']);

export const selectCompletedOrderGaData = state => {
  const gaData = state.getIn(['global', 'completedOrder', 'GaData'], new Map());
  const result = gaData && gaData.set('affiliation', selectVenueName(state));

  return result;
};

export const initialState = fromJS({
  completedOrder: {
    number: undefined,
    state: undefined,
    fulfilment: {},
    tab: false,
    GaData: {},
  },
  preSelectedService: undefined,
  tabCheckoutGratuity: {},
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GRATUITY:
      return state.set('tabCheckoutGratuity', fromJS(action.gratuity));
    case RECEIVE_EXTERNAL_LINKS:
      return state.set('external_links', fromJS(action.payload));
    case SET_COMPLETED_ORDER: {
      const existingPasscode = state.getIn(['completedOrder', 'tab', 'passcode']);
      if (
        existingPasscode &&
        action.completedOrder?.tab &&
        state.getIn(['completedOrder', 'number']) === action.completedOrder?.number
      ) {
        action.completedOrder.tab.passcode = existingPasscode;
      }
      return state.set('completedOrder', fromJS(action.completedOrder));
    }
    case UPDATE_COMPLETED_ORDER_NUMBER:
      return state.setIn(['completedOrder', 'number'], action.orderNumber);
    case UPDATE_COMPLETED_ORDER_STATE:
      return state.setIn(['completedOrder', 'state'], action.orderState);
    default:
      return state;
  }
};

export default appReducer;
