import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ResetPasswordIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M74,172v-65h14V81.076c0-4.341,0.722-8.425,2.393-12.14c1.666-3.703,3.845-6.976,6.598-9.729	c2.779-2.78,6.019-5.026,9.689-6.677c3.758-1.691,7.825-2.549,12.119-2.549c4.29,0,8.365,0.857,12.128,2.549		c3.688,1.66,6.956,3.906,9.725,6.677c2.734,2.733,5.021,5.916,6.736,9.727c1.651,3.673,2.612,7.758,2.612,12.142V100h-11V81.076		c0-2.099-1.084-4.442-2.242-7.068c-0.838-1.9-2.354-3.872-4.505-6.028c-1.556-1.68-3.596-3.039-6.055-4.095		c-2.265-0.972-4.808-1.484-7.323-1.484c-2.525,0-4.979,0.499-7.273,1.484c-2.301,0.985-4.247,2.324-5.777,3.98		c-1.906,1.911-2.882,4.078-3.793,6.144c-1.018,2.308-1.031,4.867-1.031,7.067V107h65v65H74z M153,160v-39H86v39H153z"
      />
    </g>
    <g>
      <path
        className="icon"
        fill={fill}
        d="M94.536,232.18c-15.741-3.732-29.896-10.158-42.044-19.225c-12.146-9.056-22.271-20.041-30.082-32.717	c-7.818-12.67-13.122-26.641-15.757-41.559c-2.649-14.926-2.101-30.469,1.631-46.207C9.94,85.488,12.2,78.863,15,72.771	c2.779-6.055,6.047-12.014,9.715-17.715c3.618-5.382,7.668-10.489,12.058-15.214c3.987-4.298,8.388-8.42,13.102-12.273L36.585,4.762	l71.625-0.383l-37.255,57.23l-13.41-22.219c-3.955,3.317-7.684,6.786-11.106,10.334c-3.918,4.051-7.619,8.537-11,13.335	c-2.991,4.824-5.731,10.022-8.146,15.453c-2.389,5.379-4.319,11.138-5.737,17.115c-3.257,13.732-3.671,27.389-1.231,40.588	c2.449,13.262,7.057,25.69,13.697,36.939c7.029,10.92,16.116,20.399,26.864,28.54c10.7,8.108,23.14,13.65,36.871,16.907l1.852,0.44	l-3.221,13.575L94.536,232.18z M72.107,40.665l16.398-25.191l-31.515,0.168L72.107,40.665z M147.486,7.941	c15.673,4.012,29.712,10.688,41.696,19.969c11.981,9.272,21.909,20.435,29.492,33.248c7.592,12.807,12.646,26.871,15.014,41.834	c2.384,14.971,1.56,30.501-2.453,46.17c-1.781,6.954-4.158,13.538-7.064,19.579c-2.888,6.004-6.261,11.905-10.029,17.539	c-3.715,5.316-7.854,10.351-12.327,14.995c-4.063,4.228-8.536,8.27-13.319,12.038l12.88,23.041l-71.619-0.893l38.268-56.559	l13.013,22.456c4.014-3.247,7.805-6.649,11.289-10.137c3.989-3.979,7.769-8.399,11.236-13.137c3.076-4.769,5.909-9.918,8.419-15.306	c2.486-5.335,4.518-11.059,6.042-17.011c3.502-13.671,4.159-27.319,1.955-40.56c-2.214-13.304-6.599-25.812-13.038-37.176	c-6.833-11.043-15.749-20.684-26.352-29.015c-10.552-8.298-22.893-14.061-36.564-17.561l-1.842-0.473l3.462-13.517L147.486,7.941z	 M166.5,199.825l-16.847,24.894l31.514,0.394L166.5,199.825z"
      />
    </g>
  </svg>
);

ResetPasswordIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ResetPasswordIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ResetPasswordIcon;
