import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Map } from 'immutable';
import { useSetTimeout, useIsMounted } from 'hooks';
import {
  ORDER_STATE_PENDING,
  ORDER_STATE_REQUIRES_REVIEW,
  ORDER_STATE_REJECTED,
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_FAILED,
} from 'appConstants';
import PageTitle from 'components/PageTitle';
import ProcessingIcon from 'components/Processing';
import KioskExitButton from 'components/KioskExitButton';
import { StyledMenuButton } from 'components/MenuButton';
import PoweredFooter from 'components/PoweredFooter';

import { showExitButton } from 'selectors/features';
import { selectCompletedOrder } from 'reducers/root';
import { fetchProcessingStatus } from 'actions/order';
import getOrderRedirectUrl from '../../../../shared/getOrderRedirectUrl';
import OrderError from './OrderError';
import messages from './messages';

import { PageContainer, ProcessingIconDiv, Title, Content, ProcessingMainSection, Container } from './styles';

const TIMER = 5000;
const DELAY = 3000;

const propTypes = {
  order: PropTypes.instanceOf(Map),
  fetchProcessingStatus: PropTypes.func.isRequired,
  showExitButton: PropTypes.bool,
};

const Processing = ({ order, fetchProcessingStatus, showExitButton }) => {
  const [nextContent, setNextContent] = useState(null);
  const [orderFailedMessage, setOrderFailedMessage] = useState(null);
  const [isMounted] = useIsMounted();
  const timeoutId = useRef(null);
  const [hasStartedRedirect, setHasStartedRedirect] = useState(false);

  const orderState = order.get('state');

  useSetTimeout(() => {
    if (!nextContent) setNextContent(true);
  }, TIMER);

  useEffect(() => {
    const checkOrderState = () => {
      fetchProcessingStatus(({ success, orderFailedMessage = null }) => {
        if (!isMounted.current) {
          return;
        }

        if (orderFailedMessage) {
          setOrderFailedMessage(orderFailedMessage);
          return;
        }

        if (orderState === ORDER_STATE_CONFIRMED && !hasStartedRedirect) {
          setHasStartedRedirect(true);
          const redirectUrl = getOrderRedirectUrl({ order: order.toJS() });
          window.location.href = redirectUrl;
        } else if (
          !success ||
          orderState === ORDER_STATE_REQUIRES_REVIEW ||
          orderState === ORDER_STATE_PENDING
        ) {
          timeoutId.current = setTimeout(checkOrderState, DELAY);
        }
      });
    };

    checkOrderState();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [order, fetchProcessingStatus, isMounted, orderState, hasStartedRedirect]);

  return (
    <div className="fullWrapper">
      <PageContainer>
        <PageTitle message={messages.title} />

        {showExitButton && <KioskExitButton withBackground={true} />}
        <StyledMenuButton customCssClass="menuBarColor" />

        <Container className="u-flex u-flexCol u-flexAlignItemsCenter u-flexJustifyAround">
          <ProcessingMainSection>
            {orderState === ORDER_STATE_FAILED && (
              <OrderError
                title={<FormattedMessage {...messages.failedTitle} />}
                message={orderFailedMessage || <FormattedMessage {...messages.failedContent} />}
              />
            )}
            {orderState === ORDER_STATE_REJECTED && (
              <OrderError
                title={<FormattedMessage {...messages.rejectedTitle} />}
                message={<FormattedMessage {...messages.rejectedContent} />}
              />
            )}
            {orderState !== ORDER_STATE_FAILED && orderState !== ORDER_STATE_REJECTED && (
              <div>
                <Title>
                  <FormattedMessage {...messages.title} />
                </Title>
                <Content>
                  {orderState === ORDER_STATE_REQUIRES_REVIEW ? (
                    <FormattedMessage {...messages.review_content} />
                  ) : (
                    <FormattedMessage {...messages.content} />
                  )}
                  {nextContent && (
                    <span>
                      &nbsp;
                      <FormattedMessage {...messages.nextContent} />
                    </span>
                  )}
                </Content>
                <ProcessingIconDiv>
                  <ProcessingIcon>
                    {orderState === ORDER_STATE_REQUIRES_REVIEW && (
                      <FormattedMessage {...messages.awaiting_confirmation} />
                    )}
                  </ProcessingIcon>
                </ProcessingIconDiv>
              </div>
            )}
          </ProcessingMainSection>
          <PoweredFooter />
        </Container>
      </PageContainer>
    </div>
  );
};

Processing.propTypes = propTypes;

export default connect(
  state => ({
    order: selectCompletedOrder(state),
    showExitButton: showExitButton(state),
  }),
  { fetchProcessingStatus }
)(Processing);
