import styled from 'styled-components';
import { H4 } from 'components/Typography';

export const Message = styled.p`
  font-size: 16px;
  margin: 0 50px 10px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 140px;
    margin: 0 5px;
    font-size: 14px;
  }
`;

export const ContinueAsGuest = styled(H4)`
  font-weight: 400;
  text-transform: capitalize;
  margin: 10px 0 25px;
`;
