import styled from 'styled-components';
import { lighten } from 'polished';

export const OuterContainer = styled.div`
  background: ${({ theme }) => theme.color.brandPrimary};
  padding: 10px;
`;

export const Container = styled.div`
  text-align: center;
  text-transform: capitalize;
  background-color: ${({ theme }) => lighten(0.05, theme.color.brandPrimary)};
  color: ${({ theme }) => theme.color.brandSecondary};
  padding: 10px;
  border-radius: 5px;
`;
