import styled, { css } from 'styled-components';

import { StyledInput, DisabledInput, StyledDropdown } from 'assets/styles/sharedStyles';

export const Container = styled.div`
  margin: 5px 0 10px;
  text-align: left;
`;

export const SelectStyled = styled.span`
  cursor: pointer;
  ${StyledInput}
`;

export const SpinnerContainer = styled.span`
  position: absolute;
  right: 0px;
  top: 4px;
`;

export const SelectArrow = styled.span`
  border-color: ${({ theme }) => theme.color.borderColor} transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: 5px;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 0;
  z-index: ${({ theme }) => theme.zIndex.three};
`;

export const MenuStyle = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.seven};
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow: scroll;
  ${StyledDropdown}
`;

export const Item = styled.div`
  padding: 8px;
  border-color: ${({ highlighted, theme }) => highlighted && theme.color.greyLighter};
  background: ${({ highlighted, theme }) => highlighted && theme.color.secondaryBackground};

  &:hover {
    background-color: ${({ disabled }) => !disabled && 'rgba(0, 126, 255, 0.08)'};
  }

  ${props =>
    props.disabled &&
    css`
      ${DisabledInput};
    `}
`;
