import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

import { AlertContent } from 'assets/styles/sharedStyles';
import { setPaymentError } from 'actions/payment';
import { PAYMENT_STATE_FAILED, PAYMENT_STATE_COMPLETED, PAYMENT_STATE_AUTHORIZED } from 'appConstants';

const propTypes = {
  closeAlert: PropTypes.func.isRequired,
  setPaymentError: PropTypes.func,
  iframeUrl: PropTypes.string.isRequired,
};

const IframeAlert = ({ closeAlert, setPaymentError, iframeUrl }) => {
  useEffect(() => {
    const iframeCallback = event => {
      const payment = event?.data?.data?.payment;

      if (payment) {
        if (payment.state === PAYMENT_STATE_FAILED) {
          closeAlert();
          setPaymentError(payment.error);
        }

        if ([PAYMENT_STATE_AUTHORIZED, PAYMENT_STATE_COMPLETED].includes(payment.state)) {
          closeAlert();
          window.location = '/processing';
        }
      }
    };

    window.addEventListener('message', iframeCallback);

    return () => {
      window.removeEventListener('message', iframeCallback);
    };
  }, [closeAlert, setPaymentError]);

  return (
    <AlertContent>
      <Iframe url={iframeUrl} width="100%" height="450px" display="initial" position="relative" />
    </AlertContent>
  );
};

IframeAlert.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  setPaymentError: errorMessage => dispatch(setPaymentError(true, errorMessage)),
});

export default connect(undefined, mapDispatchToProps)(IframeAlert);
