import React from 'react';
import PropTypes from 'prop-types';

// Icon
const FishIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon fishIcon"
        fill={fill}
        opacity={opacity}
        d="M18.5,746.2l16.9-16.9c30.6-30.6,71.3-47.5,114.6-47.5c23.2,0,45.7,4.8,66.7,14.3l7.9,3.6l0.6-8.7	c4.8-75.4,21.8-149.4,50.4-220.1c24.9-61.6,58.4-119.4,99.3-171.9l3.3-4.2L277,193.8l16.9-16.9c30.6-30.6,71.3-47.5,114.6-47.5	c36.1,0,70.2,11.6,98.6,33.5l3.6,2.8l3.6-2.7c61.5-45.6,129.2-80.8,201.3-104.6C788.2,34.4,864,22.2,941,22.2	c6.5,0,13.2,0.1,19.7,0.3l23,0.6l0.2,23c1,94.7-16.6,187.2-52.3,275c-23.8,58.6-55.3,113.7-93.7,164l-2.9,3.9l3.2,3.7	C863.3,522,877,559.4,877,598c0,43.3-16.9,84-47.5,114.6l-16.9,16.9l-109-109l-4.2,3.2c-51,39-107.1,70.9-166.5,94.9	c-73.7,29.8-151.1,46.9-230,50.9l-8.7,0.4l3.5,8c13.2,29.6,17.1,62.2,11.5,94.3c-5.8,32.8-21.3,62.6-44.9,86.2l-16.9,16.9	L18.5,746.2z M150,729.7c-19.4,0-38.5,4.9-55.3,14.2l-7,3.9L246,906l3.9-7c24.5-44.3,16.6-100.2-19.2-136	C209.1,741.5,180.5,729.7,150,729.7z M591.6,168.5c-43.1,26.5-83.5,57.9-119.9,93.5c-61.9,61-110.6,131.3-144.8,209	c-34.1,77.5-52.7,160-55.2,245.3l-0.2,6.3l6.3-0.1c171.3-2.8,333.8-71.4,457.7-193.3c36.1-35.5,68.1-74.7,95.3-116.8l4.1-6.3l-7-2.6	c-51.1-18.9-98.5-49.3-137.1-87.9c-40.4-40.4-71.7-90.2-90.4-144l-2.4-7L591.6,168.5z M799.2,531.9c-10,11.1-20.2,21.7-30.1,31.4	c-7.4,7.3-15.4,14.8-23.7,22.3l-4.7,4.2l70.4,70.4l3.9-7c9.3-16.8,14.2-35.9,14.2-55.3c0-23.6-7.1-46.3-20.7-65.5l-4.3-6.1	L799.2,531.9z M929.7,70.2c-99.7,1.7-195.3,24.7-284.3,68.6l-4.6,2.3l1.5,4.9c15.9,50.9,44.4,97.9,82.3,135.8	c36.4,36.4,81.3,64.2,129.9,80.4l4.9,1.6l2.4-4.5c45.8-87.9,70.7-183.1,74-282.9l0.2-6.3L929.7,70.2z M408.5,177.3	c-19.4,0-38.5,4.9-55.3,14.2l-7,3.9l62.9,62.9l4.2-4.6c8.2-8.9,16.5-17.5,24.9-25.7c8.8-8.6,18.2-17.3,27.9-25.9l6.3-5.5l-7.3-4.2	C448,182.5,428.4,177.3,408.5,177.3z"
      />
    </g>
  </svg>
);

FishIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

FishIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default FishIcon;
