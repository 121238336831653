import React from 'react';
import { List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openExportOrdersAlert } from 'actions/UI';
import ActionButton from 'components/ActionButton';
import { fetchOrders, toggleFilter, clearFilter, applyFilter, setSelectedVenue } from 'actions/user';
import { fetchQuestions } from 'actions/feedback';
import { getFeedbackQuestions } from 'selectors/feedback';
import Loading from 'components/Loading';
import OrderList from 'components/Pages/User/Orders/OrderList';
import Page from 'components/Pages/container';
import MessageBlock from 'components/MessageBlock';
import FilterByDate from 'components/FilterByDate';
import { OrdersIcon, FilterAltIcon } from 'components/Icons';
import {
  getOrders,
  getOrdersVenueList,
  getOrdersLoading,
  isLoggedIn,
  selectSelectedVenue,
  selectIsFilterOpen,
  selectDateFrom,
  selectDateTo,
} from 'selectors/user';
import { getVenueId, selectVenueName } from 'selectors/root';
import globalMessages from 'components/globalMessages';

import { filterByDateRange } from 'utils';
import VenueSelect from './VenueSelect';
import {
  VenueSelectWrapper,
  FilterBtnWrapper,
  IconFilterWrapper,
  FilterTextWrapper,
  ExportOrdersContainer,
} from './styles';

import messages from './messages';

class Orders extends React.Component {
  static propTypes = {
    loadingOrders: PropTypes.bool,
    orders: PropTypes.instanceOf(List),
    venues: PropTypes.instanceOf(List),
    venueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getServiceById: PropTypes.func,
    fetchOrders: PropTypes.func.isRequired,
    userIsLoggedIn: PropTypes.bool.isRequired,
    toggleFilter: PropTypes.func,
    clearFilter: PropTypes.func,
    applyFilter: PropTypes.func,
    setSelectedVenue: PropTypes.func,
    isFilterOpen: PropTypes.bool,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    fetchQuestions: PropTypes.func,
    feedbackQuestions: PropTypes.instanceOf(List),
    openExportOrdersAlert: PropTypes.func,
  };

  state = {
    selectedVenueId: this.props.venueId,
    expandSelect: false,
  };

  componentDidMount() {
    this.props.fetchOrders();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.orders.size && this.props.orders.size && !this.props.feedbackQuestions.size) {
      this.props.fetchQuestions(this.props.orders.getIn([0, 'service']));
    }
  }

  handleSetVenueId = selectedVenueId => {
    this.setState({ selectedVenueId, expandSelect: false });
    this.props.setSelectedVenue(selectedVenueId);
  };

  handleToggleFilter = () => this.props.toggleFilter(this.state.selectedVenueId);

  handleClearFilter = () => this.props.clearFilter(this.state.selectedVenueId);

  handleApplyFilter = (from, to) => this.props.applyFilter(this.state.selectedVenueId, from, to);

  handleOnFocus = () => this.setState({ expandSelect: true });

  handleOnBlur = () => this.setState({ expandSelect: false });

  render() {
    const { expandSelect, selectedVenueId } = this.state;
    const {
      dateFrom,
      dateTo,
      isFilterOpen,
      orders,
      venues,
      userIsLoggedIn,
      loadingOrders,
      openExportOrdersAlert,
    } = this.props;
    const hasFilter = !!(
      (dateFrom !== '' && dateFrom !== undefined) ||
      (dateTo !== '' && dateTo !== undefined)
    );

    const FilterText = hasFilter ? (
      <FormattedMessage {...globalMessages.filtered} />
    ) : (
      <FormattedMessage {...globalMessages.filter} />
    );

    const ordersList = orders.filter(order => order.get('venueId') === selectedVenueId);
    const filteredOrdersList = filterByDateRange(ordersList, dateFrom, dateTo);
    const venueName = venues.filter(venue => venue.get('id') === selectedVenueId).getIn([0, 'name']);

    return (
      <Page
        large={true}
        settingsPage={true}
        titleMessage={messages.ordersPage}
        redirectCondition={!userIsLoggedIn}
        Icon={OrdersIcon}
      >
        {loadingOrders && <Loading />}

        {!loadingOrders && (
          <>
            <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
              <VenueSelectWrapper>
                <VenueSelect
                  onFocus={this.handleOnFocus}
                  onBlur={this.handleOnBlur}
                  venueId={Number(selectedVenueId)}
                  venues={venues}
                  onChange={this.handleSetVenueId}
                />
              </VenueSelectWrapper>

              {expandSelect === false && (
                <FilterBtnWrapper
                  onClick={this.handleToggleFilter}
                  hasFilter={hasFilter}
                  className="u-flex u-flexAlignItemsCenter"
                >
                  <IconFilterWrapper>
                    <FilterAltIcon />
                  </IconFilterWrapper>

                  <FilterTextWrapper>{FilterText}</FilterTextWrapper>
                </FilterBtnWrapper>
              )}
            </div>

            {isFilterOpen && (
              <FilterByDate
                title={<FormattedMessage {...globalMessages.filterYourOrders} />}
                dateFrom={dateFrom}
                dateTo={dateTo}
                hasFilter={hasFilter}
                onClear={this.handleClearFilter}
                onApply={this.handleApplyFilter}
              />
            )}

            <ExportOrdersContainer>
              <ActionButton
                primaryButton={true}
                label={<FormattedMessage {...globalMessages.exportOrders} />}
                onClick={() => openExportOrdersAlert(dateFrom, dateTo)}
              />
            </ExportOrdersContainer>

            {filteredOrdersList.size === 0 && (
              <MessageBlock
                header={<FormattedMessage {...messages.error_header} />}
                body={<FormattedMessage {...messages.error_body} />}
              />
            )}

            {filteredOrdersList.size > 0 && <OrderList orders={filteredOrdersList} venueName={venueName} />}
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const currentVenueID = Number(selectSelectedVenue(state) || getVenueId(state) || '');
  let venues = getOrdersVenueList(state) || new List();

  if (!venues.filter(venue => venue.get('id') === currentVenueID).size) {
    // if no orders from current venue
    venues = venues.push(fromJS({ id: currentVenueID, name: selectVenueName(state) || 'Select...' }));
  }

  return {
    orders: getOrders(state),
    venues,
    userIsLoggedIn: isLoggedIn(state),
    loadingOrders: getOrdersLoading(state),
    venueId: currentVenueID,
    isFilterOpen: selectIsFilterOpen(state, currentVenueID),
    dateFrom: selectDateFrom(state, currentVenueID),
    dateTo: selectDateTo(state, currentVenueID),
    feedbackQuestions: getFeedbackQuestions(state),
  };
};

export default connect(mapStateToProps, {
  fetchOrders,
  toggleFilter,
  clearFilter,
  applyFilter,
  setSelectedVenue,
  fetchQuestions,
  openExportOrdersAlert,
})(Orders);
