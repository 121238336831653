import React from 'react';
import PropTypes from 'prop-types';

const WebsiteIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <polygon
        className="icon WebsiteIcon"
        fill={fill}
        points="782.1,636.4 990,636.4 990,575.9 701.2,575.9 	"
      />
      <polygon
        className="icon WebsiteIcon"
        fill={fill}
        points="10,111.6 10,636.4 485.5,636.4 478.9,575.9 67.4,575.9 67.4,172 990,172 990,111.6 	"
      />
      <path
        className="icon WebsiteIcon"
        fill={fill}
        d="M773.7,699.2l-227-169.6c-7.1-5.3-17.1,0.4-16.1,9.2l31,283.9c0.9,7.8,10.1,11.6,16.1,6.5l60.4-50.7l60.1,104.9 c2.8,4.8,8.9,6.5,13.7,3.7l48.1-27.5c4.8-2.8,6.5-8.9,3.7-13.7l-60.1-104.9l67.7-24.4C778.7,713.7,780,703.9,773.7,699.2z"
      />
      <path
        className="icon WebsiteIcon"
        fill={fill}
        d="M286.1,322.5L273,378.1c-1.1,4.3-2.1,8.4-3,12.4c-0.9,4-1.6,8-2.3,12c-1-4.1-2-8.1-3.2-12.1c-1.2-4-2.3-8.1-3.5-12.3	l-14.8-55.7c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-16.5c-2.4,0-4.5,0.6-6.2,1.9c-1.8,1.2-2.9,2.8-3.6,4.6l-15.5,55.7 c-1.4,4.2-2.6,8.3-3.8,12.3c-1.2,3.9-2.3,7.9-3.3,11.9c-0.6-4-1.4-7.9-2.2-11.9c-0.8-3.9-1.7-8-2.7-12.3l-12.4-55.6	c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-29.6l38.5,124H197c4.1,0,6.7-2.1,7.9-6.2l17.9-61.6c0.9-2.8,1.7-5.6,2.3-8.4	c0.7-2.8,1.3-5.6,1.9-8.4c0.6,2.8,1.2,5.5,1.9,8.2c0.7,2.7,1.5,5.5,2.3,8.2l17.7,61.8c0.6,2.2,1.5,3.8,2.6,4.8 c1.1,1,2.5,1.4,4.1,1.4h30.1l38.5-124h-28.2c-2.4,0-4.5,0.6-6.2,1.9C288,319.2,286.8,320.7,286.1,322.5z"
      />
      <path
        className="icon WebsiteIcon"
        fill={fill}
        d="M509.4,316.1c-2.4,0-4.5,0.6-6.2,1.9c-1.8,1.2-2.9,2.8-3.6,4.6l-13.1,55.6c-1.1,4.3-2.1,8.4-3,12.4c-0.9,4-1.6,8-2.3,12 c-1-4.1-2-8.1-3.2-12.1c-1.2-4-2.3-8.1-3.5-12.3l-14.8-55.7c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-16.5	c-2.4,0-4.5,0.6-6.2,1.9c-1.8,1.2-2.9,2.8-3.6,4.6l-15.5,55.7c-1.4,4.2-2.6,8.3-3.8,12.3c-1.2,3.9-2.3,7.9-3.3,11.9	c-0.6-4-1.4-7.9-2.2-11.9c-0.8-3.9-1.7-8-2.7-12.3l-12.4-55.6c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-29.6l38.5,124 h28.7c4.1,0,6.7-2.1,7.9-6.2l17.9-61.6c0.9-2.8,1.7-5.6,2.3-8.4c0.7-2.8,1.3-5.6,1.9-8.4c0.6,2.8,1.2,5.5,1.9,8.2	c0.7,2.7,1.5,5.5,2.3,8.2l17.7,61.8c0.6,2.2,1.5,3.8,2.6,4.8c1.1,1,2.5,1.4,4.1,1.4h30.1l38.5-124H509.4z"
      />
      <path
        className="icon WebsiteIcon"
        fill={fill}
        d="M631.8,433.8l17.9-61.6c0.9-2.8,1.7-5.6,2.3-8.4c0.7-2.8,1.3-5.6,1.9-8.4c0.6,2.8,1.2,5.5,1.9,8.2c0.7,2.7,1.5,5.5,2.3,8.2	l17.7,61.8c0.6,2.2,1.5,3.8,2.6,4.8c1.1,1,2.5,1.4,4.1,1.4h30.1l38.5-124h-28.2c-2.4,0-4.5,0.6-6.2,1.9c-1.8,1.2-2.9,2.8-3.6,4.6 l-13.1,55.6c-1.1,4.3-2.1,8.4-3,12.4c-0.9,4-1.6,8-2.3,12c-1-4.1-2-8.1-3.2-12.1c-1.2-4-2.3-8.1-3.5-12.3l-14.8-55.7 c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-16.5c-2.4,0-4.5,0.6-6.2,1.9c-1.8,1.2-2.9,2.8-3.6,4.6L621,378.1	c-1.4,4.2-2.6,8.3-3.8,12.3c-1.2,3.9-2.3,7.9-3.3,11.9c-0.6-4-1.4-7.9-2.2-11.9c-0.8-3.9-1.7-8-2.7-12.3l-12.4-55.6	c-0.6-1.8-1.9-3.4-3.6-4.6c-1.8-1.2-4-1.9-6.6-1.9h-29.6l38.5,124h28.7C628,440,630.6,437.9,631.8,433.8z"
      />
      <path
        className="icon WebsiteIcon"
        fill={fill}
        d="M784.7,440.1c2.5,1,5.3,1.6,8.4,1.6c2.9,0,5.7-0.5,8.2-1.6c2.5-1,4.8-2.5,6.7-4.3c1.9-1.8,3.4-4,4.5-6.5	c1.1-2.5,1.6-5.2,1.6-8.1c0-2.9-0.5-5.5-1.6-8c-1.1-2.5-2.6-4.6-4.5-6.5c-1.9-1.8-4.1-3.3-6.7-4.4c-2.6-1.1-5.3-1.6-8.2-1.6	c-3,0-5.8,0.5-8.4,1.6c-2.6,1.1-4.8,2.5-6.6,4.4c-1.9,1.8-3.3,4-4.4,6.5c-1.1,2.5-1.6,5.1-1.6,8c0,2.9,0.5,5.6,1.6,8.1	c1.1,2.5,2.5,4.7,4.4,6.5C779.9,437.7,782.1,439.1,784.7,440.1z"
      />
      <rect className="icon WebsiteIcon" fill={fill} x="857.5" y="253.9" width="28.7" height="226.7" />
    </g>
  </svg>
);

WebsiteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

WebsiteIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default WebsiteIcon;
