import React from 'react';
import PropTypes from 'prop-types';

const ExitIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M316.25,425.543c0-16.844,13.781-30.625,30.625-30.625H622.5v-179.73c0-13.59,16.461-20.48,26.031-10.91
	L919.562,478.18c12.059,12.059,12.059,31.391,0,43.449L648.531,795.531c-9.57,9.57-26.031,2.871-26.031-10.91v-179.73H346.875
	c-16.844,0-30.625-13.781-30.625-30.625V425.543 M255,425.543v148.723c0,50.723,41.152,91.875,91.875,91.875H561.25v118.48
	c0,67.949,82.305,102.402,130.539,54.168l271.223-273.711c35.984-35.984,35.984-94.172,0-130.156L691.789,161.02
	c-48.043-48.043-130.539-13.973-130.539,54.168v118.48H346.875C296.152,333.668,255,375.012,255,425.543L255,425.543z M10,224.375
	v551.25c0,50.723,41.152,91.875,91.875,91.875h252.656c12.633,0,22.969-10.336,22.969-22.969v-15.312
	c0-12.633-10.336-22.969-22.969-22.969H101.875c-16.844,0-30.625-13.781-30.625-30.625v-551.25
	c0-16.844,13.781-30.625,30.625-30.625h252.656c12.633,0,22.969-10.336,22.969-22.969v-15.312c0-12.633-10.336-22.969-22.969-22.969
	H101.875C51.152,132.5,10,173.652,10,224.375z"
      />
    </g>
  </svg>
);

ExitIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ExitIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ExitIcon;
