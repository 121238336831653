import React from 'react';

function TabOrderIcon(props) {
  return (
    <svg height="100%" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M400 0c-3 0-7 1-10 3l-38 45-54-45c-3-2-7-3-10-3s-7 1-10 3l-54 45-54-45c-3-2-7-3-10-3s-7 1-10 3L96 48 58 3c-3-2-7-3-10-3-8 0-16 7-16 16v480c0 9 8 16 16 16 3 0 7-1 10-3l38-45 54 45c3 2 7 3 10 3s7-1 10-3l54-45 54 45c3 2 7 3 10 3s7-1 10-3l54-45 38 45c3 2 7 3 10 3 8 0 16-7 16-16V16c0-9-8-16-16-16Zm-36 454c-3-4-7-6-12-6-4 0-7 1-10 4l-53 44-55-44c-3-3-6-4-10-4s-7 1-10 4l-53 44-55-44c-3-3-6-4-10-4-5 0-9 2-12 6l-36 41V17l36 41c3 4 7 6 12 6 4 0 7-1 10-4l53-44 55 44c3 3 6 4 10 4s7-1 10-4l53-44 55 44c3 3 6 4 10 4 5 0 9-2 12-6l36-41v478l-36-41Zm-20-302H104c-4 0-8 4-8 8s4 8 8 8h240c4 0 8-4 8-8s-4-8-8-8Zm0 96H104c-4 0-8 4-8 8s4 8 8 8h240c4 0 8-4 8-8s-4-8-8-8Zm0 96H104c-4 0-8 4-8 8s4 8 8 8h240c4 0 8-4 8-8s-4-8-8-8Z" />
    </svg>
  );
}

export default TabOrderIcon;
