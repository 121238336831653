import React from 'react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'components/globalMessages';
import { AddressLabel, DefaultAddress, AddressLabelContainer } from './styles';

type Props = {
  address?: Map<string, any>;
  isDefault?: boolean;
  primaryText?: boolean;
};

const AddressText = ({ address, isDefault, primaryText }: Props) => {
  const buildAddress = add => (
    <div>
      {add?.get('address1') && add.get('address1')}
      {add?.get('address2') && `, ${add.get('address2')}`}
      {add?.get('townCity') && `, ${add.get('townCity')}`}
      {add?.get('county') && `, ${add.get('county')}`}
      {add?.get('postcode') && `, ${add.get('postcode')}`}
      {add?.get('addressPin') && `, ${add.get('addressPin')}`}
      {add?.get('addressNotes') && `, ${add.get('addressNotes')}`}
    </div>
  );

  return (
    <AddressLabelContainer>
      <AddressLabel primaryText={primaryText}>
        {address?.get('addressLabel')}
        {isDefault && (
          <DefaultAddress>
            <FormattedMessage {...globalMessages.isDefaultText} />
          </DefaultAddress>
        )}
      </AddressLabel>

      {buildAddress(address)}
    </AddressLabelContainer>
  );
};

export default AddressText;
