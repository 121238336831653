import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function useScrollPositions(pathname) {
  const [positionsObj, setPositionsObj] = useState({});

  useEffect(() => {
    if (typeof window === 'undefined') return false;

    const handleScroll = debounce(() => {
      setPositionsObj(prevState => setPositionsObj({ ...prevState, [pathname]: window.pageYOffset }));
    }, 400);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [pathname]);

  return [positionsObj];
}

export default useScrollPositions;
