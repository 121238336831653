import React from 'react';
import { NoFundsLeft, Wrapper } from './styles';
import FormattedPrice from '../FormattedPrice';

function RemainingBalance({ amount }: { amount: number }) {
  return (
    <Wrapper>
      Remaining balance:{' '}
      {amount <= 0 && (
        <NoFundsLeft>
          <FormattedPrice value={amount} />
        </NoFundsLeft>
      )}
      {amount > 0 && <FormattedPrice value={amount} />}
    </Wrapper>
  );
}

export default RemainingBalance;
