import 'isomorphic-fetch';

import { trackEvent } from 'utils/tracking';
import fetchHelper from 'utils/fetchHelper';

const CALL_A_WAITER = 'app/callAWaiter/CALL_A_WAITER';

export const callWaiter = (serviceId, tableNumber, success, failure) => dispatch => {
  dispatch({
    type: CALL_A_WAITER,
    serviceId,
    tableNumber,
  });

  trackEvent('call_waiter', {
    category: 'hospitality',
    location: 'sidebar',
  });

  fetchHelper(
    `/api/service/${serviceId}/call-a-waiter?tableNumber=${tableNumber}`,
    'GET',
    null,
    success,
    failure
  );
};
