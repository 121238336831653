import styled from 'styled-components';

export const PageContent = styled.div`
  background-color: ${({ theme }) => theme.color.grayLighter};
  border-radius: 5px;
  display: grid;
  height: calc(100vh - 15rem);
  margin: 1rem;
  padding: 1rem;
  place-items: center;
`;

export const Message = styled.h1`
  color: ${({ theme }) => theme.color.text};
  font-size: 2.25rem;
`;
