import styled from 'styled-components';
import { Link } from 'react-router';

export const Container = styled.div`
  min-height: calc(100% - 75px);
`;

export const StyledLink = styled(Link)`
  position: absolute;
  left: 15px;
  top: 15px;

  html[dir='rtl'] & {
    left: auto;
    right: 15px;
  }
`;

export const Title = styled.h2`
  color: #fff;
  font-size: ${({ theme }) => theme.fonts.fontSizeBigOne};
  font-weight: normal;
  margin: 15px 0;
  text-align: center;
`;

export const ServiceCategoriesWrap = styled.div`
  display: flex;
`;
