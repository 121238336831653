import { fromJS } from 'immutable';

const filters = fromJS({
  allergens: [
    'celery',
    'crustaceans',
    'eggs',
    'fish',
    'gluten',
    'gluten_wheat',
    'gluten_rye',
    'gluten_barley',
    'gluten_oats',
    'lupin',
    'milk',
    'molluscs',
    'mustard',
    'nuts',
    'nut_almonds',
    'nut_hazelnuts',
    'nut_walnuts',
    'nut_cashews',
    'nut_pecan',
    'nut_brazil',
    'nut_pistachio',
    'nut_macadamia',
    'peanuts',
    'sesame_seeds',
    'soya',
    'sulphur_dioxide',
  ],
  lifestyleChoices: ['halal', 'kosher', 'vegetarian', 'vegan'],
});

export default filters;
