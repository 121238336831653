import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import AlertIcon from 'components/Icons/AlertIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import { getUserOfflineStatus } from 'selectors/user';

import { Container, IconWrap, TextWrap, CloseIconWrap } from './styles';

const messages = defineMessages({
  message: {
    defaultMessage: ' You are currently offline, some features may not work',
  },
});

class OfflineBanner extends React.Component {
  state = {
    bannerClosed: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.offline === false && this.props.offline === true && this.state.bannerClosed) {
      this.openBanner();
    }
  }

  openBanner = () => this.setState({ bannerClosed: false });

  onClose = () => this.setState({ bannerClosed: true });

  render() {
    const { offline } = this.props;

    return (
      offline &&
      !this.state.bannerClosed && (
        <Container className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
          <div className="u-flex u-flexAlignItemsCenter">
            <IconWrap>
              <AlertIcon width="28px" height="28px" />
            </IconWrap>
            <TextWrap>
              <FormattedMessage {...messages.message} />
            </TextWrap>
          </div>

          <CloseIconWrap onClick={this.onClose}>
            <CloseIcon width="34px" height="34px" />
          </CloseIconWrap>
        </Container>
      )
    );
  }
}

const mapStateToProps = state => ({
  offline: getUserOfflineStatus(state),
});

OfflineBanner.propTypes = {
  offline: PropTypes.bool,
};

export default connect(mapStateToProps)(OfflineBanner);
