import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import LoginForm from 'components/LoginForm';
import RegisterForm from 'components/RegisterForm';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import { changeLoginRegisterAlert } from 'actions/UI';

import messagesForgotPassword from 'components/ForgotPasswordForm/intl';
import globalMessages from 'components/globalMessages';

import SectionLink from 'components/Alert/alerts/LoginRegisterAlert/SectionLink';
import { AlertContent } from 'assets/styles/sharedStyles';

import { MessageContainer } from 'components/Alert/styles';

const ALERT_SECTIONS = {
  LOGIN_FORM: LoginForm,
  REGISTER_FORM: RegisterForm,
  FORGOT_PASSWORD_FORM: ForgotPasswordForm,
};

class LoginRegisterAlert extends Component {
  static defaultProps = {
    section: 'LOGIN_FORM',
    loginText: null,
    onSubmit: () => {},
    closeAlert: () => {},
  };

  static propTypes = {
    section: PropTypes.oneOf(['LOGIN_FORM', 'REGISTER_FORM', 'FORGOT_PASSWORD_FORM']),
    loginText: PropTypes.node,
    onSubmit: PropTypes.func,
    closeAlert: PropTypes.func,
    changeLoginRegisterAlert: PropTypes.func,
  };

  state = {
    section: this.props.section,
  };

  handleShowSection = section => () => {
    this.setState({ section });
    this.props.changeLoginRegisterAlert(section);
  };

  render() {
    const { section } = this.state;
    const { closeAlert, loginText, onSubmit } = this.props;
    const SectionComponent = ALERT_SECTIONS[section];

    return (
      <>
        <AlertContent>
          <SectionComponent
            onSubmit={onSubmit}
            closeAlert={closeAlert}
            message={section === 'LOGIN_FORM' ? loginText : null}
          >
            {() => (
              <>
                {section !== 'FORGOT_PASSWORD_FORM' && section !== 'REGISTER_FORM' && (
                  <div className="u-textRight">
                    <SectionLink
                      onClick={this.handleShowSection('FORGOT_PASSWORD_FORM')}
                      message={<FormattedMessage {...messagesForgotPassword.forgotPassword} />}
                    />
                  </div>
                )}

                {section !== 'LOGIN_FORM' && (
                  <div className="u-textCenter containerFullPad">
                    <MessageContainer>
                      <FormattedMessage {...globalMessages.haveAccount} />
                    </MessageContainer>
                    <SectionLink
                      onClick={this.handleShowSection('LOGIN_FORM')}
                      message={<FormattedMessage {...globalMessages.login} />}
                    />
                  </div>
                )}

                {section !== 'REGISTER_FORM' && (
                  <div className="u-textCenter containerFullPad">
                    <MessageContainer>
                      <FormattedMessage {...globalMessages.noAccount} />
                    </MessageContainer>
                    <SectionLink
                      onClick={this.handleShowSection('REGISTER_FORM')}
                      message={<FormattedMessage {...globalMessages.signUp} />}
                    />
                  </div>
                )}
              </>
            )}
          </SectionComponent>
        </AlertContent>
      </>
    );
  }
}

export default connect(undefined, { changeLoginRegisterAlert })(LoginRegisterAlert);
