import styled from 'styled-components';
import {
  PaymentChoiceContainer,
  sharedBorderStyles,
  backgroundStyle,
} from 'components/PaymentSelection/PaymentChoice/styles';

export const HeaderButtonContainer = styled.div`
  ${sharedBorderStyles};
  ${backgroundStyle};

  ${PaymentChoiceContainer}:first-child {
    border: none;
    margin: 0;
    width: 100%;
  }
  margin: 5px 0 10px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const PaymentsContainer = styled.div`
  width: 100%;
  padding-left: 40px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.color.grayLighter : theme.color.inputBackground};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  html[dir='rtl'] & {
    padding-left: 0;
    padding-right: 40px;
  }
`;
