import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import fetchData from 'utils/fetchHelper';
import { AlertContent } from 'assets/styles/sharedStyles';
import ComoLogo from 'components/Icons/ComoLogo';
import { setLoyaltyUser } from 'actions/loyalty';
import { useLoyalty } from 'hooks';

import SignInPage from './sign-in';
import SplashPage from './splash-page';
import SignOut from './sign-out';
import CreateAccountPage from './create-account';
import OTP from './otp';
import UserCard from './UserCard';
import { Container, StyledTitle, StyledAlertContent } from './styles';

const FLOW_SIGN_IN = 'flow/SIGN_IN';
const FLOW_CREATE_ACCOUNT = 'flow/CREATE_ACCOUNT';

const ComoModal = ({ closeAlert = () => {}, startPage = 'splash' }) => {
  const loyalty = useLoyalty();
  const intl = useIntl();
  const { user } = loyalty;

  const dispatch = useDispatch();

  const [page, setPage] = useState(user ? 'userCard' : startPage);
  const [flow, setFlow] = useState(FLOW_SIGN_IN);
  const [flowError, setFlowError] = useState('');
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const handleRegistration = async e => {
    e.preventDefault();

    const formData = new FormData(e.target);

    setFlowError('');
    setFlow(FLOW_CREATE_ACCOUNT);
    const data = Object.fromEntries(formData);

    if (data.acceptsTerms !== 'on') {
      setFlowError(intl.formatMessage({ defaultMessage: 'You must accept the Terms and Conditions' }));
    } else if (
      data.signInMethod === 'mobile' &&
      data.phoneNumber
        ?.trim()
        .replace(/(^\+[0-9]{2})/, '')
        .replace(/^\s+/g, '') === ''
    ) {
      setFlowError(intl.formatMessage({ defaultMessage: 'Enter a phone number' }));
    } else if (data.signInMethod === 'email' && data.email?.trim() === '') {
      setFlowError(intl.formatMessage({ defaultMessage: 'Enter an email address' }));
    } else {
      await sendOtp({ method: 'create_account', ...data });
    }
  };

  const handleSignIn = async e => {
    e.preventDefault();

    const formData = new FormData(e.target);

    setFlow(FLOW_SIGN_IN);
    setFlowError('');
    const data = Object.fromEntries(formData);

    await sendOtp({ method: 'sign_in', ...data });
  };

  const handleResend = async () => {
    console.log({ accountDetails, flow });
  };

  const sendOtp = async body => {
    setLoading(true);
    try {
      const result = await fetchData('/api/loyalty/user/send-otp', 'POST', body);
      if (!result.success) {
        setFlowError(result.error || 'Unknown Sign-in Error Occurred');
      } else {
        setAccountDetails(body);
        setPage('otp');
      }
    } catch (error) {
      setFlowError(error.response?.error || 'Unkown error when sending OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleOtp = async e => {
    e.preventDefault();
    setFlowError('');
    setLoading(true);

    const formData = new FormData(e.target);
    const { code } = Object.fromEntries(formData);

    const body = { code: parseInt(code, 10), ...accountDetails };

    try {
      const res = await fetchData('/api/loyalty/user/verify-otp', 'POST', body);

      if (res.success) {
        dispatch(setLoyaltyUser(res.user));
        setPage('signInSuccess');
      } else {
        setFlowError('Invalid OTP, unable to verify');
      }
    } catch (error) {
      if (flow === FLOW_SIGN_IN) {
        setFlowError(error.response?.error || 'Unknown Login Error Occured');
      } else {
        setFlowError(error.response?.error || 'Unknown Registration Error Occured');
        setPage('createAccount');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    await fetchData('/api/loyalty/user/sign_out', 'POST');
    dispatch(setLoyaltyUser(undefined));
    setPage('signOutSuccess');
  };

  const pages = {
    splash: {
      content: SplashPage,
      title: (
        <StyledTitle>
          <ComoLogo width="80px" />
        </StyledTitle>
      ),
      container: StyledAlertContent,
    },
    signIn: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="Sign In" />
        </StyledTitle>
      ),
      content: () => <SignInPage handleSignIn={handleSignIn} error={flowError} loading={loading} />,
      container: StyledAlertContent,
    },
    signInSuccess: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="Success!" />
        </StyledTitle>
      ),
      content: () => (
        <Container>
          <FormattedMessage defaultMessage="You can now start earning loyalty points!" />
        </Container>
      ),
      container: StyledAlertContent,
    },
    signUp: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="Join Vapiano Lovers Loyalty Programme" />
        </StyledTitle>
      ),
      content: () => (
        <CreateAccountPage error={flowError} handleRegistration={handleRegistration} loading={loading} />
      ),
      container: StyledAlertContent,
    },
    signOut: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="Sign Out" />
        </StyledTitle>
      ),
      content: SignOut,
      container: StyledAlertContent,
    },
    signOutSuccess: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="Signed Out" />
        </StyledTitle>
      ),
      content: () => (
        <Container>
          <FormattedMessage defaultMessage="You've successfully signed out of your Vapiano Lovers account." />
        </Container>
      ),
      container: StyledAlertContent,
    },
    otp: {
      title: (
        <StyledTitle>
          <FormattedMessage defaultMessage="One Time Pin" />
        </StyledTitle>
      ),
      content: () => (
        <OTP handleOtp={handleOtp} handleResend={handleResend} error={flowError} loading={loading} />
      ),
      container: StyledAlertContent,
    },
    userCard: {
      title: (
        <StyledTitle>
          <ComoLogo width="80px" />
        </StyledTitle>
      ),
      content: () => <UserCard user={user} handleSignOut={handleSignOut} />,
      container: StyledAlertContent,
    },
  };

  const PageContent = pages[page].content;
  const PageContainer = pages[page].container || AlertContent;

  return (
    <>
      {pages[page].title}
      <PageContainer>
        <PageContent
          setPage={setPage}
          closeAlert={closeAlert}
          user={user}
          loyaltyProgramme="Vapiano Lovers"
        />
      </PageContainer>
    </>
  );
};

ComoModal.propTypes = {
  closeAlert: PropTypes.func,
  startPage: PropTypes.oneOf([
    'splash',
    'signIn',
    'signInSuccess',
    'signUp',
    'signOut',
    'signOutSuccess',
    'otp',
    'userCard',
  ]),
};

export default ComoModal;
