import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  amount: 0 | 1 | 2 | 3;
}

export const ChilliIcon = ({ amount }: Props) => {
  // as any not the best solution but theme's type is DefaultType and DefaultType is empty object
  const theme = useTheme() as any;
  const danger = theme.color.red;

  if (!amount) {
    return null;
  }
  return (
    <svg width="25" height="20" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 16082">
        <path
          id="Vector"
          d="M6.25 6.25V15.625C6.25 15.625 0 14.0625 0 7.03125V6.25C0 5.67969 0.3125 5.17969 0.78125 4.90625L1.75781 5.46875L3.125 4.6875L4.49219 5.46875L5.46875 4.90625C5.9375 5.17969 6.25 5.67969 6.25 6.25ZM3.125 3.51562L4.49219 4.29688L5.67969 3.61719C5.25 2.85938 4.61719 2.29688 3.88281 2.07031C3.81662 1.50098 3.54383 0.975732 3.11616 0.594139C2.68848 0.212546 2.13566 0.00113912 1.5625 0V1.5625C1.90625 1.5625 2.1875 1.78906 2.29687 2.10156C1.58594 2.34375 0.984375 2.89062 0.570312 3.61719L1.75781 4.29688L3.125 3.51562Z"
          fill={danger}
        />
        <path
          id="Vector_2"
          d="M10.9378 5.80724L11.132 5.9191L11.3266 5.80791L12.5 5.1374L13.6734 5.80791L13.868 5.9191L14.0622 5.80724L14.8234 5.36878C15.0776 5.58555 15.2344 5.90168 15.2344 6.25V15.0871C15.1099 15.0408 14.9652 14.9832 14.805 14.9131C14.2431 14.6673 13.494 14.2692 12.7463 13.6617C11.2602 12.4542 9.76562 10.4113 9.76562 7.03125V6.25C9.76562 5.90168 9.92245 5.58555 10.1766 5.36878L10.9378 5.80724ZM12.6938 3.17647L12.5 3.06572L12.3062 3.17647L11.133 3.84688L10.4957 3.48215C10.8496 2.99268 11.2998 2.64099 11.7978 2.47132L12.1707 2.3443L12.0406 1.97252C11.9241 1.63978 11.6668 1.36255 11.3281 1.23979V0.431099C11.6616 0.500134 11.9736 0.655839 12.2311 0.885607C12.5874 1.20352 12.8147 1.64111 12.8698 2.11542L12.8992 2.36848L13.1427 2.44358C13.6591 2.6029 14.1345 2.96575 14.5051 3.48164L13.867 3.84688L12.6938 3.17647Z"
          stroke={danger}
          strokeWidth="0.78125"
          fill={amount >= 2 ? danger : 'transparent'}
        />

        <path
          id="Vector_3"
          d="M20.3128 5.80724L20.507 5.9191L20.7016 5.80791L21.875 5.1374L23.0484 5.80791L23.243 5.9191L23.4372 5.80724L24.1984 5.36878C24.4526 5.58555 24.6094 5.90168 24.6094 6.25V15.0871C24.4849 15.0408 24.3402 14.9832 24.18 14.9131C23.6181 14.6673 22.869 14.2692 22.1213 13.6617C20.6352 12.4542 19.1406 10.4113 19.1406 7.03125V6.25C19.1406 5.90168 19.2974 5.58555 19.5516 5.36878L20.3128 5.80724ZM22.0688 3.17647L21.875 3.06572L21.6812 3.17647L20.508 3.84688L19.8707 3.48215C20.2246 2.99268 20.6748 2.64099 21.1728 2.47132L21.5457 2.3443L21.4156 1.97252C21.2991 1.63978 21.0418 1.36255 20.7031 1.23979V0.431099C21.0366 0.500134 21.3486 0.655839 21.6061 0.885607C21.9624 1.20352 22.1897 1.64111 22.2448 2.11542L22.2742 2.36848L22.5177 2.44358C23.0341 2.6029 23.5095 2.96575 23.8801 3.48164L23.242 3.84688L22.0688 3.17647Z"
          stroke={danger}
          strokeWidth="0.78125"
          fill={amount === 3 ? danger : 'transparent'}
        />
      </g>
    </svg>
  );
};
