import { fromJS } from 'immutable';

import {
  REQUEST_ALL_VENUES,
  REQUEST_ALL_VENUES_SUCCESS,
  REQUEST_ALL_VENUES_FAILURE,
} from 'actions/venues/constants';

export const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_VENUES:
      return state.setIn(['venues', 'loading'], true).setIn(['venues', 'error'], false);

    case REQUEST_ALL_VENUES_SUCCESS:
      return state
        .setIn(['venues', 'loading'], false)
        .setIn(['venues', 'error'], false)
        .setIn(['venues', 'venuesList'], fromJS(action.venuesList));

    case REQUEST_ALL_VENUES_FAILURE:
      return state.setIn(['venues', 'loading'], false).setIn(['venues', 'error'], true);

    default:
      return state;
  }
};
