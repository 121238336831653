import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;

  & > div {
    min-width: 200px;
    white-space: break-spaces;
    top: -20px;
    left: 50%;
    transform: translate(-90%, -100%);
    padding: 10px 20px;
    color: ${({ theme }) => theme.color.darkGrey};
    background-color: white;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;

    @media (min-width: 380px) {
      min-width: 300px;
    }
  }

  &:hover {
    & > div {
      visibility: visible;
      opacity: 1;
    }
  }

  & > div {
    & > i {
      position: absolute;
      top: 100%;
      left: 90%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
    }
  }

  & > div {
    & > i::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: white;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    }
  }
`;

interface Props {
  children: React.ReactNode;
  text: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({ children, text }) => (
  <Wrapper className="btn btn-primary tooltip">
    {children}
    <div>
      <span>{text}</span>
      <i />
    </div>
  </Wrapper>
);

export default Tooltip;
