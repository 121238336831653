import React from 'react';
import PropTypes from 'prop-types';

// Icon
const DeliveryIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 62.222"
    width={width}
    height={height}
    className={className}
  >
    <path
      className="icon deliveryIcon"
      fill={fill}
      d="M67.813,40.59a6.073,6.073,0,0,0-4.253-1.7,6.936,6.936,0,0,0-4.253,1.458l-7.413,6.076a1.756,1.756,0,0,1-.851.243H46.059a7.07,7.07,0,0,0,.486-3.889,6.94,6.94,0,0,0-6.927-5.833H22a11.849,11.849,0,0,0-6.927,2.309l-4.74,3.524H1.944a1.944,1.944,0,1,0,0,3.889h9.722l5.712-4.253A7.1,7.1,0,0,1,22,40.833H39.861a2.917,2.917,0,1,1,0,5.833H29.167a1.944,1.944,0,1,0,0,3.889H51.042a5.332,5.332,0,0,0,3.281-1.094l7.535-5.955a2.35,2.35,0,0,1,3.4,0,2.53,2.53,0,0,1-.122,3.889L52.865,57.24a4.993,4.993,0,0,1-3.16,1.094H1.944a1.944,1.944,0,0,0,0,3.889H49.583A9.529,9.529,0,0,0,55.3,60.278l12.274-9.844A6.755,6.755,0,0,0,70,45.573,6.366,6.366,0,0,0,67.813,40.59ZM19.444,31.111H50.556a7.832,7.832,0,0,0,7.778-7.778V7.778A7.832,7.832,0,0,0,50.556,0H19.444a7.832,7.832,0,0,0-7.778,7.778V23.333A7.832,7.832,0,0,0,19.444,31.111ZM29.167,3.889H40.833V12.4c-1.215-.608-2.917-1.458-4.983-2.431a1.46,1.46,0,0,0-1.7,0L29.167,12.4ZM15.556,7.778a3.839,3.839,0,0,1,3.889-3.889h5.833V15.556a1.939,1.939,0,0,0,.972,1.7,2.289,2.289,0,0,0,.972.243,2.253,2.253,0,0,0,.851-.243L35,13.854c1.7.851,5.226,2.552,6.441,3.16.729.729,2.917.486,3.281-.972,0-.486.122-.729,0-.972V3.889h5.833a3.839,3.839,0,0,1,3.889,3.889V23.333a3.839,3.839,0,0,1-3.889,3.889H19.444a3.839,3.839,0,0,1-3.889-3.889Z"
    />
  </svg>
);

DeliveryIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

DeliveryIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default DeliveryIcon;
