import React from 'react';
import PropTypes from 'prop-types';

const CoffeeIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon"
      fill={fill}
      d="M782.491,464.068v-24.937c0.315-6.457-0.946-19.348-12.867-33.27
      c-32.421-38.024-123.714-57.28-271.193-57.28c-216.993,0-262.168,28.987-277.3,50.824c-6.726,9.708-7.488,18.764-6.794,24.907
      c0.156,5.583,1.884,13.115,8.092,21.007c9.192,11.657,37.172,47.169,276.001,47.169c33.918,0,65.218-0.425,93.015-1.322
      c13.675-0.425,24.428-11.882,23.974-25.603c-0.401-13.698-12-24.503-25.544-24.01c-27.305,0.875-58.064,1.323-91.445,1.323
      c-145.432,0-202.937-13.385-224.727-21.815c21.23-8.698,78.309-22.845,224.727-22.845c199.671,0,230.884,35.714,234.47,41.139
      l0.046,432.326c-4.04,15.672-82.189,65.039-234.516,65.039c-157.202,0-238.291-45.085-238.291-63.939
      c0.179-11.501,4.506-282.749-0.046-349.4c-0.941-13.698-12.779-23.787-26.454-23.07c-13.675,0.941-23.989,12.757-23.07,26.433
      c4.417,64.612-0.023,342.428-0.067,345.656c0,74.813,144.872,113.956,287.927,113.956c153.982,0,284.06-52.192,284.06-113.956
      v-20.746c104.973-2.228,189.666-88.306,189.666-193.804C972.157,552.352,887.464,466.295,782.491,464.068z M782.491,599.277
      c30.436,2.149,54.569,27.582,54.569,58.572c0,30.99-24.133,56.424-54.569,58.573V599.277z M782.491,802.005v-35.893
      c57.8-2.201,104.16-49.915,104.16-108.263c0-58.347-46.36-106.083-104.16-108.285v-35.893
      c77.575,2.217,140.029,66.055,140.029,144.178S860.067,799.789,782.491,802.005z"
    />
    <path
      className="icon"
      fill={fill}
      d="M507.398,328.628c2.353,0.694,4.731,1.031,7.061,1.031c10.718,0,20.582-6.972,23.81-17.779
      c3.9-13.138-3.632-26.948-16.748-30.848c-12.353-3.655-18.362-13.608-17.8-29.57c0.47-14.193,10.491-22.487,11.636-23.406
      c13.092-8.519,34.032-30.961,33.762-63.96c0-26.455-10.672-47.484-32.396-62.975l-1.682-1.256
      c-3.407-2.488-10.535-7.622-10.535-22.643c0-14.595,9.147-21.68,10.491-22.621c11.299-7.376,14.662-22.442,7.466-33.921
      c-7.265-11.635-22.577-15.177-34.19-7.891c-12.421,7.757-33.405,30.042-33.405,64.433c0,18.383,5.402,44.21,31.252,62.952
      c0.628,0.493,1.435,1.099,2.422,1.793c4.596,3.296,10.939,7.802,10.939,22.329c0.113,14.079-10.335,21.546-11.142,22.106
      c-0.314,0.225-0.65,0.427-0.964,0.65c-3.273,2.22-31.925,23.046-33.27,62.706C452.83,288.273,473.255,318.471,507.398,328.628z"
    />
    <path
      className="icon"
      fill={fill}
      d="M333.404,207.341c1.793,1.435,11.344,9.708,11.815,23.539c0.539,15.986-5.448,25.94-17.779,29.593
      c-13.16,3.923-20.648,17.733-16.746,30.848c3.205,10.807,13.092,17.779,23.785,17.779c2.332,0,4.709-0.337,7.086-1.031
      c34.143-10.156,54.567-40.354,53.267-78.871c-1.345-39.681-29.997-60.486-33.853-63.131c-1.166-0.785-11.613-8.273-11.524-22.554
      c0-14.281,6.322-18.81,10.964-22.106c0.964-0.695,1.793-1.299,2.399-1.793c10.805-8.452,12.375-23.831,3.9-34.615
      c-8.452-10.805-24.37-12.442-35.152-3.99c-21.051,15.043-31.723,36.073-31.723,62.281
      C299.551,176.358,320.312,198.754,333.404,207.341z"
    />
    <path
      className="icon"
      fill={fill}
      d="M641.831,207.341c1.793,1.435,11.344,9.708,11.836,23.539c0.539,15.986-5.425,25.94-17.8,29.593
      c-13.137,3.923-20.624,17.733-16.723,30.848c3.232,10.807,13.091,17.779,23.764,17.779c2.378,0,4.709-0.337,7.087-1.031
      c34.162-10.156,54.563-40.354,53.262-78.871c-1.34-39.681-29.991-60.486-33.222-62.706c-0.361-0.246-0.67-0.448-0.985-0.65
      c-0.138-0.067-11.258-7.622-11.12-22.329c0-14.302,6.319-18.81,10.943-22.106c0.939-0.695,1.793-1.299,2.417-1.793
      c10.765-8.452,12.329-23.831,3.901-34.615c-8.473-10.805-24.388-12.442-35.199-3.99c-21.025,15.043-31.685,36.073-31.685,62.281
      C608.011,176.358,628.741,198.754,641.831,207.341z"
    />
  </svg>
);

CoffeeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CoffeeIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CoffeeIcon;
