import { LOYALTY_MODAL } from 'appConstants';
import { OPEN_ALERT } from '../UI/constants';
import { SET_LOYALTY_USER, SET_LOYALTY_SPEND } from './constants';

export const openLoyaltyModal = () => dispatch =>
  dispatch({
    type: OPEN_ALERT,
    alertType: LOYALTY_MODAL,
  });

export const setLoyaltyUser = user => ({
  type: SET_LOYALTY_USER,
  user,
});

export const setLoyaltySpend = loyaltySpend => ({
  type: SET_LOYALTY_SPEND,
  loyaltySpend,
});
