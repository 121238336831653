import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import styled from 'styled-components';

import { messages as labelMessages } from 'components/Form/Fields/Label';
import globalMessages from 'components/globalMessages';
import { convertDateToVenueTimezone, isToday, isTomorrow } from 'utils/date';
import { useSelector } from 'react-redux';
import { selectAvailableFulfilmentMethods } from 'selectors/root';
import messages from './messages';

const Highlight = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

const locationKey = {
  point: 'collection_point',
  table: 'table_number',
  room: 'room_number',
  seat: 'seat',
  sunbed: 'sunbed_number',
  car: 'registration',
};

const getAddress = location => {
  const addressDetails = [
    'address1',
    'address2',
    'town_city',
    'county',
    'country',
    'postcode',
    // 'addressLabel',
    // 'building_access',
    // 'additional_instructions',
  ];
  const address = [];
  addressDetails.forEach(addr => {
    if (location[addr] && location[addr].length) address.push(location[addr]);
  });
  return address.join(', ');
};

const getFulfilmentDate = (fulfilmentTime, timezone = null) => {
  if (getFulfilmentTime(fulfilmentTime, false, timezone) === 'asap') return 'noDate';
  if (isToday(fulfilmentTime, timezone)) return 'today';
  if (isTomorrow(fulfilmentTime, new Date(), timezone)) return 'tomorrow';
  return <FormattedDate value={new Date(fulfilmentTime)} year="numeric" month="long" day="numeric" />;
};

const getFulfilmentTime = (fulfilmentTime, preOrderDatesOnly, timezone = null) => {
  if (preOrderDatesOnly) return 'noTime';

  const isAsap = !fulfilmentTime || fulfilmentTime === 'ASAP';
  const date = convertDateToVenueTimezone(new Date(fulfilmentTime), timezone);

  return isAsap ? `asap` : <FormattedTime value={date} />;
};

// This method finds the overwritten fulfilment method label, as defined in configuration.
// It assumes that there will only ever be one fulfilment method per location type.
const useDefaultLocationLabel = fulfilment => {
  const allFulfilmentMethods = useSelector(state => selectAvailableFulfilmentMethods(state));

  const foundItem = allFulfilmentMethods.find(item => fulfilment.location_type === item.get('location_type'));
  const label = foundItem ? foundItem.get('label') : undefined;

  if (label && label !== '') {
    return label;
  }

  return null;
};

export default (fulfilment, collectionPoints, preOrderDatesOnly) => {
  const defaultLocationLabel = useDefaultLocationLabel(fulfilment);

  switch (fulfilment.location_type) {
    case 'point': {
      const hasMultipleCollectionPoints = collectionPoints && collectionPoints.size > 1;
      let messageName = 'collection';
      if (!hasMultipleCollectionPoints) messageName = 'singleCollection';
      return (
        <FormattedMessage
          {...messages[messageName]}
          values={{
            collectionPoint: (
              <Highlight>
                <FormattedMessage {...labelMessages.collectionPointTitle} />
              </Highlight>
            ),
            pointValue: (
              <Highlight>{` ${fulfilment.location[locationKey[fulfilment.location_type]]} `}</Highlight>
            ),
            date: getFulfilmentDate(fulfilment?.time, fulfilment?.timezone),
            time: getFulfilmentTime(fulfilment?.time, preOrderDatesOnly, fulfilment?.timezone),
          }}
        />
      );
    }
    case 'address': {
      return (
        <FormattedMessage
          {...messages.address}
          values={{
            address: <Highlight>{getAddress(fulfilment.location)}</Highlight>,
            date: getFulfilmentDate(fulfilment?.time, fulfilment?.timezone),
            time: getFulfilmentTime(fulfilment?.time, preOrderDatesOnly, fulfilment?.timezone),
          }}
        />
      );
    }
    default: {
      return (
        <FormattedMessage
          {...messages.location}
          values={{
            location: (
              <Highlight>
                {defaultLocationLabel || <FormattedMessage {...globalMessages[fulfilment.location_type]} />}
              </Highlight>
            ),
            locationValue: (
              <Highlight>{` ${fulfilment.location[locationKey[fulfilment.location_type]]} `}</Highlight>
            ),
            date: getFulfilmentDate(fulfilment?.time, fulfilment?.timezone),
            time: getFulfilmentTime(fulfilment?.time, preOrderDatesOnly, fulfilment?.timezone),
          }}
        />
      );
    }
  }
};
