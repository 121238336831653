import { fromJS, Map, OrderedMap } from 'immutable';
import {
  SET_IS_SERVICE_ACTIVE,
  LOADING_FULFILMENT_TIMESLOTS,
  SET_FULFILMENT_TIMESLOTS,
  SET_VENUE_OFFLINE,
} from 'actions/venue/constants';

const getFulfilmentIndex = (state, serviceId, methodId) =>
  state
    .getIn(['services', serviceId.toString(), 'fulfilment_methods'])
    .findIndex(fulfilment => fulfilment.get('id') === parseInt(methodId, 10));

export default (state, action) => {
  switch (action.type) {
    case SET_VENUE_OFFLINE:
      return state.set('offline', action.offline);

    case SET_IS_SERVICE_ACTIVE: {
      if (!action.id) return state;
      return state.mergeDeep(
        fromJS({
          services: {
            [action.id.toString()]: {
              active: action.active,
              nextActiveTime: action.nextActiveTime,
            },
          },
        })
      );
    }

    case LOADING_FULFILMENT_TIMESLOTS: {
      const fulfilmentIndex = getFulfilmentIndex(state, action.serviceId, action.methodId);

      return state.setIn(
        ['services', action.serviceId?.toString(), 'fulfilment_methods', fulfilmentIndex, 'loadingTimeslots'],
        true
      );
    }

    case SET_FULFILMENT_TIMESLOTS: {
      const fulfilmentIndex = getFulfilmentIndex(state, action.serviceId, action.methodId);

      const timeslots = Object.keys(action.timeslots)
        .filter(key => action.timeslots[key]?.some(timeslot => !!timeslot.available))
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((obj, key) => {
          obj = obj.set(key, fromJS(action.timeslots[key]));
          return obj;
        }, new OrderedMap());

      return state.mergeIn(
        ['services', action.serviceId?.toString(), 'fulfilment_methods', fulfilmentIndex],
        new Map({
          timeslots,
          loadingTimeslots: false,
        })
      );
    }

    default:
      return state || null;
  }
};
