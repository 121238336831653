import styled from 'styled-components';
import { H2 } from 'components/Typography';
import { fontSizeSmall } from 'assets/styles/fonts';

export const ProfileBar = styled.div`
  padding: 26px;
  line-height: 1em;
`;

export const ProfileName = styled(H2)`
  line-height: 1.5em;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Button = styled.button`
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  flex: 1;
  height: 70px;

  &:nth-of-type(2) {
    border-left-width: 1px;
    border-style: solid;
  }

  > svg {
    margin-bottom: 5px;
    height: 70%;
  }

  html[dir='rtl'] & {
    &:nth-of-type(2) {
      border-left-width: 0;
      border-right-width: 1px;
      border-style: solid;
    }
  }
`;

export const Label = styled.div`
  ${fontSizeSmall};

  line-height: 1.1em;
`;
