import 'isomorphic-fetch';
import fetchHelper from 'utils/fetchHelper';
import { getLocale, isLoggedIn } from 'selectors/user';

export default ({ getState }) =>
  next =>
  action => {
    const result = next(action);

    if (action.refreshLocale) {
      const newState = getState();
      const locale = getLocale(newState);

      const setLocaleCookie = () =>
        fetchHelper(
          '/api/users/locale',
          'POST',
          {
            locale,
          },
          () => {
            window.location = '/';
          }
        );

      if (isLoggedIn(newState)) {
        fetchHelper('/api/users/me/default-locale', 'PUT', { default_locale: locale }, setLocaleCookie);
      } else {
        setLocaleCookie();
      }
    }

    return result;
  };
