import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  AlertContent,
  AlertTitle,
  AlertMessage,
  AlertFooter,
  AlertButtons,
} from '../../../../assets/styles/sharedStyles';
import Input from '../../../Input';
import ActionButton from '../../../ActionButton';
import globalMessages from '../../../globalMessages';

const messages = defineMessages({
  title: {
    defaultMessage: "Who's this item for?",
  },
  description: {
    defaultMessage: "We'll add a label to your item, so that you can identify it later.",
  },
  placeholder: {
    defaultMessage: 'Enter a name',
  },
});

export const InputContainer = styled.div`
  margin: 0 10px;
`;

const EnterName = ({ setEnteredName }) => {
  const [name, setName] = useState('');
  const [buttonState, setButtonState] = useState(true);

  return (
    <AlertContent>
      <AlertTitle>
        <FormattedMessage {...messages.title} />
      </AlertTitle>
      <AlertMessage>
        <p>
          <FormattedMessage {...messages.description} />
        </p>
      </AlertMessage>
      <InputContainer>
        <Input
          type="text"
          name="name"
          required={true}
          label={messages.placeholder.defaultMessage}
          value={name} // Set the input value to your state variable
          onChange={value => {
            setName(value);
            setButtonState(value === '');
          }}
        />
      </InputContainer>
      <AlertFooter>
        <AlertButtons>
          <ActionButton
            primaryButton={true}
            label={<FormattedMessage {...globalMessages.continue} />}
            disabled={buttonState}
            onClick={() => {
              setEnteredName(name);
            }}
          />
        </AlertButtons>
      </AlertFooter>
    </AlertContent>
  );
};

export default connect()(EnterName);
