import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SettingsPage from 'components/Pages/User/SettingsPage';
import InfoBox from 'components/InfoBox';
import FormHeader from 'components/Form/FormHeader';
import { selectUser, selectAllUserThirdPartySettings } from 'selectors/user';
import { selectVenueName } from 'selectors/root';
import MessageBlock from 'components/MessageBlock';
import ToggleCheckbox from 'components/ToggleCheckbox';
import ActionButton from 'components/ActionButton';
import permissionState from 'components/ThirdPartyPermission/permissionState';
import { updateAllSettings } from '../updateSettings';

import { SubmitButtonContainer } from './styles';

const messages = defineMessages({
  settingsPage: {
    defaultMessage: 'User Settings',
  },
  success: {
    defaultMessage: 'Update successful.',
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
  },
  forbidden: {
    defaultMessage: 'Could not authorise change, perhaps your password is incorrect?',
  },
  errorUpdating: {
    defaultMessage: 'Could not update notification settings, please try again later.',
  },
  updateError: {
    defaultMessage: 'Update Error!',
  },
  settingsUpdated: {
    defaultMessage: 'Your notification settings have been updated.',
  },
  loading: {
    defaultMessage: 'Updating...',
  },
  notifications: {
    defaultMessage: 'Notifications',
  },
  infoHeader: {
    defaultMessage: 'Promotional Emails',
  },
  infoMessage: {
    defaultMessage:
      'If you would like to stop receiving promotional emails simply check/select the relevant fields below.',
  },
  setAllMessage: {
    defaultMessage: 'Turn off all promotional emails',
  },
});

class EditNotificationSettings extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    updateAllSettings: PropTypes.func,
    venueName: PropTypes.string,
    venueSettings: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
      venueSettings: props.venueSettings,
    };
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true, error: false, success: false });

    const success = () => {
      this.setState({
        success: true,
        loading: false,
      });
    };

    const fail = error => {
      const message = error.message === 'forbidden' ? messages.forbidden : messages.errorUpdating;
      this.setState({
        error: message,
        loading: false,
      });
    };
    this.props.updateAllSettings(this.state.venueSettings, success, fail);
  };

  updateCheckbox = (id, checked) => {
    const index = this.state.venueSettings.findIndex(setting => setting.venue_id === id);
    const newSetting = this.state.venueSettings[index];
    newSetting.third_party_permission = checked ? permissionState.ACCEPTED : permissionState.DECLINED;
    this.setState(prevState => {
      const newSettings = prevState.venueSettings;
      newSettings[index] = newSetting;
      return { venueSettings: newSettings };
    });
  };

  updateAllCheckboxes = checked => {
    this.setState(prevState => {
      const newSettings = prevState.venueSettings;
      newSettings.forEach(setting => {
        setting.third_party_permission = checked ? permissionState.DECLINED : permissionState.ACCEPTED;
      });
      return { venueSettings: newSettings };
    });
  };

  render = () => (
    <SettingsPage>
      <div>
        <FormHeader title={<FormattedMessage {...messages.notifications} />} />
        {this.state.success === true && (
          <MessageBlock
            header={<FormattedMessage {...messages.success} />}
            body={<FormattedMessage {...messages.settingsUpdated} />}
            type="success"
          />
        )}
        {this.state.error && (
          <MessageBlock
            header={<FormattedMessage {...messages.updateError} />}
            body={
              <div>
                <FormattedMessage {...this.state.error} />
              </div>
            }
            type="error"
          />
        )}
        <InfoBox
          title={<FormattedMessage {...messages.infoHeader} />}
          message={<FormattedMessage {...messages.infoMessage} />}
        />
        <form onSubmit={this.onSubmit}>
          <ToggleCheckbox
            onChange={checked => this.updateAllCheckboxes(checked)}
            label={<FormattedMessage {...messages.setAllMessage} />}
            checked={this.state.venueSettings.every(
              setting => setting.third_party_permission !== permissionState.ACCEPTED
            )}
          />
          {this.state.venueSettings.map(setting => (
            <ToggleCheckbox
              key={setting.venue_id}
              onChange={checked => this.updateCheckbox(setting.venue_id, checked)}
              label={setting.venue_name}
              checked={setting.third_party_permission === permissionState.ACCEPTED}
            />
          ))}
          <SubmitButtonContainer className="endButtons">
            {this.state.loading && (
              <button type="submit" className="loading" disabled={true}>
                <FormattedMessage {...messages.loading} />
              </button>
            )}
            {!this.state.loading && (
              <ActionButton
                primaryButton={true}
                buttonType="submit"
                label={<FormattedMessage {...messages.saveChanges} />}
                tabIndex="3"
              />
            )}
          </SubmitButtonContainer>
        </form>
      </div>
    </SettingsPage>
  );
}

export default connect(
  state => ({
    user: selectUser(state).toJS(),
    venueSettings: selectAllUserThirdPartySettings(state).toJS(),
    venueName: selectVenueName(state),
  }),
  dispatch => ({
    updateAllSettings: (settings, success, fail) => dispatch(updateAllSettings(settings, success, fail)),
  })
)(EditNotificationSettings);
