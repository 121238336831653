import styled, { css } from 'styled-components';
import { fontSizeBig, fontSizeMedium } from 'assets/styles/fonts';
import { ORDER_STATE_PENDING, ORDER_STATE_CONFIRMED } from 'appConstants';

const textStyle = css`
  font-weight: bold;
  ${fontSizeBig};
`;

const getcolor = (theme, status) => {
  switch (status) {
    case ORDER_STATE_CONFIRMED:
      return theme.color.success;
    case ORDER_STATE_PENDING:
      return '#39d';
    default:
      return theme.color.danger;
  }
};

export const OrderListStyled = styled.ul`
  list-style: none;
  margin-top: 20px;
`;

export const OrderButton = styled.button`
  width: 100%;
  padding: 15px 0 15px 5px;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};

  html[dir='rtl'] & {
    padding: 15px 5px 15px 0;
  }
`;

export const OrderInfoContainer = styled.div`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const Date = styled.div`
  ${fontSizeMedium};
`;

export const VenueNameStyled = styled.div`
  ${textStyle}
`;

export const OrderNumber = styled.div`
  ${fontSizeMedium};
`;

export const OrderPaymentInfo = styled.div`
  text-transform: capitalize;
  ${fontSizeMedium};
`;

export const OrderTotalStyled = styled.div`
  ${textStyle}
`;

export const ChevronContainer = styled.div`
  padding: 0 10px;
`;

export const OrderPaymentContainer = styled.div`
  text-align: right;

  html[dir='rtl'] & {
    text-align: left;
  }
`;

export const OrderStatusContainer = styled.div`
  color: ${({ theme, status }) => getcolor(theme, status)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  ${fontSizeMedium};
`;

export const OrderStatusIconContainer = styled.span`
  color: ${({ theme, status }) => getcolor(theme, status)};
  padding-left: 5px;

  svg {
    display: block;
  }

  html[dir='rtl'] & {
    padding-left: 0;
    padding-right: 5px;
  }
`;

export const VenueSelectWrapper = styled.div`
  flex-grow: 1;
`;

export const FilterBtnWrapper = styled.div`
  background: ${({ theme, hasFilter }) => (hasFilter ? theme.color.success : 'rgba(255, 255, 255, 0.59)')};
  color: ${({ theme, hasFilter }) => (hasFilter ? theme.color.white : theme.color.primaryText)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 5px;
  padding: 11px 9px;
  margin: 5px 0 10px;
  margin-left: 5px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }
`;

export const IconFilterWrapper = styled.span`
  padding: 0 3px;
`;

export const FilterTextWrapper = styled.span`
  padding: 0 3px;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};

  @media (min-width: 800px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeMediumOne};
  }
`;

export const ExportOrdersContainer = styled.div`
  margin-top: 20px;
`;
