import React from 'react';
import PropTypes from 'prop-types';

// Icon
const SesameIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon sesameIcon"
        fill={fill}
        opacity={opacity}
        d="M500,944.9c-109.3,0-198.2-88.9-198.2-198.2c0-104.6,64.1-201.4,185.5-279.8l12.7-8.2l12.7,8.2 C634,545.3,698.2,642.1,698.2,746.7C698.2,856,609.3,944.9,500,944.9z M496,517.2c-41.9,29.1-75.5,60.8-99.9,94.1	c-31.6,43-47.6,88.6-47.6,135.4c0,83.6,68,151.5,151.5,151.5c83.6,0,151.5-68,151.5-151.5c0-46.9-16-92.4-47.6-135.4	c-24.4-33.3-58.1-64.9-99.9-94.1l-4-2.8L496,517.2z"
      />
      <path
        className="icon sesameIcon"
        fill={fill}
        opacity={opacity}
        d="M750.7,459.4c-62.3,0-130.2-18.6-201.8-55.2l-13.4-6.9l0.8-15.1c7.3-144.3,59-248.3,149.6-300.6	c30.1-17.4,64.3-26.6,98.9-26.6c70.7,0,136.5,38,171.8,99.1c54.6,94.6,22.1,216.1-72.5,270.7C844.3,447.8,799.5,459.4,750.7,459.4	L750.7,459.4z M784.7,101.7c-26.4,0-52.6,7-75.6,20.3c-40.6,23.4-72,60.1-93.5,108.9c-16.6,37.8-27.2,82.8-31.5,133.6l-0.4,4.8	l4.4,2.1c58.1,27.4,112.8,41.3,162.5,41.3c40.5,0,77.5-9.5,110-28.3c72.4-41.8,97.2-134.6,55.5-207	C889.1,130.8,838.8,101.7,784.7,101.7z"
      />
      <path
        className="icon sesameIcon"
        fill={fill}
        opacity={opacity}
        d="M249.4,459.4c-48.8,0-93.7-11.6-133.3-34.5c-45.8-26.4-78.5-69.2-92.2-120.3C10.1,253.4,17.1,200,43.5,154.2	c35.3-61.1,101.1-99.1,171.8-99.1c34.6,0,68.8,9.2,98.9,26.6c90.6,52.3,142.3,156.2,149.6,300.6l0.8,15.1l-13.4,6.9	C379.6,440.8,311.7,459.4,249.4,459.4z M215.3,101.7c-54.1,0-104.4,29-131.4,75.8c-41.8,72.4-16.9,165.2,55.5,207 c32.5,18.8,69.5,28.3,110,28.3c49.7,0,104.4-13.9,162.5-41.3l4.4-2.1l-0.4-4.8c-4.3-50.8-14.9-95.8-31.5-133.6	c-21.5-48.9-52.9-85.5-93.5-108.9C267.9,108.8,241.7,101.7,215.3,101.7z"
      />
    </g>
  </svg>
);

SesameIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

SesameIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default SesameIcon;
