import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useHandleOutsideClick } from 'hooks';
import globalMessages from 'components/globalMessages';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { Container, SelectStyled, SpinnerContainer, SelectArrow, MenuStyle, Item } from './styles';

export const findOptionLabel = (options, valueId) => {
  const defaultOption = 'Select...';
  const availableOptions = options.filter(option => !option?.disabled);
  if (!availableOptions || !availableOptions.length || !valueId) return defaultOption;

  const option = availableOptions.find(({ value }) => String(value) === String(valueId));
  if (!option) return defaultOption;

  return option.label;
};

type Props = {
  options?: {
    label: string | ReactNode;
    value: string | number;
    disabled: boolean;
  }[];
  haserror?: string;
  setValueOnClick?: (val: string | number) => void;
  value?: string | number;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: ReactNode;
  onBlur?: (val: string | number) => void;
};

const Select = ({
  options,
  haserror,
  setValueOnClick,
  value,
  isLoading,
  disabled,
  placeholder,
  onBlur,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const [userValue, setUserValue] = useState(placeholder || 'Select...');

  const selectRef = useRef();
  useHandleOutsideClick(isOpen, selectRef, setIsOpen, onBlur);

  useEffect(() => {
    if (options && value) {
      const label = findOptionLabel(options, value);
      setUserValue(label);
    }
  }, [options, value]);

  const handleToggleOptions = () => {
    if (!isLoading && !disabled && options?.length) setIsOpen(!isOpen);
  };

  const handleOnSetValue = value => () => {
    setValueOnClick(value);
    setActiveOption(value);
    setIsOpen(false);
  };

  return (
    <Container ref={selectRef}>
      <SelectStyled onClick={handleToggleOptions} haserror={haserror} disabled={disabled}>
        {isLoading ? <FormattedMessage {...globalMessages.loading} /> : userValue}
        {isLoading && (
          <SpinnerContainer>
            <LoadingSpinner width="45px" height="45px" />
          </SpinnerContainer>
        )}
        {!isLoading && <SelectArrow />}
      </SelectStyled>

      {isOpen && (
        <MenuStyle>
          {options.map(item => (
            <Item
              key={item.value}
              highlighted={activeOption === item.value}
              disabled={item?.disabled}
              onClick={item?.disabled ? null : handleOnSetValue(item.value)}
            >
              {item.label}
            </Item>
          ))}
        </MenuStyle>
      )}
    </Container>
  );
};

export default Select;
