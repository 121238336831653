const getUserLocation = (success, error) => {
  if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => success(coords),
      err => {
        switch (err.code) {
          case 1:
            return error('Please enable location sharing');
          case 3:
            return error('The request to get your location timed out');
          default:
            return error('Your location could not be found');
        }
      }
    );
  } else if (typeof error === 'function') error('Geolocation is not supported by this browser.');
};

export default getUserLocation;
