import { Map, List } from 'immutable';

export const getOrganisationVenues = state =>
  state.getIn(['organisation', 'venues', 'venuesList'], new List());

export const getOrganisationVenue = (state, venueId) => {
  const venues = getOrganisationVenues(state);
  return venues.find(venue => venue.get('id') === parseInt(venueId, 10)) || new Map();
};

export const getVenuesLoading = state => state.getIn(['organisation', 'venues', 'loading']);
export const getVenueEnabledMenuTypesIds = (state, venueId) => {
  const result = getOrganisationVenues(state)
    .filter(venue => venue.get('id') === venueId)
    .flatMap(venue => venue.get('enabled_menu_type_ids'));

  return result;
};

export const selectVenueDeliveryPartnersByVenueId = (state, venueId) => {
  const venuesList = getOrganisationVenues(state);
  const venue = venuesList?.find(venue => venue.get('id') === venueId);

  return venue ? venue.get('external_delivery_partners', new Map()) : new Map();
};
