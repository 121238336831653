import React from 'react';
import PropTypes from 'prop-types';

// Icon
const EditIconAlt = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon editIconAlt"
        fill={fill}
        d="M926.7,443.4v542L10,987.4V125.8l429.8,1.9v55.2l-372.8,1.9v743.6l802.6,1.9V441.5L926.7,443.4z M875.3,38.4 c-8.9-8.9-32.2-24.8-61.8-25.7c-35.2-1.1-52.3,17.4-59.9,23.8L321.9,470.1L223,770.6l300.5-98.9L955.2,238 c38.1-39.5,54-70.3,0-119.8L875.3,38.4z M365.6,515.7l110.3,112.2l-110.3,36.1l-36.1-34.2L365.6,515.7z M630,243.8l119.8,117.9 L521.6,591.8L401.8,470.1L630,243.8z M711.8,160.1l121.7,121.7l-41.8,39.9L669.9,201.9L711.8,160.1z M873.4,241.8L751.7,118.2 c0,0,31.7-28.2,41.8-38c4.9-4.8,10.7-11.3,20.9-10.5c6.7,0.5,12.7,2.5,19,8.6l81.8,81.8c9.4,10.7,13.4,23.6,2.9,35.2 C917.5,195.9,873.4,241.8,873.4,241.8z"
      />
    </g>
  </svg>
);

EditIconAlt.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

EditIconAlt.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default EditIconAlt;
