import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  selectSetLifestyleFilters,
  selectSetAllergenFilters,
  selectIsFiltered,
} from 'selectors/allergenFilter';
import { setDetails, removeDetail } from 'actions/storage';
import { allergenContactStaff } from 'selectors/features';
import ActionButton from 'components/ActionButton';
import { AlertContent, AlertFooter, AlertButtons, AlertTitle } from 'assets/styles/sharedStyles';
import FilteredIcon from 'components/Icons/FilteredIcon';
import EmptyFilterIcon from 'components/Icons/FilterIcon';
import AllergenItem from 'components/AllergenItem';
import { AllergenList } from 'components/Alert/alerts/AllergenInformation/styles';
import SystemIcon from 'components/SystemIcon';

import AllergenFilterContactStaff from './AllergenFilterContactStaff';
import dietaryFilters from './dietaryFilters';

import { FilterGroupStyled, GroupTitleStyled, StyledText } from './styles';
import messages from './messages';
import { trackEvent } from '../../../../utils/tracking';

export class AllergenFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allergens: this.props.allergens || [],
      lifestyleChoices: this.props.lifestyleChoices || [],
    };
  }

  updateChoice = (filter, category) => {
    this.setState(prevState => {
      const index = prevState[category].indexOf(filter);
      return index === -1 ? prevState[category].push(filter) : prevState[category].splice(index, 1);
    });
  };

  render() {
    const { closeAlert, allergenContactStaff, setDetails, removeDetail, isFiltered } = this.props;
    const FilterIcon = isFiltered ? FilteredIcon : EmptyFilterIcon;
    return (
      <>
        {allergenContactStaff && <AllergenFilterContactStaff closeAlert={closeAlert} />}
        {!allergenContactStaff && (
          <>
            <AlertContent>
              <SystemIcon Icon={FilterIcon} />
              <AlertTitle>
                <FormattedMessage {...messages.title} />
              </AlertTitle>
              <StyledText>
                <FormattedMessage {...messages.message} />
              </StyledText>
              <FilterGroupStyled>
                <GroupTitleStyled>
                  <FormattedMessage {...messages.dietarySelect} />
                </GroupTitleStyled>
                <AllergenList>
                  {dietaryFilters
                    .get('lifestyleChoices')
                    .map(filter => (
                      <AllergenItem
                        key={filter}
                        allergenName={filter}
                        active={this.state.lifestyleChoices.indexOf(filter) !== -1}
                        onClick={() => this.updateChoice(filter, 'lifestyleChoices')}
                      />
                    ))
                    .toList()}
                </AllergenList>
              </FilterGroupStyled>
              <FilterGroupStyled>
                <GroupTitleStyled>
                  <FormattedMessage {...messages.allergenSelect} />
                </GroupTitleStyled>
                <AllergenList>
                  {dietaryFilters
                    .get('allergens')
                    .map(filter => (
                      <AllergenItem
                        key={filter}
                        allergenName={filter}
                        active={this.state.allergens.indexOf(filter) !== -1}
                        onClick={() => this.updateChoice(filter, 'allergens')}
                      />
                    ))
                    .toList()}
                </AllergenList>
              </FilterGroupStyled>
            </AlertContent>
            <AlertFooter>
              <AlertButtons>
                <ActionButton
                  dangerButton={true}
                  label={<FormattedMessage {...messages.clear_filters} />}
                  onClick={() => {
                    trackEvent('dietary_and_allergens', {
                      category: 'settings',
                      toggle: 'cleared',
                    });
                    removeDetail('allergens');
                    removeDetail('lifestyleChoices');
                    closeAlert();
                  }}
                />
                <ActionButton
                  primaryButton={true}
                  label={<FormattedMessage {...messages.apply_filters} />}
                  onClick={() => {
                    trackEvent('dietary_and_allergens', {
                      category: 'settings',
                      toggle: 'applied',
                    });
                    setDetails({
                      allergens: this.state.allergens,
                      lifestyleChoices: this.state.lifestyleChoices,
                    });
                    closeAlert();
                  }}
                />
              </AlertButtons>
            </AlertFooter>
          </>
        )}
      </>
    );
  }
}

AllergenFilter.propTypes = {
  allergens: PropTypes.array,
  lifestyleChoices: PropTypes.array,
  SheetHeader: PropTypes.func,
  setDetails: PropTypes.func,
  removeDetail: PropTypes.func,
  closeAlert: PropTypes.func,
  allergenContactStaff: PropTypes.bool,
  isFiltered: PropTypes.bool,
};

const mapStateToProps = state => ({
  allergens: selectSetAllergenFilters(state),
  lifestyleChoices: selectSetLifestyleFilters(state),
  allergenContactStaff: allergenContactStaff(state),
  isFiltered: selectIsFiltered(state),
});

export default connect(mapStateToProps, { setDetails, removeDetail })(AllergenFilter);
