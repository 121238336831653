import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import styled from 'styled-components';

import { productType } from 'selectors/orderItemTypes';
import ActionButton from 'components/ActionButton';
import globalMessages from 'components/globalMessages';
import ItemName from 'components/OrderReview/ItemName';
import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import { AlertMessage } from 'components/Alert/StandardAlert/styles';
import messages from './messages';

const StyledItems = styled.div`
  margin-top: 15px;
  list-style: inside;
`;

const UnavailableItemsAlert = props => {
  const { items } = props;
  const quantity = items.reduce((total, item) => {
    if (item.get('type') !== productType) return total;
    return total + item.get('quantity');
  }, 0);

  return (
    <>
      <AlertContent>
        <AlertTitle>
          <FormattedMessage {...messages.header} values={{ quantity }} />
        </AlertTitle>
        <AlertMessage>
          <p>
            <FormattedMessage {...messages.unavailableMessage} values={{ quantity }} />
          </p>
          {!!items.size && (
            <StyledItems>
              <p>
                <FormattedMessage {...messages.itemsRemoved} />
              </p>
              <ul>
                {items.map(item => (
                  <li key={item.get('id')}>
                    <FormattedMessage
                      {...messages.lineItem}
                      values={{
                        item: (
                          <b>
                            <ItemName key={item.get('id')} item={item} />
                          </b>
                        ),
                        reason: messages[item.get('errorReason')] ? (
                          <FormattedMessage {...messages[item.get('errorReason')]} />
                        ) : (
                          ''
                        ),
                      }}
                    />
                  </li>
                ))}
              </ul>
            </StyledItems>
          )}
        </AlertMessage>
      </AlertContent>

      <AlertFooter>
        <AlertButtons>
          <ActionButton
            label={<FormattedMessage {...globalMessages.confirm} />}
            onClick={() => props.closeAlert(props.confirmMethod)}
          />
        </AlertButtons>
      </AlertFooter>
    </>
  );
};

UnavailableItemsAlert.defaultProps = {
  closeAlert: () => undefined,
  confirmMethod: () => undefined,
};

UnavailableItemsAlert.propTypes = {
  items: PropTypes.instanceOf(List),
  closeAlert: PropTypes.func,
  confirmMethod: PropTypes.func,
};

export default UnavailableItemsAlert;
