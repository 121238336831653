import React from 'react';
import PropTypes from 'prop-types';

const FilterAltIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 22.551 18.549"
  >
    <g>
      <path
        className="icon FilterAltIcon"
        fill={fill}
        d="M5.043,9.536a2.841,2.841,0,0,1,0,3.478,2.648,2.648,0,0,1-1.519.992v7.838a.681.681,0,0,1-.705.705.681.681,0,0,1-.705-.705V14.005a2.626,2.626,0,0,1-1.519-.992,2.841,2.841,0,0,1,0-3.478,2.648,2.648,0,0,1,1.519-.992V.705A.681.681,0,0,1,2.819,0a.681.681,0,0,1,.705.705V8.544A2.659,2.659,0,0,1,5.043,9.536ZM3.811,12.266a1.385,1.385,0,0,0,0-1.984,1.385,1.385,0,0,0-1.984,0,1.385,1.385,0,0,0,0,1.984,1.385,1.385,0,0,0,1.984,0Zm7.688,1.5a2.841,2.841,0,0,1,0,3.478,2.648,2.648,0,0,1-1.519.992v3.612a.705.705,0,1,1-1.41,0V18.234a2.626,2.626,0,0,1-1.519-.992,2.841,2.841,0,0,1,0-3.478,2.648,2.648,0,0,1,1.519-.992V.705a.705.705,0,1,1,1.41,0V12.773A2.648,2.648,0,0,1,11.5,13.765ZM10.266,16.5a1.4,1.4,0,1,0-1.984-1.984,1.387,1.387,0,0,0,0,1.984,1.382,1.382,0,0,0,1.982,0ZM17.955,5.309a2.841,2.841,0,0,1,0,3.478,2.648,2.648,0,0,1-1.519.992V21.846a.705.705,0,1,1-1.41,0V9.778a2.626,2.626,0,0,1-1.519-.992,2.841,2.841,0,0,1,0-3.478,2.648,2.648,0,0,1,1.519-.992V.705a.705.705,0,1,1,1.41,0V4.317A2.648,2.648,0,0,1,17.955,5.309ZM16.722,8.04a1.385,1.385,0,1,0-.992.419A1.351,1.351,0,0,0,16.722,8.04Z"
        transform="translate(0 18.549) rotate(-90)"
      />
    </g>
  </svg>
);

FilterAltIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FilterAltIcon.defaultProps = {
  width: '24px',
  height: '24px',
  fill: 'currentColor',
};

export default FilterAltIcon;
