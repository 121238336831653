const address = require('./address');

const filterVenues = (venues, searchTerm) => {
  if (!venues && !searchTerm) return;
  if (!searchTerm) return venues;

  const result = venues.filter(venue => {
    const { services, name, postcode } = venue;
    const firstService = services[Object.keys(services)[0]];
    const service = postcode ? { ...firstService, postcode } : firstService;

    return `${name} ${address(service)}`.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return result;
};

module.exports = filterVenues;
