import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import addCssPrefixTo from 'utils/addCssPrefixTo';
import AllergenIcon from 'components/AllergenIcon';
import allergenMessages from 'components/Alert/alerts/AllergenFilter/messages';
import { StyledAllergenItem } from './styles';

const AllergenItem = ({ allergenName, active, onClick }) => (
  <StyledAllergenItem
    key={allergenName}
    active={active}
    onClick={onClick}
    className={addCssPrefixTo(`FILTER_${allergenName}`)}
  >
    <AllergenIcon allergenName={allergenName} />
    <span>
      <FormattedMessage {...allergenMessages[allergenName]} />
    </span>
  </StyledAllergenItem>
);

AllergenItem.propTypes = {
  allergenName: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AllergenItem;
