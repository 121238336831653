import { fromJS } from 'immutable';

import {
  REQUEST_LANGUAGES,
  REQUEST_LANGUAGES_SUCCESS,
  REQUEST_LANGUAGES_FAILURE,
} from 'actions/languages/constants';

export const initialState = fromJS({
  loading: false,
  languagesList: [],
  error: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LANGUAGES:
      return state.merge(
        fromJS({
          loading: true,
          languagesList: [],
          error: false,
        })
      );

    case REQUEST_LANGUAGES_SUCCESS:
      return state.merge(
        fromJS({
          loading: false,
          languagesList: action.list,
          error: false,
        })
      );

    case REQUEST_LANGUAGES_FAILURE:
      return state.merge(
        fromJS({
          loading: false,
          languagesList: [],
          error: true,
        })
      );

    default:
      return state;
  }
};
