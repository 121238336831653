import styled from 'styled-components';

import { StyledInput } from 'assets/styles/sharedStyles';

export const CouponContainer = styled.div`
  display: flex;
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;
  margin-right: 10px;

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  ${StyledInput}
`;

export const BtnContainer = styled.div`
  min-width: 75px;
  display: flex;
  gap: 10px;

  button {
    text-transform: capitalize;
  }
`;

export const CouponDetails = styled.div`
  color: ${({ theme }) => theme.color.success};
  font-size: 14px;
  margin: 10px 3px 0;
`;
