import React from 'react';
import PropTypes from 'prop-types';

// Icon
const AmexIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391 C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55 c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M29,236.7h34.7l7.8-18.8H89l7.8,18.8H165v-14.4l6.1,14.4h35.4l6.1-14.6 v14.6h169.6l-0.1-30.8h3.3c2.3,0.1,3,0.3,3,4.1v26.8h87.7v-7.2c7.1,3.8,18.1,7.2,32.6,7.2h36.9l7.9-18.8h17.5l7.7,18.8h71.1v-17.8 l10.8,17.8h57V118.8h-56.4v13.9l-7.9-13.9h-57.9v13.9l-7.3-13.9H510c-13.1,0-24.6,1.8-33.9,6.9v-6.9h-53.9v6.9 c-5.9-5.2-14-6.9-22.9-6.9h-197L189,149.2l-13.6-30.5h-62.1v13.9l-6.8-13.9H53.6L29,174.9L29,236.7L29,236.7z M247.9,219.7h-20.8 l-0.1-66.2l-29.4,66.2h-17.8l-29.5-66.3v66.3H109l-7.8-18.9H58.9L51,219.7H29l36.3-84.6h30.2l34.5,80.1v-80.1h33.1l26.6,57.4 l24.4-57.4h33.8L247.9,219.7L247.9,219.7z M94.2,183.3l-13.9-33.7l-13.8,33.7H94.2z M330.7,219.7h-67.8v-84.6h67.8v17.6h-47.5V168 h46.4v17.3h-46.4v16.9h47.5V219.7L330.7,219.7z M426.3,157.9c0,13.5-9,20.4-14.3,22.5c4.4,1.7,8.2,4.7,10,7.1 c2.9,4.2,3.4,8,3.4,15.5v16.6h-20.5l-0.1-10.7c0-5.1,0.5-12.4-3.2-16.5c-3-3-7.5-3.6-14.8-3.6H365v30.8h-20.3v-84.6h46.7 c10.4,0,18,0.3,24.6,4.1C422.4,143,426.3,148.5,426.3,157.9z M400.6,170.5c-2.8,1.7-6.1,1.7-10,1.7h-24.7v-18.8h25 c3.5,0,7.2,0.2,9.6,1.5c2.6,1.2,4.3,3.9,4.3,7.5C404.8,166.1,403.2,169.1,400.6,170.5z M458.8,219.7h-20.7v-84.6h20.7V219.7z M699.2,219.7h-28.8L632,156.2v63.5h-41.3l-7.9-18.9h-42.2l-7.7,18.9h-23.8c-9.9,0-22.4-2.2-29.4-9.4c-7.1-7.2-10.8-16.9-10.8-32.3 c0-12.5,2.2-24,10.9-33.1c6.6-6.7,16.8-9.9,30.8-9.9h19.6v18.1h-19.2c-7.4,0-11.6,1.1-15.6,5c-3.5,3.6-5.8,10.3-5.8,19.2 c0,9.1,1.8,15.6,5.6,19.9c3.1,3.3,8.8,4.4,14.1,4.4h9.1l28.6-66.5h30.4l34.4,80v-80h30.9l35.7,58.9v-58.9h20.8V219.7z M575.9,183.3 l-14-33.7l-14,33.7H575.9z"
      />
      <path
        className="icon"
        fill={fill}
        d="M751,354.7c-4.9,7.2-14.5,10.8-27.5,10.8h-39.2v-18.1h39 c3.9,0,6.6-0.5,8.2-2.1c1.4-1.3,2.4-3.2,2.4-5.5c0-2.5-1-4.4-2.5-5.6c-1.5-1.3-3.6-1.9-7.2-1.9c-19.1-0.6-42.8,0.6-42.8-26.2 c0-12.3,7.8-25.2,29.1-25.2H751v-16.8h-37.6c-11.3,0-19.6,2.7-25.4,6.9v-6.9h-55.6c-8.9,0-19.3,2.2-24.2,6.9v-6.9H509v6.9 c-7.9-5.7-21.2-6.9-27.4-6.9h-65.4v6.9c-6.2-6-20.1-6.9-28.6-6.9h-73.2l-16.8,18.1l-15.7-18.1H172.4v118h107.4l17.3-18.3l16.3,18.3 l66.2,0.1v-27.8h6.5c8.8,0.1,19.1-0.2,28.3-4.1v31.9h54.6v-30.8h2.6c3.4,0,3.7,0.1,3.7,3.5v27.3H641c10.5,0,21.5-2.7,27.6-7.6v7.6 h52.6c10.9,0,21.6-1.5,29.8-5.4V354.7z M422.2,309.3c0,23.5-17.6,28.3-35.3,28.3h-25.3V366h-39.5l-25-28l-26,28h-80.4v-84.6h81.6 l25,27.7l25.8-27.7H388C404.1,281.5,422.2,285.9,422.2,309.3z M260.8,348.3h-49.9v-16.8h44.6v-17.3h-44.6v-15.4h50.9l22.2,24.6 L260.8,348.3z M341.2,357.9l-31.2-34.5l31.2-33.4V357.9z M387.3,320.3h-26.2v-21.5h26.5c7.3,0,12.4,3,12.4,10.4 C400,316.5,395.1,320.3,387.3,320.3z M524.7,281.5h67.7V299H545v15.4h46.4v17.3H545v16.8l47.5,0.1V366h-67.7V281.5L524.7,281.5z M498.7,326.7c4.5,1.7,8.2,4.6,9.9,7.1c2.9,4.1,3.3,8,3.4,15.4V366h-20.4v-10.6c0-5.1,0.5-12.6-3.3-16.6c-3-3-7.5-3.8-14.9-3.8 h-21.7V366h-20.4v-84.6h46.9c10.3,0,17.8,0.5,24.4,4c6.4,3.9,10.4,9.1,10.4,18.8C513.1,317.7,504,324.6,498.7,326.7z M487.2,316 c-2.7,1.6-6.1,1.7-10,1.7h-24.7v-19h25c3.6,0,7.2,0.1,9.7,1.5c2.6,1.4,4.2,4,4.2,7.6C491.4,311.5,489.9,314.5,487.2,316z M670.5,321.4c4,4.1,6.1,9.2,6.1,17.9c0,18.2-11.4,26.7-31.9,26.7h-39.5v-18.1h39.4c3.8,0,6.6-0.5,8.3-2.1c1.4-1.3,2.4-3.2,2.4-5.5 c0-2.5-1.1-4.4-2.5-5.6c-1.6-1.3-3.7-1.9-7.2-1.9c-19-0.6-42.8,0.6-42.8-26.2c0-12.3,7.7-25.2,29-25.2h40.7v18h-37.2 c-3.7,0-6.1,0.1-8.1,1.5c-2.2,1.4-3,3.4-3,6.1c0,3.2,1.9,5.4,4.4,6.3c2.1,0.7,4.4,1,7.9,1l10.9,0.3 C658.3,314.9,665.9,316.8,670.5,321.4z M751,298.8h-37c-3.7,0-6.1,0.1-8.2,1.5c-2.1,1.4-3,3.4-3,6.1c0,3.2,1.8,5.4,4.4,6.3 c2.1,0.7,4.4,1,7.8,1l11,0.3c11.1,0.3,18.5,2.2,23,6.8c0.8,0.6,1.3,1.4,1.9,2.1V298.8z"
      />
    </g>
  </svg>
);

AmexIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

AmexIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default AmexIcon;
