import React from 'react';
import { defineMessages } from 'react-intl';

import StandardAlert from '../StandardAlert';

const messages = defineMessages({
  header: {
    defaultMessage: 'Warning',
  },
  message: {
    defaultMessage:
      'You cannot add products from this menu without clearing your current order, are you sure?',
  },
  confirm: {
    defaultMessage: 'Clear Order',
  },
});

export default props => <StandardAlert {...props} messages={messages} />;
