import React from 'react';
import PropTypes from 'prop-types';

const BeerIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon"
      fill={fill}
      d="M560.316,940.319H439.779c-39.926-0.093-72.285-32.405-72.332-72.33v-19.893  c11.325,2.286,25.173,3.523,42.399,3.616h180.355c17.226-0.093,30.979-1.33,42.447-3.616v19.893  C632.553,907.914,600.242,940.226,560.316,940.319 M590.201,580.563c17.226-0.093,30.979-1.33,42.447-3.616v219.566  c-5.235,2.094-17.893,5.614-42.447,5.518H409.846c-24.412,0.096-37.213-3.328-42.399-5.472V576.947  c11.325,2.286,25.173,3.523,42.399,3.616H590.201z M682.329,533.118l-0.048-41.255c0.048-32.074-12.467-61.435-32.787-83.23  l-0.905-0.953c-21.651-20.889-36.689-65.574-43.589-107.451c-7.234-41.734-7.804-80.134-7.757-89.51v-1.714  c0.476-13.706-10.279-25.219-23.984-25.649c-13.705-0.523-25.174,10.232-25.697,23.936l-0.048,3.427  c0.048,11.136,0.571,51.726,8.47,98.029c8.281,45.827,22.414,97.602,56.724,133.196l0.238,0.331  c12.23,13.041,19.701,30.314,19.701,49.588v33.5c-5.235,2.094-17.94,5.614-42.447,5.518H409.846  c-24.412,0.096-37.213-3.329-42.399-5.472v-33.5c0-19.321,7.423-36.643,19.748-49.727l0.333-0.334  c34.215-35.644,48.253-87.465,56.533-133.196c7.947-46.254,8.423-86.893,8.47-97.979v-2.524c0-13.703-11.183-24.839-24.84-24.839  c-13.705,0-24.84,11.136-24.84,24.839v2.524c0,9.373-0.571,47.729-7.757,89.51c-6.9,41.827-21.937,86.608-43.59,107.497  l-0.999,1.048c-20.272,21.699-32.835,51.107-32.74,83.181v40.497l-0.095,0.331l0.095,2.19l-0.047,0.096v268.39l-0.048,0.473  l0.095,2.001l-0.047,62.1C317.766,935.37,372.348,990,439.779,990h120.537c67.431,0,121.965-54.63,122.013-122.011v-64.29V533.31  V533.118z"
    />
    <path
      className="icon"
      fill={fill}
      d="M489.859,59.681h13.8c18.702,0,36.452,3.189,49.3,6.425l4.283,1.095l-0.238,59.675l-8.994,2.283  c-6.567-0.142-8.661-1.618-10.327-2.713l-1.998-1.856l-2.189-2.951c-1.332-1.807-6.519-9.611-15.466-15.94  c-8.994-6.567-22.509-11.708-38.26-11.566c-18.226-0.046-32.645,7.328-41.4,14.421l-2.094,1.714l-0.048-43.067  C449.22,63.727,468.968,59.634,489.859,59.681 M386.691,154.378v0.288l0.047,0.427v0.192v0.523l0.048,0.142v0.523v0.049l0.095,0.619  v0.046l0.095,0.572l0.095,0.619v0.046l0.095,0.572l0.476,1.76v0.046l0.143,0.526l0.047,0.093l0.095,0.38l0.096,0.192l0.095,0.334  l0.095,0.238l0.095,0.235l0.142,0.334l0.048,0.192l0.19,0.38v0.046c3.807,8.899,12.61,15.086,22.841,15.086h0.048h1.094  c17.369-0.238,31.169-6.997,39.783-13.657c8.899-6.805,13.134-13.372,14.229-15.086l-0.476-0.953l0.762,0.668  c1.808-2.048,5.472-6.044,12.801-5.998c6.567,0.142,8.09,1.475,9.707,2.524l4.806,5.757l-0.047,0.046  c2.712,4.807,21.462,27.505,53.773,26.698c17.892,0.046,32.121-6.805,40.877-13.657c8.946-6.805,13.181-13.372,14.323-15.086  c3.141-5.092,4.378-10.993,3.522-16.704l0.285-84.18c0.048-10.183-6.139-19.274-15.609-23.079  c-1.665-0.572-39.401-15.989-87.75-16.134h-13.8c-48.396,0.145-86.085,15.562-87.751,16.134  c-9.422,3.712-15.609,12.942-15.609,23.079l0.191,104.834V154.378z"
    />
  </svg>
);

BeerIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

BeerIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default BeerIcon;
