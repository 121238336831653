import { List, Map } from 'immutable';
import { selectServiceId } from '../browse';

export type SessionState = Map<string, number>;

interface SessionParams {
  serviceId?: string | number;
  sessionId?: string | number;
}

export const selectSession = (
  state: Map<string, any>,
  { serviceId, sessionId }: SessionParams = {}
): SessionState => {
  const sessions = state.get('sessions');

  if (sessionId) {
    return sessions.find(session => session.get('id') === sessionId) || Map();
  }

  return sessions.find(session => session.get('serviceId') === parseInt(serviceId!.toString(), 10)) || Map();
};

export const selectSessionId = (
  state: Map<string, any>,
  serviceId: string | number = selectServiceId(state)
): string | number | null => selectSession(state, { serviceId }).get('id') || null;

export const selectSessionLocationRef = (
  state: Map<string, any>,
  serviceId: string | number = selectServiceId(state)
): string | number | null => selectSession(state, { serviceId }).get('locationRef') || null;

export const selectTabId = (
  state: Map<string, any>,
  serviceId: string | number = selectServiceId(state)
): string | number | null => selectSession(state, { serviceId }).getIn(['tab', 'id']) || null;

export const selectTabPosSyncState = (
  state: Map<string, any>,
  serviceId: string | number = selectServiceId(state)
): string | null => selectSession(state, { serviceId }).getIn(['tab', 'pos_sync_state']) || null;

export const selectTabOrder = (
  state: Map<'sessions', List<SessionState>>,
  serviceId: string | number = selectServiceId(state)
): any | null => selectSession(state, { serviceId }).get('tab') || Map();
