import React, { ReactNode } from 'react';

import { Headline4 } from './styles';

type Props = {
  children?: ReactNode;
  className?: string;
};

const H4 = ({ children, className }: Props) => <Headline4 className={className}>{children}</Headline4>;

export default H4;
