import { fromJS } from 'immutable';

import { REQUEST_DONATIONS_SUCCESS } from 'actions/donations/constants';
import { donationOrderItemsSize } from 'selectors/order';
import { selectMenuType } from 'selectors/browse';
import { getServiceId } from 'selectors/root';
import { addDonationToOrder } from 'actions/order';
import donationTransformer from 'components/Donations/donationTransformer';

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const result = next(action);

    if (action.type === REQUEST_DONATIONS_SUCCESS) {
      const state = getState();
      const [donation] = action.donationsList.filter(donation => !!donation?.enabled);
      const addByDefault = donation?.added_by_default;

      if (donation && donationOrderItemsSize(state) === 0 && addByDefault) {
        const menuType = selectMenuType(state);
        const serviceId = getServiceId(state);
        const donationMap = fromJS(donation);

        dispatch(
          addDonationToOrder(donationTransformer(donationMap), menuType, serviceId, 1, donationMap.get('key'))
        );
      }
    }

    return result;
  };
