import React from 'react';
import PropTypes from 'prop-types';

const InstagramIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon InstagramIcon"
        fill={fill}
        d="M987.1,298c-2.4-52.2-10.7-87.8-22.8-118.9c-12.5-32.2-29.3-59.5-56.5-86.8c-27.2-27.2-54.6-44-86.8-56.5 C789.8,23.6,754.2,15.3,702,12.9c-52.3-2.4-68.9-2.9-202-2.9s-149.8,0.6-202,2.9c-52.2,2.4-87.8,10.7-118.9,22.8 C146.8,48.2,119.5,65,92.2,92.2c-27.2,27.2-44,54.6-56.5,86.8C23.6,210.2,15.3,245.8,12.9,298c-2.4,52.3-2.9,68.9-2.9,202  c0,133.1,0.6,149.8,2.9,202c2.4,52.2,10.7,87.8,22.8,118.9c12.5,32.2,29.3,59.5,56.5,86.8c27.2,27.2,54.6,44,86.8,56.5 c31.2,12.1,66.8,20.4,118.9,22.8c52.3,2.4,69,2.9,202,2.9s149.8-0.6,202-2.9c52.2-2.4,87.8-10.7,118.9-22.8  c32.2-12.5,59.5-29.3,86.8-56.5c27.2-27.2,44-54.6,56.5-86.8c12.1-31.2,20.4-66.8,22.8-118.9c2.4-52.3,2.9-68.9,2.9-202 S989.4,350.2,987.1,298z M931.1,699.5c-1.9,40.9-7.7,72.1-19,101.2c-10.5,27-23.6,47.2-43.9,67.5c-20.3,20.3-40.5,33.4-67.5,43.9 c-29.1,11.3-60.3,17.2-101.2,19c-51.2,2.3-68.3,2.9-199.5,2.9c-131.1,0-148.2-0.6-199.5-2.9c-40.9-1.9-72.1-7.7-101.2-19 c-27-10.5-47.2-23.6-67.5-43.9c-20.3-20.3-33.4-40.5-43.9-67.5c-11.3-29.1-17.2-60.3-19-101.2C66.6,648.2,66,631.1,66,500 c0-131.1,0.6-148.2,2.9-199.5c1.9-40.9,7.7-72.1,19-101.2c10.5-27,23.6-47.2,43.9-67.5c20.3-20.3,40.5-33.4,67.5-43.9 c29.1-11.3,60.3-17.2,101.2-19C351.8,66.6,368.9,66,500,66c131.1,0,148.2,0.6,199.5,2.9c40.9,1.9,72.1,7.7,101.2,19 c27,10.5,47.2,23.6,67.5,43.9c20.3,20.3,33.4,40.5,43.9,67.5c11.3,29.1,17.2,60.3,19,101.2c2.3,51.2,2.9,68.3,2.9,199.5 C934,631.1,933.4,648.2,931.1,699.5z"
      />
      <path
        className="icon InstagramIcon"
        fill={fill}
        d="M500,248.4C361,248.4,248.4,361,248.4,500S361,751.6,500,751.6S751.6,639,751.6,500S639,248.4,500,248.4z M500,695.6  c-107.9,0-195.6-87.8-195.6-195.6c0-107.9,87.8-195.6,195.6-195.6c107.9,0,195.6,87.8,195.6,195.6 C695.6,607.9,607.9,695.6,500,695.6z"
      />
      <circle className="icon InstagramIcon" fill={fill} cx="761.6" cy="238.4" r="58.8" />
    </g>
  </svg>
);

InstagramIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

InstagramIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default InstagramIcon;
