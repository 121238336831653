import { defineMessages } from 'react-intl';

const messages = defineMessages({
  processing: {
    defaultMessage: 'Processing...',
  },
  cantLoginForgot: {
    defaultMessage: "Can't login? Forgot your password?",
  },
  resetInstructions: {
    defaultMessage: 'Enter your email address below and we will send you password reset instructions.',
  },
  error: {
    defaultMessage: 'Error!',
  },
  forgotPasswordError: {
    defaultMessage: "We've been unable to reset your password. Please double check your email address?",
  },
  passwordRequestSuccess: {
    defaultMessage: 'Request Sent',
  },
  checkEmail: {
    defaultMessage:
      'Your reset request was successfully sent. Please check your inbox for "{ registeredEmail }" for password reset instructions.',
  },
  email: {
    defaultMessage: 'Email',
  },
  emailAddress: {
    defaultMessage: 'Email Address',
  },
  login: {
    defaultMessage: 'Login',
  },
  create_account: {
    defaultMessage: 'Create an Account',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  forgotPassword: {
    defaultMessage: 'Forgot password?',
  },
  title: {
    defaultMessage: 'Forgot Password',
  },
});

export default messages;
