import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormHeader from 'components/Form/FormHeader';
import CheckoutLoginRegister from 'components/CheckoutLoginRegister';
import CheckoutUserDetails from 'components/CheckoutUserDetails';
import Page from 'components/Pages/container';
import CheckoutIcon from 'components/Icons/CheckoutIcon';
import { clearPaymentError, clearViolations } from 'actions/payment';
import { getFulfilmentFormFields, getTabCheckoutFields } from 'reducers/fulfilmentReducer';
import { reactPixel, gtmDataLayerPush } from 'utils';

import globalMessages from 'components/globalMessages';
import { getGratuity, getGratuityPrice } from 'selectors/order';
import { PageContent } from './styles';
import { ButtonText, FormContainer, InnerContainer, SummaryContainer } from '../../CheckoutForm/styles';
import Form from '../../Form';
import FormattedPrice from '../../FormattedPrice';
import PaymentError from '../../PaymentError';
import { selectViolations } from '../../../selectors/payment';
import { getCheckoutErrorMessage } from '../../CheckoutForm/checkoutErrorsUtil';
import { selectSession, selectTabOrder } from '../../../selectors/session';
import TabOrderSummary from '../../DineIn/TabOrderSummary';
import { submitTabPayment } from '../../../actions/order/submitOrder';
import { selectDineInSessionStorage } from '../../../selectors/root';
import { fetchSession } from '../../../actions/session';
import { fetchTab } from '../../../actions/tab';
import useInterval from '../../../hooks/useInterval';
import { RefreshIconSpin } from '../../../assets/styles/iconStyles';
import { checkoutMessages } from '../../CheckoutForm';

const propTypes = {
  clearPaymentError: PropTypes.func,
  violations: PropTypes.instanceOf(Map),
  intl: PropTypes.object.isRequired,
  checkoutErrorMessage: PropTypes.func,
  order: PropTypes.instanceOf(Map),
  confirmOrder: PropTypes.func,
  clearViolations: PropTypes.func,
  storageSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchSession: PropTypes.func,
  fetchTab: PropTypes.func,
  session: PropTypes.instanceOf(Map),
};

const Checkout = ({
  clearPaymentError,
  violations,
  intl,
  checkoutErrorMessage,
  order,
  confirmOrder,
  clearViolations,
  storageSessionId,
  fetchSession,
  fetchTab,
  session,
}) => {
  const gratuity = useSelector(getGratuity);

  useEffect(() => {
    reactPixel.track('InitiateCheckout');
    gtmDataLayerPush('InitiateCheckout');
  }, []);

  useEffect(() => {
    if (session?.size === 0 && storageSessionId) {
      fetchSession();
    }
  }, [storageSessionId, fetchSession, session?.size]);

  useInterval(fetchTab, 2000);

  const formRef = useRef(null);

  useEffect(() => () => clearPaymentError(), [clearPaymentError]);

  const { errorHeader, errorMessage } = checkoutErrorMessage(intl.formatMessage);

  const onSubmit = (values, callback) => {
    confirmOrder({ ...values }, callback);
  };

  const onChange = (value, field) => {
    if (violations.get(field.errorKey || field.name)) {
      clearViolations(field.errorKey || field.name);
    }
  };

  return (
    <Page settingsPage={true} titleMessage={globalMessages.checkout} Icon={CheckoutIcon} container={false}>
      <PageContent>
        <>
          <CheckoutLoginRegister />
          <CheckoutUserDetails />
          <>
            <FormHeader
              title={<FormattedMessage {...checkoutMessages.yourDetails} />}
              requiredFields={true}
            />
            <FormContainer ref={formRef}>
              <Form
                fields={getTabCheckoutFields()}
                onChange={onChange}
                onSubmit={onSubmit}
                submitButtonIcon={order.get('total') == null && <RefreshIconSpin />}
                submitButtonLabel={
                  order.get('total') != null && (
                    <ButtonText>
                      <FormattedMessage
                        {...checkoutMessages.payNow}
                        values={{
                          price: (
                            <FormattedPrice
                              value={order.get('total') + getGratuityPrice(order.get('total'), gratuity)}
                            />
                          ),
                        }}
                      />
                    </ButtonText>
                  )
                }
                errors={violations.toJS()}
                errorHeader={errorHeader}
                errorMessage={errorMessage}
              >
                <PaymentError />
              </Form>
            </FormContainer>
            <SummaryContainer formHeight={formRef.current?.offsetHeight}>
              <InnerContainer>
                <TabOrderSummary order={order} />
              </InnerContainer>
            </SummaryContainer>
          </>
        </>
      </PageContent>
    </Page>
  );
};

Checkout.propTypes = propTypes;

const mapStateToProps = (state, { params }) => ({
  order: selectTabOrder(state),
  session: selectSession(state, { sessionId: params?.sessionId }),
  violations: selectViolations(state),
  fulfilmentFields: getFulfilmentFormFields(state),
  storageSessionId: selectDineInSessionStorage(state),
  checkoutErrorMessage: formatMessage => getCheckoutErrorMessage(state, formatMessage),
});

const mapDispatchToProps = dispatch => ({
  fetchSession: () => dispatch(fetchSession()),
  fetchTab: () => dispatch(fetchTab()),
  clearPaymentError: () => dispatch(clearPaymentError()),
  clearViolations: errorKey => dispatch(clearViolations(errorKey)),
  confirmOrder: (details, callback) => dispatch(submitTabPayment(details, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Checkout));
