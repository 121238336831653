import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { kioskModeEnabled } from 'selectors/features';

import { Container, Text, Logo } from './styles';

const messages = defineMessages({
  powered_by: {
    defaultMessage: 'Powered by wi-Q Technologies',
  },
});

type Props = { kioskMode?: boolean };

const PoweredFooter = ({ kioskMode }: Props) => {
  if (kioskMode) return null;
  return (
    <Container>
      <a href="http://www.wi-q.com/" target="_blank">
        <Logo />
        <Text>
          <FormattedMessage {...messages.powered_by} />
        </Text>
      </a>
    </Container>
  );
};

const mapStateToProps = state => ({
  kioskMode: kioskModeEnabled(state),
});

export default connect(mapStateToProps)(PoweredFooter);
