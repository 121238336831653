import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import { isDarkModeEnabled } from 'selectors/features';
import { ChevronForward, DeliveryIcon } from 'components/Icons';
import { selectVenueDeliveryPartnersByVenueId } from 'selectors/organisation';
import { selectVenueDeliveryPartners } from 'selectors/root';
import { DeliveryLink, DeliveryName } from 'components/Alert/styles';
import { getIcon } from 'utils';
import getDeliveryPartnerName from './getDeliveryPartnerName';

const propTypes = {
  venueDeliveryPartners: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
  darkMode: PropTypes.bool,
};

const DeliveryPartnersLinks = ({ venueDeliveryPartners, darkMode }) => {
  if (!venueDeliveryPartners || venueDeliveryPartners?.size === 0) return null;

  const venueDeliveryPartnersList = new List(venueDeliveryPartners);

  return (
    <div>
      {venueDeliveryPartnersList.map(([key, value]) => (
        <DeliveryLink href={value} target="_blank" key={key}>
          <span>
            {getIcon(key, { defaultIcon: <DeliveryIcon />, darkMode })}
            <DeliveryName>{getDeliveryPartnerName(key)}</DeliveryName>
          </span>
          <ChevronForward />
        </DeliveryLink>
      ))}
    </div>
  );
};

DeliveryPartnersLinks.propTypes = propTypes;

export default connect((state, { venueId }) => ({
  venueDeliveryPartners: venueId
    ? selectVenueDeliveryPartnersByVenueId(state, venueId)
    : selectVenueDeliveryPartners(state),
  darkMode: isDarkModeEnabled(state),
}))(DeliveryPartnersLinks);
