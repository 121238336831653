import { SET_DETAILS, REMOVE_DETAIL, CLEAR_ALL_DETAILS } from './constants';

export const setDetails = (data, expiry) => ({
  type: SET_DETAILS,
  data,
  expiry,
});

export const setDetail = (name, value, expiry) => ({
  type: SET_DETAILS,
  data: { [name]: value },
  expiry,
});

export const removeDetail = key => ({
  type: REMOVE_DETAIL,
  key,
});

export const clearDetails = () => ({
  type: CLEAR_ALL_DETAILS,
});
