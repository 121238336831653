import React, { ReactElement } from 'react';

import { Label, Toggle, StyledTickIcon } from './styles';

type Props = {
  className?: string;
  name?: string;
  id?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  label?: string | ReactElement;
};

const ToggleCheckbox = ({ name, id = name, onChange, checked, label, className }: Props) => (
  <Label htmlFor={name} className={className}>
    <input type="checkbox" name={name} id={id} onChange={e => onChange(e.target.checked)} checked={checked} />
    <Toggle checked={checked}>
      <StyledTickIcon checked={checked} />
    </Toggle>
    {label}
  </Label>
);

export default ToggleCheckbox;
