import React from 'react';
import PropTypes from 'prop-types';

// Icon
const JcbIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 780 501"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        opacity="0.6"
        d="M725,501H55c-30.3,0-55-24.7-55-55V55C0,24.7,24.7,0,55,0h670c30.3,0,55,24.7,55,55v391	C780,476.3,755.3,501,725,501z M55,30c-13.8,0-25,11.2-25,25v391c0,13.8,11.2,25,25,25h670c13.8,0,25-11.2,25-25V55	c0-13.8-11.2-25-25-25H55z"
      />
      <path
        className="icon"
        fill={fill}
        d="M161.3,288.2c-7.4-2.2-22.5-9.3-22.5-9.3l-0.2-165.6c0,0-0.8-18.5,19.2-37.6c20-19.1,41.8-17.9,41.8-17.9h94.2 l-0.2,318.3c0,0,1.4,23.7-15.8,43.1c-19.2,22.5-46.5,21.3-46.5,21.3h-92.5V306.3l34.4,6.2c0,0,13.3,1.4,26.4,1.6 c11.5,0.2,22.2-0.8,29.4-1.9c13.6-2.1,20.6-4,33.5-11.8c19.2-14.2,18.3-36.1,18.3-36.1l-0.5-76.6l-53.6,0.3v75.2 c0,0,0.5,14.1-10.5,22.9c-4.8,3.8-11.8,7.3-24.9,7.3C182.1,293.4,169.5,290.7,161.3,288.2L161.3,288.2z M313.9,207.6v-84.3 c0,0-1-24.7,17-43.6C348.7,60.8,372,60,372,60h97v319.8c0,0,1.2,23.5-17.7,43c-18.8,19.4-39.5,19.5-39.5,19.5h-97.9V295.5	c0,0,13,13.9,49.7,19.2c17.3,2.5,37.7,2.1,54.4,0c19-2.4,33.3-6.7,33.3-6.7l-0.4-26.6c0,0-6.8,4.2-27.6,10.7 c-20.8,6.4-72.8,9.4-74.2-38.6c-1.4-48,42.8-48.4,62.7-45.9c19.8,2.5,39.5,13.4,39.5,13.4v-26c0,0-25-5.8-35.7-7 c-13.2-1.5-31.7-3.1-60.2,1.2C327,193.5,313.9,207.6,313.9,207.6L313.9,207.6z M486.8,310.6v129.7h97.4c0,0,22.3-2.2,35.4-15.3 c21.2-16.5,22.2-43.1,22.2-43.1V57.8h-92.8c0,0-24.8-0.8-43.8,18.8c-19.1,19.6-18.3,42.5-18.3,42.5V188h94.4c0,0,8.7,0.1,17.2,3.6	c6.8,2.7,13.4,7.7,16.3,15.3c6.4,17.1-3.3,29.3-8.2,32.7c-6.5,5.8-18.2,7.5-18.2,7.5s16.9,1.7,26.4,10.6c3.2,2.9,6.8,7.7,8.2,12.5	c1.9,6.3,1.2,12.5,1.2,12.5s-1.2,10.9-10.1,18.2c-3.8,3.1-9,6-14.2,7.6c-7,2.1-13.8,2.3-13.8,2.3L486.8,310.6L486.8,310.6z M517.8,208.5v31.2h33.5c0,0,15.7,0,15.7-15.6s-15.7-15.6-15.7-15.6L517.8,208.5L517.8,208.5z M553.7,258h-35.8v33.5h35.8	c0.1,0,17.7,1,17.7-16.8S553.7,258,553.7,258L553.7,258z"
      />
    </g>
  </svg>
);

JcbIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

JcbIcon.defaultProps = {
  width: '38px',
  height: '24px',
  fill: 'currentColor',
  opacity: '1',
};

export default JcbIcon;
