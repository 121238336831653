import { fromJS } from 'immutable';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import userMiddleware from 'middleware/userMiddleware';
import orderMiddleware from 'middleware/orderMiddleware';
import localeMiddleware from 'middleware/localeMiddleware';
import storageMiddleware from 'middleware/storageMiddleware';
import paymentMiddleware from 'middleware/paymentMiddleware';
import broadcastChannelMiddleware from 'middleware/broadcastChannelMiddleware';
import donationsMiddleware from 'middleware/donationsMiddleware';
import analyticsMiddleware from 'middleware/analyticsMiddleware';

import reducer from './reducers';

export default function configureStore(initialState = {}) {
  const middlewares = [
    thunkMiddleware,
    orderMiddleware,
    userMiddleware,
    localeMiddleware,
    storageMiddleware,
    paymentMiddleware,
    broadcastChannelMiddleware,
    donationsMiddleware,
    analyticsMiddleware,
  ];

  /* eslint-disable no-underscore-dangle */
  const enableDevTools =
    typeof window === 'object' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' &&
    process.env.NODE_ENV !== 'production';

  const enhancers = [
    applyMiddleware(...middlewares),
    enableDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ];
  /* eslint-enable */

  const store = createStore(reducer, fromJS(initialState), compose(...enhancers));

  // Make reducers hot reloadable
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
