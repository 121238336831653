import PhoneNumberInput from 'components/PhoneNumberInput';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoginChoiceContainer } from './styles';

interface SignInMethodsProps {
  method: string;
  onChange: Function;
}

const SignInMethods: React.FC<SignInMethodsProps> = ({ method = 'mobile', onChange }) => (
  <div style={{ paddingBlockEnd: '1rem' }}>
    <LoginChoiceContainer>
      <div>
        <input
          type="radio"
          name="signInMethod"
          id="mobile"
          value="mobile"
          checked={method === 'mobile'}
          onChange={e => onChange(e.target.value)}
        />
        <label htmlFor="mobile">
          <FormattedMessage defaultMessage="Mobile number" />
        </label>
      </div>

      <div>
        <input
          type="radio"
          name="signInMethod"
          id="email"
          value="email"
          checked={method === 'email'}
          onChange={e => onChange(e.target.value)}
        />
        <label htmlFor="email">
          <FormattedMessage defaultMessage="Email Address" />
        </label>
      </div>
    </LoginChoiceContainer>

    {method === 'mobile' && (
      <PhoneNumberInput
        name="phoneNumber"
        value=""
        onChange={() => {}}
        isValid={true}
        setIsValid={() => {}}
      />
    )}

    {method === 'email' && (
      <>
        <input type="email" name="email" id="mobile" placeholder="jessica@smith.com" />
      </>
    )}
  </div>
);

export default SignInMethods;
