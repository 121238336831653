import 'isomorphic-fetch';

import { showPaymentError } from 'selectors/payment';
import { getVenueId } from 'selectors/root';
import fetchHelper from 'utils/fetchHelper';
import {
  CLEAR_VIOLATIONS,
  SET_VIOLATION,
  CLEAR_ERROR_MESSAGE,
  SET_PAYMENT_METHODS,
  CLEAR_PAYMENT_ERROR,
  SET_PAYMENT_ERROR,
} from './constants';

export const getBrowserInfo = () => {
  const newDate = new Date();

  return {
    colour_depth: String(window.screen.colorDepth) || '1',
    java_enabled: window.navigator.javaEnabled() || false,
    language: navigator.language || 'en-GB',
    screen_width: window.screen.width || 0,
    screen_height: window.screen.height || 0,
    timezone: newDate.getTimezoneOffset() || 0,
  };
};

export const getPaymentMethods = () => (dispatch, getState) => {
  const success = res => {
    dispatch({
      type: SET_PAYMENT_METHODS,
      paymentMethods: res,
    });
  };

  const state = getState();

  if (getVenueId(state)) {
    fetchHelper('/api/payment-methods', 'GET', null, success);
  }
};

export const setPaymentError = (error, message) => (dispatch, getState) => {
  const state = getState();
  if (showPaymentError(state) !== error) {
    dispatch({
      type: SET_PAYMENT_ERROR,
      error,
      message,
    });
  }
};

export const clearPaymentError = () => (dispatch, getState) => {
  const state = getState();
  if (showPaymentError(state)) {
    dispatch({
      type: CLEAR_PAYMENT_ERROR,
    });
  }
};

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR_MESSAGE,
});

export const clearViolations = errorKey => ({
  type: CLEAR_VIOLATIONS,
  errorKey,
});

export const setViolation = (errorKey, error) => ({
  type: SET_VIOLATION,
  error: { errorKey, error },
});
