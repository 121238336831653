import styled from 'styled-components';
import { H1 } from 'components/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
`;

export const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
  }
  gap: 10px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const AdditionalButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > figure {
    width: 60%;
    border-radius: 10px;
    overflow: hidden;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
`;

export const ProductQuantityWrapper = styled.div`
  padding: 20px 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
`;

export const ProductDescription = styled.p`
  text-align: left;
`;

export const StyledH1 = styled(H1)`
  display: inline-block;
  line-height: 1.2;
`;
