import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BackButton from 'components/BackButton';
import MenuButton from 'components/MenuButton';
import CartButton from 'components/CartButton';
import { orderReview } from 'actions/order';
import { openClearOrderAlert, openSelectLanguageAlert } from 'actions/UI';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import { kioskModeEnabled, showExitButton } from 'selectors/features';
import KioskExitButton from 'components/KioskExitButton';
import LanguageIcon from 'components/Icons/LanguageIcon';
import { trackEvent } from 'utils/tracking';
import image from 'utils/image';

import { getLocales } from 'selectors/root';
import { openLoyaltyModal } from 'actions/loyalty';
import { selectServiceId } from 'selectors/browse';
import { selectLoyaltyTypeByService } from 'selectors/loyalty';
import {
  HeaderFixed,
  ScrollableContainer,
  LogoContainer,
  StyledLink,
  LogoImg,
  BackButtonContainer,
  HeaderTitle,
  ButtonsContainer,
  HeaderButton,
  MultilineTruncate,
} from './styles';
import LoyaltyButton from '../LoyaltyButton';
import { addCssPrefixTo } from '../../utils';

if (typeof window !== 'undefined') {
  window.requestAnimFrame = (function reqAnimFrame() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      (callback => {
        window.setTimeout(callback, 1000 / 60);
      })
    );
  })();
}

class Header extends React.Component {
  static propTypes = {
    logoUrl: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideCartButton: PropTypes.bool,
    hideSidebar: PropTypes.bool,
    backButton: PropTypes.object,
    hideBackButton: PropTypes.bool,
    companyName: PropTypes.string,
    openOrder: PropTypes.func,
    icon: PropTypes.node,
    sidebarOpen: PropTypes.bool,
    pageTitleRef: PropTypes.object,
    showExitButton: PropTypes.bool,
    openSelectLanguageAlert: PropTypes.func,
    showLanguageButton: PropTypes.bool,
    openLoyaltyModal: PropTypes.func,
    loyaltyType: PropTypes.string,
  };

  componentDidMount() {
    this.headerScroll();
  }

  headerScroll = () => {
    const element = this.header;
    const headerHeight = element.offsetHeight;
    const scrollable = this.scrollable;
    let headerOffset = 0;
    let lastPosition = 0;
    const loop = () => {
      const scrollTop = window.pageYOffset;
      if (lastPosition === scrollTop) {
        window.requestAnimFrame(loop);
        return false;
      }

      const pageTitleOffset = this.props.pageTitleRef.current?.offsetTop;
      if (pageTitleOffset) {
        lastPosition = scrollTop;
        if (scrollTop > pageTitleOffset && scrollTop < pageTitleOffset + headerHeight) {
          const offset = pageTitleOffset - scrollTop;
          headerOffset = offset;
        } else if (scrollTop >= pageTitleOffset + headerHeight) {
          headerOffset = -headerHeight;
        } else {
          headerOffset = 0;
        }

        scrollable.style.transform = `translateY(${headerOffset}px)`;
      }

      window.requestAnimFrame(loop);
    };

    loop();
  };

  render = () => (
    <HeaderFixed
      sidebarOpen={this.props.sidebarOpen}
      ref={c => {
        this.header = c;
      }}
      className={`primaryBackground primaryColor ${addCssPrefixTo('FIXED_HEADER')}`}
    >
      {this.props.showExitButton && <KioskExitButton />}
      <ScrollableContainer
        ref={c => {
          this.scrollable = c;
        }}
        showExitButton={this.props.showExitButton}
      >
        <LogoContainer
          ref={c => {
            this.logo = c;
          }}
        >
          <StyledLink to={this.props.showExitButton ? '' : '/'}>
            <LogoImg
              src={this.props.logoUrl ? image(this.props.logoUrl, { height: 38 }) : '/logo.png'}
              alt={`${this.props.companyName} Logo`}
            />
          </StyledLink>
        </LogoContainer>
        {!this.props.hideBackButton && (
          <BackButtonContainer
            ref={c => {
              this.backBtn = c;
            }}
          >
            <BackButton link={this.props.backButton?.get('href')} text={this.props.backButton?.get('text')} />
          </BackButtonContainer>
        )}
        <HeaderTitle
          ref={c => {
            this.title = c;
          }}
        >
          {this.props.icon}
          <MultilineTruncate lines="2">{this.props.subTitle}</MultilineTruncate>
        </HeaderTitle>
      </ScrollableContainer>
      <ButtonsContainer>
        {!this.props.hideSidebar && <MenuButton customCssClass="menuBarColor" />}
        {!this.props.hideCartButton && (
          <HeaderButton onClick={this.props.openOrder}>
            <div
              onClick={() =>
                trackEvent('initiate_checkout', {
                  category: 'checkout',
                  location: 'header',
                })
              }
            >
              <CartButton className="primaryBackground" />
            </div>
          </HeaderButton>
        )}
        {this.props.showLanguageButton && (
          <HeaderButton onClick={this.props.openSelectLanguageAlert}>
            <LanguageIcon />
          </HeaderButton>
        )}
        {this.props.loyaltyType && (
          <HeaderButton onClick={this.props.openLoyaltyModal}>
            <LoyaltyButton className="primaryBackground" />
          </HeaderButton>
        )}
      </ButtonsContainer>
    </HeaderFixed>
  );
}

const mapStateToProps = state => {
  const serviceId = selectServiceId(state);

  return {
    logoUrl: state.getIn(['branding', 'app_logo_path']),
    companyName: state.getIn(['venue', 'name']),
    sidebarOpen: selectSidebarIsOpen(state),
    showExitButton: kioskModeEnabled(state) || showExitButton(state),
    showLanguageButton: kioskModeEnabled(state) && getLocales(state).size > 1,
    loyaltyType: selectLoyaltyTypeByService(state, serviceId),
  };
};

export default connect(mapStateToProps, {
  openOrder: orderReview,
  openLoyaltyModal,
  openClearOrderAlert,
  openSelectLanguageAlert,
})(Header);
