import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CartEmptyIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M23.906,7.349L20.32,17.896c-0.047,0.141-0.137,0.258-0.27,0.352s-0.277,0.141-0.434,0.141H9.492 c-0.109,0-0.211-0.023-0.305-0.07s-0.176-0.113-0.246-0.199s-0.121-0.176-0.152-0.27L4.641,2.615 C4.578,2.38,4.5,2.205,4.406,2.087S4.238,1.904,4.184,1.888S4.07,1.865,4.008,1.865h-3.21c-0.125,0-0.246-0.031-0.363-0.094 s-0.207-0.152-0.27-0.27S0.071,1.263,0.071,1.138c0-0.219,0.07-0.398,0.211-0.539s0.313-0.211,0.516-0.211h3.21 c0.156,0,0.313,0.02,0.469,0.059s0.34,0.113,0.551,0.223s0.41,0.297,0.598,0.563S5.961,1.818,6.07,2.193l1.219,4.148h15.914 c0.25,0,0.449,0.105,0.598,0.316S23.984,7.099,23.906,7.349z M22.125,7.841H7.688l2.391,9.047h9.023L22.125,7.841z M11.461,19.982 c0.188,0.063,0.359,0.152,0.516,0.27s0.293,0.254,0.41,0.41s0.207,0.328,0.27,0.516s0.094,0.383,0.094,0.586 c0,0.516-0.184,0.957-0.551,1.324s-0.809,0.551-1.324,0.551s-0.957-0.184-1.324-0.551S9,22.279,9,21.764 c0-0.344,0.082-0.656,0.246-0.938s0.391-0.508,0.68-0.68s0.605-0.258,0.949-0.258C11.078,19.889,11.273,19.92,11.461,19.982z M19.5,21.764c0,0.516-0.184,0.957-0.551,1.324s-0.809,0.551-1.324,0.551s-0.957-0.184-1.324-0.551s-0.551-0.809-0.551-1.324 s0.184-0.957,0.551-1.324s0.809-0.551,1.324-0.551s0.957,0.184,1.324,0.551S19.5,21.248,19.5,21.764z"
      />
    </g>
  </svg>
);

CartEmptyIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CartEmptyIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CartEmptyIcon;
