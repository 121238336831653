import { List } from 'immutable';

import { selectVenueFeedbackUrl } from 'selectors/root';

export const getFeedbackQuestions = state => state.getIn(['feedback', 'questions']) || new List();
export const questionsLoading = state => state.getIn(['feedback', 'questionsLoading']) || false;
export const answersLoading = state => state.getIn(['feedback', 'answersLoading']) || false;

export const showFeedbackButton = (state, serviceId) =>
  !!(selectVenueFeedbackUrl(state) || (serviceId && getFeedbackQuestions(state)?.size));
