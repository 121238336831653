import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { removeFavourite } from 'actions/user';
import { RemoveFavouriteThemedIcon } from 'components/Icons';

import { ButtonStyled } from './styles';

const RemoveFavouritesButton = ({ removeFavourite }) => (
  <ButtonStyled onClick={removeFavourite}>
    <RemoveFavouriteThemedIcon />
  </ButtonStyled>
);

RemoveFavouritesButton.propTypes = {
  removeFavourite: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  removeFavourite: () => dispatch(removeFavourite(productId)),
});

export default connect(undefined, mapDispatchToProps)(RemoveFavouritesButton);
