import styled, { css } from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

import FilterAltIcon from 'components/Icons/FilterAltIcon';

export const StyledSearch = styled.div`
  color: #666;

  @media (max-width: 805px) {
    margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '0' : '40px')};
    margin-right: ${({ theme }) => (theme.dir === 'rtl' ? '40px' : '0')};
  }
`;

export const StyledSearchBar = styled.div`
  flex-grow: 1;
`;

export const FilterBtn = styled.div`
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 21px;
  padding: 8px;
  margin-left: 5px;
  ${fontSizeMedium};

  span {
    padding: 0 3px;
  }

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }

  ${props =>
    props.active &&
    css`
      background: ${({ theme }) => theme.color.success};
      color: #fff;
    `}
`;

export const StyledFilterIcon = styled(FilterAltIcon)`
  padding: 0 3px;

  ${props =>
    props.active &&
    css`
      path {
        fill: #fff;
      }
    `}
`;
