import React from 'react';
import { browserHistory } from 'react-router';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import Page from 'components/Pages/container';
import { selectSessionLocationRef } from 'selectors/session';
import { Message, PageContent } from './styles';

const messages = defineMessages({
  noOrderAtLocation: {
    defaultMessage: 'No bill to pay for {locationType} {locationRef}',
  },
});

const NoOrderAtLocation = () => {
  const locationRef = useSelector(selectSessionLocationRef);
  // if no location ref, go home
  if (!locationRef) browserHistory.replace('/');

  return (
    <Page settingsPage={true} hideBackButton={true}>
      <PageContent>
        <Message>
          <FormattedMessage {...messages.noOrderAtLocation} values={{ locationType: 'table', locationRef }} />
        </Message>
      </PageContent>
    </Page>
  );
};

export default NoOrderAtLocation;
