import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ordersPage: {
    defaultMessage: 'Orders',
  },
  favouritesPage: {
    defaultMessage: 'Favourites',
  },
  login: {
    defaultMessage: 'Login',
  },
  logout: {
    defaultMessage: 'Logout',
  },
  termsPage: {
    defaultMessage: 'T & C',
  },
  contactPage: {
    defaultMessage: 'Contact',
  },
  language: {
    defaultMessage: 'Language',
  },
  callWaiter: {
    defaultMessage: 'Call Waiter',
  },
  allMenus: {
    defaultMessage: 'All Menus',
  },
});

export default messages;
