import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';

import { registerRequestStart, registerClearState } from 'actions/user';
import { registerRequestFailedValidationErrors } from 'selectors/user';
import { requireTelephone } from 'selectors/features';
import Form from 'components/Form';
import fieldTypes from 'components/Form/fieldTypes';
import { RegisterIcon } from 'components/Icons';
import { AlertTitle, AlertMessage } from 'assets/styles/sharedStyles';
import SystemIcon from 'components/SystemIcon';
import { reactPixel, gtmDataLayerPush } from 'utils';

import messages from './intl';

class RegisterForm extends Component {
  static defaultProps = {
    onSubmit: () => undefined,
    closeAlert: () => undefined,
    registerRequestStart: () => undefined,
    registerClearState: () => undefined,
    children: () => undefined,
  };

  static propTypes = {
    isTelephoneRequired: PropTypes.bool,
    onSubmit: PropTypes.func,
    closeAlert: PropTypes.func,
    registerRequestStart: PropTypes.func,
    registerClearState: PropTypes.func,
    validationErrors: PropTypes.instanceOf(List),
    children: PropTypes.func,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }),
  };

  componentWillUnmount() {
    this.props.registerClearState();
  }

  getFormFields = () => {
    const list = [
      { ...fieldTypes.name },
      { ...fieldTypes.email },
      { ...fieldTypes.newPassword },
      { ...fieldTypes.confirmPassword },
      { ...fieldTypes.terms },
    ];

    if (this.props.isTelephoneRequired) {
      list.splice(2, 0, { ...fieldTypes.telephone, required: true });
    }

    return list;
  };

  onSubmit = (values, callback) => {
    const { name, email, telephone, newPassword } = values;
    const { registerRequestStart, onSubmit, closeAlert } = this.props;

    registerRequestStart(name, email, telephone, newPassword, res => {
      if (res.success && onSubmit) onSubmit(res);
      if (res.success) closeAlert();
      callback(res);
      reactPixel.track('CompleteRegistration');
      gtmDataLayerPush('CompleteRegistration');
    });
  };

  render() {
    return (
      <>
        <SystemIcon Icon={RegisterIcon} />
        <AlertTitle>
          <FormattedMessage {...messages.create_account} />
        </AlertTitle>
        <AlertMessage>
          <FormattedMessage {...messages.registerWithWiQMsg} />
        </AlertMessage>
        <Form
          noButtonIcon={true}
          widthLimited={true}
          fields={this.getFormFields()}
          onSubmit={this.onSubmit}
          submitButtonLabel={<FormattedMessage {...messages.register} />}
          errorHeader={<FormattedMessage {...messages.registrationError} />}
          errorMessage={
            <div>
              <p>
                <FormattedMessage {...messages.badRegister} />
              </p>
              {this.props.validationErrors && (
                <ul>
                  {this.props.validationErrors.map((errorMsg, index) => (
                    <li key={index}>{errorMsg}</li>
                  ))}
                </ul>
              )}
            </div>
          }
          solidSubmitButton={false}
        >
          {this.props.children()}
        </Form>
      </>
    );
  }
}

export default connect(
  state => ({
    validationErrors: registerRequestFailedValidationErrors(state),
    isTelephoneRequired: requireTelephone(state),
  }),
  {
    registerRequestStart,
    registerClearState,
  }
)(injectIntl(RegisterForm));
