import React, { ReactNode } from 'react';

import i from 'utils/image';
import FulfilmentTypes from 'components/FulfilmentTypes';
import { PointerIcon } from 'components/Icons';

import {
  VenueContainer,
  VenueImageContainer,
  Details,
  VenueName,
  DistanceContainer,
  Distance,
  VenueAddress,
} from './styles';

type Props = {
  name?: string;
  image?: string;
  disabled?: boolean;
  address?: string;
  distance?: ReactNode;
  highlighted?: boolean;
  venueId?: number;
};

const Venue = ({ name, image, address, distance, disabled, highlighted, venueId }: Props) => (
  <VenueContainer disabled={disabled} className="u-flex" data-cy="venueListItem">
    {image && (
      <VenueImageContainer>
        <img alt="" src={i(image, { width: 80 })} />
      </VenueImageContainer>
    )}

    <Details className="u-flex u-flexCol u-flexJustifyCenter">
      <VenueName>
        {name}{' '}
        {(distance || highlighted) && (
          <DistanceContainer>
            <Distance highlighted={highlighted} className="u-flex u-flexAlignItemsCenter">
              <PointerIcon height="16" width="16" />
              {distance && distance}
            </Distance>
          </DistanceContainer>
        )}
      </VenueName>

      <VenueAddress>{address}</VenueAddress>

      <FulfilmentTypes venueId={venueId} disabled={disabled} />
    </Details>
  </VenueContainer>
);

export default Venue;
