import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon SearchIcon"
      fill={fill}
      d="M976.6,927.9l-46.2,46.6L632.8,680c0,0-31.1,24.2-47.9,34.7c-18.3,11.4-37.5,21-57.7,28.7
      c-20.2,7.7-41.3,13.8-63.4,18.3 c-22.1,4.5-44.5,6.8-67.3,6.9c-51.8,0.2-100.2-9.4-145.1-28.8c-45-19.4-84.2-45.8-117.9-79.1c-33.6-33.3-60.3-72.7-80.1-118.1
      c-19.8-45.4-29.8-93.4-30-143.9c-0.1-25.3,2-49.6,6.3-73c4.3-23.4,11.2-46.5,20.6-69.3c9.4-22.8,20.7-44.3,33.8-64.6
      c13.2-20.3,28.6-39.3,46.2-57.1c17.6-16.5,36.5-31.7,56.7-45.7c20.1-14,41.3-25.4,63.4-34.4c22.1-8.9,45.1-16,69.1-21.1
      c24-5.1,48.6-7.8,73.9-7.9c50.5-0.2,98.6,9.4,144.2,28.8c45.6,19.4,85.2,45.8,118.8,79.1c33.6,33.4,60.3,72.4,80.1,117.2
      c19.8,44.8,29.8,93.1,30,144.9c0.1,22.7-2,45.2-6.4,67.3c-4.4,22.1-10.2,43.3-17.7,63.6c-7.5,20.3-16.9,39.6-28.2,57.9
      c-10.6,17.2-35,49.1-35,49.1L976.6,927.9z M396.3,711.7c42.9-0.2,83.4-8.9,121.2-26.1c37.8-17.2,70.9-40.1,99.2-68.7
      c28.3-28.6,50.6-61.8,66.9-99.8c16.2-38,24.3-78.4,24.1-121.4c-0.2-42.9-8.6-83.7-25.2-122.2c-16.6-38.5-39.2-71.9-67.7-100.2
      s-61.8-50.6-99.8-66.9c-38-16.2-78.5-24.3-121.4-24.1c-21.5,0.1-42.3,2.4-62.5,6.9c-20.2,4.5-39.8,10.6-58.7,18.3
      s-36.9,17.2-53.9,28.7c-17,11.4-32.4,24.8-46.3,40c-15.1,15.2-28.3,31.4-39.6,48.5c-11.3,17.1-21,34.9-29.2,53.2
      c-8.2,18.4-14.1,37.7-17.8,57.9c-3.7,20.2-5.5,41.1-5.4,62.6c0.2,43,8.6,83.7,25.2,122.2c16.6,38.5,39.2,71.6,67.7,99.2
      c28.5,27.7,62.1,50,100.7,66.9C312.5,703.5,353.3,711.8,396.3,711.7z"
    />
  </svg>
);

SearchIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SearchIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default SearchIcon;
