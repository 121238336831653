import React from 'react';
import PropTypes from 'prop-types';

const DownloadIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon"
      fill={fill}
      d="M836.191,195.611L677.374,35.06c-17.041-17.227-40.14-26.988-64.167-26.988H226.69
        c-50.156,0.191-90.864,41.344-90.864,92.047v796.097c0,50.703,40.708,91.856,90.864,91.856h545.334
        c50.156,0,90.864-41.152,90.864-91.856V260.67C862.888,236.381,853.232,212.838,836.191,195.611L836.191,195.611z M620.591,70.451
        c5.301,1.34,10.035,4.02,14.011,8.039L793.42,239.041c3.976,4.02,6.627,8.805,7.952,14.164H620.591V70.451z M802.319,896.216
        c0,16.844-13.632,30.625-30.294,30.625H226.69c-16.662,0-30.294-13.781-30.294-30.625V100.119c0-16.844,13.632-30.797,30.294-30.797
        h333.312v199.196c0,25.457,20.259,45.804,45.441,45.804h196.875V896.216z M529.651,421.509c0-8.46-6.778-15.312-15.147-15.312
        H484.21c-8.369,0-15.147,6.852-15.147,15.312v168.476h-99.706c-20.827,0-38.985,12.269-47.335,32.003
        c-8.52,20.136-4.506,43.296,10.3,58.972L461.3,818.352c10.111,10.7,23.611,16.633,38.038,16.633s27.928-5.914,38.038-16.652
        l128.978-137.334c14.787-15.695,18.82-38.875,10.3-59.011c-8.35-19.734-26.507-32.003-47.335-32.003h-99.668V421.509z
        M610.764,651.235L499.357,769.907L387.95,651.235H610.764z"
    />
  </svg>
);

DownloadIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

DownloadIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default DownloadIcon;
