import styled from 'styled-components';
import { Link } from 'react-router';

export const AccountSettingsContainer = styled.div`
  margin-bottom: 20px;
`;

export const SettingsItem = styled.div`
  display: block;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
`;

export const SettingDetails = styled.div`
  padding: 10px;
`;

export const SettingTitle = styled.h3`
  color: ${({ theme }) => theme.color.primaryText};
  font-size: 1.2em;
  line-height: 1.4;
`;

export const SettingDescription = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-size: 1em;
  font-style: italic;
  line-height: 1;
`;

export const SettingLink = styled(Link)`
  color: ${({ theme }) => theme.color.primaryText};
  padding: 10px;
  height: 48px;
  width: 48px;
`;
