import styled from 'styled-components';

export const MenuShapeStyled = styled.i`
  height: 25px;
  width: 28px;
  position: relative;
`;

export const MenuBarElement = styled.span`
  border-radius: 1px;
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: background 0.2s, top 0.2s, left 0.2s, opacity 0.2s,
    transform ${({ reShape }) => (reShape ? '0.3s 0.2s' : '0.2s')};
  width: 28px;
  opacity: ${({ reShape }) => (reShape ? '0' : '1')};

  &:first-child {
    opacity: ${({ reShape }) => reShape && '1'};
    top: ${({ reShape }) => (reShape ? '10px' : '0')};
    transform: rotate(${({ reShape }) => (reShape ? '45deg' : '0deg')});
  }

  &:last-child {
    opacity: ${({ reShape }) => reShape && '1'};
    top: ${({ reShape }) => (reShape ? '10px' : '20px')};
    transform: rotate(${({ reShape }) => (reShape ? '-45deg' : '0deg')});
  }
`;
