import React from 'react';
import AccordionItem from 'components/AccordionItem';
import { AccordionProps } from './types';

const Accordion = ({ items }: AccordionProps) => (
  <div>
    {items.map(({ children, initialOpenState, title }, index) => (
      /* eslint-disable react/no-array-index-key */
      <AccordionItem key={index} initialOpenState={initialOpenState} title={title}>
        {children}
      </AccordionItem>
    ))}
  </div>
);

export default Accordion;
