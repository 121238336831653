import React, { ReactNode } from 'react';

import { H4 } from 'components/Typography';

import { StyledInfoBox } from './styles';

type Props = {
  title?: ReactNode;
  message?: ReactNode;
};

const InfoBox = ({ title, message }: Props) => (
  <StyledInfoBox>
    <H4>{title}</H4>
    <p>{message}</p>
  </StyledInfoBox>
);

export default InfoBox;
