import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { fetchQuestions } from 'actions/feedback';
import ActionButton from 'components/ActionButton';
import FeedbackButton from 'components/Feedback/FeedbackButton';
import globalMessages from 'components/globalMessages';

import { ButtonsContainer } from './styles';

const messages = defineMessages({
  returnToMenu: {
    defaultMessage: 'Return to Menu',
  },
  noThanks: {
    defaultMessage: 'No Thanks',
  },
  createAnAccount: {
    defaultMessage: 'Create An Account',
  },
  leaveFeedback: {
    defaultMessage: 'Leave Feedback',
  },
});

const ThankYouButtons = ({ isLoggedIn, kioskMode, serviceId, fetchQuestions, onReturnClick }) => {
  useEffect(() => {
    if (serviceId) {
      fetchQuestions(serviceId);
    }
  }, [fetchQuestions, serviceId]);

  return (
    <ButtonsContainer>
      {!isLoggedIn && !kioskMode && (
        <ActionButton
          primaryButton={true}
          to="/#register"
          label={<FormattedMessage {...messages.createAnAccount} />}
        />
      )}

      <FeedbackButton serviceId={serviceId} trackingLabel="Thankyou page" />

      <ActionButton
        onClick={kioskMode ? onReturnClick : undefined}
        to="/"
        forceReload={kioskMode}
        primaryButton={true}
        label={
          isLoggedIn || kioskMode ? (
            <FormattedMessage {...globalMessages.returnToMenu} />
          ) : (
            <FormattedMessage {...messages.noThanks} />
          )
        }
      />
    </ButtonsContainer>
  );
};

ThankYouButtons.propTypes = {
  isLoggedIn: PropTypes.bool,
  kioskMode: PropTypes.bool,
  returnClass: PropTypes.string,
  buttonClass: PropTypes.string,
  serviceId: PropTypes.string,
  fetchQuestions: PropTypes.func,
  onReturnClick: PropTypes.func,
};

ThankYouButtons.defaultProps = {
  isLoggedIn: false,
  kioskMode: false,
};

export default connect(undefined, { fetchQuestions })(ThankYouButtons);
