import React from 'react';
import PropTypes from 'prop-types';

// Icon
const GoogleIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon GoogleIcon"
        fill={fill}
        d="M526.11,569.485l-45.958-35.693c-13.995-11.607-33.138-26.931-33.138-54.974c0-28.169,19.143-46.074,35.758-62.648	c53.526-42.139,107.023-86.986,107.023-181.496c0-97.183-61.148-148.303-90.453-172.561h79.016L661.298,10H410.022 C341.071,10,241.71,26.315,168.96,86.37C114.131,133.672,87.389,198.9,87.389,257.613c0,99.673,76.528,200.708,211.671,200.708 c12.766,0,26.727-1.25,40.777-2.557c-6.309,15.357-12.693,28.147-12.693,49.846c0,39.581,20.345,63.854,38.27,86.848 c-57.419,3.957-164.619,10.312-243.643,58.886C46.511,696.1,23.604,761.237,23.604,807.218C23.604,901.853,112.809,990,297.77,990	c219.34,0,335.449-121.36,335.449-241.507C633.243,660.213,582.245,616.765,526.11,569.485z M359.049,422.513	c-109.723,0-159.43-141.846-159.43-227.435c0-33.329,6.311-67.727,28.011-94.601c20.456-25.588,56.09-42.197,89.352-42.197	c105.779,0,160.64,143.119,160.64,235.164c0,23.031-2.535,63.842-31.864,93.339C425.238,407.257,390.919,422.513,359.049,422.513z	 M360.305,936.446c-136.444,0-224.431-65.274-224.431-156.034c0-90.724,81.581-121.417,109.655-131.579	c53.531-18.019,122.426-20.531,133.919-20.531c12.75,0,19.127,0,29.285,1.287c96.996,69.024,139.102,103.435,139.102,168.785 C547.835,877.507,482.771,936.446,360.305,936.446z"
      />
      <polygon
        className="icon GoogleIcon"
        fill={fill}
        points="848.183,420.565 848.183,292.779 785.061,292.779 785.061,420.565 657.491,420.565 657.491,484.372 785.061,484.372 785.061,612.946 848.183,612.946 848.183,484.372 976.396,484.372 976.396,420.565 "
      />
    </g>
  </svg>
);

GoogleIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

GoogleIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default GoogleIcon;
