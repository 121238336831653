import React, { useState } from 'react';
import { MinusIcon } from 'components/Icons';
import { Header, PlusMinusButton } from './styles';
import type { AccordionItemProps } from './types';

const AccordionItem = ({ children, title, initialOpenState }: AccordionItemProps) => {
  const [open, setOpen] = useState<boolean>(initialOpenState ?? false);

  return (
    <div>
      <Header onClick={() => setOpen(!open)}>
        {typeof title === 'string' ? <p>{title}</p> : title}
        <PlusMinusButton icon={open ? 'minus' : 'plus'}>
          <MinusIcon />
          <MinusIcon />
        </PlusMinusButton>
      </Header>
      {open && children}
    </div>
  );
};

export default AccordionItem;
