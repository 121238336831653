import React from 'react';
import PropTypes from 'prop-types';

// Icon
const MilkIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon milkIcon"
        fill={fill}
        opacity={opacity}
        d="M105.9,990V357.6l140.1-187V10H754v160.7l140.1,187V990H105.9z M614,374v566.8h230.9V374L729.4,219.9L614,374z M155.1,940.8	h409.6V390.4H155.1V940.8z M179.7,341.2H577l103.2-137.7H282.9L179.7,341.2z M295.2,154.2h409.6v-95H295.2V154.2z"
      />
    </g>
  </svg>
);

MilkIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MilkIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default MilkIcon;
