import { fromJS, List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Choosey from 'components/Choosey';
import ListOption from 'components/ListOption';
import OptionLabel from 'components/OptionLabel';
import { getModifierPrice } from 'selectors/browse';
import ExtendedOption from './ExtendedOption';

import { ModifierContainer, ModifierOptions, Error } from './styles';

const OptionModifier = ({
  values,
  options,
  name,
  required,
  multiple,
  minimum,
  maximum,
  displayType,
  updateValues,
  hasError,
  taxCategories,
  getModifierPrice,
  showModifierInfo,
  browseOnly,
  accordionOption,
  errors,
}) => {
  const modifierValuesFromIndex = indexes => {
    const values = indexes.map(index => options[index]);
    updateValues(values);
  };
  const selectedValueIndexes = values.map(value => options.findIndex(option => option.id === value.id));
  const optionsHaveImgs = options.some(o => o?.product?.images.length > 0);
  const extendedDisplayType = displayType === 'extended';
  if (!required && minimum) required = true; // overwrite required if minimum specified

  const allowMultiple = options.some(o => o?.maximum > 1);

  return (
    <ModifierContainer>
      {!accordionOption && name && (
        <OptionLabel
          browseOnly={browseOnly}
          required={required}
          hasError={hasError}
          name={name}
          minimum={minimum}
          maximum={maximum}
        />
      )}
      <ModifierOptions extended={extendedDisplayType}>
        <Choosey
          values={selectedValueIndexes}
          onChange={modifierValuesFromIndex}
          choices={[...options.keys()]}
          required={required}
          maxChoices={multiple ? maximum || undefined : 1}
          renderProps={(onCheck, checked, index, id) => {
            const choice = options[index];
            const item = fromJS(choice);
            const product = fromJS(choice.product);
            const price = getModifierPrice(choice, taxCategories, displayType);
            const showInfo = product ? () => showModifierInfo(item) : null;
            const imagePath =
              optionsHaveImgs && product?.getIn(['images', 0, 'path'])
                ? product.getIn(['images', 0, 'path'])
                : null;

            return (
              <React.Fragment key={id}>
                {extendedDisplayType ? (
                  <>
                    <ExtendedOption
                      name={choice.value}
                      product={product}
                      showImage={optionsHaveImgs}
                      imagePath={imagePath}
                      price={price || price === 0 ? price : choice.product && choice.product.price}
                      checked={!!checked}
                      onChange={onCheck}
                      required={required}
                      onInfo={showInfo}
                      browseOnly={browseOnly}
                    />
                  </>
                ) : (
                  <ListOption
                    key={id}
                    onCheck={onCheck}
                    checked={checked}
                    choice={choice}
                    onInfo={showInfo}
                    price={price}
                    plusPrice={displayType === 'base' ? false : price >= 0}
                    browseOnly={browseOnly}
                    maxQuantity={choice.maximum}
                    allowMultiple={allowMultiple}
                  />
                )}
              </React.Fragment>
            );
          }}
        />
      </ModifierOptions>
      {accordionOption && errors?.length > 0 && <Error>{errors[0]}</Error>}
    </ModifierContainer>
  );
};

OptionModifier.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  hasError: PropTypes.bool,
  name: PropTypes.string,
  displayType: PropTypes.string,
  updateValues: PropTypes.func,
  taxCategories: PropTypes.instanceOf(List),
  getModifierPrice: PropTypes.func,
  showModifierInfo: PropTypes.func,
  browseOnly: PropTypes.bool,
  accordionOption: PropTypes.bool,
  errors: PropTypes.array,
};

OptionModifier.defaultProps = {
  accordionOption: false,
};

const mapStateToProps = state => ({
  getModifierPrice: (modifier, taxCategories, displayType) =>
    getModifierPrice(state, modifier, taxCategories, displayType),
});

const ConnectedOptionModifier = connect(mapStateToProps)(OptionModifier);

export default ConnectedOptionModifier;
