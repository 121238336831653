import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { Requirement, StyledTickIcon } from './styles';

const PasswordRequirement = ({ messageKey, success }) => (
  <Requirement success={success}>
    <FormattedMessage {...messages[messageKey]} />
    {success && <StyledTickIcon width="16px" height="16px" />}
  </Requirement>
);

PasswordRequirement.propTypes = {
  messageKey: PropTypes.string,
  success: PropTypes.bool,
};

export default PasswordRequirement;
