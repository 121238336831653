import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;

  & button {
    flex: 1;
  }

  ${props =>
    props.smallBtns &&
    css`
      flex-direction: column;
      position: absolute;
      left: -90px;
      width: 90px;
      top: 65px;

      html[dir='rtl'] & {
        left: auto;
        right: -62px;
      }
    `}
`;
