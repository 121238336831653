import styled, { css } from 'styled-components';

import { AlertButtons, AlertContent } from 'assets/styles/sharedStyles';

export const InfoContainer = styled.div`
  ${props =>
    !props.pageView &&
    css`
      display: flex;
      flex-direction: column;
      overflow: hidden;
    `}

  ${props =>
    props.pageView &&
    css`
      margin-left: 80px;
      border-left: 1px solid ${({ theme }) => theme.color.borderColor};
      position: relative;
      top: -20px;
      overflow: initial;

      html[dir='rtl'] & {
        margin-left: 0;
        margin-right: 54px;
        border-left: none;
        border-right: 1px solid ${({ theme }) => theme.color.borderColor};
      }
    `}
`;

export const StyledContent = styled(AlertContent)`
  ${props =>
    props.pageView &&
    css`
      overflow: initial;
      padding-top: 1rem;
    `}
`;

export const FlexBox = styled.div`
  @media (max-width: 767px) {
    padding: 5px 0;
  }

  @media (min-width: 768px) {
    padding-right: 10px;
    width: 50%;
    margin: ${({ allowItemNotes }) => !allowItemNotes && '0 auto'};
  }
`;

export const NotesFlexBox = styled(FlexBox)`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  padding: 10px 10px 0;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 10px 20px 0;
  }
`;

export const AddNotesBtn = styled.button`
  color: #999;

  @media (min-width: 768px) {
    float: left;
    padding: 3px;
    margin: 0;

    html[dir='rtl'] & {
      float: right;
    }
  }
`;

export const StyledIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.systemIcon};
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 0 5px;
`;

export const NotesTxt = styled.span`
  line-height: 38px;

  @media (min-width: 768px) {
    float: none;
  }
`;

export const StyledActionButtons = styled(AlertButtons)`
  @media (min-width: 767px) {
    margin: 0;
  }
  flex-direction: ${({ kiosk }) => kiosk && 'column-reverse'};

  ${props =>
    !props.pageView &&
    css`
      padding: 5px 10px;
      margin: 0;
    `}
  ${props =>
    props.pageView &&
    css`
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      padding: 10px;
      margin: 0;
      z-index: ${({ theme }) => theme.zIndex.five};
      background: ${({ theme }) => theme.color.primaryBackground};
    `};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column-reverse;
  padding: 10px;
`;
