import React, { useEffect, useRef, useState } from 'react';

import Input from 'components/Input';

type Props = {
  label: React.ReactNode;
  venueDecimals: number;
  value: number;
  onChange: (n: number) => void;
};

// This function handles displaying prices such as "1234" as 12.34,
// or "123400" as 1234 if the currency is 0 decimal place.
const convertForDisplay = (value, decimals) => (value / 10 ** 2).toFixed(decimals);

const FieldCurrency = ({ venueDecimals, onChange, value, label }: Props) => {
  const [displayedValue, setDisplayedValue] = useState(convertForDisplay(value, venueDecimals));

  const inputElement = useRef();

  const onInputChange = changeValue => {
    // We don't let the user go beyond the venue currency's max decimals.
    // i.e. Trying to enter 1.2 on a 0dp currency will not work.
    const regEx = venueDecimals === 0 ? /^\d*$/ : new RegExp(`^\\d*\\.?\\d{0,${venueDecimals}}$`);

    if (changeValue === '' || regEx.test(changeValue)) {
      setDisplayedValue(changeValue);
      onChange(parseFloat(changeValue) * 10 ** 2);
    }
  };

  // Then onBlur we can also convert for display, for example if a user typed
  // "1.2" we probably want to convert to 1.20.
  const onBlur = () => {
    setDisplayedValue(convertForDisplay(value, venueDecimals));
  };

  // Lastly we need to handle when the value is updated externally (i.e. from
  // a data fetch) but not while the user is typing, so we only do it if the
  // input element is not focused.
  useEffect(() => {
    if (document.activeElement !== inputElement.current) {
      setDisplayedValue(convertForDisplay(value, venueDecimals));
    }
  }, [value, venueDecimals]);

  return (
    <Input
      type="number"
      ref={inputElement}
      onBlur={onBlur}
      value={displayedValue}
      onChange={onInputChange}
      label={label}
    />
  );
};

export default FieldCurrency;
