import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  message: PropTypes.node.isRequired,
};

const defaultProps = {
  onClick: () => undefined,
};

const SectionLink = ({ onClick, message }) => (
  <button type="button" onClick={onClick} className="linkUnderlined">
    {message}
  </button>
);

SectionLink.propTypes = propTypes;
SectionLink.defaultProps = defaultProps;

export default SectionLink;
