import styled, { css } from 'styled-components';
import type { LanguageSelectButtonProps } from './types';

const buttonStyles = css`
  border: 1px solid ${({ theme }) => theme.buttons.defaultBorder};
  border-radius: 10px;
  padding: 1rem;
  width: 45%;
`;

export const LanguageSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

export const LanguageSelectMessage = styled.p`
  color: ${({ theme }) => theme.buttons.defaultBg};
  padding-block-end: 2rem;
`;

export const LanguageSelectButton = styled.button<LanguageSelectButtonProps>`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.color.white};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  transition: opacity 200ms ease-out;
`;

export const BackButton = styled.button`
  ${buttonStyles}
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.white};
  padding: 1rem;
  width: 45%;
`;
