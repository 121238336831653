import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLabel from 'components/Form/Fields/Label';
import Choosey from 'components/Choosey';
import { selectCollectionPoints } from 'selectors/order';
import { onChangeSetIdFromIndex } from 'components/Form/utils';

const propTypes = {
  collectionPoints: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  intlIdentifier: PropTypes.string,
  intlLabel: PropTypes.string,
  required: PropTypes.bool,
};

const CollectionPoints = ({ collectionPoints, value, onChange, intlIdentifier, intlLabel, required }) => {
  if (collectionPoints?.length <= 1) return null;

  const selectedValueIndex = collectionPoints.findIndex(opt => opt.id === value);

  return (
    <div>
      <FieldLabel intlIdentifier={intlIdentifier} intlLabel={intlLabel} required={required} />

      <Choosey
        maxChoices={1}
        id={intlIdentifier}
        values={[selectedValueIndex]}
        choices={collectionPoints.map(option => option.name)}
        onChange={onChangeSetIdFromIndex(collectionPoints, onChange)}
      />
    </div>
  );
};

CollectionPoints.propTypes = propTypes;

const mapStateToProps = state => ({
  collectionPoints: selectCollectionPoints(state)?.toJS() || [],
});

export default connect(mapStateToProps)(CollectionPoints);
