import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { OrderInfoRow, InfoLabel } from '../styles';

const InfoRow = ({ message, data }) => (
  <OrderInfoRow>
    <InfoLabel>
      <FormattedMessage {...message} />
    </InfoLabel>

    <div>{data}</div>
  </OrderInfoRow>
);

InfoRow.propTypes = {
  message: PropTypes.object.isRequired,
  data: PropTypes.node,
};

export default InfoRow;
