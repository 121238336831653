import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { showPaymentError, paymentErrorMessage } from 'selectors/payment';
import MessageBlock from 'components/MessageBlock';

import paymentMessages from 'components/Payment/intl';

const PaymentError = ({ showError, errorMessage }) => {
  if (!showError) return null;
  return (
    <MessageBlock
      header={<FormattedMessage {...paymentMessages.paymentError} />}
      body={errorMessage || <FormattedMessage {...paymentMessages.paymentErrorMessage} />}
      type="error"
    />
  );
};

PaymentError.propTypes = {
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default connect(state => ({
  showError: showPaymentError(state),
  errorMessage: paymentErrorMessage(state),
}))(PaymentError);
