import React, { useState } from 'react';
import styled from 'styled-components';
import OfferListItem from 'components/Loyalty/Offer';
import { Offer } from 'hooks/useLoyalty';
import { defineMessages, FormattedMessage } from 'react-intl';

interface OfferListProps {
  offers: Offer[];
  onRedeem: Function;
}

const StyledOfferList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
`;

const TabButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  gap: 10px;
`;

const TabButton = styled.button`
  flex: 1;
  text-align: center;
  height: 100%;
  background: #eee;

  &.active {
    border-bottom: 3px solid red;
  }
`;

const messages = defineMessages({
  offersTab: {
    defaultMessage: 'View Offers',
  },
  exchangesTab: {
    defaultMessage: 'Spend Points',
  },
  noOffers: {
    defaultMessage: 'No rewards available.',
  },
});

const OfferList: React.FC<OfferListProps> = ({ offers, onRedeem }) => {
  const [activeTab, setActiveTab] = useState('offers');

  const offerItems = offers.filter(item => item.type === 'offer');
  const exchangeItems = offers.filter(item => item.type === 'exchange');

  return (
    <>
      <TabButtonContainer>
        <TabButton className={activeTab === 'offers' ? 'active' : ''} onClick={() => setActiveTab('offers')}>
          <FormattedMessage {...messages.offersTab} />
        </TabButton>
        <TabButton
          className={activeTab === 'exchanges' ? 'active' : ''}
          onClick={() => setActiveTab('exchanges')}
        >
          <FormattedMessage {...messages.exchangesTab} />
        </TabButton>
      </TabButtonContainer>

      {activeTab === 'offers' && (
        <StyledOfferList>
          {offerItems.length === 0 && <FormattedMessage {...messages.noOffers} />}
          {offerItems.map(offer => (
            <OfferListItem key={offer.id} offer={offer} onRedeem={onRedeem} />
          ))}
        </StyledOfferList>
      )}

      {activeTab === 'exchanges' && (
        <StyledOfferList>
          {exchangeItems.length === 0 && <FormattedMessage {...messages.noOffers} />}
          {exchangeItems.map(offer => (
            <OfferListItem key={offer.id} offer={offer} onRedeem={onRedeem} />
          ))}
        </StyledOfferList>
      )}
    </>
  );
};

export default OfferList;
