import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import PaymentMethodChoice from 'components/PaymentSelection/PaymentChoice/PaymentMethodChoice';
import PaymentGroup from 'components/PaymentSelection/PaymentGroup';
import tabMessages from 'components/tabMessages';

const StyledPayments = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const messages = defineMessages({
  cardPayment: {
    defaultMessage: 'Debit/Credit Card',
  },
  withPaypal: {
    defaultMessage: '{label} or PayPal',
  },
  cashPayment: {
    defaultMessage: 'Cash',
  },
});

const propTypes = {
  paymentMethods: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const PaymentSelection = ({ paymentMethods, value, onChange, disabled }) => {
  const groupCount = paymentMethods.reduce((groups, payment) => {
    const groupId = payment.group.id;
    groups[groupId] = groups[groupId] ? groups[groupId] + 1 : 1;
    return groups;
  }, {});

  const groupedPaymentMethodsObj = paymentMethods
    .filter(p => groupCount[p.group.id] > 1)
    .reduce((g, p) => {
      const groupId = p.group.id;

      if (!g[groupId]) g[groupId] = [];
      g[groupId].push(p);

      return g;
    }, {});
  const groupedPaymentMethods = Object.values(groupedPaymentMethodsObj);

  const singlePaymentMethods = paymentMethods.filter(p => groupCount[p.group.id] === 1);

  const getLabel = paymentMethod => {
    let label = paymentMethod.group.name;

    /*  eslint-disable default-case */
    switch (paymentMethod.group.id) {
      case 1:
        label = <FormattedMessage {...messages.cardPayment} />;
        break;
      case 3:
        label = <FormattedMessage {...messages.cashPayment} />;
        break;
      case 4:
        label = <FormattedMessage {...tabMessages.createTab} />;
        break;
    }
    /*  eslint-enable default-case */

    if (groupCount[paymentMethod.group.id] > 1 || !label) {
      label = paymentMethod.name;
    }

    if (paymentMethod.paypal) {
      return <FormattedMessage {...messages.withPaypal} values={{ label }} />;
    }
    return label;
  };

  return (
    <StyledPayments>
      {groupedPaymentMethods.map(group => (
        <PaymentGroup
          choices={group}
          groupName={group[0].group.name}
          groupImage={group[0].group.image}
          onChange={onChange}
          key={group[0].group.id}
          value={value}
          disabled={disabled}
        />
      ))}

      {singlePaymentMethods.map(method => (
        <PaymentMethodChoice
          key={method.key}
          id={`${method.key}-paymentMethod`}
          label={getLabel(method)}
          image={method.image}
          onChange={() => onChange(method.key)}
          checked={value === method.key}
          disabled={disabled}
        />
      ))}
    </StyledPayments>
  );
};

PaymentSelection.propTypes = propTypes;

export default PaymentSelection;
