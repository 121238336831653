import React, { useCallback, useEffect, useRef } from 'react';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';

import { showMenuSectionCarousel } from 'selectors/features';

import { CarouselContainer, CarouselButton } from './styles';
import { trackEvent } from '../../utils/tracking';

type Props = {
  showMenuSectionCarousel: boolean;
  sectionList?: List<Map<string, any>>;
  menuList: List<Map<string, any>>;
  serviceId: string;
  menuId: string;
  sectionId?: string;
};

const MenuSectionCarousel = ({
  showMenuSectionCarousel,
  sectionList,
  menuList,
  serviceId,
  menuId,
  sectionId,
}: Props) => {
  const ref = useRef<HTMLElement>();
  const setRef = useCallback(
    el => {
      ref.current = el;
    },
    [ref]
  );

  useEffect(() => {
    if (ref?.current?.scrollIntoView) {
      ref.current.scrollIntoView();
    }
  }, [menuId, sectionId]);

  if (!showMenuSectionCarousel || !menuList?.size || !menuId) return null;

  // The "showSections" variable is used to decide if we are showing a carousel of menus or sections.
  // It is used to select the list to be mapped over, and modify the URL of each carousel item accordingly.
  const showSections = sectionId && sectionList.size > 1;
  const carouselList = showSections ? sectionList : menuList;

  const handleClick = (item, index) => {
    trackEvent(`select_carousel_${showSections ? 'section' : 'menu'}`, {
      category: 'menu',
      section_name: item.get('name'),
      position: index + 1,
    });
  };

  return (
    <CarouselContainer>
      {carouselList?.map((item, index) => {
        const itemId = item.get('id');
        const menuLink = showSections ? menuId : itemId;
        const url = `/service/${serviceId}/menu/${menuLink}${showSections ? `/section/${itemId}` : ''}`;

        const selected = itemId === Number(showSections ? sectionId : menuId);

        return (
          <CarouselButton
            ref={selected ? setRef : null}
            key={itemId}
            label={item.get('name')}
            to={url}
            onClick={() => handleClick(item, index)}
            disabled={selected}
          />
        );
      })}
    </CarouselContainer>
  );
};

export default connect(state => ({
  showMenuSectionCarousel: showMenuSectionCarousel(state),
}))(MenuSectionCarousel);
