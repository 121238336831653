import React, { ReactNode } from 'react';

import ActionButton from 'components/ActionButton';

type Props = {
  primaryButton?: boolean;
  dangerButton?: boolean;
  disabled?: boolean;
  buttonIcon?: object;
  btnLabel: ReactNode;
  shareTitle?: string;
  shareText: string;
  shareURL: string;
};

const ShareButton = ({
  primaryButton = false,
  dangerButton = false,
  buttonIcon = null,
  disabled = false,
  btnLabel,
  shareTitle,
  shareText,
  shareURL,
}: Props) => {
  const shareData = {
    title: shareTitle,
    text: shareText,
    url: shareURL,
  };

  if (typeof window === 'undefined' || !navigator?.canShare || !navigator.canShare(shareData)) return null;

  return (
    <ActionButton
      primaryButton={primaryButton}
      dangerButton={dangerButton}
      disabled={disabled}
      buttonIcon={buttonIcon}
      label={btnLabel}
      type="button"
      onClick={() => navigator.share(shareData)}
    />
  );
};

export default ShareButton;
