import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { browserHistory } from 'react-router';

import { removeDetail } from 'actions/storage';

import { TAddress } from 'types';
import Form from 'components/Form';
import FormHeader from 'components/Form/FormHeader';
import fieldTypes from 'components/Form/fieldTypes';
import settingsMessages from 'components/settingsMessages';
import { addAddress, editAddress } from 'actions/user';

import { Wrapper } from './styles';

const messages = defineMessages({
  editAddressTitle: {
    defaultMessage: 'Edit Address',
  },
  addAddressError: {
    defaultMessage: 'Could not add address, please try again later.',
  },
  editAddressError: {
    defaultMessage: 'Could not update address, please try again later.',
  },
});

const fields = [
  fieldTypes.addressLabel,
  {
    ...fieldTypes.address1,
    storeDetail: false,
  },
  {
    ...fieldTypes.address2,
    storeDetail: false,
  },
  {
    ...fieldTypes.townCity,
    storeDetail: false,
  },
  {
    ...fieldTypes.county,
    storeDetail: false,
  },
  {
    ...fieldTypes.postcode,
    storeDetail: false,
  },
  {
    ...fieldTypes.addressPin,
    storeDetail: false,
  },
  {
    ...fieldTypes.addressNotes,
    storeDetail: false,
  },
];

type Props = {
  address?: TAddress;
  addAddress?: (values: {}, afterSubmit: (res: {}) => void) => void;
  editAddress?: (values: {}, addressId: string, afterSubmit: (res: {}) => void) => void;
  closeAlert?: () => void;
  isModal?: boolean;
  removeDetail?: (key: string) => void;
};

const AddressForm = ({ address, addAddress, editAddress, closeAlert, isModal, removeDetail }: Props) => {
  useEffect(() => {
    fields.map(field => removeDetail(field.name));
  }, [removeDetail]);

  const onSubmit = (values, callback) => {
    const afterSubmit = res => {
      if (res.success) {
        if (isModal) {
          closeAlert();
        } else {
          browserHistory.push('/user/addresses');
        }
      }

      callback(res);
    };

    if (address) {
      editAddress({ ...values, id: address.id }, address.id, afterSubmit);
    } else {
      addAddress(values, afterSubmit);
    }
  };

  return (
    <Wrapper>
      <FormHeader
        title={
          address ? (
            <FormattedMessage {...messages.editAddressTitle} />
          ) : (
            <FormattedMessage {...settingsMessages.addAddressTitle} />
          )
        }
        requiredFields={true}
      />
      <Form
        values={
          address
            ? {
                addressLabel: address.addressLabel,
                address1: address.address1,
                address2: address.address2,
                town_city: address.townCity,
                county: address.county,
                postcode: address.postcode,
                addressPin: address.addressPin,
                addressNotes: address.addressNotes,
              }
            : {}
        }
        fields={fields}
        onSubmit={onSubmit}
        submitButtonLabel={<FormattedMessage {...settingsMessages.saveChanges} />}
        errorMessage={
          address ? (
            <FormattedMessage {...messages.editAddressError} />
          ) : (
            <FormattedMessage {...messages.addAddressError} />
          )
        }
      />
    </Wrapper>
  );
};

export default connect(null, { removeDetail, addAddress, editAddress })(AddressForm);
