import styled, { css } from 'styled-components';
import { Link } from 'react-router';
import { H3 } from 'components/Typography';

export const Item = styled.li`
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-flow: column;

  ${props =>
    props.listView &&
    css`
      margin-bottom: -10px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
      padding: 10px 0;

      &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
      }
    `}

  ${props =>
    props.filtered &&
    css`
      opacity: 0.4;
    `}

  ${props =>
    props.hasImage &&
    css`
      border: none;
      background: no-repeat center;
      background-size: cover;
      min-height: 140px;
      color: #fff;
    `}
`;

export const ActiveIndicator = styled.div`
  font-size: 0.929em;
  line-height: 0.929em;
  padding: 5px;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
  position: relative;
  text-decoration: none;
  width: 100%;
  flex: 1;

  ${props =>
    props.image &&
    css`
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0.7) 100%);
    `}
`;

export const MenuIndicator = styled.div`
  height: 100%;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;

  svg {
    height: auto;
    width: 21px;
  }

  html[dir='rtl'] & {
    right: auto;
    left: 0;
  }

  ${props =>
    props.hasImage &&
    css`
      border-color: rgba(238, 238, 238, 0.5);

      svg {
        filter: drop-shadow(0 0 1px #000);
      }
    `}
`;

export const IndicatorIcon = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-end;
  padding: 10px;
  height: auto;

  > span {
    display: table-cell;
    vertical-align: bottom;
  }

  ${props =>
    props.hasImage &&
    css`
      color: #fff;
    `}
`;

export const Name = styled(H3)`
  font-weight: normal;
  line-height: 1;
  margin: 5px 0;
`;

export const Description = styled.div`
  font-size: 0.929em;
  line-height: 1.3;
`;
