import { useEffect, useRef } from 'react';

function useSetTimeout(cb, time) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (time === null) {
      return;
    }

    const timerId = setTimeout(() => savedCallback.current(), time);

    return () => {
      clearTimeout(timerId);
    };
  }, [time]);
}

export default useSetTimeout;
