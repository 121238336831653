import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/Loading/LoadingSpinner';

import SignInMethods from './SignInMethods';
import { Container, Content, ModalFooter, StyledActionButton, StyledError } from './styles';

function toggleCheckbox(id) {
  const el = document.getElementById(id);
  el.checked = !el.checked;
}

const CreateAccount = ({ handleRegistration, error, loading }) => {
  const [signInMethod, setSignInMethod] = useState('mobile');

  return (
    <form onSubmit={handleRegistration}>
      <Container>
        <Content>
          <p>
            <FormattedMessage defaultMessage="We just need to send you a one-time-pin to verify access. How would you like to be sent this?" />
          </p>

          {error && <StyledError>{error}</StyledError>}

          <SignInMethods onChange={method => setSignInMethod(method)} method={signInMethod} />

          {signInMethod === 'mobile' && (
            <div className="input-container">
              <input type="checkbox" name="allowSmsMarketing" id="allowSmsMarketing" />
              <span
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  toggleCheckbox('allowSmsMarketing');
                }}
              >
                <FormattedMessage defaultMessage="I agree to receive offers, benefits and other marketing communications" />
              </span>
            </div>
          )}

          {signInMethod === 'email' && (
            <div className="input-container">
              <input type="checkbox" name="allowEmailMarketing" id="allowEmailMarketing" />
              <span
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  toggleCheckbox('allowEmailMarketing');
                }}
              >
                <FormattedMessage defaultMessage="I agree to receive offers, benefits and other marketing communications" />
              </span>
            </div>
          )}

          <div className="input-container">
            <input type="checkbox" name="acceptsTerms" id="termsAndConditions" />
            <span
              style={{ marginLeft: '10px' }}
              onClick={() => {
                toggleCheckbox('termsAndConditions');
              }}
            >
              <FormattedMessage
                defaultMessage="I accept the <a>Vapiano Lovers Loyalty Programme Terms and Conditions</a>"
                values={{
                  a: msg => (
                    <a
                      href="https://app4mobilebiz.wpengine.com/business-end-user-terms-of-use.html"
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                      onClick={e => e.stopPropagation()}
                    >
                      {msg}
                    </a>
                  ),
                }}
              />
            </span>
          </div>
        </Content>
        <ModalFooter>
          <StyledActionButton
            buttonType="submit"
            disabled={loading}
            label={
              loading ? (
                <LoadingSpinner noPad={true} height="1.5rem" width="1.5rem" />
              ) : (
                <FormattedMessage defaultMessage="Send OTP" />
              )
            }
          />
        </ModalFooter>
      </Container>
    </form>
  );
};

CreateAccount.propTypes = {
  handleRegistration: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

export default CreateAccount;
