import React from 'react';

import QuantityButtons from 'components/QuantityButtons';

import { StyledQuantityOption, StyledLabel, StyledButtonContainer } from './styles';

interface Props {
  children: React.ReactNode;
  checked: number | boolean;
  onChange: (q: number, i: boolean) => void;
  maxQuantity: number;
}

const QuantityOption = ({ children, checked, onChange, maxQuantity }: Props) => (
  <StyledQuantityOption>
    <StyledLabel>
      {children}
      <StyledButtonContainer>
        <QuantityButtons
          quantity={checked || 0}
          onChange={value => {
            onChange(maxQuantity, checked < value);
          }}
          maxQuantity={maxQuantity}
          allowZero={true}
        />
      </StyledButtonContainer>
    </StyledLabel>
  </StyledQuantityOption>
);

export default QuantityOption;
