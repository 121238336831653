import React from 'react';
import PropTypes from 'prop-types';

const ServiceIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <g transform="translate(-4078.424 -169.491)">
        <path
          className="icon"
          fill={fill}
          d="M5054.4,794.4h-41.9V682.6c-0.1-205.2-158.7-375.4-363.3-389.9v-57.3h69.9c7.7,0,14-6.3,14-14
        l0,0v-27.9c0-7.7-6.3-14-14-14c0,0,0,0,0,0h-195.6c-7.7,0-14,6.3-14,14l0,0v27.9c0,7.7,6.3,14,14,14c0,0,0,0,0,0h69.9v57.3
        c-204.6,14.5-363.2,184.7-363.3,389.8v111.8h-41.9c-7.7,0-14,6.3-14,14c0,0,0,0,0,0v27.9c0,7.7,6.3,14,14,14h866.4
        c7.7,0,14-6.2,14-14c0,0,0,0,0,0v-27.9C5068.4,800.6,5062.1,794.4,5054.4,794.4z M4956.6,794.4h-670.7V682.6
        c0.6-175.6,136.2-321.2,311.4-334.1c15.9-2.1,32.1-2.1,48,0c175.1,12.9,310.8,158.5,311.4,334.1V794.4z"
        />
        <path
          className="icon"
          fill={fill}
          d="M4104.1,1159.4c-8.6,0-15.6-7-15.7-15.7v-21.2c0.1-8.6,7.1-15.6,15.7-15.6h530.1c11.6,0,22.9-4,31.9-11.2L4799.5,989
        c1.1-0.9,2.1-1.8,3-2.8c4.1-4.6,6.1-10.5,5.7-16.7c-0.4-6.1-3.1-11.8-7.7-15.8c-4.1-3.6-9.3-5.6-14.7-5.6l-0.2,0
        c-5.9,0-11.6,2.1-16.2,5.8l-81.5,65.1c-10.9,8.7-24.6,13.4-38.5,13.4h-238.3c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2,0-1.8-0.1
        c-7-0.5-13.4-3.7-18-9c-4.6-5.3-6.9-12.1-6.4-19.1c0.9-13.7,12.5-24.5,26.2-24.5c0.5,0,1.1,0,1.7,0.1h116.3
        c14.3-0.3,25.9-11.8,26.1-26.2c0.1-7.1-2.5-13.9-7.5-19c-4.9-5.1-11.6-8-18.7-8.1l-194,0c-17.1,0-34.1,5.7-47.8,15.9l-62,46.6
        c-0.9,0.7-1.9,1-3,1h-116.4c-8.6,0-15.6-7-15.7-15.7v-21.2c0-8.6,7-15.6,15.7-15.7h100.6l49.4-37.1c22.7-17,50.7-26.4,79.1-26.4
        h191.1c0.4,0,0.9,0,1.4,0c39.6,0,73.3,28.3,80.1,67.3c0,0,0,0.1,0,0.1c2,13,0.7,26.1-3.8,38.4h47.4l0.1,5.1v-5.1
        c2,0,4-0.7,5.6-1.9l81.5-65.2c13.8-11.1,31.2-17.3,48.9-17.3h0.1c18.4,0,36.1,6.8,49.8,19c0.2,0.2,0.3,0.3,0.5,0.5
        c2.9,2.6,5.6,5.5,8,8.6c12.6,15.8,18.4,35.5,16.1,55.6c-2.2,20.1-12.1,38.1-27.9,50.8l-133.5,106.8
        c-18.3,14.7-41.3,22.8-64.8,22.8H4104.1z"
        />
      </g>
    </g>
  </svg>
);

ServiceIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ServiceIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ServiceIcon;
