import styled from 'styled-components';

export const Title = styled.div`
  font-size: 21px;
  line-height: 1;
  padding-bottom: 20px;
`;

export const MenuButtons = styled.div`
  button {
    margin-bottom: 5px;
  }
`;
