import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { getLocale } from 'selectors/user';

import BrowseLink from 'components/BrowseLink';
import { sectionIsFiltered } from 'selectors/browse';

const SectionLink = ({
  section,
  serviceId,
  menuId,
  menuActive = true,
  isFiltered,
  listView,
  currentLocale,
}) => (
  <BrowseLink
    serviceId={serviceId}
    menuId={menuId}
    sectionId={section.get('id')}
    name={section?.get('translations')?.get(currentLocale)?.get('name') ?? section.get('name')}
    imagePath={section.getIn(['images', 0, 'path'])}
    active={menuActive}
    isFiltered={isFiltered}
    listView={listView}
  />
);

SectionLink.propTypes = {
  section: PropTypes.instanceOf(Map).isRequired,
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  menuId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  menuActive: PropTypes.bool,
  isFiltered: PropTypes.bool,
  listView: PropTypes.bool,
  currentLocale: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  isFiltered: sectionIsFiltered(state, ownProps.section),
  currentLocale: getLocale(state),
});

export default connect(mapStateToProps)(SectionLink);
