/**
 * Combine all reducers in this file and export them combined.
 */
import { combineReducers } from 'redux-immutable';

import ui from 'reducers/UI';
import user from 'reducers/user';
import orderList from 'reducers/order';
import global from 'reducers/root';
import browse from 'reducers/browse';
import sidebar from 'reducers/sidebar';
import payment from 'reducers/payment';
import storage from 'reducers/storage';
import feedback from 'reducers/feedback';
import features from 'reducers/features';
import analytics from 'reducers/analytics';
import fulfilment from 'reducers/fulfilmentReducer';
import venue from 'reducers/venue';
import languages from 'reducers/languages';
import crossSells from 'reducers/crossSells';
import organisation from 'reducers/organisation';
import paymentError from 'reducers/paymentError';
import donations from 'reducers/donations';
import sessions from 'reducers/sessions';
import loyalty from 'reducers/loyalty';

export default combineReducers({
  donations,
  languages,
  crossSells,
  ui,
  user,
  orderList,
  browse,
  global,
  sidebar,
  payment,
  storage,
  feedback,
  fulfilment,
  features,
  analytics,
  venue,
  branding: state => state || null,
  organisation,
  payment_error: paymentError,
  promotions: state => state || null,
  loyalty,
  sessions,
});
