import React, { useState } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import { withTheme } from 'styled-components';
import { callWaiter } from 'actions/callAWaiter';
import Page from 'components/Pages/container';
import Form from 'components/Form';
import WaiterIcon from 'components/Icons/WaiterIcon';
import { getAllowedCallTheWaiterServices, selectServiceName } from 'selectors/root';
import { setService } from 'actions/browse';
import fieldTypes from 'components/Form/fieldTypes';
import globalMessages from 'components/globalMessages';

const messages = defineMessages({
  title: {
    defaultMessage: 'Call a Waiter',
  },
  submitRequest: {
    defaultMessage: 'Submit Request',
  },
  requestSubmitted: {
    defaultMessage: 'Request Submitted',
  },
  requestFailed: {
    defaultMessage: 'There was a problem calling a waiter. Please try again later.',
  },
});

const propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  callTheWaiterServices: PropTypes.instanceOf(Map),
  getServiceName: PropTypes.func.isRequired,
  setService: PropTypes.func,
  callWaiter: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
};

const CallAWaiter = ({ intl, callTheWaiterServices, getServiceName, setService, callWaiter, location }) => {
  const [success, setSuccess] = useState(false);

  const getFormFields = () => {
    const { formatMessage } = intl;
    const serviceOptions = callTheWaiterServices
      .valueSeq()
      .map(service => ({
        value: service.get('id'),
        label: getServiceName(service.get('id')),
      }))
      .toArray();

    return [
      {
        ...fieldTypes.dropdown,
        name: 'service',
        options: serviceOptions,
        required: true,
        placeholder: formatMessage(globalMessages.location),
      },
      { ...fieldTypes.tableNumber },
    ];
  };

  const onChange = (value, field) => {
    if (field.name === 'service') {
      setService(value);
    }
  };

  const onSubmit = (values, callback) => {
    callWaiter(
      values.service,
      values.tableNumber,
      () => {
        setSuccess(true);
        callback({ success: true });
      },
      e => {
        setSuccess(false);
        callback(e);
      }
    );
  };

  return (
    <Page settingsPage={true} titleMessage={messages.title} Icon={WaiterIcon}>
      <Form
        values={{
          tableNumber: location?.query?.tableNumber,
        }}
        fields={getFormFields()}
        onChange={onChange}
        onSubmit={onSubmit}
        submitButtonLabel={
          success ? (
            <FormattedMessage {...messages.requestSubmitted} />
          ) : (
            <FormattedMessage {...messages.submitRequest} />
          )
        }
        errorMessage={<FormattedMessage {...messages.requestFailed} />}
      />
    </Page>
  );
};

CallAWaiter.propTypes = propTypes;

export default connect(
  state => ({
    callTheWaiterServices: getAllowedCallTheWaiterServices(state),
    getServiceName: id => selectServiceName(state, id),
  }),
  { setService, callWaiter }
)(injectIntl(withTheme(CallAWaiter)));
