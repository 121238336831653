const cleanDate = item => {
  const date = item?.get('completed_at') || item?.get('created_at');
  const [result] = date.split('T');

  return result;
};

const filterByDateRange = (list, dateFrom, dateTo) => {
  if (!list) return false;
  if (!dateFrom && !dateTo) return list;

  let resultList;

  if (dateFrom && dateTo) {
    resultList = list.filter(item => cleanDate(item) >= dateFrom && cleanDate(item) <= dateTo);
  }

  if (!dateFrom && dateTo) {
    resultList = list.filter(item => cleanDate(item) <= dateTo);
  }

  if (dateFrom && !dateTo) {
    resultList = list.filter(item => cleanDate(item) >= dateFrom);
  }

  return resultList;
};

export default filterByDateRange;
