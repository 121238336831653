import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

const PageTitle = ({ intl: { formatMessage }, message, messageValues, title }) => (
  <Helmet title={title || formatMessage(message, messageValues)} />
);

PageTitle.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
  }),
  messageValues: PropTypes.object,
};

export default injectIntl(PageTitle);
