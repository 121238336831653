export const hasNutritionalInformation = product => {
  const valueNames = [
    'energy_kcal',
    'energy_kj',
    'fat',
    'fat_saturates',
    'carbohydrates',
    'carbohydrates_sugars',
    'fibre',
    'protein',
    'salt',
  ];
  return (
    valueNames.reduce((total, name) => {
      const nutritionalValue = parseInt(product?.get(name) || 0, 10);
      return total + nutritionalValue;
    }, 0) > 0
  );
};
