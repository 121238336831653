import React from 'react';
import PropTypes from 'prop-types';

// Icon
const FacebookIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon FacebookIcon"
        fill={fill}
        d="M754.423,17.069V172.55h-92.462c-33.775,0-56.54,7.064-68.319,21.201c-11.779,14.133-17.667,35.334-17.667,63.604v111.31	h172.56l-22.971,174.33h-149.59V990H395.758V542.995h-150.18v-174.33h150.18V240.277c0-73.03,20.411-129.66,61.251-169.911 C497.839,30.128,552.214,10,620.142,10C677.859,10,722.619,12.358,754.423,17.069z"
      />
    </g>
  </svg>
);

FacebookIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FacebookIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default FacebookIcon;
