import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DayPicker } from 'react-day-picker';

import { CloseIcon } from 'components/Icons';
import { getLocale } from 'selectors/user';
import globalMessages from 'components/globalMessages';
import { dateLocales } from '../../../build/locales';

import 'react-day-picker/dist/style.css';
import {
  DayPickerButton,
  ModalLayer,
  ModalInner,
  Close,
  CloseIconWrapper,
  CloseLabel,
  CalendarWrapper,
} from './styles';

class DayPickerCalendar extends PureComponent {
  static propTypes = {
    buttonLabel: PropTypes.node,
    userLocale: PropTypes.string,
    onDayChange: PropTypes.func,
  };

  state = {
    showCalendar: false,
  };

  handleToggleCalendar = () => this.setState(prevState => ({ showCalendar: !prevState.showCalendar }));

  handleOnDayClick = selectedDay => {
    this.props.onDayChange(selectedDay);
    this.setState({ showCalendar: false });
  };

  render() {
    const { buttonLabel, onDayChange, userLocale, ...rest } = this.props;
    const { showCalendar } = this.state;
    const locale = dateLocales[userLocale];

    return (
      <>
        <DayPickerButton onClick={this.handleToggleCalendar}>{buttonLabel}</DayPickerButton>
        {showCalendar && (
          <ModalLayer>
            <ModalInner>
              <CalendarWrapper>
                <DayPicker locale={locale} mode="single" onSelect={this.handleOnDayClick} {...rest} />
                <Close onClick={this.handleToggleCalendar}>
                  <CloseIconWrapper>
                    <CloseIcon />
                  </CloseIconWrapper>
                  <CloseLabel>
                    <FormattedMessage {...globalMessages.close} />
                  </CloseLabel>
                </Close>
              </CalendarWrapper>
            </ModalInner>
          </ModalLayer>
        )}
      </>
    );
  }
}

export default connect(state => ({
  userLocale: getLocale(state),
}))(DayPickerCalendar);
