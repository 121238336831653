import React from 'react';
import PropTypes from 'prop-types';

// Icon
const TimerIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M164.812,13.909c14.038,5.939,26.27,14.13,36.714,24.566c10.436,10.443,18.627,22.676,24.565,36.713  C232.03,89.226,235,104.166,235,120c0,15.843-2.97,30.775-8.909,44.812c-5.938,14.037-14.13,26.277-24.565,36.713  c-10.444,10.443-22.676,18.629-36.714,24.568C150.774,232.029,135.835,235,120,235c-15.843,0-30.775-2.971-44.813-8.906  c-14.037-5.939-26.278-14.125-36.713-24.568c-10.444-10.436-18.627-22.676-24.566-36.713S5,135.844,5,120  c0-7.913,0.717-15.657,2.16-23.216c1.434-7.558,3.78-14.754,7.019-21.596c2.876-6.833,6.386-13.312,10.528-19.437  c4.134-6.116,8.722-11.878,13.768-17.276l6.479,6.479c-4.682,5.045-8.908,10.351-12.688,15.927  c-3.779,5.585-7.111,11.608-9.988,18.087c-2.522,6.124-4.505,12.688-5.939,19.706c-1.442,7.019-2.16,14.13-2.16,21.327  c0,14.763,2.784,28.529,8.368,41.304c5.577,12.779,13.042,24.023,22.406,33.742c9.718,9.363,20.963,16.83,33.744,22.406  c12.772,5.584,26.54,8.369,41.303,8.369c14.755,0,28.521-2.785,41.304-8.369c12.771-5.576,24.025-13.043,33.743-22.406  c9.355-9.719,16.821-20.963,22.405-33.742c5.577-12.775,8.369-26.541,8.369-41.304c0-14.038-2.615-27.35-7.829-39.953  c-5.222-12.595-12.511-23.756-21.866-33.475c-9.001-9.355-19.621-16.915-31.854-22.676c-12.24-5.753-25.375-8.993-39.412-9.719  v45.892L115,60.078V4.871C139.5,4.871,150.774,7.97,164.812,13.909z M108.662,128.098L65.47,72.489  c-0.726-1.08-0.996-2.244-0.81-3.51c0.177-1.256,0.81-2.243,1.89-2.969c0.717-0.717,1.62-1.08,2.7-1.08  c1.08,0,1.974,0.363,2.699,1.08l56.15,42.652c0.354,0,0.717,0.363,1.08,1.08c1.435,1.442,2.606,2.969,3.51,4.589  c0.895,1.62,1.349,3.509,1.349,5.669c0,1.806-0.362,3.601-1.079,5.398c-0.726,1.807-1.805,3.24-3.239,4.32  c-1.08,1.443-2.523,2.521-4.319,3.238c-1.806,0.727-3.604,1.08-5.399,1.08c-2.16,0-4.142-0.539-5.939-1.619  c-1.805-1.08-3.425-2.336-4.859-3.779L108.662,128.098z"
      />
    </g>
  </svg>
);

TimerIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TimerIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default TimerIcon;
