import { fromJS, Map } from 'immutable';

import {
  REQUEST_CROSS_SELLS,
  REQUEST_CROSS_SELLS_SUCCESS,
  REQUEST_CROSS_SELLS_FAILURE,
} from 'actions/crossSells/constants';

const crossSells = (state = new Map(), action) => {
  switch (action.type) {
    case REQUEST_CROSS_SELLS:
      return state.merge(
        fromJS({
          loadingCrossSells: true,
        })
      );

    case REQUEST_CROSS_SELLS_SUCCESS:
      return state.merge(
        fromJS({
          loadingCrossSells: false,
          crossSellsList: action.crossSellsList,
          errorLoadingCrossSells: false,
        })
      );

    case REQUEST_CROSS_SELLS_FAILURE:
      return state.merge(
        fromJS({
          loadingCrossSells: false,
          errorLoadingCrossSells: true,
        })
      );

    default:
      return state;
  }
};

export default crossSells;
