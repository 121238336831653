import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import dietaryFilters from 'components/Alert/alerts/AllergenFilter/dietaryFilters';
import { allergenContactStaff } from 'selectors/features';

import { Container, StyledAllergenIcon } from './styles';

type Props = {
  product: Map<string, any>;
  allergenContactStaff?: boolean;
};

const SuitableFor = ({ product, allergenContactStaff }: Props) => {
  if (allergenContactStaff) {
    return null;
  }

  return (
    <Container>
      {dietaryFilters
        .get('lifestyleChoices')
        .map(
          (lifestyleChoice, index) =>
            product?.get(lifestyleChoice) && (
              <StyledAllergenIcon allergenName={lifestyleChoice} width="20px" height="20px" key={index} />
            )
        )}
    </Container>
  );
};

const mapStateToProps = state => ({
  allergenContactStaff: allergenContactStaff(state),
});

export default connect(mapStateToProps)(SuitableFor);
