import React from 'react';
import PropTypes from 'prop-types';

const NutritionIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    style={{ borderColor: fill }}
    viewBox="0 0 1000 1000"
  >
    <path
      className="icon nutritionIcon"
      fill={fill}
      d="M695.7,268.2c-25,0-47.7-2.8-66-5.1c-2.6-0.3-5-0.6-7.4-0.9l-3.1-0.4l-0.4-3.1c-9.9-75.2,3.4-134.3,39.6-175.5 c18.6-21.3,43.4-37.5,73.5-48.3c29.9-10.7,65.3-16.1,105.3-16.1c21,0,43.6,1.5,67.1,4.5l3.8,0.5l-0.3,3.8 c-6.8,92.1-33.4,158.6-79.2,197.7C794.7,254.1,751.3,268.2,695.7,268.2C695.8,268.2,695.7,268.2,695.7,268.2z M659.9,222.3 c13.4,1.3,24.9,1.9,35.9,1.9c44.6,0,78.7-10.6,104.3-32.5c30.2-25.8,50.2-68.9,59.5-128.4c-7.6-0.4-15.1-0.6-22.3-0.6 c-67.9,0-116.9,16.6-145.7,49.5C668.9,138.1,658.2,175.1,659.9,222.3z"
    />
    <path
      className="icon nutritionIcon"
      fill={fill}
      d="M710.1,981.2c-16.9,0-35.8-7.4-54-14.5c-18.1-7.1-36.8-14.4-53.3-14.4c-19.3,0-41,6.8-61.9,13.4 c-20.9,6.6-42.6,13.4-62,13.4c-6.1,0-11.8-0.7-17-2c-111.3-28.4-196.7-120.9-228.6-247.7L98.6,636.5 c-38.1-26.7-61.9-53.2-72.8-81.1c-12.1-30.9-7.5-63.4,13.7-96.4l112.2-170.3l0.2-0.2c20.7-22.1,46.8-33.3,77.6-33.3 c41.2,0,90.9,19.3,151.9,59c14.5-4.3,30.1-6.7,46.4-7c2.2,0,4.4-0.1,6.5-0.1c61.3,0,89.8,16.5,112.6,29.7 c10.7,6.2,20.1,11.6,31.4,15c-1.9-98.3-28.8-159.1-95.1-215.8l-3-2.6l28.6-33.5l3,2.6c39.6,33.8,66.3,68.7,84,109.6 c17.3,39.9,25.7,85,26.6,141.6c17.9-2.8,28.6-9.7,40.8-17.6c22.4-14.5,47.9-31,124.6-31c63.8,0,99.2,42,128.1,87.4 c42.5,66.9,64.9,145,64.9,226.1c0,183.7-104.6,328.9-260.2,361.4C717.3,980.8,713.8,981.2,710.1,981.2 C710.1,981.2,710.1,981.2,710.1,981.2z M602.8,908.2c24.8,0,48.5,9.3,69.4,17.5c14.4,5.6,29.3,11.5,37.9,11.5 c0.9,0,1.4-0.1,1.5-0.1c65.7-13.7,121.7-50.7,162.1-107.1c41.3-57.6,63.1-130.6,63.1-211.3c0-72.7-20.1-142.7-58.1-202.5 c-35.4-55.8-61.1-67-90.9-67c-63.7,0-82.5,12.2-100.7,23.9c-17.9,11.6-40.3,26.1-83.8,26.1c0,0-1.2,0-1.9,0 c-35.2-0.3-57.2-13-76.6-24.3c-21.2-12.2-41.1-23.8-90.6-23.8c-1.9,0-3.8,0-5.8,0.1c-45.1,0.8-79,21.5-106.7,65 c-10.1,15.8-19,32.6-26.5,49.9l326.2,231.5l-142.7,201l-185.6-128c8.1,19.2,17.8,37.3,29,54c37.5,56.2,89.5,94.1,150.6,109.7 c1.7,0.4,3.7,0.6,6.2,0.6c12.7,0,31-5.8,48.8-11.4C551.8,916.1,576.7,908.2,602.8,908.2z M76.4,483.1 c-17.6,27.5-13.9,45.4-9.6,56.4c7.5,19.2,26.7,39.7,57,61l344.4,237.5l92.1-129.8l-59.8-42.5l-41.3,61.8L422.5,703l42-62.8 l-40.6-28.8l-40.8,61.1L346.5,648l41.5-62.1l-39.5-28l-40.5,60.6L271.5,594l41.2-61.6l-41.9-29.8l-40.6,60.8L193.5,539l41.3-61.8 l-2.7-1.9c-13.1-9.4-25.4-19.5-36.4-30.1l-41.6,62.3L117.5,483l48.2-72.1c-3.9-5.4-7.4-10.7-10.4-16c-3.5-6-6.5-12.1-8.9-18 L76.4,483.1z M223.1,302.3c-12.2,0-28.3,2.5-36,14.1c-9.1,13.8-6.9,33.9,6.2,56.5c13.1,22.6,35.9,46.3,64.3,66.6l1.1,0.8 c7.6-16.5,16.3-32.5,26-47.6c13.9-21.9,29.8-39.8,47.3-53.2C295.3,316.8,253.1,302.3,223.1,302.3z"
    />
  </svg>
);

NutritionIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

NutritionIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default NutritionIcon;
