import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';

import FormattedPrice from 'components/FormattedPrice';
import { getIcon } from 'utils';
import { ChevronForward, CloseIcon } from 'components/Icons';
import { openOrderInfo } from 'actions/UI';

import messages from './messages';
import {
  OrderListStyled,
  OrderButton,
  OrderInfoContainer,
  VenueNameStyled,
  OrderPaymentContainer,
  Date,
  OrderNumber,
  OrderPaymentInfo,
  OrderTotalStyled,
  OrderStatusContainer,
  OrderStatusIconContainer,
  ChevronContainer,
} from './styles';

const width = '14px';
const height = '14px';

const showPaymentTypeLabel = payment => {
  const separator = `${payment.get('display_label') !== '' && payment.get('card_type') !== '' ? '-' : ''}`;
  const label = `${payment.get('display_label')} ${separator} ${payment.get('card_type')}`;

  return label;
};

const OrderList = ({ orders, openOrderInfo, venueName }) => (
  <OrderListStyled>
    {orders.map(order => (
      <li key={order.get('number')}>
        <OrderButton
          type="button"
          onClick={() => openOrderInfo(order.get('reference'))}
          className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween"
        >
          <OrderInfoContainer>
            <Date>
              <FormattedDate
                value={order.get('completed_at') || order.get('updated_at')}
                year="numeric"
                month="short"
                day="numeric"
                hour="2-digit"
                minute="2-digit"
              />
            </Date>
            <VenueNameStyled>{venueName}</VenueNameStyled>
            <OrderNumber>
              <FormattedMessage {...messages.orderNumber} /> <span>#{order.get('number')}</span>
            </OrderNumber>
          </OrderInfoContainer>

          <div className="u-flex u-flexAlignItemsCenter">
            <OrderPaymentContainer>
              <OrderPaymentInfo>
                {order.get('payments').size ? showPaymentTypeLabel(order.get('payments').first()) : '-'}
              </OrderPaymentInfo>
              <OrderTotalStyled>
                <FormattedPrice value={order.get('total')} />
              </OrderTotalStyled>
              <OrderStatusContainer status={order.get('state')}>
                <span>{order.get('state')}</span>
                <OrderStatusIconContainer status={order.get('state')}>
                  {getIcon(order.get('state'), {
                    defaultIcon: <CloseIcon width={width} height={height} />,
                    width,
                    height,
                  })}
                </OrderStatusIconContainer>
              </OrderStatusContainer>
            </OrderPaymentContainer>

            <ChevronContainer>
              <ChevronForward />
            </ChevronContainer>
          </div>
        </OrderButton>
      </li>
    ))}
  </OrderListStyled>
);

OrderList.propTypes = {
  orders: PropTypes.instanceOf(List),
  openOrderInfo: PropTypes.func.isRequired,
  venueName: PropTypes.string,
};

export default connect(undefined, { openOrderInfo })(OrderList);
