import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Link } from 'react-router';

export const Container = styled.div`
  border-radius: 5px;
  margin: 5px;
  padding: 20px 5px;
  width: calc(50% - 10px);
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => rgba(theme.color.brandSecondary, 0.1)};

  ${({ hasimage }) =>
    hasimage &&
    css`
      background: no-repeat center;
      background-size: cover;
      min-height: 140px;
      border-color: #ddd;
      padding: 0;
      position: relative;
    `}
`;

export const StyledLink = styled(Link)`
  ${({ hasimage }) =>
    hasimage &&
    css`
      display: block;
      padding: 40px 5px 0;
      min-height: 140px;
      color: #fff;
      text-shadow: 0 0 5px #000;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0.7) 100%);
    `}
`;

export const Name = styled.h3`
  font-size: ${({ theme }) => theme.fonts.fontSizeBig};
  text-transform: uppercase;

  ${({ hasimage }) =>
    hasimage &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px;
    `}
`;
