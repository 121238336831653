import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'components/globalMessages';

const getDeliveryPartnerName = value => {
  switch (value) {
    case 'careem':
      return <FormattedMessage {...globalMessages.careem} />;
    case 'deliveroo':
      return <FormattedMessage {...globalMessages.deliveroo} />;
    case 'doordash':
      return <FormattedMessage {...globalMessages.doordash} />;
    case 'grubhub':
      return <FormattedMessage {...globalMessages.grubHub} />;
    case 'ubereats':
      return <FormattedMessage {...globalMessages.uberEats} />;
    case 'talabat':
      return <FormattedMessage {...globalMessages.talabat} />;
    case 'hungerstation':
      return <FormattedMessage {...globalMessages.hungerstation} />;
    case 'zomato':
      return <FormattedMessage {...globalMessages.zomato} />;
    case 'jahez':
      return <FormattedMessage {...globalMessages.jahez} />;
    case 'toyou':
      return <FormattedMessage {...globalMessages.toyou} />;
    case 'mrsool':
      return <FormattedMessage {...globalMessages.mrsool} />;
    default:
      return <FormattedMessage {...globalMessages.delivery} />;
  }
};

export default getDeliveryPartnerName;
