import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';

import LoadingSpinner from 'components/Loading/LoadingSpinner';
import {
  requestUserLocation,
  removeUserGeoLocation,
  clearPredictionsAndCoordsFromStateAndSession,
} from 'actions/user';
import { getUserGeoLocation, getAllowGeoLocation, isUserGeoLocationLoading } from 'selectors/user';
import LocationIcon from 'components/Icons/LocationIcon';
import ErrorContainer from 'components/ErrorContainer';

import { ButtonStyled, LocationIconContainer, Text } from './styles';

const messages = defineMessages({
  clearLocation: {
    defaultMessage: 'Clear location',
  },
  gettingLocation: {
    defaultMessage: 'Getting location...',
  },
  useMyLocation: {
    defaultMessage: 'Use my location',
  },
});

const propTypes = {
  className: PropTypes.string,
  showError: PropTypes.bool,
  userGeoLocation: PropTypes.instanceOf(Map),
  allowGeoLocation: PropTypes.bool,
  isUserGeoLocationLoading: PropTypes.bool,
  removeUserGeoLocation: PropTypes.func,
  clearPredictionsAndCoordsFromStateAndSession: PropTypes.func,
  requestUserLocation: PropTypes.func,
  callback: PropTypes.func,
};

const defaultProps = {
  callback: () => undefined,
  showError: true,
};

const GeoButton = ({
  className,
  showError,
  userGeoLocation,
  allowGeoLocation,
  isUserGeoLocationLoading,
  removeUserGeoLocation,
  clearPredictionsAndCoordsFromStateAndSession,
  requestUserLocation,
  callback,
}) => (
  <ErrorContainer
    className={className}
    errors={showError && userGeoLocation.get('error') ? userGeoLocation.get('error').toJS() : undefined}
  >
    <ButtonStyled
      className={`u-flex u-flexJustifyCenter u-flexAlignItemsCenter ${className}`}
      located={allowGeoLocation}
      onClick={
        allowGeoLocation
          ? () => {
              removeUserGeoLocation();
              clearPredictionsAndCoordsFromStateAndSession();
            }
          : () => requestUserLocation(callback)
      }
    >
      <LocationIconContainer located={allowGeoLocation}>
        {isUserGeoLocationLoading ? (
          <LoadingSpinner width="24px" height="25px" noPad={true} />
        ) : (
          <LocationIcon width="24px" height="24px" />
        )}
      </LocationIconContainer>
      <Text>
        {allowGeoLocation ? (
          <FormattedMessage {...messages.clearLocation} />
        ) : (
          <FormattedMessage {...messages.useMyLocation} />
        )}
      </Text>
    </ButtonStyled>
  </ErrorContainer>
);

GeoButton.propTypes = propTypes;
GeoButton.defaultProps = defaultProps;

export default connect(
  state => ({
    userGeoLocation: getUserGeoLocation(state),
    allowGeoLocation: getAllowGeoLocation(state),
    isUserGeoLocationLoading: isUserGeoLocationLoading(state),
  }),
  { removeUserGeoLocation, clearPredictionsAndCoordsFromStateAndSession, requestUserLocation }
)(GeoButton);
