import React from 'react';
import PropTypes from 'prop-types';

const SoloCardIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 72 45.581"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <g transform="translate(1 1)">
      <g fill="#f9f9f9" stroke="#999">
        <rect stroke="none" width="70" height="43.581" rx="4" />
        <rect fill="none" x="-0.5" y="-0.5" width="71" height="44.581" rx="4.5" />
      </g>
      <path
        d="M746.945,611.943a6.341,6.341,0,0,1-10.626,6.922l1.821-1.224a4.118,4.118,0,0,0,6.9-4.494Z"
        transform="translate(-708.625 -595.131)"
      />
      <path
        fill="#58595b"
        d="M752.723,606.592a6.341,6.341,0,1,0-10.618,6.934l1.854-1.175a4.118,4.118,0,0,1,6.9-4.5Z"
        transform="translate(-710.161 -592.472)"
      />
      <path
        d="M729.32,608.948a12.043,12.043,0,1,1,12.043,12.042A12.042,12.042,0,0,1,729.32,608.948Zm12.043,11.2a11.2,11.2,0,1,0-11.2-11.2A11.2,11.2,0,0,0,741.363,620.148Z"
        transform="translate(-706.363 -590.271)"
      />
      <path
        d="M748.786,636.277h.77l-.636,2.853h2.072l-.145.642h-2.843Z"
        transform="translate(-712.402 -602.996)"
      />
      <path
        d="M736.066,638.637a1.712,1.712,0,0,0,1.406.64c.655-.02.89-.208.935-.521.016-.125.067-.408-.478-.473l-.884-.1c-.939-.124-1.091-.5-1.069-.972.029-.642.833-1.14,1.7-1.15a2.055,2.055,0,0,1,1.707.567l-.534.323a1.709,1.709,0,0,0-1.314-.348c-.33.022-.775.154-.843.474-.048.226-.036.367.437.423l1.119.146a1.014,1.014,0,0,1,.906.96c-.061.606-.589,1.322-1.856,1.26a2.009,2.009,0,0,1-1.8-.9Z"
        transform="translate(-708.36 -602.926)"
      />
      <path
        d="M741.846,637.923a2.276,2.276,0,0,1,2.179-1.907,1.616,1.616,0,0,1,1.636,1.907,2.276,2.276,0,0,1-2.181,1.91A1.617,1.617,0,0,1,741.846,637.923Zm1.731,1.241a1.479,1.479,0,0,0,1.417-1.241,1.051,1.051,0,0,0-1.064-1.24,1.482,1.482,0,0,0-1.417,1.24A1.052,1.052,0,0,0,743.577,639.164Z"
        transform="translate(-710.405 -602.911)"
      />
      <path
        d="M753.082,637.923a2.275,2.275,0,0,1,2.179-1.907,1.616,1.616,0,0,1,1.636,1.907,2.277,2.277,0,0,1-2.18,1.91A1.617,1.617,0,0,1,753.082,637.923Zm1.731,1.241a1.479,1.479,0,0,0,1.417-1.241,1.05,1.05,0,0,0-1.063-1.24,1.481,1.481,0,0,0-1.418,1.24A1.051,1.051,0,0,0,754.813,639.164Z"
        transform="translate(-714.037 -602.911)"
      />
    </g>
  </svg>
);

SoloCardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

SoloCardIcon.defaultProps = {
  width: '50px',
  height: '30px',
};

export default SoloCardIcon;
