import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { setBrowseInfo } from 'actions/browse';
import Loading from 'components/Loading';
import RadiusMessage from 'components/RadiusMessage';
import OutOfServiceMessage from 'components/OutOfServiceMessage';
import MessageBlock from 'components/MessageBlock';
import {
  getServiceNextActiveTime,
  menuIsFiltered,
  selectMenusByType,
  selectMenuTypeId,
  menusAreFiltered,
} from 'selectors/browse';
import { getServiceId, selectServiceName } from 'selectors/root';
import BrowseLink from 'components/BrowseLink';
import FilteredItemsMessage from 'components/FilteredItemsMessage';
import { MenuContainer } from 'components/Menu/styles';
import { getLocale } from 'selectors/user';
import { isGridView } from 'selectors/features';

const messages = defineMessages({
  loadingMenus: {
    defaultMessage: 'Loading Menus...',
  },
  noMenusTitle: {
    defaultMessage: 'Sorry',
  },
  noMenus: {
    defaultMessage: 'There are currently no menus available at this time. Please check back soon?',
  },
});

const propTypes = {
  setBrowseInfo: PropTypes.func,
  serviceName: PropTypes.string,
  service: PropTypes.instanceOf(Map),
  menus: PropTypes.instanceOf(Map),
  menusAreFiltered: PropTypes.bool,
  nextAvailableTime: PropTypes.number,
  serviceId: PropTypes.string,
  menuIsFiltered: PropTypes.func.isRequired,
  currentLocale: PropTypes.string,
  isGridView: PropTypes.bool,
};

const MenuSelection = ({
  setBrowseInfo,
  serviceName,
  service,
  menus,
  menusAreFiltered,
  nextAvailableTime,
  serviceId,
  menuIsFiltered,
  currentLocale,
  isGridView,
}) => {
  useEffect(() => {
    setBrowseInfo(serviceName, '', service?.get('default_image'));
  }, [service, serviceName, setBrowseInfo]);

  const menuHasImages = !!menus?.find(menu => menu.getIn(['images', 0, 'path']));

  if (menus === undefined) {
    return (
      <Loading>
        <FormattedMessage {...messages.loadingMenus} />
      </Loading>
    );
  }

  if (menus?.size === 0) {
    return (
      <MessageBlock
        header={<FormattedMessage {...messages.noMenusTitle} />}
        body={<FormattedMessage {...messages.noMenus} />}
      />
    );
  }

  return (
    <>
      {menusAreFiltered && <FilteredItemsMessage type="menus" />}
      <RadiusMessage />
      <OutOfServiceMessage
        nextAvailableTime={{
          time: nextAvailableTime,
          type: 'service',
        }}
      />

      <MenuContainer isGridView={isGridView}>
        {menus
          .map(menu => (
            <BrowseLink
              key={menu.get('id')}
              serviceId={serviceId}
              menuId={menu.get('id')}
              name={menu?.get('translations')?.get(currentLocale)?.get('name') ?? menu.get('name')}
              imagePath={menu.getIn(['images', 0, 'path'])}
              active={menu.get('active')}
              isFiltered={menuIsFiltered(menu)}
              listView={!menuHasImages}
            />
          ))
          .toList()
          .toJS()}
      </MenuContainer>
    </>
  );
};

MenuSelection.propTypes = propTypes;

const mapStateToProps = state => {
  const serviceId = getServiceId(state);
  const services = state.getIn(['venue', 'services']);
  const menuTypeId = selectMenuTypeId(state);

  return {
    menus: selectMenusByType(state, menuTypeId, serviceId),
    serviceId,
    serviceName: services.size > 1 ? selectServiceName(state, serviceId) : null,
    service: services.get(serviceId),
    nextAvailableTime: getServiceNextActiveTime(state, serviceId),
    menuIsFiltered: menu => menuIsFiltered(state, menu),
    menusAreFiltered: menusAreFiltered(state, menuTypeId, serviceId),
    currentLocale: getLocale(state),
    isGridView: isGridView(state),
  };
};

export default connect(mapStateToProps, { setBrowseInfo })(MenuSelection);
