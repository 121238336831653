import dompurify from 'dompurify';

// finds urls in string and creates anchor element
export default (str: string | number | null) => {
  if (!str || typeof str !== 'string') return str;

  const urlRegex = /https?:\/\/\S+/gi; // matches url
  const urlMarkdownRegex = /\[(.+?)\]\((https?:\/\/([^)]+))\)/gi; // matches url in markdown i.e [text](url)
  const transformed = str
    .replace(urlRegex, (match, matchIndex, str) => {
      // if url is not preceded by markdown format, replace with anchor
      const markdown = '](';
      if (str.substring(matchIndex - markdown.length, matchIndex) === markdown) return match;
      return createAnchor(match, match);
    })
    .replace(urlMarkdownRegex, createAnchor(`$2`, `$1`));
  return dompurify.sanitize(transformed, { ADD_ATTR: ['target'] });
};

const createAnchor = (url: string, text: string) => `<a href="${url}" target="_blank">${text}</a>`;
