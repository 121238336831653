import styled from 'styled-components';

export const Container = styled.div`
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 460px;
    font-size: 21px;
  }
`;

export const QuantityContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  margin: 10px 20px;
`;

export const QuantityHeader = styled.div`
  display: block;
  font-size: 18px;
  line-height: normal;
  padding: 15px;

  @media (min-width: 768px) {
    font-size: 21px;
  }
`;
