import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from 'components/Checkbox';

const ChoiceLabel = styled.label`
  cursor: pointer;
`;

const propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Choice = ({ id, checked, onChange, text }) => (
  <ChoiceLabel htmlFor={id} key={id}>
    <Checkbox id={id} checked={checked} onChange={onChange}>
      {text}
    </Checkbox>
  </ChoiceLabel>
);

Choice.propTypes = propTypes;

export default Choice;
