import { useRef, useEffect } from 'react';

function useIsMounted() {
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return [isMounted];
}

export default useIsMounted;
