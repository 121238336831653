import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { setBrowseInfo } from 'actions/browse';
import Product from 'components/Product';
import PageTitle from 'components/PageTitle';
import SectionLink from 'components/SectionLink';
import ContentArea from 'components/ContentArea';
import MessageBlock from 'components/MessageBlock';
import { fetchSection } from 'actions/menu';
import RadiusMessage from 'components/RadiusMessage';
import OutOfServiceMessage from 'components/OutOfServiceMessage';
import { getServiceById, getServiceId } from 'selectors/root';
import {
  selectMenuTypeId,
  getMenuSectionById,
  isMenuActive,
  getMenuNextActiveDetails,
  selectSectionProducts,
  sectionHasFilteredProducts,
  selectSectionHeaderImage,
  selectCurrentSectionAndChildSections,
} from 'selectors/browse';
import FilteredItemsMessage from 'components/FilteredItemsMessage';
import { isGridView } from 'selectors/features';
import { MenuContainer } from 'components/Menu/styles';
import { getLocale } from 'selectors/user';

const messages = defineMessages({
  noProductsTitle: {
    defaultMessage: 'Sorry',
  },
  noProducts: {
    defaultMessage:
      'There are currently no products available in the section at this time. Please check back soon?',
  },
});

const propTypes = {
  slimProducts: PropTypes.bool,
  fetchSection: PropTypes.func,
  sectionId: PropTypes.string,
  setBrowseInfo: PropTypes.func,
  section: PropTypes.instanceOf(Map),
  sectionImage: PropTypes.string,
  sections: PropTypes.instanceOf(List),
  items: PropTypes.instanceOf(List),
  itemsAreFiltered: PropTypes.bool,
  nextAvailableTime: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      time: PropTypes.number,
    }),
  ]),
  serviceId: PropTypes.string,
  menuId: PropTypes.string,
  isAvailable: PropTypes.bool,
  isGridView: PropTypes.bool,
  menuTypeId: PropTypes.number,
  currentLocale: PropTypes.string,
};

const defaultProps = {
  items: new List(),
};

const Section = ({
  slimProducts,
  fetchSection,
  sectionId,
  setBrowseInfo,
  section,
  sectionImage,
  sections,
  items,
  itemsAreFiltered,
  nextAvailableTime,
  serviceId,
  menuId,
  isAvailable,
  isGridView,
  menuTypeId,
  currentLocale,
}) => {
  useEffect(() => {
    if (slimProducts) fetchSection(sectionId);

    if (section) {
      setBrowseInfo(
        section?.get('translations')?.get(currentLocale)?.get('name') ?? section.get('name'),
        section?.get('translations')?.get(currentLocale)?.get('description') ?? section.get('description'),
        sectionImage
      );
    }
  }, [slimProducts, fetchSection, sectionId, currentLocale]);

  if (items?.size + sections?.size < 1) {
    return (
      <div className="container">
        <MessageBlock
          header={<FormattedMessage {...messages.noProductsTitle} />}
          body={<FormattedMessage {...messages.noProducts} />}
        />
      </div>
    );
  }

  const sectionHasImages = !!sections.find(section => section.getIn(['images', 0, 'path']));

  return (
    <>
      <PageTitle
        title={section?.get('translations')?.get(currentLocale)?.get('name') ?? section.get('name')}
      />

      <ContentArea area="section_start" position="top" />
      {itemsAreFiltered && <FilteredItemsMessage />}
      <RadiusMessage />
      <OutOfServiceMessage nextAvailableTime={nextAvailableTime} />
      <MenuContainer isGridView={isGridView}>
        {sections.map((section, sectionIndex) => {
          if (sectionId === section.get('id').toString()) {
            return items.map((item, itemIndex) => (
              <Product
                key={itemIndex}
                item={item}
                isGridView={isGridView}
                menuTypeId={menuTypeId}
                serviceId={serviceId}
                isAvailable={isAvailable}
                shouldFetchSection={false}
              />
            ));
          }

          return (
            <SectionLink
              key={sectionIndex}
              section={section}
              serviceId={serviceId}
              menuId={menuId}
              isAvailable={isAvailable}
              listView={!sectionHasImages}
            />
          );
        })}
      </MenuContainer>
      <ContentArea area="section_end" position="bottom" />
    </>
  );
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default connect(
  (state, { params }) => {
    const menuId = params?.menuId;
    const sectionId = params?.sectionId;
    const section = getMenuSectionById(state, menuId, sectionId);
    const serviceId = getServiceId(state);
    const menuTypeId = selectMenuTypeId(state);
    const sections = selectCurrentSectionAndChildSections(state, menuId, sectionId, serviceId);
    const items = selectSectionProducts(state, section, getServiceById(state, serviceId));

    return {
      slimProducts: section && section.get('slimProducts'),
      sectionId,
      section,
      sectionImage: selectSectionHeaderImage(state, sectionId, menuId),
      sections,
      items,
      itemsAreFiltered: sectionHasFilteredProducts(state, section),
      nextAvailableTime: getMenuNextActiveDetails(state, menuId, serviceId),
      serviceId,
      menuId,
      isAvailable: isMenuActive(state, menuId, serviceId),
      isGridView: isGridView(state),
      menuTypeId,
      currentLocale: getLocale(state),
    };
  },
  {
    fetchSection,
    setBrowseInfo,
  }
)(Section);
