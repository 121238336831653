import React from 'react';

import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import { type Offer } from 'hooks/useLoyalty';

export interface OfferDetailProps {
  offer: Offer;
  onClose: Function;
  onRedeem: Function;
  onRemove: Function;
}

const OfferDetailContainer = styled.div`
  text-align: start;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 250px;
`;

const Content = styled.div`
  padding: 1rem;
`;

const OfferName = styled.h2`
  padding: 1rem;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fonts.headline3FontSize};
  letter-spacing: -0.5px;
`;

const Description = styled.p`
  padding-block-end: 1rem;
`;

const ExpiryDate = styled.p`
  padding-block-start: 0.5rem;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};
`;

const ButtonContainer = styled.div`
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row;
  direction: ltr;
  gap: 2px;

  [dir='rtl'] {
    flex-direction: row-reverse;
    direction: rtl;
  }
`;

const StyledActionButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.color.brandSecondary};
  color: ${({ theme }) => theme.color.brandPrimary};
  border-radius: 0;
  padding: 1.5rem 0;
`;

const OfferDetail: React.FC<OfferDetailProps> = ({ offer, onClose, onRedeem, onRemove }) => {
  const handleRedeem = () => {
    onRedeem();
  };

  const handleRemove = () => {
    onRemove();
  };

  const { image, name, description, endDate, redeemable, inBasket } = offer;

  return (
    <OfferDetailContainer>
      <OfferName>{name}</OfferName>
      <Image src={image} alt={name} />
      <Content>
        <Description>{description}</Description>
        <ExpiryDate>
          {endDate && (
            <FormattedMessage
              defaultMessage="Expires {expiryDate}"
              values={{
                expiryDate: <FormattedDate value={endDate} day="numeric" month="short" year="numeric" />,
              }}
            />
          )}
        </ExpiryDate>
      </Content>
      <ButtonContainer>
        <StyledActionButton
          buttonType="button"
          onClick={onClose}
          primaryButton={false}
          label={<FormattedMessage defaultMessage="Back" />}
        />

        {redeemable && !inBasket && (
          <StyledActionButton
            buttonType="button"
            onClick={handleRedeem}
            primaryButton={true}
            label={<FormattedMessage defaultMessage="Redeem" />}
          />
        )}

        {inBasket && (
          <StyledActionButton
            buttonType="button"
            onClick={handleRemove}
            primaryButton={true}
            label={<FormattedMessage defaultMessage="Remove" />}
          />
        )}
      </ButtonContainer>
    </OfferDetailContainer>
  );
};

export default OfferDetail;
