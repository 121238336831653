import styled, { css } from 'styled-components';
import { Link } from 'react-router';

export const HeaderFixed = styled.header`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.nine};
  height: 48px;
  overflow: hidden;
  width: 100%;
  display: flex;
  padding: 0 5px;

  ${props =>
    props.sidebarOpen &&
    css`
      position: sticky;
    `}
`;

export const ScrollableContainer = styled.div`
  display: inline-block;
  flex-grow: 1;

  ${props =>
    props.showExitButton &&
    css`
      text-align: center;
    `}
`;

export const LogoContainer = styled.div`
  display: inline-block;
  height: 43px;
  padding: 5px 10px;
  position: relative;
  text-decoration: none !important;

  html[dir='rtl'] & {
    left: initial;
    right: 10px;
  }
`;

export const StyledLink = styled(Link)`
  display: block;
`;

export const LogoImg = styled.img`
  height: 38px;
  max-width: 100%;
  width: auto;
  float: left;
`;

export const BackButtonContainer = styled.div`
  height: 30px;
  position: absolute;
  top: 53px;
  left: 5px;
  width: 60px;

  html[dir='rtl'] & {
    right: 5px;
    left: initial;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 1.143em;
  font-weight: 400;
  max-height: 48px;
  line-height: 1.143em;
  text-align: center;
  top: 48px;
  padding: 12px 0px 12px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > span {
    padding: 0 5px;
    font-size: 18px;
  }

  html[dir='rtl'] & {
    left: 96px;
    right: 50px;
  }

  > svg {
    height: 1.143em;
  }
`;

export const ButtonsContainer = styled.div`
  float: right;
  flex-shrink: 0;

  html[dir='rtl'] & {
    float: left;
  }
`;

export const HeaderButton = styled.button`
  cursor: pointer;
  float: right;
  height: 48px;
  position: relative;

  html[dir='rtl'] & {
    float: left;
  }
`;

export const MultilineTruncate = styled.span`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lines }) => lines};
`;
