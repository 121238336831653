import styled from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px;
  color: #999;
  text-align: center;
  float: left;
`;

export const CardIconsList = styled.ul`
  list-style-type: none;
`;

export const CardIcon = styled.li`
  display: inline-block;
  padding: 1px 2px;
`;

export const CompanyName = styled.strong`
  font-size: ${({ theme }) => theme.fonts.fontSizeBig};
  display: inline-block;
  padding-top: 10px;
`;

export const InfoContainer = styled.div`
  ${fontSizeMedium}

  & > span {
    display: inline-block;
    padding: 0 2px;
    padding-top: 10px;
  }
`;
