import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectVenueFeedbackUrl } from 'selectors/root';
import { showFeedbackButton } from 'selectors/feedback';
import ActionButton from 'components/ActionButton';
import { trackEvent } from 'utils/tracking';
import globalMessages from '../globalMessages';

const FeedbackButton = ({ showFeedbackButton, externalUrl, serviceId }) => {
  if (!showFeedbackButton) return null;

  return (
    <ActionButton
      primaryButton={true}
      to={externalUrl ? null : `/feedback/${serviceId}`}
      onClick={() => {
        trackEvent('leave_feedback', {
          category: 'hospitality',
        });
        if (externalUrl) window.open(externalUrl, '_blank');
      }}
      label={<FormattedMessage {...globalMessages.leaveFeedback} />}
    />
  );
};

FeedbackButton.propTypes = {
  showFeedbackButton: PropTypes.bool.isRequired,
  serviceId: PropTypes.any.isRequired,
  trackingLabel: PropTypes.string.isRequired,
  externalUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default connect((state, ownProps) => ({
  showFeedbackButton: showFeedbackButton(state, ownProps?.serviceId),
  externalUrl: selectVenueFeedbackUrl(state),
}))(FeedbackButton);
