import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import ProductPrice from 'components/Product/price';
import { getItemPrice } from 'selectors/browse';

const ItemPrice = ({ item, price }) => <ProductPrice price={item.get('quantity') * price} />;

ItemPrice.propTypes = {
  item: PropTypes.instanceOf(Map).isRequired,
  price: PropTypes.number,
};

const mapStateToProps = (state, { item }) => ({
  price: getItemPrice(state, item.get('unit_price'), item.get('tax_category_ids')),
});

export default connect(mapStateToProps)(ItemPrice);
