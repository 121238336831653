import styled, { keyframes } from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

const processingStartPointAnimation = keyframes`
  8.57% {
    opacity: 0;
  }

  14.29% {
    opacity: 1;
  }

  71.43% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;

const processingPathAnimation = keyframes`
  8.57% {
    opacity: 0;
  }

  14.29% {
    transform: scale(0, 1);
    opacity: 1;
  }

  42.86% {
    transform: scale(1, 1);
  }

  48.57% {
    transform: scale(1, 1);
  }

  71.43% {
    opacity: 1;
  }

  77.14% {
    transform: scale(1, 1);
  }

  80% {
    transform: scale(0, 1);
    opacity: 0;
  }

  0% {
    transform: scale(0, 1);
    opacity: 0;
  }

  100% {
    transform: scale(0, 1);
    opacity: 0;
  }
`;

const processingEndPointAnimation = keyframes`
  42.86% {
    opacity: 0;
  }

  48.57% {
    opacity: 1;
  }

  71.43% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;
export const ProcessingContainer = styled.div`
  text-align: center;

  * {
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }

  .mobile {
    fill: #999;
  }

  .clouds {
    fill: #999;
  }

  .procesingBasePath {
    fill: #ddd;
  }

  .processing {
    fill: #009e00;
  }

  #processingEndPoint {
    animation-name: ${processingEndPointAnimation};
  }

  #processingStartPoint {
    animation-name: ${processingStartPointAnimation};
  }

  #processingPath {
    animation-name: ${processingPathAnimation};
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    transform-origin: 0% 50%;
    transform-box: fill-box;
  }
`;

export const ProcessingLabel = styled.div`
  ${fontSizeMedium};

  color: ${({ theme }) => theme.color.lightGrey};
`;
