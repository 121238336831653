import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};

  @media (min-width: 870px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeMediumOne};
  }
`;
