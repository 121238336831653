const buildProductsList = (crossSellsList, activeMenus) => {
  if (crossSellsList?.size === 0) return crossSellsList;

  const crossSellsProductsList = crossSellsList
    .map(crossSellsItem => {
      const isMenuActive = activeMenus.find(menu => menu.get('id') === crossSellsItem.get('menuId'));

      if (!isMenuActive) return null;

      return crossSellsItem;
    })
    .filter(product => product !== null);

  return crossSellsProductsList;
};

export default buildProductsList;
