import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { getOrderingDevices } from 'actions/order';

import { LoadingDevices, StyledDropdown } from './styles';

const messages = defineMessages({
  loadingDevices: {
    defaultMessage: 'Loading ordering devices...',
  },
  noDevices: {
    defaultMessage: 'There are no ordering devices currently available.',
  },
  selectADevice: {
    defaultMessage: 'Please select an ordering device:',
  },
  deviceWithSerialNumber: {
    defaultMessage: '{deviceName} (Serial Number: {serial})',
  },
  registeredDevice: {
    defaultMessage: '{deviceName} (Registered At: {date} {time})',
  },
});

const getDeviceLabel = (device, { formatMessage, formatDate, formatTime }) => {
  if (device.registeredAt) {
    return formatMessage(messages.registeredDevice, {
      deviceName: device.name,
      date: formatDate(device.registeredAt),
      time: formatTime(device.registeredAt),
    });
  }

  if (device.serialNo) {
    return formatMessage(messages.deviceWithSerialNumber, {
      deviceName: device.name,
      serial: device.serialNo,
    });
  }

  return device.name;
};

class OrderingDeviceSelection extends React.Component {
  state = {
    loading: true,
    orderingDevices: [],
    selectedDevice: null,
  };

  componentDidMount() {
    const success = res => {
      const orderingDevices = res
        .map(device => ({
          value: device.id,
          label: getDeviceLabel(device, this.props.intl),
          disabled: device.isRegistered,
        }))
        .sort((a, b) => a.disabled - b.disabled);
      this.setState({ orderingDevices, loading: false });
    };
    this.props.getOrderingDevices(success);
  }

  onChange = id => {
    this.setState({ selectedDevice: id });
    this.props.onChange(id);
  };

  render() {
    if (this.state.loading) {
      return (
        <LoadingDevices>
          <FormattedMessage {...messages.loadingDevices} />
        </LoadingDevices>
      );
    }

    if (!this.state.orderingDevices.length) {
      return (
        <p className="containerFullPad">
          <FormattedMessage {...messages.noDevices} />
        </p>
      );
    }

    return (
      <div className="containerFullPad">
        <StyledDropdown
          options={this.state.orderingDevices}
          value={this.state.selectedDevice}
          onChange={value => this.onChange(value)}
          label={<FormattedMessage {...messages.selectADevice} />}
        />
      </div>
    );
  }
}

OrderingDeviceSelection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  getOrderingDevices: PropTypes.func,
  onChange: PropTypes.func,
};

export default connect(null, { getOrderingDevices })(injectIntl(OrderingDeviceSelection));
