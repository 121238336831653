import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openExportSingleOrderAlert } from 'actions/UI';
import OrdersIcon from 'components/Icons/OrdersIcon';
import ErrorBoundary from 'components/ErrorBoundary';
import ActionButton from 'components/ActionButton';
import { getOrdersVenueList, getOrder } from 'selectors/user';
import globalMessages from 'components/globalMessages';
import { AlertContent, AlertFooter, AlertButtons, AlertTitle } from 'assets/styles/sharedStyles';
import SystemIcon from 'components/SystemIcon';
import FeedbackButton from 'components/Feedback/FeedbackButton';
import InfoSummary from './InfoSummary';
import OrderItems from './OrderItems';

import messages from '../../../Pages/User/Orders/messages';
import { OrderContainer } from './styles';

const OrderInformation = ({ order, venueName, openExportSingleOrderAlert }) => (
  <>
    <AlertContent>
      <SystemIcon Icon={OrdersIcon} />
      <AlertTitle>
        <FormattedMessage {...messages.header} values={{ number: order.get('number') }} />
      </AlertTitle>
      <ErrorBoundary errorMessage="Sorry, this order could not be displayed. Please try again later.">
        <OrderContainer>
          <InfoSummary order={order} venueName={venueName} />
          <OrderItems items={order.get('items')} total={order.get('total')} />
        </OrderContainer>
      </ErrorBoundary>
    </AlertContent>
    <AlertFooter>
      <AlertButtons>
        <ActionButton
          primaryButton={true}
          label={<FormattedMessage {...globalMessages.exportPdf} />}
          onClick={() => openExportSingleOrderAlert(order.get('reference'))}
        />
        <FeedbackButton serviceId={order.get('service')} trackingLabel="Order information" />
      </AlertButtons>
    </AlertFooter>
  </>
);

OrderInformation.propTypes = {
  order: PropTypes.instanceOf(Map).isRequired,
  venueName: PropTypes.string,
  openExportSingleOrderAlert: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const order = getOrder(state, props?.orderReference);
  const venues = getOrdersVenueList(state);
  const venueName = venues.filter(venue => venue.get('id') === order.get('venueId')).getIn([0, 'name']);
  return {
    venueName,
    order,
  };
};

export default connect(mapStateToProps, { openExportSingleOrderAlert })(OrderInformation);
