import React from 'react';
import PropTypes from 'prop-types';

// Icon
const ContactIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M18,235V99.791l39-40.21V30h30.505l18.823-18.814c1.73-1.731,3.848-3.37,6.275-4.41c2.438-1.047,4.927-1.69,7.398-1.69	c2.461,0,4.95,0.475,7.396,1.521c2.422,1.04,4.538,2.399,6.292,4.145L152.169,29H183v30.308l38.719,39.186l0.281,1.648V235H18z	 M201.824,223l-76.525-76.498c-1.528-1.248-3.263-1.841-5.298-1.841c-2.035,0-3.769,0.612-5.298,1.859l-75.187,75.636L38.553,223	H201.824z M30,215.1l48.47-48.032L30,118.598V215.1z M210,215.1v-96.975l-48.703,48.704L210,215.1z M86.211,158.377l20.235-20.372	c1.753-1.751,3.802-3.163,6.225-4.201c2.435-1.046,4.891-1.577,7.364-1.577c2.462,0,4.934,0.531,7.379,1.577	c2.416,1.038,4.523,2.449,6.282,4.197l19.64,20.36L170,141.421V41H70v100.894L86.211,158.377z M57,129.969V76.496l-26.938,26.938	L57,129.969z M183,129.446l26.476-26.476L183,76.495V129.446z M135.122,30l-9.89-10.34c-1.522-1.522-3.233-2.488-5.232-2.488	s-3.71,1.078-5.233,2.601L104.878,30H135.122z M90,122v-12h60v12H90z M90,97V86h45v11H90z M90,74V62h60v12H90z"
      />
    </g>
  </svg>
);

ContactIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ContactIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default ContactIcon;
