import { defineMessages } from 'react-intl';

const settingsMessages = defineMessages({
  addressesTitle: {
    defaultMessage: 'Your Addresses',
  },
  addAddressTitle: {
    defaultMessage: 'Add Address',
  },
  settingsPage: {
    defaultMessage: 'User Settings',
  },
  success: {
    defaultMessage: 'Update successful.',
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
  },
  updateError: {
    defaultMessage: 'Update Error!',
  },
  forbidden: {
    defaultMessage: 'Could not authorise change, perhaps your password is incorrect?',
  },
  passwordMessage: {
    defaultMessage: 'Please enter your current password in order to make amendments to your current details.',
  },
  requiredFields: {
    defaultMessage: '* Required Field',
  },
});

export default settingsMessages;
