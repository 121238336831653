import React from 'react';
import PropTypes from 'prop-types';

// Icon
const AddressBookIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon"
        fill={fill}
        d="M206,5H50c-6.627,0-12,5.373-12,12v31h-9c-3.3,0-6,2.7-6,6v1c0,3.3,2.7,6,6,6h9v30h-9c-3.3,0-6,2.7-6,6v1c0,3.3,2.7,6,6,6h9	v30h-9c-3.3,0-6,2.7-6,6v1c0,3.3,2.7,6,6,6h9v30H28c-3.3,0-6,2.7-6,6v1c0,3.3,2.7,6,6,6h10v33c0,6.627,5.373,12,12,12h156	c6.627,0,12-5.373,12-12V17C218,10.373,212.627,5,206,5z M206,19v29h-21V17h19C205.104,17,206,17.896,206,19z M185,147h21v31h-21	V147z M185,135v-32h21v32H185z M185,91V60h21v31H185z M50,221v-31h9c3.3,0,6-2.7,6-6v-1c0-3.3-2.7-6-6-6h-9v-30h10c3.3,0,6-2.7,6-6	v-1c0-3.3-2.7-6-6-6H50v-30h10c3.3,0,6-2.7,6-6v-1c0-3.3-2.7-6-6-6H50V61h10c3.3,0,6-2.7,6-6v-1c0-3.3-2.7-6-6-6H50V19 c0-1.104,0.896-2,2-2h121v206H52C50.896,223,50,222.104,50,221z M204,223h-19v-33h21v31C206,222.104,205.104,223,204,223z"
      />
    </g>
  </svg>
);

AddressBookIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

AddressBookIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default AddressBookIcon;
