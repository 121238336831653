import React from 'react';
import PropTypes from 'prop-types';

// Icon
const CloseIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon CloseIcon NoIcon"
        fill={fill}
        d="M120,235c-14.703,0-29.318-2.813-43.438-8.361c-14.167-5.554-26.98-14.005-38.084-25.117	c-11.111-11.101-19.562-23.915-25.116-38.084C7.813,149.315,5,134.7,5,120c0-14.692,2.813-29.307,8.361-43.438	c5.554-14.159,14.004-26.973,25.117-38.084c11.103-11.104,23.917-19.554,38.084-25.117C90.706,7.813,105.32,5,120,5	c14.671,0,29.285,2.813,43.438,8.361c14.161,5.563,26.974,14.014,38.084,25.116c11.104,11.112,19.554,23.925,25.117,38.084 C232.187,90.716,235,105.33,235,120c0,14.679-2.813,29.292-8.36,43.438c-5.564,14.169-14.016,26.982-25.118,38.084	c-11.11,11.111-23.924,19.563-38.084,25.118C149.309,232.187,134.693,235,120,235z M120,17.953c-13.114,0-26.08,2.541-38.536,7.553	C69.023,30.52,57.629,37.97,47.602,47.65c-9.646,9.994-17.096,21.386-22.096,33.813c-5.012,12.437-7.553,25.402-7.553,38.536	c0,13.134,2.541,26.1,7.552,38.536c5.005,12.44,12.456,23.833,22.145,33.862c9.981,9.641,21.375,17.091,33.814,22.096	c12.436,5.012,25.401,7.553,38.536,7.553s26.1-2.541,38.536-7.553c12.424-4.998,23.817-12.448,33.863-22.145	c9.63-9.98,17.081-21.374,22.095-33.814c5.012-12.455,7.553-25.422,7.553-38.535c0-13.114-2.541-26.08-7.553-38.536	c-5.009-12.427-12.459-23.82-22.145-33.863c-9.994-9.637-21.389-17.088-33.814-22.095C146.08,20.494,133.114,17.953,120,17.953z	 M120,129.147l-44.577,44.577l-9.148-9.147l44.568-44.569L65.736,75.431l9.695-9.695L120,110.835l44.568-45.099l9.696,9.695	l-45.108,44.577l44.568,44.569l-9.147,9.147L120,129.147z"
      />
    </g>
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

CloseIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default CloseIcon;
