import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useHandleOutsideClick } from 'hooks';
import { MenuStyle, Item } from './styles';

const propTypes = {
  value: PropTypes.string,
  suggestionsList: PropTypes.array,
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  setValueOnChange: PropTypes.func,
  validateValueOnBlur: PropTypes.func,
};

const defaultProps = {
  type: 'text',
  suggestionsList: [],
};

const Autocomplete = ({
  value,
  suggestionsList,
  type,
  required,
  name,
  className,
  setValueOnChange,
  validateValueOnBlur,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  const autocompleteRef = useRef();
  useHandleOutsideClick(isOpen, autocompleteRef, setIsOpen);

  const handleOnBlur = () => {
    validateValueOnBlur(value);
  };

  const handleClick = (item, index) => {
    setValueOnChange(item);
    setActiveSuggestion(index);
    setIsOpen(false);
  };

  const handleOnChange = inputValue => {
    inputValue.length ? setIsOpen(true) : setIsOpen(false);

    setValueOnChange(inputValue);
  };

  const filteredSuggestions = suggestionsList.filter(item =>
    item.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <div ref={autocompleteRef}>
      <input
        type={type}
        value={value}
        required={required}
        name={name}
        className={className}
        autoComplete="off"
        onBlur={handleOnBlur}
        onChange={e => handleOnChange(e.target.value)}
      />
      {isOpen && filteredSuggestions.length > 0 ? (
        <MenuStyle>
          {filteredSuggestions.map((item, index) => (
            <Item
              key={item}
              highlighted={activeSuggestion === index}
              onPointerDown={() => handleClick(item, index)}
            >
              {item}
            </Item>
          ))}
        </MenuStyle>
      ) : null}
    </div>
  );
};

Autocomplete.propTypes = propTypes;
Autocomplete.defaultProps = defaultProps;

export default Autocomplete;
