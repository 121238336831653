import React from 'react';
import PropTypes from 'prop-types';

// Icon
const SuccessIcon = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        id="circle"
        className="icon successIcon"
        fill={fill}
        d="M919.9,257c43.7,75.3,65.6,157.3,65.6,246c0,88.7-21.9,170.7-65.6,246 c-43.7,75.3-103.2,134.8-178.5,178.5c-75.3,43.7-157.3,65.6-246,65.6c-88.7,0-170.7-21.9-246-65.6C174.2,883.7,114.7,824.2,71.1,749 C27.3,673.7,5.5,591.7,5.5,503c0-88.7,21.8-170.7,65.6-246c43.7-75.3,103.2-134.8,178.5-178.5C324.8,34.9,406.8,13,495.5,13 c88.7,0,170.7,21.9,246,65.6C816.7,122.3,876.2,181.8,919.9,257z"
      />
      <path
        id="tick"
        className="icon successIcon tick"
        fill="#FFFFFF"
        d="M816.7,330.1l-404.6,405c-16.7,16.7-43.7,16.7-60.4,0l-177.2-177c-16.8-16.8-16.8-44.1,0-60.9l0,0	c16.8-16.8,44.1-16.8,60.9,0l147.8,147.6l374-374.4c16.4-16.5,43.1-16.5,59.6,0l0,0C833.1,287,833.1,313.7,816.7,330.1z"
      />
    </g>
  </svg>
);

SuccessIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SuccessIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default SuccessIcon;
