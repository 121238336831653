const calculateDistance = require('./calculateDistance');

const addUserDistanceToVenue = (venues = [], userGeo) =>
  venues.map(venue => {
    const firstService = venue.services[Object.keys(venue.services)[0]];
    if (!firstService.lat || !firstService.lng) return { ...venue, undefined };
    const distance = calculateDistance(userGeo, {
      latitude: parseFloat(firstService.lat),
      longitude: parseFloat(firstService.lng),
    });

    return { ...venue, distance };
  });

module.exports = addUserDistanceToVenue;
