import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import withCookies from 'components/withCookies';
import ActionButton from 'components/ActionButton';
import { SettingsThemedIcon } from 'components/Icons';
import Notification from '../../Notification';

import { Container } from '../../styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Cookie Control',
  },
  cookieMessage: {
    defaultMessage: 'This web app uses cookies to improve user experience.',
  },
  cookieTool: {
    defaultMessage:
      "You can use this tool to change your cookie settings. Otherwise, we'll assume you're OK to continue.",
  },
  saveSettings: {
    defaultMessage: "I'm fine with this",
  },
  privacyInfoButton: {
    defaultMessage: 'Information & Settings',
  },
});

const PrivacySettingsNotification = ({ closeNotification, setCookie }) => {
  const setCookieAccept = () => {
    setCookie('acceptCookies', true, {
      maxLifetime: true,
    });
  };

  return (
    <Notification
      header={<FormattedMessage {...messages.title} />}
      icon={<SettingsThemedIcon />}
      message={
        <div>
          <p>
            <FormattedMessage {...messages.cookieMessage} />
          </p>
          <p>
            <FormattedMessage {...messages.cookieTool} />
          </p>
        </div>
      }
      onClose={() => {
        closeNotification();
        setCookieAccept();
      }}
    >
      <Container className="u-textCenter">
        <ActionButton
          primaryButton={true}
          dataCy="cookies"
          label={<FormattedMessage {...messages.saveSettings} />}
          onClick={() => {
            closeNotification();
            setCookieAccept();
          }}
        />
        <ActionButton
          label={<FormattedMessage {...messages.privacyInfoButton} />}
          onClick={() => {
            closeNotification();
            setCookieAccept();
          }}
          to="/user/privacy"
        />
      </Container>
    </Notification>
  );
};

PrivacySettingsNotification.propTypes = {
  closeNotification: PropTypes.func,
  setCookie: PropTypes.func.isRequired,
};

export default withCookies(PrivacySettingsNotification);
