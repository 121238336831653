import { getServiceById } from 'selectors/root';
import { getMenuById, isParentSection, selectSectionProducts } from 'selectors/browse';

export default (dispatch, getState) =>
  ({ params }, replace) => {
    const state = getState();
    const menuId = params.menuId;
    const { serviceId, sectionId, productId } = params;

    const menu = getMenuById(state, menuId);

    if (!menu) {
      return replace(`/service/${serviceId}`);
    }

    const menuUrl = `/service/${serviceId}/menu/${menuId}`;

    const section = menu.getIn(['sections', sectionId]);

    if (!section) return replace(menuUrl);

    const isParent = isParentSection(section, menu);
    const sectionProducts = selectSectionProducts(state, section, getServiceById(state, serviceId));
    const hasProducts = !!sectionProducts.size;
    if (!isParent && !hasProducts) {
      return replace(menuUrl);
    }

    if (productId) {
      if (!sectionProducts.find(product => product.get('productId') === parseInt(productId, 10))) {
        return replace(`${menuUrl}/section/${sectionId}`);
      }
    }
  };
