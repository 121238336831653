import { Map } from 'immutable';
import leftPad from 'left-pad';
import PropTypes from 'prop-types';
import React from 'react';

import TimeSelector from 'components/DateTime/TimeSelector';
import { createAvailableTimes } from 'components/DateTime/TimeWrapper/utils';

class TimeModifier extends React.Component {
  static getTimeObject = timeString => ({
    hours: parseInt(timeString.split(':')[0], 10),
    minutes: parseInt(timeString.split(':')[1], 10),
  });

  static timesToTimePoints = times =>
    times.map(time => ({
      startTime: TimeModifier.getTimeObject(time.get('start_time')),
      endTime: TimeModifier.getTimeObject(time.get('end_time')),
    }));

  static propTypes = {
    defaultValue: PropTypes.string,
    schedule: PropTypes.instanceOf(Map),
    updateValues: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const defaultTime = TimeModifier.getTimeObject(new Date().toTimeString());
    this.state = {
      selectedTime: props.defaultValue || `${defaultTime.hours}:${defaultTime.minutes}`,
    };
  }

  static defaultInterval = 30;

  static defaultTimePoints = [
    {
      startTime: { hours: 0, minutes: 0 },
      endTime: { hours: 23, minutes: 59 },
    },
  ];

  // eslint-disable-next-line class-methods-use-this
  getCurrentTime = () => {
    const now = new Date();
    return { hours: now.getHours(), minutes: now.getMinutes() };
  };

  handleTimeChange = ({ hours, minutes }) => {
    const stringTime = `${leftPad(hours, 2, '0')}:${leftPad(minutes, 2, '0')}`;
    this.setState({ selectedTime: stringTime }, () => {
      this.props.updateValues(stringTime);
    });
  };

  getHours = () => parseInt(this.state.selectedTime.split(':')[0], 10);

  getMinutes = () => parseInt(this.state.selectedTime.split(':')[1], 10);

  getCurrentDayTimes = () => {
    const day = this.props.schedule?.get('days')?.find(day => {
      const now = new Date();
      return day.get('day') === (now.getDay() === 0 ? 7 : now.getDay());
    });

    return day ? TimeModifier.timesToTimePoints(day.get('times')).toJS() : [];
  };

  render = () => {
    const { schedule } = this.props;
    const interval = schedule?.get('interval') || TimeModifier.defaultInterval;
    const timePoints = !schedule
      ? TimeModifier.defaultTimePoints
      : createAvailableTimes(
          this.getCurrentDayTimes(),
          schedule.get('initial_delay'),
          this.getCurrentTime(),
          interval
        );

    return (
      <TimeSelector
        interval={interval}
        timePoints={timePoints}
        onChange={this.handleTimeChange}
        value={{ hours: this.getHours(), minutes: this.getMinutes() }}
      />
    );
  };
}

export default TimeModifier;
