import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #eceff1; /* wi-Q Platinum */
  color: #002943; /* wi-Q midnight */

  @media (prefers-color-scheme: dark) {
    color: #eceff1; /* wi-Q Platinum */
    background-color: #002943; /* wi-Q midnight */
  }
`;

const MessageContainer = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 72px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #df3466; /* wi-Q Cerise */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:active {
    background-color: #bb2c57; /* wi-Q Cerise Darkend */
  }

  @media (prefers-color-scheme: dark) {
    background-color: #ff8811; /* wi-Q mango */
    color: #3b2004;

    &:hover,
    &:active {
      background-color: #bb640c; /* wi-Q mango Darkend */
    }
  }
`;

class AppErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static reloadPage() {
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  state: { hasError: false };

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-undef
    if (typeof Sentry !== 'undefined') {
      // eslint-disable-next-line no-undef
      Sentry.withScope(scope => {
        scope.setExtras(info);
        // eslint-disable-next-line no-undef
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <MessageContainer>
            <Title>Something went wrong.</Title>
            <Paragraph>We apologize for the inconvenience. Please try refreshing the page.</Paragraph>
            <Button onClick={AppErrorBoundary.reloadPage}>Refresh Page</Button>
          </MessageContainer>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
