import React from 'react';
import PropTypes from 'prop-types';

const MoreIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 11.667 70"
  >
    <path
      className="icon"
      fill={fill}
      opacity={opacity}
      d="M37.833,75.667A5.833,5.833,0,1,0,32,69.833,5.759,5.759,0,0,0,37.833,75.667Zm0,17.5A5.833,5.833,0,1,0,43.667,99,5.759,5.759,0,0,0,37.833,93.167Zm0,29.167a5.833,5.833,0,1,0,5.833,5.833A5.759,5.759,0,0,0,37.833,122.333Z"
      transform="translate(-32 -64)"
    />
  </svg>
);

MoreIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

MoreIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default MoreIcon;
