import styled, { css } from 'styled-components';

export const Label = styled.label`
  font-size: 1em;
  line-height: 26px;
  margin: 0 5px 20px;
  position: relative;
  cursor: pointer;
  font-weight: normal;
  display: block;

  input[type='checkbox'] {
    display: none;
  }
`;

export const Toggle = styled.div`
  position: relative;
  float: right;
  padding: 1px;
  height: 29px;
  width: 54px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  background: ${({ checked, theme }) => (checked ? '#e5f5e5' : theme.color.grayLighter)};
  transition: all 0.3s ease-out;
  margin-left: 5px;

  ${props =>
    props.theme.dir === 'rtl' &&
    css`
      float: left;
      margin-left: 0;
      margin-right: 5px;
    `}
`;

export const StyledTickIcon = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  margin: 1px;
  border-radius: 50%;
  background: ${({ checked, theme }) => (checked ? theme.color.success : theme.color.brandPrimary)};
  transition: all 0.3s ease-out;

  transform: translateX(${({ checked }) => (checked ? '100%' : '0%')});

  ${props =>
    props.theme.dir === 'rtl' &&
    css`
      transform: translateX(${({ checked }) => (checked ? '-100%' : '0%')});
    `}
`;
