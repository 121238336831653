import React from 'react';
import PropTypes from 'prop-types';

import PaymentChoice from './index';
import { PaymentMethodContainer } from './styles';

const propTypes = {
  grouped: PropTypes.bool,
  checked: PropTypes.bool,
};

const PaymentMethodChoice = props => (
  <PaymentMethodContainer grouped={props.grouped} selected={props.checked}>
    <PaymentChoice {...props} />
  </PaymentMethodContainer>
);

PaymentMethodChoice.propTypes = propTypes;

export default PaymentMethodChoice;
