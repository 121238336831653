import React from 'react';
import PropTypes from 'prop-types';

const SidesIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 48 52"
  >
    <path
      className="icon sidesIcon"
      d="M10.1558 47.2H37.8763C38.1472 47.2 38.4094 47.1036 38.6152 46.9276C38.8213 46.7516 38.9575 46.5081 39 46.2406L43.0031 20.734C43.0534 20.4059 42.9582 20.072 42.7424 19.8197C42.5266 19.5672 42.2114 19.4214 41.8793 19.4199H32.3267H32.327C32.0254 19.4199 31.7361 19.5395 31.5227 19.7528C31.3094 19.9659 31.1898 20.2552 31.1898 20.5568C31.1898 21.6482 28.1284 23.2351 23.5204 23.2351C18.9123 23.2351 15.851 21.648 15.851 20.6023C15.8534 20.2956 15.7359 20.0003 15.5234 19.7794C15.3107 19.5585 15.0202 19.4292 14.7137 19.4199H5.16112C4.8203 19.4214 4.49787 19.5754 4.28208 19.8393C4.06662 20.1033 3.98013 20.4499 4.04673 20.784L9.04143 46.2905C9.09386 46.5473 9.23338 46.7781 9.43648 46.9439C9.63958 47.1096 9.89362 47.2 10.1558 47.2L10.1558 47.2ZM36.9036 44.9264H11.0931L6.51699 21.694H13.7953C14.8277 24.1906 19.2811 25.5093 23.5022 25.5093C27.7236 25.5093 32.2039 24.1816 33.2276 21.694H40.5648L36.9036 44.9264Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M14.0267 20.1753L11.2975 12.4812C11.0893 11.8978 10.451 11.5898 9.86465 11.79L4.79281 13.5088C4.20309 13.6962 3.87165 14.3206 4.0467 14.9139L5.86619 20.8892L8.04069 20.2299L6.55777 15.3051L9.52353 14.3001L11.8845 20.9437L14.0267 20.1753Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M21.0507 24.0448V11.3123C21.0507 10.6844 20.5414 10.1754 19.9134 10.1754H14.6957C14.0677 10.1754 13.5585 10.6844 13.5585 11.3123V20.6621H15.8329V12.4764H18.7761V24.0448H21.0507Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M25.781 13.5904V5.13708C25.781 4.50909 25.272 4.00015 24.6438 4.00015H18.9758C18.3406 3.98991 17.8205 4.50188 17.8205 5.13708V11.3215H20.095V6.27371H23.5294V13.5906L25.781 13.5904Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M24.6439 25.4727C24.9452 25.4739 25.2345 25.3552 25.4478 25.1428C25.6612 24.9303 25.7811 24.6416 25.7811 24.3406V20.3027C25.7811 19.8965 25.5645 19.521 25.2125 19.3179C24.8608 19.1148 24.4272 19.1148 24.0753 19.3179C23.7236 19.521 23.5066 19.8964 23.5066 20.3027V24.3316C23.5066 24.6335 23.6266 24.9231 23.8396 25.137C24.0527 25.351 24.3419 25.4715 24.6439 25.4727L24.6439 25.4727Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M26.8956 23.9221L24.3074 14.9319L27.4916 13.7449L30.1573 22.6804L32.3273 22.062L29.3296 11.9347C29.1397 11.3058 28.459 10.9665 27.8422 11.1934L22.5109 13.1714C21.9613 13.3785 21.66 13.9694 21.8148 14.5356L24.6896 24.5395L26.8956 23.9221Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M31.8545 20.5572V8.16107H35.1069V14.0272H37.3813V7.02414C37.3813 6.39615 36.8721 5.88721 36.2441 5.88721H30.7172C30.0892 5.88721 29.58 6.39617 29.58 7.02414V20.5573L31.8545 20.5572Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M34.9971 20.939L37.6854 13.3724L40.0326 14.4L37.8355 20.1477L39.9554 20.9664L42.5481 14.2044C42.763 13.6334 42.4906 12.9942 41.9295 12.7537L37.4762 10.8212C36.8718 10.556 36.1694 10.8592 35.9479 11.4806L32.8317 20.18L34.9971 20.939Z"
      fill={fill}
    />
    <path
      className="icon sidesIcon"
      d="M11.2751 12.4765L9.61023 7.89733L12.3078 6.9879L14.2683 11.7535L16.3699 10.8896L14.0089 5.13253C13.7793 4.57747 13.1591 4.29515 12.5896 4.48676L7.77693 6.11911C7.48885 6.21855 7.2517 6.42768 7.1173 6.70129C6.98592 6.97792 6.97115 7.29583 7.07632 7.58365L9.13688 13.2543L11.2751 12.4765Z"
      fill={fill}
    />
  </svg>
);

SidesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SidesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default SidesIcon;
