import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';

import { getAllowedCallTheWaiterServices } from 'selectors/root';

import { FilteredMessage, StyledLink } from './styles';

const messages = defineMessages({
  productsAreFiltered: {
    defaultMessage:
      'Some {type, select, menus {menus} sections {sections} other {items}} have been filtered.',
  },
  callAWaiter: {
    defaultMessage: 'Call A Waiter?',
  },
});

type MessageType = 'menus' | 'sections' | 'items';
type Props = {
  callAWaiter?: Map<string, any>;
  type?: MessageType;
};

const FilteredItemsMessage = ({ callAWaiter, type = 'items' }: Props) => (
  <FilteredMessage>
    <FormattedMessage {...messages.productsAreFiltered} values={{ type }} />
    {callAWaiter.size >= 1 && (
      <div>
        <StyledLink to="/call-a-waiter" className="defaultButton greenButton">
          <FormattedMessage {...messages.callAWaiter} />
        </StyledLink>
      </div>
    )}
  </FilteredMessage>
);

const mapStateToProps = state => ({
  callAWaiter: getAllowedCallTheWaiterServices(state),
});

export default connect(mapStateToProps)(FilteredItemsMessage);
