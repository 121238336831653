import { setOffline, setOnline } from 'actions/user';

export const addOfflineChecks = store => {
  doOfflineCheck(store);
  addListenedOfflineCheck(store);
};

const doOfflineCheck = store => {
  if (navigator.onLine) {
    store.dispatch(setOnline());
  } else {
    store.dispatch(setOffline());
  }
};

const addListenedOfflineCheck = store => {
  window.addEventListener(
    'online',
    () => {
      store.dispatch(setOnline());
    },
    false
  );

  window.addEventListener(
    'offline',
    () => {
      store.dispatch(setOffline());
    },
    false
  );
};
