import { defineMessages } from 'react-intl';

const messages = defineMessages({
  enterTipAmount: {
    defaultMessage: 'Enter Tip Amount ({currencyCode})',
  },
  gratuity: {
    defaultMessage: 'Gratuity',
  },
  other: {
    defaultMessage: 'Other',
  },
});

export default messages;
