import leftPad from 'left-pad';
import { getMinutesFromTime, getTimeFromMinutes } from '../TimeSelector/getMinutes';

export const createAvailableTimes = (times, startDelay, currentTime = { hours: 0, minutes: 0 }, interval) => {
  const nextStartingTimeMinutes = startDelay
    ? getMinutesFromTime(currentTime) + startDelay
    : getMinutesFromTime(currentTime);
  const nextStartingIntervalTime = Math.ceil(nextStartingTimeMinutes / interval) * interval;
  return times
    .map(timeSet => {
      if (getMinutesFromTime(timeSet.endTime) <= nextStartingTimeMinutes) return null;
      if (getMinutesFromTime(timeSet.startTime) < nextStartingTimeMinutes) {
        return Object.assign({}, timeSet, {
          startTime: getTimeFromMinutes(nextStartingIntervalTime),
        });
      }
      return timeSet;
    })
    .filter(timeSet => timeSet);
};

export const convertArrayofStringTime = timePoints =>
  timePoints.map(timePoint => {
    if (timePoint.start_time.hours && timePoint.end_time.hours) {
      return {
        startTime: timePoint.start_time,
        endTime: timePoint.end_time,
      };
    }
    return {
      startTime: timeFromString(timePoint.start_time),
      endTime: timeFromString(timePoint.end_time),
    };
  });

export const timeFromString = timeString => {
  if (!timeString || !timeString.split || !timeString.split(':')[1]) {
    return 'ASAP';
  }
  return {
    hours: parseInt(timeString.split(':')[0], 10),
    minutes: parseInt(timeString.split(':')[1], 10),
  };
};

export const stringFromTime = time => {
  if (typeof time === 'string') return time;
  return `${leftPad(time.hours, 2, '0')}:${leftPad(time.minutes, 2, '0')}`;
};

export const getClosestTime = (value, timesList, allowASAP) => {
  if (value === 'ASAP' && allowASAP) return 'ASAP';

  if (value === 'ASAP' && !allowASAP) {
    return timesList[0];
  }

  return timesList.reduce((closestTime, time) => {
    const currentDistance = Math.abs(getMinutesFromTime(closestTime) - getMinutesFromTime(value));
    const distance = Math.abs(getMinutesFromTime(time) - getMinutesFromTime(value));
    return distance > currentDistance ? closestTime : time;
  }, {});
};
