import React from 'react';
import PropTypes from 'prop-types';

const LocalServicesIcon = ({ className, width, height, fill, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        className="icon localServicesIcon"
        fill={fill}
        opacity={opacity}
        d="M308.6,93.2c-6.4-14.7-15.1-27.5-26-38.4c-11.4-10.9-24.4-19.6-39.1-26c-14.7-6.4-30.3-9.6-46.9-9.6c-16.6,0-32.2,3.2-46.9,9.6c-14.7,6.4-27.7,15.1-39.1,26C99.6,65.7,91,78.5,84.6,93.2c-6.4,14.7-9.6,30.6-9.6,47.7c0,5.2,0.4,10.6,1.1,16c0.7,5.5,1.8,10.8,3.2,16c1.4,4.7,3.2,9.6,5.3,14.6c2.1,5,4.4,9.6,6.8,13.9l105.3,182.1l105.3-182.1c2.4-4.3,4.6-8.9,6.8-13.9c2.1-5,3.9-9.8,5.3-14.6c1.4-5.2,2.5-10.5,3.2-16c0.7-5.4,1.1-10.8,1.1-16C318.2,123.8,315,107.9,308.6,93.2z M235.7,157.2c-2.4,5.2-5.5,9.7-9.2,13.5c-3.8,3.8-8.3,6.8-13.5,8.9c-5.2,2.1-10.7,3.2-16.4,3.2c-5.7,0-11.1-1.1-16.4-3.2c-5.2-2.1-9.7-5.1-13.5-8.9c-3.8-3.8-6.9-8.3-9.2-13.5c-2.4-5.2-3.6-10.7-3.6-16.4c0-6.2,1.2-11.7,3.6-16.7c2.4-5,5.4-9.4,9.2-13.2c3.8-4.3,8.3-7.5,13.5-9.6c5.2-2.1,10.7-3.2,16.4-3.2c5.7,0,11.1,1.1,16.4,3.2c5.2,2.1,9.7,5.3,13.5,9.6c3.8,3.8,6.9,8.2,9.2,13.2c2.4,5,3.6,10.6,3.6,16.7C239.2,146.5,238.1,152,235.7,157.2z"
      />
      <path
        className="icon localServicesIcon"
        fill={fill}
        opacity={opacity}
        d="M919.2,506.2l-27.6,47.8l44.2,284.3l-209.1-123l-7.3-102.6l-39.6-68.5l12.4,174.4L515.4,832.1l0.2-558.6l139.9-71.7l8.5,119.3c4-5.2,8.4-10.2,13.1-14.9l0.3-0.3l0.3-0.3c5.9-5.7,12.2-10.9,18.9-15.6l-6.2-86.7L812.9,262c12.8,2,25.1,5.6,37,10.7c13.1,5.7,25.3,12.9,36.4,21.5l-6-38.4L670.2,155.3l-170.8,87.5l-147.3-76.5c-0.9,5.4-2,10.8-3.5,16l-0.1,0.4l-0.1,0.4c-1.8,6-4.1,12.2-6.7,18.4c-2.6,6-5.4,11.8-8.4,17.1l-0.3,0.6l-25.9,44.8l-32.5,451L64.2,839.6l75.2-483.2l-27.6-47.8L18.7,906.7l270.1-159.8l210.2,136.8l213.2-136.9l269,158.3L919.2,506.2z M480.8,830.5L309.1,718.8l37.2-516.5l134.7,70L480.8,830.5z"
      />
      <path
        className="icon localServicesIcon"
        fill={fill}
        opacity={opacity}
        d="M900.6,370.1c-6.4-14.7-15.1-27.5-26-38.4c-11.4-10.9-24.4-19.6-39.1-26c-14.7-6.4-30.3-9.6-46.9-9.6c-16.6,0-32.2,3.2-46.9,9.6c-14.7,6.4-27.7,15.1-39.1,26c-10.9,10.9-19.6,23.7-26,38.4c-6.4,14.7-9.6,30.6-9.6,47.7c0,5.2,0.4,10.6,1.1,16c0.7,5.5,1.8,10.8,3.2,16c1.4,4.7,3.2,9.6,5.3,14.6c2.1,5,4.4,9.6,6.8,13.9l105.3,182.1l105.3-182.1c2.4-4.3,4.6-8.9,6.8-13.9c2.1-5,3.9-9.8,5.3-14.6c1.4-5.2,2.5-10.5,3.2-16c0.7-5.4,1.1-10.8,1.1-16C910.2,400.6,907,384.8,900.6,370.1z M827.7,434.1c-2.4,5.2-5.5,9.7-9.2,13.5c-3.8,3.8-8.3,6.8-13.5,8.9c-5.2,2.1-10.7,3.2-16.4,3.2s-11.1-1.1-16.4-3.2c-5.2-2.1-9.7-5.1-13.5-8.9c-3.8-3.8-6.9-8.3-9.2-13.5c-2.4-5.2-3.6-10.7-3.6-16.4c0-6.2,1.2-11.7,3.6-16.7c2.4-5,5.4-9.4,9.2-13.2c3.8-4.3,8.3-7.5,13.5-9.6c5.2-2.1,10.7-3.2,16.4-3.2s11.1,1.1,16.4,3.2c5.2,2.1,9.7,5.3,13.5,9.6c3.8,3.8,6.9,8.2,9.2,13.2c2.4,5,3.6,10.6,3.6,16.7C831.2,423.4,830,428.9,827.7,434.1z"
      />
    </g>
  </svg>
);

LocalServicesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  opacity: PropTypes.string,
};

LocalServicesIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
  opacity: '1',
};

export default LocalServicesIcon;
