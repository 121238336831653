import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import withCookies from 'components/withCookies';
import LoginForm from 'components/LoginForm';
import MessageBlock from 'components/MessageBlock';
import { getIsAdmin } from 'selectors/root';
import { logout } from 'actions/user';
import { clearOrder } from 'actions/order';
import { setKioskMode } from 'actions/features';

import globalMessages from 'components/globalMessages';
import { AlertContent } from 'assets/styles/sharedStyles';
import messages from './intl';
import KioskModeConfirmed from './KioskModeConfirmed';

class KioskAlert extends React.Component {
  state = {
    isAdmin: this.props.isAdmin,
    enabled: this.props.getCookie('kioskMode') === 'true' || false,
    showError: false,
  };

  componentDidMount() {
    if (this.props.isAdmin) {
      const enabled = !this.state.enabled;
      this.setKioskCookie(enabled);
    }
  }

  setKioskMode = res => {
    this.setState({ showError: false });
    if (res.success) {
      if (res?.user.isAdmin) {
        this.setKioskCookie(!this.state.enabled);
        this.setState({ isAdmin: true });
      } else this.setState({ showError: true });
    }
  };

  setKioskCookie = enabled => {
    this.props.setCookie('kioskMode', enabled, {
      maxLifetime: true,
    });
    this.props.setKioskMode(enabled);
    if (enabled) {
      this.props.logout();
      this.props.clearOrder();
    }
    this.setState({ enabled });
  };

  render() {
    if (this.state.isAdmin) {
      return <KioskModeConfirmed closeAlert={this.props.closeAlert} enabled={this.state.enabled} />;
    }
    const enableMsg = this.state.enabled ? globalMessages.disable : globalMessages.enable;
    return (
      <>
        <AlertContent>
          <LoginForm
            onSubmit={this.setKioskMode}
            header={<FormattedMessage {...messages.header} />}
            message={
              <FormattedMessage
                {...messages.loginMessage}
                values={{
                  enable: (
                    <strong>
                      <FormattedMessage {...enableMsg} />
                    </strong>
                  ),
                }}
              />
            }
          >
            {() => (
              <>
                {this.state.showError && (
                  <MessageBlock
                    header={<FormattedMessage {...messages.adminErrorHeader} />}
                    body={<FormattedMessage {...messages.adminErrorMessage} />}
                    type="error"
                  />
                )}
              </>
            )}
          </LoginForm>
        </AlertContent>
      </>
    );
  }
}

KioskAlert.propTypes = {
  isAdmin: PropTypes.bool,
  closeAlert: PropTypes.func.isRequired,
  setCookie: PropTypes.func.isRequired,
  getCookie: PropTypes.func.isRequired,
  logout: PropTypes.func,
  clearOrder: PropTypes.func,
  setKioskMode: PropTypes.func,
};

const mapStateToProps = state => ({
  isAdmin: getIsAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  clearOrder: () => dispatch(clearOrder()),
  setKioskMode: enabled => dispatch(setKioskMode(enabled)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withCookies)(KioskAlert);
