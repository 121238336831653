import { fromJS, Map } from 'immutable';

import { SET_ORDER_VIOLATIONS, SET_ORDER_VIOLATION } from 'actions/order/constants';
import {
  SET_PAYMENT_METHODS,
  SUBMIT_PAYMENT_REQUEST,
  SUBMIT_PAYMENT_COMPLETE,
  CLEAR_VIOLATIONS,
  CLEAR_ERROR_MESSAGE,
  SET_VIOLATION,
} from 'actions/payment/constants';

export const initialState = fromJS({
  paymentMethods: [],
  waiting: false,
  successful: undefined,
  violations: new Map(),
  errorKey: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHODS:
      return state.merge(
        fromJS({
          paymentMethods: action.paymentMethods,
        })
      );

    case SUBMIT_PAYMENT_REQUEST:
      return state.merge(
        fromJS({
          waiting: true,
          methodId: action.methodId,
          successful: undefined,
          violations: new Map(),
        })
      );

    case SUBMIT_PAYMENT_COMPLETE:
      return state.merge(
        fromJS({
          waiting: false,
          successful: action.success,
          violations: action.violations,
          errorKey: action.success ? undefined : action.errorKey,
        })
      );

    case SET_ORDER_VIOLATION:
      return state.merge(
        fromJS({
          waiting: false,
          successful: action.success,
          violations: state.get('violations').merge(fromJS(action.violations)),
        })
      );

    case SET_ORDER_VIOLATIONS:
      return state.merge(
        fromJS({
          waiting: false,
          successful: action.success,
          violations: new Map(action.violations),
        })
      );

    case CLEAR_VIOLATIONS: {
      if (!state.has('violations')) return state;
      const s = state.deleteIn(['violations', action.errorKey]);
      if (s.get('violations') && s.get('violations').size === 0) {
        return state.set('violations', new Map());
      }
      return s;
    }

    case CLEAR_ERROR_MESSAGE:
      return state.merge(
        fromJS({
          errorKey: undefined,
        })
      );

    case SET_VIOLATION:
      return state.setIn(['violations', action.error.errorKey], fromJS(action.error.error));

    default:
      return state;
  }
};
