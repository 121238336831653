import _ from 'lodash';
import {
  productType,
  promotionType,
  deliveryChargeType,
  trayChargeType,
  serviceChargeType,
} from 'selectors/orderItemTypes';

const compareIdentifiers = (a, b) => {
  if (a.section_product_id) return a.section_product_id === b.section_product_id;
  if (a.base_product_id) return a.base_product_id === b.base_product_id;
  if (a.product) return a.product === b.product;
  return a.name === b.name && a.unit_price === b.unit_price;
};

// Group by section_product_id but fall back to product_id if not present.
export const getSimpleProductExistingIndex = (mergedItems, item) =>
  mergedItems.findIndex(i => compareIdentifiers(item, i) && !i?.modifiers?.length && !i.notes);

export const getDonationExistingIndex = (mergedItems, item) =>
  mergedItems.findIndex(i => i.product === item.product);

const getSortedModifierValues = values => values.map(val => val.id).sort((a, b) => a - b);

export const hasSameModifierValues = (values1, values2) => {
  if (values1?.length !== values2?.length) return false;
  if (!values1?.length && !values2?.length) return true;
  const sortedValues1 = getSortedModifierValues(values1);
  const sortedValues2 = getSortedModifierValues(values2);
  return sortedValues1.join(',') === sortedValues2.join(',');
};

export const getSortedModifiers = modifiers =>
  modifiers.sort((a, b) => a.modifier.id - b.modifier.id).sort((a, b) => a.group - b.group);

export const hasSameModifiers = (mod1, mod2) => {
  if (!mod1?.length && !mod2?.length) return true;
  const valueSorter = (a, b) => (a.product ? a.product - b.product : a.id - b.id);
  return mod1.every(a => mod2.some(b => _.isEqual(a.values.sort(valueSorter), b.values.sort(valueSorter))));
};

export const getCustomisedProductExistingIndex = (mergedItems, item) => {
  if (item.notes) return -1;

  // Group by section_product_id but fall back to product_id if not present.
  return mergedItems.findIndex(
    (i, index) =>
      compareIdentifiers(item, i) &&
      item?.modifiers.length &&
      item.modifiers.length === i.modifiers.length &&
      !i.notes &&
      hasSameModifiers(item.modifiers, mergedItems[index].modifiers)
  );
};

const getPromotionExistingIndex = (mergedItems, item) =>
  mergedItems.findIndex(
    i =>
      i.type === promotionType &&
      i.name === item.name &&
      i.unit_price === item.unit_price &&
      i.tax_category_ids.length === item.tax_category_ids.length &&
      i.tax_category_ids.sort().toString() === item.tax_category_ids.sort().toString()
  );

const getChargeExistingIndex = (mergedItems, item) =>
  mergedItems.findIndex(i => i.type === item.type && i?.chargeId === item?.chargeId);

const getMergedOrderItems = (mergedItems = [], orderItems = []) => {
  orderItems.forEach(orderItem => {
    let existingItemIndex = -1;

    if (orderItem?.type === productType && !orderItem.notes) {
      if (!orderItem?.modifiers?.length) {
        existingItemIndex = getSimpleProductExistingIndex(mergedItems, orderItem);
      } else {
        existingItemIndex = getCustomisedProductExistingIndex(mergedItems, orderItem);
      }
    }

    if (orderItem?.type === promotionType) {
      existingItemIndex = getPromotionExistingIndex(mergedItems, orderItem);
    }

    if ([deliveryChargeType, trayChargeType, serviceChargeType].includes(orderItem?.type)) {
      existingItemIndex = getChargeExistingIndex(mergedItems, orderItem);
    }

    if (existingItemIndex === -1) {
      mergedItems.push(orderItem);
      return;
    }

    mergedItems[existingItemIndex].quantity += orderItem.quantity;
    mergedItems[existingItemIndex].tax_total += orderItem.tax_total;
    mergedItems[existingItemIndex].total += orderItem.total;
    if ([deliveryChargeType, trayChargeType, serviceChargeType].includes(orderItem?.type)) {
      mergedItems[existingItemIndex].unit_price += orderItem.unit_price;
    }
  });
  return mergedItems;
};

const getMergedTaxTotals = (mergedTaxTotals = [], orderTaxTotals = []) => {
  orderTaxTotals.forEach(taxTotal => {
    const existingItemIndex = mergedTaxTotals.findIndex(t => t.tax_category_id === taxTotal.tax_category_id);
    if (existingItemIndex === -1) {
      mergedTaxTotals.push(taxTotal);
      return;
    }

    mergedTaxTotals[existingItemIndex].total += taxTotal.total;
  });
  return mergedTaxTotals;
};

export default orders => {
  const ordersCopy = JSON.parse(JSON.stringify(orders));
  return ordersCopy.reduce(
    (mergedOrders, order) => {
      const mergedItems = mergedOrders.items;
      const mergedTaxTotals = mergedOrders.tax_totals;
      const items = getMergedOrderItems(mergedItems, order?.items);
      const taxTotals = getMergedTaxTotals(mergedTaxTotals, order?.tax_totals);

      return { items, tax_totals: taxTotals };
    },
    { items: [], tax_totals: [] }
  );
};
