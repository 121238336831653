import React from 'react';
import PropTypes from 'prop-types';

// Icon
const TickIcon = ({ className, width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 240 240"
  >
    <g>
      <path
        className="icon tickIcon"
        fill={fill}
        d="M77.5,204.3c-2.2,0-4.3-0.2-6-0.6c-3-0.6-4.7-2.3-5.6-3.8l-54.7-54.7c-3.3-3.2-4.9-7.2-4.9-11.7c0-4.5,1.7-8.2,4.9-10.8 c2.6-3.2,6.3-4.9,10.8-4.9c4.5,0,8.4,1.7,11.7,4.9l43.9,43.9L207.2,40.8c2.6-3.3,6.4-5.1,10.9-5.1c4.5,0,8.4,1.7,11.7,4.9l0.2,0.2	l0.2,0.2c2.4,3.2,3.6,7,3.6,11.3c0,4.6-1.8,8.3-5.1,10.9L88.1,200c-0.9,1.5-2.6,3.1-5.6,3.7C80.7,204.1,79,204.3,77.5,204.3z"
      />
    </g>
  </svg>
);

TickIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TickIcon.defaultProps = {
  height: '100%',
  fill: 'currentColor',
};

export default TickIcon;
