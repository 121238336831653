const deg2rad = deg => deg * (Math.PI / 180);

const calculateDistance = (coords1, coords2) => {
  const R = 6371; // Radius of the earth in km
  const latitudeDistance = deg2rad(coords2.latitude - coords1.latitude);
  const longitudeDistance = deg2rad(coords2.longitude - coords1.longitude);

  const angle =
    Math.sin(latitudeDistance / 2) * Math.sin(latitudeDistance / 2) +
    Math.cos(deg2rad(coords1.latitude)) *
      Math.cos(deg2rad(coords2.latitude)) *
      Math.sin(longitudeDistance / 2) *
      Math.sin(longitudeDistance / 2);

  const c = 2 * Math.atan2(Math.sqrt(angle), Math.sqrt(1 - angle));
  return R * c; // Distance in km
};

module.exports = calculateDistance;
