import styled from 'styled-components';
import { headlineFontFamily } from 'assets/styles/fonts';

export const Headline1 = styled.h1`
  font-size: ${({ theme }) => theme.fonts.headline1FontSize};
  ${headlineFontFamily};

  @media (min-width: 800px) {
    font-size: calc(${({ theme }) => theme.fonts.headline1FontSize} + 4px);
  }
`;
