import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import ActionButton from 'components/ActionButton';

import { updateItem } from 'actions/order';
import globalMessages from 'components/globalMessages';

import { DeleteIcon } from 'components/Icons';
import { AlertMessage } from 'components/Alert/StandardAlert/styles';
import { AlertContent, AlertTitle, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import ProductQuantity from 'components/ProductQuantity';
import { Container, QuantityContainer, QuantityHeader } from './styles';

const messages = defineMessages({
  header: {
    defaultMessage: `{itemCount, plural,
      one {Remove Item?}
      other {Remove Items?}
    }`,
  },
  message: {
    defaultMessage: `{itemCount, plural,
      one {Are you sure you want to remove "{ product }"?}
      other {Are you sure you want to remove {itemCount} x "{ product }"?}
    }`,
  },
  removeItem: {
    defaultMessage: 'Remove Item',
  },
  removeAll: {
    defaultMessage: 'Remove All',
  },
  orUpdateQty: {
    defaultMessage: 'or update quantity',
  },
  update: {
    defaultMessage: 'Update Quantity',
  },
});

class RemoveProductAlert extends Component {
  static propTypes = {
    confirmRemove: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    closeAlert: PropTypes.func.isRequired,
    updateItem: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const initialNotes = props.item && props.item.get('notes');
    const initialQuantity = props.item && props.item.get('quantity');
    const initialModifiers = props.item && props.item.get('modifiers');
    this.state = {
      initialQuantity,
      quantity: initialQuantity || 1,
      notes: initialNotes || '',
      modifiers: initialModifiers || new List(),
    };
  }

  confirmMethod = () => {
    if (this.state.initialQuantity === this.state.quantity) {
      this.props.confirmRemove();
    } else {
      this.props.updateItem(this.state.quantity, this.state.modifiers, this.state.notes);
    }
    this.props.closeAlert();
  };

  confirmLabel = () => {
    switch (this.state.initialQuantity) {
      case 1:
        return <FormattedMessage {...messages.removeItem} />;
      case this.state.quantity:
        return <FormattedMessage {...messages.removeAll} />;
      default:
        return <FormattedMessage {...messages.update} />;
    }
  };

  render() {
    const { item, closeAlert } = this.props;
    const { modifiers, initialQuantity } = this.state;
    const productName = item.get('name');
    const hasModifiers = item && modifiers.size;

    return (
      <>
        <AlertContent>
          <DeleteIcon fill="#cf1010" width="60px" height="60px" />
          <AlertTitle>
            <FormattedMessage {...messages.header} values={{ itemCount: initialQuantity }} />
          </AlertTitle>
          <Container>
            <AlertMessage>
              <FormattedMessage
                {...messages.message}
                values={{ itemCount: initialQuantity, product: productName }}
              />
            </AlertMessage>

            {!hasModifiers && initialQuantity > 1 && (
              <QuantityContainer>
                <QuantityHeader>
                  <FormattedMessage {...messages.orUpdateQty} />
                </QuantityHeader>
                <ProductQuantity
                  quantity={this.state.quantity}
                  onChange={quantity => this.setState({ quantity })}
                  productId={item?.get('product')}
                />
              </QuantityContainer>
            )}
          </Container>
        </AlertContent>
        <AlertFooter>
          <AlertButtons>
            <ActionButton
              dangerButton={true}
              label={<FormattedMessage {...globalMessages.cancel} />}
              onClick={closeAlert}
            />
            <ActionButton primaryButton={true} label={this.confirmLabel()} onClick={this.confirmMethod} />
          </AlertButtons>
        </AlertFooter>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, { index }) => ({
  updateItem: (quantity, modifiers, notes) => dispatch(updateItem(index, quantity, modifiers, notes)),
});

const ConnectedRemoveProductAlert = connect(undefined, mapDispatchToProps)(RemoveProductAlert);

export default ConnectedRemoveProductAlert;
