import { css } from 'styled-components';

export const fontSizeBig = css`
  font-size: ${({ theme }) => theme.fonts.fontSizeBig};

  @media (min-width: 800px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeBigOne};
  }
`;

export const fontSizeMedium = css`
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};

  @media (min-width: 800px) {
    font-size: ${({ theme }) => theme.fonts.fontSizeMediumOne};
  }
`;

export const fontSizeSmall = css`
  font-size: ${({ theme }) => theme.fonts.fontSizeSmall};
`;

export const headlineFontFamily = css`
  font-family: ${({ theme }) => theme.fonts.headlineFontName};
`;
