export const LOGOUT = 'app/User/LOGOUT';
export const LOGIN = 'app/User/LOGIN';
export const SET_USER = 'app/User/SET_USER';
export const UPDATE_USER = 'app/User/UPDATE_USER';
export const SET_LOCALE = 'app/User/SET_LOCALE';
export const ADD_FAVOURITE = 'app/User/ADD_FAVOURITE';
export const REQUEST_ORDERS = 'app/User/REQUEST_ORDERS';
export const RECEIVE_ORDERS = 'app/User/RECEIVE_ORDERS';
export const REMOVE_FAVOURITE = 'app/User/REMOVE_FAVOURITE';
export const SET_OFFLINE = 'app/User/SET_OFFLINE';
export const SET_ONLINE = 'app/User/SET_ONLINE';
export const ERROR_USER_GEO_LOCATION = 'app/User/ERROR_USER_GEO_LOCATION';
export const LOADING_USER_GEO_LOCATION = 'app/User/LOADING_USER_GEO_LOCATION';
export const LOADING_USER_ADDRESS_FROM_COORDS = 'app/User/LOADING_USER_ADDRESS_FROM_COORDS';
export const SET_USER_GEO_LOCATION = 'app/User/SET_USER_GEO_LOCATION';
export const LOADING_USER_PREDICTIONS = 'app/User/LOADING_USER_PREDICTIONS';
export const SET_USER_PREDICTIONS = 'app/User/SET_USER_PREDICTIONS';
export const ERROR_USER_PREDICTIONS = 'app/User/ERROR_USER_PREDICTIONS';
export const LOADING_USER_COORDS = 'app/User/LOADING_USER_COORDS';
export const SET_USER_COORDS = 'app/User/SET_USER_COORDS';
export const ERROR_USER_COORDS = 'app/User/ERROR_USER_COORDS';
export const CLEAR_USER_PREDICTIONS_AND_COORDS = 'app/User/CLEAR_USER_PREDICTIONS_AND_COORDS';
export const CLEAR_USER_PREDICTIONS_AND_COORDS_SESSION = 'app/User/CLEAR_USER_PREDICTIONS_AND_COORDS_SESSION';
export const REMOVE_USER_GEO_LOCATION = 'app/User/REMOVE_USER_GEO_LOCATION';
export const LOGIN_REQUEST_START = 'app/User/LOGIN_REQUEST_START';
export const LOGIN_REQUEST_SUCCESSFUL = 'app/User/LOGIN_REQUEST_SUCCESSFUL';
export const LOGIN_REQUEST_FAILED = 'app/User/LOGIN_REQUEST_FAILED';
export const LOGIN_CLEAR_STATE = 'app/User/LOGIN_CLEAR_STATE';
export const REGISTER_REQUEST_START = 'app/User/REGISTER_REQUEST_START';
export const REGISTER_REQUEST_SUCCESSFUL = 'app/User/REGISTER_REQUEST_SUCCESSFUL';
export const REGISTER_REQUEST_FAILED = 'app/User/REGISTER_REQUEST_FAILED';
export const REGISTER_CLEAR_STATE = 'app/User/REGISTER_CLEAR_STATE';
export const INITIATE_FORGOT_PASSWORD = 'app/ForgotPassword/INITIATE_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST_COMPLETE = 'app/ForgotPassword/FORGOT_PASSWORD_REQUEST_COMPLETE';
export const FORGOT_PASSWORD_CLEAR_STATE = 'app/User/FORGOT_PASSWORD_CLEAR_STATE';
export const INITIATE_RESET_PASSWORD = 'app/ForgotPassword/INITIATE_RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST_COMPLETE = 'app/ForgotPassword/RESET_PASSWORD_REQUEST_COMPLETE';
export const VALIDATE_RESET_TOKEN_REQUEST = 'app/ForgotPassword/VALIDATE_RESET_TOKEN_REQUEST';
export const VALIDATE_RESET_TOKEN_COMPLETE = 'app/ForgotPassword/VALIDATE_RESET_TOKEN_COMPLETE';
export const SET_SELECTED_VENUE = 'app/User/Orders/SET_SELECTED_VENUE';
export const TOGGLE_FILTER = 'app/User/Orders/TOGGLE_FILTER';
export const CLEAR_FILTER = 'app/User/Orders/CLEAR_FILTER';
export const APPLY_FILTER = 'app/User/Orders/APPLY_FILTER';
export const EXPORT_ORDER_REQUEST_START = 'app/User/EXPORT_ORDER_REQUEST_START';
export const EXPORT_ORDER_REQUEST_SUCCESSFUL = 'app/User/EXPORT_ORDER_REQUEST_SUCCESSFUL';
export const EXPORT_ORDER_REQUEST_FAILED = 'app/User/EXPORT_ORDER_REQUEST_FAILED';
export const REQUEST_ADDRESSES_START = 'app/User/REQUEST_ADDRESSES_START';
export const REQUEST_ADDRESSES_SUCCESS = 'app/User/REQUEST_ADDRESSES_SUCCESS';
export const REQUEST_ADDRESSES_FAILURE = 'app/User/REQUEST_ADDRESSES_FAILURE';
export const ADD_ADDRESS_START = 'app/User/ADD_ADDRESS_START';
export const ADD_ADDRESS_SUCCESS = 'app/User/ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'app/User/ADD_ADDRESS_FAILURE';
export const EDIT_ADDRESS_START = 'app/User/EDIT_ADDRESS_START';
export const EDIT_ADDRESS_SUCCESS = 'app/User/EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAILURE = 'app/User/EDIT_ADDRESS_FAILURE';
export const DELETE_ADDRESS_START = 'app/User/DELETE_ADDRESS_START';
export const DELETE_ADDRESS_SUCCESS = 'app/User/DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'app/User/DELETE_ADDRESS_FAILURE';
export const SET_DEFAULT_ADDRESS_START = 'app/User/SET_DEFAULT_ADDRESS_START';
export const SET_DEFAULT_ADDRESS_SUCCESS = 'app/User/SET_DEFAULT_ADDRESS_SUCCESS';
export const SET_DEFAULT_ADDRESS_FAILURE = 'app/User/SET_DEFAULT_ADDRESS_FAILURE';
export const SET_PAYMENT_SOURCES = 'app/User/SET_PAYMENT_SOURCES';
export const DELETE_PAYMENT_SOURCE = 'app/User/DELETE_PAYMENT_SOURCE';
export const EXPORT_ORDERS_START = 'app/User/EXPORT_ORDERS_START';
export const EXPORT_ORDERS_SUCCESS = 'app/User/EXPORT_ORDERS_SUCCESS';
export const EXPORT_ORDERS_FAILURE = 'app/User/EXPORT_ORDERS_FAILURE';
