import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.primaryText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  inset: 5rem 1rem;
  position: fixed;
`;

export const Messages = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.color.brandPrimary} !important;
  font-size: 1.75em;
  margin-block: 1rem;
`;
